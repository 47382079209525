import { Effect } from 'mixins/withEffect';
import NodeChangesTracker from './changesTracker';

export default class NodeSizeChangesTracker extends NodeChangesTracker {
    protected observe(node: HTMLElement, onChange: Action): Effect {
        //hack for some old code. comes from timeline but no time to look for exact source
        if (<any>node == document)
            node = document.body;

        const observer = new ResizeObserver(onChange);
        observer.observe(node, { box: 'border-box' });

        return () => observer.disconnect();
    }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class AnalysisService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            smartBannerData: () => this._repository.query('analysis2/getSmartBannerData'),
            smartGridData: () => this._repository.query('analysis2/getSmartGridData'),
            smartGridDataWithAnswers: () => this._repository.query('analysis2/getSmartGridData/answers'),
            smartGridDataExternalSurvey: (topicId) => this._repository.query('analysis2/getSmartTableData?topicId=' + topicId),
            smartWordCloudData: () => this._repository.query('analysis2/getSmartGridWordCloudData'),
            smartSentimentData: () => this._repository.query('analysis2/GetSmartGridSentimentData'),
            smartSentimentDataAnswers: () => this._repository.query('analysis2/GetSmartGridSentimentData/answers'),
            smartGridThreadHighlights: (discussionId) => this._repository.query(`admin/discussions/${discussionId}/threadHighlights/full`),
            //client: (clientId) => this._repository.query(`clients/${clientId}`)
        };

        this.subscriptions = {
        };

        this.events = {
        };
    }

    /**
    * Returns data for smart banner
    */
    getSmartBannerData(requestData) {
        return this.queries.smartBannerData().add(requestData);
    }

    /**
    * Returns data for grid
    */
    getSmartGridData(requestData) {
        return this.queries.smartGridData().add(requestData);
    }

    /**
     * returns shrank comments for the grid with with only requested question answers
     */
    smartGridDataWithAnswers(requestData) {
        return this.queries.smartGridDataWithAnswers().add(requestData);
    }

    /**
     * Returns data for the External Survey table
     */
    getSmartTableData(requestData, topicId) {
        return this.queries.smartGridDataExternalSurvey(topicId).add(requestData);
    }

    /**
    * Returns data for the Word Cloud
    */
    getSmartWordCloudData(requestData) {
        return this.queries.smartWordCloudData().add(requestData);
    }

    /**
     * returns standard comments for the grid with Sentiment Data added to the threads
     */
    getSmartSentimentData(requestData) {
        return this.queries.smartSentimentData().add(requestData);
    }

    /**
     * returns shrank comments with Sentiment data for the grid with with only requested question answers
     */
    getSmartSentimentDataWithAnswers(requestData) {
        return this.queries.smartSentimentDataAnswers().add(requestData);
    }

    /**
     * returns thread highlights with extra data for Highlights browse
     */
    getSmartGridThreadHighlights(discussionId, requestData) {
        return this.queries.smartGridThreadHighlights(discussionId).add(requestData);
    }

}
import { Observable, Subscribable } from 'knockout';
import WCCEntity, { WCCEntityFieldMapper } from 'models/entity';
import { JSONTag, Tag } from 'models/tag';
import emojiPattern from 'patterns/emoji';

const emojiPatternGlobal = new RegExp(emojiPattern, 'gi');

const tagMapper: WCCEntityFieldMapper<JSONTag | undefined, Tag> = { map: jsonTag => new Tag(jsonTag), mapReverse: tag => tag.toJson() }

export interface JSONSurveyChoice {
    ProfileQuestionChoiceId: string
    ChoiceText: string
    ChoiceValue: number
    ChoiceImageFileId?: string
    ChoiceOrder: number

    IsDefault: boolean
    IsHidden: boolean
    IsExclusive: boolean
    IsPinned: boolean
    IsNA: boolean
    IsOther: boolean    

    DataTag?: JSONTag
    ThreadTag?: JSONTag
    VisibilityTag?: JSONTag

    InternalChoiceId?: string;
}

export default class SurveyChoice extends WCCEntity<JSONSurveyChoice> {
    randomIdx = _.random(1000);

    choiceId: Observable<string | undefined>
    text: Observable<string | undefined>
    value: Observable<number | undefined>
    imageFileId: Observable<string | undefined>
    order: Observable<number | undefined>
    
    isDefault: Observable<boolean>
    isHidden: Observable<boolean>
    isExclusive: Observable<boolean>
    isPinned: Observable<boolean>
    isNA: Observable<boolean>
    isOther: Observable<boolean>

    dataTag: Observable<Tag | undefined>
    threadTag: Observable<Tag | undefined>
    visibilityTag: Observable<Tag | undefined>

    displayText: Subscribable<string | undefined>
    textHasEmojisOnly: Subscribable<boolean>
    
    constructor(data?: JSONSurveyChoice) {
        super();

        this.choiceId = this.createField(data, 'ProfileQuestionChoiceId');
        this.text = this.createField(data, 'ChoiceText');
        this.value = this.createField(data, 'ChoiceValue');
        this.imageFileId = this.createField(data, 'ChoiceImageFileId');
        this.order = this.createField(data, 'ChoiceOrder');

        this.isDefault = this.createField(data, 'IsDefault', false);
        this.isHidden = this.createField(data, 'IsHidden', false);
        this.isExclusive = this.createField(data, 'IsExclusive', false);
        this.isPinned = this.createField(data, 'IsPinned', false);
        this.isNA = this.createField(data, 'IsNA', false);
        this.isOther = this.createField(data, 'IsOther', false);

        this.dataTag = this.createMappedField(data, 'DataTag', tagMapper);
        this.threadTag = this.createMappedField(data, 'ThreadTag', tagMapper);
        this.visibilityTag = this.createMappedField(data, 'VisibilityTag', tagMapper);

        this.displayText = this.text.pluck(text => system.generateStringWithBasicFormattingTags(text));
        this.textHasEmojisOnly = this.displayText.pluck(choiceText => choiceText.replace(emojiPatternGlobal, '').trim().length == 0, false);
    }
}
define('factories/threads', function () {
    const { TopicThread } = require('models/topicThread');

    function Factory() { }

    Factory.prototype.buildThread = function (jsonThread) {
        return new TopicThread(jsonThread);
    }

    return new Factory();
});
import { Disposable, isDisposable } from 'interfaces/disposable';
import { isInitializable } from 'interfaces/initializable';
import { Observable, PureComputed, SubscribableOrValue } from 'knockout';
import { StorageBucket } from 'models/storage/bucket';

const buckets = new Map<string, StorageBucket<any>>();
const timeouts = new Map<string, NodeJS.Timeout>();
const readOnly = ko.observable(false);

let debug = false;

interface StorageBucketCreationOptions {
    safe?: boolean
}

interface StorageBucketAcquisition<T> {
    bucket: StorageBucket<T>
    date: Date
}

function initContent<T>(content: T) {
    if (isInitializable(content))
        content.init();
}

function initObject<T>(bucket: StorageBucket<T>, content: T, func?: (item: T) => void) {
    if (bucket.isInitializing()) {
        bucket.markReady();
        bucket.content(content);

        if (func)
            func(content);
    }
}

function initComputed<T>(bucket: StorageBucket<T>, deps: Array<SubscribableOrValue<any>>, factory: (...deps: Array<any>) => T, func?: (item: T) => void, isUnmanaged = false) {
    if (bucket.isInitializing()) {
        let computed = ko.computed(() => {
            const args = deps.map(dep => ko.unwrap(dep));
            const valid = args.every(arg => arg != undefined);

            ko.ignoreDependencies(() => {
                let content = bucket.content();

                if (!isUnmanaged && isDisposable(content))
                    content.dispose();
            });

            if (valid) {
                const obj = factory(...args);

                if(func)
                    ko.ignoreDependencies(() => func(obj));

                bucket.content(obj);
            }
        });

        bucket.resources.push(computed);
        bucket.markReady();
    }
}

class StorageBucketBuilder<T> {
    constructor(private bucket: StorageBucket<T>, private override: boolean, private readOnly: Observable<boolean>, private options: StorageBucketCreationOptions) { }

    fromObject(content:T, func: (item: T) => void) {
        return this.setup(() => initObject(this.bucket, content, func));
    }

    from(content: T, func?: (item: T) => void) {
        return this.setup(() => initObject(this.bucket, content, func ?? initContent));
    }

    withEffects(effects: Array<Disposable>) {
        return {
            from: (content: T, func?: (item: T) => void) => {
                const result = this.from(content, func);
                effects.forEach(e => this.bucket.resources.push(e));

                return result;
            }
        }
    }

    dependsOn(...deps: Array<SubscribableOrValue<any>>) {
        return {
            from: (factory: (...deps: Array<any>) => T, func?: (item: T) => void, isUnmanaged?: boolean) => {
                return this.setup(() => initComputed(this.bucket, deps, factory, func ?? initContent, isUnmanaged))
            }
        }
    }

    private setup(func: () => void) {
        if (this.override && !this.bucket.isInitializing())
            this.bucket.dispose();

        if (this.readOnly() && !this.options.safe)
            this.readOnly.when(readonly => !readonly).then(() => func());
        else
            func();

        return this.bucket.content.unwrap();
    }
}

/**
 * ko based in-memory storage.
 */
export class StorageContext {
    buckets = ko.observableArray<StorageBucket<any>>();
    acquisitions = ko.observableArray<StorageBucketAcquisition<any>>();

    readOnly = readOnly
    disposed = false

    create<T>(name: string, override = false, disposeDelay?: number, options?: StorageBucketCreationOptions) {
        options = options ?? { safe: false }

        var bucket = this.getBucket(name, disposeDelay);

        return new StorageBucketBuilder<T>(bucket, override, this.readOnly, options);
    }

    get<T>(name: string) {
        return ko.ignoreDependencies(() => this.getBucket(name).content.unwrap() as PureComputed<T>);
    }

    getRaw<T>(name: string) {
        return ko.ignoreDependencies(() => this.getBucket(name).content as Observable<T>);
    }

    getValue<T>(name: string) {
        return ko.ignoreDependencies(() => this.getBucket(name).content() as T);
    }

    contains(name: string) {
        return buckets.get(name) != undefined;
    }

    remove<T>(nameOrContent: string | T) {
        const bucket = this.findBucket(nameOrContent);

        if (bucket)
            this.release(bucket);
    }

    dispose() {
        if (!this.disposed) {
            [...this.buckets()].forEach(bucket => this.releaseAll(bucket));

            this.buckets([]);
            this.disposed = true;
        }
    }

    private findBucket<T>(nameOrContent: string | T) {
        if (_.isString(nameOrContent))
            return this.buckets().find(bucket => bucket.name() === nameOrContent);
        else
            return this.buckets().find(bucket => bucket.content() === nameOrContent);
    }

    private getBucket(name: string, disposeDelay?: number) {
        if (this.disposed)
            throw "storage is disposed";

        var bucket = buckets.get(name);

        if (!bucket) {
            bucket = new StorageBucket(name, disposeDelay);
            buckets.set(name, bucket);
        }

        if (!_(this.buckets()).contains(bucket))
            this.buckets.push(bucket);

        this.acquire(bucket);

        if (debug)
            console.log('buckets', _(buckets).clone());

        return bucket;
    }

    private acquire<T>(bucket: StorageBucket<T>) {
        const date = new Date();

        this.acquisitions.push({ bucket, date });
        bucket.acquire();
    }

    private release<T>(bucket: StorageBucket<T>): boolean {
        if (this.buckets().includes(bucket)) {
            const acquisition = this.acquisitions().find(a => a.bucket == bucket);

            if (acquisition != undefined) {
                this.acquisitions.remove(acquisition);
                bucket.release();

                if (bucket.usersCount() === 0) {
                    var timeout = timeouts.get(bucket.name());

                    if (timeout != undefined)
                        clearTimeout(timeout);

                    if (bucket.disposeDelay() > 0) {
                        timeouts.set(bucket.name(), setTimeout(() => this.disposeBucketIfAllowed(bucket), bucket.disposeDelay()));
                    } else {
                        this.disposeBucketIfAllowed(bucket);
                    }
                }

                if (!this.acquisitions().some(a => a.bucket == bucket))
                    this.buckets.remove(bucket);

                if (debug)
                    console.log('buckets', _.clone(buckets));

                return true;
            }
        }

        return false;
    }

    private releaseAll<T>(bucket: StorageBucket<T>): void {
        let areAllAcquisitionsReleased = false;

        do {
            areAllAcquisitionsReleased = !this.release(bucket);
        } while (!areAllAcquisitionsReleased);
    }

    private disposeBucketIfAllowed<T>(bucket: StorageBucket<T>) {
        if (bucket.usersCount() === 0) {
            bucket.dispose();
            buckets.delete(bucket.name());
        }
    }
}
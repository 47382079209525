import { Subscribable } from 'knockout';
import SignalREventsManager from 'managers/signalR/events';
import { TopicThread } from 'models/topicThread';

export interface TopicThreadsAttachmentsUpdaterConfig {
    topicId: string
    threads: Subscribable<Array<TopicThread>>
}

export default class TopicThreadsAttachmentsUpdater {
    constructor(
        { topicId, threads }: TopicThreadsAttachmentsUpdaterConfig,
        signalREvents: SignalREventsManager
    ) {
        signalREvents.topicAttachmentsUpdates(topicId).onThreadAttachmentUpdated((topicId, jsonAttachment) => {
            threads().forEach(thread => {
                thread.attachments().forEach(attachment => {
                    if (attachment.attachmentId() == jsonAttachment.AttachmentId)
                        attachment.update(jsonAttachment);
                });
            });
        });
    }
}
import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyNumericOptions {
    PreText?: string
    PostText?: string
    Digits: number
    Decimals: number
    NumericControlType: number
    SliderNumericLabelType: number
    SliderMinValue: number
    SliderMaxValue: number
    SliderMinValueLabel?: string
    SliderMaxValueLabel?: string
}

export default class SurveyNumericOptions extends WCCEntity<JSONSurveyNumericOptions> {
    preText: Observable<string | undefined>
    postText: Observable<string | undefined>
    digits: Observable<number>
    decimals: Observable<number>
    numericControlType: Observable<number>
    sliderNumericLabelType: Observable<number>
    sliderMinValue: Observable<number>
    sliderMaxValue: Observable<number>
    sliderMinValueLabel: Observable<string | undefined>
    sliderMaxValueLabel: Observable<string | undefined>

    constructor(data: JSONSurveyNumericOptions) {
        super();

        this.preText = this.createField(data, 'PreText');
        this.postText = this.createField(data, 'PostText');
        this.digits = this.createField(data, 'Digits');
        this.decimals = this.createField(data, 'Decimals');
        this.numericControlType = this.createField(data, 'NumericControlType');
        this.sliderNumericLabelType = this.createField(data, 'SliderNumericLabelType');
        this.sliderMinValue = this.createField(data, 'SliderMinValue');
        this.sliderMaxValue = this.createField(data, 'SliderMaxValue');
        this.sliderMinValueLabel = this.createField(data, 'SliderMinValueLabel');
        this.sliderMaxValueLabel = this.createField(data, 'SliderMaxValueLabel');
    }
}
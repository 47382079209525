import attachmentsFactory from 'factories/attachments';
import { Observable, ObservableArray, Subscribable } from 'knockout';
import WCCAttachment, { JSONWCCAttachment } from './attachments/attachment';
import WCCFile from './attachments/file';
import WCCImage from './attachments/image';
import WCCLink from './attachments/link';
import WCCVideo from './attachments/video';
import WCCEntity from './entity';

export interface JSONTopicSlide {
    SlideId?: string
    TopicId?: string
    LinkedTopicId?: string
    Title?: string
    Type?: number
    Attachments?: Array<JSONWCCAttachment>  
}

export default class TopicSlide extends WCCEntity<JSONTopicSlide> {
    static publicTypes: Array<number> = [
        enums.apiWhiteboardSlideType.AllImages.value,
        enums.apiWhiteboardSlideType.HeatmapResults.value,
        enums.apiWhiteboardSlideType.Images.value,
        enums.apiWhiteboardSlideType.Link.value,
        enums.apiWhiteboardSlideType.SurveyResults.value,
        enums.apiWhiteboardSlideType.TopicIntro.value,
        enums.apiWhiteboardSlideType.Video.value
    ]

    content = ko.observable('');

    slideId: Observable<string | undefined>
    topicId: Observable<string | undefined>
    linkedTopicId: Observable<string | undefined>
    title: Observable<string | undefined>
    type: Observable<number | undefined>
    attachments: ObservableArray<WCCAttachment>  

    images: Subscribable<Array<WCCImage>>
    videos: Subscribable<Array<WCCVideo>>
    files: Subscribable<Array<WCCFile>>
    links: Subscribable<Array<WCCLink>>

    static create(data: JSONTopicSlide) {
        return new TopicSlide(data);
    }

    constructor(data: JSONTopicSlide) {
        super();

        this.slideId = this.createField(data, 'SlideId');
        this.topicId = this.createField(data, 'TopicId');
        this.linkedTopicId = this.createField(data, 'LinkedTopicId');
        this.title = this.createField(data, 'Title');
        this.type = this.createField(data, 'Type');
        this.attachments = this.createCollection(data, 'Attachments', a => attachmentsFactory.createAttachment(a));

        this.images = this.attachments.filter<WCCImage>(a => a.fileType() === enums.AttachmentTypes.Image.value);
        this.videos = this.attachments.filter<WCCVideo>(a => a.fileType() === enums.AttachmentTypes.Video.value);
        this.files = this.attachments.filter<WCCFile>(a => a.fileType() == enums.AttachmentTypes.File.value);
        this.links = this.attachments.filter<WCCLink>(a => a.fileType() === enums.AttachmentTypes.Link.value);
    }
}
import { Func } from "../interfaces/func";
import { Interval } from "../interfaces/interval";

export module CollectionsHelpers {
    export const dateSorterAsc = (l: Date, r: Date) => l.getTime() - r.getTime();
    export const dateSorterDesc = (l: Date, r: Date) => r.getTime() - l.getTime();

    export const nullableDateSorterAsc = (l?: Date, r?: Date) => (l?.getTime() ?? 0) - (r?.getTime() ?? 0)
    export const nullableDateSorterDesc = (l?: Date, r?: Date) => (r?.getTime() ?? 0) - (l?.getTime() ?? 0);

    export const intervalsSorterAsc = (l: Interval, r: Interval) => l.from.getTime() == r.from.getTime() ? l.to.getTime() - r.to.getTime() : l.from.getTime() - r.from.getTime();
    export const intervalsSorterDesc = (l: Interval, r: Interval) => l.from.getTime() == r.from.getTime() ? r.to.getTime() - l.to.getTime() : r.from.getTime() - l.from.getTime();

    export const stringSorterAsc = (l: string, r: string) => l.localeCompare(r);
    export const stringSorterDesc = (l: string, r: string) => -l.localeCompare(r);

    export const numberSorterAsc = (l: number, r: number) => l - r;
    export const numberSorterDesc = (l: number, r: number) => r - l;

    export function getInsertIdx<T>(sortedCollection: T[], target: T, comparator: Func<number, [l: T, r: T]>) {
        const result = sortedCollection.reduce((result, item, idx) => {
            if (result.lastItem == undefined || comparator(result.lastItem, target) <= 0)
                return { insertIdx: idx, lastItem: item }
            else
                return { insertIdx: result.insertIdx, lastItem: item }
        }, { insertIdx: 0, lastItem: <T | undefined>undefined });

        if (result.lastItem != undefined && comparator(result.lastItem, target) <= 0)
            return result.insertIdx + 1;

        return result.insertIdx;
    }

    /**
     * return true if collections are the same and false otherwise
     * @param arr collection 1
     * @param arr2 collection 2
     */
    export function compareCollections<V>(arr: Array<V>, arr2: Array<V>, comparator: Func<boolean, [V, V]> | null = null) {
        const compare = comparator ?? ((l, r) => l === r);

        if (arr.length == arr2.length)
            return arr.reduce((result, item, idx) => result && compare(item, arr2[idx]), true);

        return false;
    }
}
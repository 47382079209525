import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import events from 'managers/events';
import { apiCommentsRequestData } from 'models/apiCommentsRequestData';
import { apiRequestResult } from 'models/apiRequestResult';
import { apiThreadsSaveData } from 'models/apiThreadsSaveData';
import { JSONSurveyPageAnswers } from 'models/surveyPageAnswers';
import { JSONThreadWithVote } from 'models/threadWithVote';
import { JSONChatMessage, JSONTopicThread, TopicThread } from 'models/topicThread';
import WCCError from 'models/wccError';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

const { sitePrefix, sitePostfix, isStaging } = settings;

let threadDefaults = ko.pureComputed(() => ({
    TestOnly: isStaging,
    IsMobile: WCC.isMobile(),
    CanBeDeleted: true,
    CanBeEdited: true
}));

export class CommentsService extends BaseService {
    private repository = new Repository(endpoint);

    adminQueries = {
        thread: (threadId: string) => this.repository.query(`admin/threads/${threadId}`)
    }

    queries = {
        topicComments: (topicId: string) => this.repository.query<never, never, apiCommentsRequestData, JSONTopicThread, never, never, never>(`${sitePrefix}topics/${topicId}/comments`).addArgs(sitePostfix),
        commentReplies: (threadId: string) => this.repository.query<never, never, any, JSONTopicThread, never, never, never>(`threads/${threadId}/replies`),
        topicThreads: (topicId: string) => this.repository.query(`${sitePrefix}topics/${topicId}/threads`).addArgs(sitePostfix),
        thread: (threadId: string) => this.repository.query<never, JSONTopicThread, never, never, never, never, never>(`threads/${threadId}`).addArgs(sitePostfix),
        threads: () => this.repository.query<never, never, JSONTopicThread, apiRequestResult<string>, JSONTopicThread, apiRequestResult<string>, never>('threads'),
        v2Threads: () => this.repository.query<never, never, JSONTopicThread, JSONTopicThread, JSONTopicThread, JSONTopicThread, never>('v2/threads'),
        stagingThreads: (discussionId: string) => this.repository.query<never, never, never, never, never, never, string>(`staging/discussions/${discussionId}/threads`),
        multiplethreads: () => this.repository.query<never, never, apiThreadsSaveData, Array<apiRequestResult<string>>, never, never, never>('multiplethreads'),
        v2MultipleThreads: () => this.repository.query<never, never, apiThreadsSaveData, Array<JSONTopicThread>, never, never, never>('v2/multiplethreads'),
        threadVotes: (threadId: string) => this.repository.query(`threads/${threadId}/votes`),
        threadPinboard: (threadId: string) => this.repository.query<never, string, never, never, never, never, never>(`threads/${threadId}/editinfo`),
        threadEditInfo: (threadId: string) => this.repository.query(`threads/${threadId}/editinfo`),
        topicChatMessages: (topicId: string) => this.repository.query<never, JSONTopicThread, never, never, never, never, never>(`topics/${topicId}/chatmessages`),
        chatMessages: () => this.repository.query<never, never, JSONTopicThread, JSONChatMessage, JSONTopicThread, JSONChatMessage, never>('chatmessages'),
        surveyAnswers: (threadId: string) => this.repository.query(`profilepages/${threadId}/surveyanswers`),
        threadSurveyAnswers: (threadId: string) => this.repository.query<never, JSONSurveyPageAnswers, never, never, never, never, never>(`threads/${threadId}/surveyanswers`),
        markAsDone: (threadId: string) => this.repository.query(`threads/${threadId}/markAsDone`),
        makeResponseMandatory: (threadId: string) => this.repository.query(`threads/${threadId}/makeResponseMandatory`),
        makeResponseOptional: (threadId: string) => this.repository.query(`threads/${threadId}/makeResponseOptional`),
        clearMandatoryProbe: (threadId: string) => this.repository.query(`threads/${threadId}/clearMandatoryProbe`),
        heatMapPins: (topicId: string) => this.repository.query(`${sitePrefix}topics/${topicId}/heatMapPins`),
        pinComment: (threadId: string) => this.repository.query(`threads/${threadId}/pin`),
        generateTranscript: (topicId: string) => this.repository.query(`threads/${topicId}/generatetranscript`),
        refreshTranslation: (threadId: string) => this.repository.query(`threads/${threadId}/refreshtranslation`),
        reportAbuse: (threadId: string) => this.repository.query<never, never, never, never, never, apiRequestResult<boolean>>(`threads/${threadId}/reportAsAbuse`),
        clearReport: (threadId: string) => this.repository.query(`threads/${threadId}/clearReport`),
        saveTranslation: this.repository.query('threads/saveTranslation'),
        approve: (threadId: string) => this.repository.query<never, never, never, void, never, never, never>(`threads/${threadId}/approve`),
        unapprove: (threadId: string) => this.repository.query<never, never, never, void, never, never, never>(`threads/${threadId}/unapprove`),
        vote: () => this.repository.query<never, never, JSONThreadWithVote, apiRequestResult<string>, JSONThreadWithVote, apiRequestResult<string>, never>(`ideation/vote`),
        v2Vote: () => this.repository.query<never, never, JSONThreadWithVote, JSONTopicThread, JSONThreadWithVote, JSONTopicThread, never>(`v2/ideation/vote`),
        externalSurveyAnswers: (topicId: string) => this.repository.query(`topics/${topicId}/externalSurveyAnswers`)
    }

    subscriptions = {
        threadContentChange: this.signalRSubscription('TopicThreadContentChange'),
        discussionThreads: this.signalRSubscription('DiscussionThreads'),
        topicThreads: this.signalRSubscription('TopicThreads'),
        topicThreadVotes: this.signalRSubscription('TopicThreadVotes'),
        personThreads: this.signalRSubscription('PersonThreads'),
        moderatorMessages: this.signalRSubscription('LiveGroupModMessages'),
        participantMessages: this.signalRSubscription('LiveGroupParticipantMessages'),
        privateMessages: this.signalRSubscription('LiveGroupPrivateMessages')
    }

    events = {
        newComment: this.signalREvent('NewComment'),
        newDiscussionComment: this.signalREvent('NewDiscussionComment'),
        newTopicComment: this.signalREvent('NewTopicComment'),
        newPersonComment: this.signalREvent('NewPersonComment'),
        newReply: this.signalREvent('NewReply'),
        newDiscussionReply: this.signalREvent('NewDiscussionReply'),
        newTopicReply: this.signalREvent('NewTopicReply'),
        newPersonReply: this.signalREvent('NewPersonReply'),
        threadReportCleared: this.signalREvent('ThreadReportCleared'),
        threadReportUndone: this.signalREvent('ThreadReportUndone'),
        threadApproved: this.signalREvent('threadApproved'),
        threadDisapproved: this.signalREvent('ThreadDisapproved'),
        threadChanged: this.signalREvent('ThreadChanged'),
        discussionThreadChanged: this.signalREvent('DiscussionThreadChanged'),
        topicThreadChanged: this.signalREvent('TopicThreadChanged'),
        personThreadChanged: this.signalREvent('PersonThreadChanged'),
        threadDeleted: this.signalREvent('ThreadDeleted'),
        discussionThreadDeleted: this.signalREvent('DiscussionThreadDeleted'),
        topicThreadDeleted: this.signalREvent('TopicThreadDeleted'),
        personThreadDeleted: this.signalREvent('PersonThreadDeleted'),
        threadContentChanged: this.signalREvent('ThreadContentChanged'),
        newMessage: this.signalREvent('NewMessage'),
        messageDeleted: this.signalREvent('MessageDeleted'),
        messageChanged: this.signalREvent('MessageChanged'),
        tagAddedToThread: this.signalREvent('TagAddedToThread'),
        tagRemovedFromThread: this.signalREvent('TagRemovedFromThread'),
        threadResponseMandatorySettingChanged: this.signalREvent('ThreadResponseMandatorySettingChanged'),
        threadPinnedStateChanged: this.signalREvent('ThreadPinnedStateChanged')
    }

    messages = {
        newComment: this.signalRMessage('NewComment'),
        newReply: this.signalRMessage('NewReply'),
        threadReportCleared: this.signalRMessage('ThreadReportCleared'),
        threadReportUndone: this.signalRMessage('ThreadReportUndone'),
        threadApproved: this.signalRMessage('threadApproved'),
        threadDisapproved: this.signalRMessage('ThreadDisapproved'),
        threadChanged: this.signalRMessage('ThreadChanged'),
        threadDeleted: this.signalRMessage('ThreadDeleted')
    }

    /**
    * Returns topic comments by topicId. supports oData. uses POST request.
    * @param topicId - topic identity
    * @param oData - oData params
    * @param requestData - POST data
    */
    getTopicComments(topicId: string, oData: URLQueryData = '', requestData: any) {
        return this.queries.topicComments(topicId).addArgs(oData).toArrayPost(requestData);
    }

    /**
     * Returns topic replies by threadId. supports oData. uses POST request.
     * @param threadId - thread identity
     * @param oData - oData params
     * @param requestData - POST data
     */
    getCommentReplies(threadId: string, oData: URLQueryData = '', requestData: any) {
        return this.queries.commentReplies(threadId).addArgs(oData).toArrayPost(requestData);
    }

    /**
     * Returns topic threads(comments and replies) by topicId. supports oData.
     * @param topicId - topic identity
     * @param oData - oData params
     */
    getTopicThreads(topicId: string, oData: URLQueryData = '') {
        return this.queries.topicThreads(topicId).addArgs(oData).toArray();
    }

    /**
     * Returns the External Survey answer data (person, status, reward).
     * @param topicId - the topic (must be external survey type) we are loading the external survey answers for.
     */
    getTopicExternalSurveyAnswers(topicId: string) {
        return this.queries.externalSurveyAnswers(topicId).toArray();
    }

    /**
     * Returns topic thread by threadId.
     * @param threadId - thread identity
     */
    getThread(threadId: string) {
        return this.queries.thread(threadId).firstOrDefault();
    }

    async getSurveyStateFromThread(thread: TopicThread) {
        const threadId = thread.id();

        if (threadId == undefined)
            throw new Error(messages.InvalidRequest);

        const jsonAnswers = await this.queries.threadSurveyAnswers(threadId).toArray();
        const jsonAttachments = thread.attachments().map(a => a.toJson());

        jsonAnswers.forEach(jsonPageAnswer => {
            jsonPageAnswer.Questions.forEach(jsonQuestionAnswer => {
                jsonQuestionAnswer.IsSeen = true;
                jsonQuestionAnswer.IsContinuePressed = true;
            });
        });

        return <JSONTopicThread>{
            SurveyAnswers: jsonAnswers,
            Attachments: jsonAttachments,
        }
    }

    /**
     * Saves new topic thread
     * @param thread - thread data
     * @deprecated
     */
    saveThread(thread: JSONTopicThread) {
        return this.queries.threads().add(thread);
    }

    /**
     * Update topic thread
     * @param thread - thread data
     * @deprecated
     */
    updateThread(thread: JSONTopicThread) {
        return this.queries.threads().update(thread);
    }

    /**
    * Delete topic thread
    * @param threadId - thread identity
    * @deprecated
    */
    deleteThread(threadId: string) {
        return this.queries.thread(threadId).remove();
    }

    /**
     * Returns thread votes
     * @param threadId - thread identity
     */
    getThreadVotes(threadId: string) {
        return this.queries.threadVotes(threadId).toArray();
    }

    /**
    * Returns thread pinboard state
    * @param threadId - thread identity
    */
    getThreadPinboard(threadId: string) {
        return this.queries.threadPinboard(threadId).firstOrDefault();
    }

    /**
     * adds new chat message to topic
     * @param jsonMessage - message to save
     */
    async addChatMessage(jsonMessage: JSONTopicThread) {
        const jsonSavedMessage = await this.queries.chatMessages().add(jsonMessage);

        requestAnimationFrame(() => {
            events.chatMessageAdded.trigger(<string>jsonSavedMessage.DiscussionTopicId, jsonSavedMessage);
            events.commentAdded.trigger(<string>jsonSavedMessage.DiscussionTopicId, jsonSavedMessage);

            jsonSavedMessage.LinkedThreads?.forEach(jsonLinkedMessage => {
                events.chatMessageAdded.trigger(<string>jsonLinkedMessage.DiscussionTopicId, <JSONChatMessage>{ ...jsonLinkedMessage, Owner: jsonSavedMessage });
                events.commentAdded.trigger(<string>jsonLinkedMessage.DiscussionTopicId, jsonLinkedMessage);
            });
        });

        return jsonSavedMessage;
    }

    /**
     * updates provided chat message
     * @param message - message to update
     */
    async updateChatMessage(message: JSONTopicThread) {
        const jsonSavedMessage = await this.queries.chatMessages().update(message);

        requestAnimationFrame(() => {
            events.chatMessageUpdated.trigger(<string>jsonSavedMessage.DiscussionTopicId, <string>jsonSavedMessage.TopicThreadId, jsonSavedMessage);
            events.commentUpdated.trigger(<string>jsonSavedMessage.DiscussionTopicId, <string>jsonSavedMessage.TopicThreadId, jsonSavedMessage);

            jsonSavedMessage.LinkedThreads?.forEach(jsonLinkedMessage => {
                events.chatMessageUpdated.trigger(<string>jsonLinkedMessage.DiscussionTopicId, <string>jsonLinkedMessage.TopicThreadId, <JSONChatMessage>{ ...jsonLinkedMessage, Owner: jsonSavedMessage });
                events.commentUpdated.trigger(<string>jsonLinkedMessage.DiscussionTopicId, <string>jsonLinkedMessage.TopicThreadId, jsonLinkedMessage);
            });
        });

        return jsonSavedMessage;
    }

    /**
     * Returns heatmap topic pins filtered by given filter
     * @param topicId - topic identity
     * @param filter - browse filter
     */
    getHeatMapPins(topicId: string, filter: string) {
        return this.queries.heatMapPins(topicId).add(filter);
    }

    async vote(jsonThread: JSONTopicThread, vote: number) {
        jsonThread = { ...threadDefaults(), CreateDate: new Date(), ...jsonThread }

        const savedJsonThread = await this.queries.v2Vote().add({ Thread: jsonThread, Value: vote });

        await this.notifyAboutNewThread(savedJsonThread);

        return savedJsonThread;
    }

    async updateVote(jsonThread: JSONTopicThread, vote: number) {
        const savedJsonThread = await this.queries.v2Vote().update({ Thread: jsonThread, Value: vote });

        await this.notifyAboutThreadChange(savedJsonThread);

        return savedJsonThread;
    }

    async save(jsonThread: JSONTopicThread) {
        jsonThread = _({}).extend(threadDefaults(), { CreateDate: new Date() }, jsonThread);

        const savedJsonThread = await this.queries.v2Threads().add(jsonThread);
        await this.notifyAboutNewThread(savedJsonThread);

        return savedJsonThread;
    }

    async saveMultiple(jsonThreads: Array<JSONTopicThread>) {
        jsonThreads = jsonThreads.map(jsonThread => _({}).extend(threadDefaults(), { CreateDate: new Date() }, jsonThread));

        const savedJsonThreads = await this.queries.v2MultipleThreads().add({ Threads: jsonThreads });
        await Promise.all(savedJsonThreads.map(savedJsonThread => this.notifyAboutNewThread(savedJsonThread)));

        return savedJsonThreads;
    }

    async update(jsonThread: JSONTopicThread) {
        const savedJsonThread = await this.queries.v2Threads().update(jsonThread);
        await this.notifyAboutThreadChange(savedJsonThread);

        return savedJsonThread;
    }

    async remove(thread: TopicThread) {
        const allThreads = _.compact([thread, thread.owner()]);

        allThreads.forEach(th => {
            const threadId = th.id();
            const topicId = th.topicId();

            if (threadId == undefined || topicId == undefined)
                throw new Error(messages.InvalidRequest);
        });

        allThreads.forEach(th => events.deletingThread.trigger(<string>th.topicId(), <string>th.id()));

        try {
            await this.queries.thread(<string>thread.id()).important().remove();

            allThreads.forEach(th => {
                if (th.parentId() != undefined)
                    events.replyDeleted.trigger(<string>th.topicId(), <string>th.parentId(), <string>th.id());
                else
                    events.commentDeleted.trigger(<string>th.topicId(), <string>th.id());

                events.threadDeleted.trigger(<string>th.topicId(), <string>th.postedById(), <string>th.id());
            });
            
        } catch (ex) {
            allThreads.forEach(th => {
                events.failedToDeleteThread.trigger(<string>th.topicId(), <string>th.id());
            });
            
            throw ex;
        }
    }

    async markAsDone(topicId: string, commentId: string) {
        await this.queries.markAsDone(commentId).important().update();
        events.commentNotificationsRemoved.trigger(topicId, commentId);
    }

    async pin(topicId: string, commentId: string) {
        const response = await this.queries.pinComment(commentId).important().update();
        events.commentPinnedStateChanged.trigger(topicId, commentId, response.IsPinned);
    }

    async changeResponseSetting(comment: TopicThread, isMandatory: boolean) {
        const commentId = comment.id();
        const topicId = comment.topicId();

        if (commentId == undefined || topicId == undefined)
            throw new Error(messages.InvalidRequest);

        const query = isMandatory ? 'makeResponseMandatory' : 'makeResponseOptional';

        await this.queries[query](commentId).important().update();

        comment.isResponseMandatory(isMandatory);
        events.commentResponseIsMandatory.trigger(topicId, commentId, isMandatory);
    }

    async clearMandatoryProbe(comment: TopicThread) {
        const commentId = comment.id();
        const topicId = comment.topicId();

        if (commentId == undefined || topicId == undefined)
            throw new Error(messages.InvalidRequest);

        await this.queries.clearMandatoryProbe(commentId).important().update();

        comment.isResponseMandatory(false);
        events.commentResponseIsMandatory.trigger(topicId, commentId, false);
    }

    async refreshTranslation(threadId: string) {
        await this.queries.refreshTranslation(threadId).remove();
        events.threadTranslationIsRemoved.trigger(threadId);
    }

    async saveTranslation(jsonThread: JSONTopicThread) {
        const threadId = jsonThread.TopicThreadId;
        const content = jsonThread.TranslatedThreadContent;

        if (threadId == undefined || content == undefined)
            throw new Error(messages.InvalidRequest);

        await this.queries.saveTranslation.update(jsonThread);
        events.threadTranslationIsChanged.trigger(threadId, content);
    }

    async toggleAbuseReport(commentId: string) {
        const result = await this.queries.reportAbuse(commentId).update();
        events.threadReportedAsAbuse.trigger(commentId, result.Data);
    }

    async clearAbuseReport(commentId: string) {
        await this.queries.clearReport(commentId).update();
        events.threadClearedFromAbuseReport.trigger(commentId);
    }

    async approveThread(threadId: string) {
        await this.queries.approve(threadId).post();
        events.threadApproved.trigger(threadId);
    }

    async unapproveThread(threadId: string) {
        await this.queries.unapprove(threadId).post();
        events.threadUnapproved.trigger(threadId);
    }

    private async loadThreadById(threadId?: string) {
        if (threadId == undefined)
            throw new WCCError(messages.UnknownError);

        return await this.queries.thread(threadId).important().firstOrDefault();
    }

    private async notifyAboutNewThread(jsonThread: JSONTopicThread) {
        const topicId = jsonThread.DiscussionTopicId;
        const parentThreadId = jsonThread.ParentThreadId;

        if (topicId == undefined)
            throw new WCCError(messages.UnknownError);

        const isReply = _(parentThreadId).any();

        if (isReply)
            events.replyAdded.trigger(topicId, <string>parentThreadId, jsonThread);
        else
            events.commentAdded.trigger(topicId, jsonThread);
    }

    private async notifyAboutThreadChange(jsonThread: JSONTopicThread) {
        const threadId = jsonThread.TopicThreadId;
        const topicId = jsonThread.DiscussionTopicId;
        const parentThreadId = jsonThread.ParentThreadId;

        if (threadId == undefined || topicId == undefined)
            throw new WCCError(messages.UnknownError);

        const isReply = _(parentThreadId).any();

        if (isReply)
            events.replyUpdated.trigger(topicId, <string>parentThreadId, threadId, jsonThread);
        else
            events.commentUpdated.trigger(topicId, threadId, jsonThread);
    }
}
import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyWebsitesOptions {
    MinWebsites?: number
    MaxWebsites?: number
}

export default class SurveyWebsitesOptions extends WCCEntity<JSONSurveyWebsitesOptions> {
    minWebsites: Observable<number>
    maxWebsites: Observable<number>

    constructor(data: JSONSurveyWebsitesOptions) {
        super();

        this.minWebsites = this.createField(data, 'MinWebsites', 0);
        this.maxWebsites = this.createField(data, 'MaxWebsites', 0);
    }
}
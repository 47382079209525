enum CommunityAccess {
    none = 0,
    public = 1,
    membersonly = 2,
    termsandconditions = 3,
    forbidden = 4,
    singlepage = 5,
    twofactorauth = 6
}

export default CommunityAccess;
import {Observable, PureComputed} from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyAddressOptions {
    CityPlaceholder?: string
    StreetPlaceholder?: string
    PostCodePlaceholder?: string
    StatePlaceholder?: string
    CountryPlaceholder?: string
    AddressFormat: number,
    IsGooglePlacesSearchEnabled: boolean,
    CountriesRestricted?: string
}

export default class SurveyAddressOptions extends WCCEntity<JSONSurveyAddressOptions> {
    cityPlaceholder: Observable<string>
    streetPlaceholder: Observable<string>
    postCodePlaceholder: Observable<string>
    statePlaceholder: Observable<string>
    countryPlaceholder: Observable<string>
    addressFormat: Observable<number>
    isGooglePlacesSearchEnabled: Observable<boolean>
    countriesRestricted: Observable<string>
    
    countries: PureComputed<string[]>

    constructor(data: JSONSurveyAddressOptions) {
        super();

        this.streetPlaceholder = this.createField(data, 'StreetPlaceholder', 'Street');
        this.cityPlaceholder = this.createField(data, 'CityPlaceholder', 'City');
        this.postCodePlaceholder = this.createField(data, 'PostCodePlaceholder', 'Post Code');
        this.statePlaceholder = this.createField(data, 'StatePlaceholder', 'State');
        this.countryPlaceholder = this.createField(data, 'CountryPlaceholder', 'Country');
        this.addressFormat = this.createField(data, 'AddressFormat');
        this.isGooglePlacesSearchEnabled = this.createField(data, 'IsGooglePlacesSearchEnabled');
        this.countriesRestricted = this.createField(data, 'CountriesRestricted', '');
        
        this.countries = ko.pureComputed(() => {
            if (system.isNullOrEmpty(this.countriesRestricted())) return [];
            return this.countriesRestricted().split(',');
        });
    }
}
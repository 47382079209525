// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./blue.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./blue@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/* iCheck plugin Minimal skin, blue\r\n----------------------------------- */\r\n.icheckbox_minimal-blue,\r\n.iradio_minimal-blue {\r\n    display: inline-block;\r\n    *display: inline;\r\n    vertical-align: middle;\r\n    margin: 0;\r\n    padding: 0;\r\n    width: 18px;\r\n    height: 18px;\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n    border: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.icheckbox_minimal-blue {\r\n    background-position: 0 0;\r\n}\r\n    .icheckbox_minimal-blue.hover {\r\n        background-position: -20px 0;\r\n    }\r\n    .icheckbox_minimal-blue.checked {\r\n        background-position: -40px 0;\r\n    }\r\n    .icheckbox_minimal-blue.disabled {\r\n        background-position: -60px 0;\r\n        cursor: default;\r\n    }\r\n    .icheckbox_minimal-blue.checked.disabled {\r\n        background-position: -80px 0;\r\n    }\r\n\r\n.iradio_minimal-blue {\r\n    background-position: -100px 0;\r\n}\r\n    .iradio_minimal-blue.hover {\r\n        background-position: -120px 0;\r\n    }\r\n    .iradio_minimal-blue.checked {\r\n        background-position: -140px 0;\r\n    }\r\n    .iradio_minimal-blue.disabled {\r\n        background-position: -160px 0;\r\n        cursor: default;\r\n    }\r\n    .iradio_minimal-blue.checked.disabled {\r\n        background-position: -180px 0;\r\n    }\r\n\r\n/* HiDPI support */\r\n@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {\r\n    .icheckbox_minimal-blue,\r\n    .iradio_minimal-blue {\r\n        background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n        -webkit-background-size: 200px 20px;\r\n        background-size: 200px 20px;\r\n    }\r\n}", ""]);
// Exports
module.exports = exports;

import { Subscribable, SubscribableOrValue } from "knockout";
import { withEffects } from "mixins/withEffects";


interface AnimationConfig<T> {
    frameFactory: FrameFactory<T>

    state: T
    duration: number
    delay?: number

    isInitial: boolean
}

export interface AnimationFrameContext<T> {
    state: T
    progress: number
    duration: number

    isInitial: boolean
}

export type FrameFactory<T> = (context: AnimationFrameContext<T>) => boolean | void
export type DelayFactory<T> = (state: T) => number

class Animation<T> {
    private frameFactory: FrameFactory<T>

    private startTime?: number;
    private duration: number;
    private delay: number;
    private state: T

    private isStarted = false
    private isInitial: boolean
    private shouldAnimateFast = false;

    isDone = ko.observable(false);

    constructor(config: AnimationConfig<T>) {
        this.frameFactory = config.frameFactory;

        this.state = config.state;
        this.duration = config.duration;
        this.delay = config.delay ?? 0;

        this.isInitial = config.isInitial;
    }

    start() {
        if (!this.isStarted) {
            this.isStarted = true;
            requestAnimationFrame(now => this.animateFrame(now));
        }
    }

    finish() {
        this.shouldAnimateFast = true;
    }

    private animateFrame(now: number) {
        if (this.startTime == undefined)
            this.startTime = now;

        let start = this.startTime as number,
            change = now - start - this.delay,
            progress = change / this.duration * 100;

        if (change >= 0) {
            if (progress > 0 && this.shouldAnimateFast)
                progress = 100;

            if (progress > 100)
                progress = 100;

            let result = this.frameFactory({
                state: this.state,
                duration: this.duration,
                progress: progress,

                isInitial: this.isInitial
            });

            if (progress < 100 && result !== true) {
                requestAnimationFrame(now => this.animateFrame(now));
            } else {
                this.isDone(true);
            }
        } else {
            requestAnimationFrame(now => this.animateFrame(now))
        }
    }
}

export interface GenericAnimatorConfig<T> {
    state: SubscribableOrValue<T>
    duration?: SubscribableOrValue<number>
    delay?: DelayFactory<T>
    frame: FrameFactory<T>
}

export class GenericAnimator<T> {
    private effects = withEffects();

    isBusy: Subscribable<boolean>

    constructor(config: GenericAnimatorConfig<T>) {
        const nextAnimation = ko.observable<Animation<T>>();
        const currentAnimation = ko.observable<Animation<T>>();
        const currentAnimationIdx = ko.observable(0);

        const state = ko.pureComputed(() => ko.unwrap(config.state));
        const duration = ko.pureComputed(() => ko.unwrap(config.duration ?? 1000));

        const delayFactory = config.delay;

        this.isBusy = ko.pureComputed(() => nextAnimation() != undefined && currentAnimation() != undefined);

        this.effects.register((state, duration) => {
            if (duration > 0) {
                var animation = new Animation({
                    frameFactory: config.frame,

                    state: state,
                    duration: duration,
                    delay: delayFactory ? delayFactory(state) : undefined,

                    isInitial: currentAnimationIdx() === 0
                });

                nextAnimation(animation);
            }
        }, [state, duration]);

        this.effects.register((current, next) => {
            if (current == undefined && next != undefined) {
                nextAnimation(undefined);

                currentAnimation(next);
                currentAnimationIdx.inc();

                next.start();

                next.isDone.when().then(() => currentAnimation(undefined));
            }
        }, [currentAnimation, nextAnimation]);

        this.effects.register(() => () => {
            var animation = currentAnimation();

            if (animation != undefined)
                animation.finish();

            currentAnimation(undefined);
            nextAnimation(undefined);
        });
    }

    dispose() {
        this.effects.dispose();
    }
}
define('managers/statistics/activity',
    [
        'managers/base_es5',
        'models/activityStat'
    ],

    function (Base, Statistics) {
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');

        function Manager(activityId) {
            Base.call(this);

            this.activityId = ko.pureComputed(function () { return activityId; });

            this._datasource = this._register(new DataSource({ update: this._load.bind(this) }));

            this.statistics = this._datasource.data.mapNotNull(function (jsonStatistics) { return new Statistics(jsonStatistics) });
            this.loading = this._datasource.updating;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.update = function () {
            this._datasource.update();
        };

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            var update = this.update.bind(this);

            this._ctx.commentsService.subscriptions.topicThreads.subscribe(this.activityId());

            this._ctx.commentsService.events.newComment.on(update);
            this._ctx.commentsService.events.newReply.on(update);
            this._ctx.commentsService.events.threadDeleted.on(update);
        };

        Manager.prototype._load = function () {
            return this._ctx
                .statisticsService
                .queries
                .activityStat(this.activityId())
                .firstOrDefault();
        };

        return Manager;
    });
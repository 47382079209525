import { FilesHelpers } from 'helpers/files';
import { injectable } from 'inversify';
import WCCS3FileUploader from 'managers/uploads/s3/file';
import UserFile from 'models/userFile';
import userFile from 'models/userFile';
import WCCError from 'models/wccError';
import { IUserFilesDuplicator } from './iDuplicator';

@injectable()
export default class UserFilesDuplicator implements IUserFilesDuplicator {
    private uploader = new WCCS3FileUploader();

    async duplicate(userfile: userFile) {
        const link = userfile.link();

        try {
            var contentBlob = await FilesHelpers.loadAsBlob(<string>link);
            var newWCCFile = await this.uploader.upload({ name: <string>userfile.name(), blob: contentBlob });
            var newUserFile = newWCCFile.files()[0];

            return userfile.clone<UserFile>({
                UserFileId: newUserFile.userFileId(),
                Content: undefined,
                CreateDate: undefined,
                Hash: undefined,
                Link: newUserFile.link(),
                Token: newUserFile.token()
            });
        } catch (ex) {
            throw new WCCError(messages.AnErrorOccuredDuringUploadingFile);
        }
    }
}
import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import events from 'managers/events';
import { JSONWCCAttachment } from 'models/attachments/attachment';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import { IAttachmentsService } from './attachments.interfaces';

export class AttachmentsService extends BaseService implements IAttachmentsService {
    private repository = new Repository(endpoint);

    private addTopicAttachmentQuery = (topicId: string) => this.repository.postQuery<JSONWCCAttachment, JSONWCCAttachment>(`topics/${topicId}/attachments`);
    private updateTopicAttachmentQuery = (topicId: string) => this.repository.updateQuery<JSONWCCAttachment, JSONWCCAttachment>(`topics/${topicId}/attachments`);
    private removeTopicAttachmentQuery = (topicId: string, attachmentId: string) => this.repository.removeQuery<void>(`topics/${topicId}/attachments/${attachmentId}`);

    queries = {
        topicAttachments: (topicId: string) => this.repository.query<never, JSONWCCAttachment, JSONWCCAttachment, JSONWCCAttachment, never, never, never>(`topics/${topicId}/attachments`),
        topicAttachment: (topicId: string, attachmentId: string) => this.repository.query<never, JSONWCCAttachment | undefined, never, never, never, never, never>(`topics/${topicId}/attachments/${attachmentId}`),
        threadAttachment: (threadId: string, attachmentId: string) => this.repository.query(`threads/${threadId}/attachments/${attachmentId}`),
        userFileRotate: (userFileId: string) => this.repository.query(`userfiles/${userFileId}/rotate`),
        communityAttachments: () => this.repository.query('communities/attachments'),
        communityAttachment: (id: string) => this.repository.query(`communities/attachments/${id}`),
        accountImages: (type: string) => this.repository.query(`accounts/current/images/${type}`),
        surveyImages: (topicId: string) => this.repository.query<never, JSONWCCAttachment, never, never, never, never, never>(`topics/${topicId}/attachments/surveyimages`),
        pinboardImages: (topicId: string) => this.repository.query<never, JSONWCCAttachment, never, never, never, never, never>(`topics/${topicId}/attachments/pinboardimages`),
        publicSurveyImages: (topicId: string) => this.repository.query<never, JSONWCCAttachment, never, never, never, never, never>(`topics/${topicId}/attachments/publicsurveyimages`),
        generateTranscript: (threadId: string, attachmentId: string) => this.repository.query(`threads/${threadId}/attachments/${attachmentId}/generatetranscript`),
        transcript: (threadId: string, attachmentId: string) => this.repository.query(`threads/${threadId}/attachments/${attachmentId}/transcript`),
    }

    subscriptions = {
        threadAttachmentsChange: this.signalRSubscription('TopicThreadAttachmentsChange'),
        discussionTopicAttachments: this.signalRSubscription('DiscussionTopicAttachments'),
        discussionTopicForImageUpdates: this.signalRSubscription('DiscussionTopicForImageUpdates'),
        videoUpdates: this.signalRSubscription('VideoUpdates'),
        discussionVideoUpdates: this.signalRSubscription('DiscussionVideoUpdates')
    }

    events = {
        videoUpdated: this.signalREvent('VideoUpdated'),
        discussionVideoUpdated: this.signalREvent('DiscussionVideoUpdated'),
        topicAttachmentAdded: this.signalREvent('TopicAttachmentAdded'),
        topicAttachmentRemoved: this.signalREvent('TopicAttachmentRemoved'),
        imageUpdated: this.signalREvent('ImageUpdated'),
        threadAttachmentUpdated: this.signalREvent('ThreadAttachmentUpdated')
    }

    getTopicAttachments(topicId: string, oData: URLQueryData) {
        return this.queries.topicAttachments(topicId).addArgs(oData).toArray();
    }

    async addTopicAttachment(topicId: string, jsonAttachment: JSONWCCAttachment) {
        const jsonSaveAttachment = await this.addTopicAttachmentQuery(topicId).add(jsonAttachment);
        events.topicAttachmentAdded.trigger(topicId, jsonSaveAttachment);
    }

    async updateTopicAttachment(topicId: string, jsonAttachment: JSONWCCAttachment) {
        const jsonSaveAttachment = await this.updateTopicAttachmentQuery(topicId).update(jsonAttachment);
        events.topicAttachmentUpdated.trigger(topicId, jsonSaveAttachment);
    }

    getTopicAttachment(topicId: string, attachmentId: string) {
        return this.queries.topicAttachment(topicId, attachmentId).firstOrDefault();
    }

    async removeTopicAttachment(topicId: string, attachmentId: string) {
        await this.removeTopicAttachmentQuery(topicId, attachmentId).remove();
        events.topicAttachmentRemoved.trigger(topicId, attachmentId);
    }

    getThreadAttachment(threadId: string, attachmentId: string) {
        return this.queries.threadAttachment(threadId, attachmentId).firstOrDefault();
    }

    rotate(userFileId: string, angle: number) {
        return this.queries.userFileRotate(userFileId).add(angle);
    }

    addCommunityAttachment(jsonImage: JSONWCCAttachment) {
        return (this.queries.communityAttachments().add(jsonImage) as JQuery.Promise<JSONWCCAttachment>)
            .done(jsonImage => events.communityAttachmentAdded.trigger(jsonImage));
    }

    removeCommunityAttachment(id: string) {
        return (this.queries.communityAttachment(id).remove() as JQuery.Promise<void>)
            .done(() => events.communityAttachmentDeleted.trigger(id));
    }    
}

export let instance = new AttachmentsService();
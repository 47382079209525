import { Func } from 'interfaces/func';
import { Subscribable, SubscribableOrValue } from 'knockout';
import WCCAttachment from 'models/attachments/attachment';
import WCCImage from 'models/attachments/image';
import { CommunityWidgetVariableValue } from 'models/community/widgetVariable';
import { SimpleTopic } from 'models/simpleTopic';

function getInstructionsImageURL(attachments: Subscribable<Array<WCCAttachment>>) {
    return <Subscribable<CommunityWidgetVariableValue>>attachments
        .find<WCCImage>(a => a.fileType() == enums.AttachmentTypes.ActivityInstructionsImage.value)
        .pluck(image => image.hqUrl);
}

export interface TopicWidgetVariablesMapping {
    id: number
    types: Array<number>
    displayName: string

    topicMapper?: Func<SubscribableOrValue<CommunityWidgetVariableValue>, [topic: SimpleTopic]>
    topicAttachmentsMapper?: Func<SubscribableOrValue<CommunityWidgetVariableValue>, [attachments: Subscribable<Array<WCCAttachment>>]>
}

const topicWidgetVariablesMappings = new Array<TopicWidgetVariablesMapping>(
    { id: 0, displayName: 'Title', topicMapper: topic => <Subscribable<CommunityWidgetVariableValue>>topic.title, types: [enums.CommunityWidgetVariableTypes.String.value, enums.CommunityWidgetVariableTypes.Content.value] },
    { id: 1, displayName: 'QuickSell', topicMapper: topic => <Subscribable<CommunityWidgetVariableValue>>topic.quickSell, types: [enums.CommunityWidgetVariableTypes.String.value, enums.CommunityWidgetVariableTypes.Content.value] },

    { id: 7, displayName: 'ToDo Count', topicMapper: topic => <Subscribable<CommunityWidgetVariableValue>>topic.todoCountForUI, types: [enums.CommunityWidgetVariableTypes.Integer.value] },

    { id: 5, displayName: 'Has ToDo', topicMapper: topic => topic.todoCountForUI() > 0, types: [enums.CommunityWidgetVariableTypes.Boolean.value] },
    { id: 6, displayName: 'Should Be Answered', topicMapper: topic => topic.shouldBeAnswered(), types: [enums.CommunityWidgetVariableTypes.Boolean.value] },

    { id: 2, displayName: 'URL', topicMapper: topic => topic.getRoute(), types: [enums.CommunityWidgetVariableTypes.URL.value] },

    { id: 4, displayName: 'Instructions Image', topicAttachmentsMapper: getInstructionsImageURL, types: [enums.CommunityWidgetVariableTypes.Image.value] },
    { id: 3, displayName: 'Image', topicMapper: topic => <Subscribable<CommunityWidgetVariableValue>>topic.fullImageURL, types: [enums.CommunityWidgetVariableTypes.Image.value] });

export default topicWidgetVariablesMappings;
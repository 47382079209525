import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyEmailOptions {
    Width?: string
    EmailPlaceholder: string
}

export default class SurveyEmailOptions extends WCCEntity<JSONSurveyEmailOptions> {
    width: Observable<string | undefined>
    emailPlaceholder: Observable<string>

    constructor(data: JSONSurveyEmailOptions) {
        super();

        this.width = this.createField(data, 'Width');
        this.emailPlaceholder = this.createField(data, 'EmailPlaceholder', labels.EmailAddress);
    }
}
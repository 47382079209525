import attachmentsFactory from 'factories/attachments';
import ContentImage, { JSONContentImage } from 'models/contentImage';
import CommunityPageHistoryItem, { JSONCommunityPageHistoryItem } from 'models/community/page/history/item';
import WCCEntity from 'models/entity';
import WCCAttachment, { JSONWCCAttachment } from 'models/attachments/attachment';
import { Observable, ObservableArray, Subscribable } from 'knockout';

export interface JSONCommunityPage {
    communityPageId: string
    masterPageId?: string
    domainId?: string

    pageName: string
    pageUrl: string
    pageContent: string
    pageStyles: string

    pageAccess: number
    pageType: number
    version: number
    startUpOptions: number

    createDate: string
    editDate?: string
    lastPublishedOn?: string

    isFullscreen: boolean
    isHidden: boolean
    isDefault: boolean
    isSystem: boolean

    image?: JSONContentImage
    attachments: Array<JSONWCCAttachment>
    history: Array<JSONCommunityPageHistoryItem>
}

export default class CommunityPage extends WCCEntity<Partial<JSONCommunityPage>> {
    id: Observable<string | undefined>
    masterPageId: Observable<string | undefined>
    domainId: Observable<string | undefined>

    name: Observable<string | undefined>
    url: Observable<string | undefined>
    content: Observable<string | undefined>
    styles: Observable<string | undefined>

    access: Observable<number | undefined>
    type: Observable<number | undefined>
    version: Observable<number | undefined>
    startUpOptions: Observable<number | undefined>

    createDate: Observable<Date | undefined>
    editDate: Observable<Date | undefined>
    lastPublishedOn: Observable<Date | undefined>

    isFullscreen: Observable<boolean>
    isHidden: Observable<boolean>
    isDefault: Observable<boolean>
    isSystem: Observable<boolean>

    isCustom: Subscribable<boolean>
    isMaster: Subscribable<boolean>
    isBuiltIn: Subscribable<boolean>
    isPublished: Subscribable<boolean>

    image: Observable<ContentImage | undefined>

    attachments: ObservableArray<WCCAttachment>
    history: ObservableArray<CommunityPageHistoryItem>

    constructor(data?: Partial<JSONCommunityPage>) {
        super();

        this.id = this.createField(data, 'communityPageId');
        this.masterPageId = this.createField(data, 'masterPageId');
        this.domainId = this.createField(data, 'domainId');

        this.name = this.createField(data, 'pageName');
        this.url = this.createField(data, 'pageUrl').mapSingle(url => this.getPageUrl(url));
        this.content = this.createField(data, 'pageContent');
        this.styles = this.createField(data, 'pageStyles');

        this.access = this.createField(data, 'pageAccess').mapSingle(access => this.getPageAccess(access));
        this.type = this.createField(data, 'pageType');
        this.version = this.createField(data, 'version');
        this.startUpOptions = this.createField(data, 'startUpOptions');

        this.createDate = this.createMappedField(data, 'createDate', WCCEntity.dateMapper);
        this.editDate = this.createMappedField(data, 'editDate', WCCEntity.dateMapper);
        this.lastPublishedOn = this.createMappedField(data, 'lastPublishedOn', WCCEntity.dateMapper);

        this.isFullscreen = this.createField(data, 'isFullscreen', false);
        this.isHidden = this.createField(data, 'isHidden', false);
        this.isDefault = this.createField(data, 'isDefault', false);
        this.isSystem = this.createField(data, 'isSystem', false);
        this.isCustom = this.type.is(enums.CommunityPageType.Custom.value);
        this.isMaster = this.type.is(enums.CommunityPageType.Master.value);
        this.isBuiltIn = ko.pureComputed(() => !this.isSystem() && !this.isCustom() && !this.isMaster());
        this.isPublished = ko.pureComputed(() => this.checkIfPublished());

        this.image = this.createMappedField(data, 'image', jsonImage => new ContentImage(jsonImage));
        this.attachments = this.createCollection(data, 'attachments', jsonAttachment => attachmentsFactory.createAttachment(jsonAttachment));
        this.history = this.createCollection(data, 'history', jsonItem => new CommunityPageHistoryItem(jsonItem));
    }

    override toJson(forExport = false): Partial<JSONCommunityPage> {
        const json = super.toJson();

        if (forExport)
            return _(json).omit('createDate', 'editDate', 'lastPublishedOn', 'image', 'history');

        return json;
    }

    private getPageUrl(url?: string) {
        switch (this.type()) {
            case enums.CommunityPageType.SignUp.value:
                return 'signup';
            case enums.CommunityPageType.LogIn.value:
                return 'login';
            case enums.CommunityPageType.Activity.value:
                return 'topic/:discussionId/:topicId(/:view)';
            case enums.CommunityPageType.PasswordReset.value:
                return 'resetPassword/:emailCode';
            case enums.CommunityPageType.AccountActivation.value:
                return 'activateAccount/:emailCode';
            case enums.CommunityPageType.MemberProfile.value:
                return 'profile';
            case enums.CommunityPageType.BlogArticle.value:
                return 'blog/articles/:articleId';
            case enums.CommunityPageType.NoAccess.value:
                return 'noaccess';
            case enums.CommunityPageType.Unsubscribe.value:
                return 'unsubscribe(/:emailCode)';
            // I have removed the 2fa route as part of the other routes available
            // and instead it has its own single page route to segregate itself 
            // from other areas since those areas will be throwing 403
            //case enums.CommunityPageType.TwoFactorAuth.value:
            //    return 'account/twofactorauth';
            case enums.CommunityPageType.Referral.value:
                return 'refer';
            case enums.CommunityPageType.CommunityMessage.value:
                return 'message/community';
            default:
                return url;
        }
    }

    private getPageAccess(access?: number) {
        switch (this.type()) {
            case enums.CommunityPageType.SignUp.value:
                return enums.CommunityPageAccess.NotLoggedIn.value;

            case enums.CommunityPageType.LogIn.value:
                return enums.CommunityPageAccess.NotLoggedIn.value;

            case enums.CommunityPageType.Activity.value:
                return enums.CommunityPageAccess.LoggedInMembersOnly.value;

            case enums.CommunityPageType.PasswordReset.value:
                return enums.CommunityPageAccess.NotLoggedIn.value;

            case enums.CommunityPageType.AccountActivation.value:
                return enums.CommunityPageAccess.AlwaysVisible.value;

            case enums.CommunityPageType.MemberProfile.value:
                return enums.CommunityPageAccess.LoggedInMembersOnly.value;

            case enums.CommunityPageType.NoAccess.value:
                return enums.CommunityPageAccess.LoggedInMembersOnly.value;

            case enums.CommunityPageType.Unsubscribe.value:
                return enums.CommunityPageAccess.AlwaysVisible.value;

            // I have removed the 2fa route as part of the other routes available
            // and instead it has its own single page route to segregate itself
            // from other areas since those areas will be throwing 403
            //case enums.CommunityPageType.TwoFactorAuth.value:
            //    return enums.CommunityPageAccess.LoggedInMembersOnly.value;

            case enums.CommunityPageType.Referral.value:
                return enums.CommunityPageAccess.NotLoggedIn.value;

            case enums.CommunityPageType.CommunityMessage.value:
                return enums.CommunityPageAccess.AlwaysVisible.value;

            default:
                return access;
        }
    }

    private checkIfPublished() {
        const lastPublishedOn = this.lastPublishedOn();
        const editDate = this.editDate() ?? new Date(0, 0);

        return lastPublishedOn != undefined && editDate <= lastPublishedOn;
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { topicGroupsFactory } from 'factories/topicGroups';
import { inject, injectable } from 'inversify';
import { ObservableArray, Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import { CollectionDataSource } from 'managers/datasources/collection';
import events from 'managers/events';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import SimpleTopicManager from 'managers/simpleTopic';
import { WCCStorageManager } from 'managers/storage';
import TasksQueue from 'managers/tasksQueue';
import { EffectsContainer, withEffects } from 'mixins/withEffects';
import { JSONSimpleTopic, SimpleTopic } from 'models/simpleTopic';
import { SimpleTopicsGroup } from 'models/simpleTopicsGroup';
import { ServicesContext } from 'services/context';

const reactor = require('models/reactor');

const { isAdmin, isStaging, isCommunity, discussionId } = settings;
const forceDependedTopicsCheck = isStaging && !isCommunity();

class Updater {
    private storage: IWCCStorageManager;

    readonly effects = withEffects();

    readonly topicId: string
    readonly topic: Subscribable<SimpleTopic | undefined>

    constructor(topicId: string, searchString: string, peopleTagsIDs: Array<string>) {
        this.topicId = topicId;
        this.storage = this.effects.register(new WCCStorageManager());

        const simpleTopicManager = this.storage.get(SimpleTopicManager, { topicId, searchString, peopleTagsIDs });

        this.topic = simpleTopicManager.pluck('topic');
    }

    dispose() {
        this.effects.dispose();
    }
}

interface SimpleTopicsManagerConfig {
    discussionId: string
    searchString: string
    peopleTagsIDs: Array<string>
}

@injectable()
@storageDefaults(<Partial<SimpleTopicsManagerConfig>>{ discussionId, searchString: '', peopleTagsIDs: [] })
export default class SimpleTopicsManager {
    private tasks: TasksQueue
    private source: CollectionDataSource<JSONSimpleTopic, 'DiscussionTopicId'>

    private updaters: ObservableArray<Updater> = ko.observableArray();

    private topicsUserAddedResponseTo: ObservableArray<SimpleTopic> = ko.observableArray();
    private topicsUserRemovedResponseFrom: ObservableArray<SimpleTopic> = ko.observableArray();

    protected discussionId: string
    protected searchString: string
    protected peopleTagsIDs: Array<string>

    private userId: Subscribable<string | undefined>
    private isRegularUser: Subscribable<boolean>

    allGroups: Subscribable<Array<SimpleTopicsGroup>>    
    groups: Subscribable<Array<SimpleTopicsGroup>>
    topics: Subscribable<Array<SimpleTopic>>

    busy = ko.pureComputed(() => this.source.busy() || this.tasks.busy());
    loading = ko.pureComputed(() => this.source.busy() && this.source.requestsProcessed() === 0);
    updating = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() > 0);

    constructor(
        @inject(wccModules.managerConfig) { discussionId, searchString, peopleTagsIDs }: SimpleTopicsManagerConfig,
        @inject(wccModules.servicesContext) protected ctx: ServicesContext,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) protected effects: EffectsContainer
    ) {
        this.discussionId = discussionId;
        this.searchString = searchString;
        this.peopleTagsIDs = peopleTagsIDs;

        this.tasks = effects.register(new TasksQueue());

        this.source = effects.register(new CollectionDataSource({
            key: 'DiscussionTopicId',
            load: this.load.bind(this)
        }));

        this.allGroups = this.source.list
            .mapSingle(jsonTopics => this.buildGroups(jsonTopics))
            .sortBy(group => group.topicOrder());

        this.groups = this.allGroups.filter(group => group.topics().length > 0);
        this.topics = this.groups.pluck('topics').flatten().extend({ trackArrayChanges: true }); 

        const answeredTopics = this.topics.filter(topic => topic.isAnswered());
        const notAnsweredTopics = this.topics.filter(topic => !topic.isAnswered());

        const topicsToLoadDependedTopics = answeredTopics.intersection(this.topicsUserAddedResponseTo).extend({ trackArrayChanges: true });
        const topicsToRemoveDependedTopics = notAnsweredTopics.intersection(this.topicsUserRemovedResponseFrom).extend({ trackArrayChanges: true });

        const userManager = storage.get(CurrentUserManager, { discussionId });
        const user = userManager.pluck('person');

        this.userId = user.pluck('personId');
        this.isRegularUser = user.pluck('isRegular', false);

        ctx.commentsService.subscriptions.discussionThreads.subscribe(discussionId);

        ctx.commentsService.events.newDiscussionComment.on(this.onNewComment.bind(this));
        ctx.commentsService.events.newDiscussionReply.on(this.onNewReply.bind(this));
        ctx.commentsService.events.discussionThreadDeleted.on(this.onThreadDeleted.bind(this));
        ctx.discussionService.events.deletedNotificationEvent.on(this.onNotificationDeleted.bind(this));

        signalREvents.projectIdeations(discussionId).onStageChanged(this.onProjectIdeationStageChanged.bind(this));
        signalREvents.discussion(discussionId).onDiscussionChanged(() => this.update());

        effects.register([
            this.topics.onArrayChange({ remove: this.onTopicRemoved.bind(this) }),
            this.updaters.onArrayChange({ remove: updater => updater.dispose() }),

            topicsToLoadDependedTopics.onArrayChange({ add: this.loadDependedTopics.bind(this) }),
            topicsToRemoveDependedTopics.onArrayChange({ add: this.removeDependedTopics.bind(this) }),

            events.topicIsAnswered.on(this.onLocalTopicIsAnswered.bind(this)),

            events.commentAdded.on(this.onNewLocalComment.bind(this)),
            events.commentDeleted.on(this.onLocalCommentDeleted.bind(this)),
            events.commentNotificationsRemoved.on(this.onLocalCommentNotificationsRemoved.bind(this)),
            events.commentsNotificationsRemoved.on(this.onLocalCommentsNotificationsRemoved.bind(this)),
            events.discussionCommentsNotificationsRemoved.on(this.onLocalDiscussionCommentsNotificationsRemoved.bind(this)),

            events.replyAdded.on(this.onNewLocalReply.bind(this))
        ]);

        effects.register(userId => {
            if (userId)
                return this.ctx.peopleService.subscriptions.personNotifications.subscribe(userId);
        }, [this.userId]);

        effects.register([
            events.ideationStageChanged.on(this.onLocalIdeationStageChanged.bind(this))
        ]);

        effects.register(() => {
            const func = this.onNewLocalCommentOld.bind(this);
            reactor.on('CommentPosted', func);

            return () => reactor.off('CommentPosted', func);
        });

        effects.register(() => {
            const func = this.onLocalCommentDeletedOld.bind(this);
            reactor.on('commentDeleted', func)

            return () => reactor.off('commentDeleted', func)
        });
    }

    update() {
        this.source.load();
    }

    protected load() {    
        if (this.searchString.length > 0 || this.peopleTagsIDs.length > 0) {
            return this.ctx
                .topicsService
                .queries
                .filteredSimpleTopics(this.discussionId)
                .addArgs('$orderby=TopicOrder')
                .important()
                .toArrayPost({
                    searchString: this.searchString,
                    peopleTagsIDs: this.peopleTagsIDs
                });
        }

        return this.ctx
            .topicsService[isAdmin ? 'adminQueries' : 'queries']
            .simpleTopicsByDiscussionId(this.discussionId)
            .addArgs('$orderby=TopicOrder')
            .important()
            .toArray();    
    }

    private updateToDo(topicId: string) {
        this.withTopic(topicId, this.setupUpdater.bind(this));
    }

    private markTopicAsGotUserResponse(topicId: string) {
        if (this.isRegularUser() || forceDependedTopicsCheck)
            this.addTopicToCollection(this.topicsUserAddedResponseTo, topicId);
    }

    private markTopicAsLostUserResponse(topicId: string) {
        if (this.isRegularUser() || forceDependedTopicsCheck)
            this.addTopicToCollection(this.topicsUserRemovedResponseFrom, topicId);
    }

    private loadDependedTopics(topic: SimpleTopic) {
        const topicId = topic.id();

        if (topicId != undefined) {
            this.tasks.run(async () => {
                const jsonTopics = await this.ctx.topicsService.queries.dependedTopics(topicId).important().toArray();
                const topics = topicGroupsFactory.buildTopics(jsonTopics) as Array<SimpleTopic>;

                topics.forEach(topic => {
                    const group = this.allGroups().find(group => group.id() === topic.groupId());

                    if (group != undefined) {
                        group.addTopic(topic);
                        events.topicIsAvailable.trigger(topic.id() ?? '');
                    }
                });
            });
        }
    }

    private removeDependedTopics() {
        this.tasks.run(() => this.ctx.topicsService.queries.ids(this.discussionId).important().toArray() as JQuery.Promise<Array<string>>)
            .then(ids => {
                this.topics()
                    .filter(topic => !_(ids).contains(topic.id()))
                    .forEach(topic => {
                        var group = this.allGroups().find(group => group.id() === topic.groupId());

                        if (group != undefined)
                            group.topics.remove(topic);
                    });
            });
    }

    private onTopicRemoved(topic: SimpleTopic) {
        this.updaters.remove(item => item.topicId === topic.id());

        this.topicsUserAddedResponseTo.remove(topic);
        this.topicsUserRemovedResponseFrom.remove(topic);
    }

    private onNewComment(threadId: string, topicId: string) {
        this.updateToDo(topicId);
    }

    private onNewReply(threadId: string, parentThreadId: string, topicId: string) {
        this.updateToDo(topicId);
    }

    private onThreadDeleted(threadId: string, parentThreadId: string, personId: string, topicId: string) {
        this.updateToDo(topicId);

        if (personId === this.userId())
            this.markTopicAsLostUserResponse(topicId);
    }

    private onNotificationDeleted(topicId: string, threadId: string, personId: string) {
        this.updateToDo(topicId);
    }

    private onLocalTopicIsAnswered(topicId: string) {
        this.onNewLocalComment(topicId);
    }

    private onNewLocalComment(topicId: string) {
        this.updateToDo(topicId);
        this.markTopicAsGotUserResponse(topicId);
    }

    private onLocalCommentDeleted(topicId: string) {
        this.updateToDo(topicId);
        this.markTopicAsLostUserResponse(topicId);
    }

    private onNewLocalCommentOld(args: [string, ...any]) {
        var topicId = args[0];

        this.updateToDo(topicId);
        this.markTopicAsGotUserResponse(topicId);
    }

    private onLocalCommentDeletedOld(thread: any) {
        var topicId = thread.discussionTopicId;

        this.updateToDo(topicId);
        this.markTopicAsLostUserResponse(topicId);
    }

    private onLocalCommentNotificationsRemoved(topicId: string) {
        this.updateToDo(topicId);
    }

    private onLocalCommentsNotificationsRemoved(topicId: string) {
        this.updateToDo(topicId);
    }

    private onLocalDiscussionCommentsNotificationsRemoved() {
        this.topics().forEach(topic => this.updateToDo(<string>topic.id()));
    }

    private onNewLocalReply(topicId: string, commentId: string) {
        this.updateToDo(topicId);
    }

    private onProjectIdeationStageChanged(projectId: string, topicId: string, stage: number, isStaging: boolean) {
        if (settings.isStaging == isStaging)
            this.withTopic(topicId, topic => topic.ideationStage(stage));
    }

    private onLocalIdeationStageChanged(topicId: string, stage: number) {
        this.withTopic(topicId, topic => topic.ideationStage(stage));
    }

    private setupUpdater(topic: SimpleTopic) {
        const topicId = topic.id();

        let updater = this.updaters().find(updater => updater.topicId === topicId);

        if (updater == undefined && topicId != undefined) {
            updater = this.effects.register(new Updater(topicId, this.searchString, this.peopleTagsIDs));

            const data = updater.topic.mapNotNull(topic => topic.toJson());

            updater.effects.register(data => {
                if (data != undefined)
                    topic.update(data);
            }, [data]);

            this.updaters.push(updater);
        }
    }

    private addTopicToCollection(collection: ObservableArray<SimpleTopic>, topicId: string) {
        this.withTopic(topicId, topic => {
            const alreadyAdded = collection().includes(topic);

            if (!alreadyAdded)
                collection.push(topic);
        });
    }

    private withTopic(topicId: string, func: (topic: SimpleTopic) => void) {
        const topic = this.topics().find(topic => topic.id() === topicId);

        if (topic !== undefined)
            func(topic);
    }

    private buildGroups(jsonTopics: Array<JSONSimpleTopic>) {
        return jsonTopics.length > 0 ?
            ko.ignoreDependencies(() => topicGroupsFactory.buildGroups(jsonTopics)) :
            [];
    }
}
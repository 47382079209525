export class FileIconHelper {
    private static iconClasses = {
        excel: ['xls', 'xlsx', 'xlt', 'xltx', 'xlm', 'xlmx', 'xlsm', 'csv'],
        word: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb', 'rtf', 'txt'],
        ppt: ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'ppsx', 'ppsm', 'sldx', 'sldm'],
        pdf: ['pdf'],
        image: ['jpg', 'jpeg', 'png', 'bmp', 'gif']
    };

    static getIconClass (fileExtension: string) {
        let fileIconClass = 'wcc-file-icon wcc-file-icon-';
        let iconClass = !fileExtension
            ? 'file'
            : _.find(FileIconHelper.iconClasses, (extensions: string[], key: string) => {
                if (extensions.indexOf(fileExtension) > -1) {
                    fileIconClass += key;
                    return true;
                }
                return false;
            });
        if (!iconClass) {
            fileIconClass += 'file';
        }

        return fileIconClass;
    }
}
import { ObservableArray } from 'knockout';
import SurveyStatisticsQuestion, { JSONSurveyStatisticsQuestion } from './question';
import SurveyStatisticsRankQuestionAnswer, { JSONSurveyStatisticsRankQuestionAnswer } from './rankAnswer';

export interface JSONSurveyStatisticsRankQuestion extends JSONSurveyStatisticsQuestion {
    ProfileAnswers: Array<JSONSurveyStatisticsRankQuestionAnswer>
}

export default class SurveyStatisticsRankQuestion extends SurveyStatisticsQuestion<SurveyStatisticsRankQuestionAnswer, JSONSurveyStatisticsRankQuestion> {
    answers: ObservableArray<SurveyStatisticsRankQuestionAnswer>

    constructor(data: JSONSurveyStatisticsRankQuestion) {
        super(data);

        this.answers = this.createCollection(data, 'ProfileAnswers', {
            key: 'questionChoiceId',
            map: jsonAnswer => new SurveyStatisticsRankQuestionAnswer(jsonAnswer),
            mapReverse: answer => answer.toJson(),
            update: (answer, newAnswer) => answer.update(newAnswer.toJson())
        });
    }
}
define('managers/community/attachments',
    [
        'managers/base_es5',
        'managers/datasources/collection'        
    ],

    function (Base, { CollectionDataSource: DataSource }) {
        const { default: events } = require('managers/events');
        const attachmentsFactory = require('factories/attachments').default;

        function Manager() {
            Base.call(this);

            this._source = this._register(new DataSource({
                key: 'attachmentId',
                load: () => this._load(),
                mapper: attachmentsFactory.createAttachment
            }));

            this.attachments = this._source.list;

            this.busy = this._source.busy;
            this.loading = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() === 0 }, this);
            this.updating = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() > 0 }, this);
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._disposables.register([
                events.communityAttachmentAdded.on(this._onLocalAttachmentAdded.bind(this)),
                events.communityAttachmentDeleted.on(this._onLocalAttachmentRemoved.bind(this))
            ]);
        };

        Manager.prototype._load = function () {
            return this._ctx
                .attachmentsService
                .queries
                .communityAttachments()
                .toArray();
        };

        Manager.prototype._onLocalAttachmentAdded = function (jsonAttachment) {
            this._source.add(jsonAttachment);
        };

        Manager.prototype._onLocalAttachmentRemoved = function (id) {
            this._source.remove(function (attachment) { return attachment.attachmentId() === id });
        };

        return Manager;
    });
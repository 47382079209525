define('managers/blogs',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const { CollectionDataSource: DataSource } = require('managers/datasources/collection');
        const Blog = require('models/topic').Topic;

        function Manager(discussionId) {
            Base.call(this);

            this.discussionId = ko.pureComputed(function () { return discussionId });

            this._source = this._register(new DataSource({
                load: this._load.bind(this),
                mapper: function (jsonBlog) { return new Blog(jsonBlog) }
            }));

            this.blogs = this._source.list;

            this.busy = this._source.busy;
            this.loading = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() === 0 }, this);
            this.updating = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() > 0 }, this);
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype._load = function () {
            return this._ctx
                .blogsService
                .queries
                .blogs(this.discussionId())
                .important()
                .toArray();
        };

        return Manager;
    });
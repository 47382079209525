import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { PureComputed } from 'knockout';
import events from 'managers/events';
import { EffectsContainer } from 'mixins/withEffects';
import { JSONPerson, Person } from 'models/person';
import { ServicesContext } from 'services/context';
import { EntityDataSource } from './datasources/entity';

const { loginToken, unknownUserMode, discussionId } = settings;

interface CurrentUserManagerConfig {
    discussionId: string
}

@injectable()
@storageDefaults(<Partial<CurrentUserManagerConfig>>{ discussionId })
export class CurrentUserManager {
    private source: EntityDataSource<JSONPerson>
    private isNoDataMode: PureComputed<boolean>

    person: PureComputed<Person | undefined>
    loading: PureComputed<boolean>

    constructor(
        @inject(wccModules.managerConfig) { discussionId }: CurrentUserManagerConfig,
        @inject(wccModules.servicesContext) ctx: ServicesContext,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.source = effects.register(new EntityDataSource<JSONPerson>({
            update: () => {
                if (!this.isNoDataMode()) {
                    return ctx.peopleService.queries.currentParticipant(discussionId).addArgs({ realAnonymity: true }).firstOrDefault();
                } else {
                    return $.Deferred().resolve(undefined).promise();
                }
            }
        }));

        const person = this.source.data.toEntity(
            jsonPerson => new Person(jsonPerson),
            (person, jsonPerson) => person.update(jsonPerson));

        this.isNoDataMode = ko.pureComputed(() => loginToken() == undefined || unknownUserMode());

        this.person = ko.pureComputed(() => loginToken() != undefined ? person() : undefined).extend({ notifyIfChanged: true });
        this.loading = ko.pureComputed(() => !this.isNoDataMode() && this.source.updating());

        effects.register([
            this.isNoDataMode.subscribe(() => this.source.update()),

            events.personStatusChanged.on(this.onPersonStatusChanged.bind(this)),
            events.personAgreedToTerms.on(this.onPersonAgreedToTerms.bind(this)),
            events.personAvatarChanged.on(this.onPersonAvatarChanged.bind(this)),
            events.personChanged.on(this.onPersonChanged.bind(this))
        ]);
    }   

    update() {
        this.source.update();
    }

    private onPersonAgreedToTerms(personId: string) {
        this.person.invokeNotNull(person => {
            if (person.personId() === personId)
                person.agreedToTermsAndConditions(true);
        });
    }

    private onPersonStatusChanged(personId: string, status: number) {
        this.person.invokeNotNull(person => {
            if (person.personId() === personId)
                person.status(status);
        });
    }

    private onPersonAvatarChanged(avatarURL: string) {
        this.source.set({
            ...this.source.data(),

            ImageURL: avatarURL
        });
    }

    private onPersonChanged() {
        this.source.update();
    }
}
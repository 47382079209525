export const koData: { [key: string]: any } = {}

export function getKOData<T>(key: string, defaultValue?: T) {
    if (koData[key] != "undefined") {
        return koData[key];
    } else {
        return defaultValue;
    }
}

const config = (window as any).__wccconfig ?? {};
_(koData).extend(config.koData);
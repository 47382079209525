import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class MailingListService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            mailingList: () => this._repository.fastQuery('accounts/mailingList'),
            mailingListCleanUp: () => this._repository.fastQuery('accounts/mailingList/cleanup'),
            mailingListStat: () => this._repository.fastQuery('accounts/mailingList/statistics'),
            mailingListSendCount: () => this._repository.fastQuery('accounts/mailingList/send/count'),
            mailingListSend: () => this._repository.fastQuery('accounts/mailingList/send'),
            mailingListCancelSend: () => this._repository.query('accounts/mailingList/cancelSend'),
            mailingListStartScreener: (token) => this._repository.fastQuery(`accounts/mailingList/${token}/startscreener`)
        };
    }

    getMailingListStat() {
        return this.queries.mailingListStat().firstOrDefault();
    }

    cancelMailingListSend() {
        return this.queries.mailingListCancelSend().firstOrDefault();
    }

    /**
     * Saves imported records
     */
    saveImportedData(importedData) {
        return this.queries.mailingList().add(importedData);
    }

    /**
     * Gets the count of people this will send to based on the current filter
     */
    getSendCount(emailsData) {
        return this.queries.mailingListSendCount().firstOrDefaultPost(emailsData);
    }

    /**
     * Initiates emails send procedure
     */
    sendEmails(emailsData) {
        return this.queries.mailingListSend().add(emailsData);
    }

    /**
     * Initiates emails send procedure
     */
    startScreener(token) {
        return this.queries.mailingListStartScreener(token).add();
    }

    /**
     * Clean mailing list up
     */
    cleanUp(filterData) {
        return this.queries.mailingListCleanUp().add(filterData);
    }
}
import { Observable, Subscribable } from 'knockout';
import WCCEntity from '../entity';
import { JSONOnlineUserSlideDetails, OnlineUserSlideDetails } from './onlineUserSlideDetails';

export interface JSONOnlineUserSession {
    SessionId: string
    DiscussionId: string
    TopicId?: string
    TypingAtTopicId?: string
    PersonId: string
    FirstName: string
    LastName: string
    FullName: string
    ImageURL: string
    MemberStatus: number
    IsStaging: boolean

    Slide?: JSONOnlineUserSlideDetails
}

export default class OnlineUserSession extends WCCEntity<JSONOnlineUserSession> {
    sessionId: Observable<string>
    discussionId: Observable<string>
    topicId: Observable<string | undefined>
    typingAtTopicId: Observable<string | undefined>
    personId: Observable<string>
    firstName: Observable<string>
    lastName: Observable<string>
    fullName: Observable<string>
    imageURL: Observable<string>
    memberStatus: Observable<number>

    isStaging: Observable<boolean>

    slide: Observable<OnlineUserSlideDetails | undefined>

    isModerator: Subscribable<boolean>
    isObserver: Subscribable<boolean>
    isMember: Subscribable<boolean>    

    constructor(data: JSONOnlineUserSession) {
        super();

        this.sessionId = this.createField(data, 'SessionId');
        this.discussionId = this.createField(data, 'DiscussionId');
        this.topicId = this.createField(data, 'TopicId');
        this.typingAtTopicId = this.createField(data, 'TypingAtTopicId');
        this.personId = this.createField(data, 'PersonId');
        this.firstName = this.createField(data, 'FirstName');
        this.lastName = this.createField(data, 'LastName');
        this.fullName = this.createField(data, 'FullName');
        this.imageURL = this.createField(data, 'ImageURL');
        this.memberStatus = this.createField(data, 'MemberStatus');

        this.isStaging = this.createField(data, 'IsStaging');

        this.slide = this.createMappedField(data, 'Slide', OnlineUserSlideDetails.create);

        this.isModerator = this.memberStatus.is(enums.MembershipStatusTypes.Moderator.value);
        this.isObserver = this.memberStatus.is(enums.MembershipStatusTypes.Observer.value);
        this.isMember = this.memberStatus.is(enums.MembershipStatusTypes.Member.value);
    }
}
import { Observable, Subscribable } from "knockout";
import WCCEntity from "./entity";

export interface JSONInterval {
    From: string
    To: string
}

export class Interval extends WCCEntity<JSONInterval> {
    id: Subscribable<string>

    from: Observable<Date>
    to: Observable<Date>

    constructor(data: JSONInterval) {
        super();

        this.from = this.createMappedField(data, 'From', WCCEntity.dateMapperNotNull);
        this.to = this.createMappedField(data, 'To', WCCEntity.dateMapperNotNull);

        this.id = ko.pureComputed(() => `${this.from().getTime()}_${this.to().getTime()}`);
    }
}
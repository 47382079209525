import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyTextOptions {
    Width?: string
    Height?: string
    HintPlaceholder?: string
    LimitType: number
    MinLimit?: number
    MaxLimit?: number
}

export default class SurveyTextOptions extends WCCEntity<JSONSurveyTextOptions> {
    width: Observable<string | undefined>
    height: Observable<string | undefined>
    hintPlaceholder: Observable<string | undefined>
    limitType: Observable<number>
    minLimit: Observable<number | undefined>
    maxLimit: Observable<number | undefined>

    constructor(data: JSONSurveyTextOptions) {
        super();

        this.width = this.createField(data, 'Width');
        this.height = this.createField(data, 'Height');
        this.hintPlaceholder = this.createField(data, 'HintPlaceholder');
        this.limitType = this.createField(data, 'LimitType');
        this.minLimit = this.createField(data, 'MinLimit');
        this.maxLimit = this.createField(data, 'MaxLimit');
    }
}
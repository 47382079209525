import { wccModules } from 'enums/wccModules';
import { BlobWrapper } from 'interfaces/blobWrapper';
import { inject, injectable } from 'inversify';
import { ObservableArray } from 'knockout';
import WCCS3ImageUploader from 'managers/uploads/s3/image';
import WCCUpload from 'models/upload';

export interface TopicAttachmentsUploadsManagerConfig {
    topicId: string
}

@injectable()
export default class TopicAttachmentsUploadsManager {
    private imageUploadsManager = new WCCS3ImageUploader();

    uploads: ObservableArray<WCCUpload>

    constructor(
        @inject(wccModules.managerConfig) config: TopicAttachmentsUploadsManagerConfig,
    ) {
        this.uploads = this.imageUploadsManager.uploads;
    }

    uploadImage(fileOrBlob: File | BlobWrapper) {
        return this.imageUploadsManager.upload(fileOrBlob);
    }
}
import { Observable } from 'knockout';
import { withEffects } from 'mixins/withEffects';

type UpdateFunc<T> = () => T | PromiseLike<T>
type SaveFunc<T> = (data: T) => void | PromiseLike<void>

interface EntityDataSouceConfig<T> {
    update: UpdateFunc<T | undefined>
    save?: SaveFunc<T>
    isEmpty?: boolean
    deferred?: boolean
}

export class EntityDataSource<T> {
    private effects = withEffects();

    private contentToSave = ko.observable<T>();

    private isInitialized = ko.observable(false);
    private isUpdateRequested = ko.observable(false);
    private isUpdating = ko.observable(false)
    private isSaving = ko.observable(false)
    private isProcessing = ko.pureComputed(() => this.isUpdating() || this.isSaving());

    private updateFunc: UpdateFunc<T | undefined>
    private saveFunc?: SaveFunc<T>

    private loadOnInit: boolean
    private isDeferred: boolean

    requestsProcessed = ko.observable(0);

    data: Observable<T | undefined> = ko.observable();
    fromServer = ko.observable(false);

    updating = ko.pureComputed(() => !this.isInitialized() || this.isUpdating() || this.isUpdateRequested());
    saving = ko.pureComputed(() => this.isSaving() || this.contentToSave() != undefined);

    busy = ko.pureComputed(() => this.updating() || this.saving());

    constructor(config: EntityDataSouceConfig<T>) {
        this.updateFunc = config.update;
        this.saveFunc = config.save;

        this.loadOnInit = !(config.isEmpty ?? false);
        this.isDeferred = config.deferred ?? false;
    }

    set(value: T | undefined) {
        this.fromServer(true);
        this.data(value);
    }

    setPartial(value: Partial<T>) {
        const data = this.data();

        if (data == undefined)
            this.update();
        else
            this.set({ ...data, ...value });
    }

    update() {
        this.isUpdateRequested(true);

        if (!this.isInitialized())
            this.isInitialized(true);
    }

    save(content: T) {
        this.contentToSave(content);
    }

    init() {
        this.effects.register(async (isUpdateRequested, isProcessing) => {
            if (isUpdateRequested && !isProcessing) {
                this.isUpdating(true);
                this.isUpdateRequested(false);

                try {
                    const jsonContent = await Promise.resolve(this.updateFunc());

                    this.fromServer(true);
                    this.data(jsonContent);
                    this.requestsProcessed.inc();
                } catch (ex) {
                    console.error('failed to update', ex, this.updateFunc)
                } finally {
                    this.isUpdating(false)
                }
            }
        }, [this.isUpdateRequested, this.isProcessing]);

        this.effects.register(async (saveFunc, content, isProcessing) => {
            if (saveFunc != undefined && content != undefined && !isProcessing) {
                this.isSaving(true);
                this.contentToSave(undefined);

                try {
                    await Promise.resolve(saveFunc(content));

                    this.fromServer(false);
                    this.data(content);
                } catch (err) {
                    console.error('failed to save', err, saveFunc)
                } finally {
                    this.isSaving(false);
                }
            }
        }, [this.saveFunc, this.contentToSave, this.isProcessing]);

        if (!this.isDeferred) {
            if (this.loadOnInit)
                this.update();

            this.isInitialized(true);
        }            
    }

    dispose() {
        this.effects.dispose();
    }
}
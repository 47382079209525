import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyNicknameOptions {
    Width?: string
    NicknamePlaceholder: string
}

export default class SurveyNicknameOptions extends WCCEntity<JSONSurveyNicknameOptions> {
    width: Observable<string | undefined>
    nickNamePlaceholder: Observable<string>

    constructor(data: JSONSurveyNicknameOptions) {
        super();

        this.width = this.createField(data, 'Width');
        this.nickNamePlaceholder = this.createField(data, 'NicknamePlaceholder', labels.Nickname);
    }
}
export enum LuxonWeekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}

export const allLuxonWeekdays = [LuxonWeekday.Monday, LuxonWeekday.Tuesday, LuxonWeekday.Wednesday, LuxonWeekday.Thursday, LuxonWeekday.Friday, LuxonWeekday.Saturday, LuxonWeekday.Sunday];
export const luxonWeekdays = [LuxonWeekday.Monday, LuxonWeekday.Tuesday, LuxonWeekday.Wednesday, LuxonWeekday.Thursday, LuxonWeekday.Friday];
export const luxonWeekends = [LuxonWeekday.Saturday, LuxonWeekday.Sunday];
define('managers/community/pages/content',
    [
        'managers/base_es5'        
    ],

    function (Base) {
        const { default: events } = require('managers/events');
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');

        function Manager(pageId) {
            Base.call(this);

            this.pageId = ko.pureComputed(function () { return pageId });

            this._source = this._register(new DataSource({
                update: this._updateContent.bind(this),
                save: this._saveContent.bind(this)
            }));

            this.content = this._source.data;
            this.fromServer = this._source.fromServer;

            this.loading = this._source.updating;
            this.saving = this._source.saving;
            this.busy = this._source.busy;
            this.initializing = ko.pureComputed(function () { return this.loading() && this.content() == null }, this);
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.update = function () {
            this._source.update();
        };

        Manager.prototype.save = function (content) {
            this._source.save(content);
        };

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._disposables.register([
                events.communityPageContentUpdated.on(this._onPageContentUpdated.bind(this))
            ]);
        };

        Manager.prototype._updateContent = function () {
            return this._ctx
                .communitiesService
                .queries
                .pageContent(this.pageId())
                .firstOrDefault();
        };

        Manager.prototype._saveContent = function (content) {
            return this._ctx
                .communitiesService
                .queries
                .pageContent(this.pageId())
                .update({ pageContent: content });
        };

        Manager.prototype._onPageContentUpdated = function (pageId, data) {
            if (this.pageId() === pageId)
                this._source.set(data.pageContent);
        };

        return Manager;
    });
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

const apiEndpoint = require('endpoints/api').default;
const siteEndpoint = require('endpoints/site').default;

let cache = {};

let copy = (source, target) => _(source).each((value, key) => {
    if (target[key] == null)
        target[key] = value;
});

export class ResourcesService extends BaseService {
    constructor() {
        super();

        this._apiRepository = new Repository(apiEndpoint);
        this._siteRepository = new Repository(siteEndpoint);

        this.queries = {
            resourceBundles: id => this._siteRepository.query(`resources/${id}`),
            resources: () => this._apiRepository.query('resources')
        };
    }

    load(id) {
        return _(id).isString() ? this._loadBundle(id) : this._loadResources(id);
    }

    _loadBundle(id) {
        if (cache[id]) {
            return Promise.resolve(true);
        } else {
            return this.queries
                .resourceBundles(id)
                .firstOrDefault()
                .done(resources => {
                    copy(resources.Messages, messages);
                    copy(resources.Labels, labels);
                    copy(resources.Enums, enums);
                    copy(resources.ContentMessages, contentmessages);

                    cache[id] = true;
                }).then(() => true);
        }
    }

    _loadResources(resourcesToLoad) {
        return this.queries
            .resources()
            .firstOrDefaultPost(resourcesToLoad)
            .done(resources => {
                copy(resources.Messages, messages);
                copy(resources.Labels, labels);
                copy(resources.Enums, enums);
                copy(resources.ContentMessages, contentmessages);
            });
    }
}
import UploadStates from 'enums/uploads/states';
import { BlobWrapper } from 'interfaces/blobWrapper';
import { ObservableArray } from 'knockout';
import WCCUpload from 'models/upload';
import WCCError from 'models/wccError';
import { WCCUploaderTransport } from './transport';

export interface WCCUploaderConfig<T> {
    transport: WCCUploaderTransport<T>
    allowedExtensions?: Array<string>
}

interface WCCUploadRequestOptions {
    cancelPromise: Promise<void>
    progressCallback: (progress: number) => void
}

export default abstract class WCCUploader<T, U> {
    private transport: WCCUploaderTransport<U>
    private allowedExtensions: Array<string>

    uploads: ObservableArray<WCCUpload> = ko.observableArray().extend({ deferred: true });

    constructor(config: WCCUploaderConfig<U>) {
        this.transport = config.transport;
        this.allowedExtensions = config.allowedExtensions ?? [];
    }

    async upload(fileOrBlob: File | BlobWrapper) {
        this.validate(fileOrBlob);

        const upload = await this.getUpload(fileOrBlob);

        try {
            this.uploads.push(upload);

            const options: WCCUploadRequestOptions = {
                cancelPromise: upload.state.when(state => state === UploadStates.canceled).then(_.noop),
                progressCallback: upload.progress
            }

            options.cancelPromise.then(() => this.uploads.remove(upload));

            const uploadResult = await this.transport.upload(fileOrBlob, options);

            return this.getResult(uploadResult, upload, fileOrBlob);
        } finally {
            this.uploads.remove(upload);
        }
    }

    reset() {
        this.uploads().forEach(upload => upload.cancel());
    }

    protected abstract getResult(uploadResult: U, upload: WCCUpload, fileOrBlob: File | BlobWrapper): T | Promise<T>

    protected async getUpload(fileOrBlob: File | BlobWrapper) {
        return Promise.resolve(new WCCUpload({ name: fileOrBlob.name }));
    }

    private validate(fileOrBlob: File | BlobWrapper) {
        return this.validateExtension(fileOrBlob);
    }

    private validateExtension(fileOrBlob: File | BlobWrapper) {
        if (this.allowedExtensions.length > 0) {
            const name = fileOrBlob.name ?? '';
            const extension = system.getExtension(name) ?? '';

            if (!this.allowedExtensions.includes(extension))
                throw new WCCError(messages.InvalidExtension
                    .replace('{fileExtensionsList}', this.allowedExtensions.join(', '))
                    .replace('{fileName}', name));
        }
    }
}
import { BaseService } from 'services/base';

export class ImportsService extends BaseService {
    constructor() {
        super();

        this.subscriptions = {
            import: this.signalRSubscription('Import')
        }

        this.events = {
            progressNotification: this.signalREvent('progressnotification')
        }
    }
}
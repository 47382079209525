import { Observable } from "knockout";
import WCCEntity from "../entity";

export interface JSONTwoFactorAuthorisation {
    Is2faRequired: boolean;
    Is2faAuthenticated: boolean;
}

export class TwoFactorAuthorisation extends WCCEntity<JSONTwoFactorAuthorisation> {
    is2faRequired: Observable<boolean>;
    is2faAuthenticated: Observable<boolean>;

    constructor(data: JSONTwoFactorAuthorisation) {
        super();

        this.is2faRequired = this.createField(data, 'Is2faRequired', false);
        this.is2faAuthenticated = this.createField(data, 'Is2faAuthenticated', false);
    }

    static create(data: JSONTwoFactorAuthorisation) {
        return new TwoFactorAuthorisation(data);
    }
}
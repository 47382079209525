import { Observable } from "knockout";
import WCCEntity from "../entity";
import { JSONOnlineUserSlideVideoDetails, OnlineUserSlideVideoDetails } from "./onlineUserSlideVideoDetails";

export interface JSONOnlineUserSlideDetails {
    SlideId: string
    Video?: JSONOnlineUserSlideVideoDetails
}

export class OnlineUserSlideDetails extends WCCEntity<JSONOnlineUserSlideDetails> {
    slideId: Observable<string>
    video: Observable<OnlineUserSlideVideoDetails | undefined>

    static create(data: JSONOnlineUserSlideDetails) {
        return new OnlineUserSlideDetails(data);
    }

    constructor(data: JSONOnlineUserSlideDetails) {
        super();

        this.slideId = this.createField(data, 'SlideId');
        this.video = this.createMappedField(data, 'Video', OnlineUserSlideVideoDetails.create);
    }
}
import Uploader from 'fine-uploader/lib/s3';

const utils = Uploader.s3.util;

const patterns = [
    /^(?:https?:\/\/)?([a-z0-9.\-_]+)\.s3(?:[-.][a-z0-9\-]+)?\.amazonaws\.com/i,
    /^(?:https?:\/\/)?s3(?:[-.][a-z0-9\-]+)?\.amazonaws\.com\/([a-z0-9.\-_]+)/i,
    /^(?:https?:\/\/)?([a-z0-9.\-_]+)/i];

utils.getBucket = endpoint => {
    return patterns.reduce((result, pattern) => {
        if (result == undefined) {
            const match = pattern.exec(endpoint);

            if (match)
                return match[1];
        }

        return result;
    }, undefined);
}

export default Uploader;
import WCCAttachment from 'models/attachments/attachment';
import WCCImage from 'models/attachments/image';
import UserFile, { JSONUserFile } from 'models/userFile';

export module UserFilesHelpers {
    export function GetTypeFromAttachmentType(type: number | undefined) {
        switch (type) {
            case enums.AttachmentTypes.Image.value: return enums.UserFileTypes.OriginalImage.value;
        }
    }

    export function CreateFileFromAttachment(attachment: WCCAttachment): UserFile {
        let jsonFile = <JSONUserFile>{
            UserFileId: attachment.userFileId(),
            Type: UserFilesHelpers.GetTypeFromAttachmentType(attachment.fileType())
        }

        if (attachment instanceof WCCImage) {
            jsonFile = {
                ...jsonFile,

                Width: attachment.width(),
                Height: attachment.height(),
                Extension: attachment.fileExtension(),
                Name: attachment.originalFileName(),
                Link: attachment.hqUrl()
            }
        }

        return new UserFile(jsonFile);
    }
}
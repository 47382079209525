define('managers/statistics/poll',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');

        var discussionId = settings.discussionId;

        function Manager(pollId) {
            Base.call(this);

            this.pollId = ko.pureComputed(function () { return pollId });

            this._datasource = this._register(new DataSource({ update: this._load.bind(this) }));

            this.data = this._datasource.data;
            this.updating = this._datasource.updating;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.discussionService.subscriptions.discussion.subscribe(discussionId);
            this._ctx.commentsService.subscriptions.topicThreads.subscribe(this.pollId());

            this._ctx.commentsService.events.newComment.on(this._onNewResponse.bind(this));
            this._ctx.commentsService.events.threadChanged.on(this._onResponseChanged.bind(this));
            this._ctx.commentsService.events.threadDeleted.on(this._onResponseDeleted.bind(this));
            this._ctx.topicsService.events.topicDeleted.on(this._onPollDeleted.bind(this));
        };

        Manager.prototype._load = function () {
            return this._ctx
                .pollsService
                .queries
                .statistics(this.pollId())
                .background()
                .toArray();
        };

        Manager.prototype._onNewResponse = function (threadId, topicId) {
            if (this.pollId() === topicId)
                this._datasource.update();
        };

        Manager.prototype._onResponseChanged = function (threadId, topicId) {
            if (this.pollId() === topicId)
                this._datasource.update();
        };

        Manager.prototype._onResponseDeleted = function (threadId, parentThreadId, personId, topicId) {
            if (this.pollId() === topicId)
                this._datasource.update();
        };

        Manager.prototype._onPollDeleted = function (targetDiscussionId, pollId) {
            if (this.pollId() === pollId)
                this._datasource.data(null);
        };

        return Manager;
    });
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export interface JSONIInitialEndpointData {
    KOData: string;
    Settings: string;
    Enums: string;
}

export interface JSONUnmappedInitialEndpointDataKeys {
    KOData?: Array<string>;
    Settings?: Array<string>;
}

export interface JSONUnmappedInitialEndpointData {
    KOData: any;
    Settings: any;
}

class Queries {
    constructor(private repository: Repository) { }

    getInitialEndpointData = (projectId: string, dataId: string, dataType: number) => this.repository.query(`projects/${projectId}/initialEndpointData/${dataType}/${dataId}`);
    getInitialEndpointDataForDomain = (projectId: string, dataId: string, dataType: number, domainId: string) => this.repository.query(`projects/${projectId}/initialEndpointData/${dataType}/${dataId}/domains/${domainId}`);
}

class InitialEndpointDataService extends BaseService {
    queries = new Queries(new Repository(endpoint));
    
    getInitialEndpointData = (projectId: string, dataId: string, dataType: number) => this.queries.getInitialEndpointData(projectId, dataId, dataType).firstOrDefault();
    getInitialEndpointDataForDomain = (projectId: string, dataId: string, dataType: number, domainId: string) => this.queries.getInitialEndpointDataForDomain(projectId, dataId, dataType, domainId).firstOrDefault();

    /**
     * Merges data with the global data objects
     * @param endpointData
     */
    addInitialEndpointData = (endpointData: JSONIInitialEndpointData) => {
        _.each(JSON.parse(endpointData.Settings), (value, key) => WCC.Settings[key] = value);
        _.each(JSON.parse(endpointData.KOData), (value, key) => WCC.koData[key] = value);
        _.each(JSON.parse(endpointData.Enums), (value, key) => (<any>WCC.Enums)[key] = value);
    };

    /**
     * Merges data with the global data object but ignoring certain data elements and returning them as a new object
     * This is useful when we want to load additional data from the server to avoid an additional round trip but we don't want to merge it with the global data
     * I HAVE MADE A NEW OVERLOAD AS THIS IS USED EVERYWHERE AND ADDITIONAL CHECKS MAY SLOW THE CODE DOWN
     * @param endpointData
     * @param unmappedDataKeys
     */
    addInitialEndpointDataWithoutUnmappedData = (endpointData: JSONIInitialEndpointData, unmappedDataKeys: JSONUnmappedInitialEndpointDataKeys = { Settings: [], KOData: [] }): JSONUnmappedInitialEndpointData => {
        let unmappedData: JSONUnmappedInitialEndpointData = { Settings: {}, KOData: {}};
        
        _.each(JSON.parse(endpointData.Settings), (value, key) => {
            if (unmappedDataKeys.Settings?.includes(key)) {
                unmappedData.Settings[key] = value;
            }
            else {
                WCC.Settings[key] = value;
            }
        });
        
        _.each(JSON.parse(endpointData.KOData), (value, key) => {
            if (unmappedDataKeys.Settings?.includes(key)) {
                unmappedData.Settings[key] = value;
            }
            else {
                WCC.koData[key] = value;
            }
        });
        
        _.each(JSON.parse(endpointData.Enums), (value, key) => {
            (<any>WCC.Enums)[key] = value;
        });
        
        return unmappedData;
    };
}

export default InitialEndpointDataService;
import { Observable, PureComputed, Subscribable } from 'knockout';
import UserFile from 'models/userFile';
import WCCAttachment, { JSONWCCAttachment } from './attachment';
import {FilesHelpers} from "../../helpers/files";

const { WccCDNUrl } = settings;

interface GetURLOptions {
    prefix?: string
    nameMap?: (name: string) => string
}

interface AttachmentFields {
    userFileId: Observable<string | undefined>
    cdnFileName: Observable<string | undefined>
    originalFileName: Observable<string | undefined>
    fileExtension: Observable<string | undefined>
    width: Observable<number | undefined>
    height: Observable<number | undefined>
    hasHQVersion: Observable<boolean>
    hasThumbnail: Observable<boolean>
}

export interface JSONWCCImage extends JSONWCCAttachment {
    HQLink?: string
    CDNFileName?: string
    OriginalFileName?: string
    FileToken?: string
    FileExtension?: string
    FileHash?: string
    Crossorigin?: string
    Width?: number
    Height?: number
    HasHQVersion?: boolean
    HasThumbnail?: boolean
}

export default class WCCImage extends WCCAttachment<JSONWCCImage> {
    private base: AttachmentFields

    hqLink: Observable<string | undefined>
    cdnFileName: Observable<string | undefined>
    originalFileName: Observable<string | undefined>
    fileToken: Observable<string | undefined>
    fileExtension: Observable<string | undefined>
    fileHash: Observable<string | undefined>
    crossorigin: Observable<string | undefined>
    width: Observable<number | undefined>
    height: Observable<number | undefined>
    hasHQVersion: Observable<boolean>
    hasThumbnail: Observable<boolean>

    originalFile: PureComputed<UserFile | undefined>
    lqFile: PureComputed<UserFile | undefined>
    hqFile: PureComputed<UserFile | undefined>

    lqUrl: Subscribable<string | undefined>
    hqUrl: Subscribable<string | undefined>
    token: Subscribable<string | undefined>   // token for the file. Used to create the link to api/resources
    thumbnailUrl: Subscribable<string | undefined>
    cBackgroundUrlStr = ko.pureComputed(() =>  'url(' + this.thumbnailUrl() + ') no-repeat center / cover');

    defaultWidth = ko.pureComputed(() => 1280);
    defaultHeight = ko.pureComputed(() => 720);

    constructor(data?: JSONWCCImage) {
        super(data);

        this.fileHash = this.createField(data, 'FileHash');
        this.hqLink = this.createField(data, 'HQLink');
        this.crossorigin = this.createField(data, 'Crossorigin');
        this.fileToken = this.createField(data, 'FileToken');

        this.base = {
            userFileId: this.userFileId,
            cdnFileName: this.createField(data, 'CDNFileName'),            
            originalFileName: this.createField(data, 'OriginalFileName'),
            fileExtension: this.createField(data, 'FileExtension'),
            width: this.createField(data, 'Width'),
            height: this.createField(data, 'Height'),
            hasThumbnail: this.createField(data, 'HasThumbnail', false as boolean),
            hasHQVersion: this.createField(data, 'HasHQVersion', false as boolean),
        }

        const isOldAttachment = ko.pureComputed(() => this.base.userFileId() != undefined || this.link() != undefined);

        const oldLQFile = ko.pureComputed(() => {
            if (isOldAttachment())
                return this.getUserFile(this.getUrl(), enums.UserFileTypes.LQImage.value);
        }).extend({ deferred: true });

        const oldHQFile = ko.pureComputed(() => {
            if (isOldAttachment()) {
                const link = this.getHQLink();

                if (link != undefined)
                    return this.getUserFile(link, enums.UserFileTypes.HQImage.value);
            }
        }).extend({ deferred: true });

        const cOriginalFile = this.getObservableFile(enums.UserFileTypes.OriginalImage.value);
        const cLQFile = this.getObservableFile(enums.UserFileTypes.LQImage.value, [cOriginalFile, oldLQFile]);
        const cHQFile = this.getObservableFile(enums.UserFileTypes.HQImage.value, [oldHQFile]);
        const cLQFileOrDefaults = ko.pureComputed(() => cLQFile() || cOriginalFile());
        const cHQFileOrDefaults = ko.pureComputed(() => cHQFile() || cLQFile() || cOriginalFile());

        this.originalFile = cOriginalFile;
        this.lqFile = cLQFileOrDefaults;
        this.hqFile = cHQFileOrDefaults;

        this.lqUrl = cLQFileOrDefaults.pluck('link');
        this.hqUrl = cHQFileOrDefaults.pluck('link');
        this.token = cHQFileOrDefaults.pluck('token');
        this.thumbnailUrl = this.lqUrl;

        this.userFileId = ko.pureComputed({
            read: () => cHQFileOrDefaults()?.userFileId(),
            write: this.base.userFileId
        });

        this.originalFileName = ko.pureComputed({
            read: () => cHQFileOrDefaults()?.name(),
            write: this.base.originalFileName
        });

        this.cdnFileName = ko.pureComputed({
            read: () => {
                const cdnFileName = this.base.cdnFileName();

                if (cdnFileName)
                    return cdnFileName;

                return system.extractCDNFileNameFromLink(cLQFileOrDefaults()?.link() ?? '');
            },

            write: this.base.cdnFileName
        });

        this.fileExtension = ko.pureComputed({
            read: () => cHQFileOrDefaults()?.extension(),
            write: this.base.fileExtension
        });

        this.width = ko.pureComputed({
            read: () => cHQFileOrDefaults()?.width(),
            write: this.base.width
        });

        this.height = ko.pureComputed({
            read: () => cHQFileOrDefaults()?.height(),
            write: this.base.height
        });

        this.hasThumbnail = ko.pureComputed({
            read: () => this.thumbnailUrl() != undefined,
            write: this.base.hasThumbnail
        });

        this.hasHQVersion = ko.pureComputed({
            read: () => this.hqLink() != undefined,
            write: this.base.hasHQVersion
        });        
    }    

    protected override getViewTitle() {
        return this.title() || this.originalFileName() || '';
    }

    private getUrl({ prefix = '', nameMap = _.identity }: GetURLOptions = {}) {
        const cdnFileName = this.base.cdnFileName();
        const hash = this.fileHash();

        // short circuit if filetoken available
        if (this.fileToken() != undefined)
            return `${settings.wccApiUrl}/api/resources/taskimages/${this.fileToken()}`;

        // this is deprecated and will fail once private bucket is enabled
        // the cdnFileName is essentially the filetoken + .extension - so we could extract it and update the filetoken
        // I want to keep this before we flick on production to see if any issues arise
        // ** UPDATE - appears the place this is used, the link() below handles it. So this is safe to remove **
        //if (cdnFileName != undefined)
        //    return (WccCDNUrl + '/userfiles/' + prefix + nameMap(cdnFileName)).toLowerCase() + (hash != undefined ? '?rnd=' + hash : '');

        return this.link();
    }

    private getHQLink() {
        if (this.hqLink() != undefined)
            return this.hqLink();
        else if (this.base.hasHQVersion())
            return this.getUrl({ nameMap: name => name.replace(".", "_hq.") });
    }

    private getUserFile(link: string | undefined, type: number) {
        return new UserFile({
            UserFileId: this.base.userFileId(),
            Name: this.base.originalFileName(),
            Extension: this.base.fileExtension(),
            Link: link,
            Width: this.base.width(),
            Height: this.base.height(),
            Type: type,
            CreateDate: this.createDate()
        });
    }

    public async download() {
        return await FilesHelpers.saveAs(await FilesHelpers.loadAsBlob(this.hqUrl() ?? this.lqUrl() ?? ''), this.originalFileName() ?? 'wcs_video.mp4');
    }
}
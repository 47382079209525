import { wccModules } from "enums/wccModules";
import { IWordsMapFactory } from "factories/wordsMapFactory.interfaces";
import { inject, injectable } from "inversify";
import { EffectsContainer } from "mixins/withEffects";
import AutoCompleteManagerContext from "./autocomplete.context";
import { IAutoCompleteManager, IAutoCompleteManagerRequest } from "./autocomplete.interfaces";

@injectable()
export default class AutoCompleteManager implements IAutoCompleteManager {
    constructor(
        @inject(wccModules.wordsMapFactory) private wordsMapFactory: IWordsMapFactory,
        @inject(wccModules.effects) private effects: EffectsContainer
    ) { }

    start(request: IAutoCompleteManagerRequest) {
        return this.effects.register(new AutoCompleteManagerContext(request, this.wordsMapFactory));
    }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {IPipeline} from "../models/pipelines";

export class ServicePipelinesService extends BaseService {
    private _repository = new Repository(endpoint)

    queries = {
        pipelines: (discussionId: string, threadId: string) => 
            this._repository.query<never, IPipeline, never, never, never, never, never>(`v3/discussions/${discussionId}/threads/${threadId}/servicepipelines`),
        
        pipelineItemLog: (pipelineItemId: string, pipelineId: string, discussionId: string, threadId: string) => 
            this._repository.query<never, string, never, never, never, never, never>(`v3/discussions/${discussionId}/threads/${threadId}/servicepipelines/${pipelineId}/items/${pipelineItemId}/logs`),

        pipelinesCost: (discussionId: string) =>
            this._repository.query<never, Record<number, number>, never, never, never, never, never>(`v3/discussions/${discussionId}/servicepipelinescost`),
        
        rerunPipelineItem: (pipelineId: string, discussionId: string, threadId: string) =>
            this._repository.updateQuery<string>(`v3/discussions/${discussionId}/threads/${threadId}/servicepipelines/${pipelineId}`)
    }

    async getPipelines(discussionId: string, threadId: string) {
        return await this.queries.pipelines(discussionId, threadId).toArray();
    }
    
    async getPipelineItemLog(pipelineItemId: string, pipelineId: string, discussionId: string, threadId: string) {
        return await this.queries.pipelineItemLog(pipelineItemId, pipelineId, discussionId, threadId).firstOrDefault();
    }

    async getPipelinesCost(discussionId: string) {
        const data = await this.queries.pipelinesCost(discussionId).firstOrDefault();
        return new Map(Object.entries(data).map(([k, v]) => [+k, v]));
    }

    async rerunPipelineItem(pipelineItemId: string, pipelineId: string, discussionId: string, threadId: string) {
        await this.queries.rerunPipelineItem(pipelineId, discussionId, threadId).update(pipelineItemId);
    }
}
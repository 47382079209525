import { withEffects } from 'mixins/withEffects';

type TaskFactory<T> = () => T | PromiseLike<T>

class Task<T> {
    promise: Promise<T>

    resolve?: Action<[T]>
    reject?: Action<[any]>

    constructor(private factory: TaskFactory<T>) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    run() {
        return Promise.resolve(this.factory()).then(this.resolve, this.reject);
    }
}

export default class TasksQueueManager {
    private effects = withEffects()

    private tasks = ko.observableArray<Task<any>>();
    private taskToProcess = this.tasks.first();
    private activeTask = ko.observable<Task<any>>();

    busy = ko.pureComputed(() => this.tasks().length > 0 || this.activeTask() != undefined);

    constructor() {
        this.effects.register(async (task, activeTask) => {
            if (activeTask == undefined && task != undefined) {
                this.activeTask(task);

                try {
                    await task.run();
                } catch (ex) {
                    console.error('Error during task execution', task, ex);
                } finally {
                    this.tasks.remove(task);
                    this.activeTask(undefined);
                }
            }
        }, [this.taskToProcess, this.activeTask], false);
    }

    run<T>(factory: TaskFactory<T>) {
        var task = new Task(factory);
        this.tasks.push(task);

        return task.promise;
    }
}
import { isIE } from "./browser";
import { NotificationsHelpers } from "./notifications";

export function ajaxRequest(_url: any, requestType?: any, requestData?: any, successHandler?: any, loader?: any, disableContext?: any, errorHandler?: any, dataType?: any, asyncType?: any, additionalHeaders?: any, crossDomain?: any) {

    // this function is not 100% ready. feel free to modify it whichever way you want guys
    // it is used in WCCSignUpPage.js, WCCLoginPage.js, WCCLoginPageDirect.js

    var url;
    // if object with named properties is used instead of old calls with a list of parameters
    if (typeof _url === "object") {
        var options = _url;
        url = options.url;
        requestType = options.method || 'POST';
        requestData = options.data;
        successHandler = options.success;
        errorHandler = options.error;
        loader = options.loader;
        disableContext = options.context;
        dataType = options.dataType;
        asyncType = options.asyncType;
        additionalHeaders = options.additionalHeaders;
        crossDomain = options.crossDomain;
    }
    else {
        url = _url;
    }

    beginRequest(loader, disableContext);

    const ajaxRequestData: any = {
        type: requestType,
        async: (asyncType == null) ? true : asyncType,
        data: requestData,
        dataType: (dataType) ? dataType : "json",
        crossDomain: (crossDomain) ? crossDomain : false,
        beforeSend: function (r: any) {
            if (additionalHeaders) {
                for (var header in additionalHeaders)
                    r.setRequestHeader(header, additionalHeaders[header]);
            }
        },

        success: function (xhr: any, status: any, error: any) {
            if (xhr != null) {
                if (xhr.RedirectToLoginOnPermissionDenied != null && xhr.RedirectToLoginOnPermissionDenied != '') {
                    NotificationsHelpers.showErrorMessage(messages.YourSessionHasExpired);

                    setTimeout(function () {
                        location.href = xhr.RedirectToLoginOnPermissionDenied;
                    }, 5000);

                    return;
                }
            }

            handleSuccessRequest(loader, disableContext);

            if (successHandler) {
                successHandler.call(this, xhr, status, error);
            }
        },
        error: function (xhr: any, status: any, error: any) {
            handleErrorRequest(loader, disableContext, errorHandler, xhr, status, error);
        }
    }

    if (isIE)
        ajaxRequestData.cache = false;
    
    return $.ajax(url, ajaxRequestData);
}

export function handleSuccessRequest(loader: any, disableContext: any) {
    if (loader) {
        loader.hide();
    }
    if (disableContext) {
        $(":input:not(.static)", disableContext).prop("disabled", false);
        ($('input[type="checkbox"],input[type="radio"]', disableContext) as any).iCheck('enable');
        $('.form_datetime', disableContext).each(function () {
            var picker = $(this).data('datetimepicker');
            if (picker != null) {
                picker.enable();
            }
        });
    }
}

export function handleErrorRequest(this: any, loader: any, disableContext: any, errorHandler: any, xhr: any, status: any, error: any, showErrorMessage?: any) {
    if (loader) {
        loader.hide();
    }
    if (disableContext) {
        $(":input:not(.static)", disableContext).prop("disabled", false);
        ($('input[type="checkbox"],input[type="radio"]', disableContext) as any).iCheck('enable');
        $('.form_datetime', disableContext).each(function () {
            var picker = $(this).data('datetimepicker');
            if (picker != null) {
                picker.enable();
            }
        });
    }
    if (errorHandler) {
        errorHandler.call(this, xhr, status, error);
    }

    if (xhr.status != 0) { //for the cases where the request is aborted due to page navigation to other page during request operation
        if (showErrorMessage != null && showErrorMessage == true) {
            if (NotificationsHelpers.message)
                NotificationsHelpers.message.hide();

            NotificationsHelpers.setMessage(showErrorMessage(status));
        }
    }
}

export function beginRequest(loader: any, disableContext: any) {
    if (loader)
        loader.show();
    
    if (disableContext) {
        $(":input:not(.static)", disableContext).prop("disabled", true);
        ($('input[type="checkbox"],input[type="radio"]', disableContext) as any).iCheck('disable');
        $('.form_datetime', disableContext).each(function () {
            var picker = $(this).data('datetimepicker');
            if (picker != null) {
                picker.disable();
            }
        });
    }
}
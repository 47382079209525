import { Observable, Subscribable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyFilesOptions {
    MinFiles: number
    MaxFiles: number
    AllowedExtensions?: string
    AllowedMaxFileSize?: number
}

export default class SurveyFilesOptions extends WCCEntity<JSONSurveyFilesOptions> {
    minFiles: Observable<number>
    maxFiles: Observable<number>
    allowedExtensions: Observable<string | undefined>
    allowedMaxFileSize: Observable<number | undefined>

    parsedAllowedExtensions: Subscribable<Array<string>>

    constructor(data: JSONSurveyFilesOptions) {
        super();

        this.minFiles = this.createField(data, 'MinFiles');
        this.maxFiles = this.createField(data, 'MaxFiles');
        this.allowedExtensions = this.createField(data, 'AllowedExtensions');
        this.allowedMaxFileSize = this.createField(data, 'AllowedMaxFileSize');

        this.parsedAllowedExtensions = this.allowedExtensions.pluck(extensions => extensions.split(',').map(ext => ext.trim()), []);
    }
}
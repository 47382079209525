import EndPoint from 'endpoints/base';
import { withEffects } from 'mixins/withEffects';
import Query, { PassiveCollectionGetQuery, PassiveEntityGetQuery, PassiveEntityQuery, PassivePostQuery, PassiveQueryConfig, PassiveRemoveQuery, PassiveUpdateQuery } from 'models/queries/passive';

export default class GenericRepository {
    private effects = withEffects();

    private endpoint: EndPoint
    private disposePromise: Promise<void>    

    constructor(endpoint: EndPoint) {
        this.endpoint = endpoint;

        this.disposePromise = new Promise(resolve => this.effects.register(() => resolve))
    }

    /**
     * G - Get
     * P - Post
     * U - Update
     * D - Delete
     * I - Input - data we send to server
     * O - Output - data we get from server
     * so GI - data we send to server on Get request 
     * @param url
     */
    query<GI = any, GO = any, PI = any, PO = any, UI = any, UO = any, D = any>(url: string) {
        return new Query<GI, GO, PI, PO, UI, UO, D>({
            url: url,
            endpoint: this.endpoint,
            cancelPromise: this.disposePromise
        });
    }

    entityQuery<GO, GI = never, D = never>(url: string) {
        return new PassiveEntityQuery<GO, GI, D>(this.getQueryConfig(url));
    }

    entityGetQuery<GO, GI = never>(url: string) {
        return new PassiveEntityGetQuery<GO, GI>(this.getQueryConfig(url));
    }

    collectionGetQuery<GO, GI = never>(url: string) {
        return new PassiveCollectionGetQuery<GO, GI>(this.getQueryConfig(url));
    }

    postQuery<PI = never, PO = void>(url: string) {
        return new PassivePostQuery<PI, PO>(this.getQueryConfig(url));
    }

    updateQuery<UI = never, UO = void>(url: string) {
        return new PassiveUpdateQuery<UI, UO>(this.getQueryConfig(url));
    }

    removeQuery<D = never>(url: string) {
        return new PassiveRemoveQuery<D>(this.getQueryConfig(url));
    }

    /**
     * @deprecated
     * @param url
     */
    fastQuery(url: string) {
        return new Query({
            url: url,
            endpoint: this.endpoint,
            cancelPromise: this.disposePromise,
            aggregatable: true
        });
    }

    dispose() {
        this.effects.dispose();
    }

    private getQueryConfig(url: string) {
        return <PassiveQueryConfig>{
            url: url,
            endpoint: this.endpoint,
            cancelPromise: this.disposePromise
        }
    }
}
import { Disposer } from "interfaces/disposer";
import { injectable } from "inversify";
import { Subscribable } from "knockout";
import { ISlidesPublisher, ISlidesPublisherHandler } from "./slidesPublisher.interfaces";

@injectable()
export default class SlidesPublisher implements ISlidesPublisher {
    private allHandlers = ko.observableArray<ISlidesPublisherHandler>();
    private writableHandlers: Subscribable<ISlidesPublisherHandler[]>

    isReadOnly: Subscribable<boolean>

    constructor() {
        this.writableHandlers = this.allHandlers.filter(h => !h.isReadOnly());

        this.isReadOnly = ko.pureComputed(() => this.writableHandlers().length == 0);
    }

    registerHandler(handler: ISlidesPublisherHandler): Disposer {
        this.allHandlers.push(handler);

        return () => this.allHandlers.remove(handler);
    }

    getPublishedSlideId(): Subscribable<string | undefined> {
        return this.allHandlers
            .map(handler => handler.getPublishedSlideId())
            .reduce((result, slideId) => result ?? slideId(), () => <string | undefined>undefined);
    }

    async publishSlide(slideId: string): Promise<void> {
        await Promise.all(this.writableHandlers().map(handler => handler.publishSlide(slideId)));
    }

    async clearPublishedSlide(): Promise<void> {
        await Promise.all(this.writableHandlers().map(handler => handler.clearPublishedSlide()));
    }

    async expandSlide(slideId: string): Promise<Disposer> {
        const disposers = await Promise.all(this.allHandlers().map(handler => handler.expandSlide(slideId)));

        return () => disposers.forEach(disposer => disposer());
    }
}
import { injectable } from "inversify";
import { Subscribable } from "knockout";
import { CustomElementConfig, getCustomElement } from "../../../extensions/knockout/bindings/customElement";
import { WCCAttachmentBoxLayoutVMConfig } from "./box/box";
import { AttachmentLayoutFactoryRequestConfig } from "./iFactory";
import { WCCAttachmentPanelLayoutVMConfig } from "./panel/panel";
import { WCCAttachmentRowLayoutVMConfig } from "./row/row";

components.preload('wcc-attachment-box-layout', 'wcc-attachment-panel-layout', 'wcc-attachment-row-layout');

@injectable()
export default class AttachmentLayoutFactory {
    constructor() { }

    create(config: AttachmentLayoutFactoryRequestConfig): Subscribable<CustomElementConfig> {
        const layout = ko.flattenComputed(config.layout, 'box');

        return layout.pluck<CustomElementConfig>(layout => {
            switch (layout) {
                case 'box': return getCustomElement<WCCAttachmentBoxLayoutVMConfig>('wcc-attachment-box-layout', config.config);
                case 'panel': return getCustomElement<WCCAttachmentPanelLayoutVMConfig>('wcc-attachment-panel-layout', config.config);
                case 'row': return getCustomElement<WCCAttachmentRowLayoutVMConfig>('wcc-attachment-row-layout', config.config);
            }
        });
    }
}
import qq from 'libraries/fineUploader';
import { apiS3FileData } from 'models/apiS3FileData';
import { S3FineUploaderNewFileData } from './iFineUploader';

export default class S3FineUploaderFilesDataStorage {
    private items = new Map<number, S3FineUploaderNewFileData>();

    constructor(private endpoint: string, private uploader: qq.s3.FineUploaderBasic) { }

    set(id: number | undefined, s3FileData: apiS3FileData) {
        id ??= 0;

        this.items.set(id, {
            fileId: s3FileData.FileId,
            S3Key: s3FileData.Key,
            endpoint: this.endpoint,
            link: s3FileData.SignedS3Link
        });
    }

    get(id: number | undefined): S3FineUploaderNewFileData {
        id ??= 0;

        const newS3FileData = this.items.get(id);

        if (newS3FileData != undefined) {
            return {
                ...newS3FileData
            }
        } else {

            console.log('ATTENTION! S3FineUploaderFilesDataStorage.get() - newS3FileData is undefined! Obtaining file from uploader. S3link may not be signed');

            return {
                fileId: `${id}`,
                S3Key: this.uploader.getKey(id),
                endpoint: this.endpoint,
                link: `${this.uploader.getEndpoint(id)}/${this.uploader.getKey(id)}`
            }
        }
    }
}
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import { wccModules } from "../../enums/wccModules";
import { CurrentUserManager } from "../currentUser";
import { IWCCStorageManager } from "../iStorage";
import AppointmentManager from "./appointment";

export interface AppointmentAccessManagerConfig {
    appointmentId: string
}

@injectable()
export default class AppointmentAccessManager {
    isInterviewer: Subscribable<boolean>
    isMember: Subscribable<boolean>
    isBackroomMember: Subscribable<boolean>

    isReady: Subscribable<boolean>

    constructor(
        @inject(wccModules.managerConfig) { appointmentId }: AppointmentAccessManagerConfig,
        @inject(wccModules.storage) storage: IWCCStorageManager
    ) {
        const userManager = storage.get(CurrentUserManager, {});
        const user = userManager.pluck(m => m.person);
        const userId = user.pluck(u => u.personId);

        const appointmentManager = storage.get(AppointmentManager, { appointmentId });
        const appointment = appointmentManager.pluck(m => m.appointment);
        const interviewerId = appointment.pluck(a => a.interviewerId);

        const members = appointment.pluck(a => a.members);
        const member = members.find(m => m.personId() == userId());

        this.isInterviewer = member.pluck(m => m.personId() == interviewerId(), false);
        this.isBackroomMember = member.pluck(m => m.isModeratorOrObserver() && !this.isInterviewer(), false);
        this.isMember = member.pluck(() => !this.isInterviewer() && !this.isBackroomMember(), false);

        this.isReady = ko.pureComputed(() => user() != undefined && appointment() != undefined);
    }
}
import { withEffects } from 'mixins/withEffects';

export default class WidgetObserver {
    private effects = withEffects();

    constructor(private element: HTMLElement) {
        this.effects.register(() => {
            const observer = new MutationObserver(this.onMutations.bind(this));
            observer.observe(element, { childList: true, subtree: true });
        });
    }

    dispose() {
        this.effects.dispose();
    }

    onMutations() {
        const widgets = this.element.querySelectorAll('.cb-widget');

        widgets.forEach(widget => {
            const initialized = _(widget).any((value, key) => key.indexOf('__ko') === 0);

            if (!initialized) {
                try {
                    ko.ignoreDependencies(() => ko.applyBindings({}, widget));
                } catch (ex) { }
            }
        });
    }
}
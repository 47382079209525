import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CollectionDataSource } from 'managers/datasources/collection';
import events from 'managers/events';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import CommunityPage, { JSONCommunityPage } from 'models/community/page';
import ContentImage from 'models/contentImage';
import { CommunitiesService } from 'services/communities';

export interface CommunityPagesManagerConfig { }

@injectable()
export default abstract class CommunityPagesManager extends CollectionDataSourceOwner<CommunityPage, 'id'> {
    protected source: CollectionDataSource<CommunityPage, 'id'>

    pages: Subscribable<Array<CommunityPage>>

    constructor(
        @inject(wccModules.managerConfig) config: CommunityPagesManagerConfig,
        @inject(wccModules.effects) effects: EffectsContainer,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.communitiesService) protected communitiesService: CommunitiesService
    ) {
        super();

        this.source = effects.register(new CollectionDataSource({
            key: 'id',
            load: this.loadPages.bind(this),
            update: this.updatePage.bind(this),
            mapper: jsonPage => new CommunityPage(jsonPage),

            merge: (page, newPage) => {
                page.update(newPage.toJson())
                return page;
            }
        }));

        this.pages = this.source.list;

        effects.register([
            events.communityDomainChanged.on(this.onDomainChanged.bind(this)),
            events.communityPageAdded.on(this.onLocalPageAdded.bind(this)),
            events.communityPageUpdated.on(this.onLocalPageUpdated.bind(this)),
            events.communityPageDeleted.on(this.onPageDeleted.bind(this)),
            events.communityPagePublished.on(this.onPagePublished.bind(this)),
            events.communityPagesImported.on(this.onPagesImported.bind(this)),
            events.communityPagesPublished.on(this.onPagesPublished.bind(this))
        ]);

        if (settings.communityId != undefined) {
            signalREvents.communityPages(settings.communityId)
                .onPageAdded(this.onPageAdded.bind(this))
                .onPageUpdated(this.onPageUpdated.bind(this))
                .onPageDeleted(this.onPageDeleted.bind(this))
                .onPagePublished(this.onPagePublished.bind(this))
                .onPageImageChanged(this.onPageImageChanged.bind(this))
                .onPagesImported(this.onPagesImported.bind(this));
        }
    }

    update() {
        this.source.load();
    }

    protected abstract loadPages(): PromiseLike<Array<JSONCommunityPage>>
    protected abstract updatePage(page: CommunityPage): Promise<void> | void

    protected onDomainChanged() {
        this.update();
    }

    protected onPageAdded() {
        this.update();
    }

    protected onPageUpdated(pageId: string) {
        this.source.update(page => page.id() == pageId);
    }

    protected onLocalPageAdded(page: CommunityPage) {
        this.source.attach(page);
    }

    protected onLocalPageUpdated(pageId: string, data: JSONCommunityPage) {
        this.source.findSync(pageId, page => page.update(data));
    }

    protected onPageDeleted(pageId: string) {
        this.source.remove(page => page.id() == pageId);
    }

    protected onPagePublished(pageId: string) {
        this.source.findSync(pageId, page => {
            page.lastPublishedOn(new Date());
        });
    }

    protected onPagesImported() {
        this.update();
    }

    protected onPagesPublished() {
        this.pages().forEach(page => page.lastPublishedOn(new Date()));
    }

    protected onPageImageChanged(pageId: string) {
        this.source.findSync(pageId, async page => {
            const jsonImage = await this.communitiesService.adminQueries.pageImage(pageId).firstOrDefault();

            if (jsonImage != undefined)
                page.image(new ContentImage(jsonImage));
        });
    }
}
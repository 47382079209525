import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import { wccModules } from "enums/wccModules";
import { DateHelpers } from "helpers/date";
import { withEffect } from "mixins/withEffect";
import { EffectsContainer } from "mixins/withEffects";
import JWT, { JSONJWT } from "models/jwt";
import { ISignalRService } from "services/iSignalR";
import { EntityDataSource } from "../../datasources/entity";
import { ISignalRCoreTokenManager } from "./interfaces/token";

const { loginToken, now } = settings;

//gap we use to renew token while it's not expired yet
const renewGap = DateHelpers.milisecondsInMinute;

@injectable()
export default class SignalRCoreTokenManager implements ISignalRCoreTokenManager {
    token: Subscribable<string | undefined>
    expiryDate: Subscribable<Date | undefined>

    constructor(
        @inject(wccModules.signalRService) private signalRService: ISignalRService,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const source = effects.register(new EntityDataSource<JSONJWT>({
            update: async () => {
                if (loginToken() != undefined)
                    return await this.signalRService.tokenQuery().firstOrDefault();
            }
        }));

        const jwt = source.data.pluck(jsonJWT => new JWT(jsonJWT));

        this.token = jwt.pluck(jwt => jwt.token);
        this.expiryDate = jwt.pluck(jwt => jwt.expiryDate);

        effects.register([
            loginToken.subscribe(() => source.update())
        ]);

        //once every {n} seconds check if we need to renew token
        effects.register(jwt => {
            if (jwt != undefined) {
                return withEffect((expiryDate, now) => {
                    if (expiryDate.getTime() - renewGap < now.getTime())
                        source.update();
                }, [jwt.expiryDate, now], { rateLimit: 30000 });
            }
        }, [jwt]);
    }
}
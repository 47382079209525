import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import {BaseService} from 'services/base';
import {StripePaymentIntentStatus} from '../models/payments/IPaymentIntent';
import { ProjectPurchaseVideoMinutes } from '../pages/admin/purchase/projectPurchaseOptions';

class Queries {
    constructor(private repository: any) {
    }

    invoiceByNumber(invoiceNumber: number) {
        return this.repository.query(`payments/invoice/${invoiceNumber}`);
    }

    invoiceExternalCodeByPaymentIntent(paymentIntent: string) {
        return this.repository.query(`payments/invoiceExternalCode/${paymentIntent}`);
    }

    getPaymentResult(paymentIntent: string, clientToken: string) {
        return this.repository.query(`payments/paymentintent/${paymentIntent}?token=${clientToken}`);
    }

    createPaymentIntent() {
        return this.repository.query('payments/purchase');
    }

    purchasePayLater() {
        return this.repository.query('payments/purchasepaylater');
    }

    purchaseNoCostProject() {
        return this.repository.query('payments/purchasezero');
    }

    purchaseVideoMinutes() {
        return this.repository.query('payments/purchasevideominutes');
    }

    myStripeCustomer() {
        return this.repository.query('payments/subscription/customer');
    }

    testEvent() {
        return this.repository.query('payments/testevent');
    }

    subscriptions() {
        return this.repository.query('payments/subscriptions');
    }

    mySubscription() {
        return this.repository.query('payments/subscriptions/mine');
    }

    createSubscriptionPaymentIntent() {
        return this.repository.query('payments/subscription/paymentIntent');
    }
}

class PaymentsService extends BaseService {
    queries = new Queries(new Repository(endpoint));

    /**
     * Returns an invoice from an invoice number
     */
    getInvoiceByNumber(invoiceNumber: number) {
        return this.queries.invoiceByNumber(invoiceNumber).firstOrDefault();
    }

    /*
     *  Returns invoice external code by paymentintent
     */
    getInvoiceExternalCodeByPaymentIntent(paymentIntent: string) {
        return this.queries.invoiceExternalCodeByPaymentIntent(paymentIntent).firstOrDefault();
    }

    /*
     * Go and get payment result with payment intent. A fallback call if signalr fails to move us on.
     */
    getPaymentResult(paymentIntent: string, clientToken: string): StripePaymentIntentStatus | null {
        return this.queries.getPaymentResult(paymentIntent, clientToken).firstOrDefault();
    }

    /*
     * Gets all the Subscription Plans I can select to subscribe to
     */
    getSubscriptionPlans(): Promise<Array<any>> {
        return this.queries.subscriptions().toArray();
    }

    /*
     * Gets my subscription info
     */
    getMySubscription(): any | undefined {
        return this.queries.mySubscription().firstOrDefault();
    }

    /*
    * Create a stripe payment intent for use with a Subscription
    */
    createSubscriptionPaymentIntent(data: any) {
        return this.queries.createSubscriptionPaymentIntent().firstOrDefaultPost(data);
    }

    getMyStripeCustomer(): Promise<string | undefined> {
        return this.queries.myStripeCustomer().firstOrDefault();
    }


    /*
     * Create a stripe payment intent with our project options
     */
    createPaymentIntent(data: any) {
        return this.queries.createPaymentIntent().firstOrDefaultPost(data);
    }

    /*
     * Create transactions and open a project now to be paid later by invoice.
     */
    purchasePayLater(data: any) {
        return this.queries.purchasePayLater().firstOrDefaultPost(data);
    }

    /*
     * Update creditcardtransactions with new close date for free extensions
     */
    purchaseNoCostProject(data: any) {
        return this.queries.purchaseNoCostProject().firstOrDefaultPost(data);
    }

    /**
     * Add video minutes to project
     * @param data (ProjectId:string, VideoMinutes:number)
     */
    purchaseVideoMinutes(data: ProjectPurchaseVideoMinutes) {
        return this.queries.purchaseVideoMinutes().firstOrDefaultPost(data);
    }

    testEvent() {
        return this.queries.testEvent().firstOrDefault();
    }

    signalrPaymentGroup() {
        return this.signalRSubscription('Payment');
    }

    /*
     * SignalR event once payment has been confirmed
     */
    signalrPaymentConfirmationEvent() {
        return this.signalREvent('PaymentConfirmation');
    }
}

export default PaymentsService;
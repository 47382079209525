import { Observable } from "knockout";

export interface JSONHeatmapPoint {
    X: number,
    Y: number
}

export class HeatmapPoint {
    x: Observable<number>
    y: Observable<number>

    constructor(jsonPoint: JSONHeatmapPoint) {
        this.x = ko.observable(jsonPoint.X);
        this.y = ko.observable(jsonPoint.Y);
    }

    toJson(): JSONHeatmapPoint {
        return {
            X: this.x(),
            Y: this.y()
        }
    }

    update(jsonPoint: JSONHeatmapPoint) {
        this.x.update(jsonPoint.X);
        this.y.update(jsonPoint.Y);
    }
}
import { Observable, ObservableArray } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyBlank, { JSONSurveyBlank } from './blank';

export interface JSONSurveyBlanksOptions {
    Text: string
    Blanks: Array<JSONSurveyBlank>
}

export default class SurveyBlanksOptions extends WCCEntity<JSONSurveyBlanksOptions> {
    text: Observable<string>
    blanks: ObservableArray<SurveyBlank>

    constructor(data: JSONSurveyBlanksOptions) {
        super();

        this.text = this.createField(data, 'Text');
        this.blanks = this.createCollection(data, 'Blanks', jsonBlank => new SurveyBlank(jsonBlank));
    }
}
import { Func } from 'interfaces/func';
import { withEffects } from 'mixins/withEffects';

export default class NodeRenderTracker {
    private effects = withEffects();

    constructor(container: Node, selectorOrPredicate: string | Func<boolean, [Node]>, action: Action<[Node]>, once = false) {
        const predicate: Func<boolean, [Node]> = _.isString(selectorOrPredicate) ? node => $(node).is(selectorOrPredicate) : selectorOrPredicate;

        this.effects.register(() => {
            const checkNode = (node: Node) => {
                if (predicate(node)) {
                    action(node);

                    if (once)
                        observer.disconnect();
                } else {
                    node.childNodes.forEach(node => checkNode(node));
                }
            }

            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    mutation.addedNodes.forEach(node => checkNode(node));
                });
            });

            container.childNodes.forEach(node => checkNode(node));

            observer.observe(container, { childList: true, subtree: true });

            return () => observer.disconnect();
        });
    }

    dispose() {
        this.effects.dispose();
    }
}
import { Func } from 'interfaces/func';
import { StringMap } from 'interfaces/stringMap';
import { ObservableArray, Unwrapped } from 'knockout';
import { withEffects } from 'mixins/withEffects';

type LoadFunc<T extends StringMap<any>> = (request: LoadRequest<T>) => Array<any> | PromiseLike<Array<any>>
type UpdateFunc<T> = (item: T) => void | PromiseLike<void>

interface LoadRequestConfig<T extends StringMap<any>> {
    loadMore: boolean
    options: T
}

export class LoadRequest<T extends StringMap<any> = StringMap<any>> {
    loadMore: boolean
    options: T

    constructor(config: LoadRequestConfig<T>) {
        this.loadMore = config.loadMore;
        this.options = config.options;
    }

    compare(other: LoadRequest<T>) {
        return other.loadMore === this.loadMore &&
            _.isEmpty(other.options) && _.isEmpty(this.options);
    }
}

function mergeDefault<T>(item: T, newItem: T) {
    return newItem;
}

interface CollectionDataSourceConfig<V, K extends keyof V = never, O extends StringMap<any> = StringMap<any>> {
    load: LoadFunc<O>
    update?: UpdateFunc<V>
    merge?: Func<V, [item: V, newItem: V]> | Action<[item: V, newItem: V]>
    mapper?: Func<V, [data: any]>
    isEmpty?: boolean
    isDeferred?: boolean
    key?: K
}

export class CollectionDataSource<V, K extends keyof V = never, O extends StringMap<any> = StringMap<any>> {
    private effects = withEffects()

    private itemsToUpdate = ko.observableArray<V>();
    private itemToUpdate = ko.observable<V>();

    private loadRequests = ko.observableArray<LoadRequest<O>>();
    private loadRequest = ko.observable<LoadRequest<O>>();

    private keyField?: K
    private loadFunc: LoadFunc<O>
    private updateFunc: UpdateFunc<V>
    private mergeFunc: Func<V | undefined, [item: V, newItem: V]> | Action<[item: V, newItem: V]>
    private mapper: (data: any) => V
    private doNotLoadOnInit: boolean
    private isDeferred: boolean

    private canProcessNext = ko.pureComputed(() => this.itemToUpdate() == undefined && this.loadRequest() == undefined);

    isInitialized = ko.observable(false)

    list: ObservableArray<V> = ko.observableArray().extend({ deferred: true });
    requestsProcessed = ko.observable(0);

    updating = ko.pureComputed(() => this.itemToUpdate() != undefined || this.itemsToUpdate().length > 0);
    loading = ko.pureComputed(() => !this.isInitialized() || this.loadRequest() != undefined || this.loadRequests().length > 0);
    busy = ko.pureComputed(() => this.loading() || this.updating());

    constructor(config: CollectionDataSourceConfig<V, K>) {
        this.keyField = config.key;
        this.loadFunc = config.load;
        this.updateFunc = config.update ?? _.noop;
        this.mergeFunc = config.merge ?? mergeDefault;
        this.mapper = config.mapper ?? _.identity;
        this.doNotLoadOnInit = config.isEmpty ?? false;
        this.isDeferred = config.isDeferred ?? false;
    }

    add(jsonItem: any) {
        var item = this.mapper(jsonItem);
        this.addItem(item);

        return item;
    }

    attach(item: V) {
        var isAlreadyAttached = this.list().includes(item);

        if (!isAlreadyAttached)
            this.list.push(item);
    }

    update(predicate: (item: V) => boolean) {
        this.list().forEach(item => {
            if (predicate(item) && !this.itemsToUpdate().includes(item))
                this.itemsToUpdate.push(item)
        });
    }

    remove(itemOrPredicate: V | ((item: V) => boolean)) {
        if (_.isFunction(itemOrPredicate))
            this.list.remove(itemOrPredicate);
        else
            this.list.remove(itemOrPredicate);
    }

    load(loadMore = false, options: O = <O>{}) {
        var newRequest = new LoadRequest({
            loadMore: loadMore,
            options: options
        });

        if (newRequest.loadMore) {
            var contains = this.loadRequests().some(request => request.compare(newRequest));

            if (!contains)
                this.loadRequests.push(newRequest);
        } else {
            this.loadRequests([newRequest]);
        }

        if (!this.isInitialized())
            this.isInitialized(true);
    }

    find(key: Unwrapped<V[K]>): Promise<V> {
        return new Promise((resolve, reject) => this.findSync(key, resolve, reject));
    }

    findSync(key?: Unwrapped<V[K]>, resolve: (item: V) => void = _.noop, reject: (ex: Error) => void = _.noop) {
        let isResolved = false;

        const keyField = this.keyField;

        if (keyField != undefined && key != undefined) {
            var item = this.list().find(item => ko.unwrap(item[keyField]) === key);

            if (item !== undefined) {
                resolve(item);
                isResolved = true;
            }
        }

        if (!isResolved)
            reject(new Error('not found'));
    }

    clear() {
        this.list([]);
    }

    reset() {
        this.requestsProcessed(0);
        this.list([]);
        this.load();
    }

    init() {
        this.effects.register(async (canProcessNext, requests) => {
            if (canProcessNext && requests.length > 0) {
                const request = requests[0];

                this.loadRequest(request);
                this.loadRequests.shift();                

                try {
                    await this.processLoadRequest(request);
                } finally {
                    ko.tasks.schedule(() => this.loadRequest(undefined));
                }
            }
        }, [this.canProcessNext, this.loadRequests]);

        this.effects.register(async (canProcessNext, items) => {
            if (canProcessNext && items.length > 0) {
                var item = this.itemsToUpdate.shift();
                this.itemToUpdate(item);

                var contains = _(this.list()).contains(item);

                if (contains) {
                    try {
                        await Promise.resolve(this.updateFunc(item));
                    } finally {
                        this.itemToUpdate(undefined)
                    }
                } else {
                    this.itemToUpdate(undefined);
                }
            }
        }, [this.canProcessNext, this.itemsToUpdate]);

        if (!this.isDeferred) {
            if (!this.doNotLoadOnInit)
                this.load();
            else
                this.requestsProcessed.inc();

            this.isInitialized(true);
        }
    }

    dispose() {
        this.effects.dispose();
    }

    private addItem(newItem: V) {
        const keyField = this.keyField;

        if (keyField) {
            const key = ko.unwrap(newItem[keyField]) as Unwrapped<V[K]>;

            this.findSync(key,
                existingItem => {
                    const mergedItem = this.mergeFunc(existingItem, newItem);

                    if (mergedItem)
                        this.list.replace(existingItem, mergedItem);
                },

                () => this.list.push(newItem));
        } else if (!this.list().includes(newItem)) {
            this.list.push(newItem);
        }
    }

    private async processLoadRequest(request: LoadRequest<O>) {
        try {
            const { loadMore } = request;

            const jsonContent = await Promise.resolve(this.loadFunc(request));
            this.processContent(jsonContent, !loadMore);
            this.requestsProcessed.inc();
        } catch (ex) {
            console.warn(ex, this.loadFunc);
            throw ex;
        }
    }

    private processContent(jsonContent: Array<any>, replace: boolean) {
        const newItems = [] as Array<V>;
        const keyField = this.keyField;

        jsonContent.forEach(jsonItem => {
            const newItem = this.mapper(jsonItem);
            const key = keyField ? ko.unwrap(newItem[keyField]) as Unwrapped<V[K]> : undefined;

            this.findSync(key,
                item => newItems.push(this.mergeFunc(item, newItem) || item),
                () => newItems.push(newItem));
        });

        if (replace)
            this.list(newItems);
        else
            newItems.forEach(item => this.addItem(item));
    }
}
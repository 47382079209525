import apiEndpoint from 'endpoints/api';
import { injectable } from 'inversify';
import events from 'managers/events';
import { JSONDailyRecord } from 'models/daily/record';
import { JSONDailyRecordCompositionRequestOptions } from 'models/daily/recordCompositionRequestOptions';
import { JSONDailySession } from 'models/daily/session';
import GenericRepository from 'repositories/generic';
import { IDailyService } from './iDailyService';

@injectable()
export default class DailyService implements IDailyService {
    private repository = new GenericRepository(apiEndpoint);

    private createRecordCompositionQuery = (recordId: string) => this.repository.postQuery<JSONDailyRecordCompositionRequestOptions>(`daily/records/${recordId}/composition/create`);
    private updateAppointmentRecordsQuery = (appointmentId: string) => this.repository.postQuery<void, Array<JSONDailyRecord>>(`appointments/${appointmentId}/daily/records/update`);
    private processAppointmentRecordsQuery = (appointmentId: string) => this.repository.postQuery<void, Array<JSONDailyRecord>>(`appointments/${appointmentId}/daily/records/process`);

    appointmentSession = (appointmentId: string) => this.repository.entityGetQuery<JSONDailySession>(`appointments/${appointmentId}/daily/session`);
    appointmentRecords = (appointmentId: string) => this.repository.collectionGetQuery<JSONDailyRecord>(`appointments/${appointmentId}/daily/records`);    

    record = (recordId: string) => this.repository.entityQuery<JSONDailyRecord>(`daily/records/${recordId}`);
    processRecord = (recordId: string) => this.repository.postQuery(`daily/records/${recordId}/process`);    
    recordComposition = (compositionId: string) => this.repository.removeQuery(`daily/compositions/${compositionId}`);

    async updateAppointmentRecords(appointmentId: string) {
        var newRecords = await this.updateAppointmentRecordsQuery(appointmentId).post();

        if (newRecords.length > 0)
            events.dailyRecordsAdded.trigger(appointmentId, newRecords);
    }

    async processAppointmentRecords(appointmentId: string) {
        var newRecords = await this.processAppointmentRecordsQuery(appointmentId).post();

        if (newRecords.length > 0) {
            events.appointmentRecordsChanged.trigger(appointmentId);
            events.dailyRecordsAdded.trigger(appointmentId, newRecords);
        }
    }

    async createRecordComposition(recordId: string, options: JSONDailyRecordCompositionRequestOptions) {
        await this.createRecordCompositionQuery(recordId).post(options);
        events.dailyRecordChanged.trigger(recordId);
    }

    async removeRecord(appointmentId: string, recordId: string) {
        await this.record(recordId).remove();
        events.dailyRecordRemoved.trigger(recordId);
        events.appointmentRecordsChanged.trigger(appointmentId);
    }

    async removeRecordComposition(compositionId: string) {
        await this.recordComposition(compositionId).remove();
        events.dailyRecordCompositionRemoved.trigger(compositionId);
    }
}
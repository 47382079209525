import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class BlogsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            blogs: communityId => this._repository.fastQuery(`communities/${communityId}/blogs`)
        };
    }
}
define('models/commentsDuringTime', [], function () {
    function Model(jsonStat) {
        var self = this;

        self.startDate = ko.observable(jsonStat.StartDate != null ? new Date(jsonStat.StartDate) : null);
        self.endDate = ko.observable(jsonStat.EndDate != null ? new Date(jsonStat.EndDate) : null);
        self.threadsCount = ko.observable(jsonStat.ThreadsCount || 0);
        self.commentsCount = ko.observable(jsonStat.CommentsCount || 0);
        self.repliesCount = ko.observable(jsonStat.RepliesCount || 0);
        self.imagesCount = ko.observable(jsonStat.ImagesCount || 0);
        self.videosCount = ko.observable(jsonStat.VideosCount || 0);
        self.filesCount = ko.observable(jsonStat.FilesCount || 0);
    }
    
    return Model;
});
import { inject, injectable } from "inversify";
import { IAppointmentsService } from "services/appointments/appointments.interfaces";
import storageDefaults from "../../../decorators/storageDefaults";
import storageOptional from "../../../decorators/storageOptional";
import { wccModules } from "../../../enums/wccModules";
import { EffectsContainer } from "../../../mixins/withEffects";
import { TimelineDataSourceLoadRequest } from "../../datasources/timeline";
import SignalREventsManager from "../../signalR/events";
import BaseAppointmentsIntervalsManager, { BaseAppointmentsIntervalsManagerConfig } from "./baseIntervalsTimeline";

export interface DiscussionAppointmentsIntervalsManagerConfig extends BaseAppointmentsIntervalsManagerConfig {
    participant?: string,
}

@injectable()
@storageDefaults(<Partial<DiscussionAppointmentsIntervalsManagerConfig>>{ discussionId: settings.discussionId })
@storageOptional<DiscussionAppointmentsIntervalsManagerConfig>('participant')
export default class DiscussionAppointmentsIntervalsManager extends BaseAppointmentsIntervalsManager {
    private participant?: string

    constructor(
        @inject(wccModules.managerConfig) config: DiscussionAppointmentsIntervalsManagerConfig,
        @inject(wccModules.appointmentsService) private appointmentsService: IAppointmentsService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super(config, signalREvents, effects);

        this.participant = config.participant;
    }

    protected loadIntervals({ from, to }: TimelineDataSourceLoadRequest) {
        return this.appointmentsService.appointmentsIntervalsQuery(this.discussionId, from.toISOString(), to.toISOString()).addArgs(this.getQueryArgs()).toArray();
    }

    protected loadBoundaries() {
        return this.appointmentsService.appointmentsIntervalsTimeBoundariesQuery(this.discussionId, this.host).firstOrDefault();
    }

    protected override getQueryArgs() {
        return {
            ...super.getQueryArgs(),

            participant: this.participant
        }
    }    
}
import { PureComputed, Observable, ObservableArray } from "knockout"
import { TopicCard, JSONTopicCard } from "./topicCard"

export interface JSONTopicCardsGroup {
    TopicCardGroupId?: string
    Title?: string
    Description?: string
    CreateDate?: string
    IsCustom?: boolean

    Cards?: Array<JSONTopicCard>
}

export class TopicCardsGroup {
    id: Observable<string | undefined>
    title: PureComputed<string>
    description: PureComputed<string>
    createDate: PureComputed<Date>
    isCustom: PureComputed<boolean>
    isEmpty: PureComputed<boolean>

    cards: ObservableArray<TopicCard>

    constructor(jsonModel?: JSONTopicCardsGroup) {
        this.id = ko.observable(jsonModel?.TopicCardGroupId);
        this.title = ko.observable(jsonModel?.Title).default('');
        this.description = ko.observable(jsonModel?.Description).default('');
        this.createDate = ko.observable(jsonModel?.CreateDate != null ? new Date(jsonModel?.CreateDate) : null).default(new Date());

        this.cards = ko.observableArray();

        this.isCustom = ko.observable(jsonModel?.IsCustom).default(false);
        this.isEmpty = ko.pureComputed(() => this.cards().length === 0);
    }

    remove(card: TopicCard):void {
        this.cards.remove(card);
    }

    add(card: TopicCard): void {
        if (!this.cards().includes(card))
            this.cards.push(card);
    }

    contains(card: TopicCard) {
        return _(this.cards()).contains(card);
    }

    clone(): TopicCardsGroup {
        return new TopicCardsGroup(this.toJson());
    }

    toJson(): JSONTopicCardsGroup {
        return {
            TopicCardGroupId: this.id(),
            Title: this.title(),
            Description: this.description(),
            IsCustom: this.isCustom(),
            CreateDate: this.createDate().toISOString(),
            Cards: this.cards().map(card => card.toJson())
        }
    }
}
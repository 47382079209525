import {Observable, Subscribable} from 'knockout';
import WCCEntity from 'models/entity';
import emojiPattern from "../../patterns/emoji";

const emojiPatternGlobal = new RegExp(emojiPattern, 'gi');

export interface JSONSurveyBlankOption {
    ProfileQuestionBlankOptionId: string
    Value: string
    SortOrder: number
}

export default class SurveyBlankOption extends WCCEntity<JSONSurveyBlankOption> {
    optionId: Observable<string>
    //choiceId: Observable<string>
    text: Observable<string>
    value: Observable<string>
    sortOrder: Observable<number>

    displayText: Subscribable<string>
    textHasEmojisOnly: Subscribable<boolean>

    constructor(data: JSONSurveyBlankOption) {
        super();

        this.optionId = this.createField(data, 'ProfileQuestionBlankOptionId');
        //this.choiceId = this.createField(data, 'ProfileQuestionBlankOptionId');
        this.text = this.createField(data, 'Value');
        this.value = this.createField(data, 'Value');
        this.sortOrder = this.createField(data, 'SortOrder');

        this.displayText = this.text.pluck(text => system.generateStringWithBasicFormattingTags(text));
        this.textHasEmojisOnly = this.displayText.pluck(choiceText => choiceText.replace(emojiPatternGlobal, '').trim().length == 0, false);
    }
}
import { Func } from 'interfaces/func';
import { Container } from "inversify";
import { wccModules } from '../../../enums/wccModules';
import { ManagersContext } from '../../../managers/context';
import { EffectsContainer } from '../../../mixins/withEffects';
import { ServicesContext } from '../../../services/context';
import { createContainer, createContainerUsingEffects } from './app';
import { ScopedModuleDefinition } from './helpers';

const definitions = new Array<ScopedModuleDefinition>(
    container => container.bind<ServicesContext>(wccModules.servicesContext).toDynamicValue(() => new ServicesContext()),
    container => container.bind<ManagersContext>(wccModules.managersContext).toDynamicValue(() => new ManagersContext())
);

export function createExtendedContainer(ignore?: Func<boolean, [module: any]>): [Container, Action] {
    return createContainer(ignore, definitions);
}

export function createExtendedContainerUsingEffects(effects: EffectsContainer, ignore?: Func<boolean, [module: any]>) {
    return createContainerUsingEffects(effects, ignore, definitions);
}

export const extendedAppContainer = createExtendedContainer()[0];
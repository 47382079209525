import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import {BaseService} from 'services/base';
import {BrowseFilterData} from "../models/filters/browseFilter";
import {apiAttributeData} from "../models/apiAttributeData";

export class AttributesDataService extends BaseService {
    private repository = new Repository(endpoint);

    adminQueries = {
        attributesData: () => this.repository.query<never, never, BrowseFilterData | null, apiAttributeData[]>('communities/attributes/data')
    }

    async getAttributesData(filterData: BrowseFilterData | null = null) {
        return await this.adminQueries.attributesData().post(filterData);
    }
}
import { Func } from 'interfaces/func';
import { Observable, ObservableArray, PureComputed, SubscribableOrValue } from 'knockout';
import { withEffects } from 'mixins/withEffects';
import { CollectionsHelpers } from '../../helpers/collections';
import { DateHelpers } from '../../helpers/date';
import { withEffect } from '../../mixins/withEffect';
import { apiTimeBoundaries } from '../../models/apiTimeBoundaries';
import WCCError from '../../models/wccError';

type LoadFunc<D> = (request: TimelineDataSourceLoadRequest) => Array<D> | PromiseLike<Array<D>>
type UpdateFunc<V> = (item: V) => void | PromiseLike<void>

enum TimelineIntervalState {
    pending = 0,
    loading = 1,
    loaded = 2,
    failed = 3
}

class TimelineInterval {
    state: Observable<TimelineIntervalState>

    constructor(public from: Date, public to: Date, state = TimelineIntervalState.pending) {
        this.state = ko.observable(state);
    }
}

export class TimelineIntervalsLoader {
    private effects = withEffects();

    private minDate?: Date
    private maxDate?: Date

    private intervals = ko.observableArray<TimelineInterval>();

    private isLoading = ko.observable(false);

    constructor(private requestFactory: Func<TimelineDataSourceLoadRequest, [from: Date, to: Date]>) {
        const pendingInterval = this.intervals.find(interval => interval.state() == TimelineIntervalState.pending);

        this.effects.register([
            ko.computed(() => {
                this.intervals.remove(interval => interval.state() == TimelineIntervalState.failed);
                this.mergeIntervals();
            }).extend({ deferred: true })
        ]);

        this.effects.register(async (interval, isLoading) => {
            if (interval != null && !isLoading) {
                try {
                    this.isLoading(true);

                    const min = this.minDate ?? interval.from;
                    const max = this.maxDate ?? interval.to;

                    if (interval.from <= max && interval.to >= min) {
                        const from = DateHelpers.max(min, DateHelpers.min(max, interval.from));
                        const to = DateHelpers.max(min, DateHelpers.min(max, interval.to));

                        interval.state(TimelineIntervalState.loading);

                        const request = this.requestFactory(from, to);

                        const task = await request.task.whenNotNull();
                        await task;

                        interval.state(TimelineIntervalState.loaded);
                    } else {
                        interval.state(TimelineIntervalState.loaded);
                    }
                } catch (ex) {
                    interval.state(TimelineIntervalState.failed);
                } finally {
                    this.isLoading(false);
                }
            }
        }, [pendingInterval, this.isLoading], { rateLimit: { timeout: 0, method: 'notifyWhenChangesStop' } });
    }

    load(from: Date, to: Date) {
        return ko.ignoreDependencies(() => {
            if (from > to)
                throw new WCCError('invalid range');

            const outerInterval = this.intervals().find(interval => interval.from <= from && interval.to >= to);

            if (outerInterval == undefined) {
                const leftInterval = this.intervals().find(interval => interval.to >= from && interval.to <= to);

                if (leftInterval != undefined)
                    from = leftInterval.to;

                const rightInterval = this.intervals().find(interval => interval.from >= from && interval.from <= to);

                if (rightInterval != undefined)
                    to = rightInterval.from;

                const interval = new TimelineInterval(from, to);

                const insertIdx = CollectionsHelpers.getInsertIdx(this.intervals(), interval, CollectionsHelpers.intervalsSorterAsc);
                this.intervals.splice(insertIdx, 0, interval);
            }

            return this.intervals
                .find(interval => interval.from <= from && interval.to >= to)
                .pluck(interval => interval.state() == TimelineIntervalState.loaded ? true : false, false);
        });
    }

    setBoundaries(from?: Date, to?: Date) {
        this.minDate = from;
        this.maxDate = to;
    }

    extendBoundaries(rangeStart: Date, rangeEnd?: Date) {
        rangeEnd ??= rangeStart;

        if (rangeStart > rangeEnd)
            throw new Error(messages.InvalidRequest);

        if (this.minDate != undefined && this.maxDate != undefined) {
            if (this.minDate != undefined && rangeStart < this.minDate)
                this.minDate = rangeStart;

            if (this.maxDate != undefined && rangeEnd > this.maxDate)
                this.maxDate = rangeEnd;
        } else {
            this.minDate = rangeStart;
            this.maxDate = rangeEnd;
        }
    }

    contains(rangeStart: Date, rangeEnd?: Date) {
        rangeEnd ??= rangeStart;

        if (rangeStart > rangeEnd)
            throw new Error(messages.InvalidRequest);

        return this.intervals().some(interval => rangeStart >= interval.from && rangeStart <= interval.to || <Date>rangeEnd >= interval.from && <Date>rangeEnd <= interval.to);
    }

    reset() {
        this.intervals([]);
    }

    dispose() {
        this.effects.dispose();
    }

    private mergeIntervals() {
        let saveChanges = false;

        const intervals = this.intervals().reduce((result, interval) => {
            const lastInterval = _.last(result);

            if (lastInterval != undefined && lastInterval.state() == interval.state() && interval.from.getTime() - lastInterval.to.getTime() <= 1) {
                result.pop();
                result.push(new TimelineInterval(lastInterval.from, interval.to, interval.state()));
                saveChanges = true;
            } else {
                result.push(interval)
            }

            return result;
        }, new Array<TimelineInterval>());

        if (saveChanges)
            this.intervals(intervals);
    }
}

interface LoadRequestConfig {
    from: Date
    to: Date
}

export class TimelineDataSourceLoadRequest {
    from: Date
    to: Date

    task = ko.observable<Promise<void>>();

    constructor(config: LoadRequestConfig) {
        this.from = config.from;
        this.to = config.to;
    }
}

interface TimelineDataSourceConfig<V, D = any, K = any> {
    key: Func<SubscribableOrValue<K>, [item: V]>
    time: Func<SubscribableOrValue<Date>, [item: V]>
    load: LoadFunc<D>
    update?: UpdateFunc<V>
    merge?: Func<V, [item: V, newItem: V]> | Action<[item: V, newItem: V]>
    mapper?: Func<V, [data: D]>
    boundaries: Func<apiTimeBoundaries | PromiseLike<apiTimeBoundaries>>
}

export class TimelineDataSource<V, D = any, K = any> {
    private effects = withEffects();
    private areBoundariesLoaded = ko.observable(false);

    private itemsToUpdate = ko.observableArray<V>();
    private itemToUpdate = ko.observable<V>();

    private loadRequests = ko.observableArray<TimelineDataSourceLoadRequest>();
    private loadRequest = ko.observable<TimelineDataSourceLoadRequest>();

    private intervalsLoader: TimelineIntervalsLoader

    private loadFunc: LoadFunc<D>
    private updateFunc: UpdateFunc<V>
    private mergeFunc: Func<V, [item: V, newItem: V]>
    private mapper: Func<V, [data: D]>
    private keyExtractor: Func<K, [item: V]>
    private timeExtractor: Func<Date, [item: V]>
    private boundariesLoader: Func<apiTimeBoundaries | PromiseLike<apiTimeBoundaries>>

    private canProcessNext: PureComputed<boolean>

    list: ObservableArray<V> = ko.observableArray();
    requestsProcessed = ko.observable(0);

    updating = ko.pureComputed(() => this.itemToUpdate() != undefined || this.itemsToUpdate().length > 0);
    loading = ko.pureComputed(() => this.loadRequest() != undefined || this.loadRequests().length > 0);
    busy = ko.pureComputed(() => this.loading() || this.updating());

    constructor(config: TimelineDataSourceConfig<V, D, K>) {
        this.loadFunc = config.load;
        this.updateFunc = config.update ?? _.noop;
        this.mergeFunc = (item, newItem) => config.merge?.(item, newItem) ?? item;
        this.mapper = config.mapper ?? <Func<V, [data: D]>>_.identity;
        this.boundariesLoader = config.boundaries;
        this.keyExtractor = item => ko.unwrap(config.key(item));
        this.timeExtractor = item => ko.unwrap(config.time(item));

        this.intervalsLoader = this.effects.register(new TimelineIntervalsLoader((from, to) => {
            const request = new TimelineDataSourceLoadRequest({ from, to });
            this.loadRequests.push(request);

            return request;
        }));

        this.canProcessNext = ko.pureComputed(() => this.itemToUpdate() == undefined && this.loadRequest() == undefined);
    }

    add(jsonItem: D) {
        var item = this.mapper(jsonItem);
        this.attach(item);

        return item;
    }

    attach(...newItems: Array<V>) {
        if (newItems.length > 0) {
            const list = [...this.list()];

            newItems.forEach(newItem => {
                const key = this.keyExtractor(newItem);
                const existingItemIdx = list.findIndex(item => this.keyExtractor(item) == key);

                if (existingItemIdx != -1) {
                    const existingItem = list[existingItemIdx];
                    list.splice(existingItemIdx, 1, this.mergeFunc(existingItem, newItem));
                } else {
                    const insertIdx = CollectionsHelpers.getInsertIdx(list, newItem, (l, r) => CollectionsHelpers.dateSorterAsc(this.timeExtractor(l), this.timeExtractor(r)));
                    list.splice(insertIdx, 0, newItem);
                }
            });

            this.list(list);
        }
    }

    update(keyOrPredicate: K | Func<boolean, [item: V]>) {
        const predicate = _.isFunction(keyOrPredicate) ? keyOrPredicate : ((item: V) => this.keyExtractor(item) === keyOrPredicate)

        this.list().forEach(item => {
            if (predicate(item) && !this.itemsToUpdate().includes(item))
                this.itemsToUpdate.push(item)
        });
    }

    updateRange(start: Date, end?: Date) {
        end ??= start;

        if (start > end)
            throw new Error(messages.InvalidRequest)

        this.intervalsLoader.extendBoundaries(start, end);

        if (this.intervalsLoader.contains(start, end))
            this.loadRequests.push(new TimelineDataSourceLoadRequest({ from: start, to: end }));
    }

    remove(itemOrPredicate: V | Func<boolean, [item: V]>) {
        if (_.isFunction(itemOrPredicate))
            this.list.remove(itemOrPredicate);
        else
            this.list.remove(itemOrPredicate);
    }

    load(from: Date, to: Date) {
        return this.areBoundariesLoaded.pluck(areBoundariesLoaded => {
            if (areBoundariesLoaded)
                return this.intervalsLoader.load(from, to);
        });
    }

    get(from: Date, to: Date) {
        return this.load(from, to).pluck(isLoaded => {
            if (isLoaded) {
                return this.list.filter(item => {
                    const time = this.timeExtractor(item);

                    return time >= from && time <= to;
                });
            }
        }, new Array<V>());
    }

    any(from: Date, to: Date, predicate?: Func<boolean, [item: V]>) {
        return this.load(from, to).pluck(isLoaded => {
            if (isLoaded) {
                return this.list.any(item => {
                    const time = this.timeExtractor(item);

                    return time >= from && time <= to && predicate?.(item) !== false;
                });
            }
        }, false);
    }

    async updateBoundaries() {
        try {
            const boundaries = await Promise.resolve(this.boundariesLoader());
            this.intervalsLoader.setBoundaries(DateHelpers.parseDate(boundaries.From), DateHelpers.parseDate(boundaries.To));
        } catch (ex) {
            console.error(ex);
        }
    }

    find(key: K): Promise<V> {
        return new Promise((resolve, reject) => this.findSync(key, resolve, reject));
    }

    findSync(key: K, resolve: Action<[item: V]> = _.noop, reject: Action<[ex: Error]> = _.noop) {
        const item = this.list().find(item => this.keyExtractor(item) === key);

        if (item != undefined)
            resolve(item);
        else
            reject(new Error('not found'));
    }

    reset() {
        this.intervalsLoader.reset();
        this.requestsProcessed(0);        
        this.list([]);
        this.areBoundariesLoaded(false);
        this.updateBoundaries().then(() => this.areBoundariesLoaded(true));
    }

    init() {
        this.updateBoundaries().then(() => this.areBoundariesLoaded(true));     

        this.effects.register(areBoundariesLoaded => {
            if (areBoundariesLoaded) {
                return [
                    withEffect(async (canProcessNext, requests) => {
                        if (canProcessNext && requests.length > 0) {
                            const request = requests[0];

                            this.loadRequest(request);
                            this.loadRequests.shift();

                            try {
                                await this.processLoadRequest(request);
                            } finally {
                                ko.tasks.schedule(() => this.loadRequest(undefined));
                            }
                        }
                    }, [this.canProcessNext, this.loadRequests]),

                    withEffect(async (canProcessNext, items) => {
                        if (canProcessNext && items.length > 0) {
                            var item = this.itemsToUpdate.shift();
                            this.itemToUpdate(item);

                            var contains = this.list().includes(item);

                            if (contains) {
                                try {
                                    await Promise.resolve(this.updateFunc(item));
                                } finally {
                                    this.itemToUpdate(undefined)
                                }
                            } else {
                                this.itemToUpdate(undefined);
                            }
                        }
                    }, [this.canProcessNext, this.itemsToUpdate])
                ]
            }
        }, [this.areBoundariesLoaded]);
    }

    dispose() {
        this.effects.dispose();
    }

    private async processLoadRequest(request: TimelineDataSourceLoadRequest) {
        try {
            const jsonContent = await Promise.resolve(this.loadFunc(request));
            this.processContent(request.from, request.to, jsonContent);
            this.requestsProcessed.inc();
            request.task(Promise.resolve());
        } catch (ex) {
            console.warn(ex, this.loadFunc);
            request.task(Promise.reject(ex));

            throw ex;
        }
    }

    private processContent(from: Date, to: Date, jsonItems: Array<D>) {
        const newItems = jsonItems.map(jsonItem => this.mapper(jsonItem));

        this.list.remove(item => {
            const itemKey = this.keyExtractor(item);
            const date = this.timeExtractor(item);

            return date >= from && date <= to && !newItems.some(newItem => itemKey == this.keyExtractor(newItem));
        });

        this.attach(...newItems);
    }
}
import { Container, interfaces } from "inversify";
import { Disposable, isDisposable } from "../../../interfaces/disposable";
import { Func } from "../../../interfaces/func";

const defaultIgnoreFunc = () => false;

export type ScopedModuleDefinition = (container: Container) => interfaces.BindingWhenOnSyntax<any>
export type ModuleDefinition = (container: Container) => interfaces.BindingInWhenOnSyntax<any>

const scopedDefinitions = new Array<ScopedModuleDefinition>();

function checkIfBelongsToContainer(container: Container, context: interfaces.Context) {
    const requests = new Array<interfaces.Request>();
    let request: interfaces.Request | undefined = context.currentRequest;

    while (request != undefined) {
        requests.push(request);
        request = request.parentRequest ?? undefined;
    }

    return requests.every(request => request.bindings.every(binding => container.isCurrentBound(binding.serviceIdentifier)));
}

function createContainerFromDefinitions(name: string, definitions: Array<ScopedModuleDefinition>, parent?: Container, ignore: Func<boolean, [module: any]> = defaultIgnoreFunc): [Container, Action] {
    const container = new Container({ autoBindInjectable: true });
    const disposables = new Array<Disposable>();

    if (parent != undefined)
        container.parent = parent;

    [...definitions].forEach(d => {
        const binding = d(container);

        binding.onActivation((context, module) => {
            if (isDisposable(module) && (!ignore(module)) && checkIfBelongsToContainer(container, context))
                disposables.push(module);

            return module;
        });
    });

    const dispose = () => disposables.forEach(disposable => disposable.dispose());

    return [container, dispose];
}

const rootContainer = createContainerFromDefinitions('root', [])[0];

export module InversifyHelpers {
    export function registerSingletonInjectable(definition: ModuleDefinition) {
        definition(rootContainer).inSingletonScope();
        return InversifyHelpers;
    }

    export function registerSingletonInjectables(...definitions: ModuleDefinition[]) {
        definitions.forEach(definition => registerSingletonInjectable(definition));
        return InversifyHelpers;
    }

    export function registerInjectable(definition: ScopedModuleDefinition) {
        definition(rootContainer);
        scopedDefinitions.push(definition);
        return InversifyHelpers;
    }

    export function registerTransientInjectable(definition: ModuleDefinition) {
        definition(rootContainer).inTransientScope();
        scopedDefinitions.push(definition);
        return InversifyHelpers;
    }

    export function createScope(definitions: Array<ScopedModuleDefinition> = [], ignore: Func<boolean, [module: any]> = defaultIgnoreFunc) {
        return createContainerFromDefinitions(system.getGuid(), [...scopedDefinitions, ...definitions], rootContainer, ignore);
    }
}
define('managers/topic/mandatoryProbes',
    [
        'managers/base_es5',
        'managers/datasources/collection',
        'factories/threads'
    ],

    function (Base, { CollectionDataSource: DataSource }, threadsFactory) {
        const { default: events } = require('managers/events');

        function Manager(topicId) {
            Base.call(this);

            this.topicId = ko.pureComputed(function () { return topicId });

            this._datasource = this._register(new DataSource({ load: this._load.bind(this), mapper: threadsFactory.buildThread }));

            this.mandatoryProbes = this._datasource.list
                .cache('id', function (oldProbe, newProbe) { oldProbe.update(newProbe.toJson()) })
                .filter(function (p) { return p.isResponseMandatory() });

            this.busy = this._datasource.busy;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.commentsService.subscriptions.topicThreads.subscribe(this.topicId());
            this._ctx.commentsService.events.threadResponseMandatorySettingChanged.on(this._onCommentResponseIsMandatory.bind(this));

            this._disposables.register([
                events.commentResponseIsMandatory.on(this._onLocalCommentResponseIsMandatory.bind(this)),
                events.replyAdded.on(this._onLocalReplyAdded.bind(this))
            ]);
        };

        Manager.prototype._load = function () {
            return this._ctx
                .topicsService
                .queries
                .mandatoryProbes(this.topicId())
                .important()
                .toArray();
        };

        Manager.prototype._onCommentResponseIsMandatory = function (discussionId, topicId, commentId, isMandatory) {
            if (this.topicId() === topicId) {
                var probe = _(this.mandatoryProbes()).find(function (probe) { return probe.id() === commentId });

                if (probe !== undefined) {
                    probe.isResponseMandatory(isMandatory);
                } else if (isMandatory) {
                    this._datasource.load();
                }
            }
        };

        Manager.prototype._onLocalCommentResponseIsMandatory = function (topicId, commentId, isMandatory) {
            if (this.topicId() === topicId) {
                var probe = _(this.mandatoryProbes()).find(function (probe) { return probe.id() === commentId });

                if (probe !== undefined) {
                    probe.isResponseMandatory(isMandatory);
                } else if (isMandatory) {
                    this._datasource.load();
                }
            }
        };

        Manager.prototype._onLocalReplyAdded = function (topicId, commentId, jsonReply) {
            if (this.topicId() === topicId) {
                var probe = _(this.mandatoryProbes()).find(function (probe) { return probe.id() === commentId });

                if (probe !== undefined && probe.isResponseMandatory() && probe.postedById() === jsonReply.PostedById)
                    probe.isResponseMandatory(false);
            }
        };

        return Manager;
    });
import UploadStates from 'enums/uploads/states';
import { Observable } from 'knockout';

interface WCCUploadConfig {
    name?: string
    width?: number
    height?: number
    preview?: string
    cancellable?: boolean
}

export default class WCCUpload {
    id = _.uniqueId('wcc-upload');

    state = ko.observable(UploadStates.pending);
    progress = ko.observable(0);

    name: Observable<string | undefined>
    width: Observable<number | undefined>
    height: Observable<number | undefined>
    preview: Observable<string | undefined>
    isCancellable: Observable<boolean>

    constructor(config?: WCCUploadConfig) {
        this.name = ko.observable<string|undefined>( (config?.name || '').replace(/[^\x00-\xFF]/g,'') );
        this.width = ko.observable(config?.width);
        this.height = ko.observable(config?.height);
        this.preview = ko.observable(config?.preview);
        this.isCancellable = ko.observable(config?.cancellable ?? true);
    }

    cancel() {
        if (this.isCancellable())
            this.state(UploadStates.canceled);
    }
}
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { ObservableArray, Subscribable } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CollectionDataSource } from 'managers/datasources/collection';
import events from 'managers/events';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import { TopicManager } from 'managers/topic';
import { withEffect } from 'mixins/withEffect';
import { EffectsContainer } from 'mixins/withEffects';
import WCCImage from 'models/attachments/image';
import TopicSlide, { JSONTopicSlide } from 'models/topicSlide';
import { LiveGroupService } from 'services/livegroup';
import TopicAttachmentsManager from './attachments';

export interface TopicSlidesManagerConfig {
    topicId: string
}

@injectable()
export default class TopicSlidesManager extends CollectionDataSourceOwner<TopicSlide, 'slideId'> {
    private topicId: string
    private topicDescription: Subscribable<string>
    private topicImages: Subscribable<Array<WCCImage>>

    private isReady: Subscribable<boolean>

    protected source: CollectionDataSource<TopicSlide, 'slideId'>

    slides: ObservableArray<TopicSlide>
    
    constructor(
        @inject(wccModules.managerConfig) config: TopicSlidesManagerConfig,
        @inject(wccModules.liveGroupService) private liveGroupService: LiveGroupService,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.topicId = config.topicId;

        const topicManager = storage.get(TopicManager, { topicId: this.topicId });
        const topic = topicManager.pluck(m => m.topic);
        const topicReady = topic.isNotNull();
        this.topicDescription = topic.pluck(t => t.thoroughDescription, '');

        const topicAttachmentsManager = storage.get(TopicAttachmentsManager, { topicId: this.topicId });
        const topicAttachmentsReady = topicAttachmentsManager.pluck(m => !m.loading(), false).extend({ deferred: true });
        this.topicImages = topicAttachmentsManager.pluck(m => m.images, []);

        this.isReady = ko.pureComputed(() => topicReady() && topicAttachmentsReady());          

        this.source = effects.register(new CollectionDataSource({
            key: 'slideId',
            load: this.load.bind(this),
            update: this.update.bind(this),
            merge: this.merge.bind(this),
            mapper: jsonSlide => new TopicSlide(jsonSlide)
        }));

        this.slides = this.source.list;

        effects.register([
            events.topicSlideAdded.on(this.onLocalTopicSlideAdded.bind(this)),
            events.topicSlideUpdated.on(this.onLocalTopicSlideUpdated.bind(this)),
            events.topicSlideDeleted.on(this.onLocalTopicSlideDeleted.bind(this)),
        ]);

        effects.register(slides => slides.map(slide => this.initSlide(slide)), [this.slides]);

        signalREvents.topicSlides(this.topicId)
            .onSlideAdded(() => this.source.load())
            .onSlideChanged((topicId, slideId) => this.source.update(slide => slide.slideId() == slideId))
            .onSlideRemoved((topicId, slideId) => this.source.remove(slide => slide.slideId() === slideId));

        signalREvents.topicNotifications(this.topicId)
            .onTopicChanged(() => this.source.load());
    }

    protected override checkIfLoading() {
        return !this.isReady() || super.checkIfLoading();
    }

    protected override checkIfUpdating() {
        return this.isReady() && super.checkIfUpdating();
    }

    private load() {
        return this.liveGroupService.queries.topicSlides(this.topicId).toArray();
    }

    private async update(slide: TopicSlide) {
        const jsonSlide = await this.liveGroupService.queries.slide(<string>slide.slideId()).firstOrDefault();
        slide.update(jsonSlide);
    }

    private merge(slide: TopicSlide, newSlide: TopicSlide) {
        slide.update(newSlide.toJson());
        return slide;
    }

    private initSlide(slide: TopicSlide) {
        switch (slide.type()) {
            case enums.apiWhiteboardSlideType.TopicIntro.value: return this.initIntroSlide(slide);
            case enums.apiWhiteboardSlideType.AllImages.value: return this.initAllImagesSlide(slide);
            case enums.apiWhiteboardSlideType.Link.value: return this.initLinkSlide(slide);
        }
    }

    private initIntroSlide(slide: TopicSlide) {
        slide.title(labels.Introduction);

        return withEffect(topicDescription => slide.content(topicDescription), [this.topicDescription]);
    }

    private initAllImagesSlide(slide: TopicSlide) {
        slide.title(labels.AllImages);

        return withEffect(topicImages => slide.attachments(topicImages), [this.topicImages]);
    }

    private initLinkSlide(slide: TopicSlide) {
        const title = ko.pureComputed(() => {
            const attachment = slide.attachments().find(a => a.fileType() === enums.AttachmentTypes.Link.value);

            if (attachment != undefined && attachment.title().length > 0)
                return attachment.title();

            return 'Link';
        });

        return withEffect(title => slide.title(title), [title]);
    }      

    private onLocalTopicSlideAdded(topicId: string, jsonSlide: JSONTopicSlide) {
        if (this.topicId === topicId)
            this.source.add(jsonSlide);
    }

    private onLocalTopicSlideUpdated(slideId: string, topicId: string, jsonSlide: JSONTopicSlide) {
        if (this.topicId === topicId)
            this.source.findSync(slideId, slide => slide.update(jsonSlide));
    }

    private onLocalTopicSlideDeleted(topicId: string, slideId: string) {
        if (this.topicId === topicId)
            this.source.remove(slide => slide.slideId() === slideId);
    }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class ClientsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            clients: () => this._repository.query('clients/'),
            client: (clientId) => this._repository.query(`clients/${clientId}`), // note the different quotes ` not ' for those that aren't in the know
            clientsForActivity: (activityId) => this._repository.query(`clients/activity/${activityId}`),
        };

        this.subscriptions = {
        };

        this.events = {
        };
    }

    /**
    * Returns all clients for logged in account
    */
    getClients() {
        return this.queries.clients().toArray();
    }

    /**
    * Returns client with ID for logged in account
    */
    getClient(clientId) {
        return this.queries.client(clientId).firstOrDefault();
    }

    addClient(client) {
        return this.queries.clients().post(client);
    }

    updateClient(client) {
        return this.queries.client(client.clientId()).update(client);
    }

    /**
    *  uses get client repository query to remove from client list
    */
    deleteClient(clientId) {
        return this.queries.client(clientId).remove();
    }

    /**
     * Returns the clients attached to an activity
     * @param {any} activityId
     */
    getClientsForActivity(activityId) {
        return this.queries.clientsForActivity(activityId).toArray();
    }
}
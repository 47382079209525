import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class UserFilesService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        userfile: (id: string) => this.repository.query<never, never, never, never, never, never, void>(`userfiles/${id}`),
        addpipeFiles: () => this.repository.query(`userfiles/addpipe`)
    }
}
define('managers/statistics/person/topicsCompletion', [
    'managers/base_es5',
    'managers/datasources/collection',
    'models/topicCompletionByPerson'
], function (Base, { CollectionDataSource: DataSource }, CompletionItem) {
    function Manager(discussionId, personId) {
        Base.call(this);

        this.discussionId = ko.pureComputed(function () { return discussionId });
        this.personId = ko.pureComputed(function () { return personId });

        this._source = this._register(new DataSource({
            key: 'topicId',
            load: this._load.bind(this),
            mapper: function (jsonItem) { return new CompletionItem(jsonItem) }
        }));

        this.statistics = this._source.list;

        this.busy = this._source.busy;
        this.loading = ko.pureComputed(function () { return this.busy() && this.statistics().length === 0 }, this);
        this.updating = ko.pureComputed(function () { return this.busy() && this.statistics().length > 0 }, this);
    }

    Manager.prototype = Object.create(Base.prototype);
    Manager.prototype.constructor = Manager;

    Manager.prototype._load = function () {
        return this._ctx
            .statisticsService
            .queries
            .personTopicsCompletionStat(this.discussionId(), this.personId())
            .toArray()
            .then(function (data) { return _(data).flatten() });
    };

    return Manager;
});
define('managers/base_es5', [
    'managers/disposable_es5',
    'services/context'    
], function (Base, { ServicesContext }) {
    function Manager() {
        Base.call(this);

        const { ManagersContext } = require('managers/context');

        this._ctx = this._register(new ServicesContext());
        this._components = this._ctx.components;
        this._managers = this._register(new ManagersContext());
    }

    Manager.prototype = Object.create(Base.prototype);
    Manager.prototype.constructor = Manager;

    return Manager;
});
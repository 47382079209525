import { injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CollectionDataSource } from './datasources/collection';

@injectable()
export default abstract class CollectionDataSourceOwner<V, K extends keyof V = never, O extends StringMap<any> = StringMap<any>> {
    protected abstract source: CollectionDataSource<V, K, O>

    busy: Subscribable<boolean>
    loading: Subscribable<boolean>
    updating: Subscribable<boolean>

    constructor() {
        this.busy = ko.pureComputed(() => this.source.busy());
        this.loading = ko.pureComputed(() => this.checkIfLoading());
        this.updating = ko.pureComputed(() => this.checkIfUpdating());
    }

    protected checkIfLoading() {
        return this.busy() && this.source.requestsProcessed() === 0;
    }

    protected checkIfUpdating() {
        return this.busy() && this.source.requestsProcessed() > 0;
    }
}
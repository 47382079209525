import { DOMHelpers } from 'helpers/dom';
import { Subscribable, SubscribableOrNullableValue, SubscribableOrValue } from 'knockout';
import { Effect, withEffect } from 'mixins/withEffect';
import { withEffects } from 'mixins/withEffects';
import NodeContentChangesTracker from './contentChangesTracker';

export interface NodeVisibilityTrackerConfig {
    element: SubscribableOrValue<Node>,
    container?: SubscribableOrNullableValue<Node>,
    enable?: SubscribableOrNullableValue<boolean>
    ignoreHeight?: SubscribableOrNullableValue<boolean>
}

export default class NodeVisibilityTracker {
    private effects = withEffects();
    private _isVisible = ko.observable(false);

    private element: Subscribable<Node>;
    private container: Subscribable<Node>;
    private enable: Subscribable<boolean>
    private ignoreHeight: Subscribable<boolean>

    isVisible: Subscribable<boolean>

    constructor(config: NodeVisibilityTrackerConfig) {
        this.element = ko.flattenComputed(config.element);
        this.container = ko.flattenComputed(config.container, this.element.pluck(element => <Node>DOMHelpers.getScrollingContainer(element) ?? document.body));
        this.enable = ko.flattenComputed(config.enable, true);
        this.ignoreHeight = ko.flattenComputed(config.ignoreHeight, false);

        this.isVisible = this._isVisible;

        const tracker = NodeContentChangesTracker.bodyTracker();
        const handler = _.throttle(() => this.update(), 100, { leading: false });

        this.effects.register(enable => {
            if (enable) {
                this.update();

                return [
                    withEffect(handler, [tracker.mutations]),

                    withEffect(container => {
                        if (container != undefined) {
                            const effects = new Array<Effect>(DOMHelpers.onNodeEvent(container, 'scroll', handler));

                            if (container == document.body)
                                effects.push(DOMHelpers.onNodeEvent(document, 'scroll', handler));

                            return effects;
                        }
                    }, [this.container]),

                    () => this._isVisible(false)
                ]
            }
        }, [this.enable]);
    }

    dispose() {
        this.effects.dispose();
    }

    private update() {
        requestAnimationFrame(() => {
            this._isVisible(DOMHelpers.isInViewPort(this.element(), this.container(), { ignoreHeight: this.ignoreHeight() }));
        });
    }
}
import { DateHelpers } from "helpers/date";
import { injectable } from "inversify";
import GroupRoomEvent from "models/appointments/groupRoomEvent";
import { IAppointmentEventsFormatter } from "./formatted.interfaces";

@injectable()
export default class AppointmentEventsFormatter implements IAppointmentEventsFormatter {
    format(event: GroupRoomEvent): string | undefined {
        const person = event.person();
        const currentTopic = event.currentTopic();
        const currentSlide = event.currentSlide();
        const prevSlide = event.previousSlide();

        switch (event.type()) {
            case enums.GroupRoomEventType.TopicChange.value:
                return `Topic changed to "${currentTopic?.title()}" at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.MemberJoined.value:
            case enums.GroupRoomEventType.InterviewerJoined.value:
                return `${person?.fullName()} joined interview at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.MemberLeft.value:
            case enums.GroupRoomEventType.InterviewerLeft.value:
                return `${person?.fullName()} left interview at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.BackroomMemberJoined.value:
                return `${person?.fullName()} joined backroom at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.BackroomMemberLeft.value:
                return `${person?.fullName()} left backroom at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.Start.value:
                return `Interview started at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.End.value:
                return `Interview ended at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.RecordingStarted.value:
                return `Interview recording started at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.RecordingEnded.value:
                return `Interview recording ended at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.SlideChange.value:
                return currentSlide != undefined ? `Interview slide changed to "${currentSlide.title()}" at ${this.getTimestamp(event)}` :
                    prevSlide != undefined ? `Interview slide "${prevSlide?.title()}" hidden at ${this.getTimestamp(event)}` : undefined;

            case enums.GroupRoomEventType.SlideDocumentPageChange.value:
                return `Interview slide "${currentSlide?.title()}" document page changed to ${event.slideDocumentPageIdx()} at ${this.getTimestamp(event)}`;

            case enums.GroupRoomEventType.SlideVideoStateChange.value:
                return `Interview slide "${currentSlide?.title()}" video state changed to ${this.getFormattedSlideVideoStateChange(event)} at ${this.getTimestamp(event)}`;
        }
    }

    private getTimestamp(event: GroupRoomEvent) {
        return DateHelpers.format(event.createDate(), 'HH:mm');
    }

    private getFormattedSlideVideoStateChange(event: GroupRoomEvent) {
        return `${this.getFormattedSlideVideoState(event)} (${this.getFormattedSlideVideoPosition(event)})`;
    }

    private getFormattedSlideVideoState(event: GroupRoomEvent): string {
        switch (event.slideVideoState()) {
            case enums.GroupRoomSlideVideoState.Playing.value: return 'Playing';
            case enums.GroupRoomSlideVideoState.Paused.value: return 'Paused';
        }

        return '';
    }

    private getFormattedSlideVideoPosition(event: GroupRoomEvent): string {
        const videoPosition = event.slideVideoPosition();

        if (videoPosition != undefined)
            return DateHelpers.formatSeconds(videoPosition);

        return '';
    }
}
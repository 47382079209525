import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import events from 'managers/events';
import { apiAvatar } from 'models/apiAvatar';
import { JSONPerson } from 'models/person';
import { JSONOnlineUserSession } from 'models/signalR/onlineUserSession';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import { IPeopleService } from './iPeople';

const { sitePrefix, sitePostfix } = settings;

export class PeopleService extends BaseService implements IPeopleService {
    private repository = new Repository(endpoint);

    personQuery = (personId: string) => this.repository.entityGetQuery<JSONPerson>(`people/${personId}`);
    discussionPersonQuery = (discussionId: string, personId: string) => this.repository.entityGetQuery<JSONPerson>(`discussions/${discussionId}/people/${personId}`);
    currentParticipantSessionQuery = (discussionId: string) => this.repository.entityGetQuery<JSONOnlineUserSession>(`discussions/${discussionId}/participants/me/session`);

    queries = {
        person: (personId: string) => this.repository.query<never, JSONPerson, never, never, never, never, never>(`people/${personId}`),
        topicParticipants: (topicId: string) => this.repository.query(`${sitePrefix}topics/${topicId}/participants`).addArgs(sitePostfix),
        topicParticipant: (topicId: string, personId: string) => this.repository.query<never, JSONPerson, never, never, never, never, never>(`topics/${topicId}/participants/${personId}`),
        topicOnlineParticipantsIds: (topicId: string) => this.repository.query<never, string, never, never, never, never, never>(`topics/${topicId}/participants/online/id`),
        discussionOnlineMembersIds: (discussionId: string) => this.repository.query<never, string, never, never, never, never, never>(`discussions/${discussionId}/members/online/id`),
        discussionParticipants: (discussionId: string) => this.repository.query<never, JSONPerson | undefined, never, never, never, never, never>(`discussions/${discussionId}/participants`),
        discussionParticipant: (discussionId: string, personId: string) => this.repository.query(`discussions/${discussionId}/participants/${personId}`),
        discussionParticipantsTiny: (discussionId: string) => this.repository.query(`discussions/${discussionId}/participants/tiny`),
        discussionOnlinePeople: (discussionId: string) => this.repository.query<never, JSONOnlineUserSession, never, never, never, never, never>(`discussions/${discussionId}/people/online}`),
        slideParticipants: (slideId: string) => this.repository.query(`whiteboard/slides/${slideId}/participants`),
        threadParticipants: (threadId: string) => this.repository.query(`threads/${threadId}/participants`),
        peopleByEmailCode: (emailCode: string) => this.repository.query(`people/emailCode/${emailCode}`),
        peopleBySMSCode: (emailCode: string) => this.repository.query(`people/emailCode/${emailCode}?batchType=SMS`),
        personAvatar: () => this.repository.query<never, never, apiAvatar, void, never, never, never>('people/me/avatar'),
        personNote: (noteId: string) => this.repository.query(`people/notes/${noteId}`),
        personNotes: (personId: string) => this.repository.query(`people/${personId}/notes`),
        currentPerson: () => this.repository.query('people/me'),
        communityPeople: () => this.repository.query('community/people'),
        currentParticipant: (discussionId: string) => this.repository.query(`discussions/${discussionId}/participants/me`),
        qrCode: (personId: string) => this.repository.query(`people/${personId}/qrcode`),
        filteredEngagementPeople: (discussionId: string) => this.repository.query(`discussions/${discussionId}/engagement/filteredparticipants`),
        setDeclined: (code: string | null) => this.repository.query(`people/me/ConfirmedDecline${code ? '/' + code : ''}`)
    }

    subscriptions = {
        accountPeople: this.signalRSubscription('AccountPeople'),
        person: this.signalRSubscription('Person'),
        personNotifications: this.signalRSubscription('PersonNotifications')
    }

    events = {
        personChanged: this.signalREvent('PersonChanged'),
        personTagsChanged: this.signalREvent('PersonTagsChanged'),
        discussionMemberOffline: this.signalREvent('DiscussionMemberOffline'),
        participantStatusChanged: this.signalREvent('ParticipantStatusChanged')
    }

    messages = {
        topicMemberIsWorking: this.signalRMessage('TopicMemberIsWorking')
    }

    /**
    * Returns first topic participant. supports oData
    * @param topicId - topic identity
    * @param oData - oData params
    */
    getTopicParticipant(topicId: string, oData: URLQueryData) {
        return this.queries.topicParticipants(topicId).addArgs(oData).firstOrDefault();
    }

    /**
     * Returns topic participants. supports oData
     * @param topicId - topic identity
     * @param oData - oData params
     */
    getTopicParticipants(topicId: string, oData: URLQueryData) {
        return this.queries.topicParticipants(topicId).addArgs(oData).toArray();
    }

    /**
     * Returns topic participant by personId.
     * @param topicId - topic identity
     * @param personId - person identity
     */
    getTopicParticipantById(topicId: string, personId: string) {
        return this.queries.topicParticipant(topicId, personId).firstOrDefault();
    }

    /**
     * Returns qr code by personId.
     * @param personId - person identity
     */
    getQRCodeById(personId: string) {
        return this.queries.qrCode(personId).firstOrDefault();
    }

    /**
     * Returns topic online participants ids by topicId.
     * @param topicId - topic identity
     */
    getTopicOnlineParticipantsIds(topicId: string) {
        return this.queries.topicOnlineParticipantsIds(topicId).toArray();
    }

    /**
     * Returns discussion online participants ids by discussionId.
     * @param discussionId - discussion identity
     */
    getDiscussionOnlineMembersIds(discussionId: string) {
        return this.queries.discussionOnlineMembersIds(discussionId).toArray();
    }

    /**
     * Returns discussion participants by discussionId. supports oData
     * @param discussionId - discussion identity
     * @param oData - oData params
     */
    getDiscussionParticipants(discussionId: string, oData: URLQueryData) {
        return this.queries.discussionParticipants(discussionId).addArgs(oData).toArray();
    }

    /**
     * Returns first discussion participant by discussionId. supports oData
     * @param discussionId - discussion identity
     * @param oData - oData params
     */
    getDiscussionParticipant(discussionId: string, oData: URLQueryData) {
        return this.queries.discussionParticipants(discussionId).addArgs(oData).firstOrDefault();
    }

    /**
     * Returns participants who see current slide
     * @param slideId - slide identity
     */
    getSlideParticipants(slideId: string) {
        return this.queries.slideParticipants(slideId).toArray();
    }

    /**
    * Returns thread participants
    * @param threadId - thread identity
    */
    getThreadParticipants(threadId: string) {
        return this.queries.threadParticipants(threadId).toArray();
    }

    /**
    * Returns person data by given email activation code
    * @param emailCode - email activation code
    */
    getPersonByEmailCode(emailCode: string, isSMS: boolean) {
        if (isSMS) {            
            return this.queries.peopleBySMSCode(emailCode).firstOrDefault();
        }
        else {
            return this.queries.peopleByEmailCode(emailCode).firstOrDefault();
        }
    }

    async setPersonAvatar(avatar: apiAvatar) {
        await this.queries.personAvatar().add(avatar);
        events.personAvatarChanged.trigger(avatar.ImageUrl);
    }

    /**
    * Returns current person data
    */
    getCurrentPerson() {
        return this.queries.currentPerson().firstOrDefault();
    }

    /**
    * Save/update community person data (for admins only)
    */
    saveCommunityPerson(personData: any) {
        return this.queries.communityPeople().add(personData);
    }

    /**
    * Generates a new qr code for the person having the passed id
    */
    generateNewQRCode(personId: string) {
        return this.queries.qrCode('').update("=" + personId);
    }    

    /**
    * Deletes person note
    * @param noteId - note identity
    */
    deletePersonNote(noteId: string) {
        return this.queries.personNote(noteId).remove();
    }

    /**
    * Adds new or updates existing person note
    * @param personId - person identity
    * @param noteData - note data
    */
    savePersonNote(personId: string, noteData: any) {
        return this.queries.personNotes(personId).add(noteData);
    }

    /**
    * Gets filtered people for engagement browse
    * @param discussionId - discussion identity
    * @param filterData - filter data
    */
    getFilteredEngagementPeople(discussionId: string, filterData: any) {
        return this.queries.filteredEngagementPeople(discussionId).add(filterData);
    }

    setDeclinedByEmailCode(emailCode: string) {
        return (this.queries.setDeclined(emailCode).post() as JQueryPromise<any>)
            .done((personId: string) => events.personStatusChanged.trigger(personId, enums.MembershipStatusTypes.Declined.value));
    }
}
define('managers/mandatoryProbesTopic',
    [
        'managers/base_es5'        
    ],

    function (Base) {
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');
        const Topic = require('models/topic').Topic;

        function Manager() {
            Base.call(this);

            this.discussionId = ko.pureComputed(function () { return settings.discussionId });

            this._datasource = this._register(new DataSource({ update: this._load.bind(this) }));

            this.topic = this._datasource.data.toEntity(function (jsonTopic) { return new Topic(jsonTopic) });
            this.busy = this._datasource.busy();
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.update = function () {
            this._datasource.update();
        };

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.commentsService.subscriptions.discussionThreads.subscribe(this.discussionId());
            this._ctx.commentsService.events.threadResponseMandatorySettingChanged.on(this._onCommentResponseIsMandatory.bind(this));
        };

        Manager.prototype._load = function () {
            return this._ctx
                .tasksService
                .queries
                .getMandatoryProbes(this.discussionId())
                .important()
                .firstOrDefault();
        };

        Manager.prototype._onCommentResponseIsMandatory = function (discussionId, topicId, threadId, isMandatory) {
            if (this.discussionId() === discussionId && this.topic() === undefined && isMandatory)
                this.update();
        };

        return Manager;
    });
import { FilesHelpers } from 'helpers/files';
import { BlobWrapper } from 'interfaces/blobWrapper';
import WCCImage from 'models/attachments/image';
import WCCUpload from 'models/upload';
import WCCUploader from '../uploader';
import transportFactory from './transportFactory';
import { WCCS3UploadResult } from './uploadResult';

const { allowedUploadImageExtensionsForTopicThreads, v2Attachments } = settings;

export interface WCCS3ImageUploaderConfig {
    attachmentType?: number
    folder?: string
}

export default class WCCS3ImageUploader extends WCCUploader<WCCImage, WCCS3UploadResult> {
    private attachmentType: number

    constructor(attachmentTypeOrConfig?: number | WCCS3ImageUploaderConfig) {
        const config = _.isNumber(attachmentTypeOrConfig) ?
            { attachmentType: attachmentTypeOrConfig } :
            attachmentTypeOrConfig;

        const folder = config?.folder;

        super({
            transport: transportFactory.getTransport({
                type: 'image',
                key: folder,

                allowMultiple: true,
                allowedExtensions: allowedUploadImageExtensionsForTopicThreads
            }),

            allowedExtensions: allowedUploadImageExtensionsForTopicThreads
        });

        this.attachmentType = config?.attachmentType ?? enums.AttachmentTypes.Image.value;
    }

    protected override async getUpload(fileOrBlob: File | BlobWrapper) {
        try {
            const preview = await FilesHelpers.getPreview(fileOrBlob);

            return new WCCUpload({
                name: fileOrBlob.name,
                width: preview.width,
                height: preview.height,
                preview: preview.src
            });
        } catch(ex) {
            return new WCCUpload({ name: fileOrBlob.name });
        }
    }

    protected getResult(uploadResult: WCCS3UploadResult, upload: WCCUpload, fileOrBlob: File | BlobWrapper) {
        if (v2Attachments)
            return new WCCImage({
                FileType: this.attachmentType,
                CreateDate: new Date().toISOString(),

                Files: [
                    {
                        UserFileId: uploadResult.fileId,
                        Name: fileOrBlob.name,
                        Extension: system.getExtension(fileOrBlob.name),
                        Link: uploadResult.link,
                        Width: upload.width(),
                        Height: upload.height(),
                        Type: enums.UserFileTypes.OriginalImage.value,
                        CreateDate: new Date().toISOString()
                    }
                ]
            });

        return new WCCImage({
            FileType: this.attachmentType,
            Link: uploadResult.link,
            Width: upload.width(),
            Height: upload.height(),
            UserFileId: uploadResult.fileId,
            CDNFileName: uploadResult.key,
            CreateDate: new Date().toISOString()
        });
    }
}
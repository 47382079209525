import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

const { sitePrefix, isSuperAdmin } = settings;

export class StatisticsService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        participantStat: (discussionId: string, personId: string) => this.repository.fastQuery(`discussions/${discussionId}/participants/${personId}/stat`),
        moderatorStat: (discussionId: string, personId: string) => this.repository.fastQuery(`discussions/${discussionId}/moderators/${personId}/stat`),
        discussionStat: (discussionId: string) => this.repository.fastQuery((isSuperAdmin ? 'superadmin/' : '') + `discussions/${discussionId}/comments/stat`),
        projectStat: (projectId: string) => this.repository.fastQuery(`projects/${projectId}/comments/stat`),
        activityStat: (activityId: string) => this.repository.fastQuery(`activities/${activityId}/comments/stat`),
        topicStat: (topicId: string) => this.repository.fastQuery(`topics/${topicId}/comments/stat`),
        topicsCompletionStat: (discussionId: string) => this.repository.fastQuery(`discussions/${discussionId}/topics/stat/completion`),
        personTopicsCompletionStat: (discussionId: string, personId: string) => this.repository.fastQuery(`discussions/${discussionId}/people/${personId}/topiccompletion`),
        surveyStat: (surveyId: string) => this.repository.fastQuery(`${sitePrefix}surveys/${surveyId}/stat`),
        surveyStatWithAdvancedFiltering: (surveyId: string) => this.repository.fastQuery(`${sitePrefix}surveys/${surveyId}/stat/advancedFiltering`)
    }

    /**
    * Return participant stat
    * @param discussionId - discussion identity
    * @param personId - person identity
    */
    getParticipantStat(discussionId: string, personId: string) {
        return this.queries.participantStat(discussionId, personId).firstOrDefault();
    }

    /**
    * Return moderator stat
    * @param discussionId - discussion identity
    * @param personId - person identity
    */
    getModeratorStat(discussionId: string, personId: string) {
        return this.queries.moderatorStat(discussionId, personId).firstOrDefault();
    }

    /**
    * Returns discussion stat
    * @param discussionId - discussion identity
    */
    getDiscussionStat(discussionId: string) {
        return this.queries.discussionStat(discussionId).firstOrDefault();
    }

    /**
     * Returns topic stat
     * @param topicId - topic identity
     */
    getTopicStat(topicId: string) {
        return this.queries.topicStat(topicId).firstOrDefault();
    }

    /**
    * Returns topics completion stat
    * @param discussionId - discussion identity
    * @param oData - oData
    */
    getTopicsCompletionStat(discussionId: string, oData: URLQueryData) {
        return this.queries.topicsCompletionStat(discussionId).addArgs(oData).toArray();
    }

    /**
    * Returns Survey statistics
    * @param surveyId - servey identity
    */
    getSurveyStat(surveyId: string) {
        return this.queries.surveyStat(surveyId).firstOrDefault();
    }

    /**
    * Returns Survey statistics
    * @param surveyId - survey identity
    * @param filtersData - stat filters data 
    * @param oData
    */
    getSurveyStatFiltered(surveyId: string, filtersData: any, oData: URLQueryData) {
        return this.queries.surveyStat(surveyId).addArgs(oData).firstOrDefaultPost(filtersData);
    }
}
import { Func } from 'interfaces/func';
import ko from 'knockout';
import backgroundImageURLPattern from 'patterns/backgroundImageURL';
import { isElement } from 'underscore';
import { Disposable } from '../interfaces/disposable';
import { RenderComponentConfig } from './dom.interfaces';

type JQueryElementEventHandler = (e: JQuery.TriggeredEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => void
export type ElementLike = Node | Window | Document | undefined

interface ViewPortCheckOptions {
    ignoreHeight?: boolean
}

const nodesDisplayTypesMap = new Map<string, string>();

export module DOMHelpers {
    export const getScrollbarWidth = _.memoize(() => {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";

        document.body.appendChild(outer);

        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";

        // add innerdiv
        var inner = document.createElement("div");
        outer.appendChild(inner);

        var widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode?.removeChild(outer);

        return widthNoScroll - widthWithScroll;
    });

    /**
     * Creates hidden div inside body
     * can be used as removable container for hidden UI component
     * */
    export function getHiddenContainer() {
        const $body = $(document.body);
        const $container = $(`<div class="hidden" />`);

        $body.append($container);

        return $container;
    }

    export function onAnimationComplete(node: HTMLElement, func: JQueryElementEventHandler, once = true) {
        var $node = $(node);

        var animations = {
            "animation": "animationend",
            "OAnimation": "oAnimationEnd",
            "MozAnimation": "animationend",
            "WebkitAnimation": "webkitAnimationEnd",
            "MSAnimation": "MSAnimationEnd"
        };

        var eventName = _(animations).find((a, key: any) => node.style[key] !== undefined);

        if (eventName) {
            if (once) {
                $node.one(eventName, func);
            } else {
                $node.on(eventName, func);
            }
        }

        return {
            dispose: () => {
                if (eventName) {
                    $node.off(eventName, func)
                }
            }
        }
    }

    export function onTransitionComplete(node: HTMLElement, func: JQueryElementEventHandler, once = true) {
        var $node = $(node);

        var transitions = {
            "transition": "transitionend",
            "OTransition": "oTransitionEnd",
            "MozTransition": "transitionend",
            "WebkitTransition": "webkitTransitionEnd",
            "MSTransition": "MSTransitionEnd"
        }

        var eventName = _(transitions).find((t, key: any) => node.style[key] !== undefined);

        if (eventName != null) {
            if (once) {
                $node.one(eventName, func);
            } else {
                $node.on(eventName, func);
            }
        }

        return {
            dispose: () => {
                if (eventName) {
                    $node.off(eventName, func)
                }
            }
        }
    }

    export function redraw(node: HTMLElement) {
        let display = node.style.display;

        node.style.display = 'none';
        node.offsetHeight;
        node.style.display = display;
    }

    export function on(node: Node | JQuery | Window | Document | MediaDevices, type: string, action: JQueryElementEventHandler, once = false) {
        if (node == document.documentElement)
            node = document;

        if (once) {
            const _action = action;

            action = e => {
                _action(e);
                dispose();
            }
        }

        const $node = <JQuery<HTMLElement>>$(node);
        $node.on(type, action);

        const dispose = () => {
            $node.off(type, action);
        }

        return <Disposable>{ dispose }
    }

    export function onNodeEvent(node: Node | JQuery | Window | Document, type: string, action: JQueryElementEventHandler, once = false) {
        return on(node, type, action, once);
    }

    export function assignClassToNode(node: HTMLElement | JQuery | Window | Document, className: string) {
        const $node = $(node);
        $node.addClass(className);

        return () => $node.removeClass(className);
    }

    export function createComponent(name: string, viewmodel?: { model?: any, data?: any, ref?: any }, init?: (node: HTMLElement) => void) {
        init ??= _.noop;
        viewmodel = { model: null, data: null, ref: null, ...viewmodel }

        let node = $(`<${name} params="model: model, data: data, ref: ref"></${name}>`)[0];
        ko.applyBindings(viewmodel, node);

        init(node);

        return node;
    }

    export function renderComponent<M>(config: RenderComponentConfig<M>) {
        const container = _.isString(config.container) ? document.querySelector(config.container) : config.container;

        if (container == undefined)
            throw new Error('container not found');

        const element = DOMHelpers.createComponent(config.name, { model: config.model });

        container.appendChild(element);

        return element;
    }

    export function createBindedFragment<T = any>(template: string, viewmodel: T = <any>{}) {
        const container = document.createElement('div');
        container.innerHTML = template;

        ko.applyBindings(viewmodel, container);

        const fragment = document.createDocumentFragment();
        fragment.append(...container.children);

        return fragment;
    }    

    export function createBindedNodes<T = any>(template: string, viewmodel: T = <any>{}): Node[] {
        return [...createBindedFragment(template, viewmodel).childNodes];
    }

    export function isInViewPort(element?: Node, container?: ElementLike, options?: ViewPortCheckOptions) {
        if (element == undefined || !isElement(element))
            return false;

        const scrollingElement = getScrollingElement(container);

        const containerRect = scrollingElement.getBoundingClientRect();
        const itemRect = element.getBoundingClientRect();

        const ignoreHeight = options?.ignoreHeight ?? false;

        const isValid = itemRect.width > 0 && (ignoreHeight || itemRect.height > 0) &&
            containerRect.width > 0 && (ignoreHeight || containerRect.height > 0);

        let result = false;

        if (isValid) {
            if (scrollingElement === container) {
                const viewportHeight = window.innerHeight;

                const itemTop = Math.floor(itemRect.top);
                const itemBottom = Math.floor(itemRect.bottom);

                if (container == document.body) {
                    result = itemTop > 0 && itemTop < viewportHeight &&
                        itemBottom > 0 && itemBottom < viewportHeight;
                } else {
                    const containerTop = Math.floor(containerRect.top);
                    const containerBottom = Math.floor(containerRect.bottom);

                    result = itemTop > 0 && itemTop < viewportHeight && itemTop >= containerTop &&
                        itemBottom > 0 && itemBottom < viewportHeight && itemBottom <= containerBottom;
                }
            } else {
                const itemTop = itemRect.top - containerRect.top - scrollingElement.scrollTop;
                const itemBottom = itemRect.bottom - containerRect.top - scrollingElement.scrollTop;

                result = itemTop <= containerRect.height && itemBottom >= 0;
            }
        }

        return result;
    }

    export function getScrollingElement(target: ElementLike): HTMLElement {
        if (target == undefined || target === window || target === document)
            return document.documentElement;
        else
            return target as HTMLElement;
    }

    export function scrollTo(element: string | Element, container?: string | Element) {
        var $container = $(container as any),
            $element = $(element as any),
            offset = 80;

        if (document.documentElement.contains($element[0]) && !isInViewPort($element[0], $container[0])) {
            if ($container.length === 0 || container == document.body) {
                $('html, body').animate({
                    scrollTop: ($element.offset()?.top ?? 0) - offset
                });
            } else {
                $container.animate({
                    scrollTop: ($container.scrollTop() ?? 0) - ($container.offset()?.top ?? 0) + ($element.offset()?.top ?? 0) - offset
                });
            }
        }
    }

    export const scrollToDelayed = _.throttle((element: HTMLElement) => {
        scrollTo(element, getScrollingContainer(element));
    }, 250, { leading: false });
    
    export function getScrollingContainer(element: Node) {
        if (!document.body.contains(element))
            return undefined;

        let $container = $(<HTMLElement>element).parent();

        while ($container[0] !== document.body) {
            var overflow = $container.css('overflow-y');

            if (overflow === 'auto' || overflow === 'scroll')
                return $container[0];

            $container = $container.parent();
        }

        return $container[0];
    }

    export function waitFor(selector: string | Func<HTMLElement | undefined>, oCancelToken?: () => boolean) {
        const getNode = () => _.isString(selector) ? $(selector)[0] : selector();

        return new Promise<HTMLElement>((resolve, reject) => {
            var node = getNode();

            if (node) {
                resolve(node);
            } else {
                var observer = new MutationObserver(() => {
                    if (oCancelToken && oCancelToken()) {
                        observer.disconnect();
                        reject(new Error('cancelled'));
                    }

                    node = getNode();

                    if (node) {
                        observer.disconnect();
                        resolve(node);
                    }
                });

                observer.observe(document.documentElement, { childList: true, subtree: true });
            }
        });
    }

    export function getTemplateSectionNodes(nodes: Array<Node>, tagName: string) {
        return $(nodes).filter(tagName).contents().toArray() as Array<Node>;
    }

    export function isHTMLElement(node: Node): node is HTMLElement {
        return node.nodeType === Node.ELEMENT_NODE;
    }

    export function getElementBackgroundImageURL(element: HTMLElement) {
        const src = $(element).css('background-image');
        const matches = Array.from(backgroundImageURLPattern.exec(src) ?? []);

        if (matches.length >= 2)
            return matches[1];
    }

    export function isMetaElement(element: Element): element is HTMLMetaElement {
        return element.tagName.toLowerCase() == 'meta';
    }

    export function isParagraphElement(element: Element): element is HTMLParagraphElement {
        return element.tagName.toLowerCase() == 'p';
    }

    export function isLinkElement(element: Element): element is HTMLLinkElement {
        return element.tagName.toLowerCase() == 'link';
    }

    export function isText(node: Node): node is Text {
        return node.nodeType === Node.TEXT_NODE;
    }

    export function getDisplayType(node: Element) {
        const tagName = node.tagName;
        let displayType = nodesDisplayTypesMap.get(tagName);

        if (displayType == undefined) {
            const container = document.createElement('div');
            document.body.appendChild(container);

            const nodeCopy = document.createElement(tagName);
            container.appendChild(nodeCopy);

            const styles = window.getComputedStyle(nodeCopy);

            displayType = styles.display;
            nodesDisplayTypesMap.set(tagName, displayType);

            document.body.removeChild(container);
        }

        return displayType;
    }
}
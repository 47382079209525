import { injectable } from "inversify";
import { ICalendarVM } from "../calendar/calendar.interfaces";
import { CalendarControlVMConfig } from "../calendar/controls/control/control.interfaces";
import { ICalendarProjectControlsFactory } from "./projectControlsFactory.interfaces";

@injectable()
export default class CalendarProjectControlsFactory implements ICalendarProjectControlsFactory {
    build(calendar: ICalendarVM, openDate?: Date, closeDate?: Date): Array<CalendarControlVMConfig> {
        const result = new Array<CalendarControlVMConfig>();

        if (openDate != undefined)
            result.push({ content: 'Project Start', click: () => calendar.date(openDate), order: -1 });

        if (closeDate != undefined)
            result.push({ content: 'Project End', click: () => calendar.date(closeDate), order: 1 });

        return result;
    }
}
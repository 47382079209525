define('managers/community/surveys',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const DataSource = require('managers/datasources/collection').CollectionDataSource;
        const Survey = require('models/simpleTopic').SimpleTopic;

        function Manager() {
            Base.call(this);

            this._source = this._register(new DataSource({
                key: 'id',
                load: this._load.bind(this),
                mapper: function (jsonSurvey) { return new Survey(jsonSurvey) }
            }));

            this.surveys = this._source.list;

            this.busy = this._source.busy;
            this.loading = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() === 0 }, this);
            this.updating = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() > 0 }, this);
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype._load = function () {
            return this._ctx
                .communitiesService
                .queries
                .surveys()
                .toArray();
        };

        return Manager;
    });
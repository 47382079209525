import { Observable, ObservableArray } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyBlankOption, { JSONSurveyBlankOption } from './blankOption';

export interface JSONSurveyBlank {
    ContentId: string
    ProfileQuestionBlankId: string
    Type: number
    
    Options: Array<JSONSurveyBlankOption>

    LimitType: number
    MinLimit: number
    MaxLimit: number
    BlankHint: string
    BlankWidth: number

    Digits: number
    Decimals: number

    ShowCalendar: boolean
    ShowTimeSelector: boolean

    ChoiceSelectionLimitMode: number
    MultipleSelection: boolean
    MinChoices: number
    MaxChoices: number
}

export default class SurveyBlank extends WCCEntity<JSONSurveyBlank> {
    blankId: Observable<string>
    contentId: Observable<string>
    type: Observable<number>

    options: ObservableArray<SurveyBlankOption>

    oLimitType: Observable<number>;
    oMinLimit: Observable<number>;
    oMaxLimit: Observable<number>;
    oBlankHint: Observable<string>;
    oBlankWidth: Observable<number>;

    oDigits: Observable<number>;
    oDecimals: Observable<number>;

    oMultipleSelection: Observable<boolean>;
    oMinChoices: Observable<number>;
    oMaxChoices: Observable<number>;

    oShowCalendar: Observable<boolean>;
    oShowTimeSelector: Observable<boolean>;

    oIsNew = ko.observable(false);

    oChoiceSelectionLimitMode: Observable<number>;

    constructor(data: JSONSurveyBlank) {
        super();

        this.blankId = this.createField(data, 'ProfileQuestionBlankId');
        this.contentId = this.createField(data, 'ContentId');
        this.type = this.createField(data, 'Type');

        this.options = this.createCollection(data, 'Options', jsonOption => new SurveyBlankOption(jsonOption));

        this.oLimitType = this.createField(data, 'LimitType', WCC.Enums.ProfileQuestionTextOptionsLimitTypes.NoLimit.value);
        this.oMinLimit = this.createField(data, 'MinLimit');
        this.oMaxLimit = this.createField(data, 'MaxLimit');
        this.oBlankHint = this.createField(data, 'BlankHint');
        this.oBlankWidth = this.createField(data, 'BlankWidth');

        this.oDigits = this.createField(data, 'Digits', 5);
        this.oDecimals = this.createField(data, 'Decimals');

        this.oChoiceSelectionLimitMode = this.createField(data, 'ChoiceSelectionLimitMode', enums.ProfileQuestionMultipleChoiceSelectionLimitModes.Any.value);
        this.oMultipleSelection = this.createField(data, 'MultipleSelection');
        this.oMinChoices = this.createField(data, 'MinChoices');
        this.oMaxChoices = this.createField(data, 'MaxChoices');

        this.oShowCalendar = this.createField(data, 'ShowCalendar');
        this.oShowTimeSelector = this.createField(data, 'ShowTimeSelector');
    }
}
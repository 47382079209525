define('models/participantsDuringTime', [], function () {
    function Model(jsonStat) {
        var self = this;

        self.startDate = ko.observable(jsonStat.StartDate != null ? new Date(jsonStat.StartDate) : null);
        self.endDate = ko.observable(jsonStat.EndDate != null ? new Date(jsonStat.EndDate) : null);
        self.count = ko.observable(jsonStat.ParticipantsCount || 0);
    }

    return Model;
});
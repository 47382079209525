import { SignalRConnectionState } from 'enums/signalR/connectionState';
import { SignalRSubscriptionState } from 'enums/signalR/subscriptionState';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import Queue from 'models/queue';
import { wccModules } from '../../../enums/wccModules';
import { EffectsContainer } from '../../../mixins/withEffects';
import { ISignalRCoreManager } from './interfaces/main';
import { ISignalRCoreConnectionManager } from './interfaces/connection';
import { ISignalRCoreEventsManager } from './interfaces/events';
import { ISignalRCoreMessagesManager } from './interfaces/messages';
import { ISignalRCoreSubscriptionsManager } from './interfaces/subscriptions';
import { SignalRModuleData } from './models/module';
import { ISignalRCoreModulesManager } from './interfaces/modules';

const codesStorageSize = 100;
const deliveryCodesStorage = new Queue<string>(codesStorageSize);

@injectable()
export default class SignalRCoreManager implements ISignalRCoreManager {
    private _connectionId = ko.observable<string>();

    connectionId: Subscribable<string | undefined>
    state: Subscribable<SignalRConnectionState>

    activeSubscriptions: Subscribable<Array<string>>

    connected: Subscribable<boolean>
    disconnected: Subscribable<boolean>
    reconnected: Subscribable<boolean>

    constructor(
        @inject(wccModules.signalRConnectionManager) connectionManager: ISignalRCoreConnectionManager,
        @inject(wccModules.signalRMessagesManager) private messagesManager: ISignalRCoreMessagesManager,
        @inject(wccModules.signalRSubscriptionsManager) private subscriptionsManager: ISignalRCoreSubscriptionsManager,
        @inject(wccModules.signalREventsManager) private eventsManager: ISignalRCoreEventsManager,
        @inject(wccModules.signalRModulesManager) private modulesManager: ISignalRCoreModulesManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.connectionId = this._connectionId;

        this.activeSubscriptions = this.subscriptionsManager.subscriptions
            .filter(s => s.state() == SignalRSubscriptionState.activated)
            .pluck(s => s.name);

        eventsManager.on('SignalRMessage', jsonMessage => {
            const {
                DiscussionId: discussionId,
                Code: code,
                Name: name,
                Args: args = []
            } = JSON.parse(jsonMessage);

            const isAlreadyDelivered = deliveryCodesStorage.contains(code);

            if (!isAlreadyDelivered) {
                deliveryCodesStorage.add(code);
                eventsManager.trigger(name, ...args);
            }

            this.sendImportantMessage('ConfirmDelivery', discussionId, code);
        });

        eventsManager.on('Connected', connectionId => this._connectionId(connectionId));

        effects.register(isDisconnected => {
            if (isDisconnected)
                this._connectionId(undefined);
        }, [connectionManager.disconnected]);

        this.state = connectionManager.state;
        this.connected = connectionManager.connected;
        this.disconnected = connectionManager.disconnected;
        this.reconnected = connectionManager.reconnected;  
    }

    async sendMessage(name: string, ...args: Array<any>) {
        name = `SendMessage_${name}`;

        await this.messagesManager.add({ name, args });
    }

    async sendImportantMessage(name: string, ...args: Array<any>) {
        name = `SendMessage_${name}`;

        await this.messagesManager.addImportant({ name, args });
    }

    subscribe(name: string, ...args: Array<any>) {
        return this.subscriptionsManager.subscribe(name, ...args);
    }

    trigger(name: string, ...args: Array<any>) {
        this.eventsManager.trigger(name, ...args);
    }

    on(name: string, action: Action<Array<any>>) {
        return this.eventsManager.on(name, action);
    }

    addModule(data: SignalRModuleData) {
        this.modulesManager.add(data);
    }

    removeModule(data: SignalRModuleData) {
        this.modulesManager.remove(data);
    }
}
import { DOMHelpers } from 'helpers/dom';
import device from 'managers/device';
import { Effect } from 'mixins/withEffect';
import NodeChangesTracker from './changesTracker';

export default class NodeContentChangesTracker extends NodeChangesTracker {
    static bodyTracker = ko.pureComputed(() => new NodeContentChangesTracker(document.body));

    protected observe(node: HTMLElement, onChange: Action): Effect {
        const observer = new MutationObserver(onChange);
        observer.observe(node, { attributes: true, subtree: true, childList: true });

        return [
            device.onResize(onChange),

            DOMHelpers.onAnimationComplete(node, onChange),
            DOMHelpers.onTransitionComplete(node, onChange),

            () => observer.disconnect()
        ]
    }
}
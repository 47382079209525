import { SignalRSubscriptionState } from 'enums/signalR/subscriptionState';
import { CollectionsHelpers } from 'helpers/collections';

export interface SignalRSubscriptionConfig {
    name: string
    args: Array<any>
    refCount?: number
}

export default class SignalRSubscription {
    name: string
    args: Array<any>
    refCount: number

    state = ko.observable(SignalRSubscriptionState.justCreated);

    constructor(config: SignalRSubscriptionConfig) {
        this.name = config.name;
        this.args = config.args;
        this.refCount = config.refCount ?? 0;
    }

    equals(other: SignalRSubscription) {
        return this.name === other.name && CollectionsHelpers.compareCollections(this.args, other.args);
    }
}
import { wccModules } from 'enums/wccModules';
import { UserFilesHelpers } from 'helpers/userfiles';
import { inject, injectable } from 'inversify';
import { IUserFilesDuplicator } from 'managers/userfiles/iDuplicator';
import WCCAttachment from 'models/attachments/attachment';
import UserFile from 'models/userFile';
import { IAttachmentDuplicator } from './iDuplicator';

@injectable()
export default class AttachmentDuplicator implements IAttachmentDuplicator {
    constructor(
        @inject(wccModules.userFilesDuplicator) private userFilesDuplicator: IUserFilesDuplicator
    ) { }

    async duplicate<T extends WCCAttachment>(attachment: T) {
        const files = await this.getAttachmentFiles(attachment);
        const newFiles = await this.duplicateFiles(files);

        return attachment.clone<T>(<any>{
            AttachmentId: undefined,
            UserFileId: undefined,
            ClientContentId: undefined,
            CDNFileName: undefined,
            CreateDate: undefined,
            Files: newFiles.map(f => f.toJson())
        });
    }

    private async duplicateFiles(files: Array<UserFile>) {
        return await Promise.all(files.map(file => this.userFilesDuplicator.duplicate(file)))
    }

    private async getAttachmentFiles<T extends WCCAttachment>(attachment: T) {
        if (attachment.files().length > 0)
            return attachment.files();
        else if (attachment.userFileId() != undefined)
            return [UserFilesHelpers.CreateFileFromAttachment(attachment)]
        else
            return [];
    }
}
import { BaseService } from 'services/base';
import { VideoAnalyticsChapter } from 'pages/admin/discussions/projectDashboard/pages/tools/videoAnalytics/models/videoAnalyticsChapter';

export class VideoAnalyticsService extends BaseService {

    /**
     * Adds clip to multiple chapters and removes it from previous (if they are not in IDs list)
     */
    addClipToChapters(projectId: string, clipId: string, chapters: VideoAnalyticsChapter[]) {
        let requestData = {
            url: 'projects/' + projectId + '/videoAnalytics/clips/' + clipId + '/chapters',
            method: 'POST',
            data: {
                ChapterIDs: _.map(chapters, c => c.oChapterId())
            }
        };

        return WCC.API.apiRequest(requestData)
            .done(clipChapters => {
                system.showSuccessMessage('Clip is in ' + _.map(clipChapters, c => '"' + c.Title + '"').join(', ') + ' chapter' + (clipChapters.length != 1 ? 's' : '') + ' now');
            })
            .fail(e => system.handleError(e));
    }
}
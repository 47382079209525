import { BlobDataObject } from 'fine-uploader/lib/core';
import { APIHelpers } from 'helpers/api';
import { injectable } from 'inversify';
import qq from 'libraries/fineUploader';
import S3FineUploaderFilesDataStorage from './filesDataStorage';
import { FileFineUploaderOptions, FineUploaderOptions, IFineUploaderFactory, ImageFineUploaderOptions, VideoFineUploaderOptions } from './iFineUploader';

function getEndpoint(options: FineUploaderOptions) {
    let endpoint = '';

    if (options.endPoint)
        endpoint = options.endPoint;
    else if (settings.CDN_AWS_ImageURLPrefix)
        endpoint = settings.CDN_AWS_ImageURLPrefix;

    if (endpoint.startsWith('//'))
        endpoint = `https:${endpoint}`;

    if (endpoint.endsWith('/'))
        endpoint = endpoint.slice(0, endpoint.length - 1);

    return endpoint;
}

@injectable()
export default class FineUploaderFactory implements IFineUploaderFactory {
    constructor() { }

    createS3ImageUploader(options: ImageFineUploaderOptions) {
        return this.createS3Uploader({
            ...options,

            allowedFileExtensions: options.allowedUploadImageExtensions ?? settings.allowedUploadImageExtensions,
            fileSizeLimit: settings.maxUserContentImageSize
        });
    }

    createS3VideoUploader(options: VideoFineUploaderOptions) {
        return this.createS3Uploader({
            ...options,

            folder: options.key,

            allowedFileExtensions: settings.allowedUploadVideoExtensions,
            objectProperties: undefined
        });
    }

    createS3FileUploader(options: FileFineUploaderOptions) {
        return this.createS3Uploader(options);
    }

    private createS3Uploader(options: FineUploaderOptions) {
        options = options || {};

        const element = _.isString(options.element) ? $(options.element)[0] : options.element;        

        if (element != null) {
            const $element = $(element);
            const savedUploader: qq.s3.FineUploaderBasic | undefined = $element.data('s3-fineuploader');

            if (savedUploader == undefined) {
                if (options.template != null)
                    $element.empty();
            } else {
                return savedUploader;
            }
        }

        const endpoint = getEndpoint(options);
        const folder = options.folder ?? settings.userfilesPath ?? '';

        const buttonText = options.buttonText ?? 'Upload';

        const sizeLimit = options.validation?.sizeLimit ?? options.fileSizeLimit;

        const doNotReset = options.doNotReset ?? false;
        const canAutoUpload = options.canAutoUpload ?? false;
        const canUploadMultipleFiles = options.canUploadMultipleFiles ?? false;
        const noCleanupTasks = options.noCleanupTasks ?? false;

        const allowedFileExtensions = options.allowedFileExtensions ?? [];

        const uploader: qq.s3.FineUploaderBasic = new qq.s3[options.coreMode ? 'FineUploaderBasic' : 'FineUploader']({
            element: element,
            autoUpload: canAutoUpload,
            multiple: canUploadMultipleFiles,
            template: options.template,

            request: {
                endpoint: endpoint,
                accessKey: settings.awsAccessKey
            },

            validation: {
                allowedExtensions: allowedFileExtensions,
                sizeLimit: sizeLimit,
                image: options.validation?.image
            },
            
            iframeSupport: {
                localBlankPagePath: "ieiframedummy.html"
            },

            objectProperties: {
                key: id => {
                    const keyRetrieval = new qq.Promise();
                    const originalName = uploader.getName(id);

                    APIHelpers.apiRequest({
                        url: noCleanupTasks ? 'userfiles/s3WithoutCleanup' : 'userfiles/s3',
                        data: {
                            EndPoint: endpoint,
                            Folder: folder,
                            Name: originalName
                        }
                    }).done(data => {
                        storage.set(id, data);
                        keyRetrieval.success(data.Key);
                    }).fail(() => keyRetrieval.failure());

                    return keyRetrieval;
                },                    

                ...options.objectProperties,

                region: settings.awsRegion
            },

            text: <any>{
                uploadButton: buttonText
            },

            signature: {
                version: 4,
                endpoint: `${settings.wccApiUrl}/api/s3/signature`
            },

            callbacks: {
                onSubmit: function (id, fileName) {
                    options.submitHandler?.call(this, id, fileName);

                    if (!canUploadMultipleFiles && uploader.getInProgress() > 0) {
                        options.errorHandler?.call(this, id, fileName, 'Uploader is busy');
                        return false;
                    }
                },

                onComplete: function (id, fileName, responseJSON, xhr) {
                    if (options.successHandler) {
                        var data = storage.get(id);

                        options.successHandler.call(this, id, fileName, responseJSON, data.fileId, data.S3Key, uploader, data);
                    }
                },

                onError: function (id: number, fileName: string, errorReason: string, xhr: XMLHttpRequest) {
                    if (options.errorHandler)
                        options.errorHandler.call(this, id, fileName, errorReason);
                    
                    if (uploader.getInProgress() == 0)
                        uploader.reset();                    
                },

                onAllComplete: function () {
                    if (!doNotReset)
                        uploader.reset();

                    if (options.completeHandler)
                        options.completeHandler.call(this);
                },

                onValidate: <any>function (this: qq.s3.FineUploaderBasic, data: BlobDataObject, buttonContainer?: HTMLElement) {
                    if (options.validateHandler)
                        return options.validateHandler.call(this, data, element);
                    
                    return true;
                },

                onUpload: function (id, fileName) {
                    if (options.uploadHandler)
                        options.uploadHandler.call(this, id, fileName, uploader);                    
                },

                onProgress: function (id, fileName, uploadedBytes, totalBytes) {
                    if (options.progressHandler)
                        options.progressHandler.call(this, id, fileName, uploadedBytes, totalBytes);                    
                },

                onCancel: function (id, fileName) {
                    if (options.cancelHandler)
                        options.cancelHandler.call(this, id, fileName, storage.get(id)?.fileId);
                }                
            }
        });

        const storage = new S3FineUploaderFilesDataStorage(endpoint, uploader);

        if (element != null)
            $(element).data('s3-fineuploader', uploader);

        return uploader;
    }    
}
import storageDefaults from 'decorators/storageDefaults';
import CommentsView from 'enums/comments/views';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import { CollectionDataSource, LoadRequest } from 'managers/datasources/collection';
import events from 'managers/events';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import SimpleTopicManager from 'managers/simpleTopic';
import { TopicManager } from 'managers/topic';
import { EffectsContainer } from 'mixins/withEffects';
import { apiCommentsRequestData } from 'models/apiCommentsRequestData';
import { SimpleTopic } from 'models/simpleTopic';
import { Topic } from 'models/topic';
import { JSONTopicThread, TopicThread } from 'models/topicThread';
import { ServicesContext } from 'services/context';
import TopicThreadsEventsManager from './threads/eventsProcessor';

const pageSize = 20;

export interface CommentsManagerConfig {
    topicId: string
    view: CommentsView
    searchString: string
    peopleTagsIDs: Array<string>
}

interface CommentsLoadOptions {
    loadAll?: boolean
    loadNew?: boolean
}

@injectable()
@storageDefaults(<Partial<CommentsManagerConfig>>{ searchString: '', peopleTagsIDs: [], view: CommentsView.all })
export default class CommentsManager {
    private topicId: string
    private view: CommentsView
    private searchString: string
    private peopleTagsIDs: Array<string>

    private source: CollectionDataSource<TopicThread, 'id', CommentsLoadOptions>
    private simpleTopic: Subscribable<SimpleTopic | undefined>
    private topic: Subscribable<Topic | undefined>

    private isLoadingMain = ko.observable(false);

    comments: Subscribable<Array<TopicThread>>
    realComments: Subscribable<Array<TopicThread>>

    busy = ko.pureComputed(() => this.source.busy());
    loading = ko.pureComputed(() => this.isLoadingMain() && this.source.requestsProcessed() === 0);
    updating = ko.pureComputed(() => this.isLoadingMain() && this.source.requestsProcessed() > 0);
    loadingNew = ko.observable(false);
    allPagesLoaded = ko.observable(false);

    constructor(
        @inject(wccModules.managerConfig) config: CommentsManagerConfig,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.servicesContext) private ctx: ServicesContext,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.topicId = config.topicId;
        this.view = config.view;
        this.searchString = config.searchString;
        this.peopleTagsIDs = config.peopleTagsIDs;

        const simpleTopicManager = storage.get(SimpleTopicManager, { topicId: this.topicId });
        this.simpleTopic = simpleTopicManager.pluck(m => m.topic);

        const topicManager = storage.get(TopicManager, { topicId: this.topicId });
        this.topic = topicManager.pluck(m => m.topic);

        const userManager = storage.get(CurrentUserManager, { discussionId: settings.discussionId });
        const user = userManager.pluck('person');
        const isModerator = user.pluck('isModerator', false);

        this.source = effects.register(new CollectionDataSource({
            key: 'id',
            load: this.load.bind(this),
            update: this.update.bind(this),
            mapper: jsonThread => new TopicThread(jsonThread),
            merge: (oldComment, newComment) => {
                oldComment.update(newComment.toJson());
                return oldComment;
            }
        }));

        const comments = this.source.list;

        this.comments = comments.sortBy(comment => -(comment.createDate() ?? new Date()));
        this.realComments = this.comments.filter(comment => !comment.isDeleted());

        effects.register(new TopicThreadsEventsManager({
            topicId: this.topicId,
            source: this.source
        }, ctx, storage, signalREvents));

        effects.register(topic => {
            if (topic != undefined)
                return topic.isAnswered.subscribe(this.onTopicIsAnswered, this);
        }, [this.simpleTopic]);

        effects.register(isModerator => {
            if (isModerator && this.view === CommentsView.unread)
                return signalREvents.topicNotifications(this.topicId).onNotificationEvent((topicId, threadId) => this.source.load(true, { loadAll: true }));
        }, [isModerator]); 

        ctx.commentsService.subscriptions.topicThreads.subscribe(this.topicId);

        ctx.commentsService.events.newTopicComment.on(this.onNewComment.bind(this));
        ctx.commentsService.events.newTopicReply.on(this.onNewReply.bind(this));
        ctx.commentsService.events.topicThreadChanged.on(this.onThreadChanged.bind(this));

        effects.register([
            events.commentAdded.on(this.onLocalCommentAdded.bind(this)),
            events.commentUpdated.on(this.onLocalCommentUpdated.bind(this)),
            events.commentDeleted.on(this.onLocalCommentDeleted.bind(this)),            

            events.replyAdded.on(this.onLocalReplyAdded.bind(this)),
            events.replyUpdated.on(this.onLocalReplyUpdated.bind(this)),
            events.replyDeleted.on(this.onLocalReplyDeleted.bind(this))
        ]);
    }

    reload() {
        this.source.reset();
    }

    loadMore() {
        this.source.load(true);
    }

    private load(request: LoadRequest<CommentsLoadOptions>) {
        const loadNew: boolean = request.options.loadNew ?? false;

        return loadNew ? this.loadNew(request) : this.loadMain(request);
    }

    private async loadMain({ loadMore, options }: LoadRequest<CommentsLoadOptions>) {
        const { loadAll = false } = options;

        if (loadMore && !loadAll && this.allPagesLoaded())
            return Promise.resolve([]);
        
        let loadBefore: string | undefined;

        if (!loadAll) {
            const lastComment = _.last(this.comments());

            if (loadMore && lastComment !== undefined)
                loadBefore = lastComment.createDate()?.toISOString();
        }

        const data = <apiCommentsRequestData>{
            searchString: this.searchString,
            peopleTagsIDs: this.peopleTagsIDs,
            loadCommentsBefore: loadBefore
        }

        this.isLoadingMain(true);

        try {
            const jsonComments = await this.ctx
                .commentsService
                .queries
                .topicComments(this.topicId)
                .addArgs({ 'filter': this.getViewFilter() })
                .important()
                .toArrayPost(data);

            this.allPagesLoaded(jsonComments.length < pageSize);

            return jsonComments;
        } finally {
            this.isLoadingMain(false);
        }
    }

    private async loadNew({ loadMore }: LoadRequest) {
        const firstComment = this.comments()[0];

        let loadAfter: string | undefined;

        if (loadMore && firstComment !== undefined)
            loadAfter = firstComment.createDate()?.toISOString();

        const data = <apiCommentsRequestData>{
            searchString: this.searchString,
            peopleTagsIDs: this.peopleTagsIDs,
            loadCommentsAfter: loadAfter
        }

        this.loadingNew(true);

        try {
            return await this.ctx
                .commentsService
                .queries
                .topicComments(this.topicId)
                .addArgs({ 'filter': this.getViewFilter() })
                .important()
                .toArrayPost(data);
        } finally {
            this.loadingNew(false);
        }
    }

    private async update(comment: TopicThread) {
        const commentId = comment.id();

        if (commentId == undefined) return;

        const jsonThread = await this.ctx
            .commentsService
            .queries
            .thread(commentId)
            .important()
            .firstOrDefault();

        comment.update(jsonThread);
    }    

    /**
     * we want to update comments to sync votes in ideation ranking stage
     * note: simpleTopic and topic are not always available so we have to wait until they are
     * plus we want to load topic only if this is ideation
     */
    private async isVotesUpdateRequired() {
        const simpleTopic = await this.simpleTopic.whenNotNull();

        if (simpleTopic.isIdeation()) {
            const topic = await this.topic.whenNotNull();

            if (topic.ideationStage() === enums.IdeationStages.IdeaRating.value)
                return true;
        }

        return false;
    }

    private onTopicIsAnswered(isAnswered: boolean) {
        const commentsVisibility = this.simpleTopic()?.commentsVisibility();
        const commentsAdditionalVisibility = this.simpleTopic()?.commentsAdditionalVisibility();

        const ignore = commentsVisibility === enums.CommentsVisibilityType.HideAll.value ||
            (commentsVisibility === enums.CommentsVisibilityType.ShowAll.value &&
                commentsAdditionalVisibility === enums.CommentsAdditionalVisibilityType.Immediately.value);

        if (!ignore) {
            if (isAnswered) {
                this.allPagesLoaded(false);
                this.loadMore();
            } else {
                this.reload();
            }
        }
    }

    private onNewComment(threadId: string, topicId: string) {
        if (this.topicId === topicId && !this.contains(threadId))
            this.source.load(true, { loadNew: true });
    }

    private onNewReply(replyId: string, commentId: string, topicId: string) {
        if (this.topicId === topicId) {
            if (this.view === CommentsView.unread)
                this.reload();

            this.source.findSync(commentId, async comment => {
                comment.repliesCount.inc();

                if (await this.isVotesUpdateRequired())
                    this.source.update(c => c.id() == commentId);
            });
        }
    }

    private async onThreadChanged(threadId: string, topicId: string, threadOwnerId: string, parentThreadId?: string) {
        if (parentThreadId != undefined && await this.isVotesUpdateRequired())
            this.source.update(th => th.id() == parentThreadId);
    }

    private onLocalCommentAdded(topicId: string, jsonThread: JSONTopicThread) {
        if (this.topicId === topicId)
            this.source.add(jsonThread);
    }

    private onLocalCommentUpdated(topicId: string, commentId: string, jsonThread: JSONTopicThread) {
        if (this.topicId === topicId)
            this.source.findSync(commentId, comment => comment.update(jsonThread));
    }

    private onLocalCommentDeleted(topicId: string, threadId: string) {
        if (this.topicId === topicId)
            this.source.findSync(threadId, comment => comment.isDeleted(true));
    }    

    private onLocalReplyAdded(topicId: string, commentId: string, jsonReply: JSONTopicThread) {
        if (this.topicId === topicId)
            this.source.findSync(commentId, comment => {
                if (comment.isResponseMandatory() && comment.postedById() === jsonReply.PostedById)
                    comment.isResponseMandatory(false);

                comment.isInToDoList(false);
                comment.repliesCount.inc();
                comment.upvotesCount.inc(jsonReply.UpvotesCount ?? 0);
                comment.downvotesCount.inc(jsonReply.DownvotesCount ?? 0);
            }, async () => {
                if (this.view == CommentsView.following) {
                    const jsonThread = await this.ctx.commentsService.getThread(commentId);

                    if (jsonThread != undefined)
                        this.source.add(jsonThread);
                }
            });
    }

    private onLocalReplyUpdated(topicId: string, commentId: string, replyId: string, jsonReply: JSONTopicThread) {
        if (this.topicId == topicId) {
            this.source.findSync(commentId, async comment => {
                if (await this.isVotesUpdateRequired())
                    this.source.update(c => c.id() == commentId);
            });
        }
    }

    private onLocalReplyDeleted(topicId: string, commentId: string, replyId: string) {
        if (this.topicId === topicId)
            this.source.findSync(commentId, comment => {
                comment.repliesCount.dec();

                if (comment.upvotesCount() > 0 || comment.downvotesCount() > 0)
                    this.source.update(c => c.id() == commentId);
            });
    }

    private getViewFilter() {
        switch (this.view) {
            case CommentsView.mine: return 'my';
            case CommentsView.following: return 'followed';
            case CommentsView.unread: return 'todo';
            default: return undefined;
        }
    }

    private contains(id: string) {
        return this.comments().some(comment => comment.id() === id);
    }
}
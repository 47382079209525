import { Observable, ObservableArray } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyQuestion, { JSONSurveyQuestion } from 'models/survey/question';

export interface JSONSurveyPage {
    ProfilePageId: string
    PageName: string
    PageOrder: string
    Description: string

    IsRegistrationForm: boolean

    HasNextPage: boolean
    HasPreviousPage: boolean   

    Questions: Array<JSONSurveyQuestion>
}

export default class SurveyPage extends WCCEntity<JSONSurveyPage> {
    pageId: Observable<string>
    name: Observable<string>
    order: Observable<string>
    description: Observable<string>

    isRegistrationForm: Observable<boolean>

    hasNextPage: Observable<boolean>
    hasPreviousPage: Observable<boolean>

    questions: ObservableArray<SurveyQuestion>

    constructor(data: JSONSurveyPage) {
        super();

        this.pageId = this.createField(data, 'ProfilePageId');
        this.name = this.createField(data, 'PageName');
        this.order = this.createField(data, 'PageOrder');
        this.description = this.createField(data, 'Description');

        this.isRegistrationForm = this.createField(data, 'IsRegistrationForm');
        
        this.hasPreviousPage = this.createField(data, 'HasPreviousPage');
        this.hasNextPage = this.createField(data, 'HasNextPage');

        this.questions = this.createCollection(data, 'Questions', jsonQuestion => new SurveyQuestion(jsonQuestion));
    }
}
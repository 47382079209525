import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyNameOptions {
    Title?: string
    FirstName?: string
    LastName?: string
    DetailsFormat: number 
}

export default class SurveyNameOptions extends WCCEntity<JSONSurveyNameOptions> {
    titlePlaceholder: Observable<string>
    firstNamePlaceholder: Observable<string>
    lastNamePlaceholder: Observable<string>
    detailsFormat: Observable<number>

    constructor(data: JSONSurveyNameOptions) {
        super();

        this.titlePlaceholder = this.createField(data, 'Title', labels.Title);
        this.firstNamePlaceholder = this.createField(data, 'FirstName', labels.FirstName);
        this.lastNamePlaceholder = this.createField(data, 'LastName', labels.LastName);
        this.detailsFormat = this.createField(data, 'DetailsFormat');
    }
}
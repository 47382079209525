import { AutoCompletePopupVMItem } from "components/autocompletePopup/autocompletePopup.interfaces";
import { wccModules } from "enums/wccModules";
import { CollectionsHelpers } from "helpers/collections";
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import { IAutoCompleteManagerBinding } from "managers/autocomplete/autocomplete.interfaces";
import { IWCCStorageManager } from "managers/iStorage";
import TopicSlidesManager from "managers/topic/slides";
import { IAutoCompleteBindingsFactory, IAutoCompleteBindingsFactoryRequest, IAutoCompleteBindingsFactoryRequestSlidesOptions } from "./autocompleteBindings.interfaces";

function getValue(title: string) {
    return `[slide: ${title}]`
}

@injectable()
export default class AutoCompleteBindingsFactory implements IAutoCompleteBindingsFactory {
    constructor(
        @inject(wccModules.storage) private storage: IWCCStorageManager
    ) { }

    getBindings(request: IAutoCompleteBindingsFactoryRequest): Subscribable<IAutoCompleteManagerBinding[]> {
        const bindings = new Array<IAutoCompleteManagerBinding>();

        if (request.slides != undefined)
            bindings.push(...this.getSlidesBindings(request.slides));

        return ko.pureComputed(() => bindings.flatMap(block => ko.unwrap(block)));
    }

    private getSlidesBindings(options: IAutoCompleteBindingsFactoryRequestSlidesOptions): IAutoCompleteManagerBinding[] {
        const allowNewItems = options.allowNewItems ?? false;

        const slidesManager = this.storage.get(TopicSlidesManager, { topicId: options.topicId });
        const slides = slidesManager.pluck(m => m.slides, []);

        return [
            {
                prefix: '[slide:',
                postfix: ']',

                getResults: query => {
                    query = query.trim();

                    const normalizedQuery = query.toLowerCase().trim();

                    const result = slides()
                        .filter(s => (s.title() ?? '').toLowerCase().includes(normalizedQuery))
                        .map(s => ({ title: s.title() ?? '' }))
                        .sort((l, r) => CollectionsHelpers.stringSorterAsc(l.title, r.title))
                        .map<AutoCompletePopupVMItem>(s => ({ title: s.title, value: getValue(s.title) }));

                    if (allowNewItems && normalizedQuery.length > 0 && !result.some(item => item.title.toLowerCase() == normalizedQuery.toLowerCase()))
                        result.unshift({ title: query, value: getValue(query), badge: { title: 'new', css: 'wcc-badge--red' } });

                    return result;
                }
            }
        ]
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { apiCommunityPageRequestData } from 'models/apiCommunityPageRequestData';
import { apiCommunityPagesRequestData } from 'models/apiCommunityPagesRequestData';
import CommunityPage from 'models/community/page';
import WCCError from 'models/wccError';
import { CommunitiesService } from 'services/communities';
import CommunityPagesManager, { CommunityPagesManagerConfig } from './base';

export interface AdminCommunityPagesManagerConfig extends CommunityPagesManagerConfig {
    includeContent: boolean
    includeHistory: boolean
}

@injectable()
@storageDefaults(<Partial<AdminCommunityPagesManagerConfig>>{ includeContent: false, includeHistory: false })
export default class AdminCommunityPagesManager extends CommunityPagesManager {
    private includeContent: boolean
    private includeHistory: boolean

    constructor(
        @inject(wccModules.managerConfig) config: AdminCommunityPagesManagerConfig,
        @inject(wccModules.effects) effects: EffectsContainer,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.communitiesService) communitiesService: CommunitiesService
    ) {
        super(config, effects, signalREvents, communitiesService);

        this.includeContent = config.includeContent;
        this.includeHistory = config.includeHistory;
    }

    protected loadPages() {
        const data = <apiCommunityPagesRequestData>{
            includeContent: this.includeContent,
            includeHistory: this.includeHistory,
            domainId: settings.domainId
        }

        return this.communitiesService
            .adminQueries
            .pages()            
            .toArray(data);
    }

    protected async updatePage(page: CommunityPage) {
        const pageId = page.id();

        if (pageId == undefined)
            throw new WCCError(messages.InvalidRequest);

        const data = <apiCommunityPageRequestData>{
            includeContent: this.includeContent,
            includeHistory: this.includeHistory
        }

        const jsonPage = await this.communitiesService.adminQueries.page(pageId).firstOrDefault(data);

        if (jsonPage != undefined)
            page.update(jsonPage);
    }
}
import { IOverlayStageVM, OverlayStageVMOptions, OverlayStageVMTemplateConfig } from "components/overlay/overlayStage.interfaces";
import { Subscribable } from "knockout";

export const overlayManagerKey = Symbol();

export interface IOverlayManager {
    isActive: Subscribable<boolean>

    toOverlay<T>(template: string | OverlayStageVMTemplateConfig, options?: OverlayStageVMOptions<T>): Promise<IOverlayStageVM<T>>
    reset(): void
}
import { CookieHelpers } from 'helpers/cookie';
import ko from 'knockout';

export default class UISettings {
    isTranslationShown = ko.observable(CookieHelpers.getCookie('qzy_gui_ShowThreadsTranslation') === 'true');
    isFullscreenLayoutRequested = ko.observable(false);

    constructor() {
        this.isTranslationShown.subscribe(value => CookieHelpers.setCookie('qzy_gui_ShowThreadsTranslation', value));
    }
}
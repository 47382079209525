import { Observable } from 'knockout';
import WCCEntity from './entity';
import { JSONTag, Tag } from './tag';

export interface JSONTopicQuestion {
    TopicQuestionId: string
    Text: string
    ContentTag: JSONTag
    IsMandatory: boolean
}

export default class TopicQuestion extends WCCEntity<JSONTopicQuestion> {
    topiqQuestionId: Observable<string>
    text: Observable<string>
    tag: Observable<Tag>

    isMandatory: Observable<boolean>

    constructor(data: JSONTopicQuestion) {
        super();

        this.topiqQuestionId = this.createField(data, 'TopicQuestionId');
        this.text = this.createField(data, 'Text', '');
        this.tag = this.createMappedField(data, 'ContentTag', jsonTag => new Tag(jsonTag));
        this.isMandatory = this.createField(data, 'IsMandatory', false);
    }
}
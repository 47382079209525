import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import EntityDataSourceOwner from 'managers/entityDataSourceOwner';
import events from 'managers/events';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { IAppointmentsService } from 'services/appointments/appointments.interfaces';
import GroupRoom, { JSONGroupRoom } from '../../models/appointments/groupRoom';

export interface AppointmentManagerConfig {
    appointmentId: string
}

@injectable()
export default class AppointmentManager extends EntityDataSourceOwner<JSONGroupRoom> {
    protected source: EntityDataSource<JSONGroupRoom>

    appointment: Subscribable<GroupRoom | undefined>

    constructor(
        @inject(wccModules.managerConfig) { appointmentId }: AppointmentManagerConfig,
        @inject(wccModules.appointmentsService) appointmentsService: IAppointmentsService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.source = effects.register(new EntityDataSource<JSONGroupRoom>({
            update: () => appointmentsService.appointmentQuery(appointmentId).firstOrDefault(),
            deferred: true
        }));

        this.appointment = this.source.data.toEntity(jsonAppointment => new GroupRoom(jsonAppointment), (appointment, jsonAppointment) => appointment.update(jsonAppointment));

        signalREvents.appointment(appointmentId)
            .onChanged(() => this.source.update());

        signalREvents
            .system()
            .onConnected(() => this.source.update());

        effects.register([
            events.appointmentRecordsChanged.on(targetAppointmentId => {
                if (appointmentId == targetAppointmentId)
                    this.source.update();
            }),

            events.appointmentStatusChanged.on((targetAppointmentId, status) => {
                if (targetAppointmentId == appointmentId) {
                    const jsonAppointment = this.source.data();

                    if (jsonAppointment != undefined)
                        this.source.set({ ...jsonAppointment, Status: status });

                    this.source.update();
                }
            }),

            events.appointmentActiveTopicChanged.on((targetAppointmentId, topicId) => {
                if (appointmentId == targetAppointmentId)
                    this.appointment()?.activeTopicId(topicId);
            }),

            events.appointmentActiveSlideChanged.on((targetAppointmentId, slideId) => {
                if (appointmentId == targetAppointmentId)
                    this.appointment()?.activeSlideId(slideId);
            }),

            events.appointmentRecordingEnded.on(targetAppointmentId => {
                if (appointmentId == targetAppointmentId)
                    this.appointment()?.recordingStartedAt(undefined);
            })
        ]);
    }
}
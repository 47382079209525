import { getCustomElement } from 'extensions/knockout/bindings/customElement';
import { injectable } from 'inversify';
import { Subscribable } from 'knockout';
import vimeoPattern from 'patterns/vimeo';
import youtubePattern from 'patterns/youtube';
import { IWCCPlayerVM, WCCPlayerVMConfig } from '../components/players/player.interfaces';
import { IVideoPlayerComponentFactory } from './videoPlayerComponent.interfaces';

@injectable()
export default class VideoPlayerComponentFactory implements IVideoPlayerComponentFactory {
    constructor() { }

    build(src: string, config?: Partial<WCCPlayerVMConfig>, ref?: Subscribable<IWCCPlayerVM | undefined>) {
        if (youtubePattern.test(src))
            return getCustomElement<WCCPlayerVMConfig, IWCCPlayerVM>('wcc-player-youtube', { ...config, src }, ref);

        if (vimeoPattern.test(src))
            return getCustomElement<WCCPlayerVMConfig, IWCCPlayerVM>('wcc-player-vimeo', { ...config, src }, ref);
    }
}
define('models/highlight', function () {
    const { highlightColors: colors } = settings;

    class Model {
        constructor(jsonModel) {
            this.id = ko.observable(jsonModel.HighlightId);
            this.topicId = ko.observable(jsonModel.DiscussionTopicId);
            this.threadId = ko.observable(jsonModel.SourceTopicThreadId);
            this.color = ko.observable(jsonModel.Color).default(colors[0]);
            this.text = ko.observable(jsonModel.Text);
            this.textForReport = ko.observable(jsonModel.TextForReport);
        }

        toJson() {
            return {
                HighlightId: this.id(),
                DiscussionTopicId: this.topicId(),
                SourceTopicThreadId: this.threadId(),
                HighlightColorId: this.color().HighlightColorId,
                Text: this.text(),
                TextForReport: this.textForReport()
            };
        }

        clone() {
            return new this.constructor(this.toJson());
        }
    }

    return Model;
})
import { FineUploaderOptions } from 'factories/fineUploader/s3/iFineUploader';
import WCCUploaderDirectS3Transport, { WCCUploaderDirectS3TransportTypes } from './directTransport';
import WCCUploaderProxyS3Transport from './proxyTransport';

export interface WCCUploaderS3TransportFactoryConfig {
    type: WCCUploaderDirectS3TransportTypes
    key?: string

    allowMultiple?: boolean
    allowedExtensions?: Array<string>
    fileSizeLimit?: number
}

class WCCUploaderS3TransportFactory<T extends FineUploaderOptions> {
    constructor() { }

    getTransport(config: WCCUploaderS3TransportFactoryConfig) {
        config = {
            allowMultiple: true,

            ...config
        }

        return settings.proxyTaskResources ?

            new WCCUploaderProxyS3Transport({
                allowMultiple: config.allowMultiple,
                allowedExtensions: config.allowedExtensions,
                fileSizeLimit: config.fileSizeLimit
            }) :

            new WCCUploaderDirectS3Transport({
                type: config.type,
                fileSizeLimit: config.fileSizeLimit,

                fineUploaderConfig: <T><FineUploaderOptions>{
                    folder: config.key,
                    canUploadMultipleFiles: config.allowMultiple,
                    allowedUploadImageExtensions: config.allowedExtensions,
                    fileSizeLimit: config.fileSizeLimit
                }
            });
    }
}

const factory = new WCCUploaderS3TransportFactory();

export default factory;
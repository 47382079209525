import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

const { sitePrefix } = settings;

export class PollsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.adminQueries = {
            polls: () => this._repository.fastQuery(`admin/communities/polls`)
        };

        this.queries = {
            polls: () => this._repository.fastQuery(`${sitePrefix}communities/polls`),
            poll: (pollId) => this._repository.fastQuery(`${sitePrefix}communities/polls/${pollId}`),
            statistics: (pollId) => this._repository.fastQuery(`${sitePrefix}communities/polls/${pollId}/statistics`)
        };

        this.events = {
            pollAdded: this.signalREvent('PollAdded'),
            pollChanged: this.signalREvent('PollChanged')
        };
    }
}
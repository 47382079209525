interface Messages {
    "AccountCountryCodeIsNotCurrentlySupportedToSendSMS": string
    "AccountCreatedSuccessfully": string
    "ActivityIsImportedSuccessfully": string
    "ActivityHasBeenAnswered": string
    "AIIsUnderVeryHeavyLoad": string
    "AllValuesShouldBeUnique": string
    "AnErrorOccuredDuringUploadingFile": string
    "AnErrorOccuredDuringYourRequest": string
    "AnonymousNameForThisParticipantHasBeenChangedToN": string
    "AnotherVideoIsUploading": string
    "AResetEmailHasBeenSentTo": string
    "AResetEmailHasBeenSent": string
    "AreYouSureYouWantToActivateThisSlide": string
    "AreYouSureYouWantToActivateTopic": string
    "AreYouSureYouWantToAnonymizeThesePeople": string
    "AreYouSureYouWantToCancelAllYourChanges": string
    "AreYouSureYouWantToCancelThisAppointment": string
    "AreYouSureYouWantToConfirmThisAppointment": string
    "AreYouSureYouWantToClearAllLocalStorageData": string
    "AreYouSureYouWantToClearWhiteboard": string
    "AreYouSureYouWantToCloseChat": string
    "AreYouSureYouWantToDeleteSlide": string
    "AreYouSureYouWantToDeleteThisComment": string
    "AreYouSureYouWantToDeleteThisEmailBatch": string
    "AreYouSureYouWantToDeleteThisFile": string
    "AreYouSureYouWantToDeleteThisGroup": string
    "AreYouSureYouWantToDeleteThisHighlight": string
    "AreYouSureYouWantToDeleteThisImage": string
    "AreYouSureYouWantToDeleteThisMessage": string
    "AreYouSureYouWantToDeleteThisReply": string
    "AreYouSureYouWantToDeleteThisSMSBatch": string
    "AreYouSureYouWantToDeleteThisTag": string
    "AreYouSureYouWantToDispatchThisBatchOfEmailsNow": string
    "AreYouSureYouWantToDispatchThisBatchOfSMSMessagesNow": string
    "AreYouSureYouWantToExportPeopleToExcel": string
    "AreYouSureYouWantToExtendLiveChat": string
    "AreYouSureYouWantToMakeTagVisible": string
    "AreYouSureYouWantToMarkAllAsRead": string
    "AreYouSureYouWantToRefreshThisTranslation": string
    "AreYouSureYouWantToRemoveAllTopicNotifications": string
    "AreYouSureYouWantToRemoveThisAppointment": string
    "AreYouSureYouWantToRemoveThisComposition": string
    "AreYouSureYouWantToRemoveThisRecord": string
    "AreYouSureYouWantToRemoveThisWebSite": string
    "AtleastOnePageIsRequired": string
    "AtleastOneQuestionCodeIsRequiredInTheSurveyTemplate": string
    "AttachmentShouldHaveTitle": string
    "BlankChoicesAreNotAllowed": string
    "CantLoadPinboard": string
    "CaptionIsRequiredForThisImage": string
    "ChangingThisTaskToOfflineConfirmation": string
    "ClickHereToGoToTheNextTopic": string
    "ClickToAddMoodboardImage": string
    "DataHasBeenLoadedFromTheExcelFile": string
    "DeleteSurvey": string
    "DoYouUnderstandMyProject": string
    "DurationMustBeAValidNumber": string
    "EmailIsInvalid": string
    "ErrorParsingEarliestLatestTime": string
    "FieldIsInvalid": string
    "FieldIsMandatory": string
    "FileIsTooBig": string
    "FixErrorsBeforeContinuing": string
    "ImageCanNotBeLoaded": string
    "InTheTopic": string
    "IntoTourWelcome": string
    "IntroTourAboutThisTopic": string
    "IntroTourHeatmap": string
    "IntroTourSurvey": string
    "IntroTourTopics": string
    "IntroTourYourComments": string
    "InvalidExtension": string
    "InvalidFormat": string
    "InvalidRequest": string
    "LiveChatCheckedInMessage": string
    "LiveChatCheckInMessage": string
    "LiveChatWaitMessage": string
    "LocalStorageDataIsClearedSuccessfully": string
    "LocalStorageIsNotSupportedByThisBrowser": string
    "MandatoryProbeClearingHint": string
    "MaxNoOfDecimalsCanBeN": string
    "MaxNoOfDigitsCanBeN": string
    "MessageCantBeEmpty": string
    "MinCardLimitValueIsBiggerThanMaxValue": string
    "MinDiffBetween2DatesMustBeN": string
    "MinimumChoicesShouldBeLessThanMaximumChoices": string
    "MinimumChoicesShouldBeLessThanTheActualNumberOfChoices": string
    "MinimumChoicesShouldBeMoreThanZero": string
    "MinimumValueShouldBeLessThanTheMaximumValue": string
    "NCharsAreNotAllowed": string
    "NewTopicGroupTour": string
    "NewTopicTourNextTopic": string
    "NewTopicTourNotificationBadge": string
    "NewTopicTourOtherComments": string
    "NicknameCantBeEmpty": string
    "NoOfDecimalsShouldBeLessThanTheNumberOfDigits": string
    "NoteThatAbuseReportsHaveNotBeenMarkedAsRead": string
    "ParticipantStatusChanged": string
    "ParticipantsWillNowBeAnonymized": string
    "PasswordAndConfirmPasswordAreNotEqual": string
    "PasswordCantBeEmpty": string
    "PasswordChangedSuccessfully": string
    "PasswordIsIncorrect": string
    "PermissionDenied": string
    "PersonAddedSuccessfully": string
    "PersonNotFound": string
    "PersonWithThisEmailAlreadyExists": string
    "PersonWithThisMobilePhoneAlreadyExists": string
    "PersonWithThisNicknameAlreadyExists": string
    "PersonUpdatedSuccessfully": string
    "PhoneNumberIsInvalid": string
    "PleaseAddAnImage": string
    "PleaseAddFile": string
    "PleaseAddAtLeastNFiles": string
    "PleaseAddAtLeastNImages": string
    "PleaseAddAtLeastNVideos": string
    "PleaseAddAtLeastNWebsites": string
    "PleaseAddAtLeastOneURL": string
    "PleaseAddAtLeastOneWebsite": string
    "PleaseAddAtLeastTwoItems": string
    "PleaseAddVideo": string
    "PleaseEnterSomeNumber": string
    "PleaseEnterSomeText": string
    "PleaseRecordAudio": string
    "PleaseSelectAnImage": string
    "PleaseSelectAtLeastNChoices": string
    "PleaseSelectAtLeastNImages": string
    "PleaseSelectAtleastOneChoice": string
    "PleaseSelectAtLeastOneImage": string
    "PleaseSelectAValidDate": string
    "PleaseSelectAValidExcelFile": string
    "PleaseSelectCountry": string
    "PleaseSelectDate": string
    "PleaseSelectHost": string
    "PleaseSelectModerator": string
    "PleaseSelectOneChoice": string
    "PleaseSelectParticipant": string
    "PleaseSelectRegion": string
    "PleaseSelectTimeFromList": string
    "PleaseSelectTime": string
    "PleaseChooseFromTagsBeforePostingYourComment": string
    "PleaseMakeSureThatAllChoiceValuesAreUnique": string
    "PleaseProvideDiscussionFAQs": string
    "PleaseProvideDiscussionIntroduction": string
    "PleaseProvideLiveChatFAQs": string
    "PleaseProvideLiveChatIntroduction": string
    "PleaseProvideTopicInstructions": string
    "PleaseResetYourPassword": string 
    "PleaseUseCorrectURL": string
    "PleaseWaitUntilAllFilesAreUploaded": string
    "ProfileUpdatedSuccessfully": string
    "QuestionsWithUniqueCodesAreNotFound": string
    "QuestionWithUniqueCodeIsNotFound": string
    "RedirectToTopicMessage": string
    "RemoveImageFromThisQuestion": string
    "ScreenerIsClosed": string
    "ScreenOutMessage": string
    "SelectAtLeastOneImage": string
    "SlideCantBeEmpty": string
    "SlideNotFound": string
    "SomeoneRepliedToACommentYouAreFollowing": string
    "SomeoneRepliedToYourComment": string
    "SomeStatementsMustBeAgreedToByClickingTheCheckMarkBeforeContinuing": string
    "SomethingMustBeEnteredBeforeYouCanPost": string
    "SurveyRuleAttachedToQuestionError": string
    "SurveyRuleAttachedToChoiceError": string
    "TagWithSameNameAlreadyExists": string
    "ThankYouForCompletingRegistrationForm": string
    "ThankYouForYourResponse": string
    "TheExportWillNowBeProduced": string
    "TheFileWillNowBeProduced": string
    "TheImportProcessHasStarted": string
    "TheLinkWillBeSentToYourMobileShortly": string
    "TheOpenFromDateMustBeLessThanTheCurrentDate": string
    "TheOpenFromDateMustBeLessThanTheOpenToDate": string
    "TheOpenToDateMustBeGreaterThanTheCurrentDate": string
    "ThereAreMoreQuestionsToBeAnswered": string
    "ThereAreNoComments": string
    "ThereAreNoUnreadComments": string
    "ThereAreStillCardsToBeAddedToScale": string
    "TheReportWillNowBeProduced": string
    "ThereWasASystemError": string
    "TheTranslationWillBeRefreshedSoon": string
    "ThisTagAlreadyExistsInAList": string
    "ThisTopicHasBeenAnswered": string
    "TimeMustBeAfterEarliestTime": string
    "TimerDurationShouldBeGreaterThanZero": string
    "TitleCantBeEmpty": string
    "UnknownError": string
    "WaitSurveyData": string
    "WebSiteDoesntExist": string
    "YouAreInStage2OfIdeation": string
    "YouAreInStage2OfIdeationStaging": string
    "YouAreLeavingTheChat": string
    "YouAreNotAuthorizedToPerformThisOperation": string
    "YouCanEnterNCharsButHaveEnteredMChars": string
    "YouCanEnterNWordsButHaveEnteredMWords": string
    "YouCannotAddEmptyTextBlock": string
    "YouCanNotDeleteThisProjectAsItIsInDemoMode": string
    "YouCanNotDeleteThisProjectAsItIsLive": string
    "YouCanNotHaveMoreThanNImagesInAPost": string
    "YouCanNotHaveMoreThanNVideosInAPost": string
    "YouCanNotSelectLessThanNChoices": string
    "YouCanNotSelectMoreThanNChoices": string
    "YouCantSaveEmptyPinboard": string
    "YouHaveAddedCardToMaxAmoutOfGroups": string
    "YouHaveAlreadyResponded": string
    "YouHaveANewCommentToModerate": string
    "YouHaveANewCommentToRead": string
    "YouHaveANewReplyToRead": string
    "YouHaveChosenToNotCompleteSomeQuestions": string
    "YouHaveNotAgreedToTheTermsAndConditions": string
    "YouHaveNotFollowedAnyCommentYet": string
    "YouHaveNotPostedAnyCommentOrRepliedOnAnyAommentYet": string
    "YouHaveToAnswerScaleQuestion": string
    "YouHaveToFillDescription": string
    "YouHaveToFillTitle": string
    "YouHaveToSelectGroupForTheCard": string
    "YouHaveWatchedThisVideoMaximumAllowedNumberOfTimes": string
    "YouMayHaveUnsavedChangesConfirmation": string
    "YouMustAddAtLeastOneFile": string
    "YouMustAddAtLeastOneImage": string
    "YouMustAddAtleastOneImageToAnswer": string
    "YouMustAddAtleastOneImageToThisPost": string
    "YouMustAddAtleastOneImageVideoToAnswer": string
    "YouMustAddAtleastOneImageVideoToThisPost": string
    "YouMustAddAtLeastSomeFiles": string
    "YouMustAddAtLeastSomeImages": string
    "YouMustAddAnAudio": string
    "YouMustAddAVideo": string
    "YouMustAddAVideoToAnswer": string
    "YouMustAddAVideoToThisPost": string
    "YouMustAnswerAtLeastOnequestion": string
    "YouMustAttachAtleastSomethingToAnswer": string
    "YouMustAttachAtleastSomethingToThisPost": string
    "YouMustBeNYearsOld": string
    "YouMustEnterAtleastNChars": string
    "YouMustEnterAtleastNWords": string
    "YouMustHaveAtleastOneCardGroup": string
    "YouMustRankAllChoices": string
    "YouMustUploadAtLeastOneDocument": string
    "YouNeedToCompleteAllSurveyQuestionsBeforeContinuing": string
    "YourLoginDoesNotYetHaveAPassword": string
    "YourReplyIsSaved": string
    "YouWillBeRedirectedManuallyToTheM": string
    "YouWillBeRedirectedToTheMAfterNSeconds": string
    "YouWillBeRedirectedURLFree": string
    "YourSessionHasExpired": string 
    "PhoneNumberHasInvalidCountryCode": string
    "PhoneNumberIsTooShort": string
    "PhoneNumberIsTooLong": string
}

const messages: Messages = <any>{};

const config = (window as any).__wccconfig ?? {};
_(messages).extend(config.messages);

export default messages;
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class PrizesService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            peopleWithTickets: discussionId => this._repository.query(`discussions/${discussionId}/prizes/tickets/people/full`),
            peopleWithTicketsCount: discussionId => this._repository.query(`discussions/${discussionId}/prizes/tickets/people`),
            prizeDrawTickets: discussionId => this._repository.query(`discussions/${discussionId}/prizes/tickets`),
            prizeDrawTicketsCount: discussionId => this._repository.query(`discussions/${discussionId}/prizes/tickets/count`),
            domainPrizeDrawTicketsCount: (discussionId, domainId) => this._repository.query(`discussions/${discussionId}/prizes/tickets/count?domainId=${domainId}`),
            prizeDraws: discussionId => this._repository.query(`discussions/${discussionId}/prizes/draws`),
            domainPrizeDraws: (discussionId, domainId) => this._repository.query(`discussions/${discussionId}/prizes/draws?domainId=${domainId}`),
            prizeDrawManual: discussionId => this._repository.query(`discussions/${discussionId}/prizes/draws/manual`),
            prizeDraw: (discussionId, prizeDrawId) => this._repository.query(`discussions/${discussionId}/prizes/draws/${prizeDrawId}`),
            prizeDrawRewards: (discussionId, prizeDrawId) => this._repository.query(`discussions/${discussionId}/prizes/draws/${prizeDrawId}/rewards`)
        };
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getPeopleWithTickets(discussionId) {
        return this.queries.peopleWithTickets(discussionId).toArray();
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getPeopleWithTicketsCount(discussionId) {
        return this.queries.peopleWithTicketsCount(discussionId).toArray();
    }

    /**
     * Manually allocates some prize draw tickets to some people according to settings from allocationData object.
     */
    allocateTickets(discussionId, allocationData) {
        return this.queries.prizeDrawTickets(discussionId).add(allocationData);
    }

    /**
     * Returns all prize draws that have been made in given discussion.
     */
    getPrizeDraws(discussionId, domainId) {
        return domainId
            ? this.queries.domainPrizeDraws(discussionId, domainId).toArray()
            : this.queries.prizeDraws(discussionId).toArray();
    }

    /**
     * Draws some prizes according to settings from prizeDrawData object.
     */
    drawPrizes(discussionId, prizeDrawData, domainId) {
        return domainId
            ? this.queries.domainPrizeDraws(discussionId, domainId).add(prizeDrawData)
            : this.queries.prizeDraws(discussionId).add(prizeDrawData);
    }

    /**
     * Draws some prizes according to settings from prizeDrawData object.
     */
    countPrizeDrawTickets(discussionId, prizeDrawData, domainId) {
        return domainId
            ? this.queries.domainPrizeDrawTicketsCount(discussionId, domainId).add(prizeDrawData)
            : this.queries.prizeDrawTicketsCount(discussionId).add(prizeDrawData);
    }

    /**
     * Update prize draw
     */
    updatePrizeDraw(discussionId, prizeDrawData) {
        return this.queries.prizeDraws(discussionId).update(prizeDrawData);
    }

    /**
     * Create manually crafted prize draw
     */
    createPrizeDraw(discussionId, prizeDrawData) {
        return this.queries.prizeDrawManual(discussionId).add(prizeDrawData);
    }

    /**
     * Generate prize draw rewards
     */
    generateRewardsForPrizeDraw(discussionId, prizeDrawId) {
        return this.queries.prizeDrawRewards(discussionId, prizeDrawId).add();
    }

    /**
     * Delete prize draw with givent Id
     */
    deletePrizeDraw(discussionId, prizeDrawId) {
        return this.queries.prizeDraw(discussionId, prizeDrawId).remove();
    }
}
import apiEndpoint from "endpoints/api";
import { injectable } from "inversify";
import events from "managers/events";
import { JSONOnlineUserSession } from "models/signalR/onlineUserSession";
import { JSONTopicSlide } from "models/topicSlide";
import GenericRepository from "repositories/generic";
import { ISlidesService } from "./slides.interfaces";

@injectable()
export default class SlidesService implements ISlidesService {
    private repository = new GenericRepository(apiEndpoint);

    private addSlideQuery = () => this.repository.postQuery<JSONTopicSlide, JSONTopicSlide>(`slides`);
    private updateSlideQuery = (slideId: string) => this.repository.updateQuery<JSONTopicSlide, JSONTopicSlide>(`slides/${slideId}`);

    slideQuery = (slideId: string) => this.repository.entityGetQuery<JSONTopicSlide>(`slides/${slideId}`);
    slideOnlinePeopleQuery = (slideId: string) => this.repository.collectionGetQuery<JSONOnlineUserSession>(`slides/${slideId}/onlinepeople`);

    async addSlide(slide: JSONTopicSlide) {
        const savedSlide = await this.addSlideQuery().post(slide);
        events.topicSlideAdded.trigger(<string>savedSlide.TopicId, savedSlide);
    }

    async updateSlide(slideId: string, slide: JSONTopicSlide) {
        const savedSlide = await this.updateSlideQuery(slideId).update(slide);
        events.topicSlideUpdated.trigger(slideId, <string>savedSlide.TopicId, savedSlide);
    }
}
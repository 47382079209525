import ActiveQuery from 'models/queries/active';

interface GenericEndPointConfig {
    send: (query: ActiveQuery) => PromiseLike<any>
}

export default class GenericEndPoint implements GenericEndPoint {
    private sendHandler: (query: ActiveQuery) => PromiseLike<any>

    constructor(config: GenericEndPointConfig) {
        this.sendHandler = config.send;
    }

    send(query: ActiveQuery) {
        return $.Deferred(dfd => this.sendHandler(query).then(dfd.resolve, dfd.reject));
    }
}
import {Observable, ObservableArray} from 'knockout';
import WCCEntity from 'models/entity';
import SurveyPage, { JSONSurveyPage } from 'models/survey/page';
import SurveyQuestion from './question';

export interface JSONSurvey {
    Pages: Array<JSONSurveyPage>
    HasBackButton?: boolean
    IsOpen?: boolean
}

export default class Survey extends WCCEntity<JSONSurvey> {
    jsonPages: Array<JSONSurveyPage>

    pages: ObservableArray<SurveyPage>
    hasBackButton: Observable<boolean>

    constructor(data: JSONSurvey) {
        super();

        this.jsonPages = data.Pages;
        this.pages = this.createCollection(data, 'Pages', jsonPage => new SurveyPage(jsonPage));
        this.hasBackButton = this.createField(data, "HasBackButton", true);
    }

    getQuestionById(questionId: string) {
        return this.pages().reduce((result, page) => result ?? page.questions().find(question => question.questionId() === questionId), <SurveyQuestion | undefined>undefined);
    }

    getQuestionByChoiceId(choiceId: string) {
        return this.pages().reduce((result, page) => result ?? page.questions().find(question => question.choices().some(choice => choice.choiceId() === choiceId)), <SurveyQuestion | undefined>undefined);
    }

    hasQuestionOfTypes(types: Array<number>) {
        return this.pages().some(page => page.questions().some(question => types.some(type => question.type() == type)));
    }
}
import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyVideoOptions {
    MinVideos: number
    MaxVideos: number
}

export default class SurveyVideoOptions extends WCCEntity<JSONSurveyVideoOptions> {
    minVideos: Observable<number>
    maxVideos: Observable<number>

    constructor(data: JSONSurveyVideoOptions) {
        super();

        this.minVideos = this.createField(data, 'MinVideos');
        this.maxVideos = this.createField(data, 'MaxVideos');
    }
}
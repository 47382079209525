import { Subscribable, SubscribableOrNullableValue, SubscribableOrValue } from "knockout";
import { Zone } from "luxon";
import { CacheHelpers } from "../../helpers/cache";
import { CollectionsHelpers } from "../../helpers/collections";
import { DateHelpers } from "../../helpers/date";
import { RangesHelpers } from "../../helpers/ranges";
import { Func } from "../../interfaces/func";
import { Interval, TaggedInterval } from "../../interfaces/interval";

const defaultInitFunc: Func<boolean> = () => true;

export interface DateIntervalsFactoryConfig {
    ranges?: Func<SubscribableOrNullableValue<TaggedInterval[]>, [from: Date, to: Date, timezone: Zone]>
    excludedRanges?: Func<SubscribableOrNullableValue<Interval[]>, [from: Date, to: Date, timezone: Zone]>
    init?: Func<SubscribableOrNullableValue<boolean>, [from: Date, to: Date]>
    filter?: Func<boolean, [from: Date, to: Date, timezone: Zone]>
}

export interface DateIntervalsConstructionRequest {
    from: SubscribableOrValue<Date>
    to: SubscribableOrValue<Date>
    duration: SubscribableOrValue<number>
    gap?: SubscribableOrNullableValue<number>
    timezone: SubscribableOrValue<Zone>

    initialIntervals?: SubscribableOrNullableValue<Array<TaggedInterval>>
}

interface ExtendedDateIntervalsConstructionRequest extends DateIntervalsConstructionRequest {
    amount?: number
}

export default class DateIntervalsFactory {
    constructor(private config?: DateIntervalsFactoryConfig) { }

    getAllView(request: DateIntervalsConstructionRequest) {
        return this.getIntervals(request);
    }

    hasAnyView(request: DateIntervalsConstructionRequest) {
        return this.getIntervals({ ...request, amount: 1 }).mapSingle(intervals => intervals != undefined ? intervals.length > 0 : undefined);
    }

    private getIntervals(request: ExtendedDateIntervalsConstructionRequest): Subscribable<TaggedInterval[] | undefined> {
        const from = ko.flattenComputed(request.from);
        const to = ko.flattenComputed(request.to);

        const duration = ko.flattenComputed(request.duration);
        const gap = ko.flattenComputed(request.gap, 0);
        const timezone = ko.flattenComputed(request.timezone);

        const msDuration = duration.pluck(duration => duration * DateHelpers.milisecondsInMinute);
        const msGap = gap.pluck(gap => gap * DateHelpers.milisecondsInMinute);

        const amount = request.amount ?? 0;

        const initialIntervals = ko.flattenComputed(request.initialIntervals, []).mapSingle(intervals => this.processIntervals(from(), to(), intervals));

        const ranges = ko.pureComputed(() => ko.unwrap(this.config?.ranges?.(from(), to(), timezone()) ?? [{ from: from(), to: to() }]))
            .chainNotNull(ranges => ranges.mapSingle(ranges => this.processRanges(from(), to(), msDuration(), ranges)));

        const excludedRanges = ko.pureComputed(() => ko.unwrap(this.config?.excludedRanges?.(from(), to(), timezone()) ?? []))
            .mapNotNull(ranges => this.processRanges(from(), to(), msDuration(), ranges));

        const finalRanges = ko.strictComputed((ranges, excludedRanges, msDuration) => {
            if (ranges == undefined || excludedRanges == undefined)
                return [];

            if (excludedRanges.length == 0)
                return ranges;

            return RangesHelpers.excludeRanges(ranges, excludedRanges, msDuration);
        }, [ranges, excludedRanges, msDuration]).cache(CacheHelpers.intervalsComparator);

        const initFunc = this.config?.init ?? defaultInitFunc;
        const isInitialized = ko.pureComputed(() => initFunc(from(), to())).unwrap().default(false);
        const areRangesReady = ko.pureComputed(() => ranges() != undefined && excludedRanges() != undefined);
        const isReady = ko.pureComputed(() => areRangesReady() && isInitialized());

        const intervals = ko.strictComputed((isReady, msDuration, msGap, ranges) => {
            if (isReady) {
                return ranges.reduce((result, { tag, from: rangeFrom, to: rangeTo }) => {
                    const lastInterval = _.last(result);
                    const minFrom = lastInterval != undefined ? new Date(lastInterval.to.getTime() + msGap) : undefined;

                    let intervalFrom = minFrom != undefined ? DateHelpers.max(minFrom, rangeFrom) : rangeFrom,
                        intervalTo = new Date(intervalFrom.getTime() + msDuration);

                    while (intervalTo <= rangeTo) {
                        result.push({ tag, from: intervalFrom, to: intervalTo });

                        intervalFrom = new Date(intervalTo.getTime() + msGap);
                        intervalTo = new Date(intervalFrom.getTime() + msDuration);
                    }

                    return result;
                }, new Array<TaggedInterval>());
            }
        }, [isReady, msDuration, msGap, finalRanges]);

        return intervals.chainNotNull(intervals => intervals
            .filter(interval => !initialIntervals().some(initialInterval => RangesHelpers.checkIntersection(interval, initialInterval)))
            .concat(initialIntervals)
            .sort(CollectionsHelpers.intervalsSorterAsc)
            .reduce((result, interval, idx, all) => {
                if (amount == 0 || result.length < amount) {
                    const isAllowed = this.config?.filter?.(interval.from, interval.to, timezone()) ?? true;

                    if (isAllowed)
                        result.push(interval);
                }

                return result;
            }, () => new Array<TaggedInterval>()));
    }

    private processRanges(from: Date, to: Date, duration: number, ranges: Array<TaggedInterval>) {
        if (duration <= 0)
            return [];

        const validRanges = ranges
            .filter(range => range.from >= from && range.to <= to);

        return RangesHelpers.processRanges(validRanges);
    }

    private processIntervals(from: Date, to: Date, intervals: TaggedInterval[]) {
        return [...intervals]
            .sort(CollectionsHelpers.intervalsSorterAsc)
            .reduce((result, interval) => {
                const previousInterval = _.last(result);

                if (previousInterval == undefined || previousInterval.to < interval.from && interval.from >= from && interval.to <= to)
                    result.push(interval);
                else
                    console.warn('invalid interval', interval);

                return result;
            }, new Array<TaggedInterval>());
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import DiscussionManager from 'managers/discussion';
import { IWCCStorageManager } from 'managers/iStorage';

const { isStaging, discussionId } = settings;

interface DiscussionTermsManagerConfig {
    discussionId: string
}

@injectable()
@storageDefaults(<Partial<DiscussionTermsManagerConfig>>{ discussionId })
export default class DiscussionTermsManager {
    busy: Subscribable<boolean>
    agreedToTermsAndConditions: Subscribable<boolean>
    hasTermsAndConditions: Subscribable<boolean>
    hasToAgreeWithTermsAndConditions: Subscribable<boolean>

    constructor(
        @inject(wccModules.managerConfig) { discussionId }: DiscussionTermsManagerConfig,
        @inject(wccModules.storage) storage: IWCCStorageManager
    ) {
        const discussionManager = storage.get(DiscussionManager, { discussionId });
        const discussion = discussionManager.pluck(m => m.discussion);
        const discussionReady = discussion.isNotNull();

        const userManager = storage.get(CurrentUserManager, { discussionId });
        const user = userManager.pluck(m => m.person);
        const userReady = userManager.pluck(m => !m.loading(), false);
        const isLoggedIn = user.isNotNull();

        this.agreedToTermsAndConditions = user.pluck(u => u.agreedToTermsAndConditions, false);
        this.hasTermsAndConditions = discussion.pluck(u => u.needsTermsAndConditions, false);

        this.hasToAgreeWithTermsAndConditions = ko.pureComputed(() => {
            return isLoggedIn() &&
                this.hasTermsAndConditions() &&                
                !this.agreedToTermsAndConditions();
        });

        this.busy = ko.pureComputed(() => !discussionReady() || !userReady());
    }
}
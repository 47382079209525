import s3Pattern from 'patterns/s3Link';
import googleFontPattern from 'patterns/googleFont';
import { DOMHelpers } from 'helpers/dom';
import cloudflarePattern from '../patterns/cloudflare';

const patterns = new Array<RegExp>(s3Pattern, googleFontPattern, cloudflarePattern);

export class ProxyLinksRewriter {
    constructor() { }

    process(root: ParentNode) {
        const nodes = root.querySelectorAll('link, img, style');

        nodes.forEach(node => this.processNode(node));
    }

    processAndWatch(root: ParentNode) {
        this.process(root);

        const observer = new MutationObserver(mutations =>
            mutations.forEach(mutation => this.processNode(mutation.target)));

        observer.observe(document, { childList: true, subtree: true, attributes: true, attributeFilter: ['src', 'href'] });

        return () => observer.disconnect();
    }

    rewriteURL(url: string) {
        
        if(settings.proxyTaskResourcesAwsOnly){
            if(s3Pattern.test(url)){
                return this.wrapUrlAwsProxy(url);//wrap this with aws proxy only
            }
        }
        else {
            if (this.isSupportedURL(url)) {
                return this.wrapURL(url);
            }
        }

        return url;
    }

    private processNode(node: Node) {
        if (DOMHelpers.isHTMLElement(node)) {
            switch (node.tagName.toLowerCase()) {
                case 'link':
                    this.processLink(node as HTMLLinkElement);
                    break;
                case 'img':
                    this.processImage(node as HTMLImageElement);
                    break;
                case 'style':
                    this.processStyles(node as HTMLStyleElement);
                    break;

                default:
                    if (node.childNodes.length > 0)
                        node.childNodes.forEach(node => this.processNode(node));
            }
        }
    }

    private processLink(link: HTMLLinkElement) {
        if (this.isSupportedURL(link.href))
            link.href = this.wrapURL(link.href);
    }

    private processImage(image: HTMLImageElement) {
        if (this.isSupportedURL(image.src))
            image.src = this.wrapURL(image.src);
    }

    private processStyles(styles: HTMLStyleElement) {
        const newContent = patterns.reduce((result, pattern) => {
            return [...result.matchAll(new RegExp(pattern, 'g'))].reduce((result, match) => {
                if (match.index != undefined)
                    return result.substring(0, match.index) + this.wrapURL(match[0]) + result.substring(match.index + match[0].length);

                return result;
            }, result);
        }, styles.innerHTML);

        if (newContent !== styles.innerHTML)
            styles.innerHTML = newContent;
    }

    private wrapURL(link: string) {
        return settings.proxyURL + link.replace(/((http|https):)?\/\//, '');
    }

    private wrapUrlAwsProxy(link: string) {
        return settings.proxyUrlAwsOnly + link.replace(/((http|https):)?\/\//, '');
    }

    private isSupportedURL(url: string) {
        return patterns.some(p => p.test(url));
    }
}

export const proxyLinksRewriter = new ProxyLinksRewriter();
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CollectionDataSource } from 'managers/datasources/collection';
import { EffectsContainer } from 'mixins/withEffects';
import { JSONSurveyPage } from 'models/survey/page';
import Survey from 'models/survey/survey';
import { SurveysService } from 'services/surveys';

export interface SurveyManagerConfig {
    topicId: string
}

@injectable()
export class SurveyManager extends CollectionDataSourceOwner<JSONSurveyPage, 'ProfilePageId'> {
    private topicId: string
    
    protected source: CollectionDataSource<JSONSurveyPage, 'ProfilePageId'>

    survey: Subscribable<Survey | undefined>

    constructor(
        @inject(wccModules.managerConfig) config: SurveyManagerConfig,
        @inject(wccModules.surveysService) private surveysService: SurveysService,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.topicId = config.topicId;
        
        this.source = effects.register(new CollectionDataSource({
            load: this.load.bind(this),
        }));

        this.survey = this.source.list.mapSingle(pages => this.getSurvey(pages)).extend({ deferred: true });
    }

    private load() {
        return settings.isAdmin ?
            this.surveysService.adminQueries.topicSurveyPages(this.topicId).toArray() :
            this.surveysService.queries.topicSurveyPages(this.topicId).toArray();
    }

    private getSurvey(pages: Array<JSONSurveyPage>) {
        if (pages.length > 0)
            return new Survey({ Pages: pages });
    }
}
import { ImageEditorConfig, ImageEditorSourceConfig } from 'components/editors/image/image';

export module ImageHelpers {
    export function getImage(url: string, noCrossoriginOrOrigin: boolean | string = false) {
        let img = new Image();

        if (_.isString(noCrossoriginOrOrigin))
            img.crossOrigin = noCrossoriginOrOrigin;
        else if (!noCrossoriginOrOrigin)
            img.crossOrigin = window.location.origin;
        else
            img.crossOrigin = 'anonymous';

        img.src = url;

        return img;
    }

    // This cache can be dirty and have images that have not had crossorigin='anonymous' set and completely stuff
    // up any canvas operations when you want to download the image.
    const imagesLoadersCache = new Map<string, Promise<HTMLImageElement>>();
        
    export function waitImage(image: HTMLImageElement, ignoreCache? :boolean): Promise<HTMLImageElement> {
        const src = image.src;

        // added an ignore cache option as the changes to signed s3 links have meant the cached image link expires
        let promise = (ignoreCache == undefined || !ignoreCache) ? imagesLoadersCache.get(src) : undefined;

        if (promise == undefined) {
            promise = new Promise((resolve, reject) => {
                const newImage = new Image();
                
                newImage.onload = () => {
                    _.defer(() => resolve(image));
                }

                newImage.onerror = () => {
                    reject(new Error(`Image is not available. url: ${src}`));
                }

                // always apply crossorigin as src will always be from outside our domain (and if not it gets ignored anyway)
                // this some how breaks the waitImage promise and throws errors
                newImage.crossOrigin = 'anonymous';
                
                newImage.src = src;
            });

            imagesLoadersCache.set(src, promise);

            promise.catch(() => imagesLoadersCache.delete(src));
        }

        return promise;
    }

    export async function checkIfImageExists(url: string) {
        try {
            await waitImage(getImage(url, false));
            return true;
        } catch (ex) {
            return false;
        }
    }

    // basically the same as above, but we ignore the image cache and return a reference to the attachment/file being checked
    export async function checkIfS3ImageHasExpired(id: string, url: string) {
        try {
            await waitImage(getImage(url), true);   // ignore cache
            return { id: id, exists: true };
        } catch (ex) {
            console.log(ex);
            return { id: id, exists: false }
        }
    }

    interface DownscaleOptions {
        width: number
        height: number
        type?: 'image/png' | 'image/jpeg' | 'image/webp',
        quality?: number
    }

    export async function downscaleImage(image: HTMLImageElement, opts: DownscaleOptions) {
        let { width, height, quality = 1 } = opts;

        const awaitedImage = await waitImage(image);

        let type = opts.type ?? (awaitedImage.src.indexOf('base64') !== -1 ?
            system.getContentTypeFromBase64(awaitedImage.src) :
            system.getContentTypeFromExtension(system.getExtension(awaitedImage.src)));

        var xScale = awaitedImage.width > width ?
            width / awaitedImage.width :
            1;

        var yScale = awaitedImage.height > height ?
            height / awaitedImage.height :
            1;

        var scale = xScale < yScale ? xScale : yScale;

        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        canvas.width = awaitedImage.width * scale;
        canvas.height = awaitedImage.height * scale;

        if (ctx)
            ctx.drawImage(awaitedImage, 0, 0, awaitedImage.width, awaitedImage.height, 0, 0, canvas.width, canvas.height);

        var newImage = new Image();
        newImage.src = canvas.toDataURL(type, quality);

        return newImage;
    }

    interface ImageEditingConfig {
        src: string
        srcs?: Array<ImageEditorSourceConfig>
        initialSize?: number
    }

    export async function pickUnsplashImage(initialSize?: number) {
        const { default: UnsplashImagesPickerVM } = await import('components/imagepickers/external/unsplash');
        const image = await UnsplashImagesPickerVM.pick();

        if (image != undefined) {
            const urls = image.urls();
            const url = urls.regular;

            return await ImageHelpers.editImage({
                src: url,

                srcs: [
                    { name: 'full', url: urls.full },
                    { name: 'regular', url: urls.regular }
                ],

                initialSize
            });
        }
    }

    export async function pickPixabayImage(initialSize?: number) {
        const { default: PixabayImagesPickerVM } = await import('components/imagepickers/external/pixabay');
        const image = await PixabayImagesPickerVM.pick();

        if (image != undefined) {
            const urls = image.urls();
            const url = urls[960];

            return await ImageHelpers.editImage({
                src: url,

                srcs: [
                    { name: 'original', url: urls.original },
                    { name: '1920', url: urls[1920] },
                    { name: '1280', url: urls[1280] },
                    { name: '960', url: urls[960] }
                ],

                initialSize
            });
        }
    }

    export function editImage(config: ImageEditingConfig) {
        return new Promise<string | undefined>(async resolve => {
            const { default: overlayManager } = await import('managers/overlay');

            overlayManager.toOverlay(`<wcc-image-editor params="model: $data" />`, {
                lock: true,
                buttons: ['close'],

                data: stage => (<ImageEditorConfig>{
                    ...config,

                    save: (src: string) => {
                        resolve(src);
                        stage.close();
                    },

                    cancel: () => {
                        resolve(undefined);
                        stage.close();
                    }
                })
            });
        });
    }

    export function getBlob(url: string) {
        return new Promise<Blob>((resolve, reject) => {
            let xhr = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        resolve(xhr.response);
                    } else {
                        reject(xhr.status);
                    }
                }
            };

            xhr.open('GET', url);
            xhr.responseType = 'blob';

            try {
                xhr.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    export function toBase64(image: HTMLImageElement, full = false) {
        var contentType = null;

        if (image.src != null) {
            if (image.src.indexOf('base64') != -1) {
                contentType = system.getContentTypeFromBase64(image.src);
            } else {
                contentType = system.getContentTypeFromExtension(system.getExtension(image.src));
            }

            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            let ctx = canvas.getContext('2d');

            if (ctx)
                ctx.drawImage(image, 0, 0, image.width, image.height);

            let data = canvas.toDataURL(contentType);

            if (data != null) {
                if (full) {
                    return data;
                } else {
                    let parts = data.split(',');

                    if (parts.length == 2)
                        return parts[1];
                }
            }
        }
    }
}
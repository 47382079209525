import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import { IWCCStorageManager } from 'managers/iStorage';
import { TopicThread } from 'models/topicThread';
import { ITopicMessagesAccessManager } from './iMessagesAccess';

const { threadEditInterval, threadDeleteInterval, now } = settings;

@injectable()
export default class TopicMessagesAccessManager implements ITopicMessagesAccessManager {
    private userId: Subscribable<string | undefined>
    private isModerator: Subscribable<boolean>

    ready: Subscribable<boolean>

    constructor(
        @inject(wccModules.storage) storage: IWCCStorageManager
    ) {
        const userManager = storage.get(CurrentUserManager, {});
        const user = userManager.pluck(m => m.person);
        const userReady = userManager.pluck(m => !m.loading(), false);
        this.userId = user.pluck(u => u.personId);
        this.isModerator = user.pluck(u => u.isModerator, false);

        this.ready = ko.pureComputed(() => userReady());        
    }

    isValid(message: TopicThread) {
        const isSaved = message.id() != null;
        const isDeleted = message.isDeleted();
        const isSyncRequired = message.syncRequired();

        return isSaved && !isDeleted && !isSyncRequired;
    }

    isAllowedToEdit(message: TopicThread) {
        const isRegularMessage = message.specialType() === enums.SpecialThreadTypes.None.value;
        const isModerator = this.isModerator();

        if (this.isValid(message) && isRegularMessage) {
            if (isModerator)
                return true;

            return message.postedById() === this.userId() &&
                now().getTime() - message.createDate().getTime() < threadEditInterval;
        }

        return false;
    }

    isAllowedToDelete(message: TopicThread) {
        const isModerator = this.isModerator();

        if (this.isValid(message)) {
            if (isModerator)
                return true;

            return message.postedById() === this.userId() &&
                now().getTime() - message.createDate().getTime() < threadDeleteInterval &&
                message.replies().length == 0;
        }

        return false;
    }

    isAllowedToReply(message: TopicThread) {
        return this.isValid(message);
    }
}
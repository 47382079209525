import { Comparable } from 'interfaces/comparable';
import { js } from 'js-beautify';
import { Observable, PureComputed } from "knockout";
import WCCImage from './attachments/image';
import WCCEntity from './entity';

export interface JSONTopicCard {
    TopicCardId: string
    DiscussionTopicId: string
    Text?: string
    CardType: number
    CardOrder: number
    IsPinned?: boolean
    UserFileId?: string
    ImageName?: string
    FileToken?: string
    Image?: WCCImage
}

export class TopicCard extends WCCEntity<JSONTopicCard> implements Comparable<TopicCard> {
    randomOrder = ko.observable(_.random(10000));

    id: Observable<string>
    topicId: Observable<string>
    title: Observable<string>
    type: Observable<number>
    order: Observable<number>
    isPinned: Observable<boolean>
    isSelected = ko.observable(false);

    image: Observable<WCCImage | undefined>

    constructor(data: JSONTopicCard) {
        super();

        this.id = this.createField(data, 'TopicCardId');
        this.topicId = this.createField(data, 'DiscussionTopicId');
        this.title = this.createField(data, 'Text', '');
        this.type = this.createField(data, 'CardType');
        this.order = this.createField(data, 'CardOrder');
        this.isPinned = this.createField(data, 'IsPinned', false);

        this.image = ko.observable(this.getImage(data));
    }

    override toJson() {
        return {
            ...super.toJson(),

            UserFileId: this.image()?.userFileId(),
            ImageName: this.image()?.cdnFileName(),
            FileToken: this.image()?.fileToken()
        }
    }

    override update(data: JSONTopicCard) {
        super.update(data);

        this.image(this.getImage(data));
    }

    equals(card: TopicCard) {
        return this.id() == card.id();
    }

    private getImage(jsonModel: JSONTopicCard) : WCCImage | undefined {        
        // if we have the WCCImage object already, use that so it gets the s3 signed link from its file
        if (jsonModel.Image != undefined)
            return jsonModel.Image;        
        
        if (jsonModel.UserFileId != undefined)
            return new WCCImage({
                UserFileId: jsonModel.UserFileId,
                CDNFileName: jsonModel.ImageName,
                FileType: enums.AttachmentTypes.Image.value,                
                FileToken: jsonModel.FileToken  // token is now saved and used to reference card sort images in threadcontent
            });                
    }
}
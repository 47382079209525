import { Observable } from "knockout";
import WCCEntity from "../entity";

export interface JSONOnlineUserSlideVideoDetails {
    State: number
}

export class OnlineUserSlideVideoDetails extends WCCEntity<JSONOnlineUserSlideVideoDetails> {
    state: Observable<number>

    static create(data: JSONOnlineUserSlideVideoDetails) {
        return new OnlineUserSlideVideoDetails(data);
    }

    constructor(data: JSONOnlineUserSlideVideoDetails) {
        super();

        this.state = this.createField(data, 'State');
    }
}
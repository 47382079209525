const cache = new Map<string, string>();

/**
 * Loads Dynamic View from server
 * all views are cached once loaded
 * templates from views are added to document head
 * @param viewName
 */
export async function loadView(viewName: string) {
    try {
        let html = cache.get(viewName);

        if (html == undefined) {
            html = await $.ajax({
                url: '/dynamicViews?viewName=' + viewName,
                method: 'GET',
                dataType: 'html'
            }) as string;

            cache.set(viewName, html);

            system.getTemplatesAndResources(html)
                .forEach(node => document.head.appendChild(node));
        }

        return html;
    } catch(ex) {
        console.error(ex);
        throw ex;
    }
}
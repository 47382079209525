import { Observable } from "knockout";

export interface JSONTermsAndConditionsStatement {
    Id?: number
    StatementText?: string
    IsMandatory?: boolean
    IsAgreed?: boolean
}

export class TermsAndConditionsStatement {
    _json: JSONTermsAndConditionsStatement

    id: Observable<number | undefined>
    statementText: Observable<string>
    isMandatory: Observable<boolean>
    isAgreed: Observable<boolean>

    needsAgreement = ko.observable(false);  

    isValid = ko.pureComputed(() => !this.isMandatory() || (this.isMandatory() && this.isAgreed()));

    constructor(jsonData: JSONTermsAndConditionsStatement) {
        this._json = jsonData;

        this.id = ko.observable(jsonData.Id);
        this.statementText = ko.observable(jsonData.StatementText ?? '');
        this.isMandatory = ko.observable(jsonData.IsMandatory ?? false);
        this.isAgreed = ko.observable(false as boolean);
    }

    toJson(): JSONTermsAndConditionsStatement {
        return {
            Id: this.id(),
            StatementText: this.statementText(),
            IsMandatory: this.isMandatory(),
            IsAgreed: this.isAgreed()
        }
    }

    update(jsonData: JSONTermsAndConditionsStatement) {
        this.id(jsonData.Id ?? 1);
        this.statementText(jsonData.StatementText ?? '');
        this.isMandatory(jsonData.IsMandatory ?? true);
        this.isAgreed(jsonData.IsAgreed ?? false);
    }
}
import WCCImage from "../models/attachments/image";
import { ImageHelpers } from "./images";

export module AttachmentsHelpers {
    export async function getImage(url: string) {
        const image = await ImageHelpers.waitImage(ImageHelpers.getImage(url));

        return new WCCImage({
            FileType: enums.AttachmentTypes.Image.value,

            Files: [
                {
                    Link: url,
                    Type: enums.UserFileTypes.OriginalImage.value,
                    Width: image.width,
                    Height: image.height
                }
            ]
        });
    }

    export function isImage(type?: number, includeSystem = false) {
        switch (type) {
            case enums.AttachmentTypes.Image.value:
            case enums.AttachmentTypes.VirtualPinBoardImage.value:
            case enums.AttachmentTypes.VirtualPinBoardBackground.value:
            case enums.AttachmentTypes.SurveyAnswerImage.value:
                return true;
            case enums.AttachmentTypes.SurveyImage.value:
                return includeSystem;
        }

        return false;
    }

    export function isVideo(type?: number) {
        switch (type) {
            case enums.AttachmentTypes.Video.value:
            case enums.AttachmentTypes.SurveyAnswerVideo.value:
            case enums.AttachmentTypes.VideoPresentationVideo.value:
                return true;
        }

        return false;
    }

    export function isAudio(type?: number) {
        switch (type) {
            case enums.AttachmentTypes.Audio.value:
            case enums.AttachmentTypes.SurveyAnswerAudio.value: return true;
            default: return false;
        }
    }

    export function isFile(type?: number) {
        switch (type) {
            case enums.AttachmentTypes.File.value:
            case enums.AttachmentTypes.SurveyAnswerFile.value:
                return true;
        }

        return false;
    }

    export function isLink(type?: number) {
        return type == enums.AttachmentTypes.Link.value;
    }

    export function isImageIsh(type?: number) {
        switch (type) {
            case enums.AttachmentTypes.ActivityInstructionsImage.value:
            case enums.AttachmentTypes.TopHeroImage.value:
            case enums.AttachmentTypes.ActivityImageSmall.value:
            case enums.AttachmentTypes.ActivityImageMedium.value:
            case enums.AttachmentTypes.ActivityImageBig.value:
            case enums.AttachmentTypes.ThemeImage.value:
            case enums.AttachmentTypes.IdeaRatingImage.value:
            case enums.AttachmentTypes.SurveyImage.value:
            case enums.AttachmentTypes.ShowreelTitleBackground.value:
            case enums.AttachmentTypes.RatingMatrixBackground.value:
                return true;
        }

        return false;
    }

    export function isYouTube(type?: number) {
        return type == enums.AttachmentTypes.YouTubeCode.value;
    }

    export function isVimeo(type?: number) {
        return type == enums.AttachmentTypes.VimeoCode.value;
    }

    export function isVideoplayer(type?: number) {
        return isYouTube(type) || isVimeo(type);
    }

    export function isSurveyResponseAttachment(type?: number) {
        switch (type) {
            case enums.AttachmentTypes.SurveyAnswerVideo.value:
            case enums.AttachmentTypes.SurveyAnswerImage.value:
            case enums.AttachmentTypes.SurveyAnswerFile.value:
            case enums.AttachmentTypes.SurveyAnswerAudio.value:
                return true;
        }

        return false;
    }


    export function isProjectToDoAttachment(type?: number) {
        return type == enums.AttachmentTypes.ProjectToDoAttachment.value;
    }


    export function getFileIcon(fileExtension?: string): string {
        switch (fileExtension?.replace('.', '')) {
            case 'pdf': return 'fal fa-file-pdf';
            case 'doc':
            case 'dot':
            case 'docx':
            case 'docm':
            case 'docb': return 'fal fa-file-word';
            case 'png':
            case 'gif':
            case 'bmp':
            case 'jpeg':
            case 'jpg': return 'fal fa-image';
            case 'ppsm':
            case 'ppsx':
            case 'pptm':
            case 'pptx':
            case 'ppt': return 'fal fa-file-powerpoint';
            case 'xlsm':
            case 'xlsx':
            case 'xls': return 'fal fa-file-excel';
        }

        return 'fal fa-file';
    }

    export function getTokenFromS3SignedLink(link: string) {
        if (link == null || link.length == 0) return link;
                
        const unsginedLink = link.split('?')[0];
        const token = unsginedLink.split('/').pop();
        return token;
    }

    export function getFileAttachmentImageURL(fileExtension?: string) {
        const imageClass = getFileAttachmentImageClass(fileExtension?.toLowerCase());

        return <string>require(`Content/images/fileTypes/${imageClass}.png`).default;
    }

    function getFileAttachmentImageClass(fileExtension?: string) {
        switch (fileExtension) {
            case 'pdf': return 'pdf';
            case 'doc':
            case 'dot':
            case 'docx':
            case 'docm':
            case 'docb': return 'doc';
            case 'png':
            case 'gif':
            case 'bmp':
            case 'jpeg':
            case 'jpg': return 'photo';
            case 'ppsm':
            case 'ppsx':
            case 'pptm':
            case 'pptx':
            case 'ppt': return 'ppt';
            case 'xlsm':
            case 'xlsx':
            case 'xls': return 'xls';
            default: return 'file';
        }
    }    

    export function getRatio(width: number, height: number, isCoverModeOrCoverRatio: number | boolean | undefined) {
        if (_.isNumber(isCoverModeOrCoverRatio))
            return 100 / isCoverModeOrCoverRatio;
        else if (isCoverModeOrCoverRatio === true)
            return 80;

        if (width > 0 && height > 0)
            return height / width * 100;

        return 56.65;
    }

    
}
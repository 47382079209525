import { CollectionsHelpers } from "helpers/collections";

export interface SignalRMessageConfig {
    name: string
    args?: Array<any>
    ttl?: number
    unique?: boolean
    removeOnReconnect?: boolean
}

export default class SignalRMessage {
    private result = ko.observable<Promise<void>>();

    name: string
    args: Array<any>
    ttl: number
    unique: boolean
    removeOnReconnect: boolean

    promise: Promise<void>

    constructor(config: SignalRMessageConfig) {
        this.name = config.name;
        this.args = config.args ?? [];
        this.ttl = config.ttl ?? 1;
        this.unique = config.unique ?? false;
        this.removeOnReconnect = config.removeOnReconnect ?? false;

        this.promise = new Promise(async (resolve, reject) => {
            try {
                resolve(await this.result.whenNotNull());
            } catch(ex) {
                reject(ex);
            }
        });
    }

    onSent() {
        this.result(Promise.resolve());
    }

    onFail(ex?: any) {
        this.result(Promise.reject(ex));
    }

    equals(other: SignalRMessage) {
        return other.name === this.name && CollectionsHelpers.compareCollections(other.args, this.args);
    }
}
import { Observable, ObservableArray } from 'knockout';
import WCCAttachment, { JSONWCCAttachment } from 'models/attachments/attachment';
import WCCEntity from 'models/entity';
import attachmentsFactory from 'factories/attachments';

export interface JSONCommunityPageHistoryItem {
    communityPageId: string
    pageContent: string
    state: number
    version: number
    attachments: Array<JSONWCCAttachment>
}

export default class CommunityPageHistoryItem extends WCCEntity<JSONCommunityPageHistoryItem> {
    id: Observable<string>
    content: Observable<string>
    state: Observable<number>
    version: Observable<number>

    attachments: ObservableArray<WCCAttachment>

    constructor(data: JSONCommunityPageHistoryItem) {
        super();

        this.id = this.createField(data, 'communityPageId');
        this.content = this.createField(data, 'pageContent');
        this.state = this.createField(data, 'state');
        this.version = this.createField(data, 'version');

        this.attachments = this.createCollection(data, 'attachments', jsonAttachment => attachmentsFactory.createAttachment(jsonAttachment));
    }
}
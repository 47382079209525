define('managers/disposable_es5', [
    'managers/disposables'
], function (Disposables) {
    function Manager() {
        this.__initialized = false;
        this.__initTasks = [];

        this._disposables = new Disposables();
    }

    Manager.prototype.init = function () {
        _(this.__initTasks).each(function (func) { func() });
        this.__initialized = true;
    };

    Manager.prototype.dispose = function () {
        this._disposables.dispose();
    };

    Manager.prototype._register = function (field) {
        if (this.__initialized) {
            this._disposables.register(field);
        } else {
            this.__initTasks.push(function () {
                this._disposables.register(field);
            }.bind(this));
        }

        return field;
    };

    return Manager;
});
define('managers/polls',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const { discussionId } = settings;

        const DataSource = require('managers/datasources/collection').CollectionDataSource;
        const Poll = require('models/simpleTopic').SimpleTopic;

        function Manager(isAdmin) {
            Base.call(this);

            this._queriesType = isAdmin ? 'adminQueries' : 'queries';

            this._datasource = this._register(new DataSource({
                load: this._load.bind(this),
                update: this._update.bind(this),
                mapper: function (jsonPoll) { return new Poll(jsonPoll) }
            }));

            this.polls = this._datasource.list;
            this.loading = this._datasource.loading;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.costructor = Manager;

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.discussionService.subscriptions.discussion.subscribe(discussionId);

            this._ctx.pollsService.events.pollAdded.on(this._onPollAdded.bind(this));
            this._ctx.pollsService.events.pollChanged.on(this._onPollChanged.bind(this));
            this._ctx.topicsService.events.topicDeleted.on(this._onPollDeleted.bind(this));
        };

        Manager.prototype._load = function () {
            return this._ctx
                .pollsService[this._queriesType]
                .polls()
                .background()
                .toArray();
        };

        Manager.prototype._update = function (poll) {
            var self = this;

            return this._ctx
                .pollsService[this._queriesType]
                .poll(poll.id())
                .firstOrDefault()
                .then(function (jsonPoll) { jsonPoll != null ? poll.update(jsonPoll) : self._datasource.remove(poll) });
        };

        Manager.prototype._onPollAdded = function (targetDiscussionId) {
            if (targetDiscussionId === discussionId)
                this._datasource.load();
        };

        Manager.prototype._onPollChanged = function (targetDiscussionId, pollId) {
            this._datasource.update(function (poll) { return poll.discussionTopicId() === pollId });
        };

        Manager.prototype._onPollDeleted = function (targetDiscussionId, pollId) {
            if (targetDiscussionId === discussionId)
                this._datasource.load();
        };

        return Manager;
    });
define('models/core/timers', [], function () {
    function Model() {
        var self = this,
            timers = [];

        self.add = function (action, timeout) {
            var timer = setInterval(action, timeout);

            var result = {
                dispose: function () {
                    clearInterval(timer);
                }
            }

            timers.push(result);

            return result;
        }

        self.dispose = function () {
            _(timers).invoke('dispose');
        }
    }

    return Model;
});
import { AsyncDisposer, Disposer } from "interfaces/disposer";
import ko, { UnwrappedTuple } from "knockout";
import { Disposable, isDisposable } from "../interfaces/disposable";
import { Initializable, isInitializable } from "../interfaces/initializable";

type EffectElement = Disposable | Disposable & Initializable | Disposer | void | undefined
export type Effect = PromiseLike<Array<EffectElement> | EffectElement> | Array<Effect> | EffectElement

export interface EffectFactory<T extends Array<any>> {
	(...args: UnwrappedTuple<[...T]>): Effect
}

async function getDisposer(effect: Effect): Promise<Disposer | AsyncDisposer> {
	if (_.isArray(effect)) {
		var disposers = await Promise.all(effect.map(getDisposer));

		return async () => {
			await Promise.all(disposers.map(dispose => dispose()));
		}
	} else if (_.isObject(effect) && 'then' in effect) {
		return await getDisposer(await effect);
	} else if (ko.isComputed(effect)) {
		return () => effect.dispose();
	} else if (isDisposable(effect)) {
		if (isInitializable(effect))
			effect.init();

		return () => effect.dispose();
	} else if (_.isFunction(effect)) {
		return effect;
	} else {
		return _.noop;
	}
}

export function withEffect<T>(data: T): T
export function withEffect(factory: EffectFactory<[]>): Disposer | AsyncDisposer
export function withEffect<T extends Array<any>>(factory: EffectFactory<[...T]>, deps: [...T], extenders?: ko.ObservableExtenderOptions<void>): Disposer | AsyncDisposer
export function withEffect<T extends Array<any>>(factory: EffectFactory<[...T]>, deps: [...T], async?: boolean): Disposer | AsyncDisposer

export function withEffect(factoryOrEffect: any, deps: Array<any> = [], asyncOrExtenders: boolean | ko.ObservableExtenderOptions<void> = true) {
	const id = _.uniqueId('effect-');

	let isDisposed = false;
	let disposeInner: Disposer | AsyncDisposer = _.noop;

	const unwrappedDeps = ko.pureComputed(() => deps.map(ko.unwrap));
	const hasChanges = ko.observable(true);
	const isProcessing = ko.observable(false);

	const disposables: Array<Disposable> = [
		unwrappedDeps.subscribe(() => hasChanges(true))
	]

	const processor = ko.computed(() => {
		if (hasChanges() && !isProcessing()) {
			ko.ignoreDependencies(async () => {
				isProcessing(true);
				hasChanges(false);

				try {
					//console.log(id, 'processing effect cycle', isProcessing(), hasChanges(), isDisposed, unwrappedDeps());
					//console.log(id, 'calling previous cycle disposer');

					const disposeInnerTask = disposeInner();
					disposeInner = _.noop;

					if (disposeInnerTask != undefined && 'then' in disposeInnerTask)
						await disposeInnerTask;

					//console.log(id, 'previous cycle disposer finished');

					const deps = unwrappedDeps();
					const effect = _.isFunction(factoryOrEffect) ? factoryOrEffect(...deps) : factoryOrEffect;

					//console.log(id, 'constructing new disposer');

					const disposer = await getDisposer(effect);

					//console.log(id, 'new disposer construction finished');

					if (isDisposed) {
						//console.log(id, 'effect is disposed. calling disposer');
						disposer();
					} else {
						//console.log(id, 'effect is alive. saving disposer');
						disposeInner = disposer;
					}
				} catch (ex) {
					console.error(id, ex);
				} finally {
					isProcessing(false);
					//console.log(id, 'finished processing effect cycle', isProcessing(), hasChanges(), isDisposed, unwrappedDeps());
				}
			});
		}
	});

	if (asyncOrExtenders === true)
		processor.extend({ deferred: true });

	if (_.isObject(asyncOrExtenders))
		processor.extend(asyncOrExtenders);

	disposables.push(processor);

	return () => {
		if (!isDisposed) {
			isDisposed = true;

			disposables.forEach(d => d.dispose());
			disposeInner();
		}
	}
}
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import { URLQueryDataMap } from "../../../builders/url";
import storageOptional from "../../../decorators/storageOptional";
import { wccModules } from "../../../enums/wccModules";
import { EffectsContainer } from "../../../mixins/withEffects";
import { apiTimeBoundaries } from "../../../models/apiTimeBoundaries";
import { JSONGroupRoom } from "../../../models/appointments/groupRoom";
import { Interval, JSONInterval } from "../../../models/interval";
import TimelineDataSourceOwner from "../../datasources/owners/timelineDataSourceOwner";
import { TimelineDataSource, TimelineDataSourceLoadRequest } from "../../datasources/timeline";
import events from "../../events";
import SignalREventsManager from "../../signalR/events";

export interface BaseAppointmentsIntervalsManagerConfig {
    discussionId: string
    host?: string
    excludedRoomIDs?: string[]
}

@injectable()
@storageOptional<BaseAppointmentsIntervalsManagerConfig>('host', 'excludedRoomIDs')
export default abstract class BaseAppointmentsIntervalsManager extends TimelineDataSourceOwner<Interval, JSONInterval, string> {
    protected discussionId: string
    protected host?: string

    protected source: TimelineDataSource<Interval, JSONInterval, string>

    intervals: Subscribable<Array<Interval>>

    constructor(
        @inject(wccModules.managerConfig) private config: BaseAppointmentsIntervalsManagerConfig,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        const { discussionId, host } = config;

        this.discussionId = discussionId;
        this.host = host;

        this.source = new TimelineDataSource({
            key: interval => interval.id,
            time: interval => interval.from,
            load: request => this.loadIntervals(request),
            boundaries: () => this.loadBoundaries(),
            mapper: jsonInterval => new Interval(jsonInterval)
        });

        this.intervals = this.source.list;

        signalREvents.system().onReconnected(() => this.source.reset());

        signalREvents.discussionAppointmentsIntervals(discussionId)
            .onIntervalsChanged((discussionId, from, to) => this.updateRange(new Date(from), new Date(to)));

        signalREvents.discussionInterviewers(discussionId).onInterviewerSettingsChanged(this.onInterviewerSettingsChanged.bind(this));

        requestAnimationFrame(() => {
            effects.register(this.source);

            effects.register([
                events.appointmentAdded.on(this.onLocalAppointmentCreated.bind(this)),
                events.appointmentUpdated.on(this.onLocalAppointmentUpdated.bind(this)),
                events.appointmentRemoved.on(this.onLocalAppointmentRemoved.bind(this)),
                events.interviewerSettingsChanged.on(this.onLocalInterviewerSettingsChanged.bind(this))
            ]);
        });
    }

    protected abstract loadIntervals(request: TimelineDataSourceLoadRequest): PromiseLike<JSONInterval[]>
    protected abstract loadBoundaries(): PromiseLike<apiTimeBoundaries>

    protected getQueryArgs(): URLQueryDataMap {
        return {
            host: this.config.host,
            excludedRoomIDs: this.config.excludedRoomIDs
        }
    }

    private onInterviewerSettingsChanged(discussionId: string, personId: string) {
        if (this.host == undefined || this.host == personId)
            this.source.reset();
    }

    private onLocalAppointmentCreated(discussionId: string, jsonARoom: JSONGroupRoom) {
        if (this.discussionId == discussionId && jsonARoom.StartsAt != undefined && jsonARoom.ReserveUntil != undefined)
            this.updateRange(new Date(jsonARoom.StartsAt), new Date(jsonARoom.ReserveUntil));
    }

    private onLocalAppointmentUpdated(discussionId: string, roomId: string, jsonRoom: JSONGroupRoom) {
        if (this.discussionId == discussionId && jsonRoom.StartsAt != undefined && jsonRoom.ReserveUntil != undefined)
            this.updateRange(new Date(jsonRoom.StartsAt), new Date(jsonRoom.ReserveUntil));
    }

    private onLocalAppointmentRemoved(discussionId: string, roomId: string, jsonRoom: JSONGroupRoom) {
        if (this.discussionId == discussionId && jsonRoom.StartsAt != undefined && jsonRoom.ReserveUntil != undefined)
            this.updateRange(new Date(jsonRoom.StartsAt), new Date(jsonRoom.ReserveUntil));
    }

    private onLocalInterviewerSettingsChanged(discussionId: string, personId: string) {
        if (this.discussionId == discussionId && this.host == undefined || this.host == personId)
            this.source.reset();
    }

    private updateRange(from: Date, to: Date) {
        const intervals = this.intervals().filter(interval => interval.from() >= from && interval.from() <= to)
        to = intervals.reduce((result, interval) => interval.to() > result ? interval.to() : result, to);

        this.source.updateRange(from, to);
    }
}
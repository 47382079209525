import endpoint from 'endpoints/api';
import events from 'managers/events';
import { apiCommunityPageContentUpdateRequestData } from 'models/apiCommunityPageContentUpdateRequestData';
import { apiCommunityPageImportResult } from 'models/apiCommunityPageImportResult';
import { apiCommunityPageRequestData } from 'models/apiCommunityPageRequestData';
import { apiCommunityPagesImportData } from 'models/apiCommunityPagesImportData';
import { apiCommunityPagesRequestData } from 'models/apiCommunityPagesRequestData';
import CommunityPage, { JSONCommunityPage } from 'models/community/page';
import { JSONCommunityWidgetTemplate } from 'models/community/widgetTemplate';
import { JSONContentImage } from 'models/contentImage';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import { URLQueryData } from '../builders/url';
import { ICommunitiesService } from './iCommunities';
import {JSONCommunityPerson, JSONCommunityPersonForAddEdit, JSONPersonRegistrationResult} from "../models/communityPerson";
import {ICaptchaManager} from "../managers/captchas";
import {JSONSurveyPageAnswersWithSurveyActivityId} from "../models/surveyPageAnswers";

const { sitePrefix } = settings;

export class CommunitiesService extends BaseService implements ICommunitiesService {
    private repository = new Repository(endpoint);

    adminPage = (pageId: string) => this.repository.entityGetQuery<JSONCommunityPage, apiCommunityPageRequestData>(`admin/communities/pages/${pageId}`);

    adminQueries = {
        pages: () => this.repository.query<apiCommunityPagesRequestData, JSONCommunityPage, never, never, never, never, never>('admin/communities/pages'),
        page: (pageId: string) => this.repository.query<apiCommunityPageRequestData, JSONCommunityPage | undefined, never, never, never, never, never>(`admin/communities/pages/${pageId}`),
        pageImage: (pageId: string) => this.repository.query<never, JSONContentImage, never, never, never, never, never>(`admin/communities/pages/${pageId}/image`),
        registrationANDProfileForms: () => this.repository.query(`communities/registrationAndProfileForms`),
        addParticipantFromSurvey: () => this.repository.query<JSONCommunityPersonForAddEdit, JSONCommunityPerson>("admin/community/people/registration"),
        updateAttributeForParticipantInAdmin: (personId: string) => this.repository.query<JSONSurveyPageAnswersWithSurveyActivityId, JSONCommunityPerson>(`admin/community/people/${personId}/updateAttribute`),
        loadParticipantWithSurveyData: (personId: string) => this.repository.query<never, JSONCommunityPersonForAddEdit>(`admin/community/people/${personId}/registration`),
        surveyResponses: (topicId: string) => this.repository.query(`admin/communities/surveys/${topicId}/responses`),
        surveyResponseContent: (topicId: string, personId: string) => this.repository.query(`admin/communities/surveys/${topicId}/responses/people/${personId}/content`),
    }

    queries = {
        discussions: (communityId: string) => this.repository.query(`communities/${communityId}/discussions`),
        pages: () => this.repository.query<apiCommunityPagesRequestData, JSONCommunityPage, Partial<JSONCommunityPage>, string, never, never, never>(`${sitePrefix}communities/pages`),
        page: (pageId: string) => this.repository.query<apiCommunityPageRequestData, JSONCommunityPage | undefined, never, never, Partial<JSONCommunityPage>, void, void>(`communities/pages/${pageId}`),
        publish: (pageId: string) => this.repository.query<never, never, never, void, never, never, never>(`communities/pages/${pageId}/publish`),
        publishAll: () => this.repository.query<never, never, never, void, never, never, never>('communities/pages/publish'),
        pageContent: (pageId: string) => this.repository.query(`community/pages/${pageId}/content`),
        pageStyles: (pageId: string) => this.repository.query(`community/pages/${pageId}/styles`),
        pagesImport: () => this.repository.query < never, never, apiCommunityPagesImportData, Array<apiCommunityPageImportResult>, never, never, never>('communities/pages/import'),
        survey: (surveyId: string) => this.repository.query(`communities/surveys/${surveyId}`),
        surveys: () => this.repository.query(`communities/surveys`),
        surveyShort: (topicId: string) => this.repository.query(`communities/surveys/${topicId}/short`),
        surveyResponse: (topicId: string) => this.repository.query(`communities/surveys/${topicId}/response`),
        surveyFeedback: (topicId: string) => this.repository.query(`communities/surveys/${topicId}/feedback`),
        surveyStart: (topicId: string) => this.repository.query(`communities/surveys/${topicId}/start`),
        widgetTemplates: () => this.repository.query<never, JSONCommunityWidgetTemplate, JSONCommunityWidgetTemplate, JSONCommunityWidgetTemplate, never, never, never>(`communities/templates`),
        widgetTemplate: (templateId: string) => this.repository.query<never, never, never, never, JSONCommunityWidgetTemplate, JSONCommunityWidgetTemplate, void>(`communities/templates/${templateId}`),
        addParticipantFromSurvey: () => this.repository.postQuery<JSONCommunityPersonForAddEdit, JSONPersonRegistrationResult>('community/people/registration'),
        updateParticipantFromSurvey: () => this.repository.updateQuery<JSONCommunityPersonForAddEdit, JSONPersonRegistrationResult>('community/people/registration'),
        registrationANDProfileForms: () => this.repository.query(`communities/registrationAndProfileForms/me`),
        loadParticipantWithSurveyData: () => this.repository.query<never, JSONCommunityPersonForAddEdit>(`communities/people/profiledata`),
        mailingListStartScreener: (token: string) => this.repository.fastQuery(`accounts/mailingList/${token}/startscreener`),
        taskMemberCodeForDiscussion: (discussionId: string) => this.repository.query(`communities/${discussionId}/taskmembercode`)
    }

    subscriptions = {
        community: this.signalRSubscription('Community'),
        communityPublishing: this.signalRSubscription('CommunityPublishing'),
        communityPages: this.signalRSubscription('CommunityPages')
    }

    events = {
        communityChanged: this.signalREvent('CommunityChanged'),
        publishProgress: this.signalREvent('PublishProgress'),
        communityPageAdded: this.signalREvent('CommunityPageAdded'),
        communityPageUpdated: this.signalREvent('CommunityPageUpdated'),
        communityPageDeleted: this.signalREvent('CommunityPageDeleted'),
        communityPagePublished: this.signalREvent('CommunityPagePublished'),
        communityPagesChanged: this.signalREvent('CommunityPagesChanged'),
        communityPagesImported: this.signalREvent('CommunityPagesImported'),
        communityPageImageChanged: this.signalREvent('CommunityPageImageChanged')
    }

    async getDiscussions(communityId: string) {
        return await this.queries.discussions(communityId).toArray();
    }

    async getTaskMemberCodeForDiscussion(discussionId: string) {
        return await this.queries.taskMemberCodeForDiscussion(discussionId).firstOrDefault();
    }

    async addPage(page: CommunityPage) {
        page.id(await this.queries.pages().add(page.toJson()));
        events.communityPageAdded.trigger(page);
    }

    async updatePage(pageId: string, data: Partial<JSONCommunityPage>) {
        await this.queries.page(pageId).update(data);
        events.communityPageUpdated.trigger(pageId, <JSONCommunityPage>data);
    }

    async updatePageContent(pageId: string, data: apiCommunityPageContentUpdateRequestData) {
        const jsonPage = await this.queries.pageContent(pageId).update(data);
        events.communityPageContentUpdated.trigger(pageId, jsonPage);
    }

    async deletePage(pageId: string) {
        await this.queries.page(pageId).remove();
        events.communityPageDeleted.trigger(pageId);
    }

    async publishPage(pageId: string) {
        await this.queries.publish(pageId).post();
        events.communityPagePublished.trigger(pageId);
    }

    async publishAll() {
        await this.queries.publishAll().addArgs({ 'domainId': system.getQueryStringParams().domainid }).post();
        events.communityPagesPublished.trigger();
    }

    async import(data: apiCommunityPagesImportData) {
        const results = await this.queries.pagesImport().post(data);
        events.communityPagesImported.trigger();

        return results;
    }

    async addWidgetTemplate(data: JSONCommunityWidgetTemplate) {
        const jsonTemplate = await this.queries.widgetTemplates().add(data);
        events.communityWidgetTemplateAdded.trigger(jsonTemplate);

        return jsonTemplate;
    }

    async updateWidgetTemplate(templateId: string, data: JSONCommunityWidgetTemplate) {
        const jsonTemplate = await this.queries.widgetTemplate(templateId).update(data);
        events.communityWidgetTemplateUpdated.trigger(templateId, jsonTemplate);

        return jsonTemplate;
    }

    async removeWidgetTemplate(templateId: string) {
        await this.queries.widgetTemplate(templateId).remove();
        events.communityWidgetTemplateRemoved.trigger(templateId);
    }

    async getRegistrationANDProfileFormsInAdmin(params: URLQueryData = '') {
        return await this.adminQueries.registrationANDProfileForms().addArgs(params).toArray();
    }

    async addParticipantFromSurveyInAdmin(data: JSONCommunityPersonForAddEdit, params: URLQueryData = '') {
        return await this.adminQueries.addParticipantFromSurvey().addArgs(params).firstOrDefaultPost(data);
    }

    async addParticipantFromSurvey(data: JSONCommunityPersonForAddEdit, captcha: ICaptchaManager, params: URLQueryData = '') {
        const query = await captcha.wrapPostQuery(this.queries.addParticipantFromSurvey().addArgs(params));
        return await query.firstOrDefaultPost(data);
    }
    
    async updateParticipantFromSurveyInAdmin(data: JSONCommunityPersonForAddEdit, params: URLQueryData = '') {
        return await this.adminQueries.addParticipantFromSurvey().addArgs(params).update(data);
    }

    async updateAttributeForParticipantInAdmin(personId: string, data: JSONSurveyPageAnswersWithSurveyActivityId, params: URLQueryData = '') {
        return await this.adminQueries.updateAttributeForParticipantInAdmin(personId).addArgs(params).update(data);
    }
    
    async updateParticipantFromSurvey(data: JSONCommunityPersonForAddEdit, params: URLQueryData = '') {
        return await this.queries.updateParticipantFromSurvey().addArgs(params).update(data);
    }

    async loadParticipantWithSurveyDataInAdmin (personId: string, params: URLQueryData = '') {
        return await this.adminQueries.loadParticipantWithSurveyData(personId).addArgs(params).firstOrDefault();
    }

    async getRegistrationANDProfileFormsInCommunity(ignoreHideRegistrationFormFromProfileFormSetting: boolean = true) {
        let qry = this.queries.registrationANDProfileForms();
        
        if (!ignoreHideRegistrationFormFromProfileFormSetting) {
            qry = qry.addArgs('ignoreHideRegistrationFormFromProfileFormSetting=false');
        }
        
        return await qry.toArray();
    }

    async loadParticipantWithSurveyDataInCommunity(ignoreHideRegistrationFormFromProfileFormSetting: boolean = true) {
        let qry = this.queries.loadParticipantWithSurveyData();
        
        if (!ignoreHideRegistrationFormFromProfileFormSetting) {
            qry = qry.addArgs('ignoreHideRegistrationFormFromProfileFormSetting=false');
        }
        return await qry.firstOrDefault();
    }

    /**
     * Initiates emails send procedure
     */
    async startScreener(token:string) {
        return await this.queries.mailingListStartScreener(token).firstOrDefaultPost();
    }
}
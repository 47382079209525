export module NotificationsHelpers {
    export let message: any = null;

    export function showInfoMessage(message: string, timeout?: number, theme?: string, position?: string, actions?: any) {
        return showMessage(message, 'info', timeout, theme, position, actions);
    }

    export function showSuccessMessage(message: string, timeout?: number, theme?: string, position?: string, actions?: any) {
        return showMessage(message, 'success', timeout, theme, position, actions);
    }

    let cutMessage = (message: string) => {
        let msg = message;
        if(msg.length > 100) {
            if(msg.indexOf('<') != -1) {
                msg = $('<div></div>').html(msg).text();
            }
            let jsId = 'msp' + (Math.random() + '').slice(2);
            msg =
                `<span id="${jsId}_short"> 
                    <span>${message.slice(0, 100)}...</span>
                    <a class="jsLink" href="javascript:void(0)" onclick="$('#${jsId}_short').hide();$('#${jsId}_full').show();">Show more</a>
                </span>
                <span id="${jsId}_full" style="display:none">${message}</span>`;
        }
        return msg;
    }
    export function showErrorMessage(message: string, timeout = 10, theme = 'future', position = 'messenger-on-bottom messenger-on-right', actions: any = {}) {
        console.warn(message);
        
        Messenger.options = Messenger.options ?? {};
        Messenger.options.theme = theme;
        Messenger.options.extraClasses = 'messenger-fixed ' + position;

        return Messenger().post({
            message: cutMessage(message),
            type: 'error',
            hideAfter: timeout,
            showCloseButton: true,
            actions: actions
        });
    }

    export function showMessage(message: string, type?: string, timeout = 10, theme = 'future', position = 'messenger-on-bottom messenger-on-right', actions: any = {}) {
        Messenger.options = Messenger.options ?? {};
        Messenger.options.theme = theme;
        Messenger.options.extraClasses = 'messenger-fixed ' + position;

        return Messenger().post({
            message: cutMessage(message),
            type: type,
            hideAfter: timeout,
            showCloseButton: true,
            actions: actions
        });
    }

    export function showFailedRequestMessage(response: any) {
        console.warn(response);
        if (response && response.responseJSON && response.responseJSON.error) {
            showErrorMessage(response.responseJSON.error);
        }
        else if (response && response.responseText) {
            showErrorMessage(response.responseText);
        }
        else {
            showErrorMessage(messages.AnErrorOccuredDuringYourRequest);
        }
    }

    export function setMessage(m: any) {
        message = m;
    }
}
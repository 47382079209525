import { Observable } from 'knockout';
import tagsParser from 'managers/tagsParser';
import WCCEntity from './entity';

export interface JSONVideoPresentationOptions {
    VideoType?: number
    VideoCode?: string
    MaxWatchAmount?: number
    PlayerMode?: number
    PresentationType?: number
    AdditionalContentVisibilityType?: number    
    ThumbnailUrl?: string
    BeforePlayedInstructions?: string
    AfterPlayedInstructions?: string
    VideoFinishedInstructions?: string
    AllowSkippingOnReview?: boolean
    AllowFullScreen?: boolean
}

export default class VideoPresentationOptions extends WCCEntity<JSONVideoPresentationOptions> {
    videoType: Observable<number | undefined>
    videoCode: Observable<string | undefined>
    maxWatchAmount: Observable<number | undefined>
    playerMode: Observable<number | undefined>
    presentationType: Observable<number | undefined>
    additionalContentVisibilityType: Observable<number | undefined>
    thumbnailUrl: Observable<string | undefined>
    beforePlayedInstructions: Observable<string | undefined>
    afterPlayedInstructions: Observable<string | undefined>
    videoFinishedInstructions: Observable<string | undefined>
    allowSkippingOnReview: Observable<boolean>
    allowFullScreen: Observable<boolean>

    constructor(data?: JSONVideoPresentationOptions) {
        super();

        this.videoType = this.createField(data, 'VideoType');
        this.videoCode = this.createField(data, 'VideoCode');
        this.maxWatchAmount = this.createField(data, 'MaxWatchAmount');
        this.playerMode = this.createField(data, 'PlayerMode');
        this.presentationType = this.createField(data, 'PresentationType');
        this.additionalContentVisibilityType = this.createField(data, 'AdditionalContentVisibilityType');
        this.thumbnailUrl = this.createField(data, 'ThumbnailUrl');
        this.beforePlayedInstructions = this.createField(data, 'BeforePlayedInstructions').mapNotNull(str => tagsParser.processTags(str));
        this.afterPlayedInstructions = this.createField(data, 'AfterPlayedInstructions').mapNotNull(str => tagsParser.processTags(str));
        this.videoFinishedInstructions = this.createField(data, 'VideoFinishedInstructions').mapNotNull(str => tagsParser.processTags(str));
        this.allowSkippingOnReview = this.createField(data, 'AllowSkippingOnReview', false as boolean);
        this.allowFullScreen = this.createField(data, 'AllowFullScreen', false as boolean);
    }
}
define('mixins/withTopicMessages', [
    'managers/context',
    'mixins/withEffects'
],
    function ({ ManagersContext }, { withEffects }) {
        return function (taskId, topicId, view) {
            var mEffects = withEffects(),
                managers = mEffects.register(new ManagersContext());

            var cManager = managers.topicMessages(taskId, topicId, view),
                cLoading = cManager.pluck('loading', true),
                cUpdating = cManager.pluck('updating', false),
                cMessages = cManager.pluck('messages', []);

            var ready = ko.pureComputed(function () {
                return !cLoading();
            });

            return {
                all: cMessages,

                updating: cUpdating,
                ready: ready,

                dispose: mEffects.dispose
            };
        }
    });
import { Observable } from 'knockout';
import SurveyStatisticsQuestionAnswer, { JSONSurveyStatisticsQuestionAnswer } from './answer';

export interface JSONSurveyStatisticsChoiceQuestionAnswer extends JSONSurveyStatisticsQuestionAnswer {
    Value: number
}

export default class SurveyStatisticsChoiceQuestionAnswer extends SurveyStatisticsQuestionAnswer<JSONSurveyStatisticsChoiceQuestionAnswer> {
    value: Observable<number>

    constructor(data: JSONSurveyStatisticsChoiceQuestionAnswer) {
        super(data);

        this.value = this.createField(data, 'Value');
    }
}
import { AutoCompletePopupVMConfig } from "components/autocompletePopup/autocompletePopup.interfaces";
import { IWordsMapFactory } from "factories/wordsMapFactory.interfaces";
import { DOMHelpers } from "helpers/dom";
import { TextHelpers } from "helpers/text";
import { Disposable } from "interfaces/disposable";
import { Subscribable } from "knockout";
import NodeRenderTracker from "managers/nodes/renderTracker";
import { withEffect } from "mixins/withEffect";
import { withEffects } from "mixins/withEffects";
import { IAutoCompleteManagerBinding, IAutoCompleteManagerRequest } from "./autocomplete.interfaces";
import AutoCompleteManagerState from "./autocomplete.state";

components.preload('autocomplete-popup');

const globalEffects = withEffects();

const popupConfigs = ko.observableArray<AutoCompletePopupVMConfig>();
const popupConfig = popupConfigs.last();

globalEffects.register(popupConfig => {
    if (popupConfig != undefined) {
        const popupNode = DOMHelpers.createComponent('autocomplete-popup', { model: popupConfig });
        document.body.appendChild(popupNode);

        return () => ko.removeNode(popupNode);
    }
}, [popupConfig]);

export default class AutoCompleteManagerContext implements Disposable {
    private effects = withEffects();

    private state = ko.observable<AutoCompleteManagerState>().extend({ notifyIfChanged: true });
    private trackedNodes = ko.observableArray<Node>();

    private bindings: Subscribable<IAutoCompleteManagerBinding[]>
    private disposePopup?: Action

    constructor(
        request: IAutoCompleteManagerRequest,
        private wordsMapFactory: IWordsMapFactory
    ) {
        const container = ko.flattenComputed(request.container);
        const target = ko.flattenComputed(request.target);
        this.bindings = ko.flattenComputed(request.bindings, []);

        this.effects.register(container => {
            if (container != undefined) {
                return [
                    DOMHelpers.onNodeEvent(document, 'selectionchange keyup', () => this.update()),

                    withEffect(target => {
                        const effects = withEffects();

                        if (target != undefined)
                            effects.register(() => new NodeRenderTracker(container, target, node => this.trackedNodes.push(node)));
                        else
                            this.trackedNodes.push(container);

                        effects.register({ dispose: () => this.trackedNodes([]) });

                        return effects;
                    }, [target])
                ]
            }
        }, [container]);

        this.effects.register(state => {
            this.disposePopup?.();

            if (state != undefined) {
                const popupConfig: AutoCompletePopupVMConfig = {
                    top: state.top,
                    left: state.left,
                    items: state.binding.pluck(binding => state.query.pluck(query => binding.getResults(query)), []),
                    onSelect: item => state.onSelect()?.(item)
                }

                popupConfigs.push(popupConfig);
                this.disposePopup = _.once(() => popupConfigs.remove(popupConfig));
            }
        }, [this.state]);
    }

    dispose() {
        this.effects.dispose();
    }

    private update() {
        let state: AutoCompleteManagerState | undefined;

        const selection = document.getSelection();

        if (selection != undefined && selection.isCollapsed) {
            const anchor = selection.anchorNode;

            if (anchor != undefined && DOMHelpers.isText(anchor) && this.trackedNodes().some(node => node == anchor || node.contains(anchor))) {
                const position = selection.anchorOffset - 1;
                const text = anchor.textContent ?? '';
                const words = this.wordsMapFactory.buildMap(text);
                const word = words.find(word => position >= word.start && position <= word.end);

                if (word != undefined) {
                    const pattern = word.text.toLowerCase();
                    const binding = this.bindings().find(binding => pattern.startsWith(binding.prefix));

                    if (binding != undefined) {
                        let query = word.text.substring(binding.prefix.length);

                        if (query.endsWith(binding.postfix))
                            query = query.substr(0, query.length - binding.postfix.length);

                        query = query.trim();                        

                        const range = selection.getRangeAt(0);
                        const rangeRect = range.getBoundingClientRect();
                        const bodyRect = document.body.getBoundingClientRect();

                        state = this.state();

                        if (state == undefined)
                            state = new AutoCompleteManagerState();

                        state.top(rangeRect.top - bodyRect.top + rangeRect.height);
                        state.left(rangeRect.left - bodyRect.left);
                        state.binding(binding);
                        state.query(query);                        

                        state.onSelect(item => {
                            const left = text.substring(0, word.start);
                            const content = item.value + TextHelpers.decodeText('&nbsp;');
                            const right = text.substring(word.end + 1);

                            anchor.textContent = left + content + right;

                            range.setStart(anchor, left.length + content.length);
                            range.setEnd(anchor, range.startOffset);

                            this.update();
                        });                        
                    }
                }
            }
        }

        this.state(state);
    }
}
import { storageDefaultsMetadataKey } from 'decorators/storageDefaults';
import { storageIgnoreMetadataKey } from 'decorators/storageIgnore';
import { storageIgnoreArgMetadataKey } from 'decorators/storageIgnoreArg';
import { storageOptionsMetadataKey } from 'decorators/storageOptions';
import { wccModules } from 'enums/wccModules';
import { Constructor } from 'interfaces/constructor';
import { Disposable } from 'interfaces/disposable';
import { isInjectable } from 'interfaces/injectable';
import { PromiseOrValue } from 'interfaces/promiseOrValue';
import { StringMap } from 'interfaces/stringMap';
import { PureComputed } from 'knockout';
import { withEffects } from 'mixins/withEffects';
import { StorageContext } from 'models/storage/context';
import { createExtendedContainer } from '../configuration/inversify/container/extended';
import { storageOptionalMetadataKey } from '../decorators/storageOptional';
import { GetArgs, StorageGetConfig, IWCCStorageManager } from './iStorage';

const managerPrefix = 'context-manager-';

interface IDMapping {
    id: string
    module: any
}

const idMappings = [] as Array<IDMapping>;
const defaultDisposeDelay = settings.cacheDisposeDelay;

const none = ko.pureComputed(() => undefined);

export interface WCCStorageGetOptions {
    disposeDelay?: number
}

export class WCCStorageManager implements IWCCStorageManager {
    private effects = withEffects()
    private storage: StorageContext

    constructor() {
        this.storage = this.effects.register(new StorageContext());        
    }

    get<TResult>(moduleOrPromise: PromiseOrValue<Constructor<[], TResult>>): PureComputed<TResult | undefined>
    get<T extends StringMap<any>, TResult>(moduleOrPromise: PromiseOrValue<Constructor<[T, ...any], TResult>>, config: StorageGetConfig<Partial<T>>, lazy?: boolean): PureComputed<TResult | undefined>
    get<T extends Array<any>, TResult>(moduleOrPromise: PromiseOrValue<Constructor<T, TResult>>, args: GetArgs<[...T]>): PureComputed<TResult | undefined>

    get(moduleOrPromise: PromiseOrValue<Constructor<any[], any>>, argsOrConfig: StorageGetConfig<any> | Array<any> = [], lazy = true) {
        let instance: PureComputed<any>;

        if (_.isArray(argsOrConfig)) {
            //Create computed with unwrapped arguments so we can reevaluate instance when arguments are changed
            const args = ko.pureComputed(() => argsOrConfig.map(arg => ko.unwrap(arg))); 

            instance = ko.fromPromise(async (args: any[]) => this.loadUsingArgsAsync(moduleOrPromise, args), [args]).unwrap();
        } else {
            //Created computed with unwrapped config so we can reevaluate instance when config fields are changed
            const config = ko.pureComputed(() => _(argsOrConfig).mapObject(arg => ko.unwrap(arg)));

            instance = ko.fromPromise(async (config: any) => this.loadUsingConfigAsync(moduleOrPromise, config), [config]).unwrap();
        }

        const effects = this.effects.register(withEffects());

        const activate = () => {
            effects.register(instance => {
                if (instance != undefined)
                    return () => this.release(instance);
            }, [instance]);
        }

        if (lazy) {
            const s = instance.subscribe(() => {
                activate();
                s.dispose();
            }, this, 'awake');
        } else {
            activate();
        }

        const proxy = new Proxy(instance, {
            get(target: any, propKey, receiver) {
                const origMethod = target[propKey];

                if (propKey == 'dispose') {
                    return function (this: any, ...args: any[]) {
                        effects.dispose();

                        if (origMethod != undefined)
                            return origMethod.apply(this, args);
                    }
                }

                return origMethod;
            }
        });

        return proxy;
    }

    getInstance<T extends StringMap<any>, TResult>(module: Constructor<[T, ...any], TResult>, config: Partial<T>): TResult | undefined {
        const instance = this.loadUsingConfig(module, config);

        if (instance != undefined) {
            const release = _.once(() => {
                this.storage.remove(instance);
            });

            const proxy = new Proxy(instance, {
                get(target: any, propKey, receiver) {
                    const origMethod = target[propKey];

                    switch (propKey) {
                        case 'dispose': return _.noop;
                        case '_storageRelease': return release;
                    }

                    return origMethod;
                }
            });

            return proxy;
        }

        return instance;
    }

    installTask<T extends StringMap<any>, TResult>(module: Constructor<[T, ...any], TResult>, config: Partial<T>): Disposable {
        const task = this.loadUsingConfig(module, config);

        return {
            dispose: _.once(() => this.release(task))
        }
    }

    release<T>(instance: T) {
        if (instance != undefined) {
            if ((<any>instance)['_storageRelease'] != undefined)
                (<any>instance)._storageRelease();
            else
                this.storage.remove(instance);
        }
    }

    dispose() {
        this.effects.dispose();
    }

    private async loadUsingArgsAsync(moduleOrPromise: PromiseOrValue<new (...args: Array<any>) => any>, args: Array<any>): Promise<PureComputed<any> | undefined> {
        const ctor = await this.getModule(moduleOrPromise);

        const options: WCCStorageGetOptions = Reflect.getOwnMetadata(storageOptionsMetadataKey, ctor) ?? {};
        const disposeDelay = options.disposeDelay ?? defaultDisposeDelay;

        const nonKeyArgsIndexes: Array<number> = Reflect.getOwnMetadata(storageIgnoreArgMetadataKey, ctor) ?? [];
        const keyArgs = args.filter((arg, idx) => !nonKeyArgsIndexes.includes(idx));

        const isInvalid = _(args).any(arg => arg == undefined);

        if (isInvalid)
            return none;

        const throwError = _(args).any(arg => !this.isValidArg(arg));

        if (throwError)
            throw new Error('storage: can\'t create module');

        if (!this.storage.disposed) {
            const id = this.getId(ctor);
            const key = keyArgs.reduce((result, arg) => `${result}/${arg}`, id) as string;

            if (!this.storage.contains(key)) {
                return this.storage
                    .create(key, true, disposeDelay, { safe: true })
                    .from(this.create(ctor, args));
            }

            return this.storage.get(key);
        }
    }

    private async loadUsingConfigAsync(moduleOrPromise: PromiseOrValue<Constructor<any[], any>>, config: StorageGetConfig<any>): Promise<PureComputed<any> | undefined> {
        const ctor = await this.getModule(moduleOrPromise);

        return this.loadUsingConfigInternal(ctor, config);
    }

    private loadUsingConfig(ctor: Constructor<any[], any>, config: any): any | undefined {
        return this.loadUsingConfigInternal(ctor, config)?.peek();
    }

    private loadUsingConfigInternal(ctor: Constructor<any[], any>, config: any) {
        const options: WCCStorageGetOptions = Reflect.getOwnMetadata(storageOptionsMetadataKey, ctor) ?? {};
        const disposeDelay = options.disposeDelay ?? defaultDisposeDelay;

        const defaults: StringMap<any> = Reflect.getOwnMetadata(storageDefaultsMetadataKey, ctor);
        const nonKeyArgs: Array<string> = Reflect.getOwnMetadata(storageIgnoreMetadataKey, ctor) ?? [];
        const optionalKeys: Array<string> = Reflect.getMetadata(storageOptionalMetadataKey, ctor) ?? [];

        const optionals = optionalKeys.reduce((result, key) => (result[key] = undefined, result), <StringMap<any>>{});

        if (defaults != undefined || optionalKeys.length > 0) {
            config = {
                ...config,
                ...optionals,
                ...defaults,                
                ..._(config).pick(v => v != null)
            }
        }

        const keyArgs = _.chain(config)
            .map((arg, name) => [name, arg])
            .filter(([name]) => !nonKeyArgs.includes(<string>name))
            .sortBy(([name]) => name)
            .map(([name, arg]) => arg)
            .value();

        const keyArgsValues = keyArgs.map(arg => _.isArray(arg) && _(arg).all(i => this.isValidArg(i)) ? JSON.stringify(arg) : arg);
        const isInvalid = _(config).any((arg, key) => !optionalKeys.includes(key) && arg == undefined);

        if (isInvalid)
            return undefined;

        const throwError = _(keyArgsValues).any(arg => !this.isValidArg(arg));

        if (throwError)
            throw new Error('storage: can\'t create module');

        if (!this.storage.disposed) {
            const id = this.getId(ctor);
            const key = keyArgsValues.reduce((result, arg) => `${result}/${arg}`, id) as string;

            if (!this.storage.contains(key)) {
                try {
                    const [instance, disposeContainer] = this.createInjectable(ctor, config);

                    return this.storage
                        .create(key, true, disposeDelay, { safe: true })
                        .withEffects([{ dispose: () => disposeContainer() }])
                        .from(instance)
                } catch (ex) {
                    console.error(ex);
                    throw ex;
                }
            }

            return this.storage.get(key);
        }

        return none;
    }

    private isValidArg(arg: any) {
        return _.isString(arg) || _.isNumber(arg) || _.isBoolean(arg) || arg == undefined;
    }

    private create(ctor: new (...args: Array<any>) => object, args: Array<any>) {
        return new ctor(...args);
    }

    private createInjectable(ctor: new (...args: Array<any>) => object, config: any): [any, Action] {
        if(!isInjectable(ctor))
            throw new Error('storage: module is not injectable');

        type Model = typeof config;

        const [container, disposeContainer] = createExtendedContainer(module => module instanceof ctor);

        container.bind<Model>(wccModules.managerConfig).toConstantValue(config);
        container.bind(ctor).toSelf();

        const module = container.get(ctor);

        return [module, disposeContainer];
    }

    private async getModule(moduleOrPromise: PromiseOrValue<new (...args: Array<any>) => any>) {
        const promise = Promise.resolve(moduleOrPromise);
        return system.getDefaultIfES6(await promise);
    }

    private getId(module: any) {
        const name = _.isString(module.name) ? <string>module.name.toLowerCase() : undefined;

        let mapping = idMappings.find(item => item.module === module);

        if (mapping == undefined) {
            mapping = {
                id: _.uniqueId(name != undefined ? `${name}-` : managerPrefix),
                module: module
            }

            idMappings.push(mapping);
        }

        return mapping.id;
    }
}
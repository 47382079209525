import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { PureComputed } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import { EffectsContainer } from 'mixins/withEffects';
import { JSONTopic, Topic } from 'models/topic';
import { TopicsService } from 'services/topics';
import events from './events';
import { IWCCStorageManager } from './iStorage';
import SignalREventsManager from './signalR/events';
import SimpleTopicManager from './simpleTopic';

interface TopicManagerConfig {
    topicId: string
}

@injectable()
export class TopicManager {
    private topicId: string
    private source: EntityDataSource<JSONTopic> 

    topic: PureComputed<Topic | undefined>

    busy = ko.pureComputed(() => this.source.busy());
    loading = ko.pureComputed(() => this.source.busy() && this.source.requestsProcessed() === 0);
    updating = ko.pureComputed(() => this.source.busy() && this.source.requestsProcessed() > 0);

    constructor(
        @inject(wccModules.managerConfig) { topicId }: TopicManagerConfig,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.effects) effects: EffectsContainer,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.topicsService) private topicsService: TopicsService
    ) {
        this.topicId = topicId;

        this.source = effects.register(new EntityDataSource({
            update: this.load.bind(this)
        }));
        
        this.topic = this.source.data.toEntity(
            jsonTopic => new Topic(jsonTopic),
            (topic, jsonTopic) => topic.update(jsonTopic));

        const isIdeation = this.topic.pluck(t => t.isIdeation, false);

        const simpleTopicManager = storage.get(SimpleTopicManager, { topicId });
        const simpleTopic = simpleTopicManager.pluck('topic');           

        topicsService.subscriptions.discussionTopicNotifications.subscribe(this.topicId);
        topicsService.events.topicChanged.on(this.onTopicChanged.bind(this));

        effects.register([
            events.topicIsAvailable.on(this.onLocalTopicIsAvailable.bind(this))
        ])

        effects.register(ko.computed(() => {
            const topic = this.topic();
            const currentSimpleTopic = simpleTopic();

            if (topic != undefined && currentSimpleTopic != undefined)
                topic.isAnswered(currentSimpleTopic.isAnswered());
        }));

        effects.register(isIdeation => {
            if (isIdeation)
                return [
                    signalREvents.ideation(topicId).onStageChanged(this.onIdeationStageChanged.bind(this)),
                    events.ideationStageChanged.on(this.onLocalIdeationStageChanged.bind(this))
                ];
        }, [isIdeation]);
    }

    update() {
        this.source.update();
    }

    private load() {
        return settings.isAdmin ?
            this.topicsService.adminQueries.topic(this.topicId).firstOrDefault() :
            this.topicsService.queries.topic(this.topicId).firstOrDefault()
    }

    private onTopicChanged(topicId: string) {
        if (this.topicId === topicId)
            this.update();
    }

    private onLocalTopicIsAvailable(topicId: string) {
        if (this.topicId === topicId)
            this.update();
    }

    private onIdeationStageChanged(topicId: string, stage: number, isStaging: boolean) {
        if (this.topicId == topicId) {
            const topic = this.topic();

            if (topic != undefined) {
                if (settings.isStaging == isStaging)
                    topic.ideationStage(stage);
            } else {
                this.update();
            }
        }
    }

    private onLocalIdeationStageChanged(topicId: string, stage: number) {
        this.onIdeationStageChanged(topicId, stage, settings.isStaging)
    }
}
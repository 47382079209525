import endpoint from 'endpoints/api';
import { apiEmailSendRequest } from 'models/apiEmailSendRequest';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class EmailsService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        template: () => this.repository.query(`emailbatches/GetEmailTemplateJson`),
        sendEmail: () => this.repository.query<never, never, apiEmailSendRequest, void, never, never, never>(`me/emails/send`)
    }

    subscriptions = {
        taskEmailBatch: this.signalRSubscription('TaskEmailBatch'),
        batchEmail: this.signalRSubscription('BatchEmail')
    }

    events = {
        emailBatchNotification: this.signalREvent('emailbatchnotification'),
        emailStatusNotification: this.signalREvent('emailstatusnotification'),
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getEmailTemplateByType(emailType: number, language: string, discussionId: string) {
        return this.queries.template().addArgs({ type: emailType, language: language, discussionId: discussionId }).firstOrDefault();
    }
}
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { apiCommunityPagesRequestData } from 'models/apiCommunityPagesRequestData';
import { CommunitiesService } from 'services/communities';
import CommunityPagesManager, { CommunityPagesManagerConfig } from './base';

export interface PublicCommunityPagesManagerConfig extends CommunityPagesManagerConfig {
    includeContent: boolean
    includeHistory: boolean
}

@injectable()
export default class PublicCommunityPagesManager extends CommunityPagesManager {
    private includeContent: boolean
    private includeHistory: boolean

    constructor(
        @inject(wccModules.managerConfig) config: PublicCommunityPagesManagerConfig,
        @inject(wccModules.effects) effects: EffectsContainer,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.communitiesService) communitiesService: CommunitiesService
    ) {
        super(config, effects, signalREvents, communitiesService);

        this.includeContent = config.includeContent;
        this.includeHistory = config.includeHistory;
    }

    override update() { }

    protected loadPages() {
        const data = <apiCommunityPagesRequestData>{
            includeContent: this.includeContent,
            includeHistory: this.includeHistory,
            domainId: settings.domainId
        }

        return this.communitiesService
            .queries
            .pages()
            .toArray(data);
    }

    protected updatePage() { }

    protected override onPagePublished(pageId: string) {
        super.onPagePublished(pageId);

        this.update();
    }

    protected override onPageDeleted() { };
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import EntityDataSourceOwner from 'managers/entityDataSourceOwner';
import events from 'managers/events';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { JSONActiveTopicInfo } from 'models/activeTopicInfo';
import type { ServicesContext } from 'services/context';
import SimpleTopicsManager from './simpleTopics';

const { discussionId } = settings;

export interface DiscussionActiveTopicManagerConfig {
    taskId: string
    groupId?: string
}

@injectable()
@storageDefaults(<Partial<DiscussionActiveTopicManagerConfig>>{ taskId: discussionId })
export default class DiscussionActiveTopicManager extends EntityDataSourceOwner<JSONActiveTopicInfo> {
    protected source: EntityDataSource<JSONActiveTopicInfo>

    private isSubscriptionActivated = ko.observable(false);

    private taskId: string
    private isLoadingTopics: Subscribable<boolean>    

    topicId: Subscribable<string | undefined>
    changedBy: Subscribable<string | undefined>

    constructor(
        @inject(wccModules.managerConfig) { taskId, groupId }: DiscussionActiveTopicManagerConfig,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.servicesContext) private ctx: ServicesContext,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.taskId = taskId;

        const topicsManager = storage.get(SimpleTopicsManager, { discussionId: taskId });
        this.isLoadingTopics = topicsManager.pluck(m => m.loading, true);
        const topics = topicsManager.pluck(m => {
            return groupId ?
                m.groups().find(g => g.id() == groupId)?.topics() ?? [] :
                m.topics;
        }, []);
        const firstTopic = topics.first();
        const defaultData = firstTopic.mapNotNull(t => (<JSONActiveTopicInfo>{ TopicId: t.id() }));

        this.source = effects.register(new EntityDataSource({
            update: this.updateData.bind(this),
            isEmpty: true
        }));

        const data = ko.pureComputed(() => {
            let result = this.source.data();            

            if (result == undefined && !this.loading())
                return defaultData();

            return result;
        });

        this.topicId = data.pluck(d => d.TopicId);
        this.changedBy = data.pluck(d => d.ChangedBy);

        effects.register([
            events.liveChatActiveTopicInfoChanged.on(this.onLocalActiveTopicInfoChange.bind(this))
        ]);

        signalREvents.liveGroup(this.taskId)
            .onActiveTopicChange(this.onActiveTopicInfoChange.bind(this))
            .onSubscriptionActivated(() => {
                this.update();
                this.isSubscriptionActivated(true);
            });
    }

    update() {
        this.source.update();
    }

    protected override isLoadingData() {
        return super.isLoadingData() || this.source.data() == undefined && this.isLoadingTopics() || !this.isSubscriptionActivated();
    }

    protected override isUpdatingData() {
        return super.isUpdatingData() && this.isSubscriptionActivated();
    }

    private updateData() {
        return this.ctx
            .liveGroupService
            .queries
            .activeTopicInfo(this.taskId)
            .firstOrDefault();
    }

    private onActiveTopicInfoChange(taskId: string, isStaging: boolean) {
        if (taskId === this.taskId && isStaging == settings.isStaging)
            this.source.update();
    }

    private onLocalActiveTopicInfoChange(taskId: string, data: any) {
        if (taskId === this.taskId)
            this.source.set(data);
    }
}
import { isNumber } from "underscore";

//
//  Cookies now require more detailed documentation. Part of that is allowing users to opt out of un-essential cookies
//
export enum CookieClassification {
    // These cookies are strictly necessary to provide you with services available through our Website 
    // and to use some of its features, such as access to secure areas.
    Essential = 1,
    // These cookies are used to enhance the performance and functionality of our Website but are 
    // non-essential to their use.However, without these cookies, certain functionality(like videos) may become unavailable.
    PerformanceAndFunctionality = 2,
    // These cookies collect information that is used either in aggregate form to help us understand 
    // how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.
    AnalyticsAndCustomisation = 4    
}

export module CookieHelpers {

    export const QUALZY_COOKIE_CONSENT = 'cookieconsent2';
    export const QUALZY_COOKIE_2FA = 'wcc_2fatoken';
    export const QUALZY_COOKIE_CPREDEMPTION = 'qzy_oth_redemptionToken';   

    export function getCookie(cookieName: string) {
        var i, name, value, cookies = document.cookie.split(";");

        for (i = 0; i < cookies.length; i++) {
            name = cookies[i].substr(0, cookies[i].indexOf("="));
            value = cookies[i].substr(cookies[i].indexOf("=") + 1);
            name = name.replace(/^\s+|\s+$/g, "");

            if (name == cookieName) {
                return unescape(value);
            }
        }
    }

    /*
    *   Set a cookie with the given name and value. If expireDays is not set then the cookie will expire at the end of the session.
    *   Defaults to PerformanceAndFunctionality classification which is most likely to be accepted by the user as its not tracking
    */
    export function setCookie(cookieName: string, value: any, expireDays:number|null = null, classification: CookieClassification = CookieClassification.PerformanceAndFunctionality, secure = false) {
        // check if we have accepted this classification
        if (UserHasConsented(cookieName, classification)) {        
            var expireDate = new Date();
            if (expireDays != null && isNumber(expireDays)) {
                expireDate.setDate(expireDate.getDate() + expireDays);
            }

            var cookieValue = escape(value) + ((expireDays == null) ? "" : "; expires=" + expireDate.toUTCString()) + (secure ? ';Secure' : '') + ';path=/';
            document.cookie = cookieName + "=" + cookieValue;
        }
    }

    function UserHasConsented(cookieName: string, classification: CookieClassification) {
        // by-pass consent if version 2 is not available
        if (settings?.isCookieConsentVersion2 || (window as any).__wccconfig.settings.CookieConsentVersion2 == false) return true;

        var cookieConsentString = getCookie(QUALZY_COOKIE_CONSENT);
        var cookieConsent = cookieConsentString ? parseInt(cookieConsentString) : undefined;
        // we by-pass for cookieconsent and 2fa and check if we have given consent for this classification
        return (cookieName == QUALZY_COOKIE_CONSENT || cookieName == QUALZY_COOKIE_2FA) || (cookieConsent != undefined && (cookieConsent & classification) != 0);
    }

    export function deleteCookie(cookieName: string) {
        setCookie(cookieName, null, -1);
    }
}
import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyTimeZoneOptions {
    CountryPlaceholder: string
    RegionPlaceholder: string
}

export default class SurveyTimeZoneOptions extends WCCEntity<JSONSurveyTimeZoneOptions> {
    countryPlaceholder: Observable<string>;
    regionPlaceholder: Observable<string>;
    
    constructor(data: JSONSurveyTimeZoneOptions) {
        super();

        this.countryPlaceholder = this.createField(data, 'CountryPlaceholder');
        this.regionPlaceholder = this.createField(data,"RegionPlaceholder");
    }
}
import { PureComputed } from 'knockout';

const cNow = settings.now;

export default class LCRedirect {
    private executeOn = ko.observable<Date>();
    private timeout?: NodeJS.Timeout

    isDone = ko.observable(false);
    isCanceled = ko.observable(false);
    isFinished = ko.observable(false);

    timeLeft: PureComputed<number>    

    constructor(private ttl: number, private action: Action) {
        this.timeLeft = ko.pureComputed(() => {
            const executeOn = this.executeOn();

            if (executeOn != undefined && executeOn > cNow()) {
                const result = (executeOn.getTime() - cNow().getTime()) / 1000;

                if (result > ttl)
                    return ttl;

                return Math.ceil(result);
            }

            return 0;
        });   

        this.activate();
    }

    cancel() {
        if (!this.isFinished()) {
            this.finish();

            this.isCanceled(true);
        }
    }

    execute() {
        if (!this.isFinished()) {
            this.finish();
            this.action();

            this.isDone(true);
        }
    }

    private activate() {
        const delay = this.ttl * 1000;

        this.executeOn(moment().add(this.ttl, 'seconds').toDate());
        this.timeout = setTimeout(() => this.execute(), delay);
    }

    private finish() {
        if (!this.isFinished()) {
            this.isFinished(true);

            if (this.timeout != undefined) {
                clearInterval(this.timeout);
                this.timeout = undefined;
            }

            this.executeOn(undefined);
        }
    }
}
define('managers/discussion/highlights', [
    'managers/base_es5',
    'managers/datasources/collection',
    'models/highlight'
], function (Base, { CollectionDataSource: DataSource }, Highlight) {
    const { default: events } = require('managers/events');

    function Manager(discussionId) {
        Base.call(this);

        this.discussionId = ko.pureComputed(function () { return discussionId });

        this._source = this._register(new DataSource({
            load: this._load.bind(this),
            mapper: function (jsonHighlight) { return new Highlight(jsonHighlight) }
        }));

        this.highlights = this._source.list;

        this.busy = this._source.busy;
        this.loading = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() === 0 }, this);
        this.updating = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() > 0 }, this);
    }

    Manager.prototype = Object.create(Base.prototype);
    Manager.prototype.constructor = Manager;

    Manager.prototype.init = function () {
        Base.prototype.init.call(this);

        this._disposables.register([
            events.highlightAdded.on(this._onLocalHighlightAdded.bind(this))
        ]);
    };

    Manager.prototype._load = function () {
        return this._ctx
            .highlightsService
            .getDiscussionHighlights(this.discussionId());
    };

    Manager.prototype._onLocalHighlightAdded = function (topicId, jsonHighlight) {
        this._source.add(jsonHighlight);
    };

    return Manager;
});
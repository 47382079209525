import { DateTime, FixedOffsetZone } from "luxon";

export default class DateDecoder {
    constructor() { }

    static decode(pattern: string, year: number) {
        const decoder = new DateDecoder();
        return decoder.decode(pattern, year);
    }

    decode(pattern: string, year: number) {
        var result = new Date(0);

        if (pattern.trim().length != 0) {
            const hour = parseInt(pattern.substr(0, 2));
            const minute = parseInt(pattern.substr(2, 2));

            if (pattern.indexOf("/") != -1) {
                const day = parseInt(pattern.substr(5, 2));
                const month = parseInt(pattern.substr(8, 2));

                result = new Date(Date.UTC(year, month, day, hour, minute));
            }
            else {
                const type = pattern.substr(5, 3);
                const dayStr = pattern.substr(9, 3);
                const monthStr = pattern.substr(13, 3);

                const day = this.decodeDayOfWeek(dayStr);
                const month = this.decodeMonth(monthStr);

                result = this.getDate(year, month, day, hour, minute, type);
            }
        }

        return result;
    }

    private getDate(year: number, month: number, day: number, hour: number, minute: number, type: string) {
        switch (type) {
            case "1ST": return this.getFirstDay(year, month, day, hour, minute);
            case "LST": return this.getLastDay(year, month, day, hour, minute);
            case "2ND": return DateTime.fromJSDate(this.getFirstDay(year, month, day, hour, minute), { zone: FixedOffsetZone.utcInstance }).plus({ weeks: 1 }).toJSDate();
            case "3RD": return DateTime.fromJSDate(this.getFirstDay(year, month, day, hour, minute), { zone: FixedOffsetZone.utcInstance }).plus({ weeks: 2 }).toJSDate();
            case "4TH": return DateTime.fromJSDate(this.getFirstDay(year, month, day, hour, minute), { zone: FixedOffsetZone.utcInstance }).plus({ weeks: 3 }).toJSDate();
            default: return new Date(0);
        }
    }

    private getFirstDay(year: number, month: number, day: number, hour: number, minute: number) {
        let date = DateTime.fromMillis(Date.UTC(year, month, 1, hour, minute), { zone: FixedOffsetZone.utcInstance });

        while (date.weekday % 7 != day)
            date = date.plus({ days: 1 });

        return date.toJSDate();
    }

    private getLastDay(year: number, month: number, day: number, hour: number, minute: number) {
        let date = DateTime.fromMillis(Date.UTC(year, month, 1, hour, minute), { zone: FixedOffsetZone.utcInstance }).endOf('month').startOf('day');

        while (date.weekday % 7 != day)
            date = date.plus({ days: -1 });

        return date.toJSDate();
    }

    private decodeDayOfWeek(value: string): number {
        switch (value) {
            case "SUN": return 0;
            case "MON": return 1;
            case "TUE": return 2;
            case "WED": return 3;
            case "THU": return 4;
            case "FRI": return 5;
            case "SAT": return 6;
            default: return 0;
        }
    }

    private decodeMonth(value: string): number {
        switch (value) {
            case "JAN": return 0;
            case "FEB": return 1;
            case "MAR": return 2;
            case "APR": return 3;
            case "MAY": return 4;
            case "JUN": return 5;
            case "JUL": return 6;
            case "AUG": return 7;
            case "SEP": return 8;
            case "OCT": return 9;
            case "NOV": return 10;
            case "DEC": return 11;
            default: return 0;
        }
    }
}
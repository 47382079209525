export module StringHelpers {
    export function isNullOrEmpty(str: string | undefined | null) {
        return str == null || str == '';
    }

    export function isNotNullOrEmpty(str: string | undefined | null) {
        return str != null && str != '';
    }

    export function isNullOrWhitespace(str: string | undefined | null) {
        return str == null || str == '' || str.trim() == '';
    }

    export function toFirstCharUpperString(val = ' ') {
        return val[0].toUpperCase() + val.substring(1);
    }

    export function toFirstCharLowerString(val: string) {
        return val[0].toLowerCase() + val.substring(1);
    }

    export function replaceAll(str: string, target: string, replacement: string, maxAttempts = 1000) {
        if (target == replacement)
            return str;

        let currentAttempt = 0;

        do {
            str = str.replace(target, replacement);
            currentAttempt++;
        } while (str.indexOf(target) != -1 && currentAttempt < maxAttempts);

        return str;
    }

    export function trimLeft(str: string, character?: string) {
        if (isNullOrEmpty(str))
            return str;

        if (character == undefined || isNullOrEmpty(character))
            character = " "; //default to space

        if (!str.startsWith(character))
            return str;

        var i = 0;

        while (str[i] == character) {
            i++;
        }

        if (i < str.length) {
            return str.substr(i);
        }
        else {
            return str;
        }
    }

    export function cleanString(str: string, characters?: Array<{ Old: string, New: string}>) { //Characters parameter has to have a schema { Old, New } for us to tell what old character needs to be replaced by what new character
        if (characters == undefined)
            return str;

        var result = str;

        $.each(characters, (i, char) => {
            result = result.split(char.Old).join(char.New); //Let's not use RegEx here as it makes the code really noisy
        });

        return result;
    }

    export function escapeStringForRegEx(input: string) {
        return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    export function endsWith(str: string, suffix: string) {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }

    export function getInitials(...values: (string | undefined)[]) {
        return values.map(value => value?.[0]?.toLocaleUpperCase() ?? '').join('');
    }

    export function removeHtmlTags(input: string) {
        const regex: RegExp = /<.*?>/g;

        if (input == null || input.length == 0) return input;

        return input.replace(regex, '');
    }
}
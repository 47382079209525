import { Observable, ObservableArray } from "knockout"
import { CardsScoreAnswerCard, JSONCardsScoreAnswerCard } from "./cardsScoreAnswerCard"

export interface JSONCardsScoreAnswer {
    CardsScoreAnswerId?: string
    ThreadId?: string
    CardsScaleId?: string
    ContentId?: string
    QuestionAnswer?: string,

    Cards?: Array<JSONCardsScoreAnswerCard>
}

export class CardsScoreAnswer {
    id: Observable<string | undefined>
    threadId: Observable<string | undefined>
    cardsScaleId: Observable<string | undefined>
    contentId: Observable<string | undefined>
    questionAnswer: Observable<string | undefined>

    cards: ObservableArray<CardsScoreAnswerCard>

    constructor(config?: JSONCardsScoreAnswer) {
        this.id = ko.observable(config?.CardsScoreAnswerId);
        this.threadId = ko.observable(config?.ThreadId);
        this.cardsScaleId = ko.observable(config?.CardsScaleId);
        this.contentId = ko.observable(config?.ContentId);
        this.questionAnswer = ko.observable(config?.QuestionAnswer);

        this.cards = ko.observableArray(config?.Cards?.map(c => new CardsScoreAnswerCard(c)) ?? []);
    }

    toJson(): JSONCardsScoreAnswer {
        return {
            CardsScoreAnswerId: this.id(),
            ThreadId: this.threadId(),
            CardsScaleId: this.cardsScaleId(),
            ContentId: this.contentId(),
            QuestionAnswer: this.questionAnswer(),

            Cards: this.cards().map(c => c.toJson())
        }
    }
}
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

//
//  Fix Fancy Box when setting big image to add crossorigin="anonymous" to the img tag
//
var FancyBoxExtender = {

    FixImgCors: function (instance) {
        $.extend(true, instance, {
            setBigImage: function (slide) {

                var self = this,
                    img = document.createElement("img"),
                    $img = $(img);

                slide.$image = $img
                    .one("error", function () {
                        self.setError(slide);
                    })
                    .one("load", function () {
                        var sizes;

                        if (!slide.$ghost) {
                            self.resolveImageSlideSize(slide, this.naturalWidth, this.naturalHeight);

                            self.afterLoad(slide);
                        }

                        if (self.isClosing) {
                            return;
                        }

                        if (slide.opts.srcset) {
                            sizes = slide.opts.sizes;

                            if (!sizes || sizes === "auto") {
                                sizes =
                                    (slide.width / slide.height > 1 && window.width() / window.height() > 1 ? "100" : Math.round((slide.width / slide.height) * 100)) +
                                    "vw";
                            }

                            $img.attr("sizes", sizes).attr("srcset", slide.opts.srcset);
                        }

                        // Hide temporary image after some delay
                        if (slide.$ghost) {
                            setTimeout(function () {
                                if (slide.$ghost && !self.isClosing) {
                                    slide.$ghost.hide();
                                }
                            }, Math.min(300, Math.max(1000, slide.height / 1600)));
                        }

                        self.hideLoading(slide);
                    })
                    .addClass("fancybox-image")
                    .attr("src", slide.src)
                    .attr("crossorigin", "anonymous")   // CORS fix
                    .appendTo(slide.$content);

                if ((img.complete || img.readyState == "complete") && $img.naturalWidth && $img.naturalHeight) {
                    $img.trigger("load");
                } else if (img.error) {
                    $img.trigger("error");
                }
            },
        });
    }
};

export default FancyBoxExtender;
import { ComponentsContext } from 'extensions/knockout/components/context';
import { Disposable } from "interfaces/disposable";
import signalRService from 'managers/signalR/core/instance';
import { StorageContext } from 'models/storage/context';
import PaymentsService from 'services/payments';
import { InversifyHelpers } from '../configuration/inversify/container/helpers';
import { wccModules } from '../enums/wccModules';
import { ISignalRCoreManager } from '../managers/signalR/core/interfaces/main';
import { AccountService } from './account';
import { ActivitiesService } from './activities';
import { AnalysisService } from './analysis2';
import { AttachmentsService } from './attachments';
import { BlogsService } from './blogs';
import { ClientsService } from './clients';
import { CommentsService } from './comments';
import { CommunitiesService } from './communities';
import { ContactProfilerService } from './contactProfiler';
import { DiscussionService } from './discussion';
import { EmailsService } from './emails';
import { EngagementService } from './engagement';
import { EventsService } from './events';
import { HighlightsService } from './highlights';
import { ImportsService } from './imports';
import InitialEndpointDataService from './initialEndpointData';
import { LiveGroupService } from './livegroup';
import { LoginService } from './login';
import { LogsService } from './logs';
import { MailingListService } from './mailingList';
import { PeopleService } from './people';
import { PixabayService } from './pixabay';
import { PollsService } from './polls';
import { PrizesService } from './prizes';
import { RecruitmentService } from './recruitment';
import { ResourcesService } from './resources';
import { RewardsService } from './rewards';
import { SMSService } from './sms';
import { StatisticsService } from './statistics';
import { SurveysService } from './surveys';
import { TagsService } from './tags';
import { TaskMembersService } from './taskMembers';
import { TasksService } from './tasks';
import { ITimezoneService } from './timezones.interfaces';
import { TopicsService } from './topics';
import { UnsplashService } from './unsplash';
import { UserFilesService } from './userfiles';
import { VideoService } from './video';
import { VideoAnalyticsService } from './videoAnalytics';
import {ReportsAndDownloadFilesService} from "./reportsAndDownloadFiles";
import { TwoFactorAuthService } from './twofactorauth';

type SystemHelpers = typeof system;

const Disposables = require('managers/disposables');
const Timers = require('models/core/timers');

export class ServicesContext {
    readonly #disposables: any;
    readonly #children: Array<ServicesContext> = [];    

    #disposed: boolean = false;

    readonly accountService: AccountService;
    readonly analysisService: AnalysisService;
    readonly attachmentsService: AttachmentsService;
    readonly commentsService: CommentsService;
    readonly contactProfilerService: ContactProfilerService;
    readonly discussionService: DiscussionService;
    readonly emailsService: EmailsService;
    readonly peopleService: PeopleService;
    readonly tagsService: TagsService;
    readonly taskMembersService: TaskMembersService;
    readonly tasksService: TasksService;
    readonly topicsService: TopicsService;
    readonly communitiesService: CommunitiesService;
    readonly liveGroupService: LiveGroupService;
    readonly loginService: LoginService;
    readonly twoFactorAuthService: TwoFactorAuthService;
    readonly statisticsService: StatisticsService;
    readonly highlightsService: HighlightsService;
    readonly resourcesService: ResourcesService;
    readonly recruitmentService: RecruitmentService;
    readonly eventsService: EventsService;
    readonly surveysService: SurveysService;
    readonly smsService: SMSService;
    readonly activitiesService: ActivitiesService;
    readonly videoService: VideoService;
    readonly videoAnalyticsService: VideoAnalyticsService;
    readonly pixabayService: PixabayService;
    readonly unsplashService: UnsplashService;
    readonly prizesService: PrizesService;
    readonly reportsAndDownloadFilesService: ReportsAndDownloadFilesService;
    readonly rewardsService: RewardsService;
    readonly mailingListService: MailingListService;
    readonly pollsService: PollsService;
    readonly blogsService: BlogsService;
    readonly engagementService: EngagementService;
    readonly clientService: ClientsService;
    readonly userFilesService: UserFilesService;
    readonly importsService: ImportsService;
    readonly paymentsService: PaymentsService;
    readonly timezoneService: ITimezoneService;
    readonly logsService: LogsService;
    readonly initialEndpointDataService: InitialEndpointDataService;
    readonly cssService: any;
    readonly systemService: SystemHelpers;
    readonly signalRService: ISignalRCoreManager;
    readonly components: ComponentsContext;
    readonly storage: StorageContext;
    readonly timers: any;
    readonly subs: Array<Disposable> = [];    

    constructor() {
        this.#disposables = new Disposables();

        const [container, disposeContainer] = InversifyHelpers.createScope();
        this._register({ dispose: disposeContainer });

        this.accountService = this._register(new AccountService());
        this.analysisService = this._register(new AnalysisService());
        this.attachmentsService = this._register(new AttachmentsService());
        this.commentsService = this._register(new CommentsService());
        this.contactProfilerService = this._register(new ContactProfilerService());
        this.discussionService = this._register(new DiscussionService());
        this.emailsService = this._register(new EmailsService());
        this.peopleService = this._register(new PeopleService());
        this.tagsService = this._register(new TagsService());
        this.taskMembersService = this._register(new TaskMembersService());
        this.tasksService = this._register(new TasksService());
        this.topicsService = this._register(new TopicsService());
        this.communitiesService = this._register(new CommunitiesService());
        this.liveGroupService = this._register(new LiveGroupService());
        this.loginService = this._register(new LoginService());
        this.twoFactorAuthService = this._register(new TwoFactorAuthService());
        this.statisticsService = this._register(new StatisticsService());
        this.highlightsService = this._register(new HighlightsService());
        this.resourcesService = this._register(new ResourcesService());
        this.recruitmentService = this._register(new RecruitmentService());
        this.eventsService = this._register(new EventsService());
        this.surveysService = this._register(new SurveysService());
        this.smsService = this._register(new SMSService());
        this.activitiesService = this._register(new ActivitiesService());
        this.videoService = this._register(new VideoService());
        this.videoAnalyticsService = this._register(new VideoAnalyticsService());
        this.pixabayService = this._register(new PixabayService());
        this.unsplashService = this._register(new UnsplashService());
        this.prizesService = this._register(new PrizesService());
        this.reportsAndDownloadFilesService = this._register(new ReportsAndDownloadFilesService());
        this.rewardsService = this._register(new RewardsService());
        this.mailingListService = this._register(new MailingListService());
        this.pollsService = this._register(new PollsService());
        this.blogsService = this._register(new BlogsService());
        this.engagementService = this._register(new EngagementService());
        this.clientService = this._register(new ClientsService());
        this.userFilesService = this._register(new UserFilesService());
        this.importsService = this._register(new ImportsService());
        this.paymentsService = this._register(new PaymentsService());
        this.timezoneService = container.get<ITimezoneService>(wccModules.timezoneService);
        this.logsService = this._register(new LogsService());
        this.initialEndpointDataService = this._register(new InitialEndpointDataService());

        this.systemService = system;
        this.signalRService = signalRService;

        this.components = this._register(new ComponentsContext());
        this.storage = this._register(new StorageContext());
        this.timers = this._register(new Timers());

        this.#children = [];
        this.#disposed = false;
    }

    getChildContext(): ServicesContext {
        var ctx = new ServicesContext();
        this.#children.push(ctx);

        return ctx;
    }

    dispose():void {
        if (this.#disposed) return;

        this.#disposed = true;
        this.#disposables.dispose();

        _(this.subs).each(s => s.dispose());
        _(this.#children).each(s => s.dispose());
    }

    _register<T extends Disposable>(disposable: T): T {
        this.#disposables.register(disposable);

        return disposable;
    }
}

export var instance = new ServicesContext();

import { BaseService } from "./base";
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { cpCreditBalance, cpJobActivity, cpParticipationHistory, cpRedemptionHistory, cpRedemptionOffer, cpRedemptionRequest, cpRedemptionRequestResponse, cpRedemptionVerification } from "../models/contactProfiler/contactProfilerModels";

//const { sitePrefix, sitePostfix, isStaging } = settings;

export class ContactProfilerService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        getCreditBalance: () => this.repository.query(`contactprofiler/creditbalance`),
        getAvailableJobs: () => this.repository.query('contactprofiler/jobactivity'),
        getParticipationHistory: () => this.repository.query(`contactprofiler/participationhistory`),
        getReferralLink: (isDemo: boolean) => this.repository.query(`contactprofiler/referrallink?isDemo=${isDemo}`),
        getRedemptionOffers: () => this.repository.query(`contactprofiler/redemptionoffers`),
        getRedemptionHistory: () => this.repository.query(`contactprofiler/redemptionhistory`),
        verifyRedemptionRequest: () => this.repository.query(`contactprofiler/verifyredemptionrequest`),
        requestRedemption: () => this.repository.query('contactprofiler/requestRedemption'),
        getRecentParticipationHistory: () => this.repository.query(`contactprofiler/participationhistoryrecent`),
    }

    async getReferralLink(isDemo: boolean): Promise<string> {
        return await this.queries.getReferralLink(isDemo).firstOrDefault();
    }

    async getCreditBalance(): Promise<cpCreditBalance> {
        return await this.queries.getCreditBalance().firstOrDefault();
    }

    async getRedemptionOffers(): Promise<cpRedemptionOffer[]> {
        return await this.queries.getRedemptionOffers().toArray();
    }

    async verifyRedemptionRequest(verification: cpRedemptionVerification): Promise<cpRedemptionRequestResponse> {
        return await this.queries.verifyRedemptionRequest().add(verification);
    }

    async requestRedemption(requestData: cpRedemptionOffer): Promise<cpRedemptionRequestResponse> {
        return this.queries.requestRedemption().add(requestData);
    }

    async getRedemptionHistory(): Promise<cpRedemptionHistory[]> {
        return await this.queries.getRedemptionHistory().toArray();
    }

    async getParticipationHistory(): Promise<cpParticipationHistory[]> {
        return await this.queries.getParticipationHistory().toArray();
    }

    async getRecentParticipationHistory(): Promise<cpParticipationHistory[]> {
        return await this.queries.getRecentParticipationHistory().toArray();
    }

    async getAvailableJobs(): Promise<cpJobActivity> {
        return await this.queries.getAvailableJobs().firstOrDefault();
    }    
}

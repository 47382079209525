import { inject, injectable } from 'inversify';
import { wccModules } from '../../enums/wccModules';
import { JSONOnlineUserDetails } from '../../models/signalR/onlineUserDetails';
import { ISignalRCoreManager } from './core/interfaces/main';

class SignalRMessage<T extends Array<any>> {
    constructor(
        private signalRManager: ISignalRCoreManager,
        private name: string) { }

    async send(...args: T) {
        await this.signalRManager.sendMessage(this.name, ...args);            
    }

    async sendImportant(...args: T) {
        await this.signalRManager.sendImportantMessage(this.name, ...args)
    }
}

@injectable()
export default class SignalRMessagesManager {
    constructor(
        @inject(wccModules.signalRManager) private signalRManager: ISignalRCoreManager
    ) { }

    participantWatchingVideo = this.create<[topicId: string, src: string, progress: number]>('ParticipantWatchingVideo');
    topicMemberIsWorking = this.create<[topicId: string]>('TopicMemberIsWorking');
    userOnline = this.create<[details: JSONOnlineUserDetails]>('UserOnline');
    userTypingAt = this.create<[topicId: string | undefined]>('UserTypingAt');
    appointmentScreenSharingStopRequested = this.create<[appointmentId: string]>('StopAppointmentScreenSharing');

    private create<T extends Array<any>>(name: string) {
        return new SignalRMessage<T>(this.signalRManager, name);
    }
}
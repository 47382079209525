import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {PassiveCollectionGetQuery, PassivePostQuery} from "../models/queries/passive";
import {IReportAndDownloadFile, IReportAndDownloadFileCompact, IReportAndDownloadPasswordRequest} from "../pages/resources/reportsAndDownloadFiles/models/reportAndDownloadFile";

export interface IReportsAndDownloadFilesService {
    filesQuery(discussionId: string): PassiveCollectionGetQuery<IReportAndDownloadFile>
    passwordProtectFile(discussionId: string): PassivePostQuery<IReportAndDownloadPasswordRequest, IReportAndDownloadFileCompact>
}

export class ReportsAndDownloadFilesService extends BaseService implements IReportsAndDownloadFilesService {
    private repository = new Repository(endpoint);

    filesQuery = (discussionId: string) => this.repository.collectionGetQuery<IReportAndDownloadFile>(`reportsanddownloadfiles/discussions/${discussionId}`);
    passwordProtectFile = (discussionId: string) => this.repository.postQuery<IReportAndDownloadPasswordRequest, IReportAndDownloadFileCompact>(`reportsanddownloadfiles/discussions/${discussionId}/passwordprotectfile`);
    deleteFile = (discussionId: string, fileId: string) => this.repository.removeQuery<void>(`reportsanddownloadfiles/discussions/${discussionId}/file/${fileId}`);
}
import { Interval, TaggedInterval } from "../interfaces/interval";
import { CollectionsHelpers } from "./collections";
import { DateHelpers } from "./date";

export module RangesHelpers {
    export function getIntervalKey(from: Date, to: Date) {
        return `${from.getTime()} ${to.getTime()}`;
    }

    /**
     * excludes ranges group from another ranges group
     * result intervals are present in main group and not present in secondary group
     * @param ranges main group
     * @param excludedRanges secondary group
     * @param minDuration min size of valid interval
     */
    export function excludeRanges(ranges: TaggedInterval[], excludedRanges: Interval[], minDuration = 1): Array<TaggedInterval> {
        return excludedRanges.reduce((ranges, excludedRange) => {
            const newRanges = ranges.flatMap(range => {
                if (excludedRange.from <= range.from && excludedRange.to >= range.from)
                    return [{ tag: range.tag, from: excludedRange.to, to: range.to }];

                if (excludedRange.from <= range.to && excludedRange.to >= range.to)
                    return [{ tag: range.tag, from: range.from, to: excludedRange.from }];

                if (excludedRange.from > range.from && excludedRange.to < range.to) {
                    return [
                        { tag: range.tag, from: range.from, to: excludedRange.from },
                        { tag: range.tag, from: excludedRange.to, to: range.to }
                    ]
                }

                return [range];
            });

            return newRanges.filter(({ from, to }) => to.getTime() - from.getTime() >= minDuration);
        }, ranges);
    }

    /**
     * creates intersection of ranges from each group
     * result intervals are present in all groups
     * every group is preprocessed using {processRanges} func so it's safe to pass "dirty" intervals
     * @param groups 
     */
    export function intersectRangesGroups(groups: Interval[][]) {
        if (groups.length == 0)
            return [];

        const main = processRanges(groups[0]);
        const others = groups.slice(1).map(intervals => processRanges(intervals));

        if (others.length == 0)
            return main;

        const result = main.reduce((result, mainInterval) => {
            const othersIntervalsGroups = others.map(group => group.filter(interval => checkIntersection(interval, mainInterval)));

            if (othersIntervalsGroups.every(intervals => intervals.length > 0)) {
                const resultIntervals = othersIntervalsGroups.reduce((result, otherIntervalsGroup) => {
                    return result.flatMap(targetInterval => {
                        return otherIntervalsGroup.reduce((result, interval) => {
                            if (interval.from <= targetInterval.from && interval.to >= targetInterval.from)
                                result.push({ from: targetInterval.from, to: interval.to });
                            else if (interval.from <= targetInterval.to && interval.to >= targetInterval.to)
                                result.push({ from: interval.from, to: targetInterval.to });
                            else if (interval.from > targetInterval.from && interval.to < targetInterval.to)
                                result.push(interval);

                            return result;
                        }, new Array<Interval>());
                    });
                }, [mainInterval]);

                if (resultIntervals.length > 0)
                    result.push(...resultIntervals);
            }

            return result;
        }, new Array<Interval>());

        return result;
    }

    /**
     * Sorts ranges, removes invalid ones and combines intersecting ranges
     * @param ranges
     */
    export function processRanges(ranges: Array<TaggedInterval>) {
        const sortedRanges = [...ranges].sort(CollectionsHelpers.intervalsSorterAsc);
        const validRanges = sortedRanges.filter(range => range.from < range.to);

        return validRanges.reduce((result, range) => {
            const lastRange = _.last(result);

            if (lastRange != undefined && range.from <= lastRange.to) {
                result.pop();
                result.push({ tag: lastRange.tag, from: lastRange.from, to: DateHelpers.max(lastRange.to, range.to) });
            } else {
                result.push(range);
            }

            return result;
        }, new Array<TaggedInterval>());
    }

    export function checkIntersection(interval: Interval, other: Interval) {
        return interval.from < other.to && interval.to > other.from
    }
}
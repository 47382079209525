import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyPhoneOptions {
    Width?: string
    PhonePlaceholder: string,
    CountryCode?: string,
    PhoneNumberType: number
}

export default class SurveyPhoneOptions extends WCCEntity<JSONSurveyPhoneOptions> {
    width: Observable<string | undefined>
    phonePlaceholder: Observable<string>;
    countryCode: Observable<string | undefined>;
    phoneNumberType: Observable<number>;
    
    constructor(data: JSONSurveyPhoneOptions) {
        super();

        this.width = this.createField(data, 'Width');
        this.phoneNumberType  = this.createField(data,"PhoneNumberType", enums.PhoneNumberTypes.Mobile.value);
        this.phonePlaceholder = this.createField(data, 'PhonePlaceholder', this.defaultPlaceholder());
        this.countryCode = this.createField(data,"CountryCode");
    }

    defaultPlaceholder() {
        if (this.phoneNumberType() == enums.PhoneNumberTypes.Mobile.value) {
            return labels.MobileNumber;
        }
        return labels.PhoneNumber;
    }
}
import { Observable, PureComputed } from "knockout"
import {NumberHelpers} from "../helpers/number";

export interface JSONCardsScoreAnswerCardPerson {
    PersonId: string
    Name: string
}

export interface JSONCardsScoreAnswerCard {
    CardsScoreAnswerCardId?: string
    CardsScoreAnswerId?: string
    CardId?: string
    Value?: number
    RealValue?: number
    Median?: number
    MedianRealValue?: number
    Title?: string
    Person?: JSONCardsScoreAnswerCardPerson
    RealCards?: JSONCardsScoreAnswerCard[]
    Comment?: string
}

export class CardsScoreAnswerCard {
    id: Observable<string | undefined>
    answerId: Observable<string | undefined>
    cardId: Observable<string | undefined>
    title: Observable<string | undefined>
    value: Observable<number | undefined>
    realValue: Observable<number | undefined>
    realValueStr: PureComputed<string | undefined>
    median: Observable<number | undefined>
    medianRealValue: Observable<number | undefined>
    medianStr: PureComputed<string>
    person?: JSONCardsScoreAnswerCardPerson
    realCards?: Array<JSONCardsScoreAnswerCard>

    constructor(config?: JSONCardsScoreAnswerCard) {
        this.id = ko.observable(config?.CardsScoreAnswerCardId);
        this.answerId = ko.observable(config?.CardsScoreAnswerId);
        this.cardId = ko.observable(config?.CardId);
        this.title = ko.observable(config?.Title);
        this.value = ko.observable(config?.Value);
        this.realValue = ko.observable(config?.RealValue);
        this.realValueStr = ko.pureComputed(() => NumberHelpers.toFixed(this.realValue(), 1));
        this.median = ko.observable(config?.Median);
        this.medianRealValue = ko.observable(config?.MedianRealValue);
        this.medianStr = ko.pureComputed(() => this.medianRealValue() ? '(' + NumberHelpers.toFixed(this.medianRealValue(), 1) + ')' : '');

        this.person = config?.Person;
        this.realCards = config?.RealCards;
    }

    toJson(): JSONCardsScoreAnswerCard {
        return {
            CardsScoreAnswerCardId: this.id(),
            CardsScoreAnswerId: this.answerId(),
            CardId: this.cardId(),
            Value: this.value(),
            Person: this.person,
            RealCards: this.realCards,
        }
    }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class SMSService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            sendLink: discussionId => this._repository.query(`smsmessages/${discussionId}/sendlink`)
        }

        this.subscriptions = {
            taskSMSBatch: this.signalRSubscription('TaskSMSBatch'),
            batchSMS: this.signalRSubscription('BatchSMS')
        }

        this.events = {
            smsBatchNotification: this.signalREvent('smsbatchnotification'),
            smsStatusNotification: this.signalREvent('smsstatusnotification')
        }
    }

    /**
    * Send a link via sms
    * @param {string} discussionId - discussion identity
    * @param {Object} linkObj - link data
    */
    sendLink(discussionId, linkObj) {
        return this.queries.sendLink(discussionId).add(linkObj);
    }
}
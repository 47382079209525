import endpoint from 'endpoints/api';
import events from 'managers/events';
import { JSONActiveTopicInfo } from 'models/activeTopicInfo';
import { JSONTopicSlide } from 'models/topicSlide';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class LiveGroupService extends BaseService {
    private repository = new Repository(endpoint)

    queries = {
        slides: () => this.repository.fastQuery('whiteboard/slides'),
        slide: (slideId: string) => this.repository.fastQuery(`whiteboard/slides/${slideId}`),
        topics: (livegroupId: string) => this.repository.fastQuery(`livegroups/${livegroupId}/topics`),
        activeTopicInfo: (livegroupId: string) => this.repository.query<never, JSONActiveTopicInfo | undefined, JSONActiveTopicInfo, void, never, never, never>(`livegroups/${livegroupId}/topics/active`),
        topicSlides: (topicId: string) => this.repository.query<never, JSONTopicSlide, never, never, never, never, never>(`topics/${topicId}/whiteboard/slides`),
        topicActiveSlide: (topicId: string) => this.repository.query<never, string | undefined, string, void, never, never, void>(`topics/${topicId}/whiteboard/slides/active`),
        closeLiveGroup: (liveGroupId: string) => this.repository.query<never, never, never, void, never, never, never>(`livegroups/${liveGroupId}/close`),
        closeLiveGroupForParticipants: (liveGroupId: string) => this.repository.query<never, never, never, void, never, never, never>(`livegroups/${liveGroupId}/closeforparticipants`),
        extendLiveGroupDuration: (liveGroupId: string) => this.repository.query<never, never, number, void, never, never, never>(`livegroups/${liveGroupId}/extend`),
        checkIn: (liveGroupId: string) => this.repository.query<never, never, never, void, never, never, void>(`livegroups/${liveGroupId}/checkin`)
    }

    subscriptions = {
        slide: this.signalRSubscription('Slide'),
        liveGroup: this.signalRSubscription('LiveGroup'),
        discussionTopicSlides: this.signalRSubscription('DiscussionTopicSlides')
    }

    events = {
        activeSlideChanged: this.signalREvent('ActiveSlideChanged'),
        topicSlideAdded: this.signalREvent('TopicSlideAdded'),
        topicSlideChanged: this.signalREvent('TopicSlideChanged'),
        topicSlideRemoved: this.signalREvent('TopicSlideRemoved'),
        checkedIn: this.signalREvent('CheckedIn'),
        checkedOut: this.signalREvent('CheckedOut')
    }

    messages = {
        visibleSlideChanged: this.signalRMessage('VisibleSlideChanged')
    }

    async setActiveTopicInfo(taskId: string, data: JSONActiveTopicInfo) {
        await this.queries.activeTopicInfo(taskId).add(data);
        events.liveChatActiveTopicInfoChanged.trigger(taskId, data);
    }

    saveTopicSlide(jsonSlide: any) {
        return (this.queries.slides().important().add(jsonSlide) as JQueryPromise<any>)
            .done(jsonSlide => events.topicSlideAdded.trigger(jsonSlide.TopicId, jsonSlide));
    }

    updateTopicSlide(slideId: string, jsonSlide: any) {
        return (this.queries.slide(slideId).important().update(jsonSlide) as JQueryPromise<any>)
            .done(jsonSlide => events.topicSlideUpdated.trigger(jsonSlide.SlideId, jsonSlide.TopicId, jsonSlide));
    }

    async deleteTopicSlide(topicId: string, slideId: string) {
        await this.queries.slide(slideId).important().remove();
        events.topicSlideDeleted.trigger(topicId, slideId);
    }

    async setTopicActiveSlide(topicId: string, slideId: string) {
        await this.queries.topicActiveSlide(topicId).post(slideId);
        events.topicActiveSlideChanged.trigger(topicId, slideId);
    }

    async deleteTopicActiveSlide(topicId: string) {
        await this.queries.topicActiveSlide(topicId).remove();
        events.topicActiveSlideChanged.trigger(topicId, undefined);
    }

    async closeLiveGroup(liveGroupId: string) {
        await this.queries.closeLiveGroup(liveGroupId).post();
    }

    closeLiveGroupForParticipants(liveGroupId: string) {
        return this.queries.closeLiveGroupForParticipants(liveGroupId).post();
    }

    async extendLiveGroupDuration(liveGroupId: string, count: number) {
        await this.queries.extendLiveGroupDuration(liveGroupId).post(count);
    }

    getCheckedInPeopleIds(livegroupId: string) {
        return this.queries.checkIn(livegroupId).toArray();
    }

    async checkIn(livegroupId: string) {
        await this.queries.checkIn(livegroupId).post();
        events.liveChatCheckInStateChanged.trigger(true);
    }

    async checkOut(livegroupId: string) {
        await this.queries.checkIn(livegroupId).remove();
        events.liveChatCheckInStateChanged.trigger(false);
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { PureComputed } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import { EffectsContainer } from 'mixins/withEffects';
import Task, { JSONTask } from 'models/task';
import { DiscussionService } from 'services/discussion';
import { TasksService } from 'services/tasks';

interface TaskManagerConfig {
    taskId: string
}

@injectable()
@storageDefaults(<Partial<TaskManagerConfig>>{ taskId: settings.discussionId })
export default class TaskManager {
    private source: EntityDataSource<JSONTask>

    taskId: PureComputed<string>
    task: PureComputed<Task | undefined>

    busy = ko.pureComputed(() => this.source.busy())
    loading = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() === 0);
    updating = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() > 0);

    constructor(
        @inject(wccModules.managerConfig) { taskId }: TaskManagerConfig,
        @inject(wccModules.tasksService) tasksService: TasksService,
        @inject(wccModules.discussionService) discussionService: DiscussionService,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.taskId = ko.pureComputed(() => taskId);

        this.source = effects.register(new EntityDataSource({
            update: () => tasksService.queries.taskById(taskId).firstOrDefault()
        }));

        this.task = this.source.data.toEntity(
            jsonTask => new Task(jsonTask),
            (task, jsonTask) => task.update(jsonTask));

        discussionService.subscriptions.discussion.subscribe(taskId);
        discussionService.events.discussionChanged.on(this.onTaskChanged.bind(this));
    }

    update () {
        this.source.update();
    }

    private onTaskChanged(taskId: string) {
        if (this.taskId() === taskId)
            this.update();
    }
}
import { Observable, ObservableArray, PureComputed } from 'knockout';
import { CollectionDataSource, LoadRequest } from 'managers/datasources/collection';
import events from 'managers/events';
import { withEffects } from 'mixins/withEffects';
import { JSONTag, Tag } from 'models/tag';
import { ServicesContext } from 'services/context';

const { discussionId } = settings;

export interface TagsManager {
    tags: ObservableArray<Tag>
    tagToEdit: Observable<Tag | undefined>

    allowRemove: PureComputed<boolean>
    allowEdit: PureComputed<boolean>

    update(): void

    addTag(jsonTag: JSONTag): Promise<void>
    removeTag(tag: Tag): Promise<void>
    updateTag(jsonTag: JSONTag): Promise<void>
    editTag(tag: Tag): void

    busy: PureComputed<boolean>
    loading: PureComputed<boolean>
    updating: PureComputed<boolean>
}

export default abstract class TagsManagerBase implements TagsManager {
    protected effects = withEffects()
    protected ctx: ServicesContext     
    protected source: CollectionDataSource<Tag, 'contentTagId'>
    
    protected tasksCount = ko.observable(0);   

    tags: ObservableArray<Tag>
    tagToEdit = ko.observable<Tag>();

    allowRemove = ko.pureComputed(() => this.isRemoveAllowed());
    allowEdit = ko.pureComputed(() => this.isEditAllowed());

    busy = ko.pureComputed(() => this.source.busy() || this.tasksCount() > 0);
    loading = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() === 0);
    updating = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() > 0);

    constructor(isEmpty = false) {
        this.ctx = this.effects.register(new ServicesContext());

        this.source = this.effects.register(new CollectionDataSource({
            key: 'contentTagId',
            load: this.load.bind(this),
            mapper: this.map.bind(this),
            isEmpty: isEmpty
        }));

        this.tags = this.source.list;

        this.effects.register([
            events.tagUpdated.on(this.onLocalTagUpdated.bind(this)),
            events.tagVisibilityUpdated.on(this.onLocalTagVisibilityUpdated.bind(this))
        ]);
    }

    update() {
        this.source.load();
    }

    abstract addTag(jsonTag: JSONTag): Promise<void>
    abstract removeTag(tag: Tag): Promise<void>

    async updateTag(jsonTag: JSONTag) {
        try {
            if (discussionId == undefined)
                throw new Error(messages.UnknownError);

            this.tasksCount.inc();

            await this.ctx.tagsService.updateTag(discussionId, jsonTag);
        } finally {
            this.tasksCount.dec()
        }
    }    

    editTag(tag: Tag) {
        this.tagToEdit(tag);
    }

    dispose() {
        this.effects.dispose();
    }

    protected abstract load(request: LoadRequest): Array<any> | Promise<Array<any>>;

    protected map(jsonTag: any): Tag {
        return new Tag(jsonTag);
    }

    protected isRemoveAllowed() {
        return false;
    }

    protected isEditAllowed() {
        return false;
    }

    private onLocalTagUpdated(tagId: string, jsonTag: JSONTag) {
        this.source.findSync(tagId, tag => tag.update(jsonTag));
        const updatedTag = new Tag(jsonTag);
        if (updatedTag.isComplex()) {
            this.source.list().forEach(tag => {
                if (tag.tagRoot() == updatedTag.tagRoot()) {
                    tag.tagColor(updatedTag.tagColor());
                    tag.visibility(updatedTag.visibility());
                }
            });
        }
    }

    private onLocalTagVisibilityUpdated(tagId: string, tagRoot: string | undefined, visibility: number) {
        this.source.findSync(tagId, tag => tag.visibility(visibility));
        if (tagRoot) {
            this.source.list().forEach(tag => {
                if (tag.tagRoot() == tagRoot)
                    tag.visibility(visibility);
            });
        }
    }
}
import { Observable } from 'knockout';
import SurveyStatisticsQuestionAnswer, { JSONSurveyStatisticsQuestionAnswer } from './answer';

export interface JSONSurveyStatisticsBlankQuestionAnswer extends JSONSurveyStatisticsQuestionAnswer {
    ProfileQuestionBlankOptionId: string
    Value: number
}

export default class SurveyStatisticsBlankQuestionAnswer extends SurveyStatisticsQuestionAnswer<JSONSurveyStatisticsBlankQuestionAnswer> {
    blankOptionId: Observable<string>
    value: Observable<number>

    constructor(data: JSONSurveyStatisticsBlankQuestionAnswer) {
        super(data);

        this.blankOptionId = this.createField(data, 'ProfileQuestionBlankOptionId');
        this.questionChoiceId(this.blankOptionId());
        this.value = this.createField(data, 'Value');
    }
}
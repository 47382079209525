import { Observable, ObservableArray } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyStatisticsQuestionAnswer from './answer';

export interface JSONSurveyStatisticsQuestion {
    ProfileQuestionId: string
    DescriptionForStatistics?: string
    Average: number
    IsAllNumeric: boolean
}

export default abstract class SurveyStatisticsQuestion<A extends SurveyStatisticsQuestionAnswer = SurveyStatisticsQuestionAnswer, T extends JSONSurveyStatisticsQuestion = JSONSurveyStatisticsQuestion> extends WCCEntity<T> {
    abstract answers: ObservableArray<A>

    questionId: Observable<string>
    descriptionForStatistics: Observable<string | undefined> // for blank questions
    averageScore: Observable<number>

    isAllNumeric: Observable<boolean>

    constructor(data: T) {
        super();

        this.questionId = this.createField(<JSONSurveyStatisticsQuestion>data, 'ProfileQuestionId');
        this.descriptionForStatistics = this.createField(<JSONSurveyStatisticsQuestion>data, 'DescriptionForStatistics');
        this.averageScore = this.createField(<JSONSurveyStatisticsQuestion>data, 'Average');

        this.isAllNumeric = this.createField(<JSONSurveyStatisticsQuestion>data, 'IsAllNumeric');
    }
}
import { Subscribable, SubscribableOrNullableValue } from 'knockout';
import { INodeChangesTracker } from './changesTracker';
import NodeContentChangesTracker from './contentChangesTracker';
import NodeSizeChangesTracker from './sizeChangesTracker';

interface NodeTrackerConfig {
    Node?: SubscribableOrNullableValue<HTMLElement>
    node?: SubscribableOrNullableValue<HTMLElement>
}

export class NodeTracker {
    private tracker: Subscribable<INodeChangesTracker | undefined>

    node: Subscribable<HTMLElement | undefined>
    updateOnDocumentMutations = ko.pureComputed(() => true)

    width = ko.pureComputed(() => this.withNode(node => node.offsetWidth) ?? 0);
    height = ko.pureComputed(() => this.withNode(node => node.offsetHeight) ?? 0);

    clientWidth = ko.pureComputed(() => this.withNode(node => node.clientWidth) ?? 0);
    clientHeight = ko.pureComputed(() => this.withNode(node => node.clientHeight) ?? 0);

    scrollWidth = ko.pureComputed(() => this.withNode(node => node.scrollWidth) ?? 0);
    scrollHeight = ko.pureComputed(() => this.withNode(node => node.scrollHeight) ?? 0);

    outerWidth = ko.pureComputed(() => this.withNode(node => $(node).outerWidth(true)) ?? 0);
    outerHeight = ko.pureComputed(() => this.withNode(node => $(node).outerHeight(true)) ?? 0);  

    rect = ko.pureComputed(() => this.withNode(node => node.getBoundingClientRect()));

    mutations: Subscribable<number>

    isActive = ko.pureComputed(() => true);

    constructor(config: NodeTrackerConfig) {
        //deferred is required here. we need to wait one cycle to let ko apply all bindings. that causes wrong size value
        this.node = ko.pureComputed(() => config.Node ?? config.node).unwrap().extend({ deferred: true });
        this.tracker = this.node.mapNotNull(node => this.getTracker(node));
        this.mutations = this.tracker.pluck(t => t.mutations, 0);
    }

    update(force = false) {
        this.tracker.invokeNotNull(t => t.update(force));
    }

    init() { }
    dispose() { }

    private getTracker(node: HTMLElement): INodeChangesTracker {
        if (window.ResizeObserver == undefined)
            return NodeContentChangesTracker.bodyTracker();
        else
            return new NodeSizeChangesTracker(node);
    }

    private withNode<T>(mapper: (node: HTMLElement) => T) {
        return this.node.invokeNotNull(node => {
            this.mutations();

            return mapper(node);
        });
    }
}
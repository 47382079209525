import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyTimeSelectionOptions {
    EarliestTimePlaceholder?: string
    LatestTimePlaceholder?: string
    EarliestTime?: string
    LatestTime?: string
    TimeSteps?: number
    TimeSelectionRange: number
}

export default class SurveyTimeSelectionOptions extends WCCEntity<JSONSurveyTimeSelectionOptions> {
    earliestTimePlaceholder: Observable<string | undefined>
    latestTimePlaceholder: Observable<string | undefined>
    earliestTime: Observable<string | undefined>
    latestTime: Observable<string | undefined>
    timeSteps: Observable<number | undefined>
    timeSelectionType: Observable<number>

    constructor(data: JSONSurveyTimeSelectionOptions) {
        super();

        this.earliestTimePlaceholder = this.createField(data, 'EarliestTimePlaceholder');
        this.latestTimePlaceholder = this.createField(data, 'LatestTimePlaceholder');
        this.earliestTime = this.createField(data, 'EarliestTime');
        this.latestTime = this.createField(data, 'LatestTime');
        this.timeSteps = this.createField(data, 'TimeSteps');
        this.timeSelectionType = this.createField(data, 'TimeSelectionRange');
    }
}
import CommunityAccess from 'enums/community/access';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import DiscussionTermsManager from 'managers/discussion/terms';
import { IWCCStorageManager } from 'managers/iStorage';
import { EffectsContainer } from 'mixins/withEffects';
import TwoFactorAuthManager from '../account/twoFactorAuthManager';

const { unknownUserMode } = settings;

interface CommunityAccessManagerConfig {
    communityId: string
}

@injectable()
export default class CommunityAccessManager {
    access: Subscribable<CommunityAccess>

    busy: Subscribable<boolean>

    constructor(
        @inject(wccModules.managerConfig) { communityId: discussionId }: CommunityAccessManagerConfig,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const userManager = storage.get(CurrentUserManager, { discussionId });
        const user = userManager.pluck(m => m.person);
        const userReady = userManager.pluck(m => !m.loading());
        
        const isLoggedIn = user.isNotNull();
        const noAccess = user.pluck(u => u.noAccess, false);

        const termsManager = storage.get(DiscussionTermsManager, { discussionId });
        const termsReady = termsManager.pluck(m => !m.busy(), false);
        const hasToAgreeWithTermsAndConditions = termsManager.pluck(m => m.hasToAgreeWithTermsAndConditions, false);

        const tfaManager = storage.get(TwoFactorAuthManager, { discussionId });
        const tfaReady = tfaManager.pluck(m => !m.busy(), false);
        const requiresTwoFactor = tfaManager.pluck(m => m.requiresTwoFactorAuthVerification(), false);

        this.access = ko.pureComputed(() => {
            if (settings.communityStandalonePageType != undefined)
                return CommunityAccess.singlepage;

            if (isLoggedIn()) {
                if (this.busy()) {
                    return CommunityAccess.none;
                } else if (requiresTwoFactor()) {
                    return CommunityAccess.twofactorauth;
                } else if (noAccess()) {
                    return CommunityAccess.forbidden;
                } else if (hasToAgreeWithTermsAndConditions()) {
                    return CommunityAccess.termsandconditions;
                }

                return CommunityAccess.membersonly;
            } else {
                return CommunityAccess.public;
            }
        });

        this.busy = ko.pureComputed(() => !userReady() || !termsReady() || !tfaReady() || unknownUserMode());
        
        const isSinglePageMode = this.access.is(CommunityAccess.singlepage);

        effects.register([
            isSinglePageMode.subscribe(isSinglePageMode => unknownUserMode(isSinglePageMode))
        ]);
    }
}
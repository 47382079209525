import { inject, injectable } from "inversify";
import { DisposableSubscribable, Subscribable } from "knockout";
import { wccModules } from "../../enums/wccModules";
import { AppointmentsHelpers } from "../../helpers/appointments";
import BaseAppointmentsIntervalsManager from "../../managers/appointments/intervals/baseIntervalsTimeline";
import DiscussionAppointmentsIntervalsManager from "../../managers/appointments/intervals/discussionIntervalsTimeline";
import UserAppointmentsIntervalsManager from "../../managers/appointments/intervals/userIntervalsTimeline";
import { IWCCStorageManager } from "../../managers/iStorage";
import DateIntervalsFactory from "../date/intervals";
import { DiscussionAppointmentsIntervalsFactoryRequest, IAppointmentsIntervalsFactoryLoader, UserAppointmentsIntervalsFactoryRequest } from "./iAppointmentsIntervalsFactory";

@injectable()
export default class AppointmentsIntervalsFactoryLoader implements IAppointmentsIntervalsFactoryLoader {
    constructor(
        @inject(wccModules.storage) private storage: IWCCStorageManager
    ) { }

    get(request: DiscussionAppointmentsIntervalsFactoryRequest) {
        const intervalsManager = this.storage.get(DiscussionAppointmentsIntervalsManager, { discussionId: request.discussionId, host: request.host, participant: request.participant, excludedRoomIDs: request.excludedRoomIDs });

        return this.getInternal(request, <DisposableSubscribable<BaseAppointmentsIntervalsManager | undefined>>intervalsManager);
    }

    getForCurrentUser(request: UserAppointmentsIntervalsFactoryRequest): DateIntervalsFactory {
        const intervalsManager = this.storage.get(UserAppointmentsIntervalsManager, { discussionId: request.discussionId, host: request.host, excludedRoomIDs: request.excludedRoomIDs });
        return this.getInternal(request, <DisposableSubscribable<BaseAppointmentsIntervalsManager | undefined>>intervalsManager);
    }

    private getInternal(request: DiscussionAppointmentsIntervalsFactoryRequest | UserAppointmentsIntervalsFactoryRequest, intervalsManager: Subscribable<BaseAppointmentsIntervalsManager | undefined>) {
        return new DateIntervalsFactory({
            init: (from, to) => intervalsManager.pluck(instance => instance.load(from, to)),

            ranges: (from, to, zone) => intervalsManager.pluck(m => m.get(from, to), []).pluck(i => ({ from: i.from(), to: i.to() })),

            filter: (from, to, zone) => {
                if (from < AppointmentsHelpers.allowAppointmentsAfter())
                    return false;

                return true;
            }
        })
    }
}
import CommunityTemplateTopicsGroupBindingMode from 'enums/community/templates/topicsGroupBindingMode';
import { wccModules } from 'enums/wccModules';
import { ValuesStreamPromise } from 'interfaces/valuesStreamPromise';
import { inject, injectable } from 'inversify';
import { Subscribable, SubscribableOrNullableValue } from 'knockout';
import SimpleTopicsManager from 'managers/discussion/simpleTopics';
import { IWCCStorageManager } from 'managers/iStorage';
import { SimpleTopic } from 'models/simpleTopic';
import { SimpleTopicsGroup } from 'models/simpleTopicsGroup';
import { ICommunityTemplateTopicsGroupTopicLoader } from './iTopic';

@injectable()
export default class CommunityTemplateTopicsGroupTopicLoader implements ICommunityTemplateTopicsGroupTopicLoader {
    private groups: Subscribable<Array<SimpleTopicsGroup>>
    private isReady: Subscribable<boolean>

    constructor(
        @inject(wccModules.storage) storage: IWCCStorageManager
    ) {
        const topicsManager = storage.get(SimpleTopicsManager, {});
        const topicsReady = topicsManager.pluck(m => !m.loading(), false);
        this.groups = topicsManager.pluck(m => m.groups, []);

        this.isReady = ko.pureComputed(() => topicsReady());
    }

    load(groupId: SubscribableOrNullableValue<string>, bindingMode: SubscribableOrNullableValue<CommunityTemplateTopicsGroupBindingMode>): ValuesStreamPromise<SimpleTopic | undefined> {
        const fGroupId = ko.flattenComputed(groupId);
        const fBindingMode = ko.flattenComputed(bindingMode);        

        return ko.pureComputed(() => {
            if (this.isReady()) {
                const group = this.groups().find(group => group.id() == fGroupId());
                const topics = group?.topics() ?? [];

                switch (fBindingMode()) {
                    case CommunityTemplateTopicsGroupBindingMode.firstUnansweredTopicOrFirst: return { value: topics.find(topic => topic.shouldBeAnswered()) ?? _(topics).first() }
                    case CommunityTemplateTopicsGroupBindingMode.firstUnansweredTopicOrLast: return { value: topics.find(topic => topic.shouldBeAnswered()) ?? _(topics).last() }
                    case CommunityTemplateTopicsGroupBindingMode.lastTopic: return { value: _(topics).last() }
                    default: return { value: _(topics).first() }
                }
            }
        }).extend({ notifyIfChanged: true });
    }
}
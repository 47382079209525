import { Observable, ObservableArray, Subscribable } from "knockout";
import { Zone } from "luxon";
import { DateHelpers } from "../../helpers/date";
import WCCEntity from "../entity";
import { JSONPerson, Person } from "../person";

export interface JSONGroupRoom {
    GroupRoomId: string
    InterviewerId?: string
    ActiveTopicId?: string
    ActiveSlideId?: string
    ChatTopicId?: string
    BackRoomTopicId?: string
    Type: number
    Status: number
    StartsAt?: string
    EndsAt?: string
    ReserveUntil?: string
    RecordingStartedAt?: string
    HasRecordings?: boolean
    Members: JSONPerson[]
}

export default class GroupRoom extends WCCEntity<JSONGroupRoom> {
    groupRoomId: Observable<string>
    interviewerId: Observable<string | undefined>
    activeTopicId: Observable<string | undefined>
    activeSlideId: Observable<string | undefined>
    chatTopicId: Observable<string | undefined>
    backRoomTopicId: Observable<string | undefined>
    type: Observable<number>
    status: Observable<number>
    startsAt: Observable<Date | undefined>
    endsAt: Observable<Date | undefined>
    reserveUntil: Observable<Date | undefined>
    recordingStartedAt: Observable<Date | undefined>
    hasRecordings: Observable<boolean>

    members: ObservableArray<Person>

    duration: Subscribable<number | undefined>
    gap: Subscribable<number | undefined>

    publicMembers: Subscribable<Person[]>
    backroomMembers: Subscribable<Person[]>

    static getStatusTitle(status: number): string | undefined {
        switch (status) {
            case enums.GroupRoomStatus.Created.value: return 'Unconfirmed';
            case enums.GroupRoomStatus.Confirmed.value: return 'Confirmed';
            case enums.GroupRoomStatus.Complete.value: return 'Completed';
            case enums.GroupRoomStatus.Cancelled.value: return 'Cancelled';
            case enums.GroupRoomStatus.Missed.value: return 'Missed';
            case enums.GroupRoomStatus.InProgress.value: return 'In Progress';
        }
    }

    constructor(data: JSONGroupRoom) {
        super();

        this.groupRoomId = this.createField(data, 'GroupRoomId');
        this.interviewerId = this.createField(data, 'InterviewerId');
        this.activeTopicId = this.createField(data, 'ActiveTopicId');
        this.activeSlideId = this.createField(data, 'ActiveSlideId');
        this.chatTopicId = this.createField(data, 'ChatTopicId');
        this.backRoomTopicId = this.createField(data, 'BackRoomTopicId');
        this.type = this.createField(data, 'Type');
        this.status = this.createField(data, 'Status');
        this.startsAt = this.createMappedField(data, 'StartsAt', WCCEntity.dateMapper);
        this.endsAt = this.createMappedField(data, 'EndsAt', WCCEntity.dateMapper);
        this.reserveUntil = this.createMappedField(data, 'ReserveUntil', WCCEntity.dateMapper);
        this.recordingStartedAt = this.createMappedField(data, 'RecordingStartedAt', WCCEntity.dateMapper);

        this.hasRecordings = this.createField(data, 'HasRecordings', false);

        this.members = this.createCollection(data, 'Members', jsonMember => new Person(jsonMember));

        this.publicMembers = this.members.filter(m => m.isModerator() && (this.interviewerId() == undefined || m.personId() == this.interviewerId()) || m.isMember() || m.isInvited());
        this.backroomMembers = this.members.filter(m => !this.publicMembers().includes(m));

        this.duration = ko.pureComputed(() => {
            const startsAt = this.startsAt();
            const endsAt = this.endsAt();

            if (startsAt != undefined && endsAt != undefined)
                return Math.ceil((endsAt.getTime() - startsAt.getTime()) / DateHelpers.milisecondsInMinute);
        });

        this.gap = ko.pureComputed(() => {
            const endsAt = this.endsAt();
            const reserveUntil = this.reserveUntil();

            if (endsAt != undefined && reserveUntil != undefined)
                return Math.ceil((reserveUntil.getTime() - endsAt.getTime()) / DateHelpers.milisecondsInMinute);
        });
    }

    startsBetween(from: Date, to: Date) {
        const startsAt = this.startsAt();

        if (startsAt != undefined)
            return startsAt >= from && startsAt <= to;

        return false;
    }

    getTitle(timezone?: Zone) {
        let title = this.members().map(m => m.nicknameOrName()).join(', ');

        const timeParts = new Array<string>();

        const startsAt = this.startsAt();
        const endsAt = this.endsAt();       

        if (startsAt != undefined)
            timeParts.push(DateHelpers.format(startsAt, DateHelpers.datetimeFormat, timezone));

        if (endsAt != undefined)
            timeParts.push(DateHelpers.format(endsAt, DateHelpers.datetimeFormat, timezone));

        title += ` ${timeParts.join(' - ')}`;

        return title;
    }
}
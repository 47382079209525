import { Func } from 'interfaces/func';
import { Serializable } from 'interfaces/serializable';
import { StringMap } from 'interfaces/stringMap';
import { Observable, ObservableArray, Subscribable, WCCUnwrapped } from 'knockout';
import { Mapped } from '../interfaces/mapped';

const defaultFieldMapper = <WCCEntityFieldMapper<any, any>>{
    map: _.identity,
    mapReverse: _.identity
}

interface WCCEntityField {
    name: string
    value: Observable<any>
    update: Func<any, [any]>
    mapper: WCCEntityFieldMapper<any, any>
}

export interface WCCEntityFieldMapper<I, O> {
    key?: keyof O
    map: Func<NonNullable<O>, [NonNullable<WCCUnwrapped<I>>]>
    mapReverse: Func<NonNullable<WCCUnwrapped<I>>, [NonNullable<O>]>
    update?: Action<[item: NonNullable<O>, newItem: NonNullable<O>]>
}

type WCCEntityFieldMapFunction<I, O> = O extends WCCEntity ? Func<O, [NonNullable<WCCUnwrapped<I>>]> : never;

type WCCEntityFieldDefaultValueOrFactory<V> = V extends null | undefined ? never : Func<V> | Subscribable<V> | V;
type WCCEntityCollectionField<T> = T extends Array<infer I> ? I : never;

interface WCCEntityCollection {
    name: string
    value: ObservableArray<any>
    update: Func<any, [any]>
    mapper: WCCEntityFieldMapper<any, any>
}

export default class WCCEntity<T extends StringMap<any> = StringMap<any>> {
    private fields: Array<WCCEntityField> = []
    private collections: Array<WCCEntityCollection> = []

    constructor() { }

    update(data: T) {
        this.fields.forEach(({ update }) => update(data));
        this.collections.forEach(({ update }) => update(data));
    }

    toJson(): T {
        const result: StringMap<any> = {}

        this.fields.forEach(field => result[field.name] = field.value.invokeNotNull(field.mapper.mapReverse));
        this.collections.forEach(collection => result[collection.name] = collection.value().map(item => collection.mapper.mapReverse(item)));

        return result as T;
    }

    clone<V extends WCCEntity<T> = WCCEntity<T>>(overrides?: Partial<T>): V {
        return new (this.constructor as any)({ ...this.toJson(), ...overrides });
    }

    protected createField<K extends keyof T1, T1 = T>(data: T1, name: K): Observable<WCCUnwrapped<T1[K]>>;
    protected createField<K extends keyof T1, T1 = T>(data: T1 | undefined, name: K): Observable<WCCUnwrapped<T1[K]> | undefined>;
    protected createField<K extends keyof T1, T1 = T>(data: T1 | undefined, name: K, defaultValue: WCCEntityFieldDefaultValueOrFactory<NonNullable<WCCUnwrapped<T1[K]>>>): Observable<NonNullable<WCCUnwrapped<T1[K]>>>;

    protected createField<K extends keyof T>(data: T | undefined, name: K, defaultValue?: WCCEntityFieldDefaultValueOrFactory<T[K]>) {
        return this.createFieldInternal(data, name, defaultFieldMapper, defaultValue);
    }

    protected createMappedField<K extends keyof T1, V, T1 = T>(data: T1, name: K, mapper: WCCEntityFieldMapper<T1[K], V>): Observable<Mapped<T1[K], WCCUnwrapped<V>>>;
    protected createMappedField<K extends keyof T1, V, T1 = T>(data: T1 | undefined, name: K, mapper: WCCEntityFieldMapper<T1[K], V>): Observable<WCCUnwrapped<V> | undefined>;
    protected createMappedField<K extends keyof T1, V, T1 = T>(data: T1 | undefined, name: K, mapper: WCCEntityFieldMapper<T1[K], V>, defaultValue: WCCEntityFieldDefaultValueOrFactory<V>): Observable<NonNullable<WCCUnwrapped<V>>>;

    protected createMappedField<K extends keyof T1, V extends WCCEntity = WCCEntity, T1 = T>(data: T1, name: K, mapper: WCCEntityFieldMapFunction<T1[K], V>): Observable<Mapped<T1[K], WCCUnwrapped<V>>>;
    protected createMappedField<K extends keyof T1, V extends WCCEntity = WCCEntity, T1 = T>(data: T1 | undefined, name: K, mapper: WCCEntityFieldMapFunction<T1[K], V>): Observable<WCCUnwrapped<V> | undefined>;
    protected createMappedField<K extends keyof T1, V extends WCCEntity = WCCEntity, T1 = T>(data: T1 | undefined, name: K, mapper: WCCEntityFieldMapFunction<T1[K], V>, defaultValue: WCCEntityFieldDefaultValueOrFactory<V>): Observable<NonNullable<WCCUnwrapped<V>>>;

    protected createMappedField<K extends keyof T, V>(data: T | undefined, name: K, mapperOrMapFunc: WCCEntityFieldMapper<T[K], V> | WCCEntityFieldMapFunction<T[K], V>, defaultValue?: WCCEntityFieldDefaultValueOrFactory<T[K]>) {
        const mapper: WCCEntityFieldMapper<T[K], V> = 'map' in mapperOrMapFunc ? mapperOrMapFunc : <any><WCCEntityFieldMapper<any, WCCEntity>>{ map: mapperOrMapFunc, mapReverse: entity => entity.toJson() }
        return this.createFieldInternal(data, name, mapper, defaultValue);
    }

    protected createCollection<K extends keyof T1, I extends WCCEntityCollectionField<T1[K]>, V extends Serializable<I>, T1 = T>(data: T1 | undefined, name: K, mapper: (item: I) => V): ObservableArray<V>;
    protected createCollection<K extends keyof T1, V, T1 = T>(data: T1 | undefined, name: K, mapper?: WCCEntityFieldMapper<WCCEntityCollectionField<T1[K]>, V>): ObservableArray<V>;

    protected createCollection<K extends keyof T1, V, T1 = T>(data: T1 | undefined, name: K, mapperOrFactory: ((item: WCCEntityCollectionField<T1[K]>) => V) | WCCEntityFieldMapper<WCCEntityCollectionField<T1[K]>, V> = defaultFieldMapper) {
        const mapper = _.isFunction(mapperOrFactory) ?
            <WCCEntityFieldMapper<WCCEntityCollectionField<T1[K]>, V>>{ map: mapperOrFactory, mapReverse: item => (item as any as Serializable<WCCEntityCollectionField<T1[K]>>).toJson() } :
            mapperOrFactory;

        const factory = (data: T1 | undefined) => {
            if (data != undefined) {
                const jsonField = data[name];

                if (_.isArray(jsonField))
                    return jsonField.map((item: any) => mapper.map(item));
            }

            return [];
        }

        const update = (data: T1 | undefined) => {
            let newCollection = factory(data);

            const { key, update } = mapper;

            if (key != undefined && update != undefined) {
                const oldCollection = collection();

                newCollection = newCollection.map(item => {
                    let result = oldCollection.find(oldItem => ko.unwrap(oldItem[key]) == ko.unwrap(item[key]));

                    if (result != undefined)
                        update(result, item);
                    else
                        result = item;

                    return result;
                });
            }

            collection(newCollection);
        }

        const collection = ko.observableArray(factory(data));

        this.collections.push({ name: <string>name, value: collection, update, mapper });

        return collection;
    }

    private createFieldInternal<K extends keyof T, V = T[K]>(data: T | undefined, name: K, mapper: WCCEntityFieldMapper<T[K], V>, defaultValue?: WCCEntityFieldDefaultValueOrFactory<V>): Observable<any> {
        const defaultValueFactory = _.isFunction(defaultValue) ? defaultValue as () => V : () => defaultValue as V | undefined;

        const factory = (data: T | undefined) => {
            if (data != undefined) {
                return ko.flattenComputed(data[name]).pluck((value: any) => {
                    try {
                        return mapper.map(value);
                    } catch (ex) {
                        console.error(ex, value, mapper);
                        throw ex;
                    }
                });
            }
        }

        const update = (data: T | undefined) => {
            field(<any>factory(data));
        }

        const field = ko.observable(factory(data)).unwrap().default(<any>defaultValueFactory());

        this.fields.push({ name: name as string, value: field, update, mapper });

        return field;
    }

    static dateMapper = <WCCEntityFieldMapper<string | undefined, Date | undefined>>{ map: str => new Date(str), mapReverse: date => date.toISOString() }
    static dateMapperNotNull = <WCCEntityFieldMapper<string, Date>>{ map: str => new Date(str), mapReverse: date => date.toISOString() }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class SystemService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        time: () => this.repository.query<never, string, never, never, never, never, never>(`sys/time`),
    }    
}
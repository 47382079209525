import { Observable, ObservableArray } from "knockout";
import { JSONTermsAndConditionsStatement, TermsAndConditionsStatement } from "./termsAndConditionsStatement";
import { StringHelpers } from "../helpers/string";

const { WccCDNUrl } = settings;

const observerCanCommentAccessTypes: Array<number | undefined> = [
    enums.ObserverAccessTypes.CanComment.value,
    enums.ObserverAccessTypes.CanCommentAndModeratorNotified.value
]

export interface JSONTask {
    Id?: string
    DiscussionId?: string
    AccountId?: string
    AccountDNS?: string
    CreatorId?: string
    ReferenceCode?: string

    Title?: string
    QuickSell?: any
    ThoroughDescription?: string
    ThoroughDescriptionSecondary?: string

    Creator?: string
    CreatorImageURL?: string

    Type?: number
    EditorPagesTypes?: number 
    ModeratorAccessTypes?: string
    ObserverAccess?: number
    ObserverAccessTypes?: string
    ParticipantsAnonymity?: number
    OnlineStatus?: number
    PaymentStatus?: number    

    MediaImageCreatorId?: string
    Media?: string    
    MediaImageHash?: string
    MediaType?: number
    ContentImageType?: number

    HasThumbnail?: boolean
    HasTags?: boolean
    HasComments?: boolean
    HasAttachments?: boolean
    HasGroups?: boolean
    HasModeratorsOrObservers?: boolean
    HasLegacyAccess?: boolean

    IsOpen?: boolean
    ShowTour?: boolean
    NeedsTermsAndConditions?: boolean
    ShowInDashboard?: boolean
    RequireAPassword?: boolean

    VisibleBefore?: string
    VisibleAfter?: string
    CreateDate?: string
    EditDate?: string

    TermsAndConditions?: string
    TermsAndConditionsStatements?: string
}

export default class Task {
    _json: JSONTask | undefined

    id: Observable<string | undefined>
    accountId: Observable<string | undefined>
    accountDNS: Observable<string | undefined>
    referenceCode: Observable<string | undefined>

    title: Observable<string>
    quickSell: Observable<string>
    thoroughDescription: Observable<string>
    thoroughDescriptionSecondary: Observable<string>

    creatorId: Observable<string | undefined>
    creator: Observable<string | undefined>
    creatorImageURL: Observable<string | undefined>

    mediaImageCreatorId: Observable<string | undefined>
    media: Observable<string | undefined>
    mediaImageHash: Observable<string | undefined>
    mediaType: Observable<number | undefined>
    contentImageType: Observable<number | undefined>
    imageURL: Observable<string>

    type: Observable<number | undefined>
    editorPagesTypes: Observable<number | undefined>
    moderatorAccessTypes: ObservableArray<string>
    observerAccess: Observable<number | undefined>
    observerAccessTypes: ObservableArray<string>
    participantsAnonymity: Observable<number | undefined>
    onlineStatus: Observable<number | undefined>
    paymentStatus: Observable<number | undefined>

    hasThumbnail: Observable<boolean>    
    hasTags: Observable<boolean>
    hasComments: Observable<boolean>
    hasAttachments: Observable<boolean>
    hasGroups: Observable<boolean>
    hasModeratorsOrObservers: Observable<boolean>
    hasLegacyAccess: Observable<boolean>

    isOpen: Observable<boolean>
    showTour: Observable<boolean>
    needsTermsAndConditions: Observable<boolean>
    showInDashboard: Observable<boolean>
    requireAPassword: Observable<boolean>

    termsAndConditions: Observable<string>
    termsAndConditionsStatements: ObservableArray<TermsAndConditionsStatement>

    visibleBefore: Observable<Date | undefined>
    visibleAfter: Observable<Date | undefined>
    createDate: Observable<Date | undefined>
    editDate: Observable<string | undefined>
    
    isSurvey = ko.pureComputed(() => this.type() == enums.DiscussionType.Survey.value);
    isExternalSurvey = ko.pureComputed(() => this.type() == enums.DiscussionType.ExternalSurvey.value);
    isHomework = ko.pureComputed(() => this.type() == enums.DiscussionType.Homework.value);

    hasIntroductionPage = ko.pureComputed(() => this.editorPagesTypes() != 0);

    observerCanPostComments = ko.pureComputed(() => observerCanCommentAccessTypes.includes(this.observerAccess()))

    constructor(jsonTask?: JSONTask) {
        this._json = jsonTask;

        this.id = ko.observable(jsonTask?.Id ?? jsonTask?.DiscussionId);
        this.accountId = ko.observable(jsonTask?.AccountId);
        this.accountDNS = ko.observable(jsonTask?.AccountDNS);
        this.referenceCode = ko.observable(jsonTask?.ReferenceCode);

        this.title = ko.observable(jsonTask?.Title ?? '');
        this.quickSell = ko.observable(jsonTask?.QuickSell ?? '');
        this.thoroughDescription = ko.observable(jsonTask?.ThoroughDescription ?? '');
        this.thoroughDescriptionSecondary = ko.observable(jsonTask?.ThoroughDescriptionSecondary ?? '');

        this.creatorId = ko.observable(jsonTask?.CreatorId);
        this.creator = ko.observable(jsonTask?.Creator);
        this.creatorImageURL = ko.observable(jsonTask?.CreatorImageURL);

        this.mediaImageCreatorId = ko.observable(jsonTask?.MediaImageCreatorId);
        this.media = ko.observable(jsonTask?.Media);
        this.mediaImageHash = ko.observable(jsonTask?.MediaImageHash);
        this.mediaType = ko.observable(jsonTask?.MediaType);
        this.contentImageType = ko.observable(jsonTask?.ContentImageType);
        this.imageURL = ko.observable(`${settings.wccApiUrl}/api/resources/taskimages/${jsonTask?.Media}`.toLowerCase());

        this.type = ko.observable(jsonTask?.Type);
        this.editorPagesTypes = ko.observable(jsonTask?.EditorPagesTypes);
        this.moderatorAccessTypes = ko.observableArray(jsonTask?.ModeratorAccessTypes?.trim().split(',') ?? []);
        this.observerAccess = ko.observable(jsonTask?.ObserverAccess);
        this.observerAccessTypes = ko.observableArray(jsonTask?.ObserverAccessTypes?.trim().split(',') ?? []);
        this.participantsAnonymity = ko.observable(jsonTask?.ParticipantsAnonymity);
        this.onlineStatus = ko.observable(jsonTask?.OnlineStatus);  
        this.paymentStatus = ko.observable(jsonTask?.PaymentStatus);

        this.hasThumbnail = ko.observable(jsonTask?.HasThumbnail ?? false);        
        this.hasTags = ko.observable(jsonTask?.HasTags ?? false);
        this.hasComments = ko.observable(jsonTask?.HasComments ?? false);
        this.hasAttachments = ko.observable(jsonTask?.HasAttachments ?? false);
        this.hasGroups = ko.observable(jsonTask?.HasGroups ?? false);
        this.hasModeratorsOrObservers = ko.observable(jsonTask?.HasModeratorsOrObservers ?? false);
        this.hasLegacyAccess = ko.observable(jsonTask?.HasLegacyAccess ?? false);

        this.isOpen = ko.observable(jsonTask?.IsOpen ?? false);
        this.showTour = ko.observable(jsonTask?.ShowTour ?? false);
        this.showInDashboard = ko.observable(jsonTask?.ShowInDashboard ?? false);
        this.requireAPassword = ko.observable(jsonTask?.RequireAPassword ?? false);

        this.needsTermsAndConditions = ko.observable(jsonTask?.NeedsTermsAndConditions ?? false);
        this.termsAndConditions = ko.observable(marked(jsonTask?.TermsAndConditions ?? ''));
        this.termsAndConditionsStatements = ko.observableArray(this.buildTermsAndConditionsStatements(jsonTask?.TermsAndConditionsStatements));

        this.visibleBefore = ko.observable(jsonTask?.VisibleBefore ? new Date(jsonTask?.VisibleBefore) : undefined);
        this.visibleAfter = ko.observable(jsonTask?.VisibleAfter ? new Date(jsonTask?.VisibleAfter) : undefined);
        this.createDate = ko.observable(jsonTask?.CreateDate ? new Date(jsonTask?.CreateDate) : undefined);
        this.editDate = ko.observable(jsonTask?.EditDate);
    }

    toJson(): JSONTask {
        return {
            Id: this.id(),
            DiscussionId: this.id(),
            AccountId: this.accountId(),
            AccountDNS: this.accountDNS(),
            ReferenceCode: this.referenceCode(),

            Title: this.title(),
            QuickSell: this.quickSell(),
            ThoroughDescription: this.thoroughDescription(),
            ThoroughDescriptionSecondary: this.thoroughDescriptionSecondary(),

            CreatorId: this.creatorId(),
            Creator: this.creator(),
            CreatorImageURL: this.creatorImageURL(),

            MediaImageCreatorId: this.mediaImageCreatorId(),
            Media: this.media(),
            MediaImageHash: this.mediaImageHash(),
            MediaType: this.mediaType(),
            ContentImageType: this.contentImageType(),

            Type: this.type(),
            EditorPagesTypes: this.editorPagesTypes(),
            ModeratorAccessTypes: this.moderatorAccessTypes().join(','),
            ObserverAccess: this.observerAccess(),
            ObserverAccessTypes: this.observerAccessTypes().join(','),
            ParticipantsAnonymity: this.participantsAnonymity(),
            OnlineStatus: this.onlineStatus(),
            PaymentStatus: this.paymentStatus(),

            HasThumbnail: this.hasThumbnail(),
            HasTags: this.hasTags(),
            HasComments: this.hasComments(),
            HasAttachments: this.hasAttachments(),
            HasGroups: this.hasGroups(),
            HasModeratorsOrObservers: this.hasModeratorsOrObservers(),

            IsOpen: this.isOpen(),
            ShowTour: this.showTour(),
            ShowInDashboard: this.showInDashboard(),
            RequireAPassword: this.requireAPassword(),

            NeedsTermsAndConditions: this.needsTermsAndConditions(),
            TermsAndConditions: this.termsAndConditions(),
            TermsAndConditionsStatements: JSON.stringify(this.termsAndConditionsStatements().map(s => s.toJson())),

            VisibleBefore: this.visibleBefore()?.toISOString(),
            VisibleAfter: this.visibleAfter()?.toISOString(),
            CreateDate: this.createDate()?.toISOString(),
            EditDate: this.editDate()
        }
    }

    update(jsonTask: JSONTask) {
        this.id(jsonTask.Id ?? jsonTask.DiscussionId);
        this.accountId(jsonTask.AccountId);
        this.accountDNS(jsonTask.AccountDNS);
        this.referenceCode(jsonTask.ReferenceCode);

        this.title(jsonTask.Title ?? '');
        this.quickSell(jsonTask.QuickSell ?? '');
        this.thoroughDescription(jsonTask.ThoroughDescription ?? '');
        this.thoroughDescriptionSecondary(jsonTask.ThoroughDescriptionSecondary ?? '');

        this.creatorId(jsonTask.CreatorId);
        this.creator(jsonTask.Creator);
        this.creatorImageURL(jsonTask.CreatorImageURL);

        this.mediaImageCreatorId(jsonTask.MediaImageCreatorId);
        this.media(jsonTask.Media);
        this.mediaImageHash(jsonTask.MediaImageHash);
        this.mediaType(jsonTask.MediaType);
        this.contentImageType(jsonTask.ContentImageType);
        this.imageURL(`${settings.wccApiUrl}/api/resources/taskimages/${jsonTask?.Media}`.toLowerCase());

        this.type(jsonTask.Type);
        this.editorPagesTypes(jsonTask.EditorPagesTypes);
        this.moderatorAccessTypes(jsonTask.ModeratorAccessTypes?.trim().split(',') ?? []);
        this.observerAccess(jsonTask.ObserverAccess);
        this.observerAccessTypes(jsonTask.ObserverAccessTypes?.trim().split(',') ?? []);
        this.participantsAnonymity(jsonTask.ParticipantsAnonymity);
        this.onlineStatus(jsonTask.OnlineStatus);
        this.paymentStatus(jsonTask.PaymentStatus);

        this.hasThumbnail(jsonTask.HasThumbnail ?? false);
        this.hasTags(jsonTask.HasTags ?? false);
        this.hasComments(jsonTask.HasComments ?? false);
        this.hasAttachments(jsonTask.HasAttachments ?? false);
        this.hasGroups(jsonTask.HasGroups ?? false);
        this.hasModeratorsOrObservers(jsonTask.HasModeratorsOrObservers ?? false);

        this.isOpen(jsonTask.IsOpen ?? false);
        this.showTour(jsonTask.ShowTour ?? false);
        this.showInDashboard(jsonTask.ShowInDashboard ?? false);
        this.requireAPassword(jsonTask.RequireAPassword ?? false);

        this.needsTermsAndConditions(jsonTask.NeedsTermsAndConditions ?? false);
        this.termsAndConditions(marked(jsonTask.TermsAndConditions ?? ''));
        this.termsAndConditionsStatements(this.buildTermsAndConditionsStatements(jsonTask.TermsAndConditionsStatements));

        this.visibleBefore(jsonTask.VisibleBefore ? new Date(jsonTask.VisibleBefore) : undefined);
        this.visibleAfter(jsonTask.VisibleAfter ? new Date(jsonTask.VisibleAfter) : undefined);
        this.createDate(jsonTask.CreateDate ? new Date(jsonTask.CreateDate) : undefined);
        this.editDate(jsonTask.EditDate);
    }

    private buildTermsAndConditionsStatements(json?: string): Array<TermsAndConditionsStatement> {
        const jsonStatements = !StringHelpers.isNullOrWhitespace(json) && json ? JSON.parse(json) as Array<JSONTermsAndConditionsStatement> : [];

        return jsonStatements.map(s => new TermsAndConditionsStatement(s));
    }
}
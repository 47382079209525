import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import events from 'managers/events';
import { JSONSimpleTopic } from 'models/simpleTopic';
import { JSONThreadVote } from 'models/threadVote';
import { JSONTopic } from 'models/topic';
import { JSONVideoPresentationEvent } from 'models/videoPresentationEvent';
import { JSONVideoPresentationProgress } from 'models/videoPresentationProgress';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {JSONCommunityPersonForAddEdit} from "../models/communityPerson";
import {apiRequestResult} from "../models/apiRequestResult";

const { sitePrefix, sitePostfix, domainUrlParam } = settings;

export class TopicsService extends BaseService {
    private repository = new Repository(endpoint);

    adminQueries = {
        simpleTopicsByDiscussionId: (discussionId: string) => this.repository.query<never, JSONSimpleTopic, never, never, never, never, never>(`admin/discussions/${discussionId}/simpleTopics`),
        simpleTopic: (topicId: string) => this.repository.query<never, JSONSimpleTopic, never, never, never, never, never>(`admin/simpleTopics/${topicId}`),
        topic: (topicId: string) => this.repository.query<never, JSONTopic, never, never, never, never, never>(`admin/topics/${topicId}`)
    }

    queries = {
        discussionTopics: (discussionId: string) => this.repository.query(`topics/${discussionId}`),
        dependedTopics: (topicId: string) => this.repository.query<never, JSONSimpleTopic, never, never, never, never, never>(`${sitePrefix}topics/${topicId}/dependedTopics`).addArgs(sitePostfix).addArgs(domainUrlParam),
        dependedTopicIds: (topicId: string) => this.repository.query(`topics/${topicId}/dependedTopics/id`),
        livegroupTopics: (livegroupId: string) => this.repository.query(`livegroups/${livegroupId}/topics`),
        livegroupActiveTopicInfo: (livegroupId: string) => this.repository.query(`livegroups/${livegroupId}/topics/active`),
        saveTopic: (discussionId: string) => this.repository.query(`discussions/${discussionId}/topics`),
        filteredSimpleTopics: (discussionId: string) => this.repository.query(`discussions/${discussionId}/simpleTopics/filtered`),
        filteredSimpleTopic: (topicId: string) => this.repository.query(`${sitePrefix}simpleTopics/${topicId}/filtered`).addArgs(sitePostfix).addArgs(domainUrlParam),
        simpleTopicsByDiscussionId: (discussionId: string) => this.repository.query(`${sitePrefix}discussions/${discussionId}/simpleTopics`).addArgs(sitePostfix).addArgs(domainUrlParam),
        simpleTopicByDiscussionId: (discussionId: string, topicId: string) => this.repository.query(`${sitePrefix}discussions/${discussionId}/simpleTopics/${topicId}`).addArgs(sitePostfix).addArgs(domainUrlParam),
        simpleTopicsByDiscussionIdForCommunity: (discussionId: string) => this.repository.query(`${sitePrefix}discussions/${discussionId}/simpleTopicsCommunity`).addArgs(sitePostfix).addArgs(domainUrlParam),
        simpleTopic: (topicId: string) => this.repository.query(`${sitePrefix}simpleTopics/${topicId}`).addArgs(sitePostfix).addArgs(domainUrlParam),
        ids: (discussionId: string) => this.repository.query(`${sitePrefix}discussions/${discussionId}/topics/id`).addArgs(sitePostfix).addArgs(domainUrlParam),
        topic: (topicId: string) => this.repository.query(`${sitePrefix}topics/${topicId}`).addArgs(sitePostfix).addArgs(domainUrlParam),
        topicCommentsVotes: (topicId: string) => this.repository.query(`topics/${topicId}/votes`),
        unreadComments: (topicId: string) => this.repository.query(`topics/${topicId}/unreadComments`),
        videoPresentationEvents: (topicId: string) => this.repository.query<never, never, JSONVideoPresentationEvent, void, never, never, never>(`topics/${topicId}/videopresentationevents`),
        videoPresentationProgress: (topicId: string) => this.repository.query<never, JSONVideoPresentationProgress | undefined, never, never, never, never, never>(`topics/${topicId}/videopresentationprogress/my`),
        externalSurvey: (topicId: string) => this.repository.query(`externalsurveys/${topicId}`),
        externalSurveyUrl: (topicId: string) => this.repository.query(`externalsurveys/${topicId}/url`),
        externalSurveyShort: (topicId: string) => this.repository.query(`externalsurveys/${topicId}/short`),
        mandatoryProbes: (topicId: string) => this.repository.query(`topics/${topicId}/mandatoryProbes`),
        ideationStage: (topicId: string) => this.repository.query<never, never, never, never, number, void, never>(`ideation/${topicId}/stage`),
        votes: (topicId: string) => this.repository.query<never, JSONThreadVote, never, never, never, never, never>(`topics/${topicId}/votes`),
        loadParticipantWithScreenerDataInTasks: (discussionId: string) => this.repository.query<never, apiRequestResult<JSONCommunityPersonForAddEdit>>(`discussions/${discussionId}/screeners/me/data`)
    }

    subscriptions = {
        discussionTopic: this.signalRSubscription('DiscussionTopic'),
        discussionTopicNotifications: this.signalRSubscription('DiscussionTopicNotifications'),
        addTopicProgress: this.signalRSubscription('AddTopicProgress')
    }

    events = {
        activeTopicChanged: this.signalREvent('ActiveTopicChanged'),
        newTopic: this.signalREvent('NewTopic'),
        topicChanged: this.signalREvent('TopicChanged'),
        topicDeleted: this.signalREvent('TopicDeleted'),
        addTopicProgressNotification: this.signalREvent('addtopicprogressnotification')
    }

    /**
    * Returns simple topics by discussionId. supports oData
    */
    getSimpleTopicsByDiscussionId(discussionId: string, oData: URLQueryData) {
        return this.queries.simpleTopicsByDiscussionId(discussionId).addArgs(oData).toArray();
    }

    /**
     * Returns first simple topic in discussion. supports oData
     */
    getSimpleTopicByDiscussionId(discussionId: string, topicId: string, oData: URLQueryData) {
        return this.queries.simpleTopicByDiscussionId(discussionId, topicId).addArgs(oData).firstOrDefault();
    }

    /**
    * Returns simple topics by discussionId. supports oData
    */
    getSimpleTopicsByDiscussionIdForAdmin(discussionId: string, oData: URLQueryData) {
        return this.adminQueries.simpleTopicsByDiscussionId(discussionId).addArgs(oData).toArray();
    }

    /**
     * Returns topic by topicId.
     */
    getTopic(topicId: string, oData: URLQueryData) {
        return this.queries.topic(topicId).addArgs(oData).firstOrDefault();
    }

    /**
     * Marks topic all comments as readed.
     */
    markAllCommentsAsRead(topicId: string) {
        return (this.queries.unreadComments(topicId).important().remove() as JQuery.Promise<void>)
            .done(() => events.commentsNotificationsRemoved.trigger(topicId));
    }

    /**
     * Returns depended topics by topicId.
     */
    getDependedTopics(topicId: string) {
        return this.queries.dependedTopics(topicId).toArray();
    }

    /**
     * Returns depended topics ids by topicId.
     */
    getDependedTopicIds(topicId: string) {
        return this.queries.dependedTopicIds(topicId).toArray();
    }

    /**
     * Returns livegroup topics. Supports oData
     */
    getLivegroupTopics(livegroupId: string, oData: URLQueryData) {
        return this.queries.livegroupTopics(livegroupId).addArgs(oData).toArray();
    }

    /**
     * Returns livegroup active topic info.
     */
    getLivegroupActiveTopicInfo(livegroupId: string) {
        return this.queries.livegroupActiveTopicInfo(livegroupId).firstOrDefault();
    }

    /**
     * Sets livegroup active topic info.
     */
    setLivegroupActiveTopicInfo(livegroupId: string, info: any) {
        return this.queries.livegroupActiveTopicInfo(livegroupId).add(info);
    }

    /**
     * Returns votes for topic comment.
     */
    getTopicCommentsVotes(topicId: string) {
        return this.queries.topicCommentsVotes(topicId).toArray();
    }

    /**
     * Sends VideoPresentation even data to server
     */
    async handleVideoPresentationEvent(topicId: string, eventData: JSONVideoPresentationEvent) {
        await this.queries.videoPresentationEvents(topicId).add(eventData);
        events.videoPresentationEvent.trigger(topicId, eventData);
    }

    /**
     * Returns VideoPresentation progress for current user
     */
    getVideoPresentationProgress(topicId: string) {
        return this.queries.videoPresentationProgress(topicId).firstOrDefault();
    }

    /**
     * Creates new topic or updates an existing one
     */
    saveTopic(discussionId: string, topicData: JSONTopic) {
        return this.queries.saveTopic(discussionId).add(topicData);
    } 

    async setIdeationStage(topicId: string, stage: number) {
        await this.queries.ideationStage(topicId).update(stage);

        events.ideationStageChanged.trigger(topicId, stage);
    }

    async loadParticipantWithScreenerDataInTasks(discussionId: string) {
        return await this.queries.loadParticipantWithScreenerDataInTasks(discussionId).firstOrDefault();
    }
}
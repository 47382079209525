import { BlobWrapper } from 'interfaces/blobWrapper';
import WCCFile from 'models/attachments/file';
import type WCCUpload from 'models/upload';
import WCCUploader from '../uploader';
import transportFactory from './transportFactory';
import { WCCS3UploadResult } from './uploadResult';

export interface WCCS3FileUploaderConfig {
    attachmentType?: number
    allowMultiple?: boolean
    allowedExtensions?: Array<string>
    fileSizeLimit?: number
}

export default class WCCS3FileUploader extends WCCUploader<WCCFile, WCCS3UploadResult> {
    private attachmentType?: number

    constructor(config?: WCCS3FileUploaderConfig) {
        super({
            transport: transportFactory.getTransport({
                type: 'file',

                allowMultiple: config?.allowMultiple ?? true,
                allowedExtensions: config?.allowedExtensions,
                fileSizeLimit: config?.fileSizeLimit
            }),

            allowedExtensions: config?.allowedExtensions
        });

        this.attachmentType = config?.attachmentType;
    }

    protected getResult(uploadResult: WCCS3UploadResult, upload: WCCUpload, fileOrBlob: File | BlobWrapper) {
        if (settings.v2Attachments)
            return new WCCFile({
                FileType: this.attachmentType ?? enums.AttachmentTypes.File.value,
                CreateDate: new Date().toISOString(),

                Files: [
                    {
                        UserFileId: uploadResult.fileId,
                        Name: fileOrBlob.name,
                        Extension: system.getExtension(fileOrBlob.name),
                        Type: enums.UserFileTypes.File.value,
                        Link: uploadResult.link,
                        CreateDate: new Date().toISOString()
                    }
                ]
            });

        return new WCCFile({
            UserFileId: uploadResult.fileId,
            FileType: this.attachmentType ?? enums.AttachmentTypes.File.value,
            CDNFileName: uploadResult.key,
            Link: uploadResult.link,
            CreateDate: new Date().toISOString()
        });
    }
}
import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyStatisticsQuestionAnswer {
    ProfileQuestionChoiceId: string
}

export default class SurveyStatisticsQuestionAnswer<A extends JSONSurveyStatisticsQuestionAnswer = JSONSurveyStatisticsQuestionAnswer> extends WCCEntity<A> {
    questionChoiceId: Observable<string>

    constructor(data: A) {
        super();

        this.questionChoiceId = this.createField(<JSONSurveyStatisticsQuestionAnswer>data, 'ProfileQuestionChoiceId');
    }
}
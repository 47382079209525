import { Observable, Subscribable } from "knockout";

export interface IdleResetContainerConfig<T> {
    defaultValue: T
    resetDelay: number
}

export default class IdleResetContainer<T> {
    private _value: Observable<T>

    private resetDelay: number
    private defaultValue: T

    private timeout?: NodeJS.Timeout

    value: Subscribable<T>

    constructor(config: IdleResetContainerConfig<T>) {
        this._value = ko.observable(config.defaultValue);
        this.value = this._value.readOnly();

        this.resetDelay = config.resetDelay;
        this.defaultValue = config.defaultValue;
    }

    setValue(value: T) {
        this._value(value);

        if (this.timeout != undefined)
            clearTimeout(this.timeout);

        this.timeout = setTimeout(() => this._value(this.defaultValue), this.resetDelay);
    }
}
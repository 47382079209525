import { StagingToolsVMConfig } from 'components/staging/tools/tools';
import app from 'durandal/app';
import binder from 'durandal/binder';
import system from 'durandal/system';
import { wccModules } from 'enums/wccModules';
import { SystemHelpers } from 'helpers/system';
import 'ko-components';
import 'libraries/core.tasks';
import overlayManager from 'managers/overlay';
import SignalREventsManager from 'managers/signalR/events';
import router from 'plugins/router';
import { extendedAppContainer } from './configuration/inversify/container/extended';

system.debug(true);

app.title = WCC.getSetting('SiteTitle', 'WCCTasks');

window.HELP_IMPROVE_VIDEOJS = false;
window.WCC = WCC;
(<any>window)['$' + ''] = $;
window.ko = ko;
window._ = _;
window.moment = moment;

async function startApp() {
    binder.throwOnErrors = true;
    router.install();

    await app.start();
    const shell = SystemHelpers.getDefaultIfES6(await getShell());

    app.setRoot(shell);

    if (settings.isStaging)
        setupStagingTools();

    if (settings.disableContextMenu)
        disableContextMenu();

    if (settings.proxyTaskResources) {
        const { proxyLinksRewriter } = await import('managers/proxyLinksRewriter');
        proxyLinksRewriter.processAndWatch(document);
    }
}

function getShell(): Promise<any> {
    if (settings.isDashboard)
        return import('shells/dashboard');

    if (settings.isPolicy)
        return import('shells/policy');

    if (settings.isUnsubscribePage)
        return import('shells/unsubscribe');

    if (settings.launchCustomShell)
        return import('shells/custom/custom');

    switch (settings.discussionType) {
        case enums.DiscussionType.Community.value: return import('shells/community');
        case enums.DiscussionType.LiveGroup.value: return import('shells/livegroup');
        case enums.DiscussionType.VideoIDI.value: return import('shells/videoIDI');
        default: return import('shells/discussion');
    }
}

function setupStagingTools() {
    components.preload('staging-tools');

    if (settings.discussionId != undefined) {
        const signalREvents = <SignalREventsManager>extendedAppContainer.get(wccModules.signalREvents);

        signalREvents.staging(settings.discussionId)
            .onReset(() => location.reload());
    }

    $(document).ready(() => {
        const node = document.getElementById('page-staging-tools');

        if (node != undefined) {
            ko.applyBindingsToNode(node, {
                'click': () => {
                    overlayManager.toOverlay('<staging-tools params="model: $data" />', {
                        data: stage => (<StagingToolsVMConfig>{
                            close: () => stage.close()
                        }),

                        lock: true
                    });
                }
            });
}
    });    
}

function disableContextMenu() {
    document.addEventListener('contextmenu', e => {
        const $target = $(<HTMLElement>e.target);
        const isAllowed = $target.is('p') && $target.closest('.ql-editor').length > 0;

        if (!isAllowed)
            e.preventDefault();
    });
}

startApp();
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class EngagementService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {};

        this.subscriptions = {
            engagement: this.signalRSubscription('Engagement')
        };

        this.events = {
            dashboardStatUpdated: this.signalREvent('EngagementDashboardStatUpdated')
        };
    }
}
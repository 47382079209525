import { injectable } from "inversify";
import { Func } from "../../../interfaces/func";
import { TimelineDataSource } from "../timeline";

@injectable()
export default abstract class TimelineDataSourceOwner<V, D = any, K = any> {
    protected abstract source: TimelineDataSource<V, D, K>

    constructor() { }

    load(from: Date, to: Date) {
        return this.source.load(from, to);
    }

    get(from: Date, to: Date) {
        return this.source.get(from, to);
    }

    any(from: Date, to: Date, predicate?: Func<boolean, [item: V]>) {
        return this.source.any(from, to, predicate);
    }
}
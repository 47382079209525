import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { PureComputed, Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import DiscussionActiveTopicManager from 'managers/discussion/activeTopic';
import SimpleTopicsManager from 'managers/discussion/simpleTopics';
import events from 'managers/events';
import { IWCCStorageManager } from 'managers/iStorage';
import TaskManager from 'managers/task';
import { withEffect } from 'mixins/withEffect';
import { EffectsContainer } from 'mixins/withEffects';
import { TopicThread } from 'models/topicThread';
import { isAvailableTopic } from '../helpers/topicsAccess';
import LCRedirect from '../models/redirect';

const { topicRedirectDuration, discussionId, now, isStaging } = settings;

export interface LCStateManagerConfig {
    taskId: string
}

@injectable()
@storageDefaults(<Partial<LCStateManagerConfig>>{ taskId: discussionId })
export default class LCStateManager {
    private isModerator: Subscribable<boolean>

    selectedTopicId = ko.observable<string>();
    redirect = ko.observable<LCRedirect>();

    postOnEnter = ko.observable(true);
    checkedIn = ko.observable(false);
    
    messageToEdit: PureComputed<TopicThread | undefined>
    messageToReply: PureComputed<TopicThread | undefined>

    openDate: Subscribable<Date | undefined>
    closeDate: Subscribable<Date | undefined>

    checkInDate: Subscribable<Date | undefined>

    logOutDate: Subscribable<Date | undefined>
    logOutSoonDate: Subscribable<Date | undefined>

    ready: Subscribable<boolean>
    isOpen: Subscribable<boolean>
    isClosed: Subscribable<boolean>
    isAwaiting: Subscribable<boolean>
    readyForLogOut: Subscribable<boolean>

    constructor(
        @inject(wccModules.managerConfig) { taskId }: LCStateManagerConfig,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const oMessageToEdit = ko.observable<TopicThread>();
        const oMessageToReply = ko.observable<TopicThread>();

        const userManager = storage.get(CurrentUserManager, {});
        const user = userManager.pluck(m => m.person);
        const userReady = user.isNotNull();
        this.isModerator = user.pluck('isModerator', false);

        const taskManager = storage.get(TaskManager, { taskId });
        const taskReady = taskManager.pluck(m => !m.loading(), false);
        const task = taskManager.pluck(m => m.task);

        const topicsManager = storage.get(SimpleTopicsManager, {});
        const topicsReady = topicsManager.pluck(m => !m.loading(), false);
        const topics = topicsManager.pluck(m => m.topics, []);
        const availableTopics = topics.filter(t => isAvailableTopic(t));

        const activeTopicManager = storage.get(DiscussionActiveTopicManager, {});
        const activeTopicReady = activeTopicManager.pluck(m => !m.loading(), false);
        const activeTopicId = activeTopicManager.pluck(m => m.topicId);

        this.messageToEdit = ko.pureComputed({
            read: oMessageToEdit,
            write: value => {
                if (value != undefined)
                    oMessageToReply(undefined);

                oMessageToEdit(value);
            }
        });

        this.messageToReply = ko.pureComputed({
            read: oMessageToReply,
            write: value => {
                if (value != undefined)
                    oMessageToEdit(undefined);

                oMessageToReply(value);
            }
        });     

        const openDate = task.pluck(t => t.visibleAfter());
        this.openDate = ko.pureComputed(() => isStaging ? undefined : openDate());

        const closeDate = task.pluck(t => t.visibleBefore());
        this.closeDate = ko.pureComputed(() => isStaging ? undefined : closeDate());

        this.checkInDate = this.openDate.pluck(d => moment(d).add(-settings.liveGroupCheckInTime, 'minutes').toDate());

        this.logOutDate = this.closeDate.pluck(d => moment(d).add(settings.liveChatGlacePeriod, 'minutes').toDate());
        this.logOutSoonDate = this.logOutDate.pluck(d => moment(d).add(-5, 'minutes').toDate());

        const isOpen = ko.strictComputed((now, openDate, closeDate) => {
            return openDate != undefined && closeDate != undefined && now > openDate && now < closeDate;
        }, [now, this.openDate, this.closeDate]);

        this.isOpen = ko.pureComputed(() => isStaging ? true : isOpen());

        this.isClosed = this.closeDate.pluck(closeDate => now() > closeDate, false);
        this.isAwaiting = this.openDate.pluck(openDate => now() < openDate, false);
        this.readyForLogOut = this.logOutDate.pluck(logOutDate => now() > logOutDate, false);  

        this.ready = ko.pureComputed(() => userReady() && taskReady() && topicsReady() && activeTopicReady());

        effects.register([
            events.liveChatCheckInStateChanged.on(checkedIn => this.checkedIn(checkedIn))
        ]);

        effects.register(ko.computed(() => {
            const message = oMessageToEdit();

            if (message != undefined && message.isDeleted())
                oMessageToEdit(undefined);
        }));

        effects.register(ko.computed(function () {
            const message = oMessageToReply();

            if (message != undefined && message.isDeleted())
                oMessageToReply(undefined);
        }));

        effects.register(isReady => {
            if (isReady) {
                return [
                    withEffect(topicsManager => {
                        if (topicsManager != undefined) {
                            return this.isOpen.subscribe(isOpen => {
                                if (isOpen)
                                    topicsManager.update();
                            })
                        }
                    }, [topicsManager]),                   

                    withEffect(activeTopicId => {
                        if (activeTopicId != undefined)
                            this.selectTopic(activeTopicId);
                    }, [activeTopicId]),

                    withEffect((selectedTopicId, topics) => {
                        if (selectedTopicId == undefined && topics.length > 0)
                            this.selectedTopicId(topics[0].id());
                    }, [this.selectedTopicId, availableTopics])             
                ]
            }
        }, [this.ready]);
    }    

    private selectTopic(topicId: string) {
        this.redirect()?.cancel();

        if (this.selectedTopicId() != topicId) {
            if (this.isModerator() || this.selectedTopicId() == undefined)
                this.selectedTopicId(topicId);
            else
                this.redirect(new LCRedirect(topicRedirectDuration, () => this.selectedTopicId(topicId)));
        }
    }
}
import { IOverlayVM } from 'components/overlay/overlay.interfaces';
import { IOverlayStageVM, OverlayStageVMOptions, OverlayStageVMTemplateConfig } from 'components/overlay/overlayStage.interfaces';
import { Subscribable } from 'knockout';
import { withEffects } from 'mixins/withEffects';
import { IOverlayManager } from './overlay.interfaces';

components.preload('overlay');

export class OverlayManager implements IOverlayManager {
    private effects = withEffects();
    private overlay = ko.observable<IOverlayVM | undefined>();

    private isRequired = ko.observable(false);

    isActive: Subscribable<boolean>

    constructor() {
        this.isActive = this.overlay.pluck(overlay => overlay.isActive, false);

        $(document).ready(() => {
            const $body = $("body");

            this.effects.register(isRequired => {
                if (isRequired) {
                    const node = this.getNode();
                    $body.append(node);

                    return () => ko.removeNode(node);
                }
            }, [this.isRequired]);
        });
    }

    async toOverlay<T>(template: string | OverlayStageVMTemplateConfig, options?: OverlayStageVMOptions<T>): Promise<IOverlayStageVM<T>> {
        this.isRequired(true);

        const overlay = await this.overlay.whenNotNull();
        const stage = overlay.addStage({ ...options, template });

        return stage;
    }

    reset() {
        this.overlay()?.clear();
    }

    private getNode() {
        let node = $('<overlay params="model: model, ref: ref"></overlay')[0];
        ko.applyBindings({ model: {}, ref: this.overlay }, node);

        return node;
    }
}

const overlayManager = new OverlayManager();

export default overlayManager;
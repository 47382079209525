import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyChoiceOptions {
    ProfileQuestionChoicesOptionsId: string
    SortType: number
    DisplayLayoutType: number
    SingleChoiceControlType?: number
    MultipleChoiceControlType?: number
    SliderChoiceLabelType?: number
    ImageSelectorCaptionType?: number
    ChoiceRatingType?: number
    ChoiceSelectionLimitMode: number
    MinChoices: number
    MaxChoices: number
    RankingMainListTitle?: string
    RankingRankedListTitle?: string
    SliderMinValueLabel?: string
    SliderMaxValueLabel?: string
    ChoiceVisibilityTagModeType: number
}

export default class SurveyChoiceOptions extends WCCEntity<JSONSurveyChoiceOptions> {
    id: Observable<string | undefined>
    sortType: Observable<number | undefined>
    displayLayoutType: Observable<number | undefined>
    singleChoiceControlType: Observable<number | undefined>
    multipleChoiceControlType: Observable<number | undefined>
    sliderChoiceLabelType: Observable<number | undefined>
    imageSelectorCaptionType: Observable<number | undefined>
    choiceRatingType: Observable<number | undefined>
    minChoices: Observable<number | undefined>
    maxChoices: Observable<number | undefined>
    choiceSelectionLimitMode: Observable<number | undefined>
    mainListTitle: Observable<string | undefined>
    rankedListTitle: Observable<string | undefined>
    sliderMinValueLabel: Observable<string | undefined>
    sliderMaxValueLabel: Observable<string | undefined>
    choiceVisibilityTagModeType: Observable<number | undefined>
    
    constructor(data?: JSONSurveyChoiceOptions) {
        super();

        this.id = this.createField(data, 'ProfileQuestionChoicesOptionsId');
        this.sortType = this.createField(data, 'SortType');
        this.displayLayoutType = this.createField(data, 'DisplayLayoutType');
        this.singleChoiceControlType = this.createField(data, 'SingleChoiceControlType');
        this.multipleChoiceControlType = this.createField(data, 'MultipleChoiceControlType');
        this.sliderChoiceLabelType = this.createField(data, 'SliderChoiceLabelType');
        this.imageSelectorCaptionType = this.createField(data, 'ImageSelectorCaptionType');
        this.choiceRatingType = this.createField(data, 'ChoiceRatingType');
        this.choiceSelectionLimitMode = this.createField(data, 'ChoiceSelectionLimitMode');
        this.minChoices = this.createField(data, 'MinChoices');
        this.maxChoices = this.createField(data, 'MaxChoices');
        this.mainListTitle = this.createField(data, 'RankingMainListTitle');
        this.rankedListTitle = this.createField(data, 'RankingRankedListTitle');
        this.sliderMinValueLabel = this.createField(data, 'SliderMinValueLabel');
        this.sliderMaxValueLabel = this.createField(data, 'SliderMaxValueLabel');
        this.choiceVisibilityTagModeType = this.createField(data, 'ChoiceVisibilityTagModeType');
    }
}
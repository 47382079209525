import { Observable, ObservableArray } from 'knockout';
import SurveyStatisticsChoiceQuestionAnswer, { JSONSurveyStatisticsChoiceQuestionAnswer } from './choiceAnswer';
import SurveyStatisticsQuestion, { JSONSurveyStatisticsQuestion } from './question';

export interface JSONSurveyStatisticsChoiceQuestion extends JSONSurveyStatisticsQuestion {
    RespondentsCount?: number

    ProfileAnswers: Array<JSONSurveyStatisticsChoiceQuestionAnswer>
}

export default class SurveyStatisticsChoiceQuestion extends SurveyStatisticsQuestion<SurveyStatisticsChoiceQuestionAnswer, JSONSurveyStatisticsChoiceQuestion> {
    respondentsCount: Observable<number>

    answers: ObservableArray<SurveyStatisticsChoiceQuestionAnswer>

    constructor(data: JSONSurveyStatisticsChoiceQuestion) {
        super(data);

        this.respondentsCount = this.createField(data, 'RespondentsCount', 0);

        this.answers = this.createCollection(data, 'ProfileAnswers', {
            key: 'questionChoiceId',
            map: jsonAnswer => new SurveyStatisticsChoiceQuestionAnswer(jsonAnswer),
            mapReverse: answer => answer.toJson(),
            update: (answer, newAnswer) => answer.update(newAnswer.toJson())
        });
    }
}
import { SignalRHelpers } from "../helpers";

export default class SignalREventCompositeHandler {
    actions = ko.observableArray<Action<Array<any>>>();

    constructor(private name: string) { }

    handler = (...args: Array<any>) => {
        SignalRHelpers.log('received', this.name, args, [...this.actions()]);

        this.actions().forEach(action => action(...args));
    }
}
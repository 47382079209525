import storageDefaults from 'decorators/storageDefaults';
import { MessagesView } from 'enums/messages/views';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { ObservableArray, Subscribable } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CurrentUserManager } from 'managers/currentUser';
import { CollectionDataSource, LoadRequest } from 'managers/datasources/collection';
import events from 'managers/events';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { JSONChatMessage, TopicThread as ChatMessage, TopicThread } from 'models/topicThread';
import { CommentsService } from 'services/comments';

const { discussionId } = settings;

export interface TopicMessagesManagerConfig {
    discussionId: string
    topicId: string
    view: MessagesView
}

@injectable()
@storageDefaults(<Partial<TopicMessagesManagerConfig>>{ discussionId, view: MessagesView.all })
export default class TopicMessagesManager extends CollectionDataSourceOwner<ChatMessage, 'id'> {
    private discussionId: string
    private topicId: string
    private view: MessagesView

    private allMessages: ObservableArray<TopicThread>
    private realMessages: Subscribable<Array<TopicThread>>
    private deletedMessagesIds = ko.observableArray<string>();

    protected source: CollectionDataSource<ChatMessage, 'id'>

    private areAllMessagesLoadedInternal = ko.observable(false);

    requireTranslation = ko.observable(false);

    messages: Subscribable<Array<TopicThread>>

    areAllMessagesLoaded: Subscribable<boolean>

    constructor(
        @inject(wccModules.managerConfig) config: TopicMessagesManagerConfig,
        @inject(wccModules.commentsService) private commentsService: CommentsService,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.discussionId = config.discussionId;
        this.topicId = config.topicId;
        this.view = config.view;

        const userManager = storage.get(CurrentUserManager, { discussionId: this.discussionId });
        const user = userManager.pluck(m => m.person);
        const userId = user.pluck(u => u.personId);

        this.source = effects.register(new CollectionDataSource<ChatMessage, 'id'>({
            key: 'id',
            load: this.loadMessages.bind(this),
            mapper: jsonMessage => new ChatMessage(jsonMessage),
            merge: (oldMessage, newMessage) => oldMessage.update(newMessage.toJson()),
            isDeferred: true
        }));

        this.allMessages = this.source.list;

        this.realMessages = this.allMessages.filter(message => message.id() != undefined && !message.isDeleted());

        this.messages = this.allMessages.filter(message => {
            return !this.requireTranslation() ||
                message.translatedContent().length > 0 ||
                message.postedById() === userId();
        });

        this.areAllMessagesLoaded = this.areAllMessagesLoadedInternal;

        effects.register(messages => {
            messages.forEach(message => {
                const replies = messages.filter(reply => reply.parentId() != null && reply.parentId() === message.id());
                message.replies(replies);
            });

            messages.forEach(message => {
                const parent = messages.find(parent => message.parentId() != null && parent.id() === message.parentId());

                if (message.parent() != parent)
                    message.parent(parent);
            });
        }, [this.allMessages]);

        effects.register((messages, ids) => {
            messages.forEach(message => {
                if (!message.isDeleted() && ids.includes(<string>message.id()))
                    this.markAsRemoved(message);
            });
        }, [this.allMessages, this.deletedMessagesIds]);

        effects.register([
            events.chatMessageAdded.on(this.onNewLocalMessage.bind(this)),
            events.chatMessageUpdated.on(this.onLocalMessageUpdated.bind(this)),
            events.deletingThread.on(this.onDeletingLocalThread.bind(this)),
            events.failedToDeleteThread.on(this.onFailedToDeleteLocalThread.bind(this)),
            events.threadDeleted.on(this.onLocalThreadDeleted.bind(this)),
            events.commentNotificationsRemoved.on(this.onLocalThreadNotificationsRemoved.bind(this)),
        ]);

        signalREvents.chatMessages(this.topicId)
            .onNewMessage(this.onMessage.bind(this))
            .onMessageUpdated(this.onMessage.bind(this))
            .onMessageDeleted(this.onMessageDeleted.bind(this))
            .onSubscriptionActivated(() => this.reset());

        signalREvents.topicThreads(this.topicId)
            .onThreadDeleted(this.onThreadDeleted.bind(this));
    }

    loadMore() {
        if (!this.areAllMessagesLoadedInternal())
            this.source.load(true);
    }

    private reset() {
        this.areAllMessagesLoadedInternal(false);
        this.source.reset();
    }

    private async loadMessages(request: LoadRequest) {
        if (this.areAllMessagesLoadedInternal())
            return [];

        const args = <StringMap<string | number | boolean | undefined>>{
            'view': this.getViewName(this.view)
        }

        if (request.loadMore) {
            const from = this.realMessages().reduce((result, message) =>
                result == undefined || message.createDate() < result ? message.createDate() : result, <Date | undefined>undefined);

            args['from'] = from?.toISOString();
        }

        const jsonMessages = await this.commentsService
            .queries
            .topicChatMessages(this.topicId)
            .addArgs(args)
            .toArray();

        const newJsonMessages = jsonMessages.filter(jsonMessage => !this.allMessages().some(m => m.id() == jsonMessage.TopicThreadId));

        if (newJsonMessages.length == 0)
            this.areAllMessagesLoadedInternal(true);

        return jsonMessages;
    }

    private getViewName(view: MessagesView) {
        switch (view) {
            case MessagesView.mine: return 'mine';
            default: return undefined
        }
    }

    private markAsRemoved(message: TopicThread) {
        this.collect(message).forEach(m => m.isDeleted(true));
    }

    private collect(message: TopicThread): Array<TopicThread> {
        return [message].concat(message.replies().flatMap(r => this.collect(r)));
    }

    private onNewLocalMessage(topicId: string, jsonMessage: JSONChatMessage) {
        if (this.topicId == topicId)
            this.source.findSync(jsonMessage.TopicThreadId, _.noop, () => {
                this.source.add(jsonMessage);
                this.allMessages().forEach(message => message.isInToDoList(false));
            });
    }

    private onLocalMessageUpdated(topicId: string, messageId: string, jsonMessage: JSONChatMessage) {
        if (this.topicId == topicId)
            this.source.findSync(messageId, message => message.update(jsonMessage));
    }

    private onDeletingLocalThread(topicId: string, threadId: string) {
        this.source.findSync(threadId, message => message.isDeleting(true));
    }

    private onFailedToDeleteLocalThread(topicId: string, threadId: string) {
        this.onFinishedDeletingLocalThread(threadId);
    }

    private onLocalThreadDeleted(topicId: string, postedById: string, threadId: string) {
        this.onFinishedDeletingLocalThread(threadId);
        this.source.findSync(threadId, message => message.isDeleted(true));
    }

    private onFinishedDeletingLocalThread(threadId: string) {
        this.source.findSync(threadId, message => message.isDeleting(false));
    }

    private onMessage(discussionId: string, topicId: string, jsonMessage: JSONChatMessage, isTranslated: boolean, isStaging: boolean) {
        if (topicId == this.topicId && isStaging == settings.isStaging) {
            const existingMessage = this.allMessages().find(m => m.id() == jsonMessage.TopicThreadId);

            if (existingMessage)
                existingMessage.update(jsonMessage);
            else
                this.source.add(jsonMessage);
        }
    }

    private onMessageDeleted(discussionId: string, topicId: string, messageId: string, isStaging: boolean) {
        if (topicId == this.topicId && isStaging == settings.isStaging && !this.deletedMessagesIds().includes(messageId))
            this.deletedMessagesIds.push(messageId);
    }

    private onThreadDeleted(threadId: string, parentThreadId: string, personId: string, topicId: string) {
        if (!this.deletedMessagesIds().includes(threadId))
            this.deletedMessagesIds.push(threadId);
    }

    private onLocalThreadNotificationsRemoved(topicId: string, threadId: string) {
        if (this.topicId === topicId) {
            this.allMessages().forEach(message => {
                if (message.id() === threadId || message.parentId() === threadId)
                    message.isInToDoList(false);
            });
        }
    }
}
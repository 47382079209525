import Bluebird from 'bluebird';
import { APIHelpers as api } from 'helpers/api';
import { isOldIE } from 'helpers/browser';
import { NotificationsHelpers } from 'helpers/notifications';
import { ajaxRequest } from 'helpers/requests';
import { getSetting, settings as Settings } from 'helpers/settings';
import { StringHelpers } from 'helpers/string';
import { Func } from 'interfaces/func';
import ko, { Observable } from 'knockout';
import WCCError from 'models/wccError';
import cdnFileNamePattern from 'patterns/cdnFileName';
import emailPattern from 'patterns/email';
import extensionPattern from 'patterns/extension';
import guidPattern from 'patterns/guid';
import { CookieHelpers } from './cookie';
import videojs from "video.js";
import {MimeTypeData} from "../models/mimeTypeData";

export module SystemHelpers {
    import trigger = videojs.trigger;
    export const getCookie = CookieHelpers.getCookie;
    export const setCookie = CookieHelpers.setCookie;
    export const deleteCookie = CookieHelpers.deleteCookie;

    export const message = NotificationsHelpers.message;
    export const showInfoMessage = NotificationsHelpers.showInfoMessage;
    export const showSuccessMessage = NotificationsHelpers.showSuccessMessage;
    export const showErrorMessage = NotificationsHelpers.showErrorMessage;
    export const showMessage = NotificationsHelpers.showMessage;
    export const showFailedRequestMessage = NotificationsHelpers.showFailedRequestMessage;
    export const setMessage = NotificationsHelpers.setMessage;

    export const isNullOrEmpty = StringHelpers.isNullOrEmpty;
    export const isNotNullOrEmpty = StringHelpers.isNotNullOrEmpty;
    export const isNullOrWhitespace = StringHelpers.isNullOrWhitespace;
    export const toFirstCharUpperString = StringHelpers.toFirstCharUpperString;
    export const toFirstCharLowerString = StringHelpers.toFirstCharLowerString;
    export const trimLeft = StringHelpers.trimLeft;
    export const cleanString = StringHelpers.cleanString;
    export const escapeStringForRegEx = StringHelpers.escapeStringForRegEx;
    export const endsWith = StringHelpers.endsWith;

    const base64Encoder = require('instance!models/base64Encoder');

    const gamma = 2.2;

    // Reference to system message window
    export let confirmDialog: JQuery | undefined;
    export let alertDialog: JQuery | undefined;
    export let inputDialog: JQuery | undefined;
    export let pageInitTime = new Date();
    export let serverCurrentTime = new Date(getSetting('serverCurrentTime') ?? getSetting('ServerCurrentTime'));
    export let chipchampDisabled = false;

    function toUrlWithParams(url: string) {
        return url.indexOf('?') === -1 ? url + '?' : url;
    }

    function getUrlParamPrefix(url: string) {
        return url.indexOf('?') != -1 ? (StringHelpers.endsWith(url, '?') ? '' : '&') : '?';
    }

    export function setServerCurrentTime(value: Date) {
        serverCurrentTime = value;
    }

    export function setPageInitTime(value: Date) {
        pageInitTime = value;
    }

    export function enableClipChamp(enable: boolean) {
        chipchampDisabled = !enable;
    }

    export function handleError(err: any, allowRegularErrors = false) {
        if (err && err.isCanceled)
            return;

        if (err && err.responseText) {
            NotificationsHelpers.showErrorMessage(err.responseText);
        } else if (err instanceof WCCError || allowRegularErrors && err instanceof Error) {
            NotificationsHelpers.showErrorMessage(err.message);
        } else {
            NotificationsHelpers.showErrorMessage(messages.AnErrorOccuredDuringYourRequest);
        }

        console.error(err);
    }
    
    export function printObjectFields(o: object) {
        _.each(o, (field: any, key) => console.log(key, typeof field == "function" ? ko.unwrap(field()) : field));
    }

    export function toUICallback<T>(func: T): T;

    export function toUICallback(func: (...args: Array<any>) => void): (...args: Array<any>) => void {
        let task: number | undefined;

        return (...args) => {
            if (task == undefined)
                task = requestAnimationFrame(() => {
                    func(...args);
                    task = undefined;
                });
        }
    }

    export function lazy<T>(factory: Func<T>) {
        let cachedValue: T | undefined;

        return () => cachedValue ??= factory();
    }

    export function lazyAsync<T>(factory: Func<Promise<T>>) {
        let cachedValue: T | undefined;

        return async () => cachedValue ??= await factory()
    }    

    export function sortWithPinned<T>(items: Array<T>, sortFunc: Func<number, [T, T]>, pinnedExtractor: Func<boolean, [T]>) {
        const sortedItems = [...items].sort(sortFunc);
        const pinnedItems = items.filter(pinnedExtractor);
        const sortedItemsWithoutPinned = sortedItems.filter(item => !pinnedExtractor(item));

        if (pinnedItems.length > 0) {
            //insert pinned items into sorted list at original positions
            return pinnedItems.reduce((result, pinnedItem) => {
                const pinnedItemIdx = items.indexOf(pinnedItem);
                result.splice(pinnedItemIdx, 0, pinnedItem);

                return result;
            }, sortedItemsWithoutPinned);
        }

        return sortedItems;
    }

    /**
     * shuffles array using user token as seed generator
     * @param array
     * @param token
     */
    export function shuffleArrayUsingToken<T>(array: Array<T>, token: string) {
        const result = [...array];

        let seed = Array.from(token)
            .map(x => x.charCodeAt(0))
            .reduce((a, b) => a + b, 1);

        let currentIndex = result.length,
            temporaryValue, randomIndex;

        seed = seed || 1;

        function random() {
            var x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        }

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = result[currentIndex];
            result[currentIndex] = result[randomIndex];
            result[randomIndex] = temporaryValue;
        }

        return result;
    }

    interface RandomizationGroup<T> {
        items: Array<T>
        isPinned: boolean
    }

    export function shuffleArrayUsingTokenWithPinned<T>(array: Array<T>, token: string, pinnedExtractor: Func<boolean, [T]>) {
        const randomizationGroups = array.reduce((result, item) => {
            let lastGroup = result[result.length - 1];
            const isPinned = pinnedExtractor(item);

            if (lastGroup == undefined || lastGroup.isPinned != isPinned) {
                lastGroup = {
                    items: [item],
                    isPinned: isPinned
                }

                result.push(lastGroup);
            } else {
                lastGroup.items.push(item);
            }

            return result;
        }, new Array<RandomizationGroup<T>>());

        return randomizationGroups.reduce((result, group) => {
            const sortedItems = group.isPinned ? group.items : shuffleArrayUsingToken(group.items, token);

            result.push(...sortedItems);

            return result;
        }, new Array<T>());
    }

    export function calculateFontColor(tagColor?: string): string {
        if (tagColor == undefined)
            return "#fff";

        let useBlack = false,
            colorArr;

        if (tagColor.length == 4) {
            let matches = tagColor.match(/^#([0-9a-f]{3})$/i),
                match = matches ? matches[0] : undefined;

            if (match) {
                // in three-character format, each value is multiplied by 0x11 to give an
                // even scale from 0x00 to 0xff
                colorArr = [
                    parseInt(match.charAt(0), 16) * 0x11,
                    parseInt(match.charAt(1), 16) * 0x11,
                    parseInt(match.charAt(2), 16) * 0x11
                ];
            }
        }
        else if (tagColor.length == 7) {
            let matches = tagColor.match(/^#([0-9a-f]{6})$/i),
                match;

            if (matches)
                match = matches[1];

            if (match) {
                colorArr = [
                    parseInt(match.substr(0, 2), 16),
                    parseInt(match.substr(2, 2), 16),
                    parseInt(match.substr(4, 2), 16)
                ]
            }
        }
        else {
            let matches = tagColor.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);

            if (matches)
                colorArr = [matches[1], matches[2], matches[3]].map(str => parseInt(str));
        }

        if (colorArr && colorArr.length) {
            var level = 0.2126 * Math.pow(colorArr[0] / 255, gamma) +
                0.7152 * Math.pow(colorArr[1] / 255, gamma) +
                0.0722 * Math.pow(colorArr[2] / 255, gamma);

            useBlack = (level > 0.5);
        }

        return useBlack ? "#000" : "#fff";
    }

    export function initContainerSizeAdjustment(this: any, containerObj: JQuery, callbackFunc?: () => void) {
        if (!containerObj.length)
            return;

        var pageObj = this;

        var adjustmentFunc = function () {
            var bottomToolbar = $('#titledMenuToolbarBottom'),
                bottomToolbarHeight = bottomToolbar.length > 0 && bottomToolbar.css('display') != 'none' ? (bottomToolbar.outerHeight() ?? 0) + parseInt(bottomToolbar.css('margin-bottom').replace('px', '')) : 0,
                padding = parseInt(containerObj.css('padding-top')) + parseInt(containerObj.css('padding-bottom')) + parseInt($('#workArea').css('padding-bottom')),
                marginTop = parseInt(containerObj.css('margin-top')),
                margin = (marginTop > 0 ? marginTop : 0) + parseInt(containerObj.css('margin-bottom'));

            containerObj.css("min-height", ($(window).height() ?? 0) - ($('#footer').outerHeight() ?? 0) - (isOldIE() ? 15 : 0) - containerObj[0].offsetTop - margin - padding - bottomToolbarHeight);

            if (callbackFunc)
                callbackFunc.call(pageObj);
        }

        adjustmentFunc.call(this);
        $(window).resize(function () {
            adjustmentFunc.call(this);
        });
    }

    export function getGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);

            return v.toString(16);
        });
    }

    export function isGuid(str: string) {
        return _(str).isString() && guidPattern.test(str);
    }

    /**
     * convert seconds to string
     */
    export function secondsToStr(value: number, maxPartsCount?: number) {
        var days = null,
            hours = null,
            minutes = null,
            seconds = null,
            partsCount = 0,
            value = value,
            result = '';

        var minute = 60,
            hour = 60 * minute,
            day = 24 * hour;

        while (value > 0) {
            if (value > day) {
                days = Math.floor(value / day);
                value = value - days * day;
            }
            else if (value > hour) {
                hours = Math.floor(value / hour);
                value = value - hours * hour;
            }
            else if (value > minute) {
                minutes = Math.floor(value / minute);
                value = value - minutes * minute;
            }
            else {
                seconds = Math.floor(value);
                value = 0;
            }
        }

        if ((!maxPartsCount || partsCount < maxPartsCount) && days != null) {
            result += days + 'd';
            partsCount += 1;
        }
        if ((!maxPartsCount || partsCount < maxPartsCount) && hours != null) {
            result += (partsCount > 0 ? ' ' : '') + hours + 'h';
            partsCount += 1;
        }
        if ((!maxPartsCount || partsCount < maxPartsCount) && minutes != null) {
            result += (partsCount > 0 ? ' ' : '') + minutes + 'm';
            partsCount += 1;
        }
        if ((!maxPartsCount || partsCount < maxPartsCount)) {
            if (seconds != null) {
                result += (partsCount > 0 ? ' ' : '') + seconds + 's';
            }
            else {
                result += '0s';
            }
        }

        return result;
    }

    export function cleanUrlPart(str: string) {
        return str.replace('/', '');
    }

    export function toPx(v: any) {
        if (_(v).isNumber()) {
            return v + 'px';
        } else if (_.isString(v)) {
            return v;
        } else {
            return 'auto';
        }
    }

    export function toPercents(v: any, allowNull = false) {
        if (_(v).isNumber()) {
            return v + '%';
        } else if (_.isString(v)) {
            return v;
        } else if (allowNull && v == undefined) {
            return undefined;
        } else {
            return 'auto';
        }
    }

    export function addParamsToUrl(url: string, param: { [key: string]: string }) {
        url = toUrlWithParams(url);

        return _(param).reduce((r, value, key) => `${r}${getUrlParamPrefix(r)}${key}=${value}`, url);
    }

    export async function lockOrSkip(lock: Observable<boolean>, taskFactory: Func<PromiseLike<void>>) {
        if (!lock()) {
            lock(true);

            try {
                await taskFactory();
            } catch (ex) {
                handleError(ex);
            } finally {
                lock(false);
            }
        }
    }

    export function invokeNotNull<T>(item: T, func: (item: NonNullable<T>) => void): void {
        if (item)
            func(item as NonNullable<T>);
    }

    export function dataUrlToBlob(dataURI: string) {
        let parts = dataURI.split(','),
            byteString = atob(parts[1]);

        // separate out the mime component
        let mimeString = parts[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        let ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        _(byteString).each((v, idx) => ia[idx] = byteString.charCodeAt(idx));

        // write the ArrayBuffer to a blob, and you're done
        return new Blob([ab], { type: mimeString });
    }

    export function merge(left: any, right: any): object {
        if (typeof (left) !== typeof (right)) return left || right;

        let keys = _.union(_(left).keys(), _(right).keys());
        keys = _(keys).filter(key => key.indexOf('_') != 0);

        if (keys.length > 0)
            return _(keys).reduce((result, key) => (result[key] = merge(left[key], right[key]), result), {} as { [key: string]: any });
        else
            return left ?? right;
    }

    export function getFileNameFromUrl(url = '', defaultExtension: string) {
        let name = (_(url.split('/')).last() ?? '').split('?')[0],
            ext = getExtension(name);

        if (ext == undefined)
            ext = defaultExtension;

        return name + (ext ? `.${ext}` : '');
    }

    export function getExtension(path: string) {
        let match = extensionPattern.exec(path);

        if (match)
            return match[1].toLowerCase();

        return undefined;
    }

    export function nullIfDefault(value: any, defaultValue: any) {
        return value == defaultValue ? null : value;
    }

    export function getArray<T>(size: number, factory: () => T): Array<T> {
        let array = new Array(size);

        for (let idx = 0; idx < size; idx++)
            array[idx] = factory();

        return array;
    }

    export function toBase64(str: string) {
        return base64Encoder.encode(str);
    }

    export function parseMarkdown(marked: any, str: string) {
        var renderer = new marked.Renderer();

        renderer.link = function (href: string, title: string, text: string) {
            return '<a href="' + href + '" target="_blank">' + (text || title || href) + '</a>';
        }

        return marked(str, { renderer: renderer });
    }

    export function pick(source: any, keys: Array<string>) {
        var key = _(keys).find(key => source[key] !== undefined);

        if (key)
            return source[key];
    }

    export function getContentTypeFromBase64(str: string) {
        let [, contentType] = /data:([^;]+);base64,/.exec(str) || [];

        return contentType || '';
    }

    export function getContentTypeFromExtension(ext?: string) {
        switch (ext) {
            case 'jpg':
            case 'jpeg': return 'image/jpeg';
            default: return 'image/png';
        }
    }

    export function getExtensionFromContentType(type: string) {
        switch (type) {
            case 'image/png': return 'png';
            case 'image/jpeg': return 'jpg';
        }
    }

    export function toEven(value: number) {
        var number = Math.floor(value);

        return number - number % 2;
    }

    /**
     * Extracts templates and resources(mostly svg elements) from html
     * @param html
     */
    export function getTemplatesAndResources(html: string) {
        var container = document.createElement('div');
        container.innerHTML = html;

        var scriptsOrResources = Array.from(container.querySelectorAll('script, [component-resource="true"]'));

        return scriptsOrResources;
    }

    export function importAll(context: any) {
        return _.chain(context.keys() as Array<string>)
            .map(key => [key, context(key)])
            .object()
            .value();
    }

    export function confirm(message: string, yesCallbackFunc?: () => void, cancelCallbackFunc?: () => void, postInitCallBack?: () => void, hasYesTimeout: boolean = true, hasNoTimeout: boolean = true, noCallbackFunc?: () => void) {
        if (confirmDialog == undefined)
            confirmDialog = $('#wcc-confirm-dialog');

        var buttonClicked = false;//we need to track if a button has been pressed to deal with people double-clicking OK and running our callback function twice

        $('#wcc-confirm-dialog-content', confirmDialog).html(message);

        $('#wcc-confirm-dialog-button-yes', confirmDialog).unbind('click').click(() => {
            if (buttonClicked) return false;
            buttonClicked = true;

            if (yesCallbackFunc) {
                if (hasYesTimeout) {
                    setTimeout(function () {
                        yesCallbackFunc.call(document);
                    }, 500);
                }
                else {
                    yesCallbackFunc.call(document);
                }
            }
            return false;
        });

        function cancelFunc() {
            if (buttonClicked) return false;
            buttonClicked = true;

            if (cancelCallbackFunc) {
                if (hasNoTimeout) {
                    setTimeout(function () {
                        cancelCallbackFunc.call(document);
                    }, 500);
                }
                else {
                    cancelCallbackFunc.call(document);
                }
            }
            return false;
        }

        var noBtn = $('#wcc-confirm-dialog-button-no', confirmDialog);

        //in admin we have cancel and no buttons
        if (settings.isAdmin) {
            //no button requires "no" callback to be provided
            if (noCallbackFunc) {
                noBtn.show().unbind('click').click(() => {
                    if (buttonClicked) return false;
                    buttonClicked = true;

                    if (hasNoTimeout) {
                        setTimeout(function () {
                            noCallbackFunc.call(document);
                        }, 500);
                    }
                    else {
                        noCallbackFunc.call(document);
                    }
                    return false;
                });
            } else {
                noBtn.hide().unbind('click').click(() => false);
            }

            $('#wcc-confirm-dialog-button-cancel', confirmDialog).unbind('click').click(cancelFunc);
        } else {
            //In Tasks cancel button called "no" button so wire cancel handler
            noBtn.unbind('click').click(cancelFunc);
        }

        if (postInitCallBack)
            postInitCallBack.call(document);

        $('div.reveal-modal-bg').remove();

        (confirmDialog as any).reveal({                // The item which will be opened with reveal
            animation: 'fade',              // fade, fadeAndPop, none
            animationSpeed: 300,            // how fast animtions are
            closeOnBackgroundClick: false,   // if you click background will modal close?
            dismissModalClass: 'wcc-dialog-close'      // the class of a button or element that will close an open modal
        });
    }

    export function confirmAsync(message: string) {
        return new Promise<boolean>(resolve => confirm(message, () => resolve(true), () => resolve(false)));
    }

    export function alert(message: string, yesCallbackFunc?: () => void, hasYesTimeout = true) {
        var buttonClicked = false;//we need to track if a button has been pressed to deal with people double-clicking OK and running our callback function twice

        if (alertDialog == undefined)
            alertDialog = $('#wcc-alert-dialog');

        $('.wcc-confirm-dialog-content', alertDialog).html(message);
        $('.wcc-confirm-dialog-button-yes', alertDialog).unbind('click').click(() => {
            if (buttonClicked) return false;
            buttonClicked = true;

            if (yesCallbackFunc) {
                if (hasYesTimeout) {
                    setTimeout(function () {
                        yesCallbackFunc.call(document);
                    }, 500);
                }
                else {
                    yesCallbackFunc.call(document);
                }
            }
            return false;
        });

        $('div.reveal-modal-bg').remove();

        (alertDialog as any).reveal({                // The item which will be opened with reveal
            animation: 'fade',              // fade, fadeAndPop, none
            animationSpeed: 300,            // how fast animtions are
            closeOnBackgroundClick: false,   // if you click background will modal close?
            dismissModalClass: 'wcc-dialog-close'      // the class of a button or element that will close an open modal
        });
    }
    
    export function input(dialogTitle: string, 
                            yesCallbackFunc?: (inputVal: string, inputElement: JQuery<HTMLElement>) => void,
                            cancelCallbackFunc?: () => void,
                            postInitCallBack?: () => void,
                            saveBtnText: string = 'Save',
                            defaultValue: string = '',
                            hasYesTimeout: boolean = true,
                            hasNoTimeout: boolean = true,) {
        
        if (inputDialog == undefined)
            inputDialog = $('#wcc-input-dialog');

        let buttonClicked = false;//we need to track if a button has been pressed to deal with people double-clicking OK and running our callback function twice

        $('.wcc-confirm-dialog-heading', inputDialog).html(dialogTitle);
        
        const inputEl = $('.wcc-confirm-dialog-input', inputDialog);
        inputEl.val(defaultValue);
        
        const yesFunc = () => {
            if (buttonClicked) return false;
            buttonClicked = true;

            if (yesCallbackFunc) {
                if (hasYesTimeout) {
                    setTimeout(function () {
                        yesCallbackFunc.apply(document, [inputEl.val() as string, inputEl]);
                    }, 500);
                } else {
                    yesCallbackFunc.apply(document, [inputEl.val() as string, inputEl]);
                }
            }
            return false;
        };
        
        inputEl.off('keyup').on('keyup', (e) => {
            if (e.key == 'Enter') {
                $('.wcc-confirm-dialog-button-yes', inputDialog).trigger('click');
            }
        });

        $('.wcc-confirm-dialog-button-yes', inputDialog)
            .html(saveBtnText)
            .off('click')
            .on('click', yesFunc);
        

        const cancelFunc = () => {
            if (buttonClicked) return false;
            buttonClicked = true;

            if (cancelCallbackFunc) {
                if (hasNoTimeout) {
                    setTimeout(function () {
                        cancelCallbackFunc.call(document);
                    }, 500);
                }
                else {
                    cancelCallbackFunc.call(document);
                }
            }
            return false;
        }
        
        $('#wcc-confirm-dialog-button-cancel', inputDialog).off('click').on('click', cancelFunc);

        if (postInitCallBack)
            postInitCallBack.call(document);

        $('div.reveal-modal-bg').remove();

        (inputDialog as any).reveal({                // The item which will be opened with reveal
            animation: 'fade',              // fade, fadeAndPop, none
            animationSpeed: 300,            // how fast animtions are
            closeOnBackgroundClick: false,   // if you click background will modal close?
            dismissModalClass: 'wcc-dialog-close'      // the class of a button or element that will close an open modal
        });
        
        setTimeout(() => inputEl.trigger('focus'), 500);
    }

    export function isEmailValid(email: string) {
        return emailPattern.test(email);
    }

    export function isEmailAddress(str: any) {
        return str != null && str.indexOf('@') >= 0;
    }

    export function isDNSNameValid(DNSName: string) {
        var result = true;

        if (DNSName == "")
            result = false;

        return result;
    }

    const hashLiterals = "abcdefghijklmnopqrstuvwxyz123456789";

    export function generateHashString(length: number) {
        let generatedHash = "";

        for (var i = 0; i < length; i++)
            generatedHash += hashLiterals[Math.floor(Math.random() * 34)];

        return generatedHash;
    }

    export function generateHashForString(str: string) {
        var hash = 0, i, chr, len;
        if (str.length == 0) return hash;
        for (i = 0, len = str.length; i < len; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    /**
     * Initializes custom checkboxes and radiobuttons in given context
     */
    export function initICheck(context: any) {
        ($("input", context) as any).iCheck({
            checkboxClass: 'icheckbox_flat-blue',
            radioClass: 'iradio_flat-blue'
        });
    }

    export function destroyICheck(context: any) {
        ($("input", context) as any).iCheck('destroy');
    }

    /**
     * Initializes sparkline
     */
    export function initSparkline(element: JQuery, data: any, options: any) {
        var sparklineOptions = {
            type: 'line',
            spotColor: '',
            minSpotColor: '',
            maxSpotColor: '',
            highlightSpotColor: '',
            highlightLineColor: '',
            fillColor: '#E3F2F5',
            lineColor: '#1B6EAD',
            lineWidth: 1,
            width: '22%',
            height: '20px',
            disableTooltips: true
        };

        if (options)
            $.extend(sparklineOptions, options);

        (element as any).sparkline(data, sparklineOptions);
    }

    /*
     * Decodes html entities
     */
    export function htmlDecode(html: string, skipTags: Array<string> = []) {
        var tmpHtml = html;

        $.each(skipTags, function (i, e) {
            tmpHtml = tmpHtml.replace(new RegExp("<" + e + ">", "g"), "{" + e + "}").replace(new RegExp("</" + e + ">", "g"), "{/" + e + "}");
        });

        var plainText = $('<div />').html(tmpHtml).text();

        $.each(skipTags, function (i, e) {
            plainText = plainText.replace(new RegExp("{" + e + "}", "g"), "<" + e + ">").replace(new RegExp("{/" + e + "}", "g"), "</" + e + ">");
        });

        return plainText;
    }

    /*
     * Encodes text with html entities
     */
    export function htmlEncode(text: string, skipTags: Array<string> = []) {
        if (text) {
            var tmpText = text;

            $.each(skipTags, function (i, e) {
                tmpText = tmpText.replace(new RegExp("<" + e + ">", "g"), "{" + e + "}").replace(new RegExp("</" + e + ">", "g"), "{/" + e + "}");
            });

            var tmpHtml = $('<div />').text(tmpText).html();

            $.each(skipTags, function (i, e) {
                tmpHtml = tmpHtml.replace(new RegExp("{" + e + "}", "g"), "<" + e + ">").replace(new RegExp("{/" + e + "}", "g"), "</" + e + ">");
            });

            return tmpHtml;
        } else {
            return "";
        }
    }
    
    export function stripHtml(text: string) {
        let te = document.createElement('div');
        te.innerHTML = text;
        return te.innerText;
    }

    /*
     * Removes all script tags and script injection points from a string of html and returns the clean script free html string
     */
    export function removeScriptsFromHtml(html: string): string {

        //attributes we stript out
        const vulnerableAttributes = ['formaction', 'onanimationstart', 'onbeforescriptexecute', 'onblur', 'onerror', 'onfilterchange', 'onfocus', 'onformchange', 'onforminput', 'onhover', 'oninput', 'onload', 'onmouseover', 'onpageshow', 'onratechange', 'onresize', 'onscroll', 'ontoggle', 'onwebkittransitionend', 'xlink'];

        //elements we remove
        const vulnerableElements = ['SCRIPT', 'LINK', 'IFRAME', 'FORM', 'BASE', 'OBJECT', 'EMBED'];

        //checks to see if an element should be removed based on its type
        const isElementBad = function (e: any) {
            if (!e) return false;
            return vulnerableElements.indexOf(e.tagName) > 0;
        };

        //checks to see if an attribute is bad
        const isAttributeBad = function (attr: any) { return vulnerableAttributes.indexOf(attr.name) > 0; };

        //default remove element function - remove it from the parent node
        const elementRemoverNode = function (e: any) { return e.parentNode.removeChild(e); };

        //checks to make sure a URL is valid
        const isValidURL = function (url: any) {
            try {
                const parsed = new URL(url);
                return parsed.protocol == 'http:' || parsed.protocol == 'https:';
            }
            catch (e) {
                return false;
            }
        };

        //runs html clean function on all children if there are any
        const cleanChildren = function (e: any) {
            if (e && e.hasChildNodes && e.hasChildNodes() && e.childNodes.length > 0) {
                $.each(e.childNodes, function (i, node) {
                    cleanHtmlElement(node);
                });
            }
        };

        //cleans out any bad attributes we dont want to allow
        const cleanAttributes = function (e: any) {
            if (e == null || e.hasAttributes == null || !e.hasAttributes()) return;

            for (let i = e.attributes.length - 1; i >= 0; i--) {
                const attr = e.attributes[i];

                if (attr.name == "poster") {//need to review this one - the value can be a valid URL only
                    if (!isValidURL(attr.Value)) {//if it is NOT a well formed URL we remove it
                        e.attributes.removeNamedItem(attr.name);
                    }
                }
                else if (isAttributeBad(attr)) {//if the attribute name is on our list of vulnerable ones then we strip it out
                    e.attributes.removeNamedItem(attr.name);
                }
            }
        };

        //cleans an element and its children
        const cleanHtmlElement = function (e: any, elementRemover?: any) {
            if (isElementBad(e)) {//remove me
                (elementRemover || elementRemoverNode)(e);//find the remove function and get rid of this dodgy html node
                return;
            }

            cleanAttributes(e);//if we still have an element go through and clean out any bad attributes
            cleanChildren(e);//now go and process any child elements
        };

        //decode string into objects
        const elements = $.parseHTML(html) as any;

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            cleanHtmlElement(element, function (e: any) { elements[i] = null; });
        }

        return elements.filter(function (x: any) { return x != null; }).map(function (x: any) { return x.outerHTML; }).join('');
    }

    /*
     * Finds anchors in text and sets target for them and shortens their label/display text to match the width desired
     */
    export function cutAndSetTargetForAnchor(text: any, maxLength: any, target: any) {
        var urlRegex = /<a.*>.*<\/a>/g;

        return text.replace(urlRegex, function (anchor: any) {
            var captureRegex = /<a(.*)>(.*)<\/a>/g;
            var matches = captureRegex.exec(anchor);
            if (matches != null && matches.length && (target || maxLength)) {
                var attrs = matches[1],
                    label = matches[2];

                if (maxLength) {
                    if (label.length > maxLength) {
                        var cutPos = maxLength / 2;
                        label = label.substring(0, cutPos) + '...' + label.substring(label.length - cutPos);
                    }
                }
                if (target) {
                    if (attrs.toLowerCase().indexOf("target") < 0) {
                        attrs = (attrs + ' target="' + target + '"').trim();
                    }
                }
                return '<a ' + attrs + '>' + label + '</a>';
            }
            return anchor;
        });
    }

    /*
     * Finds links in text and converts them to links and shortens their label/display text to match the width desired
     */
    export function cutAndSetTargetForLink(text: any, maxLength: any, target: any) {
        var urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/g;

        return text.replace(urlRegex, function (link: any) {
            var anchor = '<a href="' + link + '"';
            if (maxLength) {
                if (link.length > maxLength) {
                    var cutPos = maxLength / 2;
                    link = link.substring(0, cutPos) + '...' + link.substring(link.length - cutPos);
                }
            }
            if (target) {
                anchor += ' target="' + target + '"'
            }
            anchor += '>' + link + '</a>';
            return anchor;
        });
    }

    export function copyDataToClipboard(containerElement: any, data: any) {
        if (StringHelpers.isNullOrEmpty(data))
            return false;

        var parentContainer = containerElement.target.parentElement,
            textArea = document.createElement("textarea");
        textArea.value = data;
        parentContainer.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        parentContainer.removeChild(textArea);
        return true;
    }

    export function deleteValueFromArray(arr: any, deleteValue: any, removeTextInEachValue: any) {
        var newArray = [];

        for (var i = 0; i < arr.length; i++) {
            var val = arr[i];

            if (removeTextInEachValue != null) {
                val = val.replace(removeTextInEachValue, "").trim(); //Remove some text in each value
            }
            if (deleteValue == null || val != deleteValue) {
                newArray.push(val);
            }
        }
        return newArray;
    }

    export function generateStringWithBasicFormattingTags(str: string) {
        var strippedHtml = "<p>" + str + "</p>";

        strippedHtml = strippedHtml.replace(/\<b\>/gi, "||b||").replace(/\<\/b\>/gi, "||/b||"); //replace bold with special character
        strippedHtml = strippedHtml.replace(/\<u\>/gi, "||u||").replace(/\<\/u\>/gi, "||/u||"); //replace underline with special character
        strippedHtml = strippedHtml.replace(/\<i\>/gi, "||i||").replace(/\<\/i\>/gi, "||/i||"); //replace italics with special character

        strippedHtml = $(strippedHtml).text(); //we have now removed all tags

        //Put back bold, italics and underlines
        strippedHtml = strippedHtml.replace(/\|\|b\|\|/gi, "<b>").replace(/\|\|\/b\|\|/gi, "</b>");
        strippedHtml = strippedHtml.replace(/\|\|u\|\|/gi, "<u>").replace(/\|\|\/u\|\|/gi, "</u>");
        strippedHtml = strippedHtml.replace(/\|\|i\|\|/gi, "<i>").replace(/\|\|\/i\|\|/gi, "</i>");

        return strippedHtml;
    }

    export function markControlAsInvalid(control: JQuery, message: string, scroll?: boolean, container?: any, scrollContainer?: JQuery) {
        var controlForTooltip = control;

        if (!(control.hasClass('html5editor') /*|| control.hasClass('select2')*/ || control.hasClass('generic'))) {
            control.addClass("inputError");
        }
        else {
            if (control.hasClass("select2")) { //if it's a select2 control (either binded on a hidden field or a select)
                controlForTooltip = controlForTooltip.next('.select2-container');
                controlForTooltip.addClass("inputError");
            }
        }

        if (message) {
            (controlForTooltip as any).tooltip('destroy');

            setTimeout(function () {
                var triggerOptions = controlForTooltip.attr('data-tooltip-trigger');

                var tooltipObj = (controlForTooltip as any).tooltip({
                    title: message,
                    placement: controlForTooltip.attr('data-tooltip-placement') || 'top',
                    trigger: triggerOptions || 'hover focus',
                    container: container
                });

                if (StringHelpers.isNullOrEmpty(triggerOptions))
                    tooltipObj.tooltip('show');
            }, 200); //We need a timeout here as sometimes the tooltip crashes after destroying it and before re-initializing it
        }

        if (scroll === true) {
            if (scrollContainer == null)
                scrollContainer = $('html, body');

            scrollContainer.animate({
                scrollTop: (controlForTooltip.offset()?.top ?? 0) - 100
            });
        }
    }

    export function markControlAsValid(control: JQuery) {
        if (control.is("select.select2")) {
            ($("a.select2-choice", (control as any).select2("container")).removeClass("inputError") as any).tooltip('destroy');
        }
        else {
            (control as any).removeClass("inputError").tooltip('destroy');
        }
    }

    export function isMandatoryFieldValid(fieldObj: JQuery) {
        const val = fieldObj.val();

        if (_.isString(val) && val.trim() == "") {
            markControlAsInvalid(fieldObj, messages.FieldIsMandatory);
            return false;
        }
        else {
            markControlAsValid(fieldObj);
            return true;
        }
    }

    /**
     * function to filter out all keys except numbers
     * @param event
     */
    export function allowNumbersOnly(event: KeyboardEvent) {
        if (event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39)
            return true;
        else {
            // Ensure that it is a number and stop the keypress
            if (event.keyCode < 48 || event.keyCode > 57 && event.keyCode < 96 || event.keyCode > 105) {
                event.preventDefault();
                return false;
            }
            else {
                if (event.shiftKey) {
                    return false;
                }
                return true;
            }
        }
    }

    //function to filter out all keys except numbers and alphabets
    export function allowAlphaNumericsOnly(event: KeyboardEvent) {
        if (event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39)
            return true;
        else {
            // Ensure that it is a number or an alphabet and stop the keypress
            if (event.keyCode < 48 || event.keyCode > 90 && event.keyCode < 96 || event.keyCode > 105) {
                event.preventDefault();
                return false;
            }
            else {
                if (event.shiftKey) {
                    return false;
                }
                return true;
            }
        }
    }

    const URLPattern = new RegExp(/^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/gi)

    /**
     * Validates a website's URL
     */
    export function validateURL(url: string) {
        return url.match(URLPattern);
    }

    /**
     * Validates phone number and returns object that contains validation error or validated phone number in original, national and international formats and phone type
     */
    export function validatePhoneNumber(phoneNumber: string, regionCode: string) {
        var result: any = { error: "" };

        try {
            const phoneUtil = i18n.PhoneNumberUtil.getInstance();
            var number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
            if (!phoneUtil.isPossibleNumber(number)) {
                const PNV = i18n.PhoneNumberUtil.ValidationResult;
                switch (phoneUtil.isPossibleNumberWithReason(number)) {
                    case PNV.INVALID_COUNTRY_CODE:
                        result.error = messages.PhoneNumberHasInvalidCountryCode;
                        break;
                    case PNV.TOO_SHORT:
                        result.error = messages.PhoneNumberIsTooShort;
                        break;
                    case PNV.TOO_LONG:
                        result.error = messages.PhoneNumberIsTooLong;
                        break;
                }
            } else {
                if (phoneUtil.isValidNumber(number)) {
                    var PNF = i18n.PhoneNumberFormat;
                    result.originalFormat = phoneUtil.formatInOriginalFormat(number, regionCode);
                    result.nationalFormat = phoneUtil.format(number, PNF.NATIONAL);
                    result.internationalFormat = phoneUtil.format(number, PNF.INTERNATIONAL);

                    var PNT = i18n.PhoneNumberType;
                    switch (phoneUtil.getNumberType(number)) {
                        case PNT.FIXED_LINE:
                            result.phoneType = 'FIXED_LINE';
                            break;
                        case PNT.MOBILE:
                            result.phoneType = 'MOBILE';
                            break;
                        case PNT.FIXED_LINE_OR_MOBILE:
                            result.phoneType = 'FIXED_LINE_OR_MOBILE';
                            break;
                        case PNT.TOLL_FREE:
                            result.phoneType = 'TOLL_FREE';
                            break;
                        case PNT.PREMIUM_RATE:
                            result.phoneType = 'PREMIUM_RATE';
                            break;
                        case PNT.SHARED_COST:
                            result.phoneType = 'SHARED_COST';
                            break;
                        case PNT.VOIP:
                            result.phoneType = 'VOIP';
                            break;
                        case PNT.PERSONAL_NUMBER:
                            result.phoneType = 'PERSONAL_NUMBER';
                            break;
                        case PNT.PAGER:
                            result.phoneType = 'PAGER';
                            break;
                        case PNT.UAN:
                            result.phoneType = 'UAN';
                            break;
                        case PNT.UNKNOWN:
                            result.phoneType = 'UNKNOWN';
                            break;
                    }
                } else {
                    result.error = messages.PhoneNumberIsInvalid;
                }
            }
        } catch (e: any) {
            if (typeof e == "string") {
                result.error = e;
            } else {
                result.error = e.message;
            }
        }
        return result;
    }

    export function insertAtCursor(element: JQuery<HTMLTextAreaElement> | HTMLTextAreaElement, value: string) {
        const $element = $(element);

        // IE
        if ((document as any).selection) {
            $element.focus();
            (document as any).selection.createRange().text = value;
        }
        // normal browsers
        else if ($element[0].selectionStart || $element[0].selectionStart == 0) {
            var startPos = $element[0].selectionStart,
                endPos = $element[0].selectionEnd;
            $element.val((<string>$element.val()).substring(0, startPos)
                + value
                + (<string>$element.val()).substring(endPos));
            $element[0].selectionStart = startPos + value.length;
            $element[0].selectionEnd = startPos + value.length;
        } else {
            $element.val($element.val() + value);
        }
    }

    /**
     * Removes formatting from string to be able to use it in quill
     * @param input
     */
    export function removeFormattingFromString(input: string) {
        return (input ?? '').replace(/[•o]\t/gi, '');
    }

    /**
     * Inserts string at a specified position
     * @param input
     * @param value
     * @param position
     */
    export function insertStringAtPosition(input: string, value: string, position: number) {
        // Invalid values, jusr return
        if (StringHelpers.isNullOrEmpty(input) || StringHelpers.isNullOrEmpty(value))
            return input;

        var index = position;

        // If position is < 0 or it's greater than the string length, insert at the beginning
        if (position < 0 || position > input.length)
            index = 0;

        return [input.slice(0, position), value, input.slice(position)].join('');
    }

    export function getDateIntervalString(date1: string | Date, date2: string | Date) {
        /*const language = api.language;
    
        if (isNotNullOrEmpty(language)) {
            require('moment/locale/' + language);
            moment.locale(language);
        }*/

        return moment(date1).from(moment(date2));
    }

    export function htmlSmartCut(html: string, demandedLength: number, exact?: boolean) {
        return stringSmartCut(html.replace(/<[\S\s]*?>/g, ''), demandedLength);
    }

    export function stringSmartCut(text: string, demandedLength: number, exact?: boolean, appendDots?: boolean) {
        if (text.length <= demandedLength) {
            return text;
        }

        var stopChars = " ;:-!,.?",
            cutPosition = -1;
        for (var i = demandedLength; i > 0 && i > demandedLength - 10; i--) {
            var chr = text[i];
            if (stopChars.indexOf(chr) > -1) {
                cutPosition = i;
                break;
            }
        }
        if (cutPosition == -1 && !exact) {
            for (var i = demandedLength + 1; i > text.length && i < demandedLength + 9; i++) {
                var chr = text[i];
                if (stopChars.indexOf(chr) > -1) {
                    cutPosition = i;
                    break;
                }
            }
        }
        if (cutPosition == -1) {
            cutPosition = demandedLength;
        }

        return text.substring(0, cutPosition) + (appendDots ? "..." : "");
    }

    export function isNumber(n: any): n is number {
        return n == parseFloat(n);
    }

    export function isEven(n: any): n is number {
        return isNumber(n) && (n % 2 == 0);
    }

    export function isOdd(n: any): n is number {
        return isNumber(n) && (Math.abs(n) % 2 == 1);
    }

    export function initFancyBox(context?: any) {
        if (settings.isAdmin) {
            ($(".fancybox", context) as any).fancybox({
                prevEffect: 'elastic',
                nextEffect: 'elastic',
                openEffect: 'elastic',
                helpers: {
                    title: {
                        type: 'outside'
                    },
                    thumbs: {
                        width: 50,
                        height: 50
                    }
                },
                beforeShow: function () {
                    (WCC as any).Reactor.dispatchEvent('fancyBoxWillBeOpened');
                },
                afterClose: function () {
                    (WCC as any).Reactor.dispatchEvent('fancyBoxClosed');
                },
                afterLoad: function () {
                    (WCC as any).Reactor.dispatchEvent('fancyBoxLoaded');
                }
            });
        }
    }

    export function initFancyBoxWithParams(targetElement: JQuery, customOptions?: any) {
        var options = {
            prevEffect: 'elastic',
            nextEffect: 'elastic',
            openEffect: 'elastic',
            helpers: {
                title: {
                    type: 'outside'
                },
                thumbs: {
                    width: 50,
                    height: 50
                }
            }
        };

        if (customOptions) {
            options = $.extend(options, customOptions);
        }

        (targetElement as any).fancybox(options);
    }

    export function format(str: string, params: any) {
        var string = str;

        for (var i = 0; i < params.length; i++) {
            string = string.replace(new RegExp("\\{" + i + "\\}", "g"), params[i]);
        }
        return string;
    }

    /**
     * Formats a numeric string to include 2 decimal places
     */
    export function formatMoney(s: string) {
        s = s.toString();
        var i = s.indexOf('.');

        if (i == -1) {
            //no decimal
            return s + '.00';
        }
        else {
            //has decimal already
            if (i == s.length - 2) {
                return s + "0";
            }
            else {
                return s;
            }
        }
    }
    
    /**
     * Transforms a number of milliseconds to minutes:seconds format
     */
    export function formatTime(ms: number) {
        let hours = Math.trunc(ms / 3600000),
            minutes = Math.trunc((ms % 3600000) / 60000),
            seconds = Math.trunc((ms % 60000) / 1000);
        
        let hoursStr = hours > 0 ? hours + ':' : '';
        let minutesStr = minutes + '';
        let secondsStr = seconds + '';
        if (minutesStr.length < 2) {
            minutesStr = '0' + minutesStr;
        }
        if (secondsStr.length < 2) {
            secondsStr = '0' + secondsStr;
        }

        return hoursStr + minutesStr + ':' + secondsStr;
    }

    /**
     * Formats cents into a money string so 4739 becomes $47.39, 50 becomes $0.50, etc
     */
    export function formatCentsAsMoney(cents: any, currencyString?: string): string {
        var currency = currencyString ?? '';
        var c = parseInt(cents);//cut off any decimals

        if (c == 0) return currency + '0';//if we got 0, hard code the return

        //if we are looking at a negative number we need to prefix the whole thing with the negative sign, eg: dont use $-5, use -$5
        if (c < 0) return '-' + formatCentsAsMoney(-c, currencyString);

        if (c >= 100) {//we have dollars
            return currency + (c / 100).toFixed(2);//show in 2 decimal places
        }
        else if (c >= 10) {//we have double digit cents (like 50 or 23) so just do "0." prefix plus toString
            return currency + '0.' + c.toString();//cut off any decimals
        }
        else {//we have single digit cents (like 4, or 5) so just do "0.0" prefix plus toString
            return currency + '0.0' + c.toString();//cut off any decimals
        }
    }

    export function initSpinner(element: any, options: any) {
        var opts = {
            lines: 13, // The number of lines to draw
            length: 0, // The length of each line
            width: 7, // The line thickness
            radius: 30, // The radius of the inner circle
            corners: 1, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#000', // #rgb or #rrggbb or array of colors
            speed: 1, // Rounds per second
            trail: 50, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: 'spinner', // The CSS class to assign to the spinner
            zIndex: 9100, // The z-index (defaults to 2000000000)
            top: '50%', // Top position relative to parent
            left: '50%' // Left position relative to parent
        };
        if (options) {
            for (var key in options) {
                (opts as any)[key] = options[key];
            }
        }
        return new Spinner(opts).spin(element);
    }

    export function autoSizeTextBox(textBoxes: JQuery<HTMLTextAreaElement>, minWidth: any) {
        var id = 'text-width-sizer',
            tag = $('#' + id);

        $.each(textBoxes, function (i, t) {
            var textBox = $(t);
            if (textBox.val() == "") {
                textBox.css({ width: minWidth + 'px' });
            }
            else {
                if (!tag.length) {
                    tag = $('<span id="' + id + '" style="display:none;font-family:' + textBox.css('font-family') + ';font-size: ' + textBox.css('font-size') + '">' + textBox.val() + '</span>');
                    $('body').append(tag);
                } else {
                    tag.css({ "font-family": textBox.css('font-family'), "font-size": textBox.css('font-size') }).text(textBox.val() as string);
                }

                textBox.width(tag.width() ?? 0); //".height(tag.height());" -- We can include height later, if needed 
            }
        });

        tag.remove();
    }

    export function convertDateToMomentInterval(createDate: any) {
        if (settings.isAdmin) {
            return moment(createDate).fromNow();
        } else {
            const language = api.language;

            if (StringHelpers.isNotNullOrEmpty(language)) {
                moment.locale(language);
            }
            if (serverCurrentTime.getTime() + new Date().getTime() - pageInitTime.getTime() - new Date(createDate).getTime() <= 0) {
                createDate = serverCurrentTime.getTime() + new Date().getTime() - pageInitTime.getTime() - 1000;
            }
            return moment(new Date(createDate)).
                from(moment(serverCurrentTime).
                    add(Math.floor((new Date().getTime() - pageInitTime.getTime()) / 1000), "second"));
        }
    }

    // returns first item from given array that satisfy given predicate 
    export function getFirst<T>(arr: Array<T>, predicate: (item: T) => boolean) {
        var firstItem;

        $.each(arr, function (i, item) {
            if (predicate(item)) {
                firstItem = item;
                return false;
            }
        });

        return firstItem;
    }

    export function waitImage(img: HTMLImageElement) {
        var newImage = new Image(),
            result = $.Deferred();

        newImage.onload = function () {
            result.resolve(img);
        };
        newImage.onerror = function () {
            result.reject();
        };

        if (img.crossOrigin) {
            newImage.crossOrigin = img.crossOrigin;
        }

        newImage.src = img.src;

        return result.promise();
    }

    //Executes a predicate on each item of an array
    export function forEach<T>(arr: Array<T>, predicate: (item: T) => void) {
        $.each(arr, function (i, item) {
            predicate(item);
        });
    }

    export function koShowErrors(koBaseObject: any, errors: any) {
        var count = 0;
        $.each(errors, function (k: string, e) {
            var key = StringHelpers.toFirstCharLowerString(k),
                prop = koBaseObject[key];

            prop.error(e);
            prop.scrollTo(false);

            if (count == 0)
                prop.scrollTo(true);

            count++;
        });
    }

    export function loadPartialView(viewName: string, callback: () => void) {
        return ajaxRequest({
            url: '/dynamicViews?viewName=' + viewName,
            method: 'GET',
            dataType: 'html',
            success: function (html: string) {
                $('body').append(html);
                if (callback) {
                    callback();
                }
            }
        });
    }

    export function isBrowserValidForClipChamp() {
        //if (chipchampDisabled) return false;

        //if (settings.clipChampEnabled) { //we can disable clipchamp entirely now. Ideal for local testing.
        //    const browser = bowser.getParser(window.navigator.userAgent);

        //    return browser.satisfies({
        //        chrome: '>=49',
        //        edge: '>=1',
        //        firefox: '>=45',
        //        safari: '>=9'
        //    }) ?? false;
        //}

        return false;
    }

    /**
     * Randomize array element order in-place.
     * Using Durstenfeld shuffle algorithm.
     */
    export function shuffleArray<T>(array: Array<T>) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    export function parseCSSInt(cssVal: any) {
        return parseInt((cssVal ?? '0').replace(/[^-\d\.]/g, ''));
    }

    export function toggleUrlParam(param: string) {
        var url = location.href;

        var pathAndOther = url.split('?'),
            pathAndHash = (pathAndOther[0] || '').split('#'),
            pathStr = pathAndHash[0] || '';

        var queryAndHash = (pathAndOther[1] || '').split('#'),
            queryStr = queryAndHash[0] || '';

        var otherAndHash = url.split('#'),
            hashStr = otherAndHash[1] || '';

        var query = _.chain(queryStr.split(/&|;/))
            .compact()
            .map(function (pair) { return pair.split('=') })
            .filter(function (parts) { return parts.length === 2 })
            .object()
            .value() as any;

        var queryParam = query[param];

        if (queryParam !== 'off') {
            query[param] = 'off';
        } else {
            query[param] = 'on';
        }

        var newQueryStr = _(query).map((value: string, key: string) => key + '=' + value).join('&');

        location.href = pathStr + '?' + newQueryStr + (hashStr.length > 0 ? '#' + hashStr : '');
    }

    export function toggleTopicCompletionTest() {
        toggleUrlParam('comp');
    }

    export function toggleRespondentTagTest() {
        toggleUrlParam('tags');
    }

    export function clearLocalStorage() {
        confirm(messages.AreYouSureYouWantToClearAllLocalStorageData, () => {
            if (localStorage) {
                localStorage.clear();
                NotificationsHelpers.showInfoMessage(messages.LocalStorageDataIsClearedSuccessfully);
            }
            else {
                NotificationsHelpers.showErrorMessage(messages.LocalStorageIsNotSupportedByThisBrowser); //This is typically least expected to happen
            }
        });
    }

    // not sure we need this anymore, since s3 ignores all extra params added
    export function addCrossOriginParamToUrl(url: string | undefined) {
        //return addParamToUrl(url, 'crossorigin=1');
        return url ? url : '';
    }
    export function addParamToUrl(url: string | undefined, param: string) {
        return url ? url + (url.indexOf('?') > -1 ? '&' : '?') + param : '';
    }

    export function fixUrlProtocol(url: string) {
        if (StringHelpers.isNullOrEmpty(url)) {
            return url;
        }

        var isSecureConnection = location.href.startsWith("https:");

        //Prepends appropriate protocol
        function prependProtocol(protocol: string) {
            if (url.startsWith("//") || url.startsWith("\\")) { //The url may be in the form '//tp.admin.dev.com' or '\\tp.admin.dev.com'
                return protocol + ":" + url;
            }
            else {
                return protocol + "://" + url; //The url may be in the form 'tp.admin.dev.com'
            }
        }

        if (isSecureConnection && !url.startsWith("https:")) {
            if (url.startsWith("http:")) {
                url = url.replace("http:", "https:");
            }
            else {
                url = prependProtocol("https");
            }
        }
        else if (!isSecureConnection && !url.startsWith("http:")) {
            if (url.startsWith("https:")) {
                url = url.replace("https:", "http:");
            }
            else {
                url = prependProtocol("http");
            }
        }
        return url;
    }
    
    export function findScrollableParent(element: HTMLElement) {
        let stackGuard = 0;
        let parent = $(element);
        while(parent && (parent.prop('tagName') ?? '').toLowerCase() != 'body' && stackGuard < 100) {
            if(parent.css('overflow-y') == 'auto' || parent.css('overflow-y') == 'scroll') {
                return parent;
            }
            parent = parent.parent();
            stackGuard++;
        }

        return $(window);
    }

    export function getQueryStringParams() {
        function parse_query_string(query: string) {
            var vars = query.split("&");
            var query_string: any = {};

            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                var key = decodeURIComponent(pair[0]).toLowerCase();
                var value = decodeURIComponent(pair[1]);
                // If first entry with this name
                if (typeof query_string[key] === "undefined") {
                    query_string[key] = decodeURIComponent(value);
                    // If second entry with this name
                } else if (typeof query_string[key] === "string") {
                    var arr = [query_string[key], decodeURIComponent(value)];
                    query_string[key] = arr;
                    // If third or later entry with this name
                } else {
                    query_string[key].push(decodeURIComponent(value));
                }
            }

            return query_string;
        }

        if (!location.search || location.search.indexOf('?') != 0) {
            return {};
        }

        var hashIndex = location.search.indexOf('#');
        var query_string = hashIndex > -1
            ? location.search.substr(1, hashIndex - 1)
            : location.search.substr(1);

        return parse_query_string(query_string);
    }

    export function cleanPhoneNumber(num: string) {
        if (StringHelpers.isNullOrEmpty(num))
            return "";

        if (Settings.BAD_PHONE_CHARS == null)
            return num;

        return StringHelpers.trimLeft(StringHelpers.cleanString(num, Settings.BAD_PHONE_CHARS), '0');
    }

    export function isScreenRecordingSupported() {
        return navigator.mediaDevices != null && (navigator.mediaDevices as any).getDisplayMedia != null;
    }

    //function to allow anything except numbers
    export function disAllowNumbers(event: KeyboardEvent) {
        if (!event.shiftKey && event.keyCode > 47 && event.keyCode < 58)
            return false;
        return true;
    }

    //function to filter out all keys except alphabets
    export function allowAlphaOnly(event: KeyboardEvent) {
        if (event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39)
            return true;
        else {
            // Ensure that it is a number or an alphabet and stop the keypress
            if (event.keyCode < 65 || event.keyCode > 90 && event.keyCode < 96 || event.keyCode > 105) {
                event.preventDefault();
                return false;
            }
            else {
                if (event.shiftKey) {
                    return false;
                }
                return true;
            }
        }
    }

    export function pad(num: any, size = 2) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    export function getISODate(date: any, zeroOutSeconds: boolean = false) {
        date = date || new Date();
        return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ":" + pad(date.getMinutes()) + ":" + (zeroOutSeconds ? "00" : pad(date.getSeconds()));
    }

    export function replaceUTCOffsetByLocal(dateStr: string) {
        var result = dateStr;
        if (dateStr != '' && dateStr.indexOf('+00:00') > -1) {
            var offsetFull = -(new Date().getTimezoneOffset());
            var offsetHours = Math.ceil(Math.abs(offsetFull / 60));
            var offsetMinutes = Math.abs(offsetFull) - offsetHours * 60;
            var offsetStr = (offsetFull > 0 ? '+' : '-')
                + (offsetHours < 10 ? '0' : '') + offsetHours
                + ':'
                + (offsetMinutes < 10 ? '0' : '') + offsetMinutes;
            result = dateStr.replace('+00:00', offsetStr);
        }
        return result;
    }

    export function fixDSTChanges(date: Date) {
        var serverOffset = date.getTimezoneOffset();
        var browserOffset = new Date().getTimezoneOffset();
        if (serverOffset != browserOffset) {
            date = new Date(date.getTime() + (serverOffset - browserOffset) * 60000);
        }
        return date;
    }

    export function getFormattedDate(date: string | Date) {
        return moment(date).format("hh:mmA Do MMMM");
    }
    
    export function getBusinessDatesCount(startDate: Date, endDate: Date) {
        let count = 0;
        const curDate = new Date(startDate.getTime());
        if(curDate <= endDate) {
            while (curDate <= endDate) {
                const dayOfWeek = curDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
                curDate.setDate(curDate.getDate() + 1);
            }
        }
        else {
            while (curDate > endDate) {
                const dayOfWeek = curDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) count--;
                curDate.setDate(curDate.getDate() - 1);
            }
        }
        return count;
    }

    export function toFixed(number: any, precision: any) {
        if (number.toFixed)
            return number.toFixed(precision);

        return number;
    }

    export function FilterAndFlattenEnum(wccEnum: any, ...valuesToExclude: Array<number>) {
        let result = [];

        for (let c in wccEnum) {
            const type = wccEnum[c];

            if (!valuesToExclude.includes(type.value)) {
                result.push(type);
            }
        }

        return result;
    }
    
    export function transformEnumsToArray(wccEnum: any, makeFirstLetterUppercase?: boolean) {
        var arr = [];

        for (var k in wccEnum) {
            var item = wccEnum[k];
            if (makeFirstLetterUppercase === true) {
                item.text = StringHelpers.toFirstCharUpperString(item.text);
            }
            arr.push(item);
        }

        return arr;
    }

    export function getEnumTextByValue(wccEnum: any, val: any) {
        for (var i in wccEnum) {
            if (wccEnum[i].value == val)
                return wccEnum[i].text as string;
        }

        return '';
    }

    export function getEnumValueByText(wccEnum: any, txt: string, returnFirstAsDefault?: boolean) {
        for (var i in wccEnum) {
            if (wccEnum[i].text.toLowerCase() == txt.toLowerCase()) {
                return wccEnum[i].value as string;
            }
        }
        if (returnFirstAsDefault == true)
            return wccEnum[Object.keys(wccEnum)[0]].value as string;

        return '';
    }

    export function getEnumValueByStringValue(wccEnum: any, val: string, returnFirstAsDefault?: boolean) {
        for (var i in wccEnum) {
            if (wccEnum[i].description.toLowerCase() == val.toLowerCase())
                return wccEnum[i].value as string;
        }
        if (returnFirstAsDefault == true)
            return wccEnum[Object.keys(wccEnum)[0]].value as string;

        return '';
    }

    export function getEnumStringValueByEnumValue(wccEnum: any, val: any, returnFirstAsDefault?: boolean) {
        for (var i in wccEnum) {
            if (wccEnum[i].value == val) {
                return wccEnum[i].description as string;
            }
        }
        if (returnFirstAsDefault == true)
            return wccEnum[Object.keys(wccEnum)[0]].value as string;

        return '';
    }

    export function getEnumKeyByValue(wccEnum: any, val: string) {
        for (var i in wccEnum) {
            if (wccEnum[i].value == val)
                return i;
        }

        return '';
    }

    export function getEnumObjectByValue(wccEnum: any, val: string, defaultVal?: any) {
        for (var i in wccEnum) {
            if (wccEnum[i].value == val)
                return wccEnum[i];
        }

        return defaultVal;
    }

    //Takes two times in the format 09:30pm and then returns 0 if they are equal, 1 if second is greater than first and -1 if first is greater than second, -2 if there is an error
    export function compareTimes(firstTime: any, secondTime: any) {
        if (StringHelpers.isNullOrEmpty(firstTime) || StringHelpers.isNullOrEmpty(secondTime))
            return 2;

        var currentDate = new Date(),
            date = currentDate.getDate(),
            month = currentDate.getMonth(),
            year = currentDate.getFullYear(),
            firstTimeHour = 0,
            firstTimeMinute = 0,
            secondTimeHour = 0,
            secondTimeMinute = 0;

        try {
            var firstTimeParts = firstTime.split(':'),
                secondTimeParts = secondTime.split(':');

            firstTimeHour = parseInt(firstTimeParts[0]);
            secondTimeHour = parseInt(secondTimeParts[0]);

            if (isNaN(firstTimeHour) || isNaN(secondTimeHour))
                return -2;

            if (firstTimeParts[1].toLowerCase().indexOf("pm") > 0) {
                firstTimeHour += 12;
                firstTimeParts[1] = firstTimeParts[1].replace("pm", "");
            }
            else if (firstTimeParts[1].toLowerCase().indexOf("am") > 0) {
                firstTimeParts[1] = firstTimeParts[1].replace("am", "");
            }

            if (secondTimeParts[1].toLowerCase().indexOf("pm") > 0) {
                secondTimeHour += 12;
                secondTimeParts[1] = secondTimeParts[1].replace("pm", "");
            }
            else if (secondTimeParts[1].toLowerCase().indexOf("am") > 0) {
                if (secondTimeHour == 12)
                    secondTimeHour = 0;

                secondTimeParts[1] = secondTimeParts[1].replace("am", "");
            }

            firstTimeMinute = parseInt(firstTimeParts[1]);
            secondTimeMinute = parseInt(secondTimeParts[1]);

            if (isNaN(firstTimeMinute) || isNaN(secondTimeMinute))
                return -2;

            var firstDate = new Date(year, month, date, firstTimeHour, firstTimeMinute, 0, 0),
                secondDate = new Date(year, month, date, secondTimeHour, secondTimeMinute, 0, 0);

            if (firstDate > secondDate)
                return -1;
            else if (firstDate < secondDate)
                return 1
            else
                return 0;
        }
        catch (e) {
            return -2;
        }
    }

    export function convertFileToDataURLviaFileReader(url: string, callback: (data: any) => void, errCallback: () => void) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = errCallback;
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    export function getFileExtensionFromUrl(url: string) {
        var parts = url.split('.');
        if (parts.length == 0)
            return "";
        return parts[parts.length - 1];
    }

    export function getUrlQueryStringFromObject(obj: any) {
        var qs = '';
        for (var key in obj)
            qs += key + "=" + obj[key] + '&';
        return qs.substr(0, qs.length - 1);
    }

    export function imageURLToBase64(url: string, callBack: (result: string) => void, leavePrefix?: boolean) {
        var resizeImage = function (this: HTMLImageElement) {
            // create an off-screen canvas
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            // set its dimension to target size
            canvas.width = img.width;
            canvas.height = img.height;

            if (ctx) {
                ctx.clearRect(0, 0, this.width, this.height);

                // draw source image into the off-screen canvas:
                ctx.drawImage(img, 0, 0);
            }

            var dataURL = canvas.toDataURL("image/png");
            // encode image to data-uri with base64 version of compressed image
            callBack.call(this, leavePrefix === true ? dataURL : dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
        };

        var img = new Image;
        img.crossOrigin = "Anonymous";
        img.onload = resizeImage as any;
        img.src = url;
    }

    export function imageToBase64(imageDOM: HTMLImageElement, leavePrefix?: boolean) {
        var canvas = document.createElement("canvas");
        canvas.width = imageDOM.width;
        canvas.height = imageDOM.height;

        var ctx = canvas.getContext("2d");
        if (ctx) ctx.drawImage(imageDOM, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        return leavePrefix === true ? dataURL : dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    export function imageToDataUri(url: string, cropWidth: number, cropHeight: number, callBack: (result: string) => void) {
        var resizeImage = function (this: HTMLImageElement) {
            // create an off-screen canvas
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            // set its dimension to target size
            canvas.width = cropWidth;
            canvas.height = cropHeight;

            if (ctx) {
                ctx.clearRect(0, 0, this.width, this.height);

                // draw source image into the off-screen canvas:
                ctx.drawImage(img, -((this.width - cropWidth) / 2), -((this.height - cropHeight) / 2), this.width, this.height);
            }

            // encode image to data-uri with base64 version of compressed image
            callBack.call(this, canvas.toDataURL());
        };

        const img = new Image;
        img.crossOrigin = "Anonymous";
        img.onload = resizeImage as any;
        img.src = url;
    }

    export function getTimeSpanString(secondsSpent: number) {
        const hours = secondsSpent > 3599 ? Math.floor(secondsSpent / 3600) : 0;
        const minutes = secondsSpent > 59 ? Math.floor((secondsSpent - hours * 3600) / 60) : 0;
        const seconds = secondsSpent - hours * 3600 - minutes * 60;
        return (hours ? hours + 'h ' : '') + (minutes || hours && seconds ? minutes + 'm ' : '') + (seconds ? seconds + 's' : '');
    }

    export function initScrollTracking() {
        //
        // deprecated. we don't use scroll. header should always be fixed
        //
        //var scrolled = false;

        //$(window).scroll(function () {
        //    var scrollY = this.scrollY != undefined ? this.scrollY : document.documentElement.scrollTop;
        //    if (scrollY > 20) {
        //        if (!scrolled) {
        //            $('body').addClass('scrolled');
        //            scrolled = true;
        //        }
        //    }
        //    else {
        //        if (scrolled) {
        //            $('body').removeClass('scrolled');
        //            scrolled = false;
        //        }
        //    }
        //});
    }

    export function flattenEnumValues(sourceEnum: any) {
        return _.map(sourceEnum, function (e: any) {
            return { id: '' + e.value + '', text: e.text };
        });
    }

    export function indexOfEnumValue(sourceEnum: any, valueToFind: any) {
        return _.findIndex(sourceEnum, function (item: any) {
            return item.id === valueToFind.toString();
        });
    }

    /**
     * Runs provided func until it returns true.
     * allows to check when some resource gets available when we have no events to hook into
     * @param func function to call
     * @param delay delay between calls
     * @param maxAttemps max attemps before we give up
     */
    export async function awaitResource(func: () => PromiseLike<boolean> | boolean, delay = 30000, maxAttemps = 100) {
        let attemp = 0;
        let result = false;

        while (!result && (!(maxAttemps > 0) || attemp < maxAttemps)) {
            result = await Promise.resolve(func());

            if (!result)
                await Bluebird.delay(delay);
        }

        if (!result)
            throw new Error(`can't load resource`);
    }

    export function extractCDNFileNameFromLink(link: string) {
        const match = link.match(cdnFileNamePattern);

        if (match)
            return match[1];
    }

    export function getDefaultIfES6(module: any) {
        return module?.__esModule ? module.default : module;
    }

    /**
     * Creates promise awaiter object. Allows to map promise into observable
     * result observable has undefined content before promise is resolved
     * @param promiseOrFactory Promise or factory which returns promise
     * @param onError Promise execution error handler
     */
    export function getPromiseAwaiter<T>(promiseOrFactory: Func<PromiseLike<T>> | PromiseLike<T>, onError: Action<[any]> = _.noop) {
        const awaiter: Observable<T | undefined> = ko.observable();
        const promise = 'then' in promiseOrFactory ? promiseOrFactory : promiseOrFactory();

        promise.then(awaiter, ex => {
            console.error(ex);
            onError(ex);            
        });

        return awaiter;
    }

    export function isTheSameList<T>(left: Array<T>, right: Array<T>) {
        return left.length === right.length &&
            left.every((item, idx) => item === right[idx]);
    }

    export function b64toBlob(b64Data: string, contentType: string, sliceSize: number) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    
    export function createThumbnailBlob(videoElement: HTMLVideoElement) {
        let canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        let ctx = canvas.getContext('2d');
        ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        let base64 = canvas.toDataURL('image/png');
        let commaIndex = base64.indexOf(',');

        return b64toBlob(base64.substr(commaIndex + 1), 'image/png', 512);
    }
    
    export function drawBackgroundImageToCanvas(background: HTMLImageElement, ctx: CanvasRenderingContext2D) {
        let bgWidth = background.width;
        let bgHeight = background.height;
        let scale = ctx.canvas.width / bgWidth;
        let scaledBGHeight = Math.trunc(bgHeight * scale);
        if(scaledBGHeight >= ctx.canvas.height) { // fitting background horizontally
            ctx.drawImage(background, 0, - Math.trunc((scaledBGHeight - ctx.canvas.height) / 2), ctx.canvas.width, scaledBGHeight);
        }
        else { // fitting background vertically
            scale = ctx.canvas.height / bgHeight;
            let scaledBGWidth = Math.trunc(bgWidth * scale);
            ctx.drawImage(background, - Math.trunc((scaledBGWidth - ctx.canvas.width) / 2), 0, scaledBGWidth, ctx.canvas.height);
        }
    }

    const changeColorComponent = (baseVal: number, percentage: number) => {
        let absAmount = 2.55 * percentage; // 255 / 100
        return Math.trunc(baseVal + absAmount);
    };
    
    const componentToHex = (c: number) => {
        let hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    };
    const rgbToHex = (r:number, g:number, b:number) => '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
    
    const hexToRgb = (hex: string) => {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };
    const parseRGBColor = (color: string) => {
        color = color.replace('rgb(', '').replace(')', '');
        let strArr: string[] = [];
        if(color.indexOf(',') != -1) {
            strArr = color.split(',', 3);
        }
        else {
            strArr = color.split(' ', 3);
        }
        return _.map(strArr, s => parseInt(s) || 0);
    };
    
    export function lightenDarkenColor(color: string, percentage: number) {
        //let usePound = false;
        let r = 0, g = 0, b = 0;
        if (color[0] == '#' ) {
            color = color.slice(1);
            //usePound = true;

            let num = parseInt(color, 16);

            r = (num >> 16);
            g = (num & 0x0000FF);
            b = (num >> 8) & 0x00FF;
        }
        else {
            [r, g, b] = parseRGBColor(color);
        }
        
        r = changeColorComponent(r, percentage);
        g = changeColorComponent(g, percentage);
        b = changeColorComponent(b, percentage);
        
        if ( r > 255 ) r = 255;
        else if  (r < 0) r = 0;

        if ( g > 255 ) g = 255;
        else if  ( g < 0 ) g = 0;

        if ( b > 255 ) b = 255;
        else if  (b < 0) b = 0;
        
        return `rgb(${r}, ${g}, ${b})`;
        //return '#' + (g | (b << 8) | (r << 16)).toString(16);
    }

    /**
     * Returns the supported mime type by the browser for video recording
     */
    export function getSupportedVideoMediaRecorderMimeType(): MimeTypeData {
        const types = ["webm", "ogg", "mp4", "x-matroska"];
        const codecs = [
            "vp9",
            "vp9.0",
            "vp8",
            "vp8.0",
            "avc1",
            "av1",
            "h265",
            "h.265",
            "h264",
            "h.264",
            "opus",
            "pcm",
            "aac",
            "mpeg",
            "mp4a"
        ];
        return getSupportedMediaRecorderMimeType(types, codecs);
    }

    /**
     * Returns the supported mime type by the browser for audio recording
     */
    export function getSupportedAudioMediaRecorderMimeType(): MimeTypeData {
        const types = ["webm", "ogg", "mp4"];
        const codecs = [
            "opus",
            "pcm",
            "aac",
            "mpeg",
            "mp4a"
        ];
        return getSupportedMediaRecorderMimeType(types, codecs, true);
    }

    /**
     * Returns the supported mime type by the browser for media recording
     * @param types List of media types to check
     * @param codecs List of codec types to check
     * @param forAudio True if this is fetched for audio-only purposes; False otherwise
     */
    function getSupportedMediaRecorderMimeType(types: string[], codecs: string[], forAudio: boolean = false): MimeTypeData {
        const isSupported = MediaRecorder.isTypeSupported;
        const supportedTypes: string[] = [];

        types.forEach((type) => {
            const mimeType = `${forAudio ? "audio" : "video"}/${type}`;
            codecs.forEach((codec) =>
                [
                    `${mimeType};codecs=${codec}`,
                    `${mimeType};codecs:${codec.toUpperCase()}`,
                    `${mimeType}`
                ].forEach((variation) => {
                    if (isSupported(variation)) supportedTypes.push(variation);
                })
            );
            if (isSupported(mimeType)) supportedTypes.push(mimeType);
        });

        const selectedMimeType = supportedTypes[0];
        return { MimeType: selectedMimeType, FileExtension: (selectedMimeType.split("/").pop() ?? "").split(";")[0] };
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CurrentUserManager } from 'managers/currentUser';
import { CollectionDataSource } from 'managers/datasources/collection';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import OnlineUserSession, { JSONOnlineUserSession } from 'models/signalR/onlineUserSession';
import { PeopleService } from 'services/people';

const { discussionId } = settings;

export interface DiscussionOnlinePeopleManagerConfig {
    discussionId: string
}

@injectable()
@storageDefaults(<Partial<DiscussionOnlinePeopleManagerConfig>>{ discussionId })
export default class DiscussionOnlinePeopleManager extends CollectionDataSourceOwner<OnlineUserSession, 'sessionId'> {
    private discussionId: string

    protected source: CollectionDataSource<OnlineUserSession, 'sessionId'>

    sessions: Subscribable<Array<OnlineUserSession>>

    constructor(
        @inject(wccModules.managerConfig) { discussionId }: DiscussionOnlinePeopleManagerConfig,
        @inject(wccModules.peopleService) private peopleService: PeopleService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.discussionId = discussionId;

        const userManager = storage.get(CurrentUserManager, { discussionId });
        const user = userManager.pluck(m => m.person);
        const isModeratorOrObserver = user.pluck(u => u.isModeratorOrObserver, false);

        this.source = effects.register(new CollectionDataSource({
            key: 'sessionId',
            load: this.loadPeople.bind(this),
            mapper: jsonSession => new OnlineUserSession(jsonSession),
            merge: (oldSession, newSession) => oldSession.update(newSession.toJson()),
            isEmpty: true
        }));

        const allSessions = this.source.list;
        const filteredSessions = allSessions.filter(s => !s.isObserver() || isModeratorOrObserver());

        this.sessions = filteredSessions;

        signalREvents.discussionOnlineUsers(discussionId)
            .onUserOnline(this.onUserOnline.bind(this))
            .onUserOffline(this.onUserOffline.bind(this))
            .onUserMovedToTopic(this.onUserMovedToTopic.bind(this))
            .onSubscriptionActivated(() => this.update());

        signalREvents.system()
            .onReconnected(() => this.update());
    }

    private update() {
        this.source.load();
    }

    private loadPeople() {
        return this.peopleService.queries.discussionOnlinePeople(this.discussionId).toArray();
    }

    private onUserOnline(discussionId: string, jsonSession: JSONOnlineUserSession) {
        this.source.add(jsonSession);
    }

    private onUserOffline(discussionId: string, sessionId: string) {
        this.source.remove(session => session.sessionId() == sessionId);
    }

    private onUserMovedToTopic(discussionId: string, sessionId: string, topicId: string) {
        this.source.findSync(sessionId, session => {
            session.topicId(topicId);
        });
    }
}
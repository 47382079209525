import { Observable, ObservableArray } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyStatisticsChoiceQuestion, { JSONSurveyStatisticsChoiceQuestion } from './questions/choiceQuestion';
import SurveyStatisticsRankQuestion, { JSONSurveyStatisticsRankQuestion } from './questions/rankQuestion';
import SurveyStatisticsBlankQuestion, {JSONSurveyStatisticsBlankQuestion} from "./questions/blankQuestion";

export interface JSONSurveyStatisticsPage {
    ProfilePageId: string

    ProfileChoiceQuestions: Array<JSONSurveyStatisticsChoiceQuestion>
    ProfileBlankQuestions: Array<JSONSurveyStatisticsBlankQuestion>
    ProfileRankQuestions: Array<JSONSurveyStatisticsRankQuestion>
}

export default class SurveyStatisticsPage extends WCCEntity<JSONSurveyStatisticsPage> {
    pageId: Observable<string>

    choiceQuestions: ObservableArray<SurveyStatisticsChoiceQuestion>
    blankQuestions: ObservableArray<SurveyStatisticsBlankQuestion>
    rankQuestions: ObservableArray<SurveyStatisticsRankQuestion>

    constructor(data: JSONSurveyStatisticsPage) {
        super();

        this.pageId = ko.observable(data.ProfilePageId);

        this.choiceQuestions = this.createCollection(data, 'ProfileChoiceQuestions', {
            key: 'questionId',
            map: jsonQuestion => new SurveyStatisticsChoiceQuestion(jsonQuestion),
            mapReverse: question => question.toJson(),
            update: (question, newQuestion) => question.update(newQuestion.toJson())
        });

        this.blankQuestions = this.createCollection(data, 'ProfileBlankQuestions', {
            key: 'blankId',
            map: jsonQuestion => new SurveyStatisticsBlankQuestion(jsonQuestion),
            mapReverse: question => question.toJson(),
            update: (question, newQuestion) => question.update(newQuestion.toJson())
        });

        this.rankQuestions = this.createCollection(data, 'ProfileRankQuestions', {
            key: 'questionId',
            map: jsonQuestion => new SurveyStatisticsRankQuestion(jsonQuestion),
            mapReverse: question => question.toJson(),
            update: (question, newQuestion) => question.update(newQuestion.toJson())
        });
    }
}
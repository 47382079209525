import endpoint from 'endpoints/api';
import events from 'managers/events';
import { apiQuickLoginData } from 'models/apiQuickLoginData';
import { apiRequestResult } from 'models/apiRequestResult';
import { JSONDashboardTask } from 'models/dashboard/task';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class TasksService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        dashboardTasks: (token: string) => this.repository.query<never, JSONDashboardTask, never, never, never, never, never>(`dashboard/${token}/discussions`),
        dashboardTaskToDo: (token: string, discussionId: string) => this.repository.fastQuery(`dashboard/${token}/discussions/${discussionId}/todo`),
        taskById: (taskId: string) => this.repository.fastQuery(`task/${taskId}`),
        taskByToken: (token: string) => this.repository.query(`tasks/${token}`),
        agreementStatus: (discussionId: string, taskMemberId: string) => this.repository.query(`discussions/${discussionId}/agreetoterms/${taskMemberId}`),
        agreeToTermsAsPerson: (discussionId: string, personId: string) => this.repository.query(`discussions/${discussionId}/agreetotermsasperson/${personId}`),
        moderatorLogin: () => this.repository.query<never, never, apiQuickLoginData, apiRequestResult<string>, never, never, never>('tasks/startmoderatorlogin'),
        observerLogin: () => this.repository.query<never, never, apiQuickLoginData, apiRequestResult<string>, never, never, never>('tasks/startobserverlogin'),
        getMandatoryProbes: (discussionId: string) => this.repository.fastQuery(`discussions/${discussionId}/mandatoryProbes`)
    }

    /**
    * Returns task by taskId
    * @param taskId - task identity
    */
    getTaskById(taskId: string) {
        return this.queries.taskById(taskId).firstOrDefault();
    }

    /**
     * Returns task by token
     * @param token - token
     */
    getTaskByToken(token: string) {
        return this.queries.taskByToken(token).firstOrDefault();
    }

    /**
     * @description Updates T&C status for a participant
     * @param discussionId - Id of the discussion the participant belongs to
     * @param taskMemberId - Id of the participant/task member who is agreeing to the terms
     * @param data - The data to post (Typically a task member id)
     * @returns {object}
    */
    updateAgreementStatus(discussionId: string, taskMemberId: string, personId: string, data: any) {
        return (this.queries.agreementStatus(discussionId, taskMemberId).update(data) as JQueryPromise<any>)
            .done(() => events.personAgreedToTerms.trigger(personId));
    }

    /**
     * Returns moderator login url
     * @param data - login data
     */
    startModeratorLogin(data: any) {
        return this.queries.moderatorLogin().add(data);
    }

    /**
     * Returns observer login url
     * @param data - login data
     */
    startObserverLogin(data: any) {
        return this.queries.observerLogin().add(data);
    }

    /**
     * Returns topic data and mandatory probes of the first topic in discussion that has mandatory probes by discussionId.
     * @param discussionId - discussion identity
     */
    getMandatoryProbes(discussionId: string) {
        return this.queries.getMandatoryProbes(discussionId).firstOrDefault();
    }

    /**
     * Returns dashboard task todo count
     * @param token - person token
     * @param discussionId - discussion identity 
     */
    getDashboardTaskToDo(token: string, discussionId: string) {
        return this.queries.dashboardTaskToDo(token, discussionId).firstOrDefault();
    }

    agreeToTerms(discussionId: string, personId: string, data: any) {
        return (this.queries.agreeToTermsAsPerson(discussionId, personId).important().update(data) as JQueryPromise<any>)
            .done(() => events.personAgreedToTerms.trigger(personId));
    }
}
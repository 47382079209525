export module NumberHelpers {
    /**
     * The fabric's toFixed version doesn't round the middle values correctly
     * For.e.g, it will round 4.35 as 4.3 whereas the correct value should be 4.4
     * For some sensitive computational cases, use this custom version 
     * @param number
     * @param fractionalDigits
     */
    export function toFixed(number?: number | undefined, fractionalDigits?: number | undefined): string | undefined {
        if (number == null) return "";
        
        if (fractionalDigits == null) {
            fractionalDigits = 0;
        }

        let pow = Math.pow(10, fractionalDigits);
        return (Math.round(number * pow) / pow).toFixed(fractionalDigits);
    }

    export function padStart(input: number, n = 2) {
        const isNeg = input < 0;
        let padded;

        if (isNeg)
            padded = "-" + ("" + -input).padStart(n, "0");
        else
            padded = ("" + input).padStart(n, "0");

        return padded;
    }

    /**
     * Gets next rounded maximum number based on number of digits and decimal places
     * For e.g. - If maxDigits = 5, maxDecimalPlaces = 2, then the number we will get is 1000 (max we can set is 999.99 and 1000 is next rounded)
     * @param maxDigits
     * @param maxDecimalPlaces
     */
    export function getNextMaxNumber(maxDigits: number, maxDecimalPlaces: number = 0) {
        return 10 ** (maxDigits - maxDecimalPlaces);
    }

    export function toHex(number: number, padding = 2) {
        let hex = number.toString(16);

        while (hex.length < padding)
            hex = "0" + hex;

        return hex;
    }
}
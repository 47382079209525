import URLBuilder from 'builders/url';

export module URLHelpers {
    export function removeProtocolFromURL(link: string) {
        const url = new URLBuilder(link);
        url.removeScheme();

        return url.toString();
    }

    export function toStandardS3URL(link: string) {
        return link
            .replace(/(http|https):/, '')
            .replace(/^\/\/s3-/, '//s3.')
            .split('?')[0];
    }

    export function fixURLProtocol(link: string, protocol = 'https') {
        if (link.trim().length == 0)
            return link;

        if (link.indexOf('//') == -1)
            return `${protocol}://${link}`;

        return link;
    }

    export function isS3SignedLink(link ?: string) {
        if (link == undefined || link.length == 0) return false;
        return link.indexOf('X-Amz-Expires') > -1 || link.indexOf('X-Amz-Algorithm') > -1 || link.indexOf('X-Amz-Credential') > -1 || link.indexOf('X-Amz-SignedHeaders') > -1 || link.indexOf('X-Amz-Signature') > -1;
    }

    export function cleanS3SignedLink(link?: string) {
        if (link == undefined || link.length == 0) return false;
        return link.split('?')[0];
    }
}
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./blue.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./blue@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/* iCheck plugin Flat skin, blue\r\n----------------------------------- */\r\n.icheckbox_flat-blue,\r\n.iradio_flat-blue {\r\n    display: inline-block;\r\n    *display: inline;\r\n    vertical-align: middle;\r\n    margin: 0;\r\n    padding: 0;\r\n    width: 20px;\r\n    height: 20px;\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n    border: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.icheckbox_flat-blue {\r\n    background-position: 0 0;\r\n}\r\n    .icheckbox_flat-blue.checked {\r\n        background-position: -22px 0;\r\n    }\r\n    .icheckbox_flat-blue.disabled {\r\n        background-position: -44px 0;\r\n        cursor: default;\r\n    }\r\n    .icheckbox_flat-blue.checked.disabled {\r\n        background-position: -66px 0;\r\n    }\r\n\r\n.iradio_flat-blue {\r\n    background-position: -88px 0;\r\n}\r\n    .iradio_flat-blue.checked {\r\n        background-position: -110px 0;\r\n    }\r\n    .iradio_flat-blue.disabled {\r\n        background-position: -132px 0;\r\n        cursor: default;\r\n    }\r\n    .iradio_flat-blue.checked.disabled {\r\n        background-position: -154px 0;\r\n    }\r\n\r\n/* HiDPI support */\r\n@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {\r\n    .icheckbox_flat-blue,\r\n    .iradio_flat-blue {\r\n        background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n        -webkit-background-size: 176px 22px;\r\n        background-size: 176px 22px;\r\n    }\r\n}", ""]);
// Exports
module.exports = exports;

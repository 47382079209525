import { AttachmentsHelpers } from 'helpers/attachments';
import { ImageHelpers } from 'helpers/images';
import { proxyLinkIfNeeded } from 'helpers/proxy';
import { checkIfVideoExists } from 'helpers/videos';
import { Observable, ObservableArray, SubscribableOrNullableValue } from 'knockout';
import WCCEntity from 'models/entity';
import UserFile, { JSONUserFile } from 'models/userFile';

export interface JSONWCCAttachment {
    AttachmentId?: string
    UserFileId?: string
    ClientContentId?: string
    Title?: string
    QuickSell?: string
    CannedText?: string
    TranscriptContent?: string
    Link?: string
    OriginalLink?: string
    FileType?: number
    CreateDate?: string

    IsHidden?: boolean

    Files?: Array<JSONUserFile>
}

export default class WCCAttachment<T extends JSONWCCAttachment = JSONWCCAttachment> extends WCCEntity<T> {
    attachmentId: Observable<string | undefined>
    userFileId: Observable<string | undefined>
    contentId: Observable<string>
    title: Observable<string>
    quickSell: Observable<string | undefined>
    cannedText: Observable<string | undefined>
    transcriptContent: Observable<string | undefined>
    link: Observable<string | undefined>
    originalLink: Observable<string | undefined>
    fileType: Observable<number | undefined>
    createDate: Observable<string | undefined>

    isHidden: Observable<boolean | undefined>

    files: ObservableArray<UserFile>

    viewTitle = ko.pureComputed(() => this.getViewTitle())
    fakeItem = false;

    isImage = ko.pureComputed(() => AttachmentsHelpers.isImage(this.fileType()));
    isVideo = ko.pureComputed(() => AttachmentsHelpers.isVideo(this.fileType()));
    isAudio = ko.pureComputed(() => AttachmentsHelpers.isAudio(this.fileType()));
    isFile = ko.pureComputed(() => AttachmentsHelpers.isFile(this.fileType()));
    isLink = ko.pureComputed(() => AttachmentsHelpers.isLink(this.fileType()));
    isVideoplayer = ko.pureComputed(() => AttachmentsHelpers.isVideoplayer(this.fileType()));
    isVimeo = ko.pureComputed(() => AttachmentsHelpers.isVimeo(this.fileType()));
    isYouTube = ko.pureComputed(() => AttachmentsHelpers.isYouTube(this.fileType()));
    
    isSurveyResponseAttachment = ko.pureComputed(() => AttachmentsHelpers.isSurveyResponseAttachment(this.fileType()));
    
    // title calculated from file name
    cSafeTitle = ko.pureComputed(() => {
        let safeTitle = this.title();
        if(!this.title() && this.files().length) {
            const fileName = this.files()[0].name() ?? '';
            safeTitle = fileName.substring(0, fileName.lastIndexOf('.'));
        }
        return safeTitle;
    });
    
    // attachment types calculated from attachment files
    cSafeIsImage = 
        ko.pureComputed(() => !_.some(this.files(), f => f.isVideo()) && _.some(this.files(), f => f.isImage()));
    cSafeIsVideo = ko.pureComputed(() => _.some(this.files(), f => f.isVideo()));
    cSafeIcon = ko.pureComputed(() => {
        let icon = 'fa-file';
        if(this.cSafeIsImage())
            icon = 'fa-image';
        else if(this.cSafeIsVideo())
            icon = 'fa-video';
        else {
            let extension = this.files().length ? this.files()[0].extension() : '';
            switch (extension) {
                case 'doc':
                case 'docx':
                case 'txt':
                    icon = 'fa-file-word';
                    break;
                case 'xls':
                case 'xlsx':
                case 'csv':
                    icon = 'fa-file-excel';
                    break;
                case 'ppt':
                case 'pptx':
                    icon = 'fa-file-powerpoint';
                    break;
                case 'pdf':
                    icon = 'fa-file-pdf';
                    break;
                case 'zip':
                case 'rar':
                    icon = 'fa-file-archive';
                    break;
            }
        }
        return icon;
    });

    constructor(data?: T) {
        super();

        const jsonAttachment = <JSONWCCAttachment | undefined>data;
        
        this.attachmentId = this.createField(jsonAttachment, 'AttachmentId');
        this.userFileId = this.createField(jsonAttachment, 'UserFileId');
        this.contentId = this.createField(jsonAttachment, 'ClientContentId', system.getGuid);
        this.title = this.createField(jsonAttachment, 'Title', '');
        this.quickSell = this.createField(jsonAttachment, 'QuickSell');
        this.cannedText = this.createField(jsonAttachment, 'CannedText');
        this.transcriptContent = this.createField(jsonAttachment, 'TranscriptContent');
        this.link = this.createField(jsonAttachment, 'Link').mapNotNull(link => proxyLinkIfNeeded(link));
        this.originalLink = this.createField(jsonAttachment, 'OriginalLink');
        this.fileType = this.createField(jsonAttachment, 'FileType');
        this.createDate = this.createField(jsonAttachment, 'CreateDate');

        this.isHidden = this.createField(jsonAttachment, 'IsHidden');

        this.files = this.createCollection(jsonAttachment, 'Files', jsonFile => new UserFile(jsonFile));
    }

    protected getObservableFile(type: number, fallbackFiles: Array<SubscribableOrNullableValue<UserFile>> = []) {
        return ko.pureComputed(() => this.files().find(file => file.type() === type) ??
            fallbackFiles.reduce((result: UserFile | undefined, file) => result ?? ko.unwrap(file), undefined as UserFile | undefined));
    }

    /**
     * Returns observable file which might be unavailable at the moment
     * Observable contains undefined while file is unavailable
     * @param type file type. used to find file inside all files collection
     */
    protected getObservablePendingFile(type: number) {
        const pendingFile = this.getObservableFile(type);

        const pendingFileAwaiter = ko.pureComputed(() => {
            const file = pendingFile();
            
            if (file != undefined) {
                const link = file.link();

                if (link != undefined) {
                    return system.getPromiseAwaiter(async () => {
                        await system.awaitResource(() => file.isVideo() ? checkIfVideoExists(link) : ImageHelpers.checkIfImageExists(link), 5000, 1000);
                        return file;
                    });
                }
            }

            return undefined;
        });

        return ko.pureComputed(() => ko.unwrap(pendingFileAwaiter()));
    }

    protected getViewTitle() {
        return this.title();
    }
}
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import EntityDataSourceOwner from 'managers/entityDataSourceOwner';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import SurveyStatistics, { JSONSurveyStatistics } from 'models/statistics/survey/survey';
import { StatisticsService } from 'services/statistics';

interface SurveyStatisticsManagerConfig {
    topicId: string
}

interface RegularFiltersData {
    searchedText: string
    participantsFilter: string
    filtersData: any
}

interface AdvancedFiltersData {
    useAdvancedFiltering: boolean
    filtersData: any
}

@injectable()
export default class SurveyStatisticsManager extends EntityDataSourceOwner<JSONSurveyStatistics> {
    private topicId: string

    protected source: EntityDataSource<JSONSurveyStatistics>

    stat: Subscribable<SurveyStatistics | undefined>
    filterData = ko.observable<RegularFiltersData | AdvancedFiltersData>().extend({ rateLimit: 250 });

    constructor(
        @inject(wccModules.managerConfig) { topicId }: SurveyStatisticsManagerConfig,
        @inject(wccModules.statisticsService) private statisticsService: StatisticsService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.topicId = topicId;

        this.source = effects.register(new EntityDataSource({
            update: this.load.bind(this)
        }));        

        this.stat = this.source.data.toEntity(jsonStat => new SurveyStatistics(jsonStat), (stat, jsonStat) => {
            stat.update(jsonStat);
        });

        signalREvents.surveyResponses(topicId).onSurveyResponsesChanged(this.onSurveyResponsesChanged.bind(this))

        effects.register([
            this.filterData.subscribe(() => this.source.update())
        ]);
    }

    private load() {
        const filterData = this.filterData();

        if (filterData == undefined || !('useAdvancedFiltering' in filterData)) {
            const query = this.statisticsService.queries.surveyStat(this.topicId).background();

            if (filterData != undefined) {
                return query
                    .addArgs({ 'searchText': filterData.searchedText })
                    .firstOrDefaultPost({
                        FiltersData: filterData.filtersData,
                        ParticipantsFilter: filterData.participantsFilter
                    });
            } else {
                return query.firstOrDefault();
            }
        } else {
            return this.statisticsService.queries.surveyStatWithAdvancedFiltering(this.topicId).background().firstOrDefaultPost(filterData.filtersData);
        }
    }

    private onSurveyResponsesChanged(topicId: string) {
        if (this.topicId == topicId)
            this.source.update();
    }
}
import topicWidgetVariablesMappings, { TopicWidgetVariablesMapping } from 'data/topic/widgetVariablesMappings';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { SubscribableOrNullableValue } from 'knockout';
import ICommunityTemplateTopicAvailableWidgetVariablesLoader from './iAvailable';
import ICommunityTemplateTopicWidgetVariableValueLoader from './iValue';

@injectable()
export default class CommunityTemplateTopicAvailableWidgetVariablesLoader implements ICommunityTemplateTopicAvailableWidgetVariablesLoader {
    constructor(
        @inject(wccModules.communityTemplateTopicWidgetVariableValueLoader) private topicWidgetVariableValueLoader: ICommunityTemplateTopicWidgetVariableValueLoader
    ) { }

    load(topicId: SubscribableOrNullableValue<string>, type: SubscribableOrNullableValue<number>, allowEmptyBindings: SubscribableOrNullableValue<boolean>) {
        const fType = ko.flattenComputed(type);
        const fAllowEmptyBindings = ko.flattenComputed(allowEmptyBindings, false);

        const mappings = fType.pluck(type => topicWidgetVariablesMappings.filter(mapping => mapping.types.includes(type)), new Array<TopicWidgetVariablesMapping>());

        const mappingsWithValueWrappers = mappings
            .map(mapping => ({ mapping, valueWrapper: this.topicWidgetVariableValueLoader.load(topicId, mapping.id) }))
            .pluck(({ mapping, valueWrapper }) => ({ mapping, value: valueWrapper().value, isReady: valueWrapper().isReady }));

        return ko.pureComputed(() => {
            const items = mappingsWithValueWrappers();

            const isReady = items.every(wrapper => wrapper.isReady);
            const mappings = items.reduce((result, { mapping, value }) => {
                if (fAllowEmptyBindings()) {
                    result.push(mapping);
                } else {
                    if (_.isString(value)) {
                        if (value.length > 0)
                            result.push(mapping);
                    } else if (value != undefined) {
                        result.push(mapping);
                    }
                }

                return result;
            }, new Array<TopicWidgetVariablesMapping>());

            return { isReady, mappings }
        });
    }
}
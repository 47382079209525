import apiEndpoint from "endpoints/api";
import { injectable } from "inversify";
import { JSONParticipantTopicCompletionActivity } from "models/topics/topicCompletionActivity";
import GenericRepository from "repositories/generic";
import { ITopicCompletionActivitiesService } from "./topicCompletionActivities.interfaces";

@injectable()
export default class TopicCompletionActivitiesService implements ITopicCompletionActivitiesService {
    private repository = new GenericRepository(apiEndpoint);

    topicCompletionActivityQuery = (topicId: string, personId: string) => this.repository.entityGetQuery<JSONParticipantTopicCompletionActivity>(`topics/${topicId}/people/${personId}/completionactivity`);
}
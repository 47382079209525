import { wccModules } from "enums/wccModules";
import { Disposable } from "interfaces/disposable";
import { inject, injectable } from "inversify";
import { SignalRHelpers } from "./helpers";
import { ISignalRCoreEventsManager } from "./interfaces/events";
import { ISignalRCoreModulesManager } from "./interfaces/modules";
import { ISignalRCoreSubscriptionsManager } from "./interfaces/subscriptions";
import { SignalRModule, SignalRModuleData } from "./models/module";

@injectable()
export default class SignalRCoreModulesManager implements ISignalRCoreModulesManager {
    private modules = ko.observableArray<SignalRModule>();

    constructor(
        @inject(wccModules.signalRSubscriptionsManager) private subscriptionsManager: ISignalRCoreSubscriptionsManager,
        @inject(wccModules.signalREventsManager) private eventsManager: ISignalRCoreEventsManager
    ) { }

    add(data: SignalRModuleData) {
        var exists = this.modules().some(module => module.data === data);

        if (!exists) {
            SignalRHelpers.logInfo('loading module', data);

            const subscriptions = (data.subscriptions ?? [])
                .concat(data.msubscriptions ?? [])
                .map(([name, ...args]) => <Disposable>this.subscriptionsManager.subscribe(name, ...args));

            var handlers = (data.handlers ?? []).map(([name, action]) => <Disposable>this.eventsManager.on(name, action));

            this.modules.push({
                data: data,
                subscriptions: subscriptions,
                handlers: handlers
            });

            SignalRHelpers.logInfo('module loaded', data);
        }
    }

    remove(data: SignalRModuleData) {
        var module = this.modules().find(module => module.data === data);

        if (module !== undefined) {
            SignalRHelpers.logInfo('unloading module', data);

            module.subscriptions.forEach(s => s.dispose());
            module.handlers.forEach(h => h.dispose());

            this.modules.remove(module);

            SignalRHelpers.logInfo('module unloaded', data);
        }
    }
}
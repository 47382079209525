import { BaseService } from "./base";
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { TwoFactorAuthTypes } from "../components/security/twofactorauth/twofactorauthsetup";
import { tfaAuthenticated, tfaAuthenticatorUri, tfaAuthState, tfaRemoveOptions, tfaRequestCode, tfaRequestOptions, tfaResetOptions, tfaResetResult, tfaVerifyData, tfaVerifyResult } from "../components/security/twofactorauth/models/twofactorauth-models";

export class TwoFactorAuthService extends BaseService {
    private repository = new Repository(endpoint);
    
    queries = {
        isTwoFactorAuthenticated: (discussionId: string) => this.repository.query(`v3/people/twofactorauthenticated/${discussionId}`),
        setupTwoFactorAuth: () => this.repository.query('v3/people/setuptwofactorauth'),
        getAuthenticatorUri: () => this.repository.query('v3/people/getAuthenticatorUri'),
        removeTwoFactorAuth: () => this.repository.query('v3/people/removeTwoFactorAuth'),
        getTwoFactorAuthOptions: () => this.repository.query('v3/account/twofactorauthoptions'),
        resetTwoFactorAuthenticatorLockout: () => this.repository.query('v3/people/resetTwoFactorAuthenticatorLockout'),
        requestTwoFactorAuthCode: () => this.repository.query('v3/people/requesttwofactorauthcode'),
        verifyTwoFactorAuthCode: () => this.repository.query(`v3/people/verifytwofactorauthcode`),
        getTwoFactorAuthState: (authType: TwoFactorAuthTypes) => this.repository.query(`v3/people/twofactorauthstate/${authType}`),
    }

    isTwoFactorAuthenticated(discussionId?: string): PromiseLike<tfaAuthenticated> {
        return this.queries.isTwoFactorAuthenticated(discussionId ?? '').firstOrDefault();
    }

    /**
     * Apply user choice for two factor auth type
     * @param {any} authType
     */
    setupTwoFactorAuth(options: tfaRequestOptions): PromiseLike<TwoFactorAuthTypes> {
        return this.queries.setupTwoFactorAuth().firstOrDefaultPost(options);
    }

    /*
    *   Creates a new MFA key then returns the authenticator uri for the user
    */
    setupAuthenticator(): PromiseLike<tfaAuthenticatorUri> {
        return this.queries.getAuthenticatorUri().update();
    }

    /**
     * Remove two factor auth with password challenge
     * @param {any} opts
     */
    removeTwoFactorAuth(opts: tfaRemoveOptions): PromiseLike<boolean> {
        return this.queries.removeTwoFactorAuth().firstOrDefaultPost(opts);
    }

    /**
     * Get the accounts two-factor-auth options
     * */
    getTwoFactorAuthOptions() : PromiseLike<TwoFactorAuthTypes> {
        return this.queries.getTwoFactorAuthOptions().firstOrDefault();
    }

    /**
     * Return state data including user identifier (email/mobile) but partially obscured e.g. ***@domain.com, ***-***-1234.
     * If they have mobile, whether a code has been requested, error or lockout time in seconds.
     * */
    getTwoFactorAuthState(authType: TwoFactorAuthTypes): PromiseLike<tfaAuthState> {
        return this.queries.getTwoFactorAuthState(authType).firstOrDefault();
    }

    /**
     * Reset the lockout when authenticator failed verification 3 times.
     * @param opts
     * @returns
     */
    resetTwoFactorAuthenticatorLockout(opts: tfaResetOptions): PromiseLike<tfaResetResult> {
        return this.queries.resetTwoFactorAuthenticatorLockout().update(opts);
    }

    /**
     * Request a code to be sent to logged in user via the authtype passed
     * @param {any} authType
     */
    requestTwoFactorAuthCode(opts: tfaRequestOptions): PromiseLike<tfaRequestCode> {
        return this.queries.requestTwoFactorAuthCode().firstOrDefaultPost(opts);
    }

    /**
     * Verify two facto auth code provided by user
     * @param {any} verifyData
     */
    verifyTwoFactorAuthCode(verifyData: tfaVerifyData) : PromiseLike<tfaVerifyResult> {
        return this.queries.verifyTwoFactorAuthCode().firstOrDefaultPost(verifyData);
    }    
}
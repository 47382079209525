import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class EventsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            discussionEventsSearch: discussionId => this._repository.query(`discussions/${discussionId}/userevents/search`),
            discussionEvents: discussionId => this._repository.fastQuery(`discussions/${discussionId}/userevents`),
            discussionEvent: (discussionId, eventId) => this._repository.fastQuery(`discussions/${discussionId}/userevents/${eventId}`),
            topicEvents: topicId => this._repository.fastQuery(`topics/${topicId}/userevents`),
            topicEvent: (topicId, eventId) => this._repository.fastQuery(`topics/${topicId}/userevents/${eventId}`),
            personEvents: (discussionId, personId) => this._repository.fastQuery(`discussions/${discussionId}/people/${personId}/userevents`),
            personEvent: (discussionId, personId, eventId) => this._repository.fastQuery(`discussions/${discussionId}/people/${personId}/userevents/${eventId}`)
        };

        this.subscriptions = {
            discussionEvents: this.signalRSubscription('DiscussionEvents')
        };

        this.events = {
            newEvent: this.signalREvent('NewEvent'),
            eventChanged: this.signalREvent('EventChanged'),
            eventDeleted: this.signalREvent('EventDeleted')
        };
    }

    getDiscussionEvents(discussionId, oData) {
        return this.queries.discussionEvents(discussionId).addArgs(oData).toArray();
    }

    searchDiscussionEvents(discussionId, query, oData) {
        return this.queries.discussionEventsSearch(discussionId).addArgs(oData).toArrayPost(query);
    }

    getDiscussionEvent(discussionId, eventId, oData) {
        return this.queries.discussionEvent(discussionId, eventId).addArgs(oData).firstOrDefault();
    }

    getTopicEvents(topicId, oData) {
        return this.queries.topicEvents(topicId).addArgs(oData).toArray();
    }

    getTopicEvent(topicId, eventId, oData) {
        return this.queries.topicEvent(topicId, eventId).addArgs(oData).firstOrDefault();
    }

    getPersonEvents(discussionId, personId, oData) {
        return this.queries.personEvents(discussionId, personId).addArgs(oData).toArray();
    }

    getPersonEvent(discussionId, personId, eventId, oData) {
        return this.queries.personEvent(discussionId, personId, eventId).addArgs(oData).firstOrDefault();
    }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class LogsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.adminQueries = {
            logs: (authToken) => this._repository.fastQuery(`logs?auth=${authToken}`),
        }

        this.superAdminQueries = {
            logs: () => this._repository.fastQuery(`superadmin/logs`),
        }
    }
}
import { Observable, ObservableArray } from 'knockout';
import SurveyStatisticsChoiceQuestionAnswer, { JSONSurveyStatisticsChoiceQuestionAnswer } from './choiceAnswer';
import SurveyStatisticsQuestion, { JSONSurveyStatisticsQuestion } from './question';
import SurveyStatisticsBlankQuestionAnswer, {JSONSurveyStatisticsBlankQuestionAnswer} from "./blankAnswer";

export interface JSONSurveyStatisticsBlankQuestion extends JSONSurveyStatisticsQuestion {
    ProfileQuestionBlankId: string
    RespondentsCount?: number

    ProfileAnswers: Array<JSONSurveyStatisticsBlankQuestionAnswer>
}

export default class SurveyStatisticsBlankQuestion extends SurveyStatisticsQuestion<SurveyStatisticsBlankQuestionAnswer, JSONSurveyStatisticsBlankQuestion> {
    blankId: Observable<string>
    respondentsCount: Observable<number>

    answers: ObservableArray<SurveyStatisticsBlankQuestionAnswer>

    constructor(data: JSONSurveyStatisticsBlankQuestion) {
        super(data);

        this.blankId = this.createField(data, 'ProfileQuestionBlankId');
        this.respondentsCount = this.createField(data, 'RespondentsCount', 0);

        this.answers = this.createCollection(data, 'ProfileAnswers', {
            key: 'blankOptionId',
            map: jsonAnswer => new SurveyStatisticsBlankQuestionAnswer(jsonAnswer),
            mapReverse: answer => answer.toJson(),
            update: (answer, newAnswer) => answer.update(newAnswer.toJson())
        });
    }
}
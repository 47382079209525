import { Zone, ZoneOffsetFormat, ZoneOffsetOptions } from "luxon";
import { DateHelpers } from "../../helpers/date";
import { NumberHelpers } from "../../helpers/number";
import DateDecoder from "../../managers/dateDecoder";
import { JSONTimezone } from "./timezone";

const padStart = NumberHelpers.padStart;
const chaosTimezoneType = 'chaos';

function formatOffset(offset: number, format: ZoneOffsetFormat = 'short') {
    const hours = Math.trunc(Math.abs(offset / 60));
    const minutes = Math.trunc(Math.abs(offset % 60));
    const sign = offset >= 0 ? "+" : "-";

    switch (format) {
        case "short":
            return `${sign}${padStart(hours, 2)}:${padStart(minutes, 2)}`;
        case "narrow":
            return `${sign}${hours}${minutes > 0 ? `:${minutes}` : ""}`;
        case "techie":
            return `${sign}${padStart(hours, 2)}${padStart(minutes, 2)}`;
        default:
            throw new RangeError(`Value format ${format} is out of range for property format`);
    }
}

export default class ChaosTimezone extends Zone {
    override get type() { return chaosTimezoneType };
    override get name() { return this.data.Id }
    override get isUniversal() { return this.data.DstOffset == this.data.StdOffset }
    override get isValid() { return this.data.Id.length > 0 }

    constructor(private data: JSONTimezone) {
        super();
    }

    override offsetName(ts: number, options: ZoneOffsetOptions) {
        return this.IsInDst(new Date(ts)) ?
            (this.data.DstName.length > 0 ? this.data.DstName : this.data.Country) :
            (this.data.StdName.length > 0 ? this.data.StdName : this.data.Country);
    }

    override formatOffset(ts: number, format: ZoneOffsetFormat) {
        return formatOffset(this.IsInDst(new Date(ts)) ? this.data.DstOffset : this.data.StdOffset, format);
    }

    override offset(ts: number) {
        return this.IsInDst(new Date(ts)) ? this.data.DstOffset : this.data.StdOffset;
    }

    override equals(other: Zone) {
        return other.type == chaosTimezoneType && this.name == other.name;
    }

    private IsInDst(dateTimeIn: Date) {
        if (this.isUniversal)
            return false;

        const dstStartPattern = this.data.DstStart;
        const dstEndPattern = this.data.DstEnd;

        if (dstStartPattern.trim().length == 0)
            return false;

        if (dstEndPattern.trim().length == 0)
            return false;

        let stdOffset = this.data.StdOffset,
            dstOffset = this.data.DstOffset;

        let dstStart = DateHelpers.addMinutes(DateDecoder.decode(dstStartPattern, dateTimeIn.getUTCFullYear()), -stdOffset);
        let dstEnd = DateHelpers.addMinutes(DateDecoder.decode(dstEndPattern, dateTimeIn.getUTCFullYear()), -dstOffset);

        if (dstStart.getTime() - dstEnd.getTime() > 0) {
            if (dateTimeIn.getTime() - dstStart.getTime() >= 0) {
                dstEnd = DateHelpers.addMinutes(DateDecoder.decode(dstEndPattern, dateTimeIn.getUTCFullYear() + 1), -dstOffset);
            } else {
                dstStart = DateHelpers.addMinutes(DateDecoder.decode(dstStartPattern, dateTimeIn.getUTCFullYear() - 1), -stdOffset);
            }
        }

        if (dateTimeIn.getTime() - dstStart.getTime() >= 0 && dateTimeIn.getTime() - dstEnd.getTime() < 0)
            return true;

        return false;
    }
}
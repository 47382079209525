import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import events from 'managers/events';
import TasksQueueManager from 'managers/tasksQueue';
import { TopicThread } from 'models/topicThread';
import { CommentsService } from 'services/comments';

@injectable()
export default class TopicMessagesSaver {
    private queue = new TasksQueueManager();

    constructor(
        @inject(wccModules.commentsService) private commentsService: CommentsService
    ) { }

    save(message: TopicThread) {
        if (message.id() == undefined)
            events.chatMessagePosted.trigger(<string>message.topicId(), message);

        this.queue.run(async () => {
            try {
                const jsonMessage = message.id() == undefined ?
                    await this.commentsService.addChatMessage(message.toJson()) :
                    await this.commentsService.updateChatMessage(message.toJson());                

                message.update(jsonMessage);
                message.syncRequired(false);
            } catch (ex) {
                system.handleError(ex);
                message.syncRequired(true);
            }
        });
    }
}
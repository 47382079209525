import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import DiscussionManager from 'managers/discussion';
import { IWCCStorageManager } from 'managers/iStorage';
import { EffectsContainer } from '../../mixins/withEffects';
import { JSONTwoFactorAuthorisation, TwoFactorAuthorisation } from '../../models/security/twofactorauth';
import { TwoFactorAuthService } from '../../services/twofactorauth';
import { EntityDataSource } from '../datasources/entity';

const { discussionId } = settings;

interface TwoFactorAuthManagerConfig {
    discussionId: string
}

@injectable()
@storageDefaults(<Partial<TwoFactorAuthManagerConfig>>{ discussionId })
export default class TwoFactorAuthManager {
    private source: EntityDataSource<JSONTwoFactorAuthorisation>

    private twoFactorAuthService: TwoFactorAuthService;

    busy: Subscribable<boolean>
    ready = ko.observable<boolean>(false);

    requiresTwoFactorAuthVerification: Subscribable<boolean | undefined>
    
    constructor(
        @inject(wccModules.managerConfig) { discussionId }: TwoFactorAuthManagerConfig,
        @inject(wccModules.twoFactorAuthService) twoFactorAuthService: TwoFactorAuthService,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.twoFactorAuthService = twoFactorAuthService;

        const discussionManager = storage.get(DiscussionManager, { discussionId });
        const discussion = discussionManager.pluck(m => m.discussion);
        const discussionReady = discussion.isNotNull();
        
        const userManager = storage.get(CurrentUserManager, { discussionId });
        const user = userManager.pluck(m => m.person);
        const userReady = userManager.pluck(m => !m.loading(), false);
        const isLoggedIn = user.isNotNull();

        // added our 2fa authentication result to the effects container so we can update it when we are logged in
        this.source = effects.register(new EntityDataSource<JSONTwoFactorAuthorisation>({
            update: () => {
                return isLoggedIn() ? twoFactorAuthService.isTwoFactorAuthenticated(discussionId) : undefined
            }
        }));

        const twoFactorAuthResult = this.source.data.toEntity(
            tfaResult => new TwoFactorAuthorisation(tfaResult),
            (tfaAuth, json2FA) => tfaAuth.update(json2FA));

        // this subscribe is key to triggering the source update so we get our 2fa authentication result once
        // we are logged in
        isLoggedIn.subscribe(loggedIn => loggedIn && this.source.update());
                
        // we have 2fa enabled on our discussion, are logged in and need to check if we have verified 2fa code
        this.requiresTwoFactorAuthVerification = ko.pureComputed(() => {
            return isLoggedIn() &&                
                twoFactorAuthResult()?.is2faRequired() &&
                !twoFactorAuthResult()?.is2faAuthenticated();                
        })
        
        this.busy = ko.pureComputed(() => !discussionReady() || !userReady() || this.source.updating());        
    }
}
define('models/participantStat', [
    'models/commentsDuringTime'
], function (CommentsStat) {
    function Model(jsonStat) {
        var commentsStat = _(jsonStat.CommentsStat).map(function (cs) { return new CommentsStat(cs) });

        this.personId = ko.observable(jsonStat.PersonId || '');
        this.discussionId = ko.observable(jsonStat.DiscussionId || '');
        this.topicsActivity = ko.observable(jsonStat.TopicsActivity || 0.0);
        this.unreadCommentsCount = ko.observable(jsonStat.UnreadCommentsCount || 0);
        this.imagesCount = ko.observable(jsonStat.ImagesCount || 0);
        this.videosCount = ko.observable(jsonStat.VideosCount || 0);
        this.filesCount = ko.observable(jsonStat.FilesCount || 0);
        this.pollResponsesCount = ko.observable(jsonStat.PollResponsesCount || 0);        
        
        this.commentsStat = ko.observableArray(commentsStat);
        
    }

    return Model;
});
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class TaskMembersService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            memberTasksById: (taskMemberId, isStagingSite) => this._repository.query((isStagingSite ? 'staging/' : '') + `member/tasks/${taskMemberId}`)
        };
    }

    /**
    * Returns all member tasks by memberId
    * @param {string} taskMemberId - member identity
    * @param {boolean} isStagingSite - is staging site
    */
    getMemberTasksById(taskMemberId, isStagingSite) {
        return this.queries.memberTasksById(taskMemberId, isStagingSite).toArray();
    }

    /**
    * Returns first member task by memberId
    * @param {string} taskMemberId - member identity
    * @param {boolean} isStagingSite - is staging site
    */
    getMemberTaskById(taskMemberId, isStagingSite) {
        return this.queries.memberTasksById(taskMemberId, isStagingSite).firstOrDefault();
    }
}
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import { CookieClassification } from '../helpers/cookie';

export class LoginService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            personLoginExistence: (discussionId, emailAddress) => this._repository.query(`people/auth/${discussionId}/${btoa(emailAddress)}?domainId=` + WCC.getSetting('domainId')),
            logPersonIn: (discussionId, emailAddress, password) => this._repository.query(`people/login/${btoa(emailAddress)}/${system.toBase64(password)}` +
                `?discussionId=${discussionId}` + (discussionId == null || discussionId.length == 0 ? '?' : '&') + `domainId=${WCC.getSetting('domainId')}`),
            logPersonInPost: () => this._repository.query('peoplelogin/authenticate'),
            sendResetPasswordLink: (discussionId, emailAddress, isStaging) => this._repository.query((discussionId ?
                `people/emails/resetpassword/${discussionId}/${btoa(emailAddress)}` :
                `people/emails/resetpassword/${btoa(emailAddress)}`) + (isStaging ? '?staging=true' : '')),
            sendResetPasswordLinkPost: (isStaging) => this._repository.postQuery('community/emails/resetpassword/').addArgs(isStaging),
            reset: emailCode => this._repository.query(`people/password/${emailCode}`),
            resetSms: emailCode => this._repository.query(`people/password/${emailCode}?batchType=SMS`),
            requireSmsVerification: (emailCode) => this._repository.query(`people/requiresmsverification/${emailCode}`),
            getPartialMobilePhone: (emailCode) => this._repository.query(`people/getpartialmobilephone/${emailCode}`),
            sendSmsCode: (emailCode) => this._repository.query(`people/sendsmsverificationcode/${emailCode}`),
            verifySmsCode: (emailCode) => this._repository.query(`people/verifysmscode/${emailCode}`)
        };
    }

    /**
     * Check if we need to verify by sms
     * @param {any} emailCode
     */
    requireSmsVerification(emailCode) {
        return this.queries.requireSmsVerification(emailCode).firstOrDefault();
    }

    /**
     * Returns a non identifying last 3 digits of a mobile attached to the emailcode
     * @param {any} emailCode
     */
    getPartialMobilePhone(emailCode) {
        return this.queries.getPartialMobilePhone(emailCode).firstOrDefault();
    }

    /**
     * Send an sms code to activating user
     * @param {any} smscode
     */
    sendSmsCode(emailCode) {
        return this.queries.sendSmsCode(emailCode).firstOrDefaultPost();
    }

    /**
     * Validate sms code against activating user
     * @param {any} smscode
     */
    verifySmsCode(emailCode, smscode) {
        return this.queries.verifySmsCode(emailCode).firstOrDefaultPost({ smscode: smscode });
    }

    /**
     * Checks whether a user with given email address has login in a given discussion
     * @param {string} discussionId - discussion identity
     * @param {string} emailAddress - email address to check
     */
    checkPersonLoginExistence(discussionId, emailAddress) {
        return this.queries.personLoginExistence(discussionId, emailAddress).firstOrDefault();
    }

    /**
     * Sends login creation link to a given email address
     * @param {string} discussionId - discussion identity
     * @param {string} emailAddress - email address that requires a link
     */
    sendPersonLoginCreationLink(discussionId, emailAddress) {
        return this.queries.personLoginExistence(discussionId, emailAddress).add();
    }

    /**
     * Checks user's credentials
     * @param {string} discussionId - discussion identity
     * @param {string} emailAddress - email address of a user
     * @param {string} password - user's password
     */
    logPersonIn(discussionId, emailAddress, password) {
        return this.queries.logPersonIn(discussionId, emailAddress, password).firstOrDefault();
    }

    /**
    * Checks user's credentials via POST request
    * @param {object} data - data object containing credentials and discussionid
    */
    logPersonInPost(data) {
        // add community domain (if applicable)
        var domainId = WCC.getSetting('domainId');
        if (domainId != null && domainId.length > 0) data.domainId = domainId;
        
        return this.queries.logPersonInPost().firstOrDefaultPost(data);
    }

    /**
     * Sends password reset link to a given email address
     * @param {string} discussionId - discussion identity
     * @param {string} emailAddress - email address that requires a link
     * @param {boolean} isStaging - is staging site
     */
    sendResetResetLink(discussionId, emailAddress, isStaging) {
        return this.queries.sendResetPasswordLink(discussionId, emailAddress, isStaging).add();
    }

    /**
     * Sends password reset link to a given email address as a POST
     * @param {any} emailAddress
     * @param {any} isStaging
     */
    sendResetLinkPost(emailAddress, isStaging) {
        return this.queries.sendResetPasswordLinkPost(isStaging).post({ email: emailAddress });
    }

    /**
     * Resets user's password
     * @param {string} emailCode - Unique code from email that identifies a user
     * @param {object} passwordData - new password data
     */
    resetPassword(emailCode, passwordData, isSMS = false) {
        if (isSMS) {
            return this.queries.resetSms(emailCode).update(passwordData);
        }
        else {
            return this.queries.reset(emailCode).update(passwordData);
        }
    }

    setToken(token) {
        settings.isLoggedIn(true);
        settings.currentUserLoginToken(token);
        settings.loginToken(token);
        WCC.API.setLoginToken(token);

        system.setCookie('CurrentUserLoginToken', token, 30, CookieClassification.Essential);
    }

    dropToken() {
        settings.isLoggedIn(false);
        settings.currentUserLoginToken('');
        WCC.API.setToken('');
        WCC.API.setLoginToken('');
        settings.loginToken(null);
        WCC.setSetting("CurrentUserLoginToken", null);
        WCC.setSetting("CurrentUserToken", null);
        // we have user data in three different fields (WTF???!!!). so, we need to clear all of them to not break anything
        WCC.currentUser = {};
        WCC.Settings.CurrentUser = {};
        settings.currentUser = {};

        system.deleteCookie('CurrentUserLoginToken');
    }
}
import { AsyncDisposer, Disposer } from "interfaces/disposer";
import { Func } from "interfaces/func";
import ko, { Observable, Subscribable, UnwrappedTuple } from "knockout";
import { EffectFactory, withEffect } from "./withEffect";

export interface EffectsContainer {
    /**
     * registers <data> to be disposed on container disposal
     * @param data
     */
    register<T>(data: T): T

    /**
     * Register factory result to be disposed on container disposal
     * @param factory
     */
    register(factory: EffectFactory<[]>): EffectFactory<[]>

    /**
     * Execute factory on dependencies change and register result to be disposed on change or container disposal
     * @param factory
     * @param deps
     * @param extenders ko extenders to use on changes detection computed
     */
    register<T extends Array<any>>(factory: EffectFactory<[...T]>, deps: [...T], extenders?: ko.ObservableExtenderOptions<void>): EffectFactory<[...T]>

    /**
     * Execute factory on dependencies change and register result to be disposed on change or container disposal
     * @param factory 
     * @param deps 
     * @param async use deferred changes detection. true by default
     */
    register<T extends Array<any>>(factory: EffectFactory<[...T]>, deps: [...T], async?: boolean): EffectFactory<[...T]>

    /**
     * executes factory on dependencies changes and provide new effects container for each factory call
     * so it can be used for inner effects registration
     * provided container is disposed on change or root container disposal
     * @param factory
     * @param deps
     */
    scope<T extends Array<any>>(factory: Action<[...UnwrappedTuple<T>, EffectsContainer]>, deps: [...T]): void

    /**
     * creates observable container with generated instance inside using factory
     * @param factory function which generates new instance from provided args
     * @param deps subscribable or simple value args
     */
    factory<T extends Array<any>, R>(factory: Func<R | undefined, [...UnwrappedTuple<T>]>, deps: [...T]): Subscribable<R | undefined>

    /**
     * saves state of provided subscribables so they can be reset on disposal
     * @param action inner scope where we can modify state
     * @param state
     */
    saveState<T extends Array<any>>(...state: [...T]): void

    /**
     * disposes container and all registered effects
     * */
    dispose(): void

    isDisposed: Subscribable<boolean>
}

export function withEffects(): EffectsContainer {
    const disposers = new Array<Disposer | AsyncDisposer>();
    const savedStates = new Map<Subscribable<any>, any>();

    let isDisposed = ko.observable(false);

    function register(data: any, deps: Array<any> = [], asyncOrExtenders?: boolean | ko.ObservableExtenderOptions<void>) {
        const factory = _(data).isFunction() ? data : () => data;
        const disposer = withEffect(factory, deps as any, <any>asyncOrExtenders);

        if (isDisposed())
            disposer();
        else
            disposers.push(disposer);

        return data;
    }

    function scope<T extends Array<any>>(factory: Action<[...UnwrappedTuple<T>, EffectsContainer]>, deps: [...T]): void {
        register((...args: UnwrappedTuple<T>) => {
            const scopeEffects = withEffects();
            factory(...args, scopeEffects);

            return scopeEffects;
        }, deps);
    }

    function factory<T extends Array<any>, R>(factory: Func<R | undefined, [...UnwrappedTuple<T>]>, deps: [...T]): Subscribable<R | undefined> {
        const result = ko.observable<R>();

        register((...args: [...UnwrappedTuple<T>]) => {
            const effects = withEffects();

            result(effects.register(factory(...args)));
            effects.register({ dispose: () => result(undefined) });

            return () => effects.dispose();
        }, deps);

        return result;
    }

    function saveState<T extends Array<any>>(...states: [...T]): void {
        if (!isDisposed()) {
            states.forEach(state => {
                //we work only with writable subscribables
                const isCorrect = ko.isWritableObservable(state);

                if (!isCorrect)
                    throw Error('all subscribables should be writable');

                let initialState = state();

                //clone array so nobody can mess it up
                if (_.isArray(initialState))
                    initialState = [...initialState];

                if (!savedStates.has(state))
                    savedStates.set(state, initialState);
            });
        }
    }

    async function dispose() {
        if (!isDisposed()) {
            isDisposed(true);

            await Promise.all(disposers.map(dispose => Promise.resolve(dispose())));

            savedStates.forEach((initialState, state) => (<Observable<any>>state)(initialState));
            savedStates.clear();
        }
    }

    return { register, scope, factory, saveState, dispose, isDisposed: isDisposed.readOnly() }
}
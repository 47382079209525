import endpoint from 'endpoints/api';
import { JSONUnsplashOptions } from 'models/unsplash/options';
import { JSONUnsplashResult } from 'models/unsplash/results';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class UnsplashService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        photos: () => this.repository.query<JSONUnsplashOptions, JSONUnsplashResult, never, never, never, never, never>('unsplash/photos'),
        photoDownload: (id: string) => this.repository.query(`unsplash/photos/${id}/download`),
        search: () => this.repository.query<JSONUnsplashOptions, JSONUnsplashResult, never, never, never, never, never>('unsplash/search')
    }
}
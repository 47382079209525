import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import attachmentsFactory from 'factories/attachments';
import { inject, injectable } from 'inversify';
import { ObservableArray, Subscribable } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CollectionDataSource } from 'managers/datasources/collection';
import events from 'managers/events';
import { EffectsContainer } from 'mixins/withEffects';
import WCCAttachment, { JSONWCCAttachment } from 'models/attachments/attachment';
import WCCFile from 'models/attachments/file';
import WCCImage from 'models/attachments/image';
import WCCLink from 'models/attachments/link';
import WCCVideo from 'models/attachments/video';
import { ServicesContext } from 'services/context';

export enum AttachmentsView {
    all = 0,
    surveyImages = 1,
    pinboardImages = 2
}

interface TopicAttachmentsManagerConfig {
    topicId: string,
    view: AttachmentsView
}

@injectable()
@storageDefaults(<Partial<TopicAttachmentsManagerConfig>>{ view: AttachmentsView.all })
export default class TopicAttachmentsManager extends CollectionDataSourceOwner<WCCAttachment> {
    private topicId: string
    private view: AttachmentsView

    protected source: CollectionDataSource<WCCAttachment>

    attachments: ObservableArray<WCCAttachment>

    images: Subscribable<Array<WCCImage>>
    videos: Subscribable<Array<WCCVideo>>
    files: Subscribable<Array<WCCFile>>
    links: Subscribable<Array<WCCLink>>

    constructor(
        @inject(wccModules.managerConfig) { topicId, view }: TopicAttachmentsManagerConfig,
        @inject(wccModules.servicesContext) private ctx: ServicesContext,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.topicId = topicId;
        this.view = view;

        this.source = effects.register(new CollectionDataSource({
            load: () => this.load(),
            update: attachment => this.updateAttachment(attachment),
            mapper: jsonAttachment => attachmentsFactory.createAttachment(jsonAttachment)
        }));

        this.attachments = this.source.list;

        ctx.topicsService.subscriptions.discussionTopicNotifications.subscribe(this.topicId);
        ctx.topicsService.events.topicChanged.on(this.onTopicChanged.bind(this));

        ctx.attachmentsService.subscriptions.videoUpdates.subscribe(this.topicId);
        ctx.attachmentsService.subscriptions.discussionTopicAttachments.subscribe(this.topicId);

        ctx.attachmentsService.events.videoUpdated.on(this.onVideoUpdated.bind(this));
        ctx.attachmentsService.events.topicAttachmentAdded.on(this.onAttachmentAdded.bind(this));
        ctx.attachmentsService.events.topicAttachmentRemoved.on(this.onAttachmentRemoved.bind(this));

        effects.register([
            events.topicAttachmentAdded.on(this.onLocalAttachmentAdded.bind(this)),
            events.topicAttachmentRemoved.on(this.onLocalAttachmentRemoved.bind(this))
        ]);

        this.images = this.attachments.filter<WCCImage>(a => a.fileType() === enums.AttachmentTypes.Image.value);
        this.videos = this.attachments.filter<WCCVideo>(a => a.fileType() === enums.AttachmentTypes.Video.value);
        this.files = this.attachments.filter<WCCFile>(a => a.fileType() == enums.AttachmentTypes.File.value);
        this.links = this.attachments.filter<WCCLink>(a => a.fileType() === enums.AttachmentTypes.Link.value);
    }

    update() {
        this.source.load();
    }

    private load() {
        const query = (() => {
            switch (this.view) {
                case AttachmentsView.surveyImages: return this.ctx.attachmentsService.queries.surveyImages(this.topicId);
                case AttachmentsView.pinboardImages: return this.ctx.attachmentsService.queries.pinboardImages(this.topicId);
                default: return this.ctx.attachmentsService.queries.topicAttachments(this.topicId);
            }
        })();        

        return query.background().toArray();
    }

    private async updateAttachment(attachment: WCCAttachment) {
        const attachmentId = attachment.attachmentId();

        if (attachmentId == undefined)
            throw new Error(messages.InvalidRequest);

        const jsonAttachment = await this.ctx.attachmentsService.queries.topicAttachment(this.topicId, attachmentId).background().firstOrDefault();

        if (jsonAttachment != undefined)
            attachment.update(jsonAttachment);
    }

    private onVideoUpdated(entityId: string, videoId: string) {
        if (this.topicId === entityId)
            this.source.update(attachment => attachment.attachmentId() === videoId);
    }

    private onAttachmentAdded(topicId: string, attachmentId: string) {
        if (this.topicId === topicId)
            this.update();
    }

    private onAttachmentRemoved(topicId: string, attachmentId: string) {
        if (this.topicId === topicId)
            this.source.remove(attachment => attachment.attachmentId() === attachmentId);
    }

    private onTopicChanged(topicId: string) {
        if (this.topicId === topicId)
            this.update();
    }

    private onLocalAttachmentAdded(topicId: string, jsonAttachment: JSONWCCAttachment) {
        if (this.topicId === topicId)
            this.source.add(jsonAttachment);
    }

    private onLocalAttachmentRemoved(topicId: string, attachmentId: string) {
        if (this.topicId === topicId)
            this.source.remove(attachment => attachment.attachmentId() == attachmentId);
    }
}
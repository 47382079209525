import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class RecruitmentService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            adAccounts: () => this._repository.query('facebook/adaccounts'),
            adImage: (adAccountId, imageHash) => this._repository.query(`facebook/adaccounts/${adAccountId}/adImages/${imageHash}`),
            adImages: (adAccountId) => this._repository.query(`facebook/adaccounts/${adAccountId}/adImages`),
            adLeads: (adId) => this._repository.query(`facebook/ads/${adId}/leads`),
            adSetAds: (adSetId) => this._repository.query(`facebook/adsets/${adSetId}/ads`),
            ad: (adId) => this._repository.query(`facebook/ads/${adId}`),
            batch: () => this._repository.query('facebook/batch'),
            campaignAdSets: (campaignId) => this._repository.query(`facebook/recruitmentcampaigns/${campaignId}/adsets`),
            deliveryestimate: (adAccountId) => this._repository.query(`facebook/adaccounts/${adAccountId}/deliveryestimate`),
            detachRecruitmentCampaign: (campaignId) => this._repository.query(`facebook/recruitmentcampaigns/${campaignId}/detach`),
            pages: () => this._repository.query(`facebook/pages`),
            recruitmentAdSet: (adsetId) => this._repository.query(`facebook/recruitmentadsets/${adsetId}`),
            recruitmentCampaign: (campaignId) => this._repository.query(`facebook/recruitmentcampaigns/${campaignId}`),
            recruitmentCampaigns: (adAccountId) => this._repository.query(`facebook/adaccounts/${adAccountId}/recruitmentcampaigns`),
            recruitmentCampaignFBIds: (adAccountId) => this._repository.query(`facebook/adaccounts/${adAccountId}/recruitmentcampaigns/fbids`),
            recruitmentCampaignImport: (adAccountId, fbCampaignId) => this._repository.query(`facebook/adaccounts/${adAccountId}/recruitmentcampaigns/import/${fbCampaignId}`),
            fbCampaigns: (adAccountId) => this._repository.query(`facebook/adaccounts/${adAccountId}/fbcampaigns`),
            fbCampaignAdSets: (campaignId) => this._repository.query(`facebook/fbcampaigns/${campaignId}/adsets`),
            fbCampaignAdSetsNoAds: (campaignId) => this._repository.query(`facebook/fbcampaigns/${campaignId}/adsets/noads`),
            fbObjectAds: (objectId) => this._repository.query(`facebook/fbobjects/${objectId}/ads`),
            insights: () => this._repository.query(`facebook/insights`),
        };

        this.subscriptions = {
        };

        this.events = {
        };
    }

    /**
     * Returns all facebook ad accounts
     */
    getAdAccounts() {
        return this.queries.adAccounts().toArray();
    }

    /**
     * Runs batch request
     */
    runBatch(batchParams) {
        return this.queries.batch().add(batchParams);
    }



    /**
     * Returns all facebook campaigns
     */
    getFBCampaigns(adAccountId) {
        return this.queries.fbCampaigns(adAccountId).toArray();
    }

    /**
     * Returns all recruitment campaigns
     */
    getRecruitmentCampaigns(adAccountId) {
        return this.queries.recruitmentCampaigns(adAccountId).toArray();
    }

    /**
     * Returns recruitment campaign with given Id
     */
    getRecruitmentCampaign(campaignId) {
        return this.queries.recruitmentCampaign(campaignId).firstOrDefault();
    }

    /**
     * Returns all recruitment campaign FB identities (string keys)
     */
    getImportedCampaignFBIds(adAccountId) {
        return this.queries.recruitmentCampaignFBIds(adAccountId).toArray();
    }

    /**
     * Saves recruitment campaign
     */
    saveRecruitmentCampaign(adAccountId, campaignData) {
        return this.queries.recruitmentCampaigns(adAccountId).add(campaignData);
    }
    /**
     * Updates recruitment campaign
     */
    updateRecruitmentCampaign(adAccountId, campaignData) {
        return this.queries.recruitmentCampaigns(adAccountId).update(campaignData);
    }

    /**
     * Detaches recruitment campaign
     */
    detachRecruitmentCampaign(campaignId) {
        return this.queries.detachRecruitmentCampaign(campaignId).add();
    }

    /**
     * Deletes recruitment campaign
     */
    deleteRecruitmentCampaign(campaignId) {
        return this.queries.recruitmentCampaign(campaignId).remove();
    }

    /**
     * Imports recruitment campaign
     */
    importFBCampaign(adAccountId, fbCampaignId) {
        return this.queries.recruitmentCampaignImport(adAccountId, fbCampaignId).add();
    }



    /**
     * Returns facebook campaign ad sets
     */
    getFBCampaignAdSets(campaignId) {
        return this.queries.fbCampaignAdSets(campaignId).toArray();
    }

    /**
     * Returns facebook campaign ad sets, but do not load their ads
     */
    getFBCampaignAdSetsNoAds(campaignId) {
        return this.queries.fbCampaignAdSetsNoAds(campaignId).toArray();
    }

    /**
     * Returns campaign ad sets
     */
    getCampaignAdSets(campaignId) {
        return this.queries.campaignAdSets(campaignId).toArray();
    }

    /**
     * Saves campaign ad set
     */
    saveCampaignAdSet(campaignId, adSetData) {
        return this.queries.campaignAdSets(campaignId).add(adSetData);
    }

    /**
     * Saves campaign ad set
     */
    updateCampaignAdSet(adSetId, adSetData) {
        return this.queries.recruitmentAdSet(adSetId).update(adSetData);
    }

    /**
     * Deletes ad set
     */
    deleteAdSet(adSetId) {
        return this.queries.recruitmentAdSet(adSetId).remove();
    }



    /**
     * Returns facebook ads for given FB object
     */
    getFBObjectAds(objectId) {
        return this.queries.fbObjectAds(objectId).toArray();
    }

    /**
     * Returns ads for given ad set
     */
    getAdSetAds(adSetId) {
        return this.queries.adSetAds(adSetId).toArray();
    }

    /**
     * Saves ad
     */
    saveAd(adSetId, adData) {
        return this.queries.adSetAds(adSetId).add(adData);
    }

    /**
     * Deletes ad
     */
    deleteAd(adId) {
        return this.queries.ad(adId).remove();
    }



    /**
     * Returns leads for given ad 
     */
    getAdLeads(adId) {
        return this.queries.adLeads(adId).toArray();
    }



    /**
     * Calculate delivery estimate
     */
    getDeliveryEstimate(adAccountId, targeting) {
        return this.queries.deliveryestimate(adAccountId).addArgs({ 'targeting': targeting }).firstOrDefault();
    }

    /**
     * Returns all available FB pages
     */
    getPages() {
        return this.queries.pages().toArray();
    }


    /**
     * Uploads new ad image to FB
     */
    uploadAdImage(adAccountId, imageData) {
        return this.queries.adImages(adAccountId).add(imageData);
    }

    /**
      * Deletes ad image
      */
    deleteAdImage(adAccountId, imageHash) {
        return this.queries.adImage(adAccountId, imageHash).remove();
    }


    /**
     * Returns insights for given object ids
     */
    getFBObjectInsights(requestData) {
        return this.queries.insights().add(requestData);
    }
}
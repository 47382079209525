import { HubConnection } from "@microsoft/signalr";
import { wccModules } from "enums/wccModules";
import { Disposable } from "interfaces/disposable";
import { inject, injectable } from "inversify";
import { EffectsContainer } from "mixins/withEffects";
import { SignalRHelpers } from "./helpers";
import { ISignalRCoreConnectionManager } from "./interfaces/connection";
import { ISignalRCoreEventsManager } from "./interfaces/events";
import SignalREventCompositeHandler from "./models/eventCompositeHandler";
import { SignalREventHandler } from "./models/eventHandler";

@injectable()
export default class SignalRCoreEventsManager implements ISignalRCoreEventsManager {
    private handlers = ko.observableArray<SignalREventHandler>();
    private handlersMap = new Map<string, SignalREventCompositeHandler>();

    constructor(
        @inject(wccModules.signalRConnectionManager) connectionManager: ISignalRCoreConnectionManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        effects.register((connection, isConnected) => {
            if (connection != undefined && isConnected) {
                const add = (handler: SignalREventHandler) => this.addHandler(handler, connection);
                const remove = (handler: SignalREventHandler) => this.removeHandler(handler, connection);

                _(this.handlers()).each(remove);
                _(this.handlers()).each(add);

                return this.handlers.onArrayChange({ add, remove });
            }
        }, [connectionManager.connection, connectionManager.connected]);
    }

    trigger(eventName: string, ...args: Array<any>) {
        this.handlers().forEach(handler => {
            if (handler.name === eventName)
                handler.action(...args);
        });
    }

    on(name: string, action: Action<Array<any>>) {
        //const _action = action;

        //action = (...args: any[]) => {
        //    console.log('signalr event', name, args);
        //    _action(...args);
        //}

        const handler = <SignalREventHandler>{ name, action };

        this.handlers.push(handler);

        return <Disposable>{
            dispose: _.once(() => {
                this.handlers.remove(handler);
            })
        }
    }

    private addHandler({ name, action }: SignalREventHandler, connection: HubConnection) {
        let item = this.handlersMap.get(name);

        if (item == undefined) {
            item = new SignalREventCompositeHandler(name);

            connection.on(name, item.handler);
            this.handlersMap.set(name, item);

            SignalRHelpers.log('listening for', name, item);
        }

        item.actions.push(action);

        SignalRHelpers.logInfo('handler added for', { name, action });
    }

    private removeHandler({ name, action }: SignalREventHandler, connection: HubConnection) {
        let item = this.handlersMap.get(name);

        if (item != undefined) {
            item.actions.remove(item => item === action);

            if (item.actions().length == 0) {
                connection.off(name, item.handler);
                this.handlersMap.delete(name);

                SignalRHelpers.log('stopped listening for', name);
            }
        }

        SignalRHelpers.logInfo('handler removed from', { name, action });
    }
}
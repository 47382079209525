import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyAdvancedContinueOptions {
    TimerType: number
    Duration?: number
}

export default class SurveyAdvancedContinueOptions extends WCCEntity<JSONSurveyAdvancedContinueOptions> {
    timerType: Observable<number>
    duration: Observable<number | undefined>

    constructor(data: JSONSurveyAdvancedContinueOptions) {
        super();

        this.timerType = this.createField(data, 'TimerType');
        this.duration = this.createField(data, 'Duration');
    }
}
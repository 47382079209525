export const settings: { [key: string]: any } = {}

export function getSetting<T = any>(settingName: string, defaultValue?: any): T {
    if (typeof settings[settingName] != "undefined") {
        return settings[settingName];
    } else {
        return defaultValue;
    }
}

export function setSetting<T>(settingName: string, value: T) {
    settings[settingName] = value;
}

const config = (window as any).__wccconfig ?? {};

_(config.settings).each((value, key: string) => setSetting(key, value));
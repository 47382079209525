define('services/knockout', ['knockout'], ko => {
    class Service {
        constructor() { }

        getTuple(...deps) {
            return ko.pureComputed(() => _(deps).map(d => ko.unwrap(d)));
        }

        getTupleNotNull(...deps) {
            return this.getTuple(...deps).mapSingle(values => _(values).all(value => value != null) ? values : null);
        }

        onArrayChanged(opts) {
            let { add: addFunc = _.noop, remove: removeFunc = _.noop, move: moveFunc = _.noop } = opts || {};

            return changes => {
                let toAdd = _(changes).filter(change => change.status === 'added' && change.moved === undefined),
                    toMove = _(changes).filter(change => change.status === 'added' && change.moved !== undefined),
                    toRemove = _(changes).filter(change => change.status === 'deleted' && change.moved === undefined);

                _(toAdd).each(change => addFunc(change.value, change.index));
                _(toRemove).each(change => removeFunc(change.value, change.index));
                _(toMove).each(change => moveFunc(change.value, change.index));
            };
        }

        fromEvent(func) {
            let value = ko.observable(null);

            func(value);

            return value;
        }
    }

    return new Service();
});
import { StringMap } from 'interfaces/stringMap';

const schemePattern = /([\w\d.+-]+):/;
const pathPattern = /(?:\w*:)?(?:\/\/)?((?:[\w\d.+-=]+\/?)+)/;
const queryPattern = /\?((?:[\w\d$.+-]+=[\w\d():%,.+ -]+[;&]?)+)/;
const queryOnlyPattern = /((?:[\w\d$.+-]+=[\w\d():,.%+\ \-\']+[;&]?)+)/;
const fragmentPattern = /#(\S+)/;

export type URLQueryData = string | URLQueryDataMap;
export type URLQueryDataMap = StringMap<string | number | boolean | Array<string | number | boolean> | undefined>

export default class URLBuilder {
    private scheme: string
    private path: Array<string>
    private query: Map<string, Array<string>>
    private fragment: string
    private isCaseInsensitive: boolean
    
    private isRelative: boolean

    constructor(url: string, isCaseInsensitive: boolean = false) {
        let [, scheme = ''] = schemePattern.exec(url) ?? [],
            [fullPath = '', path = ''] = pathPattern.exec(url) ?? [],
            [, query = ''] = queryPattern.exec(url) ?? [],
            [, fragment = ''] = fragmentPattern.exec(url) ?? [];

        this.isCaseInsensitive = isCaseInsensitive;
        this.scheme = scheme;
        this.path = path.split('/');
        this.query = this.parseQueryString(query);
        this.fragment = fragment;

        this.isRelative = fullPath.indexOf('//') === -1;
    }

    static from(url: string, isCaseInsensitive: boolean = false) {
        return new URLBuilder(url, isCaseInsensitive);
    }

    getQuery(key: string) {
        return this.query.get(this.isCaseInsensitive ? key.toLowerCase() : key)?.[0];
    }

    addToPath(value = '') {
        const parts = value.split('/');

        if (parts.some(part => part.trim().length === 0))
            throw new Error('invalid URL path');

        parts.forEach(part => this.path.push(part));

        return this;
    }

    addToQuery(strOrObject: URLQueryData) {
        if (_.isString(strOrObject)) {
            let [, query = ''] = queryOnlyPattern.exec(strOrObject) ?? [];

            if (query.length > 0)
                this.parseQueryString(query).forEach((value, key) => this.query.set(this.isCaseInsensitive ? key.toLowerCase() : key, value));
        } else {
            _.chain(strOrObject)
                .pick(value => value != undefined)
                .forEach((value, key) => {
                    if (value != undefined)
                        this.query.set(this.isCaseInsensitive ? key.toLowerCase() : key, _.isArray(value) ? value.map(v => v.toString()) : [value.toString()])
                });
        }

        return this;
    }

    removeQuery() {
        this.query.clear();

        return this;
    }

    removeScheme() {
        this.scheme = '';
        this.isRelative = true;
    }

    toString() {
        let scheme = this.scheme;
        let path = this.path.join('/');
        let query = Array.from(this.query.entries()).flatMap(([key, values]) => values.map(value => `${key}=${value}`)).join('&');
        let fragment = this.fragment;

        if (scheme.length > 0)
            scheme += ':';

        if (query.length > 0)
            query = `?${query}`;

        if (fragment.length > 0)
            fragment = `#${fragment}`;

        return this.isRelative ?
            `${path}${query}${fragment}` :
            `${scheme}//${path}${query}${fragment}`;
    }

    private parseQueryString(query: string) {
        const result = new Map<string, Array<string>>();

        query.split(/&|;/)
            .filter(str => str.length > 0)
            .map(str => str.split('='))
            .forEach(([key, value]) => result.set(this.isCaseInsensitive ? key.toLowerCase() : key, [...result.get(this.isCaseInsensitive ? key.toLowerCase() : key) ?? [], value]));

        return result;
    }
}
import {BasePassiveQuery, PassivePostQuery, PassiveUpdateQuery} from "../models/queries/passive";
import {injectable} from "inversify";

/**
 * Every type of captcha needs to implement this
 * Currently we support Google RcCaptcha V3
 */
export interface ICaptchaManager {
    /**
     * Registers the captcha on the page
     */
    register() : void;

    /**
     * It wraps any POST query and adds captcha specific details to the query request
     * @param qry
     */
    wrapPostQuery<PI, PO>(qry: PassivePostQuery<PI, PO>): Promise<PassivePostQuery<PI, PO>>;
    
    /**
     * It wraps any PUT query and adds captcha specific details to the query request
     * @param qry
     */
    wrapUpdateQuery<UI, UO>(qry: PassiveUpdateQuery<UI, UO>): Promise<PassiveUpdateQuery<UI, UO>>;
}

/**
 * Google ReCaptcha V3
 */
@injectable()
export class GoogleRecaptchaV3Manager implements ICaptchaManager {

    /**
     * It register the script tag for the recaptcha
     * MAKE SURE YOU CALL IT ONLY ONCE PER PAGE
     */
    register() {
        if (!settings.isCaptchaEnabled) return;
        
        $(() => {
            const $body = $("body");
            $body.append(`<script src="https://www.google.com/recaptcha/api.js?render=${WCC.Settings.reCAPTCHA_site_key}"></script>`)
        });
    }

    /**
     * It wraps any query (PassiveQuery, PassiveEntityQuery etc. which must inherit from BasePassiveQuery) by doing two things:
     * 1. Issues a request to Google and generates a token
     * 2. Adds this token as a request header of the query so that it can be validated on the server
     * @param qry
     */
    private static async wrapAnyQuery(qry: BasePassiveQuery) {
        const captchaCode = settings.captchaCode;
        if (captchaCode != null && captchaCode.trim() != "") {
            qry.addHeaders({'captcha-code': captchaCode});
        }
        
        if (!settings.isCaptchaEnabled) return;
        
        const token = await grecaptcha.execute(WCC.Settings.reCAPTCHA_site_key, {action: 'submit'});
        qry.addHeaders({ 'google-recaptcha-v3-token': token });
    }

    async wrapPostQuery<PI, PO>(qry: PassivePostQuery<PI, PO>): Promise<PassivePostQuery<PI, PO>> {
        await GoogleRecaptchaV3Manager.wrapAnyQuery(qry);
        return qry;
    }
    
    async wrapUpdateQuery<UI, UO>(qry: PassiveUpdateQuery<UI, UO>): Promise<PassiveUpdateQuery<UI, UO>> {
        await GoogleRecaptchaV3Manager.wrapAnyQuery(qry);
        return qry;
    }
}
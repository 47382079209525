import { inject, injectable } from "inversify";
import { wccModules } from "enums/wccModules";
import { withEffect } from "mixins/withEffect";
import { EffectsContainer } from "mixins/withEffects";
import { SignalRHelpers } from "./helpers";
import { ISignalRCoreConnectionManager } from "./interfaces/connection";
import { ISignalRCoreMessagesManager } from "./interfaces/messages";
import SignalRMessage, { SignalRMessageConfig } from "./models/message";

@injectable()
export default class SignalRCoreMessagesManager implements ISignalRCoreMessagesManager {
    private messageToSend = ko.observable<SignalRMessage>();
    private messages = ko.observableArray<SignalRMessage>().extend({ deferred: true });

    constructor(
        @inject(wccModules.signalRConnectionManager) connectionManager: ISignalRCoreConnectionManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const connection = connectionManager.connection;
        const isConnected = connectionManager.connected;        

        effects.register((connection, isConnected) => {
            if (connection != undefined && isConnected) {
                const removedMessages = this.messages.remove(message => message.removeOnReconnect);

                if (removedMessages.length > 0)
                    SignalRHelpers.logInfo('removed messages', removedMessages);

                return [
                    withEffect((messageToSend, messages) => {
                        if (messageToSend == undefined && messages.length > 0)
                            this.messageToSend(this.messages.shift());
                    }, [this.messageToSend, this.messages]),

                    withEffect(async (connection, message) => {
                        if (connection != undefined && message != undefined) {
                            try {
                                SignalRHelpers.logInfo('sending message', message);
                                await connection.invoke(message.name, ...message.args);
                                SignalRHelpers.log('message sent', message);

                                message.onSent();
                            } catch (ex) {
                                SignalRHelpers.logError('message send error', message, ex);

                                message.ttl--;

                                if (message.ttl > 0)
                                    this.messages.unshift(message);
                                else
                                    message.onFail(ex);
                            } finally {
                                this.messageToSend(undefined);
                            }
                        }
                    }, [connection, this.messageToSend])
                ];
            }
        }, [connection, isConnected]);

        effects.register([
            ko.computed(() => SignalRHelpers.logInfo('messages', [...this.messages()]))
        ]);
    }

    async add(config: SignalRMessageConfig) {
        const newMessage = new SignalRMessage(config);

        let existingMessage = this.messages().find(message => message.equals(newMessage));

        if (existingMessage !== undefined)
            existingMessage.promise.then(() => newMessage.onSent(), ex => newMessage.onFail(ex));
        else
            this.messages.push(newMessage);

        try {
            return await newMessage.promise;
        } catch (ex) {
            SignalRHelpers.logError('failed to send', newMessage, ex);
            throw ex;
        }
    }

    async addImportant(config: SignalRMessageConfig) {
        return await this.add({ ...config, ttl: SignalRHelpers.maxRetryCount });
    }
}
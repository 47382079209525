import { Func } from 'interfaces/func';
import { JSONSimpleTopic, SimpleTopic } from 'models/simpleTopic';
import { JSONSimpleTopicsGroup, SimpleTopicsGroup } from 'models/simpleTopicsGroup';

const defaultGroupFactory = (jsonGroup: JSONSimpleTopicsGroup) => new SimpleTopicsGroup(jsonGroup);
const defaultTopicFactory = (jsonTopic: JSONSimpleTopic) => new SimpleTopic(jsonTopic);

export interface TopicGroupsFactoryConfig<T extends SimpleTopic = SimpleTopic, G extends SimpleTopicsGroup = SimpleTopicsGroup> {
    groupConstructor?: Func<G, [JSONSimpleTopicsGroup]>
    topicConstructor?: Func<T, [JSONSimpleTopic]>
}

export default class TopicGroupsFactory<T extends SimpleTopic = SimpleTopic, G extends SimpleTopicsGroup = SimpleTopicsGroup> {
    static instance = new TopicGroupsFactory();

    private buildGroupFunc: Func<G, [JSONSimpleTopicsGroup]>
    private buildTopicFunc: Func<T, [JSONSimpleTopic]>    

    constructor(config?: TopicGroupsFactoryConfig<T, G>) {
        this.buildGroupFunc = config?.groupConstructor ?? <any>defaultGroupFactory;
        this.buildTopicFunc = config?.topicConstructor ?? <any>defaultTopicFactory;
    }

    buildTopics(jsonTopics: Array<JSONSimpleTopic>) {
        return jsonTopics.map(jsonTopic => this.buildTopicFunc(jsonTopic));
    }

    buildGroups(jsonTopics: Array<JSONSimpleTopic>) {
        return jsonTopics.reduce((groups, jsonTopic) => {
            if (jsonTopic.IsGroup) {
                groups.push(this.buildGroupFunc(<JSONSimpleTopicsGroup>jsonTopic));
            } else {
                let group = _.last(groups);

                if (group == undefined) {
                    group = this.buildGroupFunc({ Title: 'dummy' });
                    groups.push(group);
                }

                group.addTopic(this.buildTopicFunc(jsonTopic));
            }

            return groups;
        }, new Array<G>());
    }
}

export const topicGroupsFactory = new TopicGroupsFactory();
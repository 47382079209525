define('managers/statistics/person',
    [
        'managers/base_es5',
        'models/participantStat'        
    ],

    function (Base, Statistics) {
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');

        function Manager(discussionId, personId, status) {
            Base.call(this);

            this.discussionId = ko.pureComputed(function () { return discussionId });
            this.personId = ko.pureComputed(function () { return personId });
            this.status = ko.pureComputed(function () { return status });

            this._datasource = this._register(new DataSource({ update: this._load.bind(this) }));

            this.statistics = this._datasource.data.mapNotNull(function (jsonStatistics) { return new Statistics(jsonStatistics) });
            this.loading = this._datasource.updating;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.update = function () {
            this._datasource.update();
        };

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.commentsService.subscriptions.discussionThreads.subscribe(this.discussionId());

            this._ctx.commentsService.events.newComment.on(this._onNewComment.bind(this));
            this._ctx.commentsService.events.newReply.on(this._onNewReply.bind(this));
            this._ctx.commentsService.events.threadDeleted.on(this._onThreadDeleted.bind(this));
        };

        Manager.prototype._load = function () {
            var queries = this._ctx.statisticsService.queries;

            switch (this.status()) {
                case enums.MembershipStatusTypes.Member.value:
                case enums.MembershipStatusTypes.Completed.value:
                case enums.MembershipStatusTypes.Observer.value:
                    return queries.participantStat(this.discussionId(), this.personId()).firstOrDefault();

                case enums.MembershipStatusTypes.Moderator.value:
                    return queries.moderatorStat(this.discussionId(), this.personId()).firstOrDefault();

                default:
                    return $.Deferred(function (dfd) { dfd.resolve(null) });
            }
        };

        Manager.prototype._onNewComment = function (threadId, topicId, personId) {
            if (personId === this.personId())
                this.update();
        };

        Manager.prototype._onNewReply = function (threadId, parentThreadId, topicId, personId, parentThreadOwnerId) {
            if (personId === this.personId() || parentThreadOwnerId === this.personId())
                this.update();
        };

        Manager.prototype._onThreadDeleted = function (threadId, parentThreadId, personId, topicId) {
            if (personId === this.personId())
                this.update();
        };

        return Manager;
    });
define('models/activityStat', [
], function () {
    function Model(jsonStat) {
        var self = this;

        self.recentPosts = ko.observable(jsonStat.RecentPostsCount || 0);
        self.totalPosts = ko.observable(jsonStat.TotalPostsCount || 0);
        self.participantsCount = ko.observable(jsonStat.ParticipantsCount || 0);
    }

    return Model;
});
export default class Queue<T> {
    private items = new Array<T>();

    constructor(private size: number) { }

    add(item: T) {
        if (this.items.length >= this.size)
            this.items.shift();

        this.items.push(item);
    }

    contains(item: T) {
        return this.items.includes(item);
    }
}
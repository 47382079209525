import { Observable, ObservableArray, Subscribable } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyChoice, { JSONSurveyChoice } from 'models/survey/choice';
import SurveyChoiceOptions, { JSONSurveyChoiceOptions } from 'models/survey/choiceOptions';
import { JSONTag, Tag } from 'models/tag';
import UserFile, { JSONUserFile } from 'models/userFile';
import SurveyFilesOptions, { JSONSurveyFilesOptions } from './filesOptions';
import SurveyAdvancedContinueOptions, { JSONSurveyAdvancedContinueOptions } from './advancedContinueOptions';
import SurveyStaticImagesOptions, { JSONSurveyStaticImagesOptions } from './staticImagesOptions';
import SurveyNumericOptions, { JSONSurveyNumericOptions } from './numericOptions';
import SurveyTextOptions, { JSONSurveyTextOptions } from './textOptions';
import SurveyTimeSelectionOptions, { JSONSurveyTimeSelectionOptions } from './timeSelectionOptions';
import SurveyStaticVideoOptions, { JSONSurveyStaticVideoOptions } from './staticVideoOptions';
import SurveyWebsitesOptions, { JSONSurveyWebsitesOptions } from './websitesOptions';
import SurveyBlanksOptions, { JSONSurveyBlanksOptions } from './blanksOptions';
import SurveyNameOptions, { JSONSurveyNameOptions } from './nameOptions';
import SurveyEmailOptions, { JSONSurveyEmailOptions } from './emailOptions';
import SurveyPhoneOptions, { JSONSurveyPhoneOptions } from './phoneOptions';
import SurveyTimeZoneOptions, { JSONSurveyTimeZoneOptions } from './timeZoneOptions';
import SurveyAddressOptions, { JSONSurveyAddressOptions } from './addressOptions';
import SurveyDateOptions, { JSONSurveyDateOptions } from './dateOptions';
import SurveyNicknameOptions, { JSONSurveyNicknameOptions } from './nicknameOptions';
import SurveyVideoOptions, {JSONSurveyVideoOptions} from "./videoOptions";
import SurveyBlankOption from "./blankOption";

export interface JSONSurveyQuestion {
    ProfileQuestionId: string
    QuestionTitle: string
    QuestionTitleOverride: string
    QuestionHeading: string
    QuestionHeadingOverride: string
    QuestionOrder: number
    QuestionType: number
    QuestionVisibilityType: number
    ProfileQuestionContinueOptionType: number
    QuestionSettings: string
    TitlePosition: number
    UrlParamAnswerType: number
    UniqueCode: string
    HelpText: string
    HelpTextOverride: string
    ImageName: string
    CDNImageName: string
    ContinueButtonText?: string
    Average: number
    MaxHeight?: number

    IsHidden: boolean
    IsMandatory: boolean
    IsReadOnly: boolean
    IsRandomized: boolean

    InternalQuestionId?: string;

    ProfileQuestionChoicesOptions?: JSONSurveyChoiceOptions
    ProfileQuestionImagesOptions?: JSONSurveyStaticImagesOptions
    ProfileQuestionVideoOptions?: JSONSurveyVideoOptions
    ProfileQuestionFilesOptions?: JSONSurveyFilesOptions
    ProfileQuestionTextOptions?: JSONSurveyTextOptions
    ProfileQuestionAdvancedContinueOptions?: JSONSurveyAdvancedContinueOptions
    ProfileQuestionNumericOptions?: JSONSurveyNumericOptions
    ProfileQuestionTimeSelectionOptions?: JSONSurveyTimeSelectionOptions
    ProfileQuestionStaticVideoOptions?: JSONSurveyStaticVideoOptions
    ProfileQuestionWebsitesOptions?: JSONSurveyWebsitesOptions
    ProfileQuestionBlanksOptions?: JSONSurveyBlanksOptions
    ProfileQuestionNameOptions?: JSONSurveyNameOptions
    ProfileQuestionEmailOptions?: JSONSurveyEmailOptions
    ProfileQuestionPhoneOptions?: JSONSurveyPhoneOptions
    ProfileQuestionTimeZoneOptions?: JSONSurveyTimeZoneOptions
    ProfileQuestionAddressOptions?: JSONSurveyAddressOptions
    ProfileQuestionDateOptions?: JSONSurveyDateOptions
    ProfileQuestionNicknameOptions?: JSONSurveyNicknameOptions

    Choices?: Array<JSONSurveyChoice>
    QuestionVisibilityTags?: Array<JSONTag>
    StaticImages?: Array<JSONUserFile>
}

export default class SurveyQuestion extends WCCEntity<JSONSurveyQuestion> {
    questionId: Observable<string | undefined>
    title: Observable<string | undefined>
    heading: Observable<string | undefined>
    order: Observable<number | undefined>
    type: Observable<number | undefined>
    visibilityType: Observable<number | undefined>
    continueType: Observable<number>
    settings: Observable<string | undefined>
    titlePosition: Observable<number | undefined>
    urlParamAnswerType: Observable<number | undefined>
    uniqueCode: Observable<string | undefined>
    helpText: Observable<string | undefined>
    imageName: Observable<string | undefined>
    cdnImageName: Observable<string | undefined>
    continueButtonText: Observable<string>
    averageScore: Observable<number | undefined>
    maxHeight: Observable<number>

    isHidden: Observable<boolean>
    isMandatory: Observable<boolean>
    isReadOnly: Observable<boolean>
    isRandomized: Observable<boolean>

    choicesOptions: Observable<SurveyChoiceOptions | undefined>
    imagesOptions: Observable<SurveyStaticImagesOptions | undefined>
    videoOptions: Observable<SurveyVideoOptions | undefined>
    staticVideoOptions: Observable<SurveyStaticVideoOptions | undefined>
    filesOptions: Observable<SurveyFilesOptions | undefined>
    textOptions: Observable<SurveyTextOptions | undefined>
    advancedContinueOptions: Observable<SurveyAdvancedContinueOptions | undefined>
    numericOptions: Observable<SurveyNumericOptions | undefined>
    timeSelectionOptions: Observable<SurveyTimeSelectionOptions | undefined>
    websitesOptions: Observable<SurveyWebsitesOptions | undefined>
    blanksOptions: Observable<SurveyBlanksOptions | undefined>
    nameOptions: Observable<SurveyNameOptions | undefined>
    emailOptions: Observable<SurveyEmailOptions | undefined>
    phoneOptions: Observable<SurveyPhoneOptions | undefined>
    timeZoneOptions: Observable<SurveyTimeZoneOptions | undefined>
    addressOptions: Observable<SurveyAddressOptions | undefined>
    dateOptions: Observable<SurveyDateOptions | undefined>
    nicknameOptions: Observable<SurveyNicknameOptions | undefined>

    choices: ObservableArray<SurveyChoice>
    blankOptions: ObservableArray<SurveyBlankOption>
    visibilityTags: ObservableArray<Tag>
    staticImages: ObservableArray<UserFile>

    isSingleChoiceQuestion: Subscribable<boolean>
    isMultipleChoiceQuestion: Subscribable<boolean>

    usesDropdowns: Subscribable<boolean>
    usesRadioButtons: Subscribable<boolean>
    usesSlider: Subscribable<boolean>
    usesImageSelector: Subscribable<boolean>
    usesCheckboxes: Subscribable<boolean>

    constructor(data?: JSONSurveyQuestion) {
        super();

        this.questionId = this.createField(data, 'ProfileQuestionId');
        
        //Title, Heading and HelpText have overrides on the usage record i.e. we can override these on global attributes via usage records
        //Therefore, we need to check if the override for each is not there and use the global attribute version in that case, otherwise use the override
        //This isn't applicable for ad-hoc but the implementation is consistent as overrides will be NULL for questions in ad-hoc surveys 
        this.title = this.createField(data, data?.QuestionTitleOverride == null ? 'QuestionTitle' : 'QuestionTitleOverride');        
        this.heading = this.createField(data, data?.QuestionHeadingOverride == null ? 'QuestionHeading' : 'QuestionHeadingOverride');
        this.helpText = this.createField(data, data?.HelpTextOverride == null ? 'HelpText' : 'HelpTextOverride');
        
        this.order = this.createField(data, 'QuestionOrder');
        this.type = this.createField(data, 'QuestionType');
        this.visibilityType = this.createField(data, 'QuestionVisibilityType');
        this.continueType = this.createField(data, 'ProfileQuestionContinueOptionType', enums.ProfileQuestionContinueOptionTypes.NoPause.value);
        this.settings = this.createField(data, 'QuestionSettings');
        this.titlePosition = this.createField(data, 'TitlePosition');
        this.urlParamAnswerType = this.createField(data, 'UrlParamAnswerType');
        this.uniqueCode = this.createField(data, 'UniqueCode');
        this.imageName = this.createField(data, 'ImageName');
        this.cdnImageName = this.createField(data, 'CDNImageName');
        this.continueButtonText = this.createField(data, 'ContinueButtonText', labels.ClickToContinue);
        this.averageScore = this.createField(data, 'Average');
        this.maxHeight = this.createField(data, 'MaxHeight', 0);

        this.isHidden = this.createField(data, 'IsHidden', false);
        this.isMandatory = this.createField(data, 'IsMandatory', false);
        this.isReadOnly = this.createField(data, 'IsReadOnly', false);
        this.isRandomized = this.createField(data, 'IsRandomized', false);

        this.choicesOptions = this.createMappedField(data, 'ProfileQuestionChoicesOptions', jsonChoiceOptions => new SurveyChoiceOptions(jsonChoiceOptions));
        this.imagesOptions = this.createMappedField(data, 'ProfileQuestionImagesOptions', jsonImagesOptions => new SurveyStaticImagesOptions(jsonImagesOptions));
        this.staticVideoOptions = this.createMappedField(data, 'ProfileQuestionStaticVideoOptions', jsonStaticVideoOptions => new SurveyStaticVideoOptions(jsonStaticVideoOptions));
        this.videoOptions = this.createMappedField(data, 'ProfileQuestionVideoOptions', jsonVideoOptions => new SurveyVideoOptions(jsonVideoOptions));
        this.filesOptions = this.createMappedField(data, 'ProfileQuestionFilesOptions', jsonFilesOptions => new SurveyFilesOptions(jsonFilesOptions));
        this.textOptions = this.createMappedField(data, 'ProfileQuestionTextOptions', jsonTextOptions => new SurveyTextOptions(jsonTextOptions));
        this.advancedContinueOptions = this.createMappedField(data, 'ProfileQuestionAdvancedContinueOptions', jsonAdvancedContinueOptions => new SurveyAdvancedContinueOptions(jsonAdvancedContinueOptions));
        this.numericOptions = this.createMappedField(data, 'ProfileQuestionNumericOptions', jsonNumericOptions => new SurveyNumericOptions(jsonNumericOptions));
        this.timeSelectionOptions = this.createMappedField(data, 'ProfileQuestionTimeSelectionOptions', jsonTimeSelectionOptions => new SurveyTimeSelectionOptions(jsonTimeSelectionOptions));
        this.websitesOptions = this.createMappedField(data, 'ProfileQuestionWebsitesOptions', jsonWebsitesOptions => new SurveyWebsitesOptions(jsonWebsitesOptions));
        this.blanksOptions = this.createMappedField(data, 'ProfileQuestionBlanksOptions', jsonBlanksOptions => new SurveyBlanksOptions(jsonBlanksOptions));
        this.nameOptions = this.createMappedField(data, 'ProfileQuestionNameOptions', jsonNameOptions => new SurveyNameOptions(jsonNameOptions));
        this.emailOptions = this.createMappedField(data, 'ProfileQuestionEmailOptions', jsonEmailOptions => new SurveyEmailOptions(jsonEmailOptions));
        this.phoneOptions = this.createMappedField(data, 'ProfileQuestionPhoneOptions', jsonPhoneOptions => new SurveyPhoneOptions(jsonPhoneOptions));
        this.timeZoneOptions = this.createMappedField(data, 'ProfileQuestionTimeZoneOptions', jsonTimeZoneOptions => new SurveyTimeZoneOptions(jsonTimeZoneOptions));
        this.addressOptions = this.createMappedField(data, 'ProfileQuestionAddressOptions', jsonAddressOptions => new SurveyAddressOptions(jsonAddressOptions));
        this.dateOptions = this.createMappedField(data, 'ProfileQuestionDateOptions', jsonDateOptions => new SurveyDateOptions(jsonDateOptions));
        this.nicknameOptions = this.createMappedField(data, 'ProfileQuestionNicknameOptions', jsonNicknameOptions => new SurveyNicknameOptions(jsonNicknameOptions));

        this.choices = this.createCollection(data, 'Choices', jsonChoice => new SurveyChoice(jsonChoice));
        this.blankOptions = ko.observableArray(_.flatten(this.blanksOptions()?.blanks().map(b => b.options()) ?? []));
        this.visibilityTags = this.createCollection(data, 'QuestionVisibilityTags', jsonTag => new Tag(jsonTag));
        this.staticImages = this.createCollection(data, 'StaticImages', jsonUserFile => new UserFile(jsonUserFile));

        this.isSingleChoiceQuestion = this.type.is(enums.ProfileQuestionTypes.SingleChoice.value);
        this.isMultipleChoiceQuestion = this.type.is(enums.ProfileQuestionTypes.MultipleChoice.value);

        const singleChoiceControlType = this.choicesOptions.pluck(o => o.singleChoiceControlType);
        const multipleChoiceControlType = this.choicesOptions.pluck(o => o.multipleChoiceControlType);

        this.usesDropdowns = singleChoiceControlType.is(enums.ProfileQuestionSingleChoiceControlTypes.Dropdown.value);
        this.usesRadioButtons = singleChoiceControlType.is(enums.ProfileQuestionSingleChoiceControlTypes.RadioButtons.value);
        this.usesSlider = singleChoiceControlType.is(enums.ProfileQuestionSingleChoiceControlTypes.Slider.value);
        this.usesImageSelector = ko.pureComputed(() => {
            return this.isSingleChoiceQuestion()
                ? singleChoiceControlType() == enums.ProfileQuestionSingleChoiceControlTypes.ImageSelector.value
                : multipleChoiceControlType() == enums.ProfileQuestionMultipleChoiceControlTypes.ImageSelector.value;
        });
        this.usesCheckboxes = ko.pureComputed(() => this.isMultipleChoiceQuestion() && multipleChoiceControlType() == enums.ProfileQuestionMultipleChoiceControlTypes.Checkboxes.value);
    }
}
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import URLBuilder from "builders/url";
import { SignalRConnectionState } from "enums/signalR/connectionState";
import { wccModules } from "enums/wccModules";
import { EffectsContainer } from "mixins/withEffects";
import { SignalRHelpers } from "./helpers";
import { ISignalRCoreConnectionManager } from "./interfaces/connection";
import { ISignalRCoreTokenManager } from "./interfaces/token";

const { wccApiUrl, disableSignalR } = settings;

@injectable()
export default class SignalRCoreConnectionManager implements ISignalRCoreConnectionManager {
    private connectionsCount = ko.observable(0);

    private connectionFailsCount = ko.observable(0);
    private connectionFailedOn = ko.observable<Date>();

    private disconnectedOn = ko.observable<Date>();

    state = ko.observable(SignalRConnectionState.disconnected);
    reconnected = ko.observable(false).extend({ notify: 'always' });

    connection: Subscribable<HubConnection | undefined>

    connected: Subscribable<boolean>
    disconnected: Subscribable<boolean>

    constructor(
        @inject(wccModules.signalRTokenManager) tokenManager: ISignalRCoreTokenManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const token = tokenManager.token;
        
        const onFailReconnectDelay = this.connectionFailsCount.mapSingle(failsCount => {
            switch (failsCount) {
                case 0: return 0;
                case 1: return 5;
                default: return 10;
            }
        });

        const connectionAllowed = ko.pureComputed(() => {
            const delay = onFailReconnectDelay();
            const connectionFailedOn = this.connectionFailedOn();
            const disconnectedOn = this.disconnectedOn();

            const connectionOnFailDelayIsActive = delay > 0 &&
                (connectionFailedOn != undefined && SignalRHelpers.secondsSince(connectionFailedOn) <= delay);

            const connectionDelayIsActive = disconnectedOn != undefined && SignalRHelpers.secondsSince(disconnectedOn) <= 1;

            return !connectionOnFailDelayIsActive && !connectionDelayIsActive;
        });

        this.connection = token.pluck(token => {
            if (!disableSignalR && wccApiUrl != undefined) {
                SignalRHelpers.logInfo(`creating connection for ${wccApiUrl}`);

                const url = URLBuilder.from(wccApiUrl)
                    .addToPath('notificationHub')
                    .addToQuery({ discussionId: !settings.isAdmin ? settings.discussionId : undefined })
                    .toString();

                return new HubConnectionBuilder()
                    .withUrl(url, { accessTokenFactory: () => token })
                    .configureLogging(LogLevel.Information)
                    .build();
            }
        });

        this.connected = this.state.is(SignalRConnectionState.connected);
        this.disconnected = this.state.is(SignalRConnectionState.disconnected);

        effects.register(connection => {
            if (connection != undefined) {
                connection.onclose(() => {
                    this.state(SignalRConnectionState.disconnected);
                    this.disconnectedOn(new Date());
                });

                return () => connection.stop()
            } else {
                this.state(SignalRConnectionState.disconnected);
            }
        }, [this.connection]);

        effects.register((connection, isDisconnected, isConnectionAllowed) => {
            if (connection != undefined && isDisconnected && isConnectionAllowed)
                this.connect(connection);
        }, [this.connection, this.disconnected, connectionAllowed]);

        //If that is not first connection send reconnected signal
        effects.register(connectionsCount => {
            if (connectionsCount > 1)
                this.reconnected(true);
        }, [this.connectionsCount]);

        effects.register([
            ko.computed(() => SignalRHelpers.logInfo('token', token())),
            ko.computed(() => SignalRHelpers.logInfo('connection allowed', connectionAllowed())),
            ko.computed(() => SignalRHelpers.log('connection', this.connection())),

            ko.computed(() => {
                if (this.connected())
                    SignalRHelpers.log('connected')
            }),

            ko.computed(() => {
                if (this.disconnected())
                    SignalRHelpers.log('disconnected');
            }),

            ko.computed(() => {
                if (this.reconnected())
                    SignalRHelpers.log('reconnected')
            })
        ]);
    }

    private async connect(connection: HubConnection) {
        try {
            SignalRHelpers.logInfo('connecting');
            await connection.start();

            this.connectionsCount.inc();
            SignalRHelpers.logInfo('connection idx', this.connectionsCount());

            this.connectionFailsCount(0);
            this.connectionFailedOn(undefined);

            this.state(SignalRConnectionState.connected);
        } catch {
            this.connectionFailsCount.inc();
            this.connectionFailedOn(new Date());

            SignalRHelpers.logError('failed to connect', this.connectionFailsCount());
        }
    }
}
import signalRManager from 'managers/signalR/core/instance';
import ActiveQuery from 'models/queries/active';
import WCCError from 'models/wccError';
import EndPoint from './base';
import GenericEndPoint from './generic';
import WCCResourcedError from "../models/wccResourcedError";

export class APIEndPoint implements EndPoint {
    private endpoint: GenericEndPoint

    constructor() {
        this.endpoint = new GenericEndPoint({
            send: ({ url, method, data, contentType, cancelPromise, headers }) => {
                let jqxhr = WCC.API.apiRequest({
                    url,
                    method,
                    additionalHeaders: { ...headers, ConnectionId: signalRManager.connectionId() },
                    data,
                    contentType
                });

                cancelPromise.then(() => jqxhr.abort());

                return jqxhr.then(data => data, ex => {
                    if (ex.responseText?.length > 0) {
                        if (ex.responseText == '2farequired')
                            window.location.href = '#account/twofactorauth';
                        else {
                            //we probably need some kind of factory here
                            if (ex.responseText != null && ex.responseText.startsWith("{\"LabelKey")) {
                                throw new WCCResourcedError(ex.responseJSON);
                            }
                            else {
                                throw new Error(ex.responseText);
                            }
                        }
                    }
                    else
                        throw new WCCError(messages.UnknownError);
                });
            }
        });
    }

    send(query: ActiveQuery) {
        return this.endpoint.send(query);
    }
}

const apiEndpoint = new APIEndPoint();

export default apiEndpoint;
import endpoint from 'endpoints/api';
import events from 'managers/events';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class HighlightsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.adminQueries = {
            defaultHighlightColors: () => this._repository.fastQuery('admin/account/highlightColors'),
            discussionHighlights: discussionId => this._repository.fastQuery(`admin/discussions/${discussionId}/highlights`),
            discussionHighlightColors: discussionId => this._repository.fastQuery(`admin/discussions/${discussionId}/highlightColors`),
            filteredHighlights: discussionId => this._repository.fastQuery(`admin/discussions/${discussionId}/filteredHighlights`),
            filteredHighlightColors: discussionId => this._repository.fastQuery(`admin/discussions/${discussionId}/filteredHighlightColors`),
            highlightColor: (highlightColorId) => this._repository.fastQuery(`admin/highlightColor/${highlightColorId}`),
            highlightColorId: (highlightId, highlightColorId) => this._repository.fastQuery(`highlights/${highlightId}/highlightColorId/${highlightColorId}`),
            threadHighlightColorId: (highlightId, highlightColorId) => this._repository.query(`threadHighlights/${highlightId}/highlightColorId/${highlightColorId}`),
            highlightColors: () => this._repository.fastQuery('admin/highlightColors'),
            topicHighlights: topicId => this._repository.fastQuery(`admin/topics/${topicId}/highlights`),
            personHighlights: (discussionId, personId) => this._repository.fastQuery(`admin/discussions/${discussionId}/people/${personId}/highlights`),
            defaultHighlightColorsReplacing: (discussionId) => this._repository.fastQuery(`admin/account/highlightColors/discussions/${discussionId}`),
            defaultHighlightColorsRestoring: (discussionId) => this._repository.fastQuery(`admin/account/highlightColors/discussions/${discussionId}/restore`),
        };

        this.queries = {
            highlights: () => this._repository.query('highlights')
        };
    }

    /**
     * Returns discussion highlight colors
     * @param {String} discussionId - discussion identity
     */
    getDiscussionHighlightColors(discussionId) {
        return this.adminQueries.discussionHighlightColors(discussionId).toArray();
    }

    /**
     * Returns default highlight colors
     */
    getDefaultHighlightColors() {
        return this.adminQueries.defaultHighlightColors().toArray();
    }

    /**
     * Returns discussion highlights
     * @param {String} discussionId - discussion identity
     */
    getDiscussionHighlights(discussionId) {
        return this.adminQueries.discussionHighlights(discussionId).toArray();
    }

    /**
     * Returns topic highlights
     * @param {String} topicId - topic identity
     */
    getTopicHighlights(topicId) {
        return this.adminQueries.topicHighlights(topicId).toArray();
    }

    /**
     * Returns discussion highlights
     * @param {String} discussionId - discussion identity
     * @param {String} personId - person identity
     */
    getPersonHighlights(discussionId, personId) {
        return this.adminQueries.personHighlights(discussionId, personId).toArray();
    }

    /**
     * Returns filtered highlights
     * @param {String} discussionId - discussion identity
     * @param {Object} filtersData - advanced filters data
     */
    getFilteredHighlights(discussionId, filtersData) {
        return this.adminQueries.filteredHighlights(discussionId).add(filtersData);
    }

    /**
     * Returns filtered highlights
     * @param {String} discussionId - discussion identity
     * @param {Object} filtersData - advanced filters data
     */
    getFilteredHighlightColors(discussionId, filtersData) {
        return this.adminQueries.filteredHighlightColors(discussionId).add(filtersData);
    }

    /**
     * Adds new highlight
     * @param {object} jsonHighlight -
     */
    addHighlight(jsonHighlight) {
        var data = {
            DiscussionTopicId: jsonHighlight.DiscussionTopicId,
            ThreadContent: jsonHighlight.TextForReport,
            ParentThreadId: jsonHighlight.SourceTopicThreadId,
            HighlightColorId: jsonHighlight.HighlightColorId,
            Text: jsonHighlight.Text
        };

        return this.queries.highlights().important().add(data)
            .then(function (response) { return _({}).extend(jsonHighlight, { HighlightId: response.HighlightId }) })
            .done(function (jsonHighlight) { events.highlightAdded.trigger(jsonHighlight.DiscussionTopicId, jsonHighlight) });
    }

    /**
     * Adds or updates highlight color (depends on highlightColorId value)
     * @param {Highlight} highlightColor - highlight color data
     */
    saveHighlightColor(highlightColor) {
        return this.adminQueries.highlightColors().add(highlightColor);
    }

    /**
     * Swaps highlight highlightColorId
     * @deprecated
     * @param {String} highlightId - highlight identity
     * @param {String} highlightColorId - highlight color identity
     */
    swapHighlightColorId(highlightId, highlightColorId) {
        return this.adminQueries.highlightColorId(highlightId, highlightColorId).update();
    }

    /**
     * Swaps highlight highlightColorId
     * @param {String} highlightId - highlight identity
     * @param {String} highlightColorId - highlight color identity
     */
    swapThreadHighlightColorId(highlightId, highlightColorId) {
        return this.adminQueries.threadHighlightColorId(highlightId, highlightColorId).update();
    }

    /**
     * Deletes highlight color and all highlight associated with it
     * @param {String} highlightColorId - highlight color identity
     */
    deleteHighlightColor(highlightColorId) {
        return this.adminQueries.highlightColor(highlightColorId).remove();
    }

    /**
     * Replaces default account highlight colors by colors from given discussion
     * @param {String} discussionId - source discussion identity
     */
    replaceDefaultHighlightColors(discussionId) {
        return this.adminQueries.defaultHighlightColorsReplacing(discussionId).update();
    }

    /**
     * Replaces highlight colors in given discussion by default account highlight colors. 
     * Warning!!!
     * This will delete ALL existing highlights in the target discussion
     * @param {String} discussionId - target discussion identity
     */
    restoreDefaultHighlightColors(discussionId) {
        return this.adminQueries.defaultHighlightColorsRestoring(discussionId).update();
    }
}
interface ComponentInfo {
    vm: object,
    requests: number
}

class ComponentsLibrary {
    data: { [id: string]: ComponentInfo } = {}

    isRegistered(name: string): boolean {
        return this.data[name] != undefined;
    }

    register(name: string, vm: object) {
        if (this.data[name] == undefined) {
            this.data[name] = {
                vm: vm,
                requests: 1
            };
        } else {
            this.data[name].requests += 1;
        }
    }

    getViewModel(name: string): object | undefined {
        return this.data[name]?.vm;
    }    

    hasOneOwner(name: string): boolean {
        return this.data[name]?.requests === 1;
    }

    unregister(name: string) {
        if (this.hasOneOwner(name)) {
            delete this.data[name];
        } else if (this.data[name] != undefined) {
            this.data[name].requests -= 1;
        }
    };
}

export var componentsLibrary = new ComponentsLibrary();
export const componentMasterMetadataKey = Symbol('component:master');

export default function componentMaster(masterId: string) {
    return (ctor: any) => {
        const masterIDs: Array<string> = [...Reflect.getMetadata(componentMasterMetadataKey, ctor) ?? []];
        masterIDs.push(masterId);

        Reflect.defineMetadata(componentMasterMetadataKey, masterIDs, ctor);

        return ctor;
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { ObservableArray } from 'knockout';
import CollectionDataSourceOwner from 'managers/collectionDataSourceOwner';
import { CollectionDataSource } from 'managers/datasources/collection';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { Person } from 'models/person';
import { PeopleService } from 'services/people';
import events from '../../events';

const { accountId, discussionId } = settings;

export interface DiscussionPeopleManagerConfig {
    discussionId: string
    statusTypes: Array<number>
}

@injectable()
@storageDefaults(<Partial<DiscussionPeopleManagerConfig>>{ discussionId, statusTypes: [] })
export default class DiscussionPeopleManager extends CollectionDataSourceOwner<Person, 'personId'> {
    private discussionId: string

    protected source: CollectionDataSource<Person, 'personId'>

    people: ObservableArray<Person>

    constructor(
        @inject(wccModules.managerConfig) { discussionId, statusTypes }: DiscussionPeopleManagerConfig,
        @inject(wccModules.peopleService) private peopleService: PeopleService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.discussionId = discussionId;

        this.source = effects.register(new CollectionDataSource({
            load: () => this.peopleService.queries.discussionParticipants(this.discussionId).addArgs({ statusTypes }).toArray(),
            update: this.updatePerson.bind(this),
            mapper: jsonPerson => new Person(jsonPerson)
        }));

        this.people = this.source.list;

        if (accountId != undefined)
            signalREvents.accountPeople(accountId).onPersonChanged(this.onPersonChanged.bind(this));            

        signalREvents.discussion(discussionId)
            .onParticipantStatusChanged(this.onStatusChange.bind(this));

        signalREvents.discussionInterviewers(discussionId)
            .onInterviewerSettingsChanged(this.onInterviewerSettingsChanged.bind(this))

        effects.register([
            events.interviewerSettingsChanged.on(this.onLocalInterviewerSettingsChanged.bind(this))
        ]);
    }

    private async updatePerson(person: Person) {
        const jsonPerson = await this.peopleService.queries.discussionParticipants(this.discussionId)
            .addArgs({ '$filter': 'PersonId eq cast(' + person.personId() + ', Edm.Guid)' })
            .background()
            .firstOrDefault();

        if (jsonPerson != undefined)
            person.update(jsonPerson);
    }

    private onPersonChanged(personId: string) {
        this.source.update(function (person) { return person.personId() === personId });
    }

    private onStatusChange(discussionId: string, personId: string, status: number) {
        if (this.discussionId === discussionId) {
            const person = this.people().find(person => person.personId() === personId);

            if (person != undefined)
                person.status(status);
        }
    }

    private onInterviewerSettingsChanged(discussionId: string, personId: string) {
        this.source.update(person => person.personId() == personId);
    }

    private onLocalInterviewerSettingsChanged(discussionId: string, personId: string) {
        if (discussionId == this.discussionId)
            this.source.update(person => person.personId() == personId);
    }
}
import { StringHelpers } from 'helpers/string';
import { CurrentUserManager } from 'managers/currentUser';
import { WCCStorageManager } from 'managers/storage';
import { IWCCStorageManager } from 'managers/iStorage';
import PersonTagsManager from 'managers/tags/person';

const storage: IWCCStorageManager = new WCCStorageManager();

const userManager = storage.get(CurrentUserManager, { discussionId: settings.discussionId });
const user = userManager.pluck(m => m.person);
const userId = user.pluck(m => m.personId);

const userTagsManager = storage.get(PersonTagsManager, [userId]);
const userTags = userTagsManager.pluck(m => m.tags, []);
const userTagsValues = userTags.pluck(t => t.tagValue);

userTagsManager.subscribe(_.noop); //activate manager by subscribing to it

const blankValue = '____';
const tagsRegex = /##([^\##]+)##/g;

export class TagsParser {
    processTags(strInput: string, externalTags: Array<string> = []) {
        return this.injectTagsIntoString(strInput, this.combineTags(externalTags, userTagsValues()));
    }

    processCustomTags(strInput: string, tags: Array<string>) {
        return this.injectTagsIntoString(strInput, tags);
    }

    private injectTagsIntoString(strInput: string, tags: Array<string>) {
        if (StringHelpers.isNullOrEmpty(strInput))
            return strInput;

        if (!tagsRegex.test(strInput))
            return strInput;

        let result = strInput;

        tagsRegex.lastIndex = 0;

        let match: RegExpExecArray | null;

        while (match = tagsRegex.exec(strInput)) {
            const [, pattern] = match;
            const [tagCode, tagDefault = blankValue] = pattern.split('|');

            let tagValue = tagDefault;

            if (tags.length > 0) {
                const foundTagValue: string | undefined = tags
                    .filter(this.getTagCodeSearcher(tagCode))
                    .map(tag => this.extractTagValue(tag))[0];

                if (foundTagValue != undefined)
                    tagValue = foundTagValue;
            }

            result = result.replace(new RegExp('##' + StringHelpers.escapeStringForRegEx(pattern) + '##', 'g'), tagValue);
        }

        return result;
    }

    private combineTags(...collections: Array<Array<string>>) {
        return collections
            .reduce((result, collection) => result.concat(collection), new Array<string>())
            .reduce((tags, tag) => {
                const isComplex = tag.indexOf(":") !== -1;

                if (!isComplex) {
                    if (tags.indexOf(tag) == -1)
                        tags.push(tag); //if it's a simple tag and is not already there in main collection, push it
                } else {
                    const tagRoot = tag.split(':')[0];
                    const tagHead = tagRoot + ':';

                    if (!tags.some(otherTag => otherTag.startsWith(tagHead)))
                        tags.push(tag); //if it's a complex tag, we can't have duplicates with for first part of the tag i.e. Company:Microsoft and Company:Amazon can't exist together
                }

                return tags;
            }, new Array<string>());
    }

    private getTagCodeSearcher(tagCode: string) {
        const simpleValue = tagCode.toLowerCase();
        const headValue = `${simpleValue}:`;

        return (targetTag: string) => {
            const targetTagValue = targetTag.toLowerCase();

            return targetTagValue == simpleValue || targetTagValue.startsWith(headValue);
        }
    }

    private extractTagValue(tag: string) {
        const complexTagSplitterIdx = tag.indexOf(":");

        if (complexTagSplitterIdx > 0)
            return tag.substring(complexTagSplitterIdx + 1)
        else
            return tag;
    }
}

const tagsParser = new TagsParser();

export default tagsParser;
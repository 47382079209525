import { PureComputed } from 'knockout';
import { Effect } from 'mixins/withEffect';
import { withEffects } from 'mixins/withEffects';

export interface INodeChangesTracker {
    mutations: PureComputed<number>
    update: Action<[force?:boolean]>
}

export default abstract class NodeChangesTracker implements INodeChangesTracker {
    private effects = withEffects();
    private _mutations = ko.observable(0);
    private updateFunc: Action

    mutations: PureComputed<number>

    constructor(node: HTMLElement) {
        this.mutations = ko.pureComputed(() => this._mutations());
        const isAwake = this.mutations.isAwake();

        this.updateFunc = _.debounce(() => this._mutations.inc(), 0, false);

        this.effects.register((isNeeded) => {
            if (isNeeded)
                return this.observe(node, this.updateFunc);
        }, [isAwake]);
    }    

    update(force = false) {
        if (force)
            this._mutations.inc();
        else
            this.updateFunc();
    }

    protected abstract observe(node: HTMLElement, onChange: Action): Effect;
}
import { Observable } from "knockout"

export interface JSONTopicCardsScale {
    TopicCardScaleId?: string
    DiscussionTopicId?: string
    Title?: string
    Description?: string
    LeftName?: string
    RightName?: string
    OutOf?: number
    CardScaleOrder?: number
    IncludeQuestion?: boolean
    QuestionText?: string
    QuestionIsMandatory?: boolean
}

export class TopicCardsScale {
    id: Observable<string | undefined>
    topicId: Observable<string | undefined>
    title: Observable<string>
    description: Observable<string>
    leftName: Observable<string>
    rightName: Observable<string>
    outOf: Observable<number | undefined>
    cardScaleOrder: Observable<number | undefined>
    includeQuestion: Observable<boolean>
    questionText: Observable<string | undefined>
    questionIsMandatory: Observable<boolean>

    constructor(jsonModel: JSONTopicCardsScale) {
        this.id = ko.observable(jsonModel.TopicCardScaleId);
        this.topicId = ko.observable(jsonModel.DiscussionTopicId);
        this.title = ko.observable(jsonModel.Title ?? '');
        this.description = ko.observable(jsonModel.Description ?? '');
        this.leftName = ko.observable(jsonModel.LeftName ?? '');
        this.rightName = ko.observable(jsonModel.RightName ?? '');
        this.outOf = ko.observable(jsonModel.OutOf);
        this.cardScaleOrder = ko.observable(jsonModel.CardScaleOrder);
        this.includeQuestion = ko.observable(jsonModel.IncludeQuestion ?? false);
        this.questionText = ko.observable(jsonModel.QuestionText);
        this.questionIsMandatory = ko.observable(jsonModel.QuestionIsMandatory ?? false);
    }

    toJson(): JSONTopicCardsScale {
        return {
            TopicCardScaleId: this.id(),
            DiscussionTopicId: this.topicId(),
            Title: this.title(),
            Description: this.description(),
            LeftName: this.leftName(),
            RightName: this.rightName(),
            OutOf: this.outOf(),
            CardScaleOrder: this.cardScaleOrder(),
            IncludeQuestion: this.includeQuestion(),
            QuestionText: this.questionText(),
            QuestionIsMandatory: this.questionIsMandatory()
        }
    }

    isSimilarTo(scaleToCheck: TopicCardsScale): boolean {
        return this.title().toLowerCase() == scaleToCheck.title().toLowerCase()
            && this.leftName().toLowerCase() == scaleToCheck.leftName().toLowerCase()
            && this.rightName().toLowerCase() == scaleToCheck.rightName().toLowerCase()
            && this.outOf() == scaleToCheck.outOf();
    }
}
define('models/taskStat', [
    'models/commentsDuringTime',
    'models/participantsDuringTime'
], function (CommentsStat, ParticipantsStat) {
    function Model(jsonStat) {
        var self = this;

        var commentsStat = _(jsonStat.CommentsStat).map(function (s) {
            return new CommentsStat(s);
        });

        var participantsStat = _(jsonStat.ParticipantsStat).map(function (s) {
            return new ParticipantsStat(s);
        });

        self.commentsCount = ko.observable(jsonStat.CommentsCount || 0);
        self.participantsCount = ko.observable(jsonStat.ParticipantsCount || 0);
        self.participantsActivity = ko.observable(jsonStat.ParticipantsTopicActivity || 0.0);

        self.commentsStat = ko.observableArray(commentsStat);
        self.participantsStat = ko.observableArray(participantsStat);
    }

    return Model;
});
import URL, { URLQueryData } from 'builders/url';
import EndPoint from 'endpoints/base';
import QueryPriorities from 'enums/queries/priorities';
import HTTPQueryMethods from 'interfaces/httpQueryMethods';
import ActiveQuery from './active';

function toFirst<T>(data: T | Array<T>): T {
    return _.isArray(data) ? data[0] : data
}

function toArray<T>(data: T | Array<T>): Array<T> {
    return _.isArray(data) ? data : [];
}

export interface PassiveQueryConfig {
    url: string
    endpoint: EndPoint

    aggregatable?: boolean
    priority?: QueryPriorities

    cancelPromise?: Promise<void>
}

interface QueryOptions {
    data?: any
    method: HTTPQueryMethods
    aggregatable?: boolean
}

interface QueryDataInfo {
    data?: any,
    contentType?: string
}

export class BasePassiveQuery {
    protected url: URL
    protected endpoint: EndPoint
    private headers: StringMap<string> = {}
    
    protected aggregatable: boolean
    protected priority: QueryPriorities

    protected cancelPromise: Promise<void>

    constructor(jsonQuery: PassiveQueryConfig) {
        this.url = new URL(jsonQuery.url);
        this.endpoint = jsonQuery.endpoint;

        this.aggregatable = jsonQuery.aggregatable ?? false;
        this.priority = jsonQuery.priority ?? QueryPriorities.normal;

        this.cancelPromise = jsonQuery.cancelPromise ?? new Promise(_.noop);
    }

    addArgs(strOrObject: URLQueryData) {
        this.url.addToQuery(strOrObject);
        return this;
    }

    addHeaders(headers: StringMap<string>) {
        this.headers = { ...this.headers, ...headers };
    }
    
    protected getQuery(options: QueryOptions): ActiveQuery {
        return {
            ...this.getDataInfo(options.data),

            url: this.url.toString(),
            method: options.method,
            priority: this.priority,
            headers: this.headers,
            aggregatable: options.aggregatable ?? false,
            cancelPromise: this.cancelPromise
        }
    }

    protected getDataInfo(data?: any): QueryDataInfo {
        if (_.isNumber(data) || _.isString(data) || _.isArray(data))
            return { data: JSON.stringify(data), contentType: 'application/json' }
        else
            return { data: data };
    }
}

export class PassiveEntityGetQuery<GO = any, GI = never> extends BasePassiveQuery {
    firstOrDefault(data?: GI) {
        const query = this.getQuery({
            method: 'GET',
            data: data,
            aggregatable: this.aggregatable
        });

        return this.endpoint.send<GO>(query).then(toFirst);
    }
}

export class PassiveCollectionGetQuery<GO = any, GI = never> extends BasePassiveQuery {
    toArray(data?: GI) {
        const query = this.getQuery({
            method: 'GET',
            data: data,
            aggregatable: this.aggregatable
        });

        return this.endpoint.send<NonNullable<GO>>(query).then(toArray);
    }
}

export class PassivePostQuery<PI = never, PO = void> extends BasePassiveQuery {
    firstOrDefaultPost(data?: PI) {
        const query = this.getQuery({
            method: 'POST',
            data: data
        });

        return this.endpoint.send<PO>(query).then(toFirst);
    }

    toArrayPost(data?: PI) {
        const query = this.getQuery({
            method: 'POST',
            data: data
        });

        return this.endpoint.send<PO>(query).then(toArray);
    }

    add(item: PI) {
        return this.post(item);
    }

    post(data?: PI) {
        const query = this.getQuery({
            method: 'POST',
            data: data
        });

        return this.endpoint.send<PO>(query);
    }
}

export class PassiveUpdateQuery<UI = any, UO = any> extends BasePassiveQuery {
    update(item?: UI) {
        const query = this.getQuery({
            method: 'PUT',
            data: item
        });

        return this.endpoint.send<UO>(query);
    }
}

export class PassiveRemoveQuery<D = never> extends BasePassiveQuery {
    remove() {
        const query = this.getQuery({
            method: 'DELETE'
        });

        return this.endpoint.send<D>(query);
    }
}

abstract class PassiveCompositeQuery {
    protected abstract queries: Array<BasePassiveQuery>

    /** @deprecated */
    important() {
        return this;
    }

    /** @deprecated */
    background() {
        return this;
    }

    addArgs(strOrObject: URLQueryData) {
        this.queries.forEach(q => q.addArgs(strOrObject));
        return this;
    }

    addHeaders(headers: StringMap<string>) {
        this.queries.forEach(q => q.addHeaders(headers));
        return this;
    }
}

export class PassiveEntityQuery<GO = any, GI = any, D = never> extends PassiveCompositeQuery {
    private getEntityQuery: PassiveEntityGetQuery<GO, GI>
    private removeQuery: PassiveRemoveQuery<D>

    protected queries: Array<BasePassiveQuery>

    constructor(jsonQuery: PassiveQueryConfig) {
        super();

        this.getEntityQuery = new PassiveEntityGetQuery(jsonQuery);
        this.removeQuery = new PassiveRemoveQuery(jsonQuery);

        this.queries = [
            this.getEntityQuery,
            this.removeQuery
        ]
    }

    firstOrDefault(data?: GI) {
        return this.getEntityQuery.firstOrDefault(data);
    }

    remove() {
        return this.removeQuery.remove();
    }
}

/**
 * G - Get
 * P - Post
 * U - Update
 * D - Delete
 * I - Input - data we send to server
 * O - Output - data we get from server
 * so GI - data we send to server on Get request
 * */
export default class PassiveQuery<GI = any, GO = any, PI = any, PO = any, UI = any, UO = any, D = any> extends PassiveCompositeQuery {
    private getEntityQuery: PassiveEntityGetQuery<GO, GI>
    private getCollectionQuery: PassiveCollectionGetQuery<GO, GI>
    private postQuery: PassivePostQuery<PI, PO>
    private updateQuery: PassiveUpdateQuery<UI, UO>
    private removeQuery: PassiveRemoveQuery<D>

    protected queries: Array<BasePassiveQuery>

    constructor(jsonQuery: PassiveQueryConfig) {
        super();

        this.getEntityQuery = new PassiveEntityGetQuery(jsonQuery);
        this.getCollectionQuery = new PassiveCollectionGetQuery(jsonQuery);
        this.postQuery = new PassivePostQuery(jsonQuery);
        this.updateQuery = new PassiveUpdateQuery(jsonQuery);
        this.removeQuery = new PassiveRemoveQuery(jsonQuery);

        this.queries = [
            this.getEntityQuery,
            this.getCollectionQuery,
            this.postQuery,
            this.updateQuery,
            this.removeQuery
        ]
    }

    firstOrDefault(data?: GI) {
        return this.getEntityQuery.firstOrDefault(data);
    }

    firstOrDefaultPost(data?: PI) {
        return this.postQuery.firstOrDefaultPost(data);
    }

    toArray(data?: GI) {
        return this.getCollectionQuery.toArray(data);
    }

    toArrayPost(data?: PI) {
        return this.postQuery.toArrayPost(data);
    }

    add(item: PI) {
        return this.postQuery.add(item);
    }

    post(data?: PI) {
        return this.postQuery.post(data);
    }

    update(item?: UI) {
        return this.updateQuery.update(item);
    }

    remove() {
        return this.removeQuery.remove();
    }    
}
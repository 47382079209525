import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import EntityDataSourceOwner from 'managers/entityDataSourceOwner';
import events from 'managers/events';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { LiveGroupService } from 'services/livegroup';

export interface TopicActiveSlideManagerConfig {
    topicId: string
}

@injectable()
export default class TopicActiveSlideManager extends EntityDataSourceOwner<string> {
    private topicId: string

    protected source: EntityDataSource<string>

    slideId: Subscribable<string | undefined>

    constructor(
        @inject(wccModules.managerConfig) config: TopicActiveSlideManagerConfig,
        @inject(wccModules.liveGroupService) private liveGroupService: LiveGroupService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.topicId = config.topicId;

        this.source = effects.register(new EntityDataSource<string>({
            update: this.loadActiveSlideId.bind(this)
        }));

        this.slideId = this.source.data;

        effects.register([
            events.topicActiveSlideChanged.on(this.onLocalActiveSlideChanged.bind(this))
        ]);        

        signalREvents.topicSlides(this.topicId)
            .onActiveSlideChanged((topicId, slideId, isStaging) => {
                if (isStaging == settings.isStaging)
                    this.source.set(slideId);
            }).onSubscriptionActivated(() => {
                this.source.update();
            });
    }

    private loadActiveSlideId() {
        return this.liveGroupService.queries.topicActiveSlide(this.topicId).firstOrDefault();
    }

    private onLocalActiveSlideChanged(topicId: string, slideId: string | undefined) {
        if (this.topicId == topicId)
            this.source.set(slideId);
    }
}
import { inject } from "inversify";
import { Subscribable } from "knockout";
import { wccModules } from "../../enums/wccModules";
import { EffectsContainer } from "../../mixins/withEffects";
import { JSONPerson, Person } from "../../models/person";
import { IPeopleService } from "../../services/iPeople";
import { EntityDataSource } from "../datasources/entity";
import EntityDataSourceOwner from "../entityDataSourceOwner";

export interface PersonManagerConfig {
    personId: string
}

export default class PersonManager extends EntityDataSourceOwner<JSONPerson> {
    protected source: EntityDataSource<JSONPerson>

    person: Subscribable<Person | undefined>

    constructor(
        @inject(wccModules.managerConfig) { personId }: PersonManagerConfig,
        @inject(wccModules.peopleService) peopleService: IPeopleService,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.source = effects.register(new EntityDataSource({
            update: () => peopleService.personQuery(personId).firstOrDefault()
        }));

        this.person = this.source.data.toEntity((jsonPerson) => new Person(jsonPerson), (person, jsonPerson) => person.update(jsonPerson));
    }
}
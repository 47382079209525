import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import { IWebSitesService } from './iWebsites';

export default class WebSitesService extends BaseService implements IWebSitesService {
    private repository = new Repository(endpoint);

    check = () => this.repository.postQuery<string, boolean>(`websites/check`);
    websiteContent = () => this.repository.postQuery<string, string>(`websites/loadcontent`);
}
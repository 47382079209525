export const browser: { [key: string]: boolean } = {
    mozilla: /mozilla/.test(navigator.userAgent.toLowerCase()) && !/webkit/.test(navigator.userAgent.toLowerCase()),
    webkit: /webkit/.test(navigator.userAgent.toLowerCase()),
    opera: /opera/.test(navigator.userAgent.toLowerCase()),
    msie: /msie/.test(navigator.userAgent.toLowerCase())
}

export const isIE = browser.msie || navigator.userAgent.match(/Trident\/7\./) ? true : false;
export const isMobileIE = navigator.userAgent.match(/iemobile/i) ? true : false;

export function isOldIE() {
    return false;// WCC.isIE && parseInt(browser.version) < 9;
}

export function isIE8() {
    return false; // WCC.isIE && browser.version === '8.0';
}
define('managers/community/projects',
    [
        'managers/base_es5',
        'managers/datasources/collection'
    ],

    function (Base, { CollectionDataSource: DataSource }) {
        const { default: Project } = require('models/task');

        function Manager() {
            Base.call(this);

            this.communityId = ko.pureComputed(function () { return settings.discussionId });

            this._source = this._register(new DataSource({
                load: this._load.bind(this),
                mapper: function (jsonProject) { return new Project(jsonProject) }
            }));

            this.projects = this._source.list;
            this.busy = this._source.busy;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype._load = function () {
            return this._ctx
                .communitiesService
                .queries
                .discussions(this.communityId())
                .toArray();
        };

        return Manager;
    });  
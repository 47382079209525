import endpoint from 'endpoints/api';
import { JSONPixabayOptions } from 'models/pixabay/options';
import { JSONPixabayResponse } from 'models/pixabay/response';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class PixabayService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        search: () => this.repository.query<JSONPixabayOptions, JSONPixabayResponse>('pixabay/search')
    }

    search(options: JSONPixabayOptions) {
        return this.queries.search().firstOrDefault(options);
    }
}
define('managers/account/images', [
    'managers/base_es5',
    'managers/datasources/collection',
    'models/account/image'
], function (Base, { CollectionDataSource: DataSource }, AccountImage) {
    function Manager(type) {
        Base.call(this);

        this.type = ko.pureComputed(function () { return type });

        this._source = this._register(new DataSource({
            load: this._load.bind(this),
            mapper: function (jsonImage) { return new AccountImage(jsonImage) }
        }));

        this.images = this._source.list;

        this.busy = this._source.busy;
        this.loading = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() === 0 }, this);
        this.updating = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() > 0 }, this);
    }

    Manager.prototype = Object.create(Base.prototype);
    Manager.prototype.constructor = Manager;

    Manager.prototype._load = function () {
        return this._ctx
            .attachmentsService
            .queries
            .accountImages(this.type())
            .background()
            .toArray();
    };

    return Manager;
});
import { ObservableArray, PureComputed, Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import { CollectionDataSource } from 'managers/datasources/collection';
import events from 'managers/events';
import SignalREventsManager from 'managers/signalR/events';
import SimpleTopicManager from 'managers/simpleTopic';
import { IWCCStorageManager } from 'managers/iStorage';
import { TopicManager } from 'managers/topic';
import { withEffect } from 'mixins/withEffect';
import { withEffects } from 'mixins/withEffects';
import { TopicThread } from 'models/topicThread';
import { ServicesContext } from 'services/context';
import TopicThreadsAttachmentsUpdater from './attachmentsUpdater';

export interface TopicThreadsEventsProcessorConfig {
    topicId: string,
    source: CollectionDataSource<TopicThread, 'id'>
}

export default class TopicThreadsEventsProcessor {
    private effects = withEffects()
    private source: CollectionDataSource<TopicThread, 'id'>

    private topicId: string
    private userId: Subscribable<string | undefined>
    private isModerator: Subscribable<boolean>

    private threads: ObservableArray<TopicThread>

    constructor(
        { topicId, source }: TopicThreadsEventsProcessorConfig,
        ctx: ServicesContext,
        storage: IWCCStorageManager,
        signalREvents: SignalREventsManager
    ) {
        this.source = source;
        this.topicId = topicId;
        this.threads = source.list;

        const effects = this.effects;
        const threads = source.list;

        const userManager = storage.get(CurrentUserManager, { discussionId: settings.discussionId });
        const user = userManager.pluck('person');
        this.userId = user.pluck('personId');
        this.isModerator = user.pluck('isModerator', false);

        const simpleTopicManager = storage.get(SimpleTopicManager, { topicId: this.topicId });
        const simpleTopic = simpleTopicManager.pluck(m => m.topic);
        const isIdeation = simpleTopic.pluck(t => t.isIdeation, false);

        const topicManager = storage.get(TopicManager, { topicId: this.topicId });
        const topic = topicManager.pluck(m => m.topic);

        effects.register(new TopicThreadsAttachmentsUpdater({ topicId, threads }, signalREvents));

        effects.register(userId => {
            if (userId != undefined)
                return [
                    ctx.peopleService.subscriptions.personNotifications.subscribe(userId),
                    signalREvents.personNotifications(userId).onNotificationEvent(this.onNewNotification.bind(this))
                ]
        }, [this.userId]);

        //In ideation we want to reload threads on stage change
        effects.register((isIdeation) => {
            if (isIdeation)
                return withEffect(topic => {
                    if (topic != undefined)
                        return topic.ideationStage.subscribe(() => source.reset())
                }, [topic]);
        }, [isIdeation]);

        ctx.commentsService.subscriptions.topicThreads.subscribe(topicId);
        ctx.commentsService.subscriptions.threadContentChange.subscribe(topicId);
        ctx.attachmentsService.subscriptions.videoUpdates.subscribe(topicId);

        ctx.commentsService.events.topicThreadChanged.on(this.onThreadChanged.bind(this));
        ctx.commentsService.events.topicThreadDeleted.on(this.onThreadDeleted.bind(this));
        ctx.commentsService.events.threadContentChanged.on(this.onThreadContentIsChanged.bind(this));
        ctx.commentsService.events.threadResponseMandatorySettingChanged.on(this.onThreadResponseIsMandatory.bind(this));
        ctx.commentsService.events.threadPinnedStateChanged.on(this.onPinnedStateChanged.bind(this));
        ctx.discussionService.events.deletedNotificationEvent.on(this.onThreadNotificationDeleted.bind(this));
        ctx.attachmentsService.events.videoUpdated.on(this.onThreadVideoUpdated.bind(this));

        signalREvents.topicThreads(this.topicId)
            .onTranslationIsChanged(this.onThreadTranslationIsChanged.bind(this));

        signalREvents.topicNotifications(this.topicId)
            .onThreadReportCleared(this.onThreadReportCleared.bind(this))
            .onThreadReportUndone(this.onThreadReportUndone.bind(this));

        effects.register(isModerator => {
            if (isModerator)
                return signalREvents.topicNotifications(this.topicId).onNotificationEvent(this.onNewNotification.bind(this));
        }, [this.isModerator]);

        effects.register([
            events.threadApproved.on(threadId => this.source.findSync(threadId, thread => thread.isApproved(true))),
            events.threadUnapproved.on(threadId => this.source.findSync(threadId, thread => thread.isApproved(false))),

            events.threadTranslationIsRemoved.on(this.onLocalThreadTranslationIsRemoved.bind(this)),
            events.threadTranslationIsChanged.on(this.onLocalThreadTranslationIsChanged.bind(this)),

            events.threadReportedAsAbuse.on(this.onLocalThreadReportedAsAbuse.bind(this)),
            events.threadClearedFromAbuseReport.on(this.onLocalThreadClearedFromAbuseReport.bind(this)),

            events.commentNotificationsRemoved.on(this.onLocalThreadNotificationsRemoved.bind(this)),
            events.commentsNotificationsRemoved.on(this.onLocalThreadsNotificationsRemoved.bind(this)),

            events.commentPinnedStateChanged.on(this.onLocalThreadPinnedStateChanged.bind(this)),
            events.commentResponseIsMandatory.on(this.onLocalThreadResponseIsMandatory.bind(this)),
        ]);   
    }

    dispose() {
        this.effects.dispose();
    }

    private onThreadChanged(threadId: string) {
        this.source.update(thread => thread.id() === threadId);
    }

    private onThreadDeleted(threadId: string, parentThreadId: string, personId: string, topicId: string) {
        if (this.topicId === topicId) {
            this.source.findSync(threadId, thread => thread.isDeleted(true));
            this.source.findSync(parentThreadId, thread => {
                thread.repliesCount.dec();

                if (thread.upvotesCount() > 0 || thread.downvotesCount() > 0)
                    this.source.update(c => c.id() == parentThreadId);
            });
        }
    }

    private onThreadContentIsChanged(topicId: string, threadId: string, threadContent: string) {
        if (this.topicId === topicId)
            this.source.findSync(threadId, thread => thread.content(threadContent));
    }

    private onThreadTranslationIsChanged(topicId: string, threadId: string, content: string) {
        this.source.findSync(threadId, thread => thread.translatedContent(content))
    }

    private onThreadResponseIsMandatory(discussionId: string, topicId: string, threadId: string, isMandatory: boolean) {
        if (this.topicId === topicId)
            this.source.findSync(threadId, thread => thread.isResponseMandatory(isMandatory));
    }

    private onPinnedStateChanged(topicId: string, threadId: string, isPinned: boolean) {
        if (this.topicId === topicId)
            this.source.findSync(threadId, thread => thread.isPinned(isPinned));
    }

    private onThreadNotificationDeleted(topicId: string, threadId: string, personId: string) {
        if (this.userId() === personId && this.topicId === topicId) {
            this.threads().forEach(thread => {
                if (thread.id() === threadId || thread.parentId() === threadId)
                    thread.isInToDoList(false)
            });
        }
    }

    private onThreadVideoUpdated(topicId: string, videoId: string) {
        if (this.topicId === topicId)
            this.source.update(thread => thread.videos().some(video => video.attachmentId() === videoId));
    }

    private onNewNotification(topicId: string, threadId: string, personId: string, eventType: number) {
        if (eventType === enums.NotificationEventType.ReportedAsAbuse.value)
            this.source.findSync(threadId, thread => {
                thread.spamComplaintCount.inc();
                thread.isInToDoList(true);
            });
    }

    private onThreadReportCleared(topicId: string, threadId: string) {
        this.source.findSync(threadId, thread => {
            thread.spamComplaintCount(0);
            thread.isReportedAsAbuse(false);

            if(this.isModerator())
                thread.isInToDoList(false);
        });
    }

    private onThreadReportUndone(topicId: string, threadId: string) {
        this.source.findSync(threadId, thread => {
            if (thread.spamComplaintCount() > 0)
                thread.spamComplaintCount.dec();
        });
    }    

    private onLocalThreadTranslationIsRemoved(threadId: string) {
        this.source.findSync(threadId, thread => thread.translatedContent(''));
    }

    private onLocalThreadTranslationIsChanged(threadId: string, content: string) {
        this.source.findSync(threadId, thread => thread.translatedContent(content));
    }

    private onLocalThreadReportedAsAbuse(threadId: string, isAbuse: boolean) {
        this.source.findSync(threadId, thread => thread.isReportedAsAbuse(isAbuse));
    }

    private onLocalThreadClearedFromAbuseReport(threadId: string) {
        this.source.findSync(threadId, thread => {
            thread.spamComplaintCount(0);
            thread.isInToDoList(false);
        });
    }

    private onLocalThreadNotificationsRemoved(topicId: string, threadId: string) {
        if (this.topicId === topicId) {
            this.threads().forEach(thread => {
                if (thread.id() === threadId || thread.parentId() === threadId)
                    thread.isInToDoList(false)
            });
        }
    }

    private onLocalThreadsNotificationsRemoved(topicId: string) {
        if (this.topicId === topicId)
            this.threads().forEach(thread => thread.isInToDoList(false));
    }    

    private onLocalThreadPinnedStateChanged(topicId: string, threadId: string, pinned: boolean) {
        if (this.topicId === topicId)
            this.source.findSync(threadId, thread => thread.isPinned(pinned));
    }

    private onLocalThreadResponseIsMandatory(topicId: string, threadId: string, isMandatory: boolean) {
        if (this.topicId === topicId)
            this.source.findSync(threadId, thread => thread.isResponseMandatory(isMandatory));
    }
}
define('managers/disposables', [], function () {
    var defaultInit = function (item) { item.init && item.init() };

    var defaultDispose = function (item) {
        var isArray = _(item).isArray();

        if (item.dispose != null) {
            item.dispose();
        } else if (isArray) {
            _(item).each(defaultDispose);
        }
    };

    function Manager() {
        this._onDisposed = _.noop;

        this.disposed = false;

        this.disposePromise = new Promise(function (resolve) {
            this._onDisposed = resolve;
        }.bind(this));

        this._subs = [];
    }

    Manager.prototype.register = function () {
        var args = _.toArray(arguments);

        if (_(args[0]).isArray()) {
            _(args[0]).each(function (s) { this.register(s) }, this);
        } else {
            let data, init, dispose;

            if (args.length === 3) {
                data = args[0];
                init = args[1];
                dispose = args[2];

                init(data);
            } else if (args.length === 2) {
                init = args[0];
                dispose = args[1];

                data = init();
            } else if (args.length === 1) {
                data = args[0];
                dispose = defaultDispose;

                if (!ko.isObservable(data) && _(data.init).isFunction())
                    data.init();
            } else {
                throw 'invalid register call';
            }

            if (this.disposed) {
                dispose(data);
            } else {
                this._subs.push({ dispose: function () { dispose(data) } });
            }
        }

        return this;
    };

    Manager.prototype.registerObservable = function (observable, init, dispose) {
        init = init || defaultInit;
        dispose = dispose || defaultDispose;

        this.register([
            observable.subscribeNotNull(dispose, null, 'beforeChange'),

            ko.computed(function () {
                var data = observable();

                if (data != null)
                    ko.ignoreDependencies(init, null, [data]);
            })
        ]);

        this.register({
            dispose: function () {
                var data = observable();

                if (data)
                    dispose(data);

                if (ko.isComputed(observable))
                    observable.dispose();
            }
        });
    };

    Manager.prototype.getChild = function () {
        var child = new Manager();
        this.register(child);

        return child;
    };

    Manager.prototype.dispose = function () {
        ko.ignoreDependencies(function () {
            if (!this.disposed) {
                _(this._subs).invoke('dispose');
                this._subs = [];

                this.disposed = true;
                this._onDisposed();
            }
        }, this);
    };

    return Manager;
});
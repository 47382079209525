import {Observable} from "knockout";

/**
 * Checks if video with provided URL exists
 * @param url url to check
 * @param timeout request timeout. false is returned once timeout is reached
 */
export function checkIfVideoExists(url: string, timeout = 30000) {
    return new Promise<boolean>((resolve, reject) => {
        const $video = $('<video/>');

        $video.on('loadedmetadata', () => resolve(true));
        $video.on('loadeddata', () => resolve(true));
        $video.on('error', ex => resolve(false));

        if (timeout != 0)
            _.delay(() => resolve(false), timeout);

        $video.attr('src', url);
    });
}

/**
 * Disposes a media stream
 * @param stream The stream to dispose
 */
export function disposeStream(stream: MediaStream) {
    if (stream == null) return;

    //stop all the tracks of the stream
    stream.getTracks().forEach(track => track.stop());
}

/**
 * Gets width/height from a video stream
 * @param mediaStream The source stream
 */
export function getDimensionsFromMediaStream(mediaStream: MediaStream) {
    try {
        const videoTrack = mediaStream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();

        const width = settings.width ?? 0;
        const height = settings.height ?? 0;
        
        return [ width, height ];
    } catch (err) {
        console.error("Error capturing dimensions from the media stream:", err);
        return [0, 0];
    }
}
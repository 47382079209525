define('managers/topic/moodTags',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const { CollectionDataSource: DataSource } = require('managers/datasources/collection');
        const { Tag } = require('models/tag');

        function Manager(topicId) {
            Base.call(this);

            this.topicId = ko.pureComputed(function () { return topicId });

            this._source = this._register(new DataSource({
                load: this._load.bind(this),
                mapper: function (jsonTag) { return new Tag(jsonTag) }
            }));

            this.tags = this._source.list;

            this.busy = this._source.busy;
            this.loading = ko.pureComputed(function () { return this.busy() && this.tags().length === 0 }, this);
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.update = function () {
            this._source.load();
        };

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.topicsService.subscriptions.discussionTopicNotifications.subscribe(this.topicId());
            this._ctx.topicsService.events.topicChanged.on(this._onTopicChanged.bind(this));
        };

        Manager.prototype._load = function () {
            if (this.topicId() == null)
                return $.Deferred().reject();

            return this._ctx
                .tagsService
                .queries
                .topicMoodTags(this.topicId())
                .toArray();
        };

        Manager.prototype._onTopicChanged = function (topicId) {
            if (this.topicId() === topicId)
                this.update();
        };

        return Manager;
    });  
import endpoint from 'endpoints/api';
import { injectable } from 'inversify';
import events from 'managers/events';
import { apiAppointmentSaveData } from 'models/apiAppointmentSaveData';
import { apiTimeBoundaries } from 'models/apiTimeBoundaries';
import { JSONAppointmentInterviewerSettings } from 'models/appointments/appointmentInterviewerSettings';
import { JSONAppointmentPublicInterviewerSettings } from 'models/appointments/appointmentPublicInterviewerSettings';
import { JSONAppointmentRequest } from 'models/appointments/appointmentRequest';
import { JSONGroupRoom } from 'models/appointments/groupRoom';
import { JSONGroupRoomEvent } from 'models/appointments/groupRoomEvent';
import { JSONGroupRoomSlideDocumentState, JSONGroupRoomSlideState, JSONGroupRoomSlideVideoState } from 'models/appointments/groupRoomSlideState';
import { JSONGroupRoomSlideStateDocumentObjectsChanges } from 'models/appointments/groupRoomSlideStateDocumentObjectsChanges';
import { JSONAppointmentsProgress } from 'models/appointmentsProgress';
import { JSONInterval } from 'models/interval';
import { JSONOnlineUserSession } from 'models/signalR/onlineUserSession';
import Repository from 'repositories/generic';
import { IAppointmentsService } from './appointments.interfaces';

@injectable()
export class AppointmentsService implements IAppointmentsService {
    private repository = new Repository(endpoint);

    private setAppointmentActiveTopicQuery = (appointmentId: string) => this.repository.postQuery<string>(`appointments/${appointmentId}/setactivetopic`);
    private setAppointmentActiveSlideQuery = (appointmentId: string) => this.repository.postQuery<string>(`appointments/${appointmentId}/setactiveslide`);
    private clearAppointmentActiveSlideQuery = (appointmentId: string) => this.repository.postQuery<string>(`appointments/${appointmentId}/clearactiveslide`);

    private setAppointmentSlideVideoStateQuery = (appointmentId: string, slideId: string) => this.repository.postQuery<JSONGroupRoomSlideVideoState, JSONGroupRoomSlideState>(`appointments/${appointmentId}/slides/${slideId}/videostate`);
    private setAppointmentSlideDocumentStateQuery = (appointmentId: string, slideId: string) => this.repository.postQuery<JSONGroupRoomSlideDocumentState, JSONGroupRoomSlideState>(`appointments/${appointmentId}/slides/${slideId}/documentstate`);
    private changeAppointmentSlideStateDocumentObjectsQuery = (appointmentId: string, slideId: string) => this.repository.postQuery<JSONGroupRoomSlideStateDocumentObjectsChanges, JSONGroupRoomSlideState>(`appointments/${appointmentId}/slides/${slideId}/state/documentobjectschanges`);

    private addDiscussionAppointmentQuery = (discussionId: string) => this.repository.postQuery<apiAppointmentSaveData, JSONGroupRoom>(`discussions/${discussionId}/appointments`);
    private updateAppointmentQuery = (appointmentId: string) => this.repository.updateQuery<apiAppointmentSaveData, JSONGroupRoom>(`appointments/${appointmentId}`);
    private updateAppointmentStatusQuery = (appointmentId: string) => this.repository.updateQuery<number>(`appointments/${appointmentId}/status`);
    private sendAppointmentRequestToParticipantsWithoutAppointmentQuery = (discussionId: string) => this.repository.postQuery(`discussions/${discussionId}/people/withoutappointment/requestappointment`);
    private addAppointmentFromRequestQuery = (requestId: string) => this.repository.postQuery<apiAppointmentSaveData, JSONGroupRoom>(`appointmentsrequests/${requestId}/addappointment`);
    private cancelAppointmentQuery = (appointmentId: string) => this.repository.postQuery<never, JSONGroupRoom>(`appointments/${appointmentId}/cancel`);
    private confirmAppointmentQuery = (appointmentId: string) => this.repository.postQuery<never, JSONGroupRoom>(`appointments/${appointmentId}/confirm`);
    private setAppointmentInterviewerSettingsQuery = (discussionId: string, personId: string) => this.repository.updateQuery<JSONAppointmentInterviewerSettings>(`discussions/${discussionId}/appointments/interviewers/${personId}/settings`);

    private addRecordingEndedEventQuery = (appointmentId: string) => this.repository.postQuery(`appointments/${appointmentId}/events/recordingended`);
    private addRecordingStartedEventQuery = (appointmentId: string) => this.repository.postQuery(`appointments/${appointmentId}/events/recordingstarted`);    

    appointmentQuery = (appointmentId: string) => this.repository.entityQuery<JSONGroupRoom>(`appointments/${appointmentId}`);
    appointmentEventsQuery = (appointmentId: string) => this.repository.collectionGetQuery<JSONGroupRoomEvent>(`appointments/${appointmentId}/events`);

    userAppointmentsQuery = (discussionId: string) => this.repository.collectionGetQuery<JSONGroupRoom>(`discussions/${discussionId}/user/appointments`);
    userAppointmentRequestsQuery = (discussionId: string) => this.repository.collectionGetQuery<JSONAppointmentRequest>(`discussions/${discussionId}/user/appointmentsrequests`);

    appointmentsQuery = (discussionId: string, from?: string, to?: string, host?: string, participant?: string) => this.repository.collectionGetQuery<JSONGroupRoom>(`discussions/${discussionId}/appointments`).addArgs({ from, to, host, participant });
    appointmentsTimeBoundariesQuery = (discussionId: string, host?: string, participant?: string) => this.repository.entityGetQuery<apiTimeBoundaries>(`discussions/${discussionId}/appointments/timeboundaries`).addArgs({ host, participant });

    appointmentsIntervalsQuery = (discussionId: string, from?: string, to?: string) => this.repository.collectionGetQuery<JSONInterval>(`discussions/${discussionId}/appointments/intervals`).addArgs({ from, to });
    appointmentsIntervalsTimeBoundariesQuery = (discussionId: string, host?: string) => this.repository.entityGetQuery<apiTimeBoundaries>(`discussions/${discussionId}/appointments/intervals/timeboundaries`).addArgs({ host });

    userAppointmentsIntervalsQuery = (discussionId: string, from?: string, to?: string) => this.repository.collectionGetQuery<JSONInterval>(`discussions/${discussionId}/user/appointments/intervals`).addArgs({ from, to });
    userAppointmentsIntervalsTimeBoundariesQuery = (discussionId: string, host?: string) => this.repository.entityGetQuery<apiTimeBoundaries>(`discussions/${discussionId}/user/appointments/intervals/timeboundaries`).addArgs({ host });

    interviewerIntervalsQuery = (discussionId: string, interviewerPersonId: string, from?: string, to?: string) => this.repository.collectionGetQuery<JSONInterval>(`discussions/${discussionId}/interviewers/${interviewerPersonId}/intervals`).addArgs({ from, to });
    interviewerIntervalsTimeBoundariesQuery = (discussionId: string, interviewerPersonId: string) => this.repository.entityGetQuery<apiTimeBoundaries>(`discussions/${discussionId}/interviewers/${interviewerPersonId}/intervals/timeboundaries`);

    appointmentOnlinePeopleQuery = (appointmentId: string) => this.repository.collectionGetQuery<JSONOnlineUserSession>(`appointments/${appointmentId}/onlinepeople`);
    appointmentOnlinePersonQuery = (appointmentId: string, sessionId: string) => this.repository.entityGetQuery<JSONOnlineUserSession>(`appointments/${appointmentId}/onlinepeople/${sessionId}`);

    appointmentSlideStateQuery = (appointmentId: string, slideId: string) => this.repository.entityGetQuery<JSONGroupRoomSlideState>(`appointments/${appointmentId}/slides/${slideId}/state`)

    appointmentInterviewerSettingsQuery = (discussionId: string, personId: string) => this.repository.entityGetQuery<JSONAppointmentInterviewerSettings>(`discussions/${discussionId}/appointments/interviewers/${personId}/settings`);
    appointmentPublicInterviewerSettingsQuery = (discussionId: string, personId: string) => this.repository.entityGetQuery<JSONAppointmentPublicInterviewerSettings>(`discussions/${discussionId}/appointments/interviewers/${personId}/settings/public`);

    appointmentsProgressQuery = (discussionId: string) => this.repository.entityGetQuery<JSONAppointmentsProgress>(`discussions/${discussionId}/appointments/progress`);    

    async addAppointmentFromRequest(discussionId: string, requestId: string, appointmentData: apiAppointmentSaveData) {
        const jsonAppointment = await this.addAppointmentFromRequestQuery(requestId).add(appointmentData);
        events.appointmentAdded.trigger(discussionId, jsonAppointment);
        events.appointmentRequestResolved.trigger(discussionId, requestId);
        events.appointmentsProgressChanged.trigger(discussionId);

        return jsonAppointment;
    }

    async addAppointment(discussionId: string, appointmentData: apiAppointmentSaveData) {
        const jsonAppointment = await this.addDiscussionAppointmentQuery(discussionId).add(appointmentData);

        events.appointmentAdded.trigger(discussionId, jsonAppointment);
        events.appointmentsProgressChanged.trigger(discussionId);

        return jsonAppointment;
    }

    async updateAppointment(discussionId: string, appointmentId: string, appointmentData: apiAppointmentSaveData) {
        const jsonAppointment = await this.updateAppointmentQuery(appointmentId).update(appointmentData);

        events.appointmentUpdated.trigger(discussionId, appointmentId, jsonAppointment);
        events.appointmentsProgressChanged.trigger(discussionId);

        return jsonAppointment;
    }

    async updateAppointmentStatus(appointmentId: string, status: number) {
        await this.updateAppointmentStatusQuery(appointmentId).update(status);

        events.appointmentStatusChanged.trigger(appointmentId, status);
    }

    async cancelAppointment(discussionId: string, appointmentId: string) {
        const jsonAppointment = await this.cancelAppointmentQuery(appointmentId).post();

        events.appointmentUpdated.trigger(discussionId, appointmentId, jsonAppointment);
        events.appointmentsProgressChanged.trigger(discussionId);
    }

    async confirmAppointment(discussionId: string, appointmentId: string) {
        const jsonAppointment = await this.confirmAppointmentQuery(appointmentId).post();

        events.appointmentUpdated.trigger(discussionId, appointmentId, jsonAppointment);
        events.appointmentsProgressChanged.trigger(discussionId);
    }

    async removeAppointment(discussionId: string, roomId: string, roomData: JSONGroupRoom) {
        await this.appointmentQuery(roomId).remove();

        events.appointmentRemoved.trigger(discussionId, roomId, roomData);
        events.appointmentsProgressChanged.trigger(discussionId);
    }

    async sendAppointmentRequestToParticipantsWithoutAppointment(discussionId: string) {
        await this.sendAppointmentRequestToParticipantsWithoutAppointmentQuery(discussionId).post();
        events.appointmentsProgressChanged.trigger(discussionId);
    }

    async setAppointmentActiveTopic(appointmentId: string, topicId?: string) {
        await this.setAppointmentActiveTopicQuery(appointmentId).post(topicId);
        events.appointmentActiveTopicChanged.trigger(appointmentId, topicId);
    }

    async setAppointmentActiveSlide(appointmentId: string, slideId: string | undefined) {
        if (slideId != undefined)
            await this.setAppointmentActiveSlideQuery(appointmentId).post(slideId);
        else
            await this.clearAppointmentActiveSlideQuery(appointmentId).post();

        events.appointmentActiveSlideChanged.trigger(appointmentId, slideId);
    }

    async setAppointmentSlideVideoState(appointmentId: string, slideId: string, data: JSONGroupRoomSlideVideoState): Promise<void> {
        const newState = await this.setAppointmentSlideVideoStateQuery(appointmentId, slideId).post(data);

        events.appointmentSlideStateChanged.trigger(appointmentId, slideId, newState);
    }

    async setAppointmentSlideDocumentState(appointmentId: string, slideId: string, data: JSONGroupRoomSlideDocumentState): Promise<void> {
        const newState = await this.setAppointmentSlideDocumentStateQuery(appointmentId, slideId).post(data);

        events.appointmentSlideStateChanged.trigger(appointmentId, slideId, newState);
    }

    async changeAppointmentSlideStateDocumentObjects(appointmentId: string, slideId: string, changes: JSONGroupRoomSlideStateDocumentObjectsChanges): Promise<void> {
        const newState = await this.changeAppointmentSlideStateDocumentObjectsQuery(appointmentId, slideId).post(changes);
        events.appointmentSlideStateChanged.trigger(appointmentId, slideId, newState);
    }

    async saveInterviewerSettings(discussionId: string, personId: string, settings: JSONAppointmentInterviewerSettings) {
        await this.setAppointmentInterviewerSettingsQuery(discussionId, personId).update(settings);
        events.interviewerSettingsChanged.trigger(discussionId, personId);
    }

    async onRecordingStarted(appointmentId: string): Promise<void> {
        await this.addRecordingStartedEventQuery(appointmentId).post();
    }

    async onRecordingEnded(appointmentId: string): Promise<void> {
        await this.addRecordingEndedEventQuery(appointmentId).post();
        events.appointmentRecordingEnded.trigger(appointmentId);
    }
}
import storageIgnoreArg from 'decorators/storageIgnoreArg';
import { PureComputed } from 'knockout';
import events from 'managers/events';
import { JSONTag, Tag } from 'models/tag';
import TagsManagerBase from './base';

@storageIgnoreArg(2)
export default class ThreadTagsManager extends TagsManagerBase {
    discussionId: PureComputed<string>
    threadId: PureComputed<string>

    constructor(discussionId: string, threadId: string, isEmpty: boolean) {
        super(isEmpty);

        this.discussionId = ko.pureComputed(() => discussionId);
        this.threadId = ko.pureComputed(() => threadId);

        this.ctx.commentsService.subscriptions.discussionThreads.subscribe(this.discussionId());

        this.ctx.commentsService.events.tagAddedToThread.on(this.onTagsChange.bind(this));
        this.ctx.commentsService.events.tagRemovedFromThread.on(this.onTagsChange.bind(this));

        this.effects.register([
            events.threadTagAdded.on(this.onLocalTagAdded.bind(this)),
            events.threadTagRemoved.on(this.onLocalTagRemoved.bind(this))
        ]);
    }

    addTag(jsonTag: JSONTag): Promise<void> {
        this.tasksCount.inc();

        var task = this.ctx.tagsService.saveThreadTag(this.discussionId(), this.threadId(), jsonTag)
            .fail(system.handleError)
            .always(() => this.tasksCount.dec());

        return Promise.resolve(task);
    }

    async removeTag(tag: Tag) {
        try {
            const tagId = tag.contentTagId();

            if (tagId == undefined)
                throw new Error(messages.InvalidRequest);

            this.tasksCount.inc();

            await this.ctx.tagsService.removeThreadTag(this.discussionId(), this.threadId(), tagId);
        } catch (ex) {
            system.handleError(ex);
        } finally {
            this.tasksCount.dec()
        }
    }

    protected async load() {
        return await this.ctx.tagsService.queries.threadTags(this.threadId()).background().toArray();
    }

    protected override isRemoveAllowed() {
        return true;
    }

    protected override isEditAllowed() {
        return true;
    }

    private onTagsChange(threadId: string, tagId: string) {
        if (this.threadId() === threadId)
            this.update();
    }

    private onLocalTagAdded(threadId: string, tagId: string, jsonTag: JSONTag) {
        if (this.threadId() === threadId)
            this.source.add(jsonTag);
    }

    private onLocalTagRemoved(threadId: string, tagId: string) {
        if (this.threadId() === threadId)
            this.source.remove(tag => tag.contentTagId() === tagId);
    }
}
import { WordInfo } from 'interfaces/wordInfo';
import imagePattern from 'patterns/image';
import vimeoPattern from 'patterns/vimeo';
import YoutubePattern from 'patterns/youtube';

let span = document.createElement('span');

export module TextHelpers {
    interface MediaCheckOptions {
        images?: boolean
        video?: boolean
    }

    export function getWords(str: string) {
        var _str = _(str);

        if (_str.isString() && !_str.isEmpty())
            return _(str.split(/\s+/)).map(word => $.trim(word));

        return [];
    }

    export function toTitleCase(str: string) {
        return str.replace(/.+\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    export function getBlobFromText(str: string) {
        return new Blob([str], {
            type: 'text/plain'
        });
    }

    export function decodeText(str: string) {
        return $(span).html(str).text();
    }

    export function checkIfHasAllowedMedia(text: string, { images = false, video = false }: MediaCheckOptions) {
        return images && checkIfHasImagesInTheText(text) ||
            video && checkIfHasVideosInTheText(text);
    }

    export function checkIfHasImagesInTheText(text: string) {
        return imagePattern.test(text);
    }

    export function checkIfHasVideosInTheText(text: string) {
        return _([vimeoPattern, YoutubePattern]).any(pattern => pattern.test(text));
    }

    export function convertBracketsIntoSpan(str: string, attrs = '') {
        if (!_(str).any()) return str;

        return str.replace(/\[(\w+)]/, (match, text) => `<span ${attrs}>${text}</span>`);
    }

    export function replaceContentWithHashes(str: string, config: any) {
        config = _(config).defaults({
            base64: false,
            components: false,
            widgets: false
        });

        var mappings: StringMap<string> = {};

        function replace(match: string, left: string, content: string, right: string) {
            var hash = '{' + system.generateHashString(20) + '}';

            mappings[hash] = content;

            return left + hash + right;
        }

        function replaceWidgetsOnly(match: string, left: string, content: string, right: string) {
            if (match.indexOf('wcc-widget') !== -1)
                return replace(match, left, content, right);

            return match;
        }

        var result = str;

        if (config.base64)
            result = result.replace(/(src=")(data:[\w\/]+;base64[^"]+)(")/g, replace)
                .replace(/(url\(&quot;)(data:[\w\/]+;base64[^&]+)(&quot;\))/g, replace)
                .replace(/(url\(")(data:[\w\/]+;base64[^"]+)("\))/g, replace);

        if (config.components)
            result = result.replace(/(<[\w-]+[^>]+)(params="[^"]+")([^>]*>)/g, replace);

        if (config.widgets)
            result = result.replace(/(<[\w-]+[^>]+)(params="[^"]+")([^>]*>)/g, replaceWidgetsOnly);

        return { result: result, mappings: mappings };
    }

    export function cutTextInTheMiddle(content: string, length: number) {
        var splitter = '...',
            minLength = splitter.length + 2;

        if (length < minLength)
            return splitter;

        if (content.length > length) {
            var partLength = Math.floor((length - splitter.length) / 2),
                middleIdx = Math.floor(content.length / 2);

            var left = content.slice(0, middleIdx),
                right = content.slice(middleIdx);

            return left.slice(0, partLength) + splitter + right.slice(-partLength);
        }

        return content;
    }

    export function shortcut(content: string, length: number, hard = false, fullwords = false) {
        if (content.length <= length) {
            return content.trim();
        } else {
            const words = content.split(' ');

            const result = words.slice(0, length).reduce((result, word) =>
                result.length >= length ? result : result + " " + (fullwords ? word : word.substr(0, hard ? length - result.length : length).trim()), "");

            return result.length < content.length ? result.trim() + "..." : result.trim();
        }
    }

    export function selectLabelFromDoubleLabel(str: string, count: number) {
        const labels = str.substr(1, str.length - 2).split('|');

        return count == 1 ? labels[0] : labels[1];
    }
}
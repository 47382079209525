import { AttachmentsHelpers } from 'helpers/attachments';
import WCCAttachment, { JSONWCCAttachment } from 'models/attachments/attachment';
import WCCAudio from 'models/attachments/audio';
import WCCFile from 'models/attachments/file';
import WCCImage from 'models/attachments/image';
import WCCLink from 'models/attachments/link';
import WCCVideo from 'models/attachments/video';
import WCCVimeo from 'models/attachments/vimeo';
import WCCYouTube from 'models/attachments/youtube';
import {imageUserFileTypes, videoUserFileTypes} from "../models/userFile";

export class AttachmentsFactory {
    constructor() { }

    createAttachment<T extends JSONWCCAttachment>(jsonAttachment: T): WCCAttachment {
        switch (jsonAttachment.FileType) {
            case enums.AttachmentTypes.Link.value:
                return new WCCLink(jsonAttachment);

            default:
                if (AttachmentsHelpers.isImage(jsonAttachment.FileType) || AttachmentsHelpers.isImageIsh(jsonAttachment.FileType)) {
                    return new WCCImage(jsonAttachment);
                } else if (AttachmentsHelpers.isVideo(jsonAttachment.FileType)) {
                    return new WCCVideo(jsonAttachment);
                } else if (AttachmentsHelpers.isAudio(jsonAttachment.FileType)) {
                    return new WCCAudio(jsonAttachment);
                } else if (AttachmentsHelpers.isFile(jsonAttachment.FileType)) {
                    return new WCCFile(jsonAttachment);
                } else if (AttachmentsHelpers.isVimeo(jsonAttachment.FileType)) {
                    return new WCCVimeo(jsonAttachment);
                } else if (AttachmentsHelpers.isYouTube(jsonAttachment.FileType)) {
                    return new WCCYouTube(jsonAttachment);
                } else if (AttachmentsHelpers.isProjectToDoAttachment(jsonAttachment.FileType)) {
                    if(_.find(jsonAttachment.Files ?? [], f => videoUserFileTypes.includes(f.Type))) {
                        return new WCCVideo(jsonAttachment);
                    }
                    if(_.find(jsonAttachment.Files ?? [], f => f.Type == enums.UserFileTypes.Audio.value)) {
                        return new WCCAudio(jsonAttachment);
                    }
                    else if(_.find(jsonAttachment.Files ?? [], f => imageUserFileTypes.includes(f.Type))) {
                        return new WCCImage(jsonAttachment);
                    }
                    else {
                        return new WCCFile(jsonAttachment);
                    }
                } else {
                    return new WCCAttachment<JSONWCCAttachment>(jsonAttachment);
                }
        }
    }
}

const attachmentsFactory = new AttachmentsFactory;

export default attachmentsFactory;
import FineUploaderFactory from 'factories/fineUploader/s3/fineUploader';
import { FileFineUploaderOptions, ImageFineUploaderOptions, VideoFineUploaderOptions } from 'factories/fineUploader/s3/iFineUploader';
import { FineUploader } from 'fine-uploader';
import * as browserHelpers from 'helpers/browser';

export module FineUploadersHelpers {
    const fineUploaderFactory = new FineUploaderFactory();

    export function initFineUploader(uploader: string | HTMLElement, options: StringMap<any>) {
        var element = typeof (uploader) == "string" ? $(uploader)[0] : uploader,
            headers = {
                'x-auth': settings.currentUserToken,
            };

        if (options.additionalHeaders) {
            headers = $.extend(headers, options.additionalHeaders);
        }

        return new FineUploader({
            element: element,
            autoUpload: options.canAutoUpload == true,
            multiple: options.canUploadMultipleFiles,
            cors: {
                allowXdr: true,
                expected: true, //all requests are expected to be cross-domain requests
                sendCredentials: true //if you want cookies to be sent along with the request
            },
            request: {
                endpoint: options.endPoint + (browserHelpers.isIE ? '?qqfile=wcc_explorer_dummy.jpg' : ''),
                customHeaders: headers
            },
            validation: {
                allowedExtensions: options.allowedFileExtensions || [],
                sizeLimit: options.sizeLimit ? options.sizeLimit : null
            },
            template: options.template,
            //iframeSupport: {
            //    localBlankPagePath: "ieiframedummy.html"
            //},
            text: <any>{
                uploadButton: options.buttonText
            },
            callbacks: {
                onSubmit: function (id, fileName) {
                    if (options.submitHandler) {
                        options.submitHandler.call(this, id, fileName);
                    }
                },
                onComplete: function (id, fileName, responseJSON, xhr) {
                    if (options.successHandler) {
                        options.successHandler.call(this, id, fileName, responseJSON);
                    }
                },
                onError: function (id, fileName, responseJSON, xhr) {
                    if (options.errorHandler) {
                        options.errorHandler.call(this, id, fileName, responseJSON, xhr);
                    }
                },
                onAllComplete: function () {
                    if (options.completeHandler) {
                        options.completeHandler.call(this);
                    }
                },
                onValidate: function (data, e) {
                    if (options.validationHandler) {
                        return options.validationHandler.call(this, data, e);
                    }
                    return true;
                },
                onUpload: function (fileId, fileName) {
                    if (options.uploadHandler) {
                        options.uploadHandler.call(this, fileId, (fileName || '').toLowerCase(), uploader);
                    }
                },
                onProgress: function (fileId, fileName, uploadedBytes, totalBytes) {
                    if (options.progressHandler) {
                        options.progressHandler.call(this, fileId, fileName, uploadedBytes, totalBytes);
                    }
                },
                onCancel: function (id, fileName) {
                    if (options.cancelHandler) {
                        options.cancelHandler.call(this, id, fileName, options.canUploadMultipleFiles ? (<any>uploader).S3[id].fileId : (<any>uploader).fileId);
                    }
                }
            }
        });
    }

    export function initS3ImageUploader(uploader: string | HTMLElement | undefined, options: Exclude<ImageFineUploaderOptions, 'element'>) {
        return fineUploaderFactory.createS3ImageUploader({ ...options, element: uploader });
    }

    export function initS3VideoUploader(uploader: string | HTMLElement | undefined, options: Exclude<VideoFineUploaderOptions, 'element'>) {
        return fineUploaderFactory.createS3VideoUploader({ ...options, element: uploader });
    }

    export function initS3FileUploader(uploader: string | HTMLElement | undefined, options: Exclude<FileFineUploaderOptions, 'element'>) {
        return fineUploaderFactory.createS3FileUploader({ ...options, element: uploader });
    }
}
import { WordInfo } from "interfaces/wordInfo";
import { injectable } from "inversify";
import { IWordsMapFactory } from "./wordsMapFactory.interfaces";

interface Block {
    start: string
    end: string
}

const blocks = new Array<Block>(
    { start: '[', end: ']' }
);

@injectable()
export default class WordsMapFactory implements IWordsMapFactory {
    buildMap(text: string) {
        const letters = text.split('');

        let continueMode = false;
        let continueUntil = new Array<string>();

        return letters.reduce((words, letter, letterIdx, letters) => {
            if (letter.match(/\S/) || continueUntil.length > 0) {
                let word: WordInfo;

                if (continueMode) {
                    const prevWord = <WordInfo>words.pop();
                    word = { text: prevWord.text + letter, start: prevWord.start, end: letterIdx }
                } else {
                    word = { text: letter, start: letterIdx, end: letterIdx }
                }

                words.push(word);

                continueMode = true;
            } else {
                continueMode = false;
            }

            const block = blocks.find(item => letter === item.start);

            if (block != undefined)
                continueUntil.push(block.end);

            if (continueUntil.length > 0 && letter == _.last(continueUntil))
                continueUntil.pop();

            return words;
        }, new Array<WordInfo>());
    }
}
import { DOMHelpers } from 'helpers/dom';
import { Subscribable } from 'knockout';
import { withEffects } from 'mixins/withEffects';
import { Disposable } from '../interfaces/disposable';

const { mobileMaxSize, tabletMaxSize } = settings;

interface ResizeSubscription {
    dispose: () => void,
    register: (group: Array<Disposable>) => void
}

export class DeviceManager {
    private effects = withEffects();

    private _width = ko.observable(window.innerWidth);
    private _height = ko.observable(window.innerHeight);

    width: Subscribable<number>
    height: Subscribable<number>

    isMobile: Subscribable<boolean>
    isTablet: Subscribable<boolean>
    isDesktop: Subscribable<boolean>

    constructor() {
        this.width = this._width;
        this.height = this._height;

        this.isMobile = ko.pureComputed(() => this.width() <= mobileMaxSize);
        this.isTablet = ko.pureComputed(() => this.width() > mobileMaxSize && this.width() <= tabletMaxSize);
        this.isDesktop = ko.pureComputed(() => this.width() > tabletMaxSize);

        const update = system.toUICallback(() => this.update());

        this.effects.register([
            DOMHelpers.onNodeEvent(window, 'resize', update),
            DOMHelpers.onNodeEvent(window, 'orientationchange', update)
        ]);

        this.update();
    }

    onResize(action: (width: number, height: number) => void): ResizeSubscription {
        const effects = withEffects();
        const subscription = { dispose: () => effects.dispose() };

        function register(group: Array<Disposable>) {
            var isValid = _(group).isArray() && !_(group).contains(subscription);

            if (isValid)
                group.push(subscription);
        }

        effects.register(action, [this.width, this.height]);

        return {
            dispose: effects.dispose,
            register: register
        }
    }

    private update() {
        this._width(window.innerWidth);
        this._height(window.innerHeight);
    }
}

const device = new DeviceManager;

export default device;
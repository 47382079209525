import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import { JSONSurveyPage } from 'models/survey/page';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import { JSONSurveyRule } from '../components/surveyRules/models/surveyRule';
import {JSONSurvey} from "../models/survey/survey";

const { sitePostfix } = settings;

export class SurveysService extends BaseService {
    private repository = new Repository(endpoint);

    adminQueries = {
        surveys: () => this.repository.query(`admin/communities/surveys`),
        topicSurveyPages: (topicId: string) => this.repository.query<never, JSONSurveyPage, never, never, never, never, never>(`admin/surveys/${topicId}`),
        surveyRules: (surveyId: string) => this.repository.query<never, JSONSurveyRule, never, never, never, never, never>(`admin/surveys/rules/${surveyId}`),
        surveyRuleAdd: (surveyId: string) => this.repository.postQuery<JSONSurveyRule, boolean>(`admin/surveys/${surveyId}/rule/`),
        surveyRuleUpdate: (surveyId: string, ruleId: string) => this.repository.query<never, never, never, never, JSONSurveyRule, never, never>(`admin/surveys/${surveyId}/rule/${ruleId}`),
        surveyRuleDelete: (surveyId: string, ruleId: string) => this.repository.removeQuery<boolean>(`admin/surveys/${surveyId}/rule/${ruleId}`),
        surveyRulesPost: (surveyId: string) => this.repository.query(`admin/surveys/rules/${surveyId}`)
    }

    queries = {
        topicSurveyPages: (topicId: string) => this.repository.query<never, JSONSurveyPage, never, never, never, never, never>(`surveys/${topicId}`).addArgs(sitePostfix),
        mySurveyAnswers: (topicId: string) => this.repository.fastQuery(`surveys/${topicId}/answers/mine`),
        surveyDetails: (topicId: string) => this.repository.fastQuery(`ExternalSurveys/${topicId}/Details`),
        publicSurvey: (topicId: string) => this.repository.query<never, JSONSurvey, never, never, never, never, never>(`community/surveys/${topicId}`).addArgs(sitePostfix),
        publicScreener: (topicId: string) => this.repository.query<never, JSONSurvey, never, never, never, never, never>(`screeners/${topicId}`).addArgs(sitePostfix)
    }

    subscriptions = {
        surveyResponses: this.signalRSubscription('SurveyResponses')
    }

    events = {
        surveyResponsesChanged: this.signalREvent('SurveyResponsesChanged')
    }

    /**
     * Returns current user survey answers
     */
    getMySurveyAnswers(topicId: string, oData: URLQueryData = {}) {
        return this.queries.mySurveyAnswers(topicId).addArgs(oData).firstOrDefault();
    }

    /**
     * Returns all surveys for current community
     */
    getSurveysForAdmin() {
        return this.adminQueries.surveys().toArray();
    }

    /**
     * Returns all survey rules for survey id
     * @param surveyId
     */
    async getSurveyRulesForSurvey(surveyId: string) {
        return await this.adminQueries.surveyRules(surveyId).toArray();
    }

    /**
     * Add or Post new/existing rule for survey id
     * @param surveyId
     * @param surveyRule
     */
    async saveSurveyRuleForSurvey(surveyId: string, surveyRule: JSONSurveyRule) {
        if (surveyRule.SurveyRuleId != undefined && surveyRule.SurveyRuleId.length > 0) {
            return await this.adminQueries.surveyRuleUpdate(surveyId, surveyRule.SurveyRuleId).update(surveyRule);
        }
        else {
            return await this.adminQueries.surveyRuleAdd(surveyId).firstOrDefaultPost(surveyRule);
        }
    }

    /**
     * delete a survey rule based on id
     * @param surveyId
     * @param surveyRule
     */
    async deleteSurveyRuleForSurveyId(surveyId: string, surveyRuleId: string) {
        return await this.adminQueries.surveyRuleDelete(surveyId, surveyRuleId).remove();
    }

    async saveSurveyRulesForSurvey(surveyId: string, surveyRules: JSONSurveyRule[]) {
        return await this.adminQueries.surveyRulesPost(surveyId).toArrayPost({ surveyRules: surveyRules });
    }

    /**
     * Gets the details for an External Survey we need to show in the Discusion or Community
     * @param topicId The Discussion Topic Id that is the Survey Activity
     */
    getExternalSurveyDetails(topicId: string) {
        return this.queries.surveyDetails(topicId).firstOrDefault();
    }

    /**
     * Gets public survey
     * @param topicId
     * @param params
     */
    async publicSurvey(topicId: string, params: URLQueryData = '') {
        return await this.queries.publicSurvey(topicId).addArgs(params).firstOrDefault()
    }
}
import { BaseService } from "./base";
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { SmsSenderSettings } from "../components/sms/senderId/smssenderid";

export class AccountService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        getSmsSenderIds: (id: string) => this.repository.query(`accounts/${id}/smssenderids`),
        addSmsSenderId: () => this.repository.query(`accounts/smssenderid`),
        updateSmsSenderId: (id: string) => this.repository.query(`accounts/smssenderid/${id}`),
        deleteSmsSenderId: (accountId:string, id: string) => this.repository.query(`accounts/${accountId}/smssenderid/${id}`)
    }

    async getSmsSenderIdsForAccount(id: string): Promise<SmsSenderSettings[]> {
        return this.queries.getSmsSenderIds(id).toArray();
    }

    async addSmsSenderId(requestData: SmsSenderSettings): Promise<SmsSenderSettings> {
        return this.queries.addSmsSenderId().add(requestData);
    }   

    async updateSmsSenderId(requestData: SmsSenderSettings): Promise<SmsSenderSettings> {
        return this.queries.updateSmsSenderId(requestData.SmsSenderSettingsId).update(requestData);
    }

    async deleteSmsSenderId(accountId: string, id: string): Promise<boolean> {
        return this.queries.deleteSmsSenderId(accountId, id).remove();
    }
}
import { dailyRoomOverlaysManagerKey, IDailyRoomOverlaysManager } from "components/daily/room/managers/overlays.interfaces";
import { wccModules } from "enums/wccModules";
import { Disposer } from "interfaces/disposer";
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import AppointmentAccessManager from "managers/appointments/access";
import AppointmentManager from "managers/appointments/appointment";
import { IWCCStorageManager } from "managers/iStorage";
import { IAppointmentsService } from "services/appointments/appointments.interfaces";
import { ISlidesPublisherHandler } from "../slidesPublisher.interfaces";
import { ISlidesPublisherAppointmentHandlersFactory } from "./appointment.interfaces";

@injectable()
export default class SlidesPublisherAppointmentHandlersFactory implements ISlidesPublisherAppointmentHandlersFactory {
    constructor(
        @inject(wccModules.appointmentsService) private appointmentsService: IAppointmentsService,
        @inject(dailyRoomOverlaysManagerKey) private overlaysManager: IDailyRoomOverlaysManager,
        @inject(wccModules.storage) private storage: IWCCStorageManager
    ) { }

    create(appointmentId: string): ISlidesPublisherHandler {
        return new SlidesPublisherAppointmentHandler(appointmentId, this.appointmentsService, this.overlaysManager, this.storage);
    }
}

export class SlidesPublisherAppointmentHandler implements ISlidesPublisherHandler {
    private appointmentSlideId: Subscribable<string | undefined>

    isReadOnly: Subscribable<boolean>

    constructor(
        private appointmentId: string,
        private appointmentsService: IAppointmentsService,
        private overlaysManager: IDailyRoomOverlaysManager,
        storage: IWCCStorageManager
    ) {
        const appointmentManager = storage.get(AppointmentManager, { appointmentId });
        const appointment = appointmentManager.pluck(m => m.appointment);
        this.appointmentSlideId = appointment.pluck(a => a.activeSlideId);

        const accessManager = storage.get(AppointmentAccessManager, { appointmentId });
        const isInterviewer = accessManager.pluck(m => m.isInterviewer, false);

        this.isReadOnly = ko.pureComputed(() => !isInterviewer());
    }

    getPublishedSlideId(): Subscribable<string | undefined> {
        return this.appointmentSlideId;
    }

    async publishSlide(slideId: string): Promise<void> {
        await this.appointmentsService.setAppointmentActiveSlide(this.appointmentId, slideId);
    }

    async clearPublishedSlide(): Promise<void> {
        await this.appointmentsService.setAppointmentActiveSlide(this.appointmentId, undefined);
    }

    async expandSlide(slideId: string): Promise<Disposer> {
        return await this.overlaysManager.showSlideOverlay({ appointmentId: this.appointmentId, slideId: slideId });
    }
}
import FineUploaderFactory from 'factories/fineUploader/s3/fineUploader';
import { S3FineUploaderNewFileData, FineUploaderOptions } from 'factories/fineUploader/s3/iFineUploader';
import { FilesHelpers } from 'helpers/files';
import { BlobWrapper } from 'interfaces/blobWrapper';
import { Func } from 'interfaces/func';
import type qq from 'libraries/fineUploader';
import WCCError from 'models/wccError';
import { WCCUploaderTransport } from '../transport';
import { WCCUploadRequestOptions } from '../uploadRequestOptions';
import { WCCS3UploadResult } from './uploadResult';

const fineUploaderFactory = new FineUploaderFactory();

interface WCCS3UploadTask {
    id?: number
    fileName: string
    resolve: (data: WCCS3UploadResult) => void
    reject: (ex?: any) => void,
    onProgress: (progress: number) => void
    cancelPromise: Promise<void>
}

export interface WCCUploaderDirectS3TransportConfig {
    type: WCCUploaderDirectS3TransportTypes
    fineUploaderConfig?: FineUploaderOptions
    fileSizeLimit?: number
}

export type WCCUploaderDirectS3TransportTypes = 'image' | 'video' | 'file'

export default class WCCUploaderDirectS3Transport implements WCCUploaderTransport<WCCS3UploadResult> {
    private tasks = ko.observableArray<WCCS3UploadTask>();
    private uploader: qq.s3.FineUploaderBasic

    constructor(public config: WCCUploaderDirectS3TransportConfig) {
        this.uploader = this.getUploader();
    }

    upload(fileOrBlob: File | BlobWrapper, { cancelPromise, progressCallback }: WCCUploadRequestOptions) {
        return new Promise<WCCS3UploadResult>((resolve, reject) => {
            try {
                const fileSizeLimit = this.config.fileSizeLimit ?? 0;

                if (!FilesHelpers.checkIfMatchesSizeLimit(fileOrBlob, fileSizeLimit))
                    throw new WCCError(messages.FileIsTooBig.replace('{0}', fileSizeLimit.toString()));

                this.tasks.push({
                    fileName: fileOrBlob.name,
                    resolve,
                    reject,
                    onProgress: progressCallback,
                    cancelPromise
                });

                this.uploader.addFiles('blob' in fileOrBlob ? fileOrBlob : [fileOrBlob]);
            } catch (e) {
                reject(e);
            }
        });
    }

    private getUploader(): qq.s3.FineUploaderBasic {
        const factory = this.getUploaderFactory(this.config.type);

        const uploader = factory(<FineUploaderOptions>{
            ...this.config.fineUploaderConfig,

            coreMode: true,
            canAutoUpload: true,

            submitHandler: (id: number, fileName: string) => {
                try {
                    const task = this.tasks().find(t => t.id == undefined);

                    if (task != undefined) {
                        task.id = id;

                        task.cancelPromise.then(() => {
                            if (task != undefined && this.tasks().includes(task))
                                uploader.cancel(<number>task.id);
                        }, _.noop);
                    } else {
                        throw new Error('new collision. no new uploads to assing id to');
                    }
                } catch (ex) {
                    system.handleError(ex);
                    throw ex;
                }
            },

            progressHandler: (id: number, fileName: string, loaded: number, total: number) => {
                const task = this.tasks().find(t => t.id === id);

                if (task != undefined && total > 0)
                    task.onProgress(Math.floor(loaded / total * 100));
            },

            successHandler: (id: number, fileName: string, responseJSON: StringMap<any>, fileId: string, S3Key: string, uploader: qq.s3.FineUploaderBasic, data: S3FineUploaderNewFileData) => {
                const task = this.tasks().find(t => t.id === id);

                if (task != undefined) {
                    this.tasks.remove(task);

                    task.resolve({
                        fileId: fileId,
                        key: S3Key,
                        link: data.link
                    });
                }
            },

            errorHandler: (id: number, fileName: string, error: string) => {
                const task = this.tasks().find(t => t.id === id) ?? this.tasks().find(t => t.fileName === fileName);

                if (task != undefined) {
                    this.tasks.remove(task);
                    task.reject(new WCCError(error));
                }
            },            

            cancelHandler: (id: number) => {
                var task = this.tasks().find(u => u.id === id);

                if (task != undefined) {
                    this.tasks.remove(task);
                    task.reject({ isCanceled: true });
                }
            }
        });

        return uploader;
    }

    private getUploaderFactory(type: WCCUploaderDirectS3TransportTypes): Func<qq.s3.FineUploaderBasic, [FineUploaderOptions]> {
        switch (type) {
            case 'image': return options => fineUploaderFactory.createS3ImageUploader(options);
            case 'video': return options => fineUploaderFactory.createS3VideoUploader(options);
            case 'file': return options => fineUploaderFactory.createS3FileUploader(options);
        }
    }
}
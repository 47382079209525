import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyStaticVideoOptions {
    VideoType: number
    VideoCode: string
}

export default class SurveyStaticVideoOptions extends WCCEntity<JSONSurveyStaticVideoOptions> {
    videoType: Observable<number>
    videoCode: Observable<string>

    constructor(data: JSONSurveyStaticVideoOptions) {
        super();

        this.videoType = this.createField(data, 'VideoType');
        this.videoCode = this.createField(data, 'VideoCode');
    }
}
define('managers/poll',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');

        const { discussionId } = settings;

        const Poll = require('models/simpleTopic').SimpleTopic;

        function Manager(pollId) {
            Base.call(this);

            this.pollId = ko.pureComputed(function () { return pollId });

            this._source = this._register(new DataSource({
                update: this._load.bind(this)
            }));

            this.poll = this._source.data.toEntity(
                function (jsonPoll) { return new Poll(jsonPoll) },
                function (poll, jsonPoll) { poll.update(jsonPoll) });

            this.busy = this._source.busy;
            this.loading = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() === 0 }, this);
            this.updating = ko.pureComputed(function () { return this.busy() && this._source.requestsProcessed() > 0 }, this);
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.init = function () {
            Base.prototype.init.call(this);

            this._ctx.discussionService.subscriptions.discussion.subscribe(discussionId);
            this._ctx.commentsService.subscriptions.topicThreads.subscribe(this.pollId());

            this._ctx.commentsService.events.newComment.on(this._onNewResponse.bind(this));
            this._ctx.commentsService.events.threadChanged.on(this._onResponseChanged.bind(this));
            this._ctx.commentsService.events.threadDeleted.on(this._onResponseDeleted.bind(this));
            this._ctx.topicsService.events.topicDeleted.on(this._onPollDeleted.bind(this));
        };

        Manager.prototype._load = function () {
            return this._ctx
                .pollsService
                .queries
                .poll(this.pollId())
                .background()
                .firstOrDefault();
        };

        Manager.prototype._onNewResponse = function (threadId, topicId) {
            if (this.pollId() === topicId)
                this._source.update();
        };

        Manager.prototype._onResponseChanged = function (threadId, topicId) {
            if (this.pollId() === topicId)
                this._source.update();
        };

        Manager.prototype._onResponseDeleted = function (threadId, parentThreadId, personId, topicId) {
            if (this.pollId() === topicId)
                this._source.update();
        };

        Manager.prototype._onPollDeleted = function (targetDiscussionId, pollId) {
            if (this.pollId() === pollId)
                this._source.data(null);
        };

        return Manager;
    });
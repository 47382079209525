import { Observable } from 'knockout';
import WCCEntity from './entity';
import tagsParser from 'managers/tagsParser';

export interface JSONScreenRecordingOptions {
    BrowserRecordingInstructions?: string
    MobileRecordingInstructions?: string
    NonCompatibleDevicesInstructions?: string
}

export default class ScreenRecordingOptions extends WCCEntity<JSONScreenRecordingOptions> {
    browserRecordingInstructions: Observable<string | undefined>
    mobileRecordingInstructions: Observable<string | undefined>
    nonCompatibleDevicesInstructions: Observable<string | undefined>

    constructor(data: JSONScreenRecordingOptions) {
        super();

        this.browserRecordingInstructions = this.createField(data, 'BrowserRecordingInstructions').mapNotNull(str => tagsParser.processTags(str));
        this.mobileRecordingInstructions = this.createField(data, 'MobileRecordingInstructions', '').mapNotNull(str => tagsParser.processTags(str));
        this.nonCompatibleDevicesInstructions = this.createField(data, 'NonCompatibleDevicesInstructions', '').mapNotNull(str => tagsParser.processTags(str));
    }
}
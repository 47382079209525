import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class RewardsService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            rewards: (discussionId) => this._repository.query(`discussions/${discussionId}/rewards`),
            rewardsSettings: (discussionId) => this._repository.query(`discussions/${discussionId}/rewards/settings`),
            loadRewardsForSelected: (discussionId) => this._repository.query(`discussions/${discussionId}/selectedRewards`),
            personRewards: (discussionId, personId) => this._repository.query(`discussions/${discussionId}/people/${personId}/rewards`),
            currentUserRewards: (discussionId) => this._repository.query(`discussions/${discussionId}/rewards/mine`),
            currentUserRedemptionData: (discussionId) => this._repository.query(`discussions/${discussionId}/redemptionData/mine`),
            redemptionData: (discussionId, personId) => this._repository.query(`discussions/${discussionId}/people/${personId}/redemptionData`),
            myRewardRedemptions: (discussionId) => this._repository.query(`discussions/${discussionId}/rewards/redemptions`),
            rewardRedemptions: (discussionId) => this._repository.query(`admin/discussions/${discussionId}/rewards/redemptions`),
            approveRedemption: (discussionId) => this._repository.query(`discussions/${discussionId}/rewards/redemptions/approve`),
            markRequestAsRead: (discussionId, requestId) => this._repository.query(`discussions/${discussionId}/rewards/redemptions/${requestId}/read`),
            markAllRequestAsRead: (discussionId) => this._repository.query(`discussions/${discussionId}/rewards/redemptions/mine/read`)
        };

        this.subscriptions = {
            discussionRewardEvents: this.signalRSubscription('DiscussionRewardEvents')
        };

        this.events = {
            rewardChanged: this.signalREvent('RewardChanged')
        };
    }

    loadRewardsForSelected(discussionId, peopleIds) {
        return this.queries.loadRewardsForSelected(discussionId).toArrayPost({ peopleIds: peopleIds });
    }

    getPersonRewards(discussionId, personId) {
        return this.queries.personRewards(discussionId, personId).toArray();
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getCurrentUserRewards(discussionId) {
        return this.queries.currentUserRewards(discussionId).toArray();
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getCurrentUserRedemptionData(discussionId) {
        return this.queries.currentUserRedemptionData(discussionId).firstOrDefault();
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getRedemptionData(discussionId, personId) {
        return this.queries.redemptionData(discussionId, personId).firstOrDefault();
    }

    /**
     * Returns all people with prize draw tickets.
     */
    getRewardsSettings(discussionId) {
        return this.queries.rewardsSettings(discussionId).firstOrDefault();
    }

    /**
     * Submits a redemption request for current user
     */
    requestRedemption(discussionId, requestData) {
        return this.queries.myRewardRedemptions(discussionId).add(requestData);
    }

    /**
     * Approve redemption request
     */
    approveRedemption(discussionId, requestData) {
        return this.queries.approveRedemption(discussionId).add(requestData);
    }

    /**
     * Returns all redemption requests with selected statuses
     */
    getRedemptionRequests(discussionId, statuses, oData) {
        return this.queries.rewardRedemptions(discussionId).addArgs(oData).add(statuses);
    }

    /**
     * Mark redemption request as read
     */
    markRedemptionRequestAsRead(discussionId, requestId) {
        return this.queries.markRequestAsRead(discussionId, requestId).update();
    }

    /**
     * Mark all redemption requests of current user as read
     */
    markAllRedemptionRequestsAsRead(discussionId) {
        return this.queries.markAllRequestAsRead(discussionId).update();
    }
}
import { ServicesContext } from "../services/context";

export class DefaultViewModel {
    model: object;
    options: object;

    constructor(model: object, info: ko.components.ComponentInfo, ctx: ServicesContext, options: object) {
        this.model = model;
        this.options = options;
    }
}
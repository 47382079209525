import endpoint from 'endpoints/api';
import { injectable } from 'inversify';
import { ITimezone } from 'models/timezones/ITimezone';
import { JSONTimezone } from 'models/timezones/timezone';
import Repository from 'repositories/generic';
import { ITimezoneService } from './timezones.interfaces';

@injectable()
export default class TimezoneService implements ITimezoneService {
    private repository = new Repository(endpoint);

    private topLevelTimezonesQuery = () => this.repository.collectionGetQuery<ITimezone>('timezones');
    private secondLevelTimezonesQuery = (zoneCode: string) => this.repository.collectionGetQuery<ITimezone>(`timezones?zonecode=${zoneCode}`);

    chaosTimezoneQuery = (timezoneCode: string) => this.repository.entityGetQuery<JSONTimezone>(`chaostimezones/${timezoneCode}`);

    /**
      * Returns array of timezones
      */
    getTopLevelTimezones() {
        return this.topLevelTimezonesQuery().toArray() as JQueryPromise<ITimezone[]>;
    }

    /**
      * Returns array of timezones filtered by zonecode
      */
    getSecondLevelTimezones(zoneCode: string) {
        return this.secondLevelTimezonesQuery(zoneCode).toArray() as JQueryPromise<ITimezone[]>;
    }
}
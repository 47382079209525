import { Observable } from 'knockout';
import WCCEntity from './entity';

export interface JSONContentImage {
    Url: string
    FileName: string
    FileExtension: string
    ImageToken: string
    ImageHash: string
    HasThumbnail: boolean
    CreateDate: string
}

export default class ContentImage extends WCCEntity<JSONContentImage>{
    url: Observable<string>
    name: Observable<string>
    extension: Observable<string>
    token: Observable<string>
    hash: Observable<string>
    hasThumbnail: Observable<boolean>
    createDate: Observable<Date>

    constructor(data: JSONContentImage) {
        super();

        this.url = this.createField(data, 'Url');
        this.name = this.createField(data, 'FileName');
        this.extension = this.createField(data, 'FileExtension');
        this.token = this.createField(data, 'ImageToken');
        this.hash = this.createField(data, 'ImageHash');
        this.hasThumbnail = this.createField(data, 'HasThumbnail');
        this.createDate = this.createMappedField(data, 'CreateDate', WCCEntity.dateMapperNotNull);
    }
}
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';

export interface LCTopicStateManagerConfig {
    topicId: string
}

@injectable()
export default class LCTopicStateManager {
    selectedSlideId = ko.observable<string>();
    slideToPostId = ko.observable<string>();

    constructor(
        @inject(wccModules.managerConfig) config: LCTopicStateManagerConfig
    ) { }
}
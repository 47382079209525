import { injectable } from 'inversify';
import { PureComputed } from 'knockout';
import { EntityDataSource } from './datasources/entity';

@injectable()
export default abstract class EntityDataSourceOwner<T> {
    protected abstract source: EntityDataSource<T>

    busy: PureComputed<boolean>
    loading: PureComputed<boolean>
    updating: PureComputed<boolean>

    constructor() {
        this.busy = ko.pureComputed(() => this.source.busy());
        this.loading = ko.pureComputed(() => this.isLoadingData());
        this.updating = ko.pureComputed(() => this.isUpdatingData());
    }

    protected isLoadingData() {
        return this.busy() && this.source.requestsProcessed() === 0;
    }

    protected isUpdatingData() {
        return this.busy() && this.source.requestsProcessed() > 0;
    }
}
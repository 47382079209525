import endpoint from 'endpoints/api';
import { JSONPersonActivity } from 'models/people/activity';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class ActivitiesService extends BaseService {
    private repository = new Repository(endpoint);

    queries = {
        topicActivities: (topicId: string) => this.repository.query<never, JSONPersonActivity | undefined, never, never, never, never, never>(`topics/${topicId}/useractivities`)
    }
}
import { inject, injectable } from "inversify";
import { SignalRSubscriptionState } from "enums/signalR/subscriptionState";
import { wccModules } from "enums/wccModules";
import { Disposable } from "interfaces/disposable";
import { Func } from "interfaces/func";
import { withEffect } from "mixins/withEffect";
import { EffectsContainer } from "mixins/withEffects";
import { SignalRHelpers } from "./helpers";
import { ISignalRCoreConnectionManager } from "./interfaces/connection";
import { ISignalRCoreMessagesManager } from "./interfaces/messages";
import { ISignalRCoreSubscriptionsManager } from "./interfaces/subscriptions";
import SignalRSubscription from "./models/subscription";

@injectable()
export default class SignalRCoreSubscriptionsManager implements ISignalRCoreSubscriptionsManager {
    subscriptions = ko.observableArray<SignalRSubscription>().extend({ deferred: true });

    constructor(
        @inject(wccModules.signalRConnectionManager) connectionManager: ISignalRCoreConnectionManager,
        @inject(wccModules.signalRMessagesManager) messagesManager: ISignalRCoreMessagesManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const oNextSubscription = ko.observable<SignalRSubscription>();
        const processors = new Map<SignalRSubscriptionState, Func<Promise<void>, [SignalRSubscription]> | Action<[SignalRSubscription]>>();

        processors.set(SignalRSubscriptionState.justCreated, async subscription => {
            subscription.state(SignalRSubscriptionState.activating);

            try {
                await messagesManager.add({
                    name: 'SubscribeTo' + subscription.name,
                    args: subscription.args,
                    removeOnReconnect: true
                });

                subscription.state(SignalRSubscriptionState.activated);
            } catch {
                subscription.state(SignalRSubscriptionState.failedToActivate);
            }
        });

        processors.set(SignalRSubscriptionState.toDeactivate, async subscription => {
            subscription.state(SignalRSubscriptionState.deactivating);

            try {
                await messagesManager.add({
                    name: 'UnsubscribeFrom' + subscription.name,
                    args: subscription.args,
                    removeOnReconnect: true
                });

                subscription.state(SignalRSubscriptionState.deactivated);
            } catch (ex) {
                subscription.state(SignalRSubscriptionState.failedToDeactivate)
            }
        });

        processors.set(SignalRSubscriptionState.deactivated, subscription => {
            this.subscriptions.remove(subscription);
        });

        const statesToProcess = Array.from(processors.keys());

        effects.register(isConnected => {
            if (isConnected) {
                this.subscriptions.remove(subscription => subscription.refCount === 0);
                this.subscriptions().forEach(subscription => subscription.state(SignalRSubscriptionState.justCreated));

                return [
                    withEffect((nextSubscription, subscriptions) => {
                        if (nextSubscription == undefined && subscriptions.length > 0) {
                            const subscription = subscriptions.find(subscription => statesToProcess.includes(subscription.state()));

                            oNextSubscription(subscription);
                        }
                    }, [oNextSubscription, this.subscriptions]),

                    withEffect(async subscription => {
                        if (subscription != undefined) {
                            const processor = processors.get(subscription.state());

                            try {
                                if (processor != undefined)
                                    await Promise.resolve(processor(subscription))
                            } finally {
                                oNextSubscription(undefined);
                            }
                        }
                    }, [oNextSubscription])
                ];
            }
        }, [connectionManager.connected]);

        effects.register([
            ko.computed(() => SignalRHelpers.logInfo('subscriptions', this.subscriptions().map(s => ({ name: s.name, args: s.args, refCount: s.refCount, state: s.state() }))))
        ]);
    }

    subscribe(name: string, ...args: Array<any>) {
        return ko.ignoreDependencies(() => {
            const newSubscription = new SignalRSubscription({ name: name, args: args });

            let subscription = this.subscriptions().find(s => s.equals(newSubscription) && s.refCount > 0) ?? newSubscription;

            if (subscription === newSubscription) {
                this.subscriptions.push(subscription);
                SignalRHelpers.log('requested subscription', name, args);
            }

            subscription.refCount++;

            return <Disposable>{
                dispose: () => {
                    subscription.refCount--;

                    if (subscription.refCount === 0) {
                        subscription.state(SignalRSubscriptionState.toDeactivate);

                        SignalRHelpers.log('subscription released', name, args);                            
                    }
                }
            }
        });
    }
}
import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { PureComputed } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import { EffectsContainer } from 'mixins/withEffects';
import Discussion, { JSONDiscussion } from 'models/discussion';
import { DiscussionService } from 'services/discussion';
import SignalREventsManager from './signalR/events';

const { discussionId } = settings;

interface DiscussionManagerConfig {
    discussionId: string
}

@injectable()
@storageDefaults(<Partial<DiscussionManagerConfig>>{ discussionId })
export default class DiscussionManager {
    private discussionId: string
    private source: EntityDataSource<JSONDiscussion>

    discussion: PureComputed<Discussion | undefined>

    busy = ko.pureComputed(() => this.source.busy())
    loading = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() === 0);
    updating = ko.pureComputed(() => this.busy() && this.source.requestsProcessed() > 0);

    constructor(
        @inject(wccModules.managerConfig) { discussionId }: DiscussionManagerConfig,
        @inject(wccModules.discussionService) discussionService: DiscussionService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.discussionId = discussionId;

        this.source = effects.register(new EntityDataSource({
            update: () => discussionService.queries.discussion(discussionId).firstOrDefault()
        }));

        this.discussion = this.source.data.toEntity(
            jsonTask => new Discussion(jsonTask),
            (task, jsonTask) => task.update(jsonTask));

        signalREvents.discussion(discussionId).onDiscussionChanged(this.onDiscussionChanged.bind(this));
    }

    update() {
        this.source.update();
    }

    private onDiscussionChanged(discussionId: string) {
        if (this.discussionId == discussionId)
            this.update();
    }
}
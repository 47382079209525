import { Observable } from "knockout";
import WCCEntity from "./entity";

export interface JSONJWT {
    Token: string
    ExpiryDate: string
}

export default class JWT extends WCCEntity<JSONJWT> {
    token: Observable<string>
    expiryDate: Observable<Date>

    constructor(data: JSONJWT) {
        super();

        this.token = this.createField(data, 'Token');
        this.expiryDate = this.createMappedField(data, 'ExpiryDate', WCCEntity.dateMapperNotNull);
    }
}
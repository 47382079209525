import { wccModules } from "enums/wccModules";
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import IdleResetContainer from "managers/idleResetContainer";
import { EffectsContainer } from "mixins/withEffects";
import { Disposable } from "../../../interfaces/disposable";
import { withEffect } from "../../../mixins/withEffect";
import { JSONOnlineUserDetails } from "../../../models/signalR/onlineUserDetails";
import { JSONOnlineUserSlideDetails } from "../../../models/signalR/onlineUserSlideDetails";
import TasksQueueManager from "../../tasksQueue";
import { ISignalRCoreManager } from "../core/interfaces/main";
import SignalRMessagesManager from "../messages";
import { ISignalRCurrentUserSessionManager } from "./currentUser.interfaces";

@injectable()
export default class SignalRCurrentUserSessionManager implements ISignalRCurrentUserSessionManager {
    private queue = new TasksQueueManager();
    private typingAt = new IdleResetContainer<string | undefined>({ defaultValue: undefined, resetDelay: 500 });

    private topicIds = ko.observableArray<string>();
    private appointmentIds = ko.observableArray<string>();
    private slides = ko.observableArray<JSONOnlineUserSlideDetails>();

    topicId: Subscribable<string | undefined>
    appointmentId: Subscribable<string | undefined>
    slide: Subscribable<JSONOnlineUserSlideDetails | undefined>

    constructor(
        @inject(wccModules.signalRManager) signalRManager: ISignalRCoreManager,
        @inject(wccModules.signalRMessages) private signalRMessages: SignalRMessagesManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.topicId = this.topicIds.last();
        this.appointmentId = this.appointmentIds.last();
        this.slide = this.slides.last();

        const details = ko.pureComputed((): JSONOnlineUserDetails => {
            return { 
                TopicId: this.topicId(),
                AppointmentId: this.appointmentId(),
                Slide: this.slide()                
            }
        });

        effects.register((isConnected) => {
            if (isConnected) {
                return [
                    withEffect(async details => {
                        await this.queue.run(() => this.signalRMessages.userOnline.sendImportant(details));
                    }, [details]),

                    withEffect(async typingAt => {
                        if (typingAt != undefined) {
                            await this.queue.run(() => this.signalRMessages.userTypingAt.sendImportant(typingAt));

                            return () => {
                                this.queue.run(() => this.signalRMessages.userTypingAt.sendImportant(undefined));
                            }
                        }
                    }, [this.typingAt.value])
                ]
            }
        }, [signalRManager.connected]);        
    }

    markAsTypingAt(topicId?: string) {
        this.typingAt.setValue(topicId);
    }

    assignToTopic(topicId: string): Disposable {
        this.topicIds.push(topicId);

        return {
            dispose: () => this.topicIds.remove(topicId)
        }
    }

    assignToAppointment(appointmentId: string) {
        this.appointmentIds.push(appointmentId);

        return {
            dispose: () => this.appointmentIds.remove(appointmentId)
        }
    }

    assignToSlide(slide: JSONOnlineUserSlideDetails) {
        this.slides.push(slide);

        return {
            dispose: () => this.slides.remove(slide)
        }
    }
}
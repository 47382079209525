import { Observable } from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyStaticImagesOptions {
    MinImages: number
    MaxImages: number
    CaptionType: number
    PickerType: number
}

export default class SurveyStaticImagesOptions extends WCCEntity<JSONSurveyStaticImagesOptions> {
    minImages: Observable<number>
    maxImages: Observable<number>
    captionType: Observable<number>
    pickerType: Observable<number>

    constructor(data: JSONSurveyStaticImagesOptions) {
        super();

        this.minImages = this.createField(data, 'MinImages');
        this.maxImages = this.createField(data, 'MaxImages');
        this.captionType = this.createField(data, 'CaptionType');
        this.pickerType = this.createField(data, 'PickerType');
    }
}
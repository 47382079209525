import { Constructor } from 'interfaces/constructor';
import { WCCStorageGetOptions } from 'managers/storage';

export const storageOptionsMetadataKey = Symbol('storage:options');

export default function storageOptions<T extends Constructor<any[], any> = Constructor<any[], any>>(options: WCCStorageGetOptions) {
    return (ctor: T) => {
        Reflect.defineMetadata(storageOptionsMetadataKey, options, ctor);
        return ctor;
    }
}
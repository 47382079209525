import { Observable } from 'knockout';
import SurveyStatisticsQuestionAnswer, { JSONSurveyStatisticsQuestionAnswer } from './answer';

export interface JSONSurveyStatisticsRankQuestionAnswer extends JSONSurveyStatisticsQuestionAnswer {
    Average: number
    TimesSelectedAsTop: number
    TimesSelectedAsLast?: number
}

export default class SurveyStatisticsRankQuestionAnswer extends SurveyStatisticsQuestionAnswer<JSONSurveyStatisticsRankQuestionAnswer> {
    average: Observable<number>
    timesSelectedAsTop: Observable<number>
    timesSelectedAsLast: Observable<number | undefined>

    constructor(data: JSONSurveyStatisticsRankQuestionAnswer) {
        super(data);

        this.average = this.createField(data, 'Average');
        this.timesSelectedAsTop = this.createField(data, 'TimesSelectedAsTop');
        this.timesSelectedAsLast = this.createField(data, 'TimesSelectedAsLast');
    }
}
import attachmentsFactory from 'factories/attachments';
import { Observable, ObservableArray, PureComputed, Subscribable } from "knockout";
import WCCAttachment, { JSONWCCAttachment } from './attachments/attachment';
import WCCAudio from './attachments/audio';
import WCCFile from './attachments/file';
import WCCImage from './attachments/image';
import WCCLink from './attachments/link';
import WCCVideo from './attachments/video';
import { CardsScoreAnswer, JSONCardsScoreAnswer } from "./cardsScoreAnswer";
import WCCEntity from './entity';
import { HeatmapPoint } from "./heatmapPoint";
import { Person } from "./person";
import { SimpleTopic } from './simpleTopic';
import { JSONSurveyPageAnswers } from './surveyPageAnswers';
import { JSONTag, Tag } from "./tag";

const whisperVisibilityTypes: Array<number | undefined> = [
    enums.ThreadVisibility.Private.value,
    enums.ThreadVisibility.PrivateMessage.value,
    enums.ThreadVisibility.PrivateModsAndObs.value
]

export interface JSONTopicThread {
    TopicContentType?: number;

    TopicThreadId?: string
    DiscussionTopicId?: string
    ParentThreadId?: string
    PostedById?: string
    VisibleToId?: string
    SlideId?: string
    TopicQuestionId?: string

    RepliesCount?: number
    SpamComplaintCount?: number
    UpvotesCount?: number
    DownvotesCount?: number

    Title?: string
    ThreadContent?: string
    TranslatedThreadContent?: string
    ThreadCreator?: string
    CreatorNickname?: string
    AnonymousName?: string
    VisibleToFullName?: string
    ThreadCreatorUserImage?: string
    UAInfo?: string
    EditInfo?: string

    Visibility?: number    
    ThreadType?: number
    CreatorTaskMemberStatus?: number
    SpecialThreadType?: number

    IsInToDoList?: boolean
    IsPinned?: boolean
    IsResponseMandatory?: boolean    
    IsReportedAsAbuse?: boolean
    IsModeratorIdea?: boolean
    IsApproved?: boolean
    IsModerator?: boolean
    IsObserver?: boolean
    TestOnly?: boolean
    CanBeDeleted?: boolean
    CanBeEdited?: boolean
    IsMobile?: boolean
    HasTags?: boolean
    HasReplies?: boolean

    HeatMapX?: number
    HeatMapY?: number

    CreateDate?: string | Date
    
    Owner?: JSONTopicThread   
    HeatmapPoint?: { X: number, Y: number }
    Attachments?: Array<JSONWCCAttachment>
    Tags?: Array<JSONTag>
    CardsScoreAnswers?: Array<JSONCardsScoreAnswer>
    ThreadParticipantIDs?: Array<string> 
    ThreadVisibilityTagIDs?: Array<string>
    SurveyAnswers?: Array<JSONSurveyPageAnswers>
    UrlTags?: Array<JSONTag>

    MoodTagId?: string
}

export interface JSONChatMessageBase extends JSONTopicThread {
    SpecialThreadType: number
}

export interface JSONChatMessage extends JSONChatMessageBase {
    Owner?: JSONTopicThread
    LinkedThreads?: Array<JSONChatMessageBase>
}

export class TopicThreadBase<T extends TopicThreadBase<T>> extends WCCEntity<JSONTopicThread> {
    private _topicContentType?: number

    id: Observable<string | undefined>
    topicId: Observable<string | undefined>
    parentId: Observable<string | undefined>
    postedById: Observable<string | undefined>
    visibleToId: Observable<string | undefined>
    slideId: Observable<string | undefined>
    topicQuestionId: Observable<string | undefined>

    title: Observable<string>
    content: Observable<string>
    translatedContent: Observable<string>
    creatorName: Observable<string>
    creatorNickname: Observable<string>
    anonymousName: Observable<string>
    visibleToFullName: Observable<string>
    creatorImageUrl: Observable<string>
    uaInfo: Observable<string>
    editInfo: Observable<string | undefined>

    visibility: Observable<number | undefined>
    threadType: Observable<number | undefined>
    creatorStatus: Observable<number | undefined>
    specialType: Observable<number | undefined>

    repliesCount: Observable<number>
    spamComplaintCount: Observable<number>
    upvotesCount: Observable<number>
    downvotesCount: Observable<number>
    vote: PureComputed<number>

    isInToDoList: Observable<boolean>
    isPinned: Observable<boolean>
    isResponseMandatory: Observable<boolean>      
    isReportedAsAbuse: Observable<boolean>
    IsModeratorIdea: Observable<boolean>
    isApproved: Observable<boolean>
    isModerator: Observable<boolean>
    isObserver: Observable<boolean>
    testOnly: Observable<boolean>
    canBeDeleted: Observable<boolean>
    canBeEdited: Observable<boolean>
    isMobile: Observable<boolean>
    hasTags: Observable<boolean>
    hasReplies: Observable<boolean>

    syncRequired = ko.observable(false);
    syncRequested = ko.observable(false);
    isDeleted = ko.observable(false);
    isDeleting = ko.observable(false);
    editing = ko.observable(false);

    createDate: Observable<Date>    

    owner: Observable<TopicThreadBase<T> | undefined>
    point: Observable<HeatmapPoint | undefined>

    topic = ko.observable<SimpleTopic>();
    person = ko.observable<Person>();
    topLevelThread = ko.observable<T>();
    parent = ko.observable<T>();
    replies = ko.observableArray<T>();

    attachments: ObservableArray<WCCAttachment>
    tags: ObservableArray<Tag>
    cardsScoreAnswers: ObservableArray<CardsScoreAnswer>
    threadParticipantIDs: ObservableArray<string>
    threadVisibilityTagIDs: ObservableArray<string>

    randomIdx = ko.pureComputed(() => _.random(10000));

    images: Subscribable<Array<WCCImage>>
    videos: Subscribable<Array<WCCVideo>>
    audios: Subscribable<Array<WCCAudio>>
    files: Subscribable<Array<WCCFile>>
    links: Subscribable<Array<WCCLink>>

    isWhisper = ko.pureComputed(() => whisperVisibilityTypes.includes(this.visibility()))
    isForSelectedParticipants = ko.pureComputed(() => this.visibility() == enums.ThreadVisibility.Group.value)
    isForTaggedPeople = ko.pureComputed(() => this.visibility() == enums.ThreadVisibility.Tags.value);
    isIdeationVote = ko.pureComputed(() => this.parentId() != undefined && this.vote() != 0);

    

    constructor(data?: JSONTopicThread) {
        super();

        this._topicContentType = data?.TopicContentType;

        this.id = this.createField(data, 'TopicThreadId');
        this.topicId = this.createField(data, 'DiscussionTopicId');
        this.parentId = this.createField(data, 'ParentThreadId');
        this.postedById = this.createField(data, 'PostedById');
        this.visibleToId = this.createField(data, 'VisibleToId');
        this.slideId = this.createField(data, 'SlideId');
        this.topicQuestionId = this.createField(data, 'TopicQuestionId');

        this.title = this.createField(data, 'Title', '');
        this.content = this.createField(data, 'ThreadContent', '');
        this.translatedContent = this.createField(data, 'TranslatedThreadContent', '');
        this.creatorName = this.createField(data, 'ThreadCreator', '');
        this.creatorNickname = this.createField(data, 'CreatorNickname', '');
        this.anonymousName = this.createField(data, 'AnonymousName', '');
        this.visibleToFullName = this.createField(data, 'VisibleToFullName', '');
        this.creatorImageUrl = this.createField(data, 'ThreadCreatorUserImage', '');
        this.uaInfo = this.createField(data, 'UAInfo', '');
        this.editInfo = this.createField(data, 'EditInfo');

        this.visibility = this.createField(data, 'Visibility');        
        this.threadType = this.createField(data, 'ThreadType');
        this.creatorStatus = this.createField(data, 'CreatorTaskMemberStatus');
        this.specialType = this.createField(data, 'SpecialThreadType');

        this.repliesCount = this.createField(data, 'RepliesCount', 0);
        this.spamComplaintCount = this.createField(data, 'SpamComplaintCount', 0);
        this.upvotesCount = this.createField(data, 'UpvotesCount', 0);
        this.downvotesCount = this.createField(data, 'DownvotesCount', 0);

        this.vote = ko.strictComputed((upvotesCount, downvotesCount) => upvotesCount - downvotesCount, [this.upvotesCount, this.downvotesCount]);
        
        this.isInToDoList = this.createField(data, 'IsInToDoList', <boolean>false);
        this.isPinned = this.createField(data, 'IsPinned', <boolean>false);
        this.isResponseMandatory = this.createField(data, 'IsResponseMandatory', <boolean>false);
        this.isReportedAsAbuse = this.createField(data, 'IsReportedAsAbuse', <boolean>false);
        this.IsModeratorIdea = this.createField(data, 'IsModeratorIdea', <boolean>false);
        this.isApproved = this.createField(data, 'IsApproved', <boolean>false);
        this.isModerator = this.createField(data, 'IsModerator', <boolean>false);
        this.isObserver = this.createField(data, 'IsObserver', <boolean>false);
        this.testOnly = this.createField(data, 'TestOnly', <boolean>false);
        this.canBeDeleted = this.createField(data, 'CanBeDeleted', <boolean>false);
        this.canBeEdited = this.createField(data, 'CanBeEdited', <boolean>false);
        this.isMobile = this.createField(data, 'IsMobile', <boolean>false);
        this.hasTags = this.createField(data, 'HasTags', <boolean>false);
        this.hasReplies = this.createField(data, 'HasReplies', <boolean>false);

        this.createDate = this.createMappedField(data, 'CreateDate', { map: value => new Date(value), mapReverse: date => date.toISOString() }, () => new Date());

        this.owner = this.createMappedField(data, 'Owner', value => new TopicThreadBase<T>(value));
        this.point = ko.observable(this.getPoint(data));

        this.attachments = this.createCollection(data, 'Attachments', jsonAttachment => attachmentsFactory.createAttachment(jsonAttachment));
        this.tags = this.createCollection(data, 'Tags', jsonTag => new Tag(jsonTag));
        this.cardsScoreAnswers = this.createCollection(data, 'CardsScoreAnswers', jsonCardsScoreAnswer => new CardsScoreAnswer(jsonCardsScoreAnswer));
        this.threadParticipantIDs = this.createCollection(data, 'ThreadParticipantIDs');
        this.threadVisibilityTagIDs = this.createCollection(data, 'ThreadVisibilityTagIDs');

        this.images = this.attachments.filter<WCCImage>(a => a.isImage());
        this.videos = this.attachments.filter<WCCVideo>(a => a.isVideo());
        this.audios = this.attachments.filter<WCCAudio>(a => a.isAudio());
        this.files = this.attachments.filter<WCCFile>(a => a.isFile());
        this.links = this.attachments.filter<WCCLink>(a => a.isLink());
    }

    override toJson(): JSONTopicThread {
        return {
            ...super.toJson(),

            TopicContentType: this._topicContentType,
            HeatMapX: this.point.invokeNotNull(point => point.x()),
            HeatMapY: this.point.invokeNotNull(point => point.y())
        }
    }

    override update(jsonThread: JSONTopicThread) {
        super.update(jsonThread);

        this.point(this.getPoint(jsonThread));
    }

    private getPoint(jsonThread?: JSONTopicThread) {
        if (jsonThread) {
            let { HeatMapX: X, HeatMapY: Y } = jsonThread;

            if(jsonThread.HeatmapPoint)
                return new HeatmapPoint(jsonThread.HeatmapPoint);
            else if (X != undefined && Y != undefined)
                return new HeatmapPoint({ X, Y });
        }
    }

}

export class TopicThread extends TopicThreadBase<TopicThread> {
    constructor(data?: JSONTopicThread) {
        super(data);
    }
}
import { LogLevel } from "@microsoft/signalr";
import URLBuilder from "builders/url";

const { now } = settings;

function parseDebugMode(type?: string) {
    switch (type?.toLowerCase()) {
        case 'debug': return LogLevel.Debug;
        case 'trace': return LogLevel.Trace;
        default: return LogLevel.Error;
    }
}

const url = URLBuilder.from(location.href);
const debug = parseDebugMode(url.getQuery('debugSignalR'));

export module SignalRHelpers {
    export const maxRetryCount = 10;
    
    export function secondsSince(date: Date) {
        return (now().getTime() - date.getTime()) / 1000;
    }

    export function log(message: string, ...args: Array<any>) {
        if (debug <= LogLevel.Debug)
            console.log(`signalR: ${message}`, ...args);
    }

    export function logError(message: string, ...args: Array<any>) {
        if (debug <= LogLevel.Error)
            console.error(`signalR: ${message}`, ...args);
    }

    export function logInfo(message: string, ...args: Array<any>) {
        if (debug <= LogLevel.Trace)
            console.info(`signalR: ${message}`, ...args);
    }
}
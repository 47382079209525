import {Observable, PureComputed} from 'knockout';
import WCCEntity from 'models/entity';

export interface JSONSurveyDateOptions {
    DatePlaceholder: string
    MonthPlaceholder: string
    YearPlaceholder: string
    UseDropdowns: boolean
    MinDifference?: number
    DateFormat: number
    DateFormatString: string
}

export default class SurveyDateOptions extends WCCEntity<JSONSurveyDateOptions> {
    datePlaceholder: Observable<string>
    monthPlaceholder: Observable<string>
    yearPlaceholder: Observable<string>
    useDropdowns: Observable<boolean>
    minDifference: Observable<number | undefined>
    dateFormat: Observable<number>
    dateFormatString: Observable<string>
    
    constructor(data: JSONSurveyDateOptions) {
        super();

        this.useDropdowns = this.createField(data, 'UseDropdowns');
        this.datePlaceholder = this.createField(data, 'DatePlaceholder', this.defaultDatePlaceholder());
        this.monthPlaceholder = this.createField(data, 'MonthPlaceholder', 'Month');
        this.yearPlaceholder = this.createField(data, 'YearPlaceholder', 'Year');
        this.minDifference = this.createField(data, 'MinDifference');
        this.dateFormat = this.createField(data, 'DateFormat');
        this.dateFormatString = this.createField(data, 'DateFormatString', this.defaultDateFormatString());
    }

    defaultDatePlaceholder() {
        if (this.useDropdowns()) {
            return labels.Day;
        }
        return labels.Date;
    }
    
    defaultDateFormatString() {
        return settings.dateFormatStrings[this.dateFormat()][0];
    }
}
import { inject, injectable } from "inversify";
import { IAppointmentsService } from "services/appointments/appointments.interfaces";
import storageDefaults from "../../../decorators/storageDefaults";
import { wccModules } from "../../../enums/wccModules";
import { EffectsContainer } from "../../../mixins/withEffects";
import { TimelineDataSourceLoadRequest } from "../../datasources/timeline";
import SignalREventsManager from "../../signalR/events";
import BaseAppointmentsIntervalsManager, { BaseAppointmentsIntervalsManagerConfig } from "./baseIntervalsTimeline";

export interface UserAppointmentsIntervalsManagerConfig extends BaseAppointmentsIntervalsManagerConfig { }

@injectable()
@storageDefaults(<Partial<UserAppointmentsIntervalsManagerConfig>>{ discussionId: settings.discussionId })
export default class UserAppointmentsIntervalsManager extends BaseAppointmentsIntervalsManager {
    constructor(
        @inject(wccModules.managerConfig) config: UserAppointmentsIntervalsManagerConfig,
        @inject(wccModules.appointmentsService) private appointmentsService: IAppointmentsService,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super(config, signalREvents, effects);
    }

    protected loadIntervals({ from, to }: TimelineDataSourceLoadRequest) {
        return this.appointmentsService.userAppointmentsIntervalsQuery(this.discussionId, from.toISOString(), to.toISOString()).addArgs(this.getQueryArgs()).toArray();
    }

    protected loadBoundaries() {
        return this.appointmentsService.userAppointmentsIntervalsTimeBoundariesQuery(this.discussionId, this.host).firstOrDefault();
    }
}
import LinkType from 'enums/linkTypes';
import { URLHelpers } from 'helpers/url';
import { PureComputed } from 'knockout';
import vimeoPattern from 'patterns/vimeo';
import youtubePattern from 'patterns/youtube';
import WCCAttachment, { JSONWCCAttachment } from './attachment';

export interface JSONWCCLink extends JSONWCCAttachment { }

export default class WCCLink extends WCCAttachment<JSONWCCLink> {
    linkType: PureComputed<LinkType>

    constructor(data?: JSONWCCLink) {
        super(data);

        this.linkType = ko.pureComputed(() => {
            const link = this.link() ?? '';

            if (link.length > 0) {
                if (youtubePattern.test(link)) return LinkType.youtube;
                if (vimeoPattern.test(link)) return LinkType.vimeo;
            }

            return LinkType.unknown;
        }, this);
    }

    protected override getViewTitle(): string {
        return this.title() || URLHelpers.removeProtocolFromURL(this.link() ?? '');
    }
}
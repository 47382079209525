import endpoint from 'endpoints/api';
import { injectable } from 'inversify';
import { JSONOpenTokSession } from 'models/openTokSession';
import Repository from 'repositories/generic';

@injectable()
export class OpenTokService {
    private repository = new Repository(endpoint);

    queries = {
        session: (topicId: string) => this.repository.query<never, JSONOpenTokSession, never, never, never, never, never>(`topics/${topicId}/opentok/session`)
    }
}
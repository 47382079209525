import topicWidgetVariablesMappings from 'data/topic/widgetVariablesMappings';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { SubscribableOrNullableValue } from 'knockout';
import SimpleTopicManager from 'managers/simpleTopic';
import { WCCStorageManager } from 'managers/storage';
import TopicAttachmentsManager from 'managers/topic/attachments';
import { EffectsContainer } from 'mixins/withEffects';
import ICommunityTemplateTopicWidgetVariableValueLoader, { TopicWidgetVariableValueWrapper } from './iValue';

@injectable()
export default class CommunityTemplateTopicWidgetVariableValueLoader implements ICommunityTemplateTopicWidgetVariableValueLoader {
    constructor(
        @inject(wccModules.storage) private storage: WCCStorageManager,
        @inject(wccModules.effects) private effects: EffectsContainer
    ) { }

    load(topicId: SubscribableOrNullableValue<string>, topicFieldId: SubscribableOrNullableValue<number>) {
        return ko.flattenComputed(topicFieldId).pluck<TopicWidgetVariableValueWrapper>(topicFieldId => {
            const mapping = topicWidgetVariablesMappings.find(field => field.id == topicFieldId);

            if (mapping != undefined) {
                const { topicMapper, topicAttachmentsMapper } = mapping;

                if (topicMapper != undefined) {
                    const topicManager = this.storage.get(SimpleTopicManager, { topicId });
                    const isReady = topicManager.pluck(m => !m.loading(), false)
                    const topic = topicManager.pluck(m => m.topic());
                    const value = topic.pluck(topic => topicMapper(topic));

                    return this.effects.register(ko.computed(() => (<TopicWidgetVariableValueWrapper>{ isReady: isReady(), value: value })));
                }

                if (topicAttachmentsMapper != undefined) {
                    const attachmentsManager = this.storage.get(TopicAttachmentsManager, { topicId });
                    const attachmentsReady = attachmentsManager.pluck(m => !m.loading(), false);
                    const attachments = attachmentsManager.pluck(m => m.attachments, []);
                    const value = ko.flattenComputed(topicAttachmentsMapper(attachments));

                    return this.effects.register(ko.computed(() => (<TopicWidgetVariableValueWrapper>{ isReady: attachmentsReady(), value: value() })));
                }                
            }
        }, { isReady: true, value: undefined });
    }
}
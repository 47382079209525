import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { PureComputed, Subscribable } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import events from 'managers/events';
import { EffectsContainer } from 'mixins/withEffects';
import { JSONSimpleTopic, SimpleTopic } from 'models/simpleTopic';
import { CommentsService } from 'services/comments';
import { DiscussionService } from 'services/discussion';
import { PeopleService } from 'services/people';
import { TopicsService } from 'services/topics';
import { CurrentUserManager } from './currentUser';
import EntityDataSourceOwner from './entityDataSourceOwner';
import { IWCCStorageManager } from './iStorage';
import SignalREventsManager from './signalR/events';

interface SimpleTopicManagerConfig {
    topicId: string
    searchString: string
    peopleTagsIDs: Array<string>
}

@injectable()
@storageDefaults(<Partial<SimpleTopicManagerConfig>>{ searchString: '', peopleTagsIDs: [] })
export default class SimpleTopicManager extends EntityDataSourceOwner<JSONSimpleTopic> {
    private topicId: string
    private searchString: string
    private peopleTagsIDs: Array<string>

    private userId: Subscribable<string | undefined>
    private isRegularUser: Subscribable<boolean>

    protected source: EntityDataSource<JSONSimpleTopic>

    topic: PureComputed<SimpleTopic | undefined>

    constructor(
        @inject(wccModules.managerConfig) { topicId, searchString, peopleTagsIDs }: SimpleTopicManagerConfig,
        @inject(wccModules.discussionService) discussionService: DiscussionService,
        @inject(wccModules.topicsService) private topicsService: TopicsService,
        @inject(wccModules.peopleService) peopleService: PeopleService,
        @inject(wccModules.commentsService) commentsService: CommentsService,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.signalREvents) signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer        
    ) {
        super();

        this.topicId = topicId;
        this.searchString = searchString;
        this.peopleTagsIDs = peopleTagsIDs;

        this.source = effects.register(new EntityDataSource({
            update: this.load.bind(this)
        }));

        this.topic = this.source.data.toEntity(
            jsonTopic => new SimpleTopic(jsonTopic),
            (topic, jsonTopic) => topic.update(jsonTopic));        

        const userManager = storage.get(CurrentUserManager, { discussionId: settings.discussionId });
        const user = userManager.pluck('person');
        const isModerator = user.pluck('isModerator', false);

        this.userId = user.pluck('personId');
        this.isRegularUser = user.pluck('isRegular', false);

        const isIdeation = this.topic.pluck(t => t.isIdeation, false);

        topicsService.subscriptions.discussionTopicNotifications.subscribe(this.topicId);
        topicsService.events.topicChanged.on(this.onTopicChanged.bind(this));

        commentsService.subscriptions.topicThreads.subscribe(this.topicId);

        commentsService.events.newTopicComment.on(this.onNewComment.bind(this));
        commentsService.events.newTopicReply.on(this.onNewReply.bind(this));
        commentsService.events.topicThreadDeleted.on(this.onThreadDeleted.bind(this));
        discussionService.events.deletedNotificationEvent.on(this.onNotificationDeleted.bind(this));

        if(settings.discussionId)
            signalREvents.discussion(settings.discussionId).onDiscussionChanged(() => this.update());

        effects.register(userId => {
            if (userId)
                return peopleService.subscriptions.personNotifications.subscribe(userId);
        }, [this.userId]);

        effects.register(isModerator => {
            if (isModerator) {
                return signalREvents.topicNotifications(this.topicId)
                    .onNotificationEvent(() => this.update())
                    .onThreadReportCleared(() => this.update());
            }
        }, [isModerator]);

        effects.register(isIdeation => {
            if (isIdeation)
                return [
                    signalREvents.ideation(topicId).onStageChanged(this.onIdeationStageChanged.bind(this)),
                    events.ideationStageChanged.on(this.onLocalIdeationStageChanged.bind(this))
                ];
        }, [isIdeation]);

        effects.register([
            events.topicIsAnswered.on(this.onLocalTopicIsAnswered.bind(this)),
            events.topicIsAvailable.on(this.onLocalTopicIsAvailable.bind(this)),

            events.commentAdded.on(this.onLocalComentAdded.bind(this)),
            events.commentDeleted.on(this.onLocalCommentDeleted.bind(this)),
            events.commentNotificationsRemoved.on(this.onCommentNotificationsRemoved.bind(this)),
            events.commentsNotificationsRemoved.on(this.onCommentsNotificationsRemoved.bind(this)),
            events.discussionCommentsNotificationsRemoved.on(this.onDiscussionCommentsNotificationsRemove.bind(this)),

            events.replyAdded.on(this.onNewLocalReply.bind(this))
        ]);
    }

    update() {
        this.source.update();
    }

    private load() {
        if (this.searchString.length > 0 || this.peopleTagsIDs.length > 0) {
            return this.topicsService.queries.filteredSimpleTopic(this.topicId).important().firstOrDefaultPost({
                searchString: this.searchString,
                peopleTagsIDs: this.peopleTagsIDs
            });
        }

        return settings.isAdmin ?
            this.topicsService.adminQueries.simpleTopic(this.topicId).firstOrDefault() :
            this.topicsService.queries.simpleTopic(this.topicId).firstOrDefault();
    }

    private onTopicChanged(topicId: string) {
        if (this.topicId === topicId)
            this.update();
    }

    private onNewComment(threadId: string, topicId: string) {
        if (this.topicId === topicId)
            this.source.update();
    }

    private onNewReply(threadId: string, parentThreadId: string, topicId: string) {
        if (this.topicId === topicId)
            this.source.update();
    }

    private onThreadDeleted(threadId: string, parentThreadId: string, personId: string, topicId: string) {
        if (this.topicId === topicId)
            this.source.update();
    }

    private onNotificationDeleted(topicId: string, threadId: string, personId: string) {
        if (this.userId() === personId && this.topicId === topicId)
            this.source.update();
    }

    private onIdeationStageChanged(topicId: string, stage: number, isStaging: boolean) {
        if (this.topicId == topicId && settings.isStaging == isStaging)
            this.source.update();
    }

    private onLocalIdeationStageChanged(topicId: string, stage: number) {
        this.onIdeationStageChanged(topicId, stage, settings.isStaging)
    }

    private onLocalTopicIsAnswered(topicId: string) {
        if (this.topicId === topicId)
            this.topic()?.isAnswered(true);
    }

    private onLocalTopicIsAvailable(topicId: string) {
        if (this.topicId === topicId)
            this.update();
    }

    private onLocalComentAdded(topicId: string) {
        if (this.topicId === topicId && this.isRegularUser()) {
            this.topic.invokeNotNull(topic => {
                if (!topic.isHeatMap())
                    topic.isAnswered(true);
            });

            this.source.update();
        }
    }

    private onLocalCommentDeleted(topicId: string) {
        if (this.topicId === topicId && this.isRegularUser())
            this.source.update();
    }

    private onCommentNotificationsRemoved(topicId: string, commentId: string) {
        if (this.topicId === topicId)
            this.source.update();
    }

    private onCommentsNotificationsRemoved(topicId: string) {
        if (this.topicId === topicId)
            this.source.update();
    }

    private onDiscussionCommentsNotificationsRemove() {
        this.source.update();
    }

    private onNewLocalReply(topicId: string) {
        if (this.topicId === topicId)
            this.source.update();
    }
}
define('models/account/image', function () {
    const { WccCDNUrl } = settings;

    class Model {
        constructor(jsonModel) {
            this.accountImageId = ko.observable(jsonModel.AccountImageId);
            this.accountId = ko.observable(jsonModel.AccountId);
            this.imageFolder = ko.observable(jsonModel.ImageFolder);
            this.imageName = ko.observable(jsonModel.ImageName);
            this.imageType = ko.observable(jsonModel.ImageType);
            this.imageWidth = ko.observable(jsonModel.ImageWidth);
            this.imageHeight = ko.observable(jsonModel.ImageHeight);
            this.createDate = ko.observable(jsonModel.CreateDate);

            this.url = ko.pureComputed(() => `${WccCDNUrl}${this.imageFolder() || ''}${this.imageName()}`);
        }
    }

    return Model;
});
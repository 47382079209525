import { PureComputed } from 'knockout';
import events from 'managers/events';
import { JSONTag, Tag } from 'models/tag';
import TagsManagerBase from './base';

export default class PersonTagsManager extends TagsManagerBase {
    personId: PureComputed<string>

    constructor(personId: string) {
        super();

        this.personId = ko.pureComputed(() => personId);

        this.effects.register([
            events.participantTagAdded.on(this._onLocalTagAdded.bind(this)),
            events.participantTagRemoved.on(this._onLocalTagRemoved.bind(this))
        ]);

        if (settings.accountId)
            this.ctx.peopleService.subscriptions.accountPeople.subscribe(settings.accountId);
        else
            console.warn('settings.accountId is undefined');

        this.ctx.peopleService.events.personTagsChanged.on(this.update.bind(this));
    }

    async addTag(jsonTag: JSONTag) {
        try {
            if (settings.discussionId == undefined)
                throw new Error(messages.UnknownError);

            this.tasksCount.inc();

            await this.ctx.tagsService.saveParticipantTag(settings.discussionId, this.personId(), jsonTag);
        } catch (ex) {
            system.handleError(ex);
        } finally {
            this.tasksCount.dec()
        }
    }

    async removeTag(tag: Tag) {
        try {
            if (settings.discussionId == undefined)
                throw new Error(messages.UnknownError);

            const tagId = tag.contentTagId();

            if (tagId == undefined)
                throw new Error(messages.InvalidRequest);

            this.tasksCount.inc();

            await this.ctx.tagsService.removeParticipantTag(settings.discussionId, this.personId(), tagId);
        } catch (ex) {
            system.handleError(ex);
        } finally {
            this.tasksCount.dec()
        }
    }

    protected async load() {
        if (settings.discussionId == undefined)
            throw new Error(messages.UnknownError);

        return await this.ctx.tagsService.queries.participantTags(settings.discussionId, this.personId()).background().toArray();
    }

    protected override isRemoveAllowed() {
        return true;
    }

    protected override isEditAllowed() {
        return true;
    }

    private _onLocalTagAdded(personId: string, tagId: string, jsonTag: JSONTag) {
        if (this.personId() === personId)
            this.source.add(jsonTag);
    }

    private _onLocalTagRemoved(personId: string, tagId: string) {
        if (this.personId() === personId)
            this.source.remove(tag => tag.contentTagId() === tagId);
    }
}
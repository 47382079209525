define('models/topicCompletionByPerson', [], function () {
    function Model(jsonModel) {
        this.topicId = ko.observable(jsonModel.TopicId);
        this.quickSell = ko.observable(jsonModel.QuickSell);
        this.topicContentType = ko.observable(jsonModel.TopicContentType);
        this.postCreatorRestrictionType = ko.observable(jsonModel.PostCreatorRestrictionType);
        this.status = ko.observable(jsonModel.Status);
        this.hasAdditionalStage = ko.observable(jsonModel.HasAdditionalStage);
        this.secondStageStatus = ko.observable(jsonModel.SecondStageStatus);
    }

    return Model;
});
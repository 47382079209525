export const componentSectionsMetadataKey = Symbol('component:master-sections');

export default function componentSection(name: string, sectionId: string) {
    return (ctor: any) => {
        const sections = new Map<string, string>(Reflect.getMetadata(componentSectionsMetadataKey, ctor) ?? new Map<string, string>());
        sections.set(name, sectionId);

        Reflect.defineMetadata(componentSectionsMetadataKey, sections, ctor);

        return ctor;
    }
}
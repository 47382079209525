define('managers/community/pages/styles',
    [
        'managers/base_es5'
    ],

    function (Base) {
        const { EntityDataSource: DataSource } = require('managers/datasources/entity');

        function Manager(pageId) {
            Base.call(this);

            this.pageId = ko.pureComputed(function () { return pageId });

            this._source = this._register(new DataSource({
                update: this._updateStyles.bind(this),
                save: this._saveStyles.bind(this)
            }));

            this.content = this._source.data;
            this.fromServer = this._source.fromServer;

            this.loading = this._source.updating;
            this.saving = this._source.saving;
            this.busy = this._source.busy;
        }

        Manager.prototype = Object.create(Base.prototype);
        Manager.prototype.constructor = Manager;

        Manager.prototype.update = function () {
            this._source.update();
        };

        Manager.prototype.save = function (content) {
            this._source.save(content);
        };

        Manager.prototype._updateStyles = function () {
            return this._ctx
                .communitiesService
                .queries
                .pageStyles(this.pageId())
                .firstOrDefault();
        };

        Manager.prototype._saveStyles = function (styles) {
            return this._ctx
                .communitiesService
                .queries
                .pageStyles(this.pageId())
                .update({ pageStyles: styles });
        };

        return Manager;
    });
import { Observable, PureComputed, Subscribable } from 'knockout';
import UserFile from 'models/userFile';
import WCCAttachment, { JSONWCCAttachment } from './attachment';
import { FileIconHelper } from 'helpers/fileIconHelper';
import {FilesHelpers} from "../../helpers/files";

const { WccCDNUrl } = settings;
const attachmentFilenameMaxLength = 25;

interface AttachmentFields {
    originalFileName: Observable<string | undefined>
    fileExtension: Observable<string | undefined>
}

export interface JSONWCCFile extends JSONWCCAttachment {
    CDNFileName?: string
    FileHash?: string
    FileExtension?: string
    OriginalFileName?: string
    Token?: string
}

export default class WCCFile extends WCCAttachment<JSONWCCFile> {
    private base: AttachmentFields

    cdnFileName: Observable<string | undefined>
    fileHash: Observable<string | undefined>
    fileExtension: Observable<string | undefined>
    originalFileName: Observable<string | undefined>

    file: PureComputed<UserFile | undefined>
    fileUrl: Subscribable<string | undefined>

    token: Subscribable<string | undefined>   // token for the file. Used to create the link to api/resources
    
    fileIconClass: PureComputed<string>

    constructor(data?: JSONWCCFile) {
        super(data);

        this.cdnFileName = this.createField(data, 'CDNFileName');
        this.fileHash = this.createField(data, 'FileHash');

        this.token = this.createField(data, 'Token');
        
        this.base = {
            originalFileName: this.createField(data, 'OriginalFileName'),
            fileExtension: this.createField(data, 'FileExtension'),
        }

        const cOldFile = ko.pureComputed(() => {
            return new UserFile({
                UserFileId: this.userFileId(),
                Name: this.base.originalFileName(),
                Extension: this.base.fileExtension(),
                Link: this.getUrl(),
                Type: enums.UserFileTypes.File.value,
                CreateDate: this.createDate()
            });
        });

        const cFile = this.getObservableFile(enums.UserFileTypes.File.value, [cOldFile]);

        this.originalFileName = ko.pureComputed({
            read: () => cFile()?.name(),
            write: this.base.originalFileName
        });

        this.fileExtension = ko.pureComputed({
            read: () => cFile()?.extension(),
            write: this.base.fileExtension
        });

        this.file = cFile;
        this.fileUrl = cFile.pluck('link');
        
        this.fileIconClass = ko.pureComputed(() => {
            return FileIconHelper.getIconClass(this.fileExtension() ?? '');
        });
    }

    protected override getViewTitle() {
        return this.title() || (this.originalFileName() ?? '');
    }

    private getUrl() {
        const hash = this.fileHash();
        const name = this.cdnFileName();

        return name != undefined ?
            (WccCDNUrl + '/userfiles/' + name).toLowerCase() + (hash != null ? '?rnd=' + hash : '') :
            this.link();
    }

    public async download() {
        if(!this.fileUrl()) {
            system.showErrorMessage('File is not downloadable');
        }
            
        return await FilesHelpers.saveAs(await FilesHelpers.loadAsBlob(this.fileUrl()!), this.originalFileName() ?? 'some_file.txt');
    }
}
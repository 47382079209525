import { Observable } from 'knockout';
import { Disposable, isDisposable } from 'interfaces/disposable';

enum states {
    init = 0,
    loading = 1,
    ready = 2
}

export class StorageBucket<T = any> {
    private state = ko.observable(states.init);

    name: Observable<string>
    content: Observable<T | undefined> = ko.observable()
    usersCount = ko.observable(0);
    disposeDelay: Observable<number>    
    resources = [] as Array<Disposable>

    isInitializing = ko.pureComputed(() => this.state() === states.init);
    isLoading = ko.pureComputed(() => this.state() === states.loading);
    isReady = ko.pureComputed(() => this.state() === states.ready);

    constructor(name: string, disposeDelay = 0) {
        this.name = ko.observable(name);        
        this.disposeDelay = ko.observable(disposeDelay);
    }

    acquire() {
        this.usersCount.inc();
    }

    release() {
        this.usersCount.dec();
    }

    markInitializing() {
        this.state(states.init);
    }

    markLoading() {
        this.state(states.loading);
    }

    markReady() {
        this.state(states.ready);
    }

    dispose() {
        var content = this.content();

        if (isDisposable(content))
            content.dispose();

        this.content(undefined);

        this.resources.forEach(r => r.dispose());
        this.resources = [];
        
        this.state(states.init);
    }
}
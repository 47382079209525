define('managers/topic/questions',
    [
        'mixins/withEffects',
        'mixins/withTopicMessages'
    ],

    function ({ withEffects }, withMessages) {
        function isModeratorQuestion(message) {
            return message.isModerator() &&
                message.parentId() == null &&
                message.visibility() === enums.ThreadVisibility.Public.value &&
                !message.isDeleted() &&
                !message.syncRequired();
        }

        function Manager(taskId, topicId) {
            var mEffects = withEffects();

            var mMessages = mEffects.register(withMessages(taskId, topicId)),
                cMessages = mMessages.all;

            var cQuestion = ko.pureComputed(function () {
                return _.chain(cMessages()).filter(isModeratorQuestion).last().value();
            }).extend({ deferred: true });

            var cIsReady = ko.pureComputed(function () {
                return mMessages.ready();
            });

            this.ready = cIsReady;
            this.question = cQuestion;

            this.dispose = mEffects.dispose;
        }

        return Manager;
    });
import attachmentsFactory from 'factories/attachments';
import { Observable } from "knockout";
import WCCAttachment, { JSONWCCAttachment } from 'models/attachments/attachment';

export interface JSONHeatmapOptions {
    HeatMapOptionsId: string
    HeatMapOption: number
    UnlimitedPins: boolean
    HeatMapHint: string
    MinPins?: number;
    MaxPins?: number;

    HeatMapImage: JSONWCCAttachment
}

export class HeatmapOptions {
    heatMapOptionsId: string
    option: Observable<number>
    hint: Observable<string>
    unlimitedPins: Observable<boolean>
    minPins: Observable<number | undefined>
    maxPins: Observable<number | undefined>

    image: Observable<WCCAttachment>

    constructor(jsonOptions: JSONHeatmapOptions) {
        this.heatMapOptionsId = jsonOptions.HeatMapOptionsId
        this.image = ko.observable(attachmentsFactory.createAttachment(jsonOptions.HeatMapImage));
        this.option = ko.observable(jsonOptions.HeatMapOption);
        this.hint = ko.observable(jsonOptions.HeatMapHint);
        this.unlimitedPins = ko.observable(jsonOptions.UnlimitedPins);
        this.minPins = ko.observable(jsonOptions.MinPins);
        this.maxPins = ko.observable(jsonOptions.MaxPins);
    }

    toJSON(): JSONHeatmapOptions {
        return {
            HeatMapOptionsId: this.heatMapOptionsId,
            HeatMapOption: this.option(),
            UnlimitedPins: this.unlimitedPins(),
            HeatMapHint: this.hint(),
            MinPins: this.minPins(),
            MaxPins: this.maxPins(),
            HeatMapImage: this.image()?.toJson()
        }
    }
}
import { wccModules } from 'enums/wccModules';
import { Disposable } from 'interfaces/disposable';
import { Func } from 'interfaces/func';
import { inject, injectable } from 'inversify';
import { EffectsContainer, withEffects } from 'mixins/withEffects';
import { JSONWCCAttachment } from 'models/attachments/attachment';
import { JSONCommunityWidgetTemplate } from 'models/community/widgetTemplate';
import { JSONOnlineUserSession } from 'models/signalR/onlineUserSession';
import { JSONChatMessage } from 'models/topicThread';
import { CollectionsHelpers } from '../../helpers/collections';
import { JSONGroupRoomSlideState } from '../../models/appointments/groupRoomSlideState';
import { JSONOnlineUserSlideDetails } from '../../models/signalR/onlineUserSlideDetails';
import { IWCCStorageManager } from '../iStorage';
import { ISignalRCoreManager } from './core/interfaces/main';
import {IReportAndDownloadFileCompact} from "../../pages/resources/reportsAndDownloadFiles/models/reportAndDownloadFile";
import {IPipeline, IPipelineItemUpdateNotification, IPipelineUpdateNotification} from "../../models/pipelines";

interface SignalRSubscriptionOwner<T> {
    onSubscriptionActivated(handler: Action): T
    onSubscriptionDeactivated(handler: Action): T
}

export type SignalREventIDExtractor<T extends Array<unknown>> = (...args: [...T]) => SignalRSubscriptionArgs
type SignalRSubscriptionArgs = string | Array<string | undefined>
type SignalREventHandler<T extends Array<unknown> = []> = (...args: [...T]) => void
type SignalREventsConfig<T> = { [K in keyof T]: T[K] extends Action<infer R> ? Func<SignalREventsConfig<T>, R> : never } & Disposable & SignalRSubscriptionOwner<SignalREventsConfig<T>>;
type SignalRSystemEventsConfig<T> = { [K in keyof T]: T[K] extends Action<infer R> ? Func<SignalREventsConfig<T>, R> : never } & Disposable;

class EventsFactory<A extends SignalRSubscriptionArgs>{
    private subscriptionArgs: Array<string | undefined>

    constructor(
        private groupArgs: A,
        private groupName: string,
        private effects: EffectsContainer,
        private signalRManager: ISignalRCoreManager
    ) {
        this.subscriptionArgs = _.isString(groupArgs) ? [groupArgs] : groupArgs;
    }

    createEvent<T extends Array<any>>(eventName: string, idExtractor: SignalREventIDExtractor<[...T]>) {
        return (handler: SignalREventHandler<[...T]>) => {
            this.effects.register([
                this.signalRManager.subscribe(this.groupName, ...this.subscriptionArgs),

                this.signalRManager.on(eventName, (...args) => {
                    let isMatch = true;

                    if (idExtractor != undefined) {
                        const extractedValue = idExtractor(...args as any);

                        if (_.isString(extractedValue))
                            isMatch = extractedValue === this.groupArgs;
                        else
                            isMatch = CollectionsHelpers.compareCollections(extractedValue, <Array<string | undefined>>this.groupArgs);
                    }

                    if (isMatch)
                        handler(...args as any);
                })
            ]);
        }
    }
}

@injectable()
export default class SignalREventsManager {
    accountPeople = this.createEventsGroupFactory('AccountPeople', factory => ({
        onPersonChanged: factory.createEvent('PersonChanged', (personId: string, accountId: string) => accountId)
    }));

    appointment = this.createEventsGroupFactory('Appointment', factory => ({
        onChanged: factory.createEvent('AppointmentChanged', (appointmentId: string) => appointmentId),
        onScreenSharingStopRequested: factory.createEvent('SignalRMessage__AppointmentScreenSharingStopRequested', (discussionId: string, appointmentId: string) => appointmentId)
    }));

    appointmentEvents = this.createEventsGroupFactory('AppointmentEvents', factory => ({
        onEventCreated: factory.createEvent('AppointmentEventCreated', (appointmentId: string) => appointmentId)
    }));

    appointmentDailyRecords = this.createEventsGroupFactory('AppointmentDailyRecords', factory => ({
        onRecordsChanged: factory.createEvent('AppointmentDailyRecordsChanged', (appointmentId: string) => appointmentId),
        onRecordChanged: factory.createEvent('AppointmentDailyRecordChanged', (appointmentId: string, recordId: string) => appointmentId)
    }));

    appointmentSlide = (appointmentId: string, slideId: string) => this.createEventsGroup('AppointmentSlide', [appointmentId, slideId], factory => ({
        onStateChanged: factory.createEvent('AppointmentSlideStateChanged', (appointmentId: string, slideId: string, state: JSONGroupRoomSlideState) => [appointmentId, slideId])
    }));

    appointmentOnlineUsers = this.createEventsGroupFactory('AppointmentOnlineUsers', factory => ({
        onUserJoined: factory.createEvent('SignalRMessage_UserJoinedAppointment', (discussionId: string, appointmentId: string, session: JSONOnlineUserSession) => appointmentId),
        onUserLeft: factory.createEvent('SignalRMessage_UserLeftAppointment', (discussionId: string, appointmentId: string, sessionId: string) => appointmentId)
    }));

    chatMessages = this.createEventsGroupFactory("ChatMessages", factory => ({
        onNewMessage: factory.createEvent('SignalRMessage__NewChatMessage', (discussionId: string, topicId: string, chatMessage: JSONChatMessage, isTranslated: boolean, IsStaging: boolean) => topicId),
        onMessageUpdated: factory.createEvent('SignalRMessage__ChatMessageUpdated', (discussionId: string, topicId: string, chatMessage: JSONChatMessage, isTranslated: boolean, IsStaging: boolean) => topicId),
        onMessageDeleted: factory.createEvent('SignalRMessage__ChatMessageDeleted', (discussionId: string, topicId: string, messageId: string, IsStaging: boolean) => topicId)
    }));

    communityPages = this.createEventsGroupFactory('CommunityPages', factory => ({
        onPageAdded: factory.createEvent('CommunityPageAdded', (communityId: string) => communityId),
        onPagesImported: factory.createEvent('CommunityPagesImported', (communityId: string) => communityId),
        onPageUpdated: factory.createEvent('CommunityPageUpdated', (pageId: string, communityId: string) => communityId),
        onPageDeleted: factory.createEvent('CommunityPageDeleted', (pageId: string, communityId: string) => communityId),
        onPageImageChanged: factory.createEvent('CommunityPageImageChanged', (pageId: string, communityId: string) => communityId),
        onPagePublished: factory.createEvent('CommunityPagePublished', (pageId: string, communityId: string) => communityId)
    }));

    communityWidgets = this.createEventsGroupFactory('CommunityWidgets', factory => ({
        onTemplateAdded: factory.createEvent('CommunityWidgetTemplateAdded', (communityId: string, template: JSONCommunityWidgetTemplate) => communityId),
        onTemplateUpdated: factory.createEvent('CommunityWidgetTemplateUpdated', (communityId: string, templateId: string, template: JSONCommunityWidgetTemplate) => communityId),
        onTemplateRemoved: factory.createEvent('CommunityWidgetTemplateRemoved', (communityId: string, templateId: string) => communityId)
    }));

    discussion = this.createEventsGroupFactory('Discussion', factory => ({
        onDiscussionChanged: factory.createEvent('DiscussionIsChanged', (discussionId: string) => discussionId),
        onParticipantStatusChanged: factory.createEvent('ParticipantStatusChanged', (discussionId: string, personId: string, status: number) => discussionId),
    }));

    reportAndDownloadFiles = (discussionId: string, personToken: string) => this.createEventsGroup('ReportAndDownloadFiles', [discussionId, personToken], factory => ({
        onReportOrExportIsUpdated: factory.createEvent('ReportOrExportIsUpdated', (discussionId: string, personToken: string, file: IReportAndDownloadFileCompact) => [discussionId, personToken]),
        onReportOrExportIsDeleted: factory.createEvent('ReportOrExportIsDeleted', (discussionId: string, personToken: string, deletedFileId: string) => [discussionId, personToken])
    }));

    servicePipelineUpdates = (discussionId: string) => this.createEventsGroup('ServicePipelineUpdates', [discussionId], factory => ({
        onServicePipelineIsCreated: factory.createEvent('ServicePipelineIsCreated', (discussionId: string, threadId: string, message: IPipeline) => [discussionId]),
        onServicePipelineIsUpdated: factory.createEvent('ServicePipelineIsUpdated', (discussionId: string, threadId: string, message: IPipelineUpdateNotification) => [discussionId]),
        onServicePipelineItemIsUpdated: factory.createEvent('ServicePipelineItemIsUpdated', (discussionId: string, threadId: string, message: IPipelineItemUpdateNotification) => [discussionId]),
    }));
    
    discussionAppointments = this.createEventsGroupFactory('DiscussionAppointments', factory => ({
        onAppointmentCreated: factory.createEvent('DiscussionAppointmentCreated', (discussionId: string, appointmentId: string, startsAt: string) => discussionId),
        onAppointmentChanged: factory.createEvent('DiscussionAppointmentChanged', (discussionId: string, appointmentId: string) => discussionId),
        onAppointmentDeleted: factory.createEvent('DiscussionAppointmentDeleted', (discussionId: string, appointmentId: string) => discussionId),
        onAppointmentsProgressChanged: factory.createEvent('DiscussionAppointmentsProgressChanged', (discussionId: string) => discussionId)        
    }));

    discussionAI = this.createEventsGroupFactory('discussionAI', factory => ({
        onAiWorkbenchQuestionImageReady: factory.createEvent('AiWorkbenchQuestionImageReady', (discussionId: string, questionId: string) => discussionId),
        onAiWorkbenchTalkingPointsGenerated: factory.createEvent('AiWorkbenchTalkingPointsGenerated', (discussionId: string) => discussionId),
        onAiWorkbenchTalkingPointsUpdated: factory.createEvent('AiWorkbenchTalkingPointsUpdated', (discussionId: string) => discussionId),
        onAiWorkbenchTalkingPointsGenerationFailed: factory.createEvent('AiWorkbenchTalkingPointsGenerationFailed', (discussionId: string) => discussionId),
        onAiWorkbenchTalkingPointsLinkageFailed: factory.createEvent('AiWorkbenchTalkingPointsLinkageFailed', (discussionId: string) => discussionId),
    }));

    discussionAppointmentsIntervals = this.createEventsGroupFactory('DiscussionAppointmentsIntervals', factory => ({
        onIntervalsChanged: factory.createEvent('DiscussionAppointmentsIntervalsChanged', (discussionId: string, from: string, to: string) => discussionId),
    }));

    discussionInterviewers = this.createEventsGroupFactory('DiscussionInterviewers', factory => ({
        onInterviewerSettingsChanged: factory.createEvent('InterviewerSettingsChanged', (discussionId: string, personId: string) => discussionId)
    }));

    discussionOnlineUsers = this.createEventsGroupFactory('DiscussionOnlineUsers', factory => ({
        onUserOnline: factory.createEvent('SignalRMessage_UserOnline', (discussionId: string, session: JSONOnlineUserSession) => discussionId),
        onUserOffline: factory.createEvent('SignalRMessage_UserOffline', (discussionId: string, sessionId: string) => discussionId),
        onUserMovedToTopic: factory.createEvent('SignalRMessage_UserMovedToTopic', (discussionId: string, sessionId: string, topicId: string) => discussionId),
        onUserMovedToAppointment: factory.createEvent('SignalRMessage_UserMovedToAppointment', (discussionId: string, sessionId: string, topicId: string) => discussionId)
    }));

    discussionThreads = this.createEventsGroupFactory('DiscussionThreads', factory => ({
        onTranslationIsChanged: factory.createEvent('DiscussionThreadTranslationIsChanged', (discussionId: string, threadId: string, content: string) => discussionId)
    }));

    ideation = this.createEventsGroupFactory('Ideation', factory => ({
        onStageChanged: factory.createEvent('IdeationStageChanged', (topicId: string, IdeationStages: number, isStaging: boolean) => topicId)
    }));

    liveGroup = this.createEventsGroupFactory('LiveGroup', factory => ({
        onActiveTopicChange: factory.createEvent('ActiveTopicChanged', (liveGroupId: string, isStaging: boolean) => liveGroupId),
        onCheckedIn: factory.createEvent('CheckedIn', (liveGroupId: string, personId: string) => liveGroupId),
        onCheckedOut: factory.createEvent('CheckedOut', (liveGroupId: string, personId: string) => liveGroupId)
    }));    

    personThreads = this.createEventsGroupFactory('PersonThreads', factory => ({
        onTranslationIsChanged: factory.createEvent('PersonThreadTranslationIsChanged', (personId: string, threadId: string, content: string) => personId)
    }));    

    personNotifications = this.createEventsGroupFactory('PersonNotifications', factory => ({
        onNotificationEvent: factory.createEvent('NewNotificationEvent', (topicId: string, threadId: string, personId: string, type: number) => personId)
    }));

    projectIdeations = this.createEventsGroupFactory('Ideations', factory => ({
        onStageChanged: factory.createEvent('ProjectIdeationStageChanged', (projectId: string, topicId: string, stage: number, isStaging: boolean) => projectId)
    }));

    signalRMessages = this.createEventsGroupFactory('SignalRMessages', factory => ({
        onNewChatMessage: factory.createEvent('NewMessage', (discussionId: string, topicId: string, chatMessage: JSONChatMessage, isTranslated: boolean, isStaging: boolean) => discussionId),
        onChatMessageChanged: factory.createEvent('MessageChanged', (discussionId: string, topicId: string, chatMessage: JSONChatMessage, isTranslated: boolean, isStaging: boolean) => discussionId),
        onChatMessageDeleted: factory.createEvent('MessageDeleted', (discussionId: string, topicId: string, messageId: string, isStaging: boolean) => discussionId)        
    }));

    slideOnlineUsers = this.createEventsGroupFactory('SlideOnlineUsers', factory => ({
        onUserJoined: factory.createEvent('SignalRMessage_UserJoinedSlide', (discussionId: string, slideId: string, session: JSONOnlineUserSession) => slideId),
        onUserLeft: factory.createEvent('SignalRMessage_UserLeftSlide', (discussionId: string, slideId: string, sessionId: string) => slideId),
        onSlideStateChanged: factory.createEvent('SignalRMessage_SlideStateChanged', (slideId: string, slide: JSONOnlineUserSlideDetails, sessionId: string) => slideId)
    }));

    staging = this.createEventsGroupFactory('Staging', factory => ({
        onReset: factory.createEvent('StagingReset', (discussionId: string) => discussionId)
    }));

    surveyResponses = this.createEventsGroupFactory('SurveyResponses', factory => ({
        onSurveyResponsesChanged: factory.createEvent('SurveyResponsesChanged', (topicId: string) => topicId)
    }));

    system = this.createSystemEventsGroupFactory(effects => ({
        onConnected: (handler: SignalREventHandler) => effects.register(connected => {
            if (connected)
                handler();
        }, [this.signalRManager.connected]),

        onDisconnected: (handler: SignalREventHandler) => effects.register(disconnected => {
            if (disconnected)
                handler();
        }, [this.signalRManager.disconnected]),

        onReconnected: (handler: SignalREventHandler) => effects.register(this.signalRManager.reconnected.subscribe(reconnected => {
            if (reconnected)
                handler();
        }))
    }));

    topicActivities = this.createEventsGroupFactory('TopicParticipantsActivities', factory => ({
        onParticipantWatchingVideo: factory.createEvent('ParticipantWatchingVideo', (topicId: string, personId: string, link: string, progress: number) => topicId)
    }));

    topicAttachmentsUpdates = this.createEventsGroupFactory('DiscussionTopicForImageUpdates', factory => ({
        onThreadAttachmentUpdated: factory.createEvent('ThreadAttachmentUpdated', (topicId: string, attachment: JSONWCCAttachment, oldUserFileId?: string) => topicId)
    }));    

    topicNotifications = this.createEventsGroupFactory('DiscussionTopicNotifications', factory => ({
        onNotificationEvent: factory.createEvent('NewNotificationEvent', (topicId: string, threadId: string, personId: string, type: number) => topicId),
        onThreadReportCleared: factory.createEvent('ThreadReportCleared', (topicId: string, threadId: string) => topicId),
        onThreadReportUndone: factory.createEvent('ThreadReportUndone', (topicId: string, threadId: string) => topicId),
        onTopicChanged: factory.createEvent('TopicChanged', (topicId: string) => topicId)
    }));

    topicSlides = this.createEventsGroupFactory('DiscussionTopicSlides', factory => ({
        onSlideAdded: factory.createEvent('TopicSlideAdded', (topicId: string, slideId: string) => topicId),
        onSlideChanged: factory.createEvent('TopicSlideChanged', (topicId: string, slideId: string) => topicId),
        onSlideRemoved: factory.createEvent('TopicSlideRemoved', (topicId: string, slideId: string) => topicId),
        onActiveSlideChanged: factory.createEvent('ActiveSlideChanged', (topicId: string, slideId: string | undefined, isStaging: boolean) => topicId)
    }));

    topicThreads = this.createEventsGroupFactory('TopicThreads', factory => ({
        onNewComment: factory.createEvent('NewTopicComment', (threadId: string, topicId: string, personId: string) => topicId),
        onThreadDeleted: factory.createEvent('TopicThreadDeleted', (threadId: string, parentThreadId: string, personId: string, topicId: string) => topicId),
        onTranslationIsChanged: factory.createEvent('TopicThreadTranslationIsChanged', (topicId: string, threadId: string, content: string) => topicId)
    }));

    typingUsers = this.createEventsGroupFactory('TypingUsers', factory => ({
        onTyping: factory.createEvent('SignalRMessage_UserIsTyping', (discussionId: string, topicId: string, session: JSONOnlineUserSession) => topicId),
        onStoppedTyping: factory.createEvent('SignalRMessage_UserStoppedTyping', (discussionId: string, topicId: string, sessionId: string) => topicId)
    }));

    userAppointments = this.createEventsGroupFactory('UserAppointments', factory => ({
        onAppointmentCreated: factory.createEvent('UserAppointmentCreated', (discussionId: string, personId: string, appointmentId: string, startsAt: string) => discussionId),
        onAppointmentChanged: factory.createEvent('UserAppointmentChanged', (discussionId: string, personId: string, appointmentId: string) => discussionId),
        onAppointmentDeleted: factory.createEvent('UserAppointmentDeleted', (discussionId: string, personId: string, appointmentId: string) => discussionId),
        onAppointmentRequestCreated: factory.createEvent('UserAppointmentRequestCreated', (discussionId: string, personId: string, requestId: string) => discussionId),
        onAppointmentRequestDeleted: factory.createEvent('UserAppointmentRequestDeleted', (discussionId: string, personId: string, requestId: string) => discussionId)
    }));

    constructor(
        @inject(wccModules.effects) private effects: EffectsContainer,
        @inject(wccModules.storage) private storage: IWCCStorageManager,
        @inject(wccModules.signalRManager) private signalRManager: ISignalRCoreManager
    ) { }    

    /**
     * Creates new events group from provided events config
     * in order to avoid describing each group as new class we "inject" events from config into group object
     * @param subscriptionName Group or subscription name from NotificationsHub on server
     * @param configFactory functions which takes EventsFactory and produce events config
     */
    private createEventsGroupFactory<T extends StringMap<Action<[SignalREventHandler<any>]>>>(subscriptionName: string, configFactory: Func<T, [eventsFactory: EventsFactory<string>]>) {
        return (id: string) => this.createEventsGroupInternal(subscriptionName, effects => configFactory(new EventsFactory(id, subscriptionName, effects, this.signalRManager)));
    }

    private createEventsGroup<A extends SignalRSubscriptionArgs, T extends StringMap<Action<[SignalREventHandler<any>]>>>(subscriptionName: string, subscriptionArgs: A, configFactory: Func<T, [eventsFactory: EventsFactory<A>]>) {
        return this.createEventsGroupInternal(subscriptionName, effects => configFactory(new EventsFactory(subscriptionArgs, subscriptionName, effects, this.signalRManager)));
    }

    /**
     * creates new fake events group.
     * these are binded to local js events
     * @param configFactory
     */
    private createSystemEventsGroupFactory<T extends StringMap<Action<[SignalREventHandler<any>]>>>(configFactory: Func<T, [eventsFactory: EffectsContainer]>) {
        return () => this.buildSystemEventsGroup(effects => configFactory(effects));
    }

    /**
     * builds actual group using events from factory and group methods
     * @param subscriptionName NotificationHub subscription name
     * @param factory events factory
     */
    private createEventsGroupInternal<T>(subscriptionName: string, factory: Func<T, [EffectsContainer]>): SignalREventsConfig<T> {
        const effects = this.effects.register(withEffects());
        const config = factory(effects);

        const subscription = this.signalRManager.connected.pluck(isConnected => {
            if (isConnected)
                return this.signalRManager.activeSubscriptions.find(s => s == subscriptionName).extend({ notifyIfChanged: true });
        });

        const mappedConfig: SignalREventsConfig<T> = <any>_(config).mapObject((func: any) => {
            return (...args: any[]) => {
                func(...args);
                return mappedConfig;
            }
        });

        mappedConfig.onSubscriptionActivated = (handler: Action) => {
            effects.register(subscription => {
                if (subscription != undefined)
                    handler();
            }, [subscription]);

            return mappedConfig;
        }

        mappedConfig.onSubscriptionDeactivated = (handler: Action) => {
            effects.register([
                subscription.subscribe((subscription) => {
                    if (subscription == null)
                        handler();
                })
            ]);

            return mappedConfig;
        }

        mappedConfig.dispose = () => effects.dispose();        

        return mappedConfig;
    }

    /**
     * builds actual group using events from factory and group methods
     * @param factory events factory
     */
    private buildSystemEventsGroup<T>(factory: Func<T, [EffectsContainer]>) {
        const effects = this.effects.register(withEffects());
        const config = factory(effects);

        const mappedConfig: SignalRSystemEventsConfig<T> = <any>_(config).mapObject((func: any) => {
            return (...args: any[]) => {
                func(...args);
                return mappedConfig;
            }
        });

        mappedConfig.dispose = () => effects.dispose();

        return mappedConfig;
    }
}
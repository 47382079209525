import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import events from 'managers/events';
import { apiTagChangeResult } from 'models/apiTagChangeResult';
import { JSONTag, Tag } from 'models/tag';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class TagsService extends BaseService {
    private _repository = new Repository(endpoint)

    queries = {
        participantTags: (discussionId: string, participantId: string) => this._repository.query<never, JSONTag, never, never, never, never, never>(`discussions/${discussionId}/participants/${participantId}/tags`),
        topicPeopleTags: (topicId: string) => this._repository.query<never, JSONTag, never, never, never, never, never>(`topics/${topicId}/personTags`),
        communityPageTags: (pageId: string) => this._repository.fastQuery(`communitypages/${pageId}/tags`),
        saveTag: (discussionId: string, entity: string, entityId: string) => this._repository.query(`${entity}/${entityId}/attachTag?discussionId=${discussionId}`),
        removeTag: (discussionId: string, entity: string, entityId: string, tagId: string) => this._repository.query(`discussions/${discussionId}/${entity}/${entityId}/tags/${tagId}`),
        updateTag: (discussionId: string) => this._repository.query<never, never, never, never, JSONTag, apiTagChangeResult, never>(`${discussionId}/contentTags`),
        communityPagesTags: () => this._repository.fastQuery(`communitypages/tags`),
        tags: () => this._repository.query<never, JSONTag | undefined, never, never, never, never, never>('tags'),
        contentTags: (discussionId: string) => this._repository.query(`${discussionId}/contentTags`),
        allTopicTags: (discussionId: string) => this._repository.fastQuery(`discussions/${discussionId}/topictags`),
        allPeopleTags: (discussionId: string) => this._repository.fastQuery(`discussions/${discussionId}/peopletags`),
        allThreadTags: (discussionId: string) => this._repository.fastQuery(`discussions/${discussionId}/threadtags`),
        commentApprovalTag: (commentId: string) => this._repository.query(`thread/${commentId}/approvalThreadTag/`),
        threadTags: (threadId: string) => this._repository.query<never, JSONTag, never, never, never, never, never>(`thread/${threadId}/tags`),
        topicMoodTags: (topicId: string) => this._repository.fastQuery(`topics/${topicId}/moodTags`),
        tagVisibility: (tagId: string) => this._repository.query(`tags/${tagId}/visibility`),
        moderatorPostsFilteringTags: (discussionId: string) => this._repository.query(`projects/${discussionId}/moderators/postsFilteringTags`),
        observersPostsFilteringTags: (discussionId: string) => this._repository.query(`projects/${discussionId}/observers/postsFilteringTags`),
        tagByValue: (discussionId: string) => this._repository.query(`projects/${discussionId}/tags/values`),
    }

    events = {
        topicTagAdded: this.signalREvent('TopicTagAdded'),
        threadTagAdded: this.signalREvent('ThreadTagAdded')
    }

    messages = {
        threadTagAdded: this.signalRMessage('ThreadTagAdded')
    }

    async changeTagVisibility(tagId: string, tagRoot: string | undefined, visibility: number) {
        await this.queries.tagVisibility(tagId).update(visibility);
        events.tagVisibilityUpdated.trigger(tagId, tagRoot, visibility);
    }

    async getTags(oData: URLQueryData) {
        return await this.queries.tags().addArgs(oData).toArray();
    }

    async getTagByValue(value: string, discussionId?: string) {
        const requestData = { TagValue: value };
        const response = await this.queries.tagByValue(discussionId ?? settings.emptyGuid).post(requestData);
        return _.first(response);
    }

    getAllTopicTags(discussionId: string, oData: URLQueryData) {
        return this.queries.allTopicTags(discussionId).addArgs(oData).toArray();
    }

    getAllPeopleTags(discussionId: string, oData: URLQueryData) {
        return this.queries.allPeopleTags(discussionId).addArgs(oData).toArray();
    }

    getAllThreadTags(discussionId: string, oData: URLQueryData) {
        return this.queries.allThreadTags(discussionId).addArgs(oData).toArray();
    }

    toggleCommentApprovalTag(commentId: string) {
        return this.queries.commentApprovalTag(commentId).update().then(jsonTag => new Tag(jsonTag)) //todo: test
    }

    getTopicMoodTags(topicId: string) {
        return this.queries.topicMoodTags(topicId).toArray();
    }

    getParticipantTags(discussionId: string, participantId: string, oData: URLQueryData) {
        return this.queries.participantTags(discussionId, participantId).addArgs(oData).toArray();
    }

    saveParticipantTag(discussionId: string, participantId: string, jsonTag: JSONTag) {
        return (this.queries.saveTag(discussionId, 'participants', participantId).post(jsonTag) as JQuery.Promise<any>)
            .done(result => events.participantTagAdded.trigger(participantId, result.Tag.ContentTagId, result.Tag));
    }

    removeParticipantTag(discussionId: string, participantId: string, tagId: string) {
        return (this.queries.removeTag(discussionId, 'participants', participantId, tagId).remove() as JQuery.Promise<void>)
            .done(() => events.participantTagRemoved.trigger(participantId, tagId));
    }

    getThreadTags(threadId: string) {
        return this.queries.threadTags(threadId).toArray();
    }

    saveThreadTag(discussionId: string, threadId: string, jsonTag: JSONTag) {
        return (this.queries.saveTag(discussionId, 'thread', threadId).post(jsonTag) as JQuery.Promise<any>)
            .done(result => events.threadTagAdded.trigger(threadId, result.Tag.ContentTagId, result.Tag));
    }

    removeThreadTag(discussionId: string, threadId: string, tagId: string) {
        return (this.queries.removeTag(discussionId, 'thread', threadId, tagId).remove() as JQuery.Promise<any>)
            .done(() => events.threadTagRemoved.trigger(threadId, tagId));
    }

    async updateTag(discussionId: string, jsonTag: JSONTag) {
        const result = await this.queries.updateTag(discussionId).update(jsonTag);

        events.tagUpdated.trigger(<string>result.Tag.ContentTagId, {
            ...result.Tag,

            DiscussionId: discussionId
        });
    }
}
import { Observable, ObservableArray } from 'knockout';
import WCCEntity from 'models/entity';
import SurveyStatisticsPage, { JSONSurveyStatisticsPage } from './page';

export interface JSONSurveyStatistics {
    DiscussionTopicId: string
    AverageScore: number

    ProfilePages: Array<JSONSurveyStatisticsPage>
}

export default class SurveyStatistics extends WCCEntity<JSONSurveyStatistics> {
    topicId: Observable<string>
    averageScore: Observable<number>

    pages: ObservableArray<SurveyStatisticsPage>

    constructor(data: JSONSurveyStatistics) {
        super();

        this.topicId = this.createField(data, 'DiscussionTopicId');
        this.averageScore = this.createField(data, 'AverageScore');

        this.pages = this.createCollection(data, 'ProfilePages', {
            key: 'pageId',
            map: jsonPage => new SurveyStatisticsPage(jsonPage),
            mapReverse: page => page.toJson(),
            update: (page, newPage) => page.update(newPage.toJson())
        });
    }
}
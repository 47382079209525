import { Func } from 'interfaces/func';
import { Observable } from 'knockout';
import { ObservableMapping } from 'models/observableMapping';

export function getObservableAccesser(observable: Observable<any>, predicateOrMapping: Func<any, [any]> | ObservableMapping<any, any>) {
    let read: Func<any, [any]> = _.identity;
    let write = (unwrapped: any, value: any) => { observable(<any>value) };

    if (_.isFunction(predicateOrMapping)) {
        read = predicateOrMapping;
    } else if (predicateOrMapping != undefined) {
        if (predicateOrMapping.read != undefined)
            read = predicateOrMapping.read;

        if (predicateOrMapping.write != undefined)
            write = predicateOrMapping.write;
    }

    return { read, write };
};
import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';

export class VideoService extends BaseService {
    constructor() {
        super();

        this._repository = new Repository(endpoint);

        this.queries = {
            vimeoSettings: () => this._repository.fastQuery('videoplayers/vimeo/settings'),
            youtubeSettings: () => this._repository.fastQuery('videoplayers/youtube/settings')
        };
    }

    /**
     * Returns Vimeo settings.
     */
    getVimeoSettings() {
        return this.queries.vimeoSettings().firstOrDefault();
    }

    /**
     * Returns YouTube settings.
     */
    getYouTubeSettings() {
        return this.queries.youtubeSettings().firstOrDefault();
    }
}
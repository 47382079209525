import ActiveQuery from 'models/queries/active';
import EndPoint from './base';
import GenericEndPoint from './generic';

export class SiteEndPoint implements EndPoint {
    private endpoint: GenericEndPoint;

    constructor() {
        this.endpoint = new GenericEndPoint({
            send: ({ url, method, data, cancelPromise }) => {
                let jqxhr = $.ajax({
                    url: `${location.protocol}//${location.host}/${url}`,
                    method: method,
                    data: data
                });

                if (cancelPromise)
                    cancelPromise.then(() => jqxhr.abort(), _.noop);

                return jqxhr;
            }
        })
    }

    send<T>(query: ActiveQuery) {
        return this.endpoint.send(query);
    }
}

const siteEndpoint = new SiteEndPoint();

export default siteEndpoint;
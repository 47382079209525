import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { EntityDataSource } from 'managers/datasources/entity';
import EntityDataSourceOwner from 'managers/entityDataSourceOwner';
import events from 'managers/events';
import { EffectsContainer } from 'mixins/withEffects';
import CommunityPage, { JSONCommunityPage } from 'models/community/page';
import { ICommunitiesService } from 'services/iCommunities';

export interface CommunityPageManagerConfig {
    pageId: string
    includeContent: boolean
    includeHistory: boolean
}

@injectable()
@storageDefaults(<Partial<CommunityPageManagerConfig>>{ includeContent: false, includeHistory: false })
export default class CommunityPageManager extends EntityDataSourceOwner<JSONCommunityPage> {
    private pageId: string
    protected source: EntityDataSource<JSONCommunityPage>

    page: Subscribable<CommunityPage | undefined>

    constructor(
        @inject(wccModules.managerConfig) { pageId, includeContent, includeHistory }: CommunityPageManagerConfig,
        @inject(wccModules.communitiesService) communitiesService: ICommunitiesService,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        super();

        this.pageId = pageId;

        this.source = effects.register(new EntityDataSource({
            update: () => communitiesService.adminPage(pageId).addArgs({ includeContent, includeHistory }).firstOrDefault()
        }));

        this.page = this.source.data.toEntity(
            jsonPage => new CommunityPage(jsonPage),
            (page, jsonPage) => page.update(jsonPage));

        effects.register([
            events.communityPageContentUpdated.on(this.onPageContentUpdated.bind(this))
        ]);
    }

    private onPageContentUpdated(pageId: string, data: JSONCommunityPage) {
        if (this.pageId == pageId)
            this.page()?.update(data);
    }
}